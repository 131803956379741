export default {
  tags: 'Метки',
  status: 'Статус',
  statuses: {
    all: 'Все',
    new: 'Новые',
    in_work: 'В работе',
    completed: 'Завершенные'
  },
  statuses_one: {
    all: 'Все',
    new: 'Новая',
    in_work: 'В работе',
    completed: 'Завершенная'
  },
  helper: {
    new: 'Новая',
    auto_accepted: 'Подтверждена автоматически',
    accepted_rejected: 'Подтверждена/Отклонена',
    in_work: 'В работе',
    comment: 'Комментарий',
    completed: 'Завершена'
  },
  items_empty: 'У вас нет новых тревог',
  reaction: 'Реакция',
  reactions: {
    none: 'Нет реакции',
    acknowledged: 'Подтверждённые',
    rejected: 'Отклонённые'
  },
  features: {
    gender_v2: 'Пол',
    male: 'Мужской',
    female: 'Женский'
  },
  comments: 'Комментарии',
  with_comments: 'С комментариями',
  without_comments: 'Без комментариев',
  reaction_time: 'Время реакции',
  detection_time: 'Время обнаружения',
  author: 'Исполнитель',
  comment: 'Комментарий',
  add_comment: 'Добавить комментарий',
  add_comment_and_complete: 'Добавить комментарий и завершить',
  alarm_info: 'Информация о тревоге',
  new_alarm: 'Новая тревога',
  acknowledged: 'Подтверждено',
  rejected: 'Отклонено',
  complete_with_comment: 'Завершено с комментарием',
  ack_status: {
    in_work: 'В работе у ',
    acknowledged: 'Завершено ',
    auto_acknowledged: 'Подтверждена автоматически'
  },
  info: 'Информация',
  history: 'История',
  details: 'Детали',
  acknowledge: 'Подтвердить',
  reject: 'Отклонить',
  acknowledge_all: 'Подтвердить все',
  new_items: 'Показать новые',
  auto_acknowledge_mf:
    '{ count, plural, one {# секунд осталось до автоматического подтверждения} other {# секунд осталось до автоматического подтверждения} } ',
  not_found: 'Не найдено',
  system: 'Система'
};
