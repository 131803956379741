export default {
  all: 'Все',
  current: 'Текущий',
  common: 'Общее',
  disabled: 'ОТКЛЮЧЕНО',
  enabled: 'Включено',
  generated: 'Сгенерирован',
  intervals: 'Интервалы',
  last_updated: 'Последнее обновление',
  left: 'осталось',
  no: 'Нет',
  second_ago_pf: 'секунд назад',
  license_id: 'ID лицензии',
  services: 'Службы',
  license: 'Лицензия',
  source: 'Файл',
  type: 'Тип лицензии',
  valid: 'Действительная',
  invalid: 'Недействительная',
  yes: 'Да',
  resource_names: 'Ресурсы',
  resources: {
    cameras: 'Камеры',
    extapi: 'Количество Extraction API',
    objects_tntapi: 'Объекты TNT API',
    cameras_body: 'Камеры: Силуэты',
    cameras_car: 'Камеры: ТС',
    cameras_face: 'Камеры: Лица',
    cameras_video_recording: 'Камеры: Запись видео'
  },
  expiry_date: 'Действительна до',
  updated_date: 'Обновлена',
  used: 'Использовано',
  limits: 'Ограничения',
  groups: {
    functional: 'Функциональные модули',
    detections: 'Обнаружение объектов и их атрибутов'
  },
  subgroups: {
    face: 'Атрибуты лица',
    body: 'Атрибуты силуэта',
    car: 'Атрибуты транспортного средства',
    head: 'Атрибуты головы',
    other: 'Другие атрибуты'
  },
  extra_name: 'Дополнительно',
  valid_items: 'Действительные',
  invalid_items: 'Недействительные',
  nearest_date: 'Ближайшая дата',
  latest_date: 'Последняя истекает',
  tabs: {
    common: 'Основное',
    licenses: 'Лицензии',
    intervals: 'Интервалы',
    services: 'Службы'
  },
  actions: {
    buy_or_update: 'Купить или обновить',
    download_c2v: 'Скачать C2V',
    upload_file: 'Загрузить файл'
  },
  start_date: 'Дата начала',
  end_date: 'Дата окончания',
  status: 'Статус',
  counters: 'Счетчики',
  empty_license_description: 'Лицензия не найдена.',
  last_updated_at: 'Последнее обновление ',
  disabled_feature_description: 'Свяжитесь с администратором или службой поддержки, чтобы включить эту функцию в вашей лицензии',
  last_update: 'Последнее обновление ',
  last_update_seconds_ago: '{ value, plural, =0 {прямо сейчас} one {# секунду назад} few {# секунды назад}  other {# секунд назад} }',
  featureNames: {
    all_attrs: 'Все атрибуты',
    body_bags: 'Сумки',
    body_emben: 'Вектор признаков силуэта',
    body_other_attrs: 'Другие атрибуты',
    body_protective_equipment: 'Защитное снаряжение',
    car_categories: 'Категория ТС',
    car_description: 'Описание ТС',
    car_emben: 'Вектор признаков ТС',
    car_license_plate: 'Регистрационный номер',
    car_license_plate_visibility: 'Видимость регистрационного знака',
    car_orientation: 'Ориентация ТС',
    car_other_attrs: 'Другие атрибуты ТС',
    car_special_types: 'Спецтранспорт',
    car_weight_types: 'Вес и размер ТС',
    face_age: 'Возраст',
    face_eyes_attrs: 'Состояние глаз',
    face_beard: 'Борода',
    face_emotions: 'Эмоции',
    face_gender: 'Пол',
    face_glasses: 'Очки',
    face_headpose: 'Положение головы',
    face_liveness: 'Витальность',
    face_medmask: 'Медицинская маска',
    tntapi_fast_index: 'Быстрый индекс TNT API',
    video_recording: 'Видеозапись',
    'sec-external-vms': 'Внешние VMS',
    'sec-genetec': 'Genetec',
    'bi-analytics': 'BI аналитика',
    'line-crossing': 'Пересечение линии',
    maps: 'Карты',
    alerts: 'Тревоги'
  },
  contact_description: 'Чтобы приобрести лицензию, пожалуйста, обратитесь в офис продаж по адресу:',
  contact_email: 'info@ntechlab.com',
  expired: 'Истёк срок действия',
  invalid_license_description: 'Лицензия недействительна. Обратитесь к вашему менеджеру или в отдел технической поддержки для получения консультации.',
  buy_or_update_license: 'Купить или обновить лицензию',
  download_c2v: 'Скачать C2V'
};
