
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CommonPageState } from '@/components/common/page/CommonPageState';
import DisplayTypesButtonGroup from '@/components/common/DisplayTypesButtonGroup.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import NTable from '@/uikit/table/NTable.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import Statistics from '@/components/common/Statistics.vue';
import SortSection from '@/components/common/SortSection.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { DisplayTypes } from '@/store/application/page.definitions';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NInput from '@/uikit/input/NInput.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { aclModule } from '@/store/acl';

@Options({
  name: 'CommonPage',
  components: {
    NInput,
    NLoadingCircle,
    PageContentLayout,
    NButtonGroup,
    NButton,
    SortDropdown,
    SortSection,
    Statistics,
    FilterSection,
    NTable,
    InfiniteScroll,
    DisplayTypesButtonGroup
  }
})
export default class CommonPage extends Vue {
  @Prop({ type: Object, required: true })
  readonly state!: CommonPageState;

  get DisplayTypes() {
    return DisplayTypes;
  }

  get module() {
    return this.state.module;
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  get items() {
    return this.module.items.map((item: any) => ({
      ...item,
      key: `${item.key}_${item.id}_${item.ddp_id}`
    }));
  }

  @Watch('module.filter.current', { deep: true })
  currentFilterHandler() {
    this.module.debouncedGet();
    console.log('Get common page', this.module);
  }

  getIsItemSelected(item: any) {
    return !!multisidebarModule.getItem(this.state.pageSidebarType, item.id);
  }

  getIsItemOpened(item: any) {
    return multisidebarModule.currentItem?.id === generateMultisidebarId(this.state.pageSidebarType, item.id);
  }

  // Serialization and save state to local storage
  get serializeData() {
    return this.state.serialize();
  }

  @Watch('serializeData')
  serializeToLocalStorage(newSerializeData: string) {
    if (this.state.serializeToLocalStorageAsKey) {
      localStorage.setItem(this.state.serializeToLocalStorageAsKey, newSerializeData);
    }
  }

  @Watch('state.serializeToLocalStorageAsKey')
  unserializeFromLocalStorage() {
    if (this.state.serializeToLocalStorageAsKey) {
      try {
        this.state.unserialize(localStorage.getItem(this.state.serializeToLocalStorageAsKey));
      } catch (e) {
        console.warn(e);
      }
    }
  }

  mounted() {
    this.unserializeFromLocalStorage();
  }

  async handleCreate(): Promise<void> {
    console.log('Handler create', this, this.state.addNew);
    return this.state.addNew?.handler ? await this.state.addNew?.handler() : await multisidebarModule.addNewItemByType(this.state.pageSidebarType);
  }

  // new table functions BEGIN
  sortField = '';

  get selectedItemIds(): number[] {
    return multisidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.Alerts).map((v) => v.model.item.id);
  }

  get disabledItemsIds(): number[] {
    return [];
  }

  computeRowClass(value: any, index: number) {
    const item: any = value[0]?.row;
    return item.active ? '' : 'opacity50';
  }

  handleSelectChanges(changes: number[]) {
    const items = changes?.map((id) => (this.module.items as any[]).find((v: any) => v.id === id));
    items.forEach((item) => {
      multisidebarModule.toggleItemSelect(this.state.pageSidebarType, item);
    });
  }

  async tableActionHandler({ row, path }: any): Promise<void> {
    const id = row.id;
    if (path === 'active') {
      if (row.active) {
        await actionHandler.run(ItemsActionNames.Activate, { type: this.state.pageSidebarType, rawItem: { ...row, active: false } });
      } else {
        await actionHandler.run(ItemsActionNames.Deactivate, { type: this.state.pageSidebarType, rawItem: { ...row, active: true } });
      }
    } else {
      await actionHandler.run(ItemsActionNames.ShowItem, { type: this.state.pageSidebarType, rawItem: id });
    }
    return;
  }

  handleTableSort(columnName: string) {
    this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = this.sortField;
    this.module.get();
  }

  // new table functions END
}
