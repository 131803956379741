export default {
  zones: 'Zones',
  enter_zone_name: 'Enter zone name',
  apply_zone: 'Apply zone',
  save_selected_zone: 'Save selected zone',
  geo: 'Geo',
  ok: 'Ok',
  standard_watch_list_was_not_deleted: 'Standard watch-list can`t be deleted.',
  latitude: 'Latitude',
  longitude: 'Longitude',
  elevation: 'Altitude',
  match_type: 'Match type',
  match_type_related: 'Related',
  match_type_matched: 'Auto matched',
  match_type_matched_or_related: 'Either type of match',
  photo: 'Photo',
  code: 'Code',
  success_count: 'Success count',
  fail_count: 'Fail count',
  description: 'Description',
  only_without_bodies: 'Only without body images',
  only_with_bodies: 'Only with body images',
  submit_and_create_report: 'apply and create report',
  add: 'add',
  create_card: 'add record',
  dark: 'Dark theme',
  light: 'Light theme',
  off: 'off',
  warning: 'warning',
  close_empty_detect_dialog: 'Objects are not found. Close',
  removing_confirmation: 'Are you sure you want to delete this file?',
  activate_all: 'Activate all',
  deactivate_all: 'Deactivate all',
  save_all: 'Save all',
  save_current: 'Save current',
  delete_all: 'Delete all',
  delete_current: 'Delete current',
  delete_cards_in_all: 'Delete records in all',
  delete_cards_in: 'Delete associated records',
  open_all: 'Open all',
  archive_all: 'Archive all',
  language: 'language',
  unknown: 'unknown',
  unknown_cluster: 'unknown cluster',
  acknowledge: 'acknowledge',
  loading: 'loading',
  live: 'live',
  event: 'event',
  events: 'events',
  episode: 'episode',
  episodes: 'episodes',
  episode_open: 'open',
  episode_close: 'close',
  episode_event: 'have events',
  episode_allowed_types: 'episode types',
  total: 'total',
  total_events: 'events in total',
  total_episodes: 'episodes in total',
  no_data: 'no data',
  face: 'face',
  faces: 'faces',
  body: 'body',
  bodies: 'bodies',
  car: 'vehicle',
  cars: 'vehicles',
  humans: 'individuals',
  id: 'ID',
  photo_id: 'Photo ID',
  video_id: 'Video ID',
  in_total: 'in total',
  total_of: 'of',
  activate_webcam_for_face_recognition: 'Activate webcam to log in using face recognition',
  activate_webcam: 'Activate webcam',
  wait_face_verification: 'Waiting for face verification',
  login_cryptopro: 'Log in with CryptoPro',
  cancel: 'Cancel',
  login: 'Login',
  login_enter: 'Enter login',
  password: 'Password',
  password_desc:
    'Password requirements:\n' +
    '- at least 8 characters long\n' +
    '- not only numerals\n' +
    '- not within the list of 20000 commonly used passwords\n' +
    '- not similar to other user attributes\n' +
    '- only Latin letters, numerals, and special characters are allowed',
  password_change: 'Change password',
  password_repeat: 'Repeat password',
  password_enter: 'Enter password',
  login_with_password: 'Log in with password',
  login_with_ad: 'Log in with Active Directory',
  login_ad_error: 'Log-in attempt via Active Directory failed. Please try again',
  login_and_password_required: 'Login and password are required',
  login_or_password_invalid: 'Check if login and password are correct',
  video_recording_in_progress: 'Video recording is in progress',
  face_recognition_in_progress: 'Your face is being recognized',
  matched_event: 'Matched event',
  matched_episode: 'Matched episode',
  unmatched_event: 'Unmatched event',
  unmatched_episode: 'Unmatched episode',
  matches: 'matches',
  detectors: 'detectors',
  camera: 'camera',
  camera_optional: 'Camera (optional)',
  cameras: 'cameras',
  camera_type: 'camera type',
  camera_group: 'camera group',
  camera_group_create: 'Add new camera group',
  camera_groups_not_selected: 'Not selected',
  camera_groups: 'Camera groups',
  watch_lists: 'Watch lists',
  watch_list: 'watch list',
  watch_list_create: 'Add new watch list',
  watch_list_inactive: 'inactivated',
  watch_list_active: 'Active',
  attributes: 'attributes',
  face_attributes: 'face attributes',
  body_attributes: 'body attributes',
  car_attributes: 'vehicle attributes',
  error: 'error',
  error_loading: 'Loading error',
  errors: 'errors',
  info: 'info',
  connection: 'connection',
  connections: 'connections',
  acknowledged_datetime: 'acknowledged datetime',
  acknowledged_by: 'acknowledged by',
  acknowledged: 'acknowledged',
  unacknowledged_clusters: 'unacknowledged clusters',
  acknowledgement: 'acknowledgment',
  only_acknowledged: 'only acknowledged',
  only_unacknowledged: 'only unacknowledged',
  no_unacknowledged: 'without unacknowledged',
  has_unacknowledged: 'with unacknowledged',
  only_with_matches: 'only with matches',
  only_without_matches: 'only without matches',
  only_active: 'only active',
  only_inactive: 'only inactive',
  face_matches: 'matches: faces',
  body_matches: 'matches: bodies',
  created: 'created',
  updated: 'updated',
  beige: 'beige',
  blue: 'blue',
  brown: 'brown',
  cyan: 'cyan',
  gold: 'gold',
  grey: 'gray',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  silver: 'silver',
  violet: 'violet',
  lightblue: 'light blue',
  white: 'white',
  black: 'black',
  any: 'any',
  reset: 'reset',
  collapse: 'collapse',
  settings: 'settings',
  appearance: 'appearance',
  roles: 'roles',
  users: 'users',
  user_create: 'Add new user',
  column_name: 'name',
  column_active: 'active',
  column_off: 'off',
  column_view: 'view',
  column_change: 'change',
  column_created: 'added',
  column_modified: 'modified',
  column_status: 'status',
  column_faces_posted: 'faces',
  column_cars_posted: 'vehicles',
  column_add: 'add',
  column_delete: 'delete',
  column_size: 'size',
  column_actions: 'actions',
  sessions: 'sessions',
  blocklist_records: 'Blocklist records',
  license: 'licenses',
  extensions: 'extensions',
  genetec: 'genetec',
  tools: 'tools',
  developer: 'developer',
  verify: 'verify',
  documentation_api: 'API documentation',
  preferences: 'settings',
  build_date: 'build date',
  build_version: 'build version',
  documentation: 'documentation',
  logout: 'logout',
  filter_events: 'filter events',
  filter_camera_event: 'filter events',
  search: 'search',
  devices: 'Devices',
  onvif_discovery: 'ONVIF discovery',
  upload: 'Upload',
  external_detectors: 'External detectors',
  stream_url_or_onvif: 'Stream URL or ONVIF device IP address',
  ds_next: 'Next',
  you_are_adding: 'You are adding {count} devices',
  permissions: 'permissions',
  information: 'information',
  general: 'general',
  general_information: 'General information',
  advanced: 'advanced',
  inactivated: 'inactivated',
  changed: 'changed',
  labels: 'labels',
  deduplicate_events_with_interval: 'Deduplicate events with interval',
  enable_sound_alert: 'Enable sound alert',
  send_events_to_external_vms: 'Send mapping events to VMS',
  dont_create_events: 'Do not create events',
  require_event_acknowledgement: 'Require event acknowledgment',
  confidence_threshold: 'Confidence threshold',
  comment: 'comment',
  color: 'color',
  name: 'name',
  name_contains: 'name contains',
  permission: 'permission',
  add_new_role: 'add new role',
  video_archive_id: 'video archive ID',
  video_archive: 'video archive',
  select_video_archive: 'select video archive',
  count_events: 'counts events',
  bs_type: 'event’s best shot',
  bs_type_overall: 'the best shot after the track',
  bs_type_realtime: 'real-time shot',
  episode_id: 'episode ID',
  event_id: 'event ID',
  save: 'save',
  create: 'add',
  created_by: 'by',
  created_by_user: 'added by',
  clusters: 'clusters',
  participants: 'participants',
  sources: 'sources',
  videos: 'videos',
  date_and_time: 'Date and time',
  date: 'date',
  create_report: 'create report',
  reset_filters: 'reset filters',
  enter_episode_id: 'enter episode ID',
  enter_card_name_or_id: 'enter record name or ID',
  enter_video_archive_id: 'enter video archive ID',
  enter_event_id: 'enter event ID',
  card_name_or_id: 'record name or ID',
  card_name: 'Record name',
  select_card: 'Select record',
  card_id: 'Record ID',
  type: 'type',
  object: 'object',
  select_camera_group: 'select camera group',
  select_camera: 'select camera',
  choose_make: 'choose make',
  choose_model: 'choose model',
  select_country: 'select country',
  select_region: 'select region',
  enter_number_plate: 'enter number plate',
  enter_number_plate_contains_description: 'Enter the vehicle license plate number. You can use "?" wildcard to replace any amount of unknown characters.',
  enter_number_plate_strict_description: 'Enter the vehicle license plate number. You can use "," to enter multiple license plate numbers.',
  drag_and_drop_files: 'Drag and drop files to upload',
  drag_and_drop_file: 'Drag and drop file to upload',
  select_file: 'Select file',
  photos: 'photos',
  primary_role: 'primary role',
  user_inactive: 'inactivated',
  add_role: 'add role',
  more_filters: 'More filters',
  apply: 'Apply',
  apply_filters: 'Apply filters',
  save_selected_filters: 'Save selected filters',
  photo_upload: 'Upload photo',
  take_picture: 'Take picture',
  redo_picture: 'Take another picture',
  uploader_activate_webcam: 'Activate webcam',
  or: 'or',
  no_photos: 'No photos',
  uploads: 'uploads',
  cluster_events: 'Cluster events',
  photo_cluster_events: 'Faces detected',
  cluster_event: 'Cluster event',
  cluster_event_mf: '{ count, plural, one {# cluster event} other {# cluster events} } ',
  cant_load_image: 'Unable to load the image',
  yes: 'Yes',
  user_ad_label: 'Active Directory user',
  ad_group: 'Active Directory group',
  latest_device_events: 'Latest device events',
  none: 'none',
  delete_file: 'Delete file',
  delete_file_and_data: 'Delete file and linked data',
  uuid: 'UUID',
  user: 'user',
  device: 'device',
  ip: 'IP address',
  status: 'status',
  last_ping: 'last ping',
  online: 'online',
  offline: 'offline',
  computer: 'computer',
  phone: 'phone',
  block: 'block',
  blocked: 'blocked',
  license_agreement: 'license agreement',
  accept_license_agreement: 'accept license agreement',
  proceed: 'proceed',
  i_accept_license_agreement: 'I accept the license agreement',
  create_blocklist_record: 'create blocklist record',
  edit_blocklist_record: 'edit blocklist record',
  reason: 'reason',
  expires: 'expires',
  uuids: 'UUIDs',
  card_type: 'record type',
  cluster_id: 'cluster ID',
  enter_cluster_id: 'enter cluster ID',
  files: 'Files',
  no_files: 'No files',
  attach_more: 'Attach more',
  attach_first_one: 'Attach the first one',
  enter_filters_name: 'Enter filter name',
  acknowledge_all: 'Acknowledge all',
  card: 'Record',
  select: 'select',
  filter: 'Filter',
  active: 'Active',
  activate: 'Activate',
  deactivate: 'Deactivate',
  delete: 'Delete',
  action_success: 'Action is successfully completed',
  today: 'Today',
  hour_mf: '{ count, plural, one {# hour} other {# hours}}',
  day_mf: '{ count, plural, =0 {# days} one {# day} other {# days}}',
  month_mf: '{ count, plural, =0 {# months}=1 {# month} other {# months}}',
  year_mf: '{ count, plural, =0 {# years} one {# year} other {# years}}',
  images_mf: '{ count, plural, =0 {# images} one {# image} other {# images}}',
  files_mf: '{ count, plural, =0 {# files} one {# file} other {# files}}',
  reset_all: 'Reset all',
  confidence: 'Confidence',
  has_face_objects: 'Faces',
  only_without_faces: 'Only without face images',
  only_with_faces: 'Only with face images',
  has_body_objects: 'Bodies',
  has_car_objects: 'Vehicles',
  only_without_cars: 'Only without vehicle images',
  only_with_cars: 'Only with vehicle images',
  filled: 'Filling',
  only_filled: 'Only filled',
  only_not_filled: 'Only empty',
  filter_event: 'Filter events',
  filter_face_events: 'Filter face events',
  filter_body_events: 'Filter body events',
  filter_car_events: 'Filter vehicle events',
  new_tool: 'New tool',
  participant_id: 'Participant ID',
  participant: 'Participant',
  only_with_participant: 'Only with participant',
  only_without_participant: 'Only without participant',
  participant_name: 'participant name',
  enter_participant_name: 'enter participant name',
  role: 'role',
  activate_all_selected: 'Activate selected',
  deactivate_all_selected: 'Deactivate selected',
  save_all_selected: 'Save selected',
  delete_all_selected: 'Delete selected',
  delete_cards_in_all_selected: 'Delete records in selected',
  open_all_selected: 'Extract from archive',
  archive_all_selected: 'Archive',
  mute: 'Mute',
  unmute: 'Turn on sound',
  user_name: 'Username',
  show_events: 'Show events',
  matched_cluster: 'Matched cluster',
  unmatched_cluster: 'Unmatched cluster',
  url: 'URL',
  close_group: 'Close tabs',
  terminate: 'Terminate',
  discard: 'Discard',
  external_vms: 'External VMS',
  custom_reports: 'Remote tasks',
  filters: 'filters',
  no_items: 'no items',
  restart: 'restart',
  other: 'other',
  clear: 'clear',
  camera_id: 'Camera ID',
  camera_status: 'Camera status',
  disabled: 'DISABLED',
  video_processing_confirm: 'Reprocessing of this video will delete all data associated with this video. Are you sure you want to process this video again?',
  delete_all_cards_confirm: 'Are you sure you want to delete all records from the watch list(s)? It may take some time.',
  delete_all_watch_lists_confirm: 'Are you sure you want to delete watch list(s)? Make sure to delete associated records first if any. A watch list with associated records will not be deleted.',
  delete_video_confirm:
    'Are you sure you want to delete the video(s)? Attention: the video(s) will be deleted only after deleting all data related to it, this may take a long time to complete.',
  delete_confirm: 'Are you sure you want to delete?',
  delete_cameras_confirm: 'Are you sure you want to delete camera(s)?',
  delete_camera_groups_confirm: 'Are you sure you want to delete camera-group(s)?',
  delete_cards_confirm: 'Are you sure you want to delete record(s)?',
  delete_cards_monitoring_confirm: 'Are you sure you want to delete record(s)? All related remote monitoring alerts will also be purged.',
  delete_clusters_confirm: 'Are you sure you want to delete cluster(s)?',
  delete_users_confirm: 'Are you sure you want to delete user(s)?',
  delete_groups_confirm: 'Are you sure you want to delete role(s)?',
  delete_webhooks_confirm: 'Are you sure you want to delete webhook(s)?',
  delete_external_vms_confirm: 'Are you sure you want to delete external VMS(s)?',
  delete_objects_confirm: 'Are you sure you want to permanently delete image(s)?',
  limit: 'Limit',
  enter: 'Enter',
  interface: 'interface',
  display_lists_by_default: 'display lists by default',
  meta_item_enabled: 'enabled',
  meta_item_disabled: 'disabled',
  meta_item_not_found: 'not found',
  make_copy: 'Click to copy',
  use_for_a_card: 'Use for record',
  confidence_filters_desc: 'If the field is empty, the threshold set in the system is used',
  face_body_filter_label: 'Individuals type',
  face_body_filter_placeholder: 'Type',
  collect_location_data: 'Collect location data',
  has_error: 'Has errors',
  with_error: 'With errors',
  without_error: 'Without errors',
  batch_uploader_confirm: 'Are you certain to cancel the upload?',
  reset_participant: 'Reset participant',
  page: 'Page',
  creation_date: 'Date created',
  address: 'Address',
  location: 'Location',
  azimuth: 'Azimuth',
  next: 'Next',
  save_and_close: 'Save & Close',
  tags: 'Tags',
  expired: 'Expired',
  geolocation_not_available_warning: 'Geolocation is not available. Check geolocation settings in your browser. You may have to manually enable access to geolocation.',
  duplicate: 'Duplicate'
};
