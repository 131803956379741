
import { Options, Vue } from 'vue-class-component';
import { configModule } from '@/store/config';
import { multisidebarModule } from '@/store/multisidebar';
import { DetectionItem, Position } from '@/store/verify/types';
import { VerifyModule } from '@/store/verify/VerifyModule';
import NAlertBadge from '@/uikit/alert-badge/NAlertBadge.vue';
import { NAttachment, NAttachmentFileBlob, NAttachments, NAttachmentsI18n } from '@/uikit/attachments';
import NButtonFileSelect from '@/uikit/buttons/NButtonFileSelect.vue';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import VerifyImages from '@/pages/verify/VerifyImages.vue';
import VerifyResults from '@/pages/verify/VerifyResults.vue';
import { verifyFormLayout } from './verify-form-schema';
import VerifyLayout from './VerifyLayout.vue';
import { Watch } from 'vue-property-decorator';
import { Debounce } from '@/common/debounce-decorator';

@Options({
  name: 'VerifyPage',
  components: {
    NAlertBadge,
    NAttachments,
    NButtonFileSelect,
    NForm,
    NLoadingCircle,
    VerifyImages,
    VerifyLayout,
    VerifyResults
  }
})
export default class VerifyPage extends Vue {
  get module() {
    return VerifyModule.create();
  }

  get model() {
    return this.module.model;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get schema(): IFormLayout {
    return verifyFormLayout;
  }

  get isEmptyFiles() {
    return !this.model.filter(({ file }: { file: File | null }) => !!file).length;
  }

  get results() {
    return this.module.result;
  }

  get canShowResultBlock() {
    return !!(this.module.detectionsSources[Position.First] && this.module.detectionsSources[Position.Second]);
  }

  get hasDetectorConfig() {
    return Boolean(configModule.config?.detect_config);
  }

  get attachmentsI18n(): NAttachmentsI18n {
    return {
      droparea_label: this.$t('verify.dnd_placeholder', 'f'),
      droparea_link_label: this.$t('verify.select_files', 'f')
    };
  }

  setFirstFile(file: File) {
    this.module.addFiles(file, Position.First);
  }

  setSecondFile(file: File) {
    this.module.addFiles(file, Position.Second);
  }

  uploadHandler(attachments: NAttachment[]) {
    this.module.addFiles(attachments.map((attachment) => attachment.file as NAttachmentFileBlob));
  }

  selectFirstBbox(detection: DetectionItem) {
    this.module.addDetectionSource(detection, Position.First);
  }
  selectSecondBbox(detection: DetectionItem) {
    this.module.addDetectionSource(detection, Position.Second);
  }

  @Watch('model.0.event_url', { deep: true })
  @Debounce(1000)
  async firstEventHandler(url: string) {
    if (!url) return;
    await this.module.eventUrlHandler(url, Position.First);
  }

  @Watch('model.1.event_url', { deep: true })
  @Debounce(1000)
  async secondEventHandler(url: string) {
    if (!url) return;
    await this.module.eventUrlHandler(url, Position.Second);
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  mounted() {
    this.sidebarModule.contentOverlap = true;
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }
}
