import capitalize from 'lodash/capitalize';
import mapValues from 'lodash/mapValues';
import toUpper from 'lodash/toUpper';
import { exception } from '@/common/utils';
import {
  BodyFeature,
  BodyFeatureNames,
  BodyFeaturesFormatter,
  BodyFeaturesFormatterFactory,
  CarFeature,
  CarFeatureNames,
  CarFeaturesFormatter,
  CarFeaturesFormatterFactory,
  FaceFeature,
  FaceFeatureNames,
  FaceFeaturesFormatter,
  FaceFeaturesFormatterFactory,
  FeatureI18n
} from './types';
import { ObjectsType } from '@/store/application/data.assets';

export const createBodyFeaturesFormatter: BodyFeaturesFormatterFactory = (formatters, unknown) => {
  return (features, i18n) => mapValues(features, (feature, name) => (Reflect.get(formatters, name) ?? unknown)(name, feature, i18n));
};

export const createCarFeaturesFormatter: CarFeaturesFormatterFactory = (formatters, unknown) => {
  return (features, i18n) => mapValues(features, (feature, name) => (Reflect.get(formatters, name) ?? unknown)(name, feature, i18n));
};

export const createFaceFeaturesFormatter: FaceFeaturesFormatterFactory = (formatters, unknown) => {
  return (features, i18n) => mapValues(features, (feature, name) => (Reflect.get(formatters, name) ?? unknown)(name, feature, i18n));
};

export const formatBodyFeatures = createBodyFeaturesFormatter(
  {
    age_group: (name, feature, i18n) => ({
      content: formatBodyFeatureAsSingleValueString(name, feature, i18n)
    }),
    bag_back: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    /* see FFSEC-3321, FFSEC-862
    bag_ground: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),*/
    bag_hand: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    bottom_color: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    detailed_upper_clothes: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsSingleValueString(name, feature, i18n)
    }),
    gender: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsSingleValueString(name, feature, i18n)
    }),
    headwear: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    helmet_type_score: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: i18n.t(`features.bodies_${name}: ${feature.name}`, 'f')
    }),
    helmet_type: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    lower_clothes: (name, feature, i18n) => {
      const content =
        feature.name === 'obscured' ? formatBodyFeatureAsKeyValueString(name, feature, i18n) : formatBodyFeatureAsSingleValueString(name, feature, i18n);
      return {
        confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
        content
      };
    },
    top_color: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    upper_clothes: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsSingleValueString(name, feature, i18n)
    }),
    vest_type_score: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: i18n.t(`features.bodies_${name}: ${feature.name}`, 'f')
    }),
    vest_type: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    fall: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    handface_smoking: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    handface_phone_use: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    }),
    handface_phone_call: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatBodyFeatureAsKeyValueString(name, feature, i18n)
    })
  },
  (name, feature, i18n) => ({
    confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
    content: i18n.t(`features.bodies_${name}__${feature.name}`, 'f')
  })
);

export const formatCarFeatures = createCarFeaturesFormatter(
  {
    body: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureWithComingSoon(name, feature, i18n)
    }),
    category: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    color: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureWithComingSoon(name, feature, i18n)
    }),
    license_plate_country: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    license_plate_number_color: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    license_plate_number: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureIfValueUnknownAsSingleValueString(name, feature, i18n, toUpper)
    }),
    license_plate_region: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    make: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: checkComingSoon(feature.name) ? '' : formatCarFeatureIfValueUnknownAsSingleValueString(name, feature, i18n, formatCarMakeModel)
    }),
    model: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: checkComingSoon(feature.name) ? '' : formatCarFeatureIfValueUnknownAsSingleValueString(name, feature, i18n, formatCarMakeModel)
    }),
    orientation: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    special_vehicle_type: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    }),
    weight_type: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatCarFeatureAsSingleValueString(name, feature, i18n)
    })
  },
  (name, feature, i18n) => ({
    confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
    content: i18n.t(`features.cars_${name}__${feature.name}`, 'f')
  })
);

export const formatFaceFeatures = createFaceFeaturesFormatter(
  {
    age: (_name, feature, i18n) => ({
      content: i18n.tm('common.year_mf', { count: Math.round(feature.name) })
    }),
    beard: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    emotions: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    gender: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    glasses: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    headpose_pitch: (name, feature, i18n) => ({
      content: `${i18n.t(`features.faces_${name}`, 'f')}: ${Math.round(feature.name)}\xB0`
    }),
    headpose_yaw: (name, feature, i18n) => ({
      content: `${i18n.t(`features.faces_${name}`, 'f')}: ${Math.round(feature.name)}\xB0`
    }),
    liveness: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    look: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    }),
    medmask: (name, feature, i18n) => ({
      confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
      content: formatFaceFeatureAsSingleValueString(name, feature, i18n)
    })
  },
  (name, feature, i18n) => ({
    confidence: formatFeatureConfidenceValue(feature.confidence, i18n),
    content: i18n.t(`features.faces_${name}__${feature.name}`, 'f')
  })
);

export function getFeaturesFormatter(object: ObjectsType): BodyFeaturesFormatter | CarFeaturesFormatter | FaceFeaturesFormatter {
  switch (object) {
    case 'bodies':
      return formatBodyFeatures;
    case 'cars':
      return formatCarFeatures;
    case 'faces':
      return formatFaceFeatures;
    default:
      exception(`Unsupported features object type: ${object}`);
  }
}

function formatBodyFeatureAsKeyValueString<TFeatureName extends BodyFeatureNames>(
  name: TFeatureName,
  feature: BodyFeature<TFeatureName>,
  i18n: FeatureI18n
): string {
  return `${i18n.t(`features.bodies_${name}`, 'f')}: ${i18n.t(`features.bodies_${name}__${feature.name}`)}`;
}

function formatBodyFeatureAsSingleValueString<TFeatureName extends BodyFeatureNames>(
  name: TFeatureName,
  feature: BodyFeature<TFeatureName>,
  i18n: FeatureI18n
): string {
  return i18n.t(`features.bodies_${name}__${feature.name}`, 'f');
}

function formatCarFeatureAsSingleValueString<TFeatureName extends CarFeatureNames>(
  name: TFeatureName,
  feature: CarFeature<TFeatureName>,
  i18n: FeatureI18n
): string {
  return i18n.t(`features.cars_${name}__${feature.name}`, 'f');
}

function formatCarFeatureWithComingSoon<TFeatureName extends CarFeatureNames>(
  name: TFeatureName,
  feature: CarFeature<TFeatureName>,
  i18n: FeatureI18n
): string {
  return !checkComingSoon(feature.name) ? formatCarFeatureAsSingleValueString(name, feature, i18n) : '';
}

function formatFaceFeatureAsSingleValueString<TFeatureName extends FaceFeatureNames>(
  name: TFeatureName,
  feature: FaceFeature<TFeatureName>,
  i18n: FeatureI18n
): string {
  return i18n.t(`features.faces_${name}__${feature.name}`, 'f');
}

function formatCarFeatureIfValueUnknownAsSingleValueString<TFeatureName extends CarFeatureNames>(
  name: TFeatureName,
  feature: CarFeature<TFeatureName>,
  i18n: FeatureI18n,
  postFormatter?: (v: string) => string
): string {
  return feature.name === 'unknown' ? i18n.t(`features.cars_${name}__unknown`, 'f') : postFormatter?.(feature.name) ?? feature.name;
}

function checkComingSoon(name: string) {
  return name === 'coming_soon';
}

function formatFeatureConfidenceValue(confidence: number, i18n: FeatureI18n): string {
  return `${i18n.t('common.confidence')}: ${confidence?.toFixed(2)}`;
}

function formatCarMakeModel(makeModel: string) {
  return makeModel.split('/').map(formatCarMakeModelPart).join(' ');
}

function formatCarMakeModelPart(model: string) {
  return model.split('_').map(capitalize).join(' ');
}
