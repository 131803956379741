
import { get, merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { CasePhoto } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ModelAclResult } from '@/store/acl/types';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { websocketModule } from '@/store/ws/websocket.module';
import { Action, NButton } from '@/uikit';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import { NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import { createIdTableSelectionColumn } from '@/uikit/table-v2/utils';
import NTableProgressCell from '@/uikit/table/NTableProgressCell.vue';
import NText from '@/uikit/text/NText.vue';
import { formatFileSize } from '@/common/filters';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';

@Options({
  name: 'DataSourcesPhotosTable',
  components: { NTable }
})
export default class DataSourcesPhotosTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: CasePhoto[];

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: number[];

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Boolean, required: false, default: false })
  readonly isCaseArchived!: boolean;

  createIdTableSelectionColumn = createIdTableSelectionColumn;

  get displayFaceClusters() {
    return configModule.config.extra_options?.includes('display_face_clusters_in_videos_table') ?? false;
  }

  get availableObjectsMap() {
    return dataAssetsModule.availableObjectsMap;
  }

  get columns(): NTableColumn<CasePhoto>[] {
    const baseColumns: NTableColumn<CasePhoto>[] = [
      {
        width: 'max-content',
        head: this.$t('ds.id'),
        body: ({ model }) => model.id
      },
      {
        width: 120,
        head: this.$t('ds.image'),
        body: ({ model }) => {
          const props = { width: 100, displayWidth: '100px', modelValue: model.thumbnail, hasPlay: false, onClick: () => this.showImage(model) };
          return <CameraScreenshot {...props} />;
        }
      },
      {
        width: 'minmax(160px, 1fr)',
        head: this.$t('ds.name'),
        body: ({ model }) => {
          const nameProps = {
            modelValue: model.name,
            class: 'datasource-videos-table__description-name',
            onClick: this.handleNameClick.bind(this, model)
          };
          return (
            <div class="datasource-videos-table__description">
              <NText {...nameProps} />
              <NText>{formatFileSize(model.file_size)}</NText>
            </div>
          );
        }
      }
    ];

    const progressColumn: NTableColumn<CasePhoto> = {
      width: 180,
      head: this.$t('ds.status'),
      body: ({ model }) => <NTableProgressCell {...this.statusPropsHandler(model)}></NTableProgressCell>
    };

    const faceClusterStats: NTableColumn<CasePhoto> = {
      width: 80,
      head: this.$t('ds.faces', 'f'),
      body: ({ model }) => <NText>{model.status}</NText>
    };

    const faceStats: NTableColumn<CasePhoto> = {
      width: 80,
      head: this.$t('ds.faces', 'f'),
      body: ({ model }) => {
        const props = {
          modelValue: model.case_cluster_count ?? 0
        };
        return <NText {...props} />;
      }
    };

    const actionsColumn: NTableColumn<CasePhoto> = {
      width: 80,
      head: '',
      body: ({ model }) => {
        const dropdownProps = {
          actions: this.getActions(model),
          placement: 'left-start',
          disableActions: this.isCaseArchived,
          onAction: (action: ItemsActionName) => this.actionHandler(action, model)
        };
        return (
          <div class="datasource-videos-table__actions-cell">
            <ActionsDropdown class="datasource-videos-table__actions" {...dropdownProps} />
          </div>
        );
      }
    };

    const statColumns = this.displayFaceClusters ? [faceClusterStats] : [faceStats];

    return [...baseColumns, progressColumn, ...statColumns, actionsColumn];
  }

  get websocketModule() {
    return websocketModule;
  }

  @Watch('websocketModule.casePhotoUpdated')
  updateCasePhoto(updatedItem: any) {
    const item = this.items.find((v) => v.id === updatedItem.id) as CasePhoto;
    if (item) {
      merge(item, updatedItem);
      autoUpdateHelper.updateHandler('/case-photos/', item);
    }
  }

  statusPropsHandler(item: CasePhoto) {
    let i18nLabel: string = `cases.photo_status__${item.status}`;
    let label = this.$t(i18nLabel, 'f');
    return { label };
  }

  handleNameClick(model: CasePhoto) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.CasePhotos, rawItem: model });
  }

  dispatchSelect(selected: CasePhoto[]) {
    this.$emit('select', selected);
  }

  actionHandler(v: ItemsActionName, item: CasePhoto) {
    actionHandler.run(v, { type: MultisidebarItemTypes.CasePhotos, rawItem: item });
  }

  showImage(item: CasePhoto) {
    this.$photoViewer.show(item);
  }

  getActions(item: CasePhoto): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasDelete: true,
        hasProcess: true
      })
      .map(actionsModule.computeActionByName);
  }
}
