
import { Alert as AlertRule } from '@/api';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { NButton, NForm, NModalWindow, NTabs } from '@/uikit';
import AlertRuleForm from './forms/AlertRuleForm.vue';
import { EditorSection, EditorSections } from '@/pages/webhooks/components/types';
import generalSchema from '@/pages/alerts/forms/general.schema';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import deliveryChannelsSchema from '@/pages/alerts/forms/delivery.channels.schema';
import settingsSchema from '@/pages/alerts/forms/settings.schema';
import FastCommentsForm from '@/pages/alerts/forms/FastCommentsForm.vue';
import { AlertRuleTabItem, AlertRuleTabItems, AlertRuleTabs } from '@/pages/alerts/alert.definitions';
import { getDelay } from '@/definitions/common/base';
import { dialogModule } from '@/store/dialogs/dialogModule';

@Options({
  name: 'AlertWizard',
  components: { FastCommentsForm, NTabs, NForm, NButton, AlertRuleForm, NModalWindow },
  emits: ['save', 'close']
})
export default class AlertWizard extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: AlertRule;

  formRef?: NForm;

  tab: AlertRuleTabItem = AlertRuleTabItems.General;
  availableIndex = 0;

  get isNextAvailable() {
    return this.tab !== AlertRuleTabItems.FastComments;
  }

  get isSaveAvailable() {
    return this.tab === AlertRuleTabItems.FastComments;
  }

  get tabItems() {
    return AlertRuleTabs.map((v: string, key: number) => ({ name: v, label: this.$t(`alerts.tabs.${v}`, 'f'), disabled: key > this.availableIndex }));
  }

  get isAlertRulesSelected() {
    return this.tab === AlertRuleTabItems.AlertRules;
  }

  get isFastCommentsSelected() {
    return this.tab === AlertRuleTabItems.FastComments;
  }

  get filterSections(): EditorSection[] {
    return [EditorSections.FaceEvents, EditorSections.BodyEvents, EditorSections.CarEvents, EditorSections.Counters];
  }

  get formSchema(): IFormLayoutBlocks | IFormLayout | null {
    let result: IFormLayoutBlocks | IFormLayout | null = null;
    switch (this.tab) {
      case AlertRuleTabItems.General:
        result = generalSchema;
        break;
      case AlertRuleTabItems.DeliveryChannels:
        result = deliveryChannelsSchema;
        break;
      case AlertRuleTabItems.Settings:
        result = settingsSchema;
        break;
    }
    return result;
  }

  setFormRef(value: NForm) {
    this.formRef = value;
  }

  validateAlertRules(): boolean {
    const hasAlertRulesSection = Object.keys(this.item.filters)?.length > 0;
    return hasAlertRulesSection;
  }

  async showEmptyAlertRuleError() {
    await dialogModule.alert('alerts.empty_alert_rule_error');
  }

  nextHandler() {
    const result = this.formRef ? this.formRef.validateAndDisplayErrors() : true;

    if (this.tab === AlertRuleTabItems.AlertRules && !this.validateAlertRules()) {
      this.showEmptyAlertRuleError();
      return;
    }

    if (result) {
      const index = AlertRuleTabs.findIndex((v: string) => v === this.tab);
      this.availableIndex = index + 1;
      this.tab = AlertRuleTabs[index + 1];
    }
  }

  async saveHandler() {
    this.$refs.fastCommentForm?.cleanHandler();
    await getDelay(300);
    this.$emit('save');
    this.$emit('close');
  }

  closeHandler() {
    this.$emit('close');
  }
}
