import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { PagePaths, PageNames } from '@/store/application/page.definitions';
import AuditLogsPage from '@/pages/audit-logs/AuditLogsPage.vue';
import BlocklistPage from '@/pages/blocklist/BlocklistPage.vue';
import CameraGroupsPage from '@/pages/camera-groups/CameraGroupsPage.vue';
import CardsPage from '@/pages/cards/CardsPage.vue';
import CasesPage from '@/pages/cases/CasesPage.vue';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import ClustersPage from '@/pages/clusters/ClustersPage.vue';
import CountersPage from '@/pages/counters/CountersPage.vue';
import CustomReportsPage from '@/pages/custom-reports/CustomReportsPage.vue';
import DataSourcesPage from '@/pages/data-sources/DataSourcesPage.vue';
import DevTools from '@/pages/devtools/DevToolsPage.vue';
import EmptyPage from '@/pages/empty/EmptyPage.vue';
import EventsPage from '@/pages/events/EventsPage.vue';
import ExternalSearchPage from '@/pages/external-search/ExternalSearchPage.vue';
import ExternalVmsPage from '@/pages/external-vms/ExternalVmsPage.vue';
import GroupsPage from '@/pages/groups/GroupsPage.vue';
import InteractionsPage from '@/pages/interactions/InteractionsPage.vue';
import InterfaceSettingsPage from '@/pages/interface-settings/InterfaceSettingsPage.vue';
import LicensePage from '@/pages/license_v1/LicensePage.vue';
import LinesPage from '@/pages/lines/LinesPage.vue';
import PageNotFound from '@/pages/PageNotFound.vue';
import RemoteMonitoringEventsPage from '@/pages/remote-monitoring/RemoteMonitoringEventsPage.vue';
import { ReportsPage } from '@/pages/reports';
import SearchPage from '@/pages/search/SearchPage.vue';
import SessionsPage from '@/pages/sessions/SessionsPage.vue';
import UsersPage from '@/pages/users/UsersPage.vue';
import { VerifyPage } from '@/pages/verify';
import { VideoWallPage } from '@/pages/video-wall';
import WatchListsPage from '@/pages/watch-lists/WatchListsPage.vue';
import WebhooksPage from '@/pages/webhooks/WebhooksPage.vue';
import { Routes } from './enums';
import AnnexAlarmsPage from '@/pages/annex/AnnexAlarmsPage.vue';
import UiComponentsPage from '@/pages/uicomponents/UiComponentsPage.vue';
import AlertsPage from "@/pages/alerts/AlertsPage.vue";

const routes: Array<RouteRecordRaw> = [
  Routes.Main,
  Routes.Login,
  Routes.Settings,
  {
    name: PageNames.Cards,
    path: PagePaths.Cards,
    component: CardsPage
  },
  {
    name: PageNames.Events,
    path: PagePaths.Events,
    component: EventsPage
  },
  {
    name: PageNames.DataSources,
    path: PagePaths.DataSources,
    component: DataSourcesPage
  },
  {
    name: PageNames.Roles,
    path: PagePaths.Roles,
    component: GroupsPage
  },
  {
    name: PageNames.Users,
    path: PagePaths.Users,
    component: UsersPage
  },
  {
    name: PageNames.Sessions,
    path: PagePaths.Sessions,
    component: SessionsPage
  },
  {
    name: PageNames.BlockList,
    path: PagePaths.BlockList,
    component: BlocklistPage
  },
  {
    name: PageNames.WatchLists,
    path: PagePaths.WatchLists,
    component: WatchListsPage
  },
  {
    name: PageNames.CameraGroups,
    path: PagePaths.CameraGroups,
    component: CameraGroupsPage
  },
  {
    name: PageNames.Cases,
    path: PagePaths.Cases,
    component: CasesPage
  },
  {
    name: PageNames.Case,
    path: PagePaths.Case,
    component: EditCasePage
  },
  {
    name: PageNames.Clusters,
    path: PagePaths.Clusters,
    component: ClustersPage
  },
  {
    name: PageNames.Counters,
    path: PagePaths.Counters,
    component: CountersPage
  },
  {
    name: PageNames.Search,
    path: PagePaths.Search,
    component: SearchPage
  },
  {
    name: PageNames.ExternalSearch,
    path: PagePaths.ExternalSearch,
    component: ExternalSearchPage
  },
  {
    name: PageNames.RemoteMonitoringEvents,
    path: PagePaths.RemoteMonitoringEvents,
    component: RemoteMonitoringEventsPage
  },
  {
    name: PageNames.VideoWall,
    path: PagePaths.VideoWall,
    component: VideoWallPage
  },
  {
    name: PageNames.Analytics,
    path: PagePaths.Analytics,
    component: () => import('@/pages/kyc/KycPage.vue') // import KycPage from '@/pages/kyc/KycPage.vue';
  },
  {
    name: PageNames.Verify,
    path: PagePaths.Verify,
    component: VerifyPage
  },
  {
    name: PageNames.License,
    path: PagePaths.License,
    component: LicensePage
  },
  {
    name: PageNames.AuditLogs,
    path: PagePaths.AuditLogs,
    component: AuditLogsPage
  },
  {
    name: PageNames.Reports,
    path: PagePaths.Reports,
    component: ReportsPage
  },
  {
    name: PageNames.ExternalVms,
    path: PagePaths.ExternalVms,
    component: ExternalVmsPage
  },
  {
    name: PageNames.CustomReports,
    path: PagePaths.CustomReports,
    component: CustomReportsPage
  },
  {
    name: PageNames.Alerts,
    path: PagePaths.Alerts,
    component: AlertsPage
  },
  {
    name: PageNames.Hooks,
    path: PagePaths.Hooks,
    component: WebhooksPage
  },
  {
    name: PageNames.Interactions,
    path: PagePaths.Interactions,
    component: InteractionsPage
  },
  {
    name: PageNames.Interface,
    path: PagePaths.Interface,
    component: InterfaceSettingsPage
  },
  {
    name: PageNames.Lines,
    path: PagePaths.Lines,
    component: LinesPage
  },
  {
    name: PageNames.Alarms,
    path: PagePaths.Alarms,
    component: AnnexAlarmsPage
  },
  {
    name: PageNames.DevTools,
    path: PagePaths.DevTools,
    component: DevTools
  },
  {
    name: PageNames.UiComponents,
    path: PagePaths.UiComponents,
    component: UiComponentsPage
  },

  {
    name: PageNames.NotFound,
    path: '/:pathMatch(.*)*',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
