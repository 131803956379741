
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { Camera } from '@/api';

@Options({
  name: 'CameraPopup',
  components: { NIcon, CameraScreenshot, NTooltip }
})
export default class CameraPopup extends Vue {
  @Prop({ type: Object, required: true })
  readonly reference!: HTMLElement;

  @Prop({ type: Object, required: true })
  readonly item!: Camera;

  get detectors() {
    return {
      face: !!this.item?.stream_settings?.detectors?.face,
      body: !!this.item?.stream_settings?.detectors?.body,
      car: !!this.item?.stream_settings?.detectors?.car
    };
  }
}
