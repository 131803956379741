// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { viewModelRepository } from '@/api/common';

const defaultItem = {
  active: true,
  name: 'CameraGroup 1',
  comment: '',
  deduplicate: false,
  deduplicateDelay: 15,
  labels: {},
  face_threshold: 0,
  body_threshold: 0,
  car_threshold: 0
};

async function createCameraGroup(name: string, options: any = {}) {
  const model = viewModelRepository.getCameraGroupsItemViewModel();
  model.setItemsState({ ...defaultItem, name, ...options });
  await model.save();
}

export { createCameraGroup };
