
import { Options, Prop, Vue } from 'vue-property-decorator';
import NButton from '../buttons/NButton.vue';
import NHint from '../hint/NHint.vue';
import type { NAttachmentsI18n } from './types';

@Options({
  components: { NButton, NHint },
  emits: ['cancel', 'remove']
})
export default class NAttachmentsListItemRemovingPopover extends Vue {
  @Prop({ required: true, type: Object })
  readonly i18n!: Readonly<Required<NAttachmentsI18n>>;
  @Prop({ required: true, type: Boolean })
  readonly visible!: boolean;

  dispatchCancelEvent(): void {
    this.$emit('cancel');
  }

  dispatchRemoveEvent(): void {
    this.$emit('remove');
  }
}
