import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "heading-m" }
const _hoisted_2 = { class: "paragraph-m" }
const _hoisted_3 = { class: "custom-report-dialog__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "class-name": "custom-report-dialog",
    size: 'large',
    "insert-into-body": true
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createVNode(_component_NButton, {
        icon: "close",
        type: "secondary",
        round: "",
        class: "n-modal-window__close-button",
        onClick: _ctx.closeHandler
      }, null, 8, ["onClick"])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NForm, {
          ref: "form",
          layout: _ctx.schema,
          model: _ctx.model
        }, null, 8, ["layout", "model"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NButton, {
          type: "link",
          onClick: _ctx.closeHandler
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.cancel', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_NButton, { onClick: _ctx.createHandler }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('common.create', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}