
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { Camera, FaceEvent, HumanCard, VideoArchive } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { CamerasFilter } from '@/api/models/CamerasFilter';
import { VideosFilter } from '@/api/models/VideosFilter';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { DisplayType, DisplayTypes, PagePaths, PageState, PageType, PageTypes } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { uploadModule } from '@/store/application/upload.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarCommonItem, MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import EntityBlank from '@/components/common/EntityBlank.vue';
import { OptionalIFormLayout } from '@/components/common/filter/filters/types';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import AddNewWizard from '@/pages/data-sources/add-devices/AddNewWizard.vue';
import { getDataSourceCamerasFilter } from '@/pages/data-sources/dataSourceFilterBuilder';
import ModulePageNavigation from '@/components/common/ModulePageNavigation.vue';
import DataSourcesCamerasTable from '@/pages/data-sources/DataSourcesCamerasTable.vue';
import DataSourceVideosTable from '@/pages/data-sources/DataSourceVideosTable.vue';
import ListPage from '@/pages/ListPage.vue';
import { configModule, DefaultCenterMapPoint } from '@/store/config';
import DataSourcesCamerasMap from '@/pages/data-sources/DataSourcesCamerasMap.vue';
import { LatLngType } from '@/components/map/types';
import DisplayTypesButtonGroup from '@/components/common/DisplayTypesButtonGroup.vue';
import { CommonMapConfig } from '@/components/map/CommonMapConfig';
import { colorsConfig } from '@/components/map/colors';
import CameraMarker from '@/components/map/CameraMarker.vue';
import CameraClusterMarker from '@/components/map/CameraClusterMarker.vue';
import CommonMap from '@/components/map/CommonMap.vue';
import CameraItem from '@/components/data-source/CameraItem.vue';
import DataSourceTableRowItem from '@/pages/data-sources/DataSourceTableRowItem.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { sortModule } from '@/store/data/SortModule';

type ModuleItem = Camera | VideoArchive;
type ModuleFilter = CamerasFilter | VideosFilter;

function isVideo(item: ModuleItem): item is VideoArchive {
  return typeof (item as VideoArchive).finished !== 'undefined';
}

function filterSidebarItemByPageType(item: MultisidebarCommonItem, pageType: PageType) {
  switch (pageType) {
    case PageTypes.Videos:
      return item.type === MultisidebarItemTypes.Videos;
    case PageTypes.Cameras:
    case PageTypes.ExternalDetectors:
      return item.type === MultisidebarItemTypes.Cameras;
    default:
      return false;
  }
}

function filterItemByPageType(item: ModuleItem, pageType: PageType) {
  if (isVideo(item)) return true;

  switch (pageType) {
    case PageTypes.ExternalDetectors:
      return item.external_detector;
    case PageTypes.ExternalVmsCameras:
    case PageTypes.Cameras:
    default:
      return !item.external_detector;
  }
}

@Options({
  name: 'DataSourcesPage',
  components: {
    NLoadingCircle,
    NIcon,
    ModulePageNavigation,
    DisplayTypesButtonGroup,
    AddNewWizard,
    EntityBlank,
    FilterSection,
    ListPage,
    NBaseBar,
    NButton,
    NButtonGroup,
    SortDropdown,
    Statistics,
    CommonMap
  }
})
export default class DataSourcesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  mapView: { center: LatLngType; zoom: number } | null = null;
  mapRect: any = {};

  protected wizardEnabled = false;

  get isMapView() {
    return this.state.displayType === 'map';
  }

  get contentComponent() {
    if (this.state.pageType === PageTypes.Videos) return DataSourceVideosTable;
    if ((this.state.pageType === PageTypes.Cameras || this.state.pageType === PageTypes.ExternalDetectors) && this.state.displayType === DisplayTypes.Map) return DataSourcesCamerasMap;
    return DataSourcesCamerasTable;
  }

  get smallFiltersLayout(): OptionalIFormLayout[] {
    return getDataSourceCamerasFilter({ small: true, pageType: this.state.pageType, displayType: this.state.displayType });
  }

  get bigFiltersLayout(): OptionalIFormLayout[] {
    return getDataSourceCamerasFilter({ small: false, pageType: this.state.pageType, displayType: this.state.displayType });
  }

  get state(): PageState {
    return pageModule.getPageStateByTab(PagePaths.DataSources, this.tab);
  }

  get module(): ListViewModel<any, any> {
    const r = pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
    const ordering = sortModule.get('ds.' + this.state.pageType);
    if (ordering) r.filter.current.ordering = ordering;
    r.filter.current.limit = this.limit;
    return r;
  }

  get createIcon() {
    return this.state.pageType === PageTypes.Videos ? 'upload' : 'add';
  }

  get createLabel() {
    return this.state.pageType === PageTypes.Videos ? this.$t('ds.upload', 'f') : this.$t('ds.create', 'f');
  }

  get sortTypes(): any[] {
    // todo: add when back will be ready: {name: true, state: true}
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get pageTypeItems() {
    const items = [
      {
        name: MultisidebarItemTypes.Cameras,
        i18n_label: 'ds.cameras',
        permissions: 'ffsecurity.view_camera'
      },
      {
        name: MultisidebarItemTypes.Videos,
        i18n_label: 'ds.uploads',
        permissions: 'ffsecurity.view_videoarchive'
      },
      {
        name: MultisidebarItemTypes.ExternalDetectors,
        i18n_label: 'ds.external_detectors',
        permissions: 'ffsecurity.view_camera'
      }
    ];
    return items.filter((i) => aclModule.getAccess(i.permissions)).map((v) => ({ name: v.name, label: this.$t(v.i18n_label, 'f') }));
  }

  get modelAcl() {
    this.module.aclModelName = this.state.pageType === PageTypes.Videos ? 'videoarchive' : 'camera';
    return aclModule.getModelAcl<ModuleItem, ModuleFilter>(this.module);
  }

  get sidebarType() {
    switch (this.state.pageType) {
      case PageTypes.Videos:
        return MultisidebarItemTypes.Videos;
      case PageTypes.Cameras:
        return MultisidebarItemTypes.Cameras;
      case PageTypes.ExternalDetectors:
        return MultisidebarItemTypes.Cameras;
      case PageTypes.ExternalVmsCameras:
      default:
        return MultisidebarItemTypes.Cameras;
    }
  }

  get sidebarSelectedItemIds(): number[] {
    return this.sidebarModule.items
      .filter((v) => filterSidebarItemByPageType(v, this.state.pageType))
      .filter((v) => filterItemByPageType(v.model.item as ModuleItem, this.state.pageType))
      .map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get hasReport() {
    const pagesHaveReport: MultisidebarItemType[] = [MultisidebarItemTypes.Cameras, MultisidebarItemTypes.ExternalDetectors];
    return pagesHaveReport.includes(this.state.pageType as any as MultisidebarItemType);
  }

  get hasDisplayToggle() {
    return configModule.features.maps_enabled && (this.state.pageType === PageTypes.Cameras || this.state.pageType === PageTypes.ExternalDetectors);
  }

  get commonMapConfig() {
    const result: CommonMapConfig = {
      sidebarTitle_i18n: 'common.cameras',
      module: this.module,
      showTracks: false,
      showHasCones: true,
      msbType: MultisidebarItemTypes.Cameras,
      centerPoint: configModule.config.map?.default_center || DefaultCenterMapPoint,
      colorsConfig: colorsConfig,
      ItemMarker: CameraMarker,
      ClusterMarker: CameraClusterMarker,
      PopupItem: CameraItem,
      ListItem: DataSourceTableRowItem,
      itemProps: {
        type: this.state.pageType,
        openedItem: (item: any) => this.openItem(item)
      },
      actionHandler: async (actionName: ItemsActionName, item: FaceEvent | HumanCard) => {
        const result = await actionHandler.run(actionName, { type: this.sidebarType, rawItem: item });
        return result;
      }
    };
    return result;
  }

  openItem(item: any) {
  }

  @Watch('state.pageType', { immediate: true })
  setForceFilters() {
    const force: Record<string, any> = {};
    if (this.state.pageType === PageTypes.Cameras) force.external_detector = false;
    if (this.state.pageType === PageTypes.ExternalDetectors) force.external_detector = true;
    if (this.module) {
      this.module.filter.force = force;
    }
  }

  @Watch('state.pageType', { immediate: false })
  syncVideosUploaderModule() {
    if (this.state.pageType === PageTypes.Videos) {
      uploadModule.registerClient(this.tab, this.module);
      uploadModule.syncClient(this.tab);
    } else {
      uploadModule.unregisterClient(this.tab);
    }
  }

  @Watch('state.displayType')
  changeDisplayType(v: DisplayType) {
    if (this.state.displayType != DisplayTypes.Map) {
      this.module.filter.current.longitude_gte = undefined;
      this.module.filter.current.longitude_lte = undefined;
      this.module.filter.current.latitude_gte = undefined;
      this.module.filter.current.latitude_lte = undefined;
      this.module.filter.current.page = '';
    } else {
      this.module.filter.current.page = '';
    }
  }


  get limit() {
    return this.state.displayType === DisplayTypes.Map ? configModule?.config?.map?.maximum_objects_on_map ?? 200 : 20;
  }

  @Watch('module')
  registerModule(v: ListViewModel<any, any>) {
    autoUpdateHelper.addListInstance(v);
  }

  @Watch('module.filter.current', { deep: true })
  handleFilterStateChange(v: any, p: any): void {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    if (v.has_coordinates === 'True') {
      this.module.filter.force = Object.assign(this.module.filter.force, this.mapRect);
    } else {
      delete this.module.filter.force.latitude_gte;
      delete this.module.filter.force.latitude_lte;
      delete this.module.filter.force.longitude_gte;
      delete this.module.filter.force.longitude_lte;
    }
    this.module.debouncedGet();
  }

  created() {}

  mounted() {
    this.syncVideosUploaderModule();
  }

  beforeUnmount() {
    uploadModule.unregisterClient(this.tab);
  }

  async uploadHandler({ urls, attachments, params }: any) {
    const config: Record<string, number | boolean> = params;
    const parameters = await cameraResetModule.getVideoSettings();
    const results: VideoArchive[] = [];
    this.wizardEnabled = false;

    results.push(...(await uploadModule.createVideos(this.tab, urls, config, parameters)));
    results.push(...(await uploadModule.uploadVideos(this.tab, attachments, config, parameters)));

    if (results.length) {
      await this.module.get();
      params.open && (await multisidebarModule.addItemAndOpen(MultisidebarItemTypes.Videos, results[0]));
      await multisidebarModule.addItems(MultisidebarItemTypes.Videos, results);
      await this.module.get();
      await this.module.getStatistics();
    }
  }

  enableWizard() {
    this.wizardEnabled = true;
  }

  closeWizard() {
    this.wizardEnabled = false;
    this.module.get();
  }

  handleSelect(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
      });
  }

  scrollBottomHandler(v: number | null) {
    if (this.state.displayType === DisplayTypes.Map) return;
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  handleSetFilter(filters: any) {
    this.module.filter.current = Object.assign(this.module.filter.current, filters);
  }

  updateMapRect(mapRect: any) {
    this.mapRect = mapRect;
    const current = this.module.filter.current;
    if (current.has_coordinates === 'True') {
      this.handleFilterStateChange(current, current);
    }
  }

  setFilter(filter: any) {
    Object.assign(this.module.filter.current, filter);
  }
}
