
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CameraStreamSettings } from '@/api';
import { dataModule } from '@/store/data';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonFileSelect from '@/uikit/buttons/NButtonFileSelect.vue';
import NForm, { IFormModel } from '@/uikit/forms/NForm.vue';
import { AllowedVideoProtocols } from '@/uikit/forms/validators';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';

export interface IDataSourceDevice {
  name: string;
  group?: number;
  url: string;
  stream_settings: CameraStreamSettings;
  group_name: string;
}

@Options({
  name: 'DataSourcesDevices',
  components: { NButton, NButtonFileSelect, NForm }
})
export default class DataSourcesDevices extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  private content = '';

  private layout = [
    {
      classes: 'w100',
      tooltip: '',
      component: NTextareaAutosize,
      props: { i18n_placeholder: 'ds.enter_stream_url' },
      encode: (model: IFormModel, value: any) => (this.content = value),
      decode: () => this.content
    }
  ];

  get textAsArray() {
    return this.content.split('\n');
  }

  isDeviceURL(url: string) {
    const protocolRules = AllowedVideoProtocols.join('|');
    const regexp = new RegExp(`^(${protocolRules})\\/\\/.+`, 'i');
    return regexp.test(url);
  }

  cameraGroupByName(cameraGroupName: string) {
    return dataModule.cameraGroupsModule.items.filter((item) => cameraGroupName.toLowerCase() === item.name.toLowerCase())[0]?.id;
  }

  parseString(string: string) {
    const stringAsArray = string.split(';').map((v) => v.trim());
    const isUrlContent = stringAsArray.length === 1 && this.isDeviceURL(stringAsArray[0]);
    let result: Record<string, any> = {};
    if (isUrlContent) {
      const [url] = stringAsArray[0];
      result = { name: '', group_name: '', url: stringAsArray[0] };
    } else {
      const [name, group_name, url, latitude, longitude, azimuth, angle_of_view, address] = stringAsArray;
      result = {
        name,
        group_name,
        url,
        latitude: parseNumberOrEmpty(latitude),
        longitude: parseNumberOrEmpty(longitude),
        azimuth: parseNumberOrEmpty(azimuth),
        angle_of_view: parseNumberOrEmpty(angle_of_view),
        address
      };
    }
    return result;
  }

  get computedDevices(): IDataSourceDevice[] {
    return this.textAsArray.reduce((acc: any[], item: string) => {
      if (item.length === 0) return acc;
      const { name, group_name, url, latitude, longitude, azimuth, angle_of_view, address } = this.parseString(item);
      return [
        ...acc,
        {
          name,
          group: group_name ? this.cameraGroupByName(group_name) : undefined,
          url,
          stream_settings: {
            detectors: {}
          },
          latitude,
          longitude,
          azimuth,
          angle_of_view,
          address
        }
      ];
    }, []);
  }

  next() {
    this.$emit('nextStep', this.computedDevices);
  }

  selectFile(files: File[]) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.content = e.target?.result as string;
    };
    reader.readAsText(file);
  }

  saveSampleCSV() {
    window.open('ui-static/sample.csv');
  }
}

function parseNumberOrEmpty(value: string) {
  return value ? Number(value) : null;
}
