
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NCounter, { NCounterType } from '../counters/NCounter.vue';
import NIcon from '../icons/NIcon.vue';
import NLoadingCircle from '../loading/NLoadingCircle.vue';
import NLoadingDots from '../loading/NLoadingDots.vue';
import { NLoaderTypes } from '../loading/types';
import { NButtonIconSize, NButtonIconSizes, NButtonLoader, NButtonLoaders, NButtonSize, NButtonSizes, NButtonType, NButtonTypes } from './types';

@Options({
  name: 'NButton',
  components: { NCounter, NIcon, NLoadingCircle, NLoadingDots }
})
export default class NButton extends Vue {
  @Prop({ type: String, default: 'button' })
  readonly tag!: string;

  @Prop({ type: String, default: NButtonTypes.Primary })
  readonly type!: NButtonType;

  @Prop({ type: String, default: 'center' })
  readonly align!: string;

  @Prop({ type: String, default: 'button' })
  readonly nativeType!: string;

  @Prop({ type: String, default: NButtonSizes.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: String, default: '' })
  readonly label?: string;

  @Prop({ type: String })
  readonly color?: string;

  @Prop({ type: [String, Number], default: NButtonIconSizes.Normal })
  readonly iconSize!: NButtonIconSize;

  @Prop({ type: Boolean, default: false })
  readonly selected!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiline!: false;

  @Prop({ type: String })
  readonly icon?: string;

  @Prop({ type: String })
  readonly suffixIcon?: string;

  @Prop({ type: [Number, String] })
  readonly counter?: number | string;

  @Prop({ type: Boolean, default: false })
  readonly round!: false;

  @Prop({ type: Boolean, default: false })
  readonly transparent!: false;

  @Prop({ type: Boolean, default: false })
  readonly loading!: false;

  @Prop({ type: String, default: NButtonLoaders.Dots })
  readonly loader!: NButtonLoader;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly widen!: false;

  @Prop({ type: String })
  readonly dataQa?: string;

  get colorStyle() {
    return {
      'background-color': this.color
    };
  }

  get loaderComponent() {
    return this.loader === NButtonLoaders.Dots ? 'NLoadingDots' : 'NLoadingCircle';
  }

  get loaderType() {
    switch (this.type) {
      case NButtonTypes.Link:
      case NButtonTypes.Text:
      case NButtonTypes.Secondary:
      case NButtonTypes.SecondaryAlt:
        return NLoaderTypes.Primary;
      default:
        return NLoaderTypes.Secondary;
    }
  }

  get labelClasses() {
    return {
      'n-button__label': true,
      'n-button__label_pr': this.extraPaddingsAllowed && this.labelHasExtraPaddingRight,
      'n-button__label_pl': this.extraPaddingsAllowed && this.labelHasExtraPaddingLeft
    };
  }

  get extraPaddingsAllowed() {
    return !([NButtonTypes.Link, NButtonTypes.Text] as NButtonType[]).includes(this.type);
  }

  get labelHasExtraPaddingRight() {
    return this.counter === undefined && !this.suffixIcon;
  }

  get labelHasExtraPaddingLeft() {
    return !this.color && !this.icon;
  }

  get labelClassMap() {
    return {
      [NButtonSizes.Big]: 'l',
      [NButtonSizes.Normal]: 'm',
      [NButtonSizes.Small]: 's'
    };
  }

  get counterType() {
    switch (this.type) {
      case NButtonTypes.Link:
      case NButtonTypes.Text:
      case NButtonTypes.Secondary:
      case NButtonTypes.SecondaryAlt:
      case NButtonTypes.Outline:
        return NCounterType.Primary;
      default:
        return NCounterType.Secondary;
    }
  }

  get counterEnabled() {
    return this.counter !== undefined;
  }

  get labelSizeClassPrefix() {
    switch (this.type) {
      case NButtonTypes.Link:
      case NButtonTypes.Text:
        return 'label';
      case NButtonTypes.Secondary:
      case NButtonTypes.SecondaryAlt:
      default:
        return 'control';
    }
  }

  get classes() {
    const root = 'n-button';
    const labelSizeClass = `${this.labelSizeClassPrefix}-${this.labelClassMap[this.size || NButtonSizes.Normal]}`;

    return {
      [root]: true,
      [`${root}_round`]: this.round,
      [`${root}_align-${this.align}`]: true,
      [`${root}_multiline`]: this.multiline,
      [`${root}_disabled`]: this.disabled,
      [`${root}_loading`]: this.loading,
      [`${root}__size_${this.size}`]: this.size,
      [`${root}__type_${this.type}`]: this.type,
      [`${root}_selected`]: this.selected,
      [`${root}_transparent`]: this.transparent,
      [`${root}_widen`]: this.widen,
      [labelSizeClass]: this.size
    };
  }

  clickHandler() {
    if (this.disabled || this.loading) return;
    this.$emit('action');
  }
}
