import { FormValidatorNames, IFormContext } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NText from '@/uikit/text/NText.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import { formatDate, formatDateTime, shortString } from '@/common/filters';
import ParticipantRole from '@/components/participants/sidebar/ParticipantRole.vue';
import CaseClusterAcknowledgment from '@/pages/cases/case-clusters/form/CaseClusterAcknowledgment.vue';

export const informationLayout1 = [
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'cases.participant_info' }
  },
  [
    {
      path: 'name',
      classes: 'n-form-w-4 n-form-pad-10',
      i18n_label: 'cases.name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      path: 'role',
      classes: 'n-form-w-2 n-form-pad-10',
      i18n_label: 'cases.type',
      component: ParticipantRole,
      validators: [{ name: FormValidatorNames.Required }]
    }
  ],
  {
    path: 'comment',
    classes: 'n-form-w-6 n-form-pad-10',
    i18n_label: 'cases.comment',
    component: NInput
  },
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'cases.case_info' }
  },
  [
    {
      classes: 'n-form-w-6 n-form-pad-10 n-form-label-horizontal-100 n-form-label-primary-400',
      i18n_label: 'cases.case_name',
      component: NText,
      props: function (this: IFormContext) {
        return { modelValue: shortString(this.state?.case?.name, 36) };
      }
    },
    {
      classes: 'n-form-w-6  n-form-pad-10 n-form-label-horizontal-100 n-form-label-primary-400',
      i18n_label: 'cases.case_id',
      component: NText,
      props: function (this: IFormContext) {
        return { modelValue: this.state?.case?.id };
      }
    }
  ],
  [
    {
      classes: 'n-form-w-6 n-form-pad-10 n-form-label-horizontal-100 n-form-label-primary-400',
      i18n_label: 'cases.date_incident',
      component: NText,
      props: function (this: IFormContext) {
        return { modelValue: formatDate(this.state?.case?.incident_date) };
      }
    },
    {
      classes: 'n-form-w-6 n-form-pad-10 n-form-label-horizontal-100 n-form-label-primary-400',
      i18n_label: 'cases.record_date',
      component: NText,
      props: function (this: IFormContext) {
        return { modelValue: formatDate(this.state?.case?.record_created_date) };
      }
    }
  ]
];

export const informationLayout2 = [
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'cases.acknowledgment' }
  },
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NText,
    props: { i18n_label: 'Acknowledgment' }
  },
  {
    classes: 'n-form-w-6 n-form-pad-10 n-form-label-horizontal-100',
    component: CaseClusterAcknowledgment,
    props: function (this: IFormContext) {
      return { modelValue: this.state?.caseCluster };
    }
  }
];
