
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WatchList } from '@/api';
import WatchListItem from '@/components/common/WatchListItem.vue';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';

@Options({
  components: { WatchListItem }
})
export default class WatchListTableItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: WatchList;

  get currentMonitoringRecord() {
    const id = this.item.id;
    return monitoringModule.watchListInRemoteMonitoring[id] ? monitoringModule.remoteMonitoringItemsByWatchList[id]?.[0] : null;
  }

  mounted() {
    if (monitoringModule.isPuppeteerEnabled) monitoringModule.getItemsByWatchList(this.item.id);
  }

  handleClick() {
    this.$emit('update:modelValue', this.item.name);
  }
}
