export default {
  search_results_updated: 'Результаты поиска обновлены',
  search_reason: 'Причина поиска',
  enter_reason_for_search: 'Введите причину поиска',
  confidence_edge: 'Порог срабатывания',
  interval: 'Интервал',
  results_limit: 'Количество результатов',
  active_until: 'Активен до',
  done: 'Выполнить',
  save_result: 'Сохранить результаты',
  in_total: 'всего',
  event_mf: '{ count, plural, =0 {0 событий} one {# событие} few {# события} other {# событий} }',
  unmatched: 'нет совпадений',
  back: 'назад'
};
