
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NInputInterval } from '@/uikit';
import NSelect from '@/uikit/select/NSelect.vue';

const MaxValue = 100;

@Options({
  name: 'NFilterAgeRange',
  components: {
    NInputInterval,
    NSelect
  }
})
export default class NFilterAgeRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Number, default: MaxValue })
  maxValue!: number;

  @Prop({ type: Boolean })
  plain?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  get minItems() {
    return Array(this.maxValue)
      .fill(0)
      .map((v, index) => {
        return { label: String(index + 1), value: index + 1 };
      });
  }

  get ageGte() {
    return this.model.age_gte;
  }

  get ageLte() {
    return this.model.age_lte;
  }

  get maxItems() {
    const arrayLength = this.ageGte ? this.minItems.length - this.ageGte : this.maxValue;
    return Array(arrayLength)
      .fill(0)
      .map((v, index) => {
        if (this.ageGte) {
          return { label: String(this.ageGte + index + 1), value: this.ageGte + index + 1 };
        }
        return { label: String(index + 1), value: index + 1 };
      });
  }

  changeHandlerGte(age: any) {
    Object.assign(this.model, { age_gte: age });
  }
  changeHandlerLte(age: any) {
    Object.assign(this.model, { age_lte: age });
  }
}
