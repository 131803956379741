import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "event-marker__main-container"
}
const _hoisted_2 = ["width", "height", "viewBox"]
const _hoisted_3 = ["r"]
const _hoisted_4 = ["data-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LIcon = _resolveComponent("LIcon")!
  const _component_LMarker = _resolveComponent("LMarker")!

  return (_openBlock(), _createBlock(_component_LMarker, {
    "lat-lng": _ctx.latLng,
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LIcon, {
        "class-name": "event-marker__icon-wrapper",
        "icon-size": [this.size + 2, this.size + 2],
        "icon-anchor": _ctx.center
      }, {
        default: _withCtx(() => [
          (_ctx.isNew)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: "event-marker__main-animated-circle",
                  style: _normalizeStyle({ borderColor: _ctx.itemColors.borderColor })
                }, null, 4)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(_ctx.iconClass),
            style: _normalizeStyle(_ctx.iconSvgStyle),
            width: _ctx.size,
            height: _ctx.size,
            viewBox: `0 0 ${_ctx.size + 2} ${_ctx.size + 2}`,
            fill: "none"
          }, [
            _createElementVNode("circle", {
              class: "event-marker__main-circle",
              cx: "50%",
              cy: "50%",
              r: _ctx.size / 2,
              style: _normalizeStyle({ fill: _ctx.itemColors.fillColor })
            }, null, 12, _hoisted_3),
            _createElementVNode("circle", {
              class: _normalizeClass(_ctx.openedCircleClass),
              cx: "50%",
              cy: "50%",
              r: "10"
            }, null, 2),
            _createElementVNode("circle", {
              class: "event-marker__transparent-circle",
              cx: "50%",
              cy: "50%",
              r: "15",
              "data-id": _ctx.clickTargetDataId
            }, null, 8, _hoisted_4)
          ], 14, _hoisted_2))
        ]),
        _: 1
      }, 8, ["icon-size", "icon-anchor"])
    ]),
    _: 1
  }, 8, ["lat-lng", "onClick"]))
}