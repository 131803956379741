
import { Options, Vue } from 'vue-class-component';
import { LicenseExtraMap, LicenseResourcesMap } from '@/pages/license_v1/types';
import { Prop } from 'vue-property-decorator';
import LicenseTag from '@/pages/license_v1/components/common/LicenseTag.vue';

@Options({
  components: { LicenseTag }
})
export default class LicenseExtraContent extends Vue {
  @Prop({ required: true })
  itemsMap!: LicenseExtraMap;

  get items() {
    return Object.keys(this.itemsMap);
  }

  getLabel(name: string) {
    const prefix = 'license.extra.';
    let result = this.$t(prefix + name, 'f');
    return result.indexOf(prefix) === -1 ? result : name;
  }

}
