
import { Options, Vue } from 'vue-class-component';
import { DefaultItemAclResult } from '@/store/acl/types';
import { applicationModule } from '@/store/application';
import { multisidebarModule } from '@/store/multisidebar';
import NButton from '@/uikit/buttons/NButton.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import MultisidebarContentDebug from '@/components/multisidebar/MultisidebarContentDebug.vue';
import MultisidebarContentNotFound from './MultisidebarContentNotFound.vue';

const HttpNotFound = 404;

@Options({
  name: 'MultisidebarContent',
  components: { MultisidebarContentDebug, MultisidebarContentNotFound, NButton, NLoadingCircle }
})
export default class MultisidebarContent extends Vue {
  get module() {
    return multisidebarModule;
  }

  get dataQa() {
    return 'msb-' + this.currentItem?.type;
  }

  get hasChanges() {
    return this.currentItem?.model.hasChanges;
  }

  get currentItem() {
    return this.module.currentItem;
  }

  get currentItemConfig() {
    return this.module.currentItemConfig;
  }

  get currentSharedState() {
    return this.module.currentSharedState;
  }

  get modelAcl() {
    return this.currentItem && this.currentItemConfig?.getModelAcl(this.currentItem);
  }

  get itemAcl() {
    if (this.currentItem && this.modelAcl && this.currentItemConfig?.getItemAcl) {
      return this.currentItemConfig.getItemAcl(this.currentItem, this.modelAcl);
    }
    return { ...DefaultItemAclResult };
  }

  get debugMode() {
    const { debug, presaleDebug } = applicationModule.settings;
    return debug || presaleDebug;
  }

  get ready() {
    const { loaded, loading } = this.currentItem?.model || {};
    return (loaded || !loading) && !this.notFound;
  }

  get notFound() {
    return this.currentItem?.model.loadError?.response?.status === HttpNotFound || !this.itemAcl?.view;
  }
}
