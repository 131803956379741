
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { Case, CaseStatusEnum } from '@/api';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { convertToMsbItem } from '@/store/data/ActionHandler';
import { dialogModule } from '@/store/dialogs/dialogModule';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import { NLoadingCircle } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import EmptyPage from '@/components/common/EmptyPage.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import Statistics from '@/components/common/Statistics.vue';
import Permissions from '@/components/permissions/Permissions.vue';
import { columns } from '@/pages/cases/common/case-table-schema.tsx';
import EditCasePage from '@/pages/cases/EditCasePage.vue';
import { getCaseFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import ListPage from '@/pages/ListPage.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { dataServiceRepository } from '@/api/common';
import { getDelay } from '@/definitions/common/base';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { PageViewModel } from '@/store/application/page.view.model';
import { CasesFilter } from '@/api/models/CasesFilter';
import SortDropdown from '@/components/common/SortDropdown.vue';

const CaseActions: Record<string, string> = {
  Archive: 'archive',
  Dearchive: 'dearchive'
} as const;

type CaseAction = typeof CaseActions[keyof typeof CaseActions];

@Options({
  components: {
    EditCasePage,
    EmptyPage,
    FilterSection,
    ListPage,
    NButton,
    NButtonGroup,
    NForm,
    NInput,
    NLoadingCircle,
    NTable,
    PageContentLayout,
    Permissions,
    Statistics,
    SortDropdown
  }
})
export default class CasesPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;
  selectedItemIds: number[] = [];
  newCaseCreating = false;
  totalCount = 0;
  archivedCount = 0;
  openCount = 0;
  _pageViewModel!: PageViewModel<Case, CasesFilter>;
  
  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get dataQa(): string {
    return this._pageViewModel?.dataQa || 'undefined';
  }

  get largeFilterLayout() {
    const options = { small: false };
    return getCaseFiltersBuilder(options);
  }

  get smallFilterLayout() {
    const options = { small: true };
    return getCaseFiltersBuilder(options);
  }

  get columns() {
    return columns;
  }

  get state(): PageState {
    const tab = this.tab;
    const state = pageModule.getPageStateByTab(PagePaths.Cases, tab);
    return state;
  }

  get pageViewModel() {
    this._pageViewModel = this._pageViewModel || new PageViewModel({ tab: this.tab, path: PagePaths.Cases, pageState: this.state });
    return this._pageViewModel;
  }

  get module() {
    const module = this.pageViewModel.module;
    module.aclModelName = 'case';
    return module as ListViewModel<Case, CasesFilter>;
  }

  get selectedItems() {
    return this.module.items.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get showNewCase() {
    return !this.newCaseCreating && !this.module.items.length && !this.module.filter.current?.status && !this.module.filter.current?.name_contains;
  }

  get selectedItemsContainOpen() {
    return !!this.selectedItems.find((e) => e.status === CaseStatusEnum.Open);
  }

  get selectedItemsContainArchive() {
    return !!this.selectedItems.find((e) => e.status === CaseStatusEnum.Archived);
  }

  get statusFilterItems() {
    const items = [
      { name: undefined, i18n_label: 'cases.filter_all', counter: this.totalCount },
      { name: 'open', i18n_label: 'cases.filter_open', counter: this.openCount },
      { name: 'archived', i18n_label: 'cases.filter_archived', counter: this.archivedCount }
    ];
    return items.map((v) => ({ name: v.name, label: this.$t(v.i18n_label, 'f'), counter: v.counter }));
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(c: any, p: any) {
    console.log('Filter ', JSON.stringify(c), p);
    this.module.debouncedGet();
    this.state.filter = this.module.filter.currentClean;
  }

  @Watch('module.items')
  @Watch('module.loaded')
  itemsCountHandler() {
    if (!this.module.items.length && !this.module.filter.hasChanges) this.state.showOverlay = true;
    else if (this.module.items.length) this.state.showOverlay = false;
  }

  async mounted() {
    autoUpdateHelper.addListInstance(this.module);
    await this.module.get();
  }

  addNew() {
    this.state.showOverlay = true;
    this.newCaseCreating = true;
  }

  async saveNew(item?: Case) {
    await this.module.get({ resetState: false });
    this.resetNewItem();
    if (item) RouterModule.navigateToCase(item.id);
  }

  resetNewItem() {
    this.newCaseCreating = false;
    this.state.showOverlay = false;
  }

  cellClickHandler(row: Case, cell: ITableCell) {
    if (cell.path === 'name') RouterModule.navigateToCase(row.id);
    else if (cell.path === 'created_by') RouterModule.navigateToUser(row?.created_by ?? 0);
  }

  async updateStatusOfSelectedItems(v: CaseAction) {
    let results = [];
    for (const item of this.selectedItems) {
      let result = this.module.dataService.createItemSomethingByAction(item.id, v);
      results.push(result);
    }
    await Promise.any(results);
  }

  async openAll() {
    await this.updateStatusOfSelectedItems(CaseActions.Dearchive);
    this.syncAll();
  }

  async archiveAll() {
    await this.updateStatusOfSelectedItems(CaseActions.Archive);
    this.syncAll();
  }

  async syncAll() {
    await this.module.get({ resetState: false });
  }

  async deleteAll() {
    const msbItem = await convertToMsbItem({ type: MultisidebarItemTypes.Cases, rawItem: this.selectedItems[0] });
    const isActionAllowedByUser = await dialogModule.createDialog(ItemsActionNames.DeleteAll, msbItem);
    if (!isActionAllowedByUser) return;
    for (let item of this.selectedItems) {
      await this.module.delete(item.id);
    }
    this.syncAll();
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  computeRowClass(value: any, index: number) {
    const item: Case = value[0]?.row;
    return item.status !== CaseStatusEnum.Open ? 'opacity50' : '';
  }

  handleTableSort(columnName: string) {
    if (columnName === 'name') {
      this.module.filter.current.ordering = this.module.filter.current.ordering === columnName ? `-${columnName}` : columnName;
      this.module.get();
    }
  }

  @Watch('module.loading')
  loadStatistic(loading = false) {
    if (loading) return;
    this.loadTotalCasesCount();
    this.loadArchivedCasesCount();
    this.loadOpenCasesCount();
  }
  async loadTotalCasesCount() {
    const result = await dataServiceRepository.CasesService.getStatistics({ limit: '1' });
    this.totalCount = result.count;
  }

  async loadArchivedCasesCount() {
    const result = await dataServiceRepository.CasesService.getStatistics({ status: CaseStatusEnum.Archived });
    this.archivedCount = result.count;
  }

  async loadOpenCasesCount() {
    const result = await dataServiceRepository.CasesService.getStatistics({ status: CaseStatusEnum.Open });
    this.openCount = result.count;
  }
}
