import { CustomReportFormItem } from '@/pages/custom-reports/types';
import { IFormLayoutItem, FormValidatorNames } from '@/uikit/forms/NForm.vue';
import {
  configCheckbox,
  configDateTime, configInput, configSelect,
  SimpleFilterOptions
} from '@/components/common/filter/filters/types';
import { MetaFieldTypes } from '@/store/cards/CardAssetsModule';
import { dataModule } from '@/store/data';
import { mapObjectToSelectItem } from '@/common/utilsFilterSchema';

export class CustomReportForm {

  static computeSchema(schema: CustomReportFormItem[]) {
    return schema.map(CustomReportForm.adaptReportItemToLayoutItem).filter((v) => !!v);
  }

  private static adaptReportItemToLayoutItem(item: CustomReportFormItem): IFormLayoutItem | undefined {
    const options: SimpleFilterOptions = {
      classes: 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right',
      path: item.name,
      label: item.label,
      multiple: item.multiple,
      small: false
    };

    let result: IFormLayoutItem | undefined;

    switch (item.type) {
      case MetaFieldTypes.Boolean:
        result = configCheckbox(options);
        break;

      case MetaFieldTypes.Date:
        result = configDateTime(options, false);
        break;

      case MetaFieldTypes.Datetime:
        result = configDateTime(options, true);
        break;

      case MetaFieldTypes.List:
        options.items = CustomReportForm.getListItems(item);
        result = configSelect(options);
        break;

      case MetaFieldTypes.ValueList:
        options.items = CustomReportForm.getListItems(item);
        result = configSelect(options);
        break;

      case MetaFieldTypes.ObjectList:
        console.warn('MetaFieldTypes.ObjectList is deprecated. Item details: ', item);
        break;

      default:
        result = configInput(options);
        break;
    }

    if (item['data.provider']) {
      const provider = item['data.provider'];
      let items;
      switch (provider) {
        case 'watch-lists':
          items = mapObjectToSelectItem(dataModule.watchListsModule.items);
          break;
        case 'camera-groups':
          items = mapObjectToSelectItem(dataModule.cameraGroupsModule.items);
          break;
        case 'cameras':
          items = mapObjectToSelectItem(dataModule.camerasModule.items);
          break;
        default:
          console.warn(`Custom report form error ${provider} provider is not supported.`);
      }
      result!.props!.items = items;
    }

    if (item.required) {
      result!.validators = [{ name: FormValidatorNames.Required }];
    }

    return result;
  }

  private static getListItems(item: CustomReportFormItem): any[] {
    const storedItems = item.items || [];
    return storedItems.map((v: any) => {
      const isStringOrNumberValue = typeof v === 'string' || typeof v === 'number';
      return isStringOrNumberValue ? { value: v, label: v } : { value: v.value, label: v.label };
    });
  }
}
