
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { AddNewPayload } from '@/definitions/app/types';
import { ListViewModel } from '@/definitions/view-models';
import { Case, CasePhoto, CaseStatusEnum } from '@/api';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { CasePhotosFilter } from '@/api/models/CasePhotosFilter';
import { CasePhotosService } from '@/api/services/CasePhotosService';
import { aclModule } from '@/store/acl';
import { AclResult, DefaultModelAclResult, ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NLoadingCircle } from '@/uikit';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import notify from '@/uikit/notification/helpers/notification';
import ImageConverter from '@/common/image-converter';
import EmptyPage from '@/components/common/EmptyPage.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import AddNewItemsModal from '@/components/common/modals/AddNewItemsModal.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import AddNewWizard from '@/pages/data-sources/add-devices/AddNewWizard.vue';
import DataSourcesPhotosTable from '@/pages/data-sources/DataSourcesPhotosTable.vue';

@Options({
  name: 'CasePhotos',
  components: {
    AddNewItemsModal,
    AddNewWizard,
    DataSourcesPhotosTable,
    EmptyPage,
    InfiniteScroll,
    NBaseBar,
    NButton,
    NLoadingCircle,
    SortDropdown,
    Statistics
  }
})
export default class CasePhotos extends Vue {
  @Prop({ required: false })
  readonly item!: Case;

  @Prop({ required: true })
  readonly caseAcl!: ItemAclResult;

  addNewDialogVisisble: boolean = false;
  _module?: ListViewModel<CasePhoto, CasePhotosFilter>;

  get isCaseArchived() {
    return this.item.status !== CaseStatusEnum.Open;
  }

  get modelAcl() {
    const currentPageModelAcl = aclModule.maxAcl;
    const caseAclToVideoAcl: ModelAclResult = { view: this.caseAcl.view, update: this.caseAcl.update, add: this.caseAcl.update, delete: this.caseAcl.update };
    const archiveAcl: Partial<ModelAclResult> | null = this.isCaseArchived ? { add: false, update: false, delete: false } : {};
    const result = aclModule.mergeMultipleAcl(currentPageModelAcl, archiveAcl, caseAclToVideoAcl);
    return result;
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get module() {
    const result = viewModelRepository.getCasePhotosListViewModel();
    result.aclModelName = 'casephoto';
    result.filter.current.limit = '20';
    (result.filter.current as any).case_in = this.item?.id;
    autoUpdateHelper.addListInstance(result);
    this._module = result;
    return this._module;
  }

  get sidebarType() {
    return MultisidebarItemTypes.CasePhotos;
  }

  get sidebarSelectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === this.sidebarType).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.debouncedGet();
  }

  async mounted() {
    await this.module.get();
    if (this.isCaseArchived) {
      this.sidebarModule.disabledActions = true;
    }
  }

  async addNewHandler(payload: AddNewPayload) {
    const items = payload.attachments;
    for (let item of items) {
      const data = {
        case: this.item.id,
        fullframe: item.file as Blob
      };
      try {
        const ic = new ImageConverter();
        data.fullframe = await ic.tryConvert(data.fullframe);
        const result = await CasePhotosService.casePhotosCreate(data);
        dataServiceRepository.CasePhotosService.createItemSomethingByAction(result.id, 'process')
          .catch((e) => {
            notify({ content: 'Error', showClose: true, type: 'error' });
          })
          .finally(() => {
            this.module.debouncedGet();
          });
      } catch (e) {
        await notify({ content: 'Error', showClose: true, type: 'error' });
      }
    }
    await autoUpdateHelper.updateHandler('/cases/', this.item, [], { remote: true });
    return;
  }

  handleSelect(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
      });
  }

  async displayAddNewModal() {
    this.addNewDialogVisisble = true;
  }

  async hideAddNewModal() {
    this.addNewDialogVisisble = false;
  }
}
