import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateTimeRange = _resolveComponent("NDateTimeRange")!

  return (_openBlock(), _createBlock(_component_NDateTimeRange, {
    modelValue: _ctx.dateAsArray,
    placeholders: _ctx.placeholders,
    "onUpdate:modelValue": _ctx.changeHandler,
    mode: _ctx.mode,
    plain: _ctx.plain,
    intervals: _ctx.intervals,
    clearable: _ctx.clearable,
    "clear-button": _ctx.clearButton,
    "data-qa": _ctx.dataQa
  }, null, 8, ["modelValue", "placeholders", "onUpdate:modelValue", "mode", "plain", "intervals", "clearable", "clear-button", "data-qa"]))
}