
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import EventItemFull from '@/components/events/EventItemFull.vue';
import EventItemShort from '@/components/events/EventItemShort.vue';

@Options({
  name: 'CardItemProxy',
  components: { CardItemRow, CardItemTile, EventItemFull, EventItemShort }
})
export default class CardItemProxy extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: Boolean, default: false })
  display!: boolean;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: Boolean, default: false })
  opened!: boolean;

  @Prop({ type: String, default: 'clusters' })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: Object })
  modelAcl!: any;

  @Prop({ type: Boolean, default: true })
  showThumbnail!: boolean;

  @Prop({ type: Boolean, default: true })
  showDate!: boolean;

  @Prop({ type: Boolean, default: true })
  showAttachments!: boolean;

  @Prop({ type: Boolean, default: true })
  showMetafields!: boolean;

  @Prop({ type: Boolean, default: true })
  showActions!: boolean;

  @Prop({ type: Boolean, default: false })
  showMatchedConnections!: boolean;

  @Prop({ type: Boolean, default: false })
  showRelatedConnections!: boolean;

  @Prop({ type: Boolean, default: false })
  showInteractions!: boolean;

  get isShort() {
    return this.displayType === 'short';
  }

  get classes() {
    const base = 'card-item-proxy';
    return {
      [base + '_' + this.displayType]: true
    };
  }
}
