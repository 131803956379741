import audit_logs from './audit_logs';
import cards from './cards';
import cases from './cases';
import clusters from './clusters';
import common from './common';
import counters from './counters';
import date from './date';
import ds from './data-sources';
import errors from './errors';
import events from './events';
import exceptions from './exceptions';
import external_search from './external-search';
import external_vms from './external-vms';
import features from './features';
import forms from './forms';
import kyc from './kyc';
import license from './license';
import lines from './lines';
import menu from './menu';
import parameters from './parameters';
import phrases from './phrases';
import remote_monitoring from './remote-monitoring';
import reports from './reports';
import search from './search';
import sessions from './sessions';
import settings from './settings';
import sorts from './sorts';
import texts from './texts';
import verify from './verify';
import video_player from './video-player';
import videos from './videos';
import videowall from './videowall';
import webhooks from './webhooks';
import annex_alarms from './annex-alarms';
import alerts from './alerts';

export default {
  audit_logs,
  cards,
  cases,
  clusters,
  common,
  counters,
  date,
  ds,
  errors,
  events,
  exceptions,
  external_search,
  external_vms,
  features,
  forms,
  kyc,
  license,
  lines,
  menu,
  parameters,
  phrases,
  remote_monitoring,
  reports,
  search,
  sessions,
  settings,
  sorts,
  texts,
  verify,
  video_player,
  videos,
  videowall,
  webhooks,
  alerts,
  annex_alarms,
  accept_language: 'ru-RU;q=0.9, en;q=0.6, *;q=0.5',
  documentation_language: 'ru'
};
