import { cloneDeep, merge } from 'lodash';
import { getViewModelByName } from '@/api/common';
import {
  CommonModel,
  ModelLoaderOptions,
  MultisidebarItem,
  MultisidebarItemAttributes,
  MultisidebarLocationState,
  MultisidebarRawItemPayload,
  MultisidebarRawItemsPayload
} from '@/store/multisidebar/types';
import { DefaultAclPrefix } from '@/definitions/common/base';
import { ItemViewModel } from '@/definitions/view-models';
import { BodyCluster, BodyEvent, CarCluster, CarEvent, CasePhoto, FaceCluster, FaceEvent } from '@/api';
import { CaseCluster } from '@/api/models/CaseCluster';
import { aclModule } from '@/store/acl';
import { EpisodeTypesMap, ObjectsType, ObjectsTypesMap } from '@/store/application/data.assets';
import { PageState, PageType, PageTypes } from '@/store/application/page.definitions';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';

const IdDelimiter = ':';
const TypeDelimiter = '_';

export function generateMultisidebarId(type: string, id: number | string) {
  return `${type}${IdDelimiter}${id}`;
}

export function parseMultisidebarId(id: string) {
  const [type, rawItem] = id.split(IdDelimiter);
  if ([type, rawItem].some((v) => v === undefined || v === null)) {
    throw new Error("Can't parse Multisidebar Id: " + id);
  }
  return { type, rawItem } as MultisidebarRawItemPayload;
}

export function parseMultisidebarType(type: MultisidebarItemType) {
  return type.split(TypeDelimiter) as [PageType, ObjectsType?];
}

export function itemViewModelWebsocketUpdateHandler(item: MultisidebarItem<ItemViewModel<any>>, property: string, data: Record<any, any>) {
  const model = item.model;
  if (data.id === model.item.id) {
    const changes = model.changedData;
    merge(model.originalItem, data); // data could be Partial<T>
    merge(model.item, data, changes);
  }
}

export async function itemViewModelLoader(
  type: MultisidebarItemType,
  id?: number | string,
  options?: ModelLoaderOptions
): Promise<MultisidebarItem<ItemViewModel<any>>>;
export async function itemViewModelLoader(
  type: MultisidebarItemType,
  rawItem?: any,
  options?: ModelLoaderOptions
): Promise<MultisidebarItem<ItemViewModel<any>>>;
export async function itemViewModelLoader(
  type: MultisidebarItemType,
  rawItemOrId: any,
  options?: ModelLoaderOptions
): Promise<MultisidebarItem<ItemViewModel<any>>> {
  const isItem = typeof rawItemOrId === 'object';
  const item = isItem && rawItemOrId;
  const id = !isItem && rawItemOrId;
  const model = getViewModelByName<any, any>(type) as ItemViewModel<any>;
  model.aclPrefix = options?.aclPrefix || DefaultAclPrefix;
  model.aclModelName = options?.aclModelName || '';

  if (item) {
    model.setItemsState(item);
  } else if (id && id < -1000) {
    const item = Object.assign(cloneDeep(model.emptyItem), { id });
    model.setItemsState(item);
  } else {
    model.item = { id };
    if (options?.immediate) {
      await model.get(id);
    }
  }

  return { id: generateMultisidebarId(type, model.item.id), type, model };
}

export function getMsbStateFromUrl(url: string) {
  const re = new RegExp('msbState=(.*?)(&|$)');
  const matches = re.exec(url);
  let msbState: MultisidebarLocationState = { currentId: '', ids: [] };
  if (matches?.[1]) {
    try {
      msbState = JSON.parse(decodeURIComponent(matches?.[1]));
    } catch (e) {
      console.warn("[multisidebar]: Can't parse msbState param from url:" + url);
    }
  }
  return msbState;
}

export function getPageStateByMsbType(type: MultisidebarItemType) {
  const [pageType, objectEpisodeCardType] = type.split(TypeDelimiter);
  if (!pageType) throw new Error("Can't get pageState from type: " + type);

  const pageState: Partial<PageState> = { pageType: pageType as PageType };
  switch (pageType) {
    case PageTypes.Case:
      pageState.pageType = PageTypes.CaseClusters;
      break;
    case PageTypes.Events:
    case PageTypes.Clusters:
      pageState.objectType = objectEpisodeCardType;
      pageState.episodeType = objectEpisodeCardType === ObjectsTypesMap.Cars ? EpisodeTypesMap.Cars : EpisodeTypesMap.Humans;
      break;
    case PageTypes.Episodes:
      pageState.episodeType = objectEpisodeCardType;
      pageState.objectType = objectEpisodeCardType === EpisodeTypesMap.Cars ? ObjectsTypesMap.Cars : ObjectsTypesMap.Faces;
      break;
    case PageTypes.Cards:
      pageState.cardType = objectEpisodeCardType;
      pageState.episodeType = objectEpisodeCardType === ObjectsTypesMap.Cars ? EpisodeTypesMap.Cars : EpisodeTypesMap.Humans;
      pageState.objectType = objectEpisodeCardType === EpisodeTypesMap.Cars ? ObjectsTypesMap.Cars : ObjectsTypesMap.Faces;
      break;
  }

  return pageState;
}

export function getModelAclByItemViewModel(item: MultisidebarItem<any>) {
  return aclModule.getModelAcl(item.model);
}

export async function eventAttributesLoader({ model }: MultisidebarItem<ItemViewModel<FaceEvent | BodyEvent | CarEvent>>): Promise<MultisidebarItemAttributes> {
  return {
    label: model.item?.id,
    thumbnailSrc: model.item?.thumbnail
  };
}

export async function casePhotoAttributesLoader({ model }: MultisidebarItem<ItemViewModel<CasePhoto>>): Promise<MultisidebarItemAttributes> {
  return {
    label: model.item?.name,
    thumbnailSrc: model.item?.thumbnail
  };
}

export async function clusterAttributesLoader({
  model
}: MultisidebarItem<ItemViewModel<FaceCluster | BodyCluster | CarCluster>>): Promise<MultisidebarItemAttributes> {
  return {
    label: model.item?.id as any as string,
    thumbnailSrc: (model.item?.best_event as any)?.thumbnail
  };
}

export async function caseClusterAttributesLoader({ model }: MultisidebarItem<ItemViewModel<CaseCluster>>): Promise<MultisidebarItemAttributes> {
  return {
    label: (model.item?.name as any as string) || (model.item?.id as any as string),
    thumbnailSrc: (model.item?.best_event as any)?.thumbnail
  };
}

export async function episodeAttributesLoader({ model }: MultisidebarItem<ItemViewModel<any>>): Promise<MultisidebarItemAttributes> {
  const episode = model.item;
  const best_event = episode.best_face_event || episode.best_body_event || episode.best_car_event;
  const last_event = episode.last_face_event || episode.last_body_event || episode.last_car_event;
  const matched_event = episode.matched_face_event || episode.matched_body_event || episode.matched_car_event;
  return {
    label: model.item.id,
    thumbnailSrc: best_event.thumbnail || last_event.thumbnail || matched_event.thumbnail
  };
}

export async function remoteMonitoringEventAttributesLoader({ model }: MultisidebarItem<ItemViewModel<RemoteMonitoringEvent>>): Promise<MultisidebarItemAttributes> {
  return {
    label: String(model.item?.external_face_id || model.item?.id),
    thumbnailSrc: model.item?.thumbnail
  };
}


export function isMultisidebarItem(item: any): item is MultisidebarItem<CommonModel<Record<string, any>>> {
  return typeof item.id !== 'undefined' && typeof item.type !== 'undefined' && typeof item.model !== 'undefined';
}

export function isMultisidebarRawItemPayload(item: any): item is MultisidebarRawItemPayload {
  return typeof item.type !== 'undefined' && typeof item.rawItem !== 'undefined';
}

export function isMultisidebarRawItemsPayload(item: any): item is MultisidebarRawItemsPayload {
  return typeof item.type !== 'undefined' && typeof item.rawItems !== 'undefined';
}

