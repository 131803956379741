
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NIcon } from '@/uikit';
import FilterAngleRange from '@/components/common/filter/controls/FilterAngleRange.vue';

@Options({
  components: {
    FilterAngleRange,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallAngleRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: String, required: true })
  readonly gteFieldName!: string;

  @Prop({ type: String, required: true })
  readonly lteFieldName!: string;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;
}
