
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import AnnexAlarmItem from '@/pages/annex/alarms/AnnexAlarmItem';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import AnnexAlarmAcknowledge from '@/pages/annex/alarms/AnnexAlarmAcknowledge.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import AnnexAlarmAcknowledgeInfo from '@/pages/annex/alarms/AnnexAlarmAcknowledgeInfo.vue';
import Confidence from '@/components/common/Confidence.vue';

@Options({
  name: 'AnnexAlarmItemTile',
  components: { Confidence, AnnexAlarmAcknowledgeInfo, NButton, CardItemTile, AnnexAlarmAcknowledge, NThumbnail }
})
export default class AnnexAlarmItemTile extends AnnexAlarmItem {}
