import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "event-item-full__reaction"
}
const _hoisted_2 = {
  key: 1,
  style: {"width":"0"}
}
const _hoisted_3 = { class: "event-item-full__content" }
const _hoisted_4 = { class: "event-item-full__main-content" }
const _hoisted_5 = { class: "event-item-full__information" }
const _hoisted_6 = { class: "event-item-full__date" }
const _hoisted_7 = { class: "event-item-full__info-wrap" }
const _hoisted_8 = { class: "event-item-full__attributes" }
const _hoisted_9 = { class: "event-item-full__matched-card" }
const _hoisted_10 = { class: "event-item-full__card-image" }
const _hoisted_11 = { class: "event-item-full__confidence" }
const _hoisted_12 = { class: "event-item-full__matched-card-meta" }
const _hoisted_13 = { class: "event-item-full__user heading-m" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "event-item-full__unknown"
}
const _hoisted_16 = {
  key: 0,
  class: "event-item-full__license-plate-number label-xs"
}
const _hoisted_17 = { class: "event-item-full__watch-lists" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Acknowledge = _resolveComponent("Acknowledge")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NHint = _resolveComponent("NHint")!
  const _component_CameraLabelsNew = _resolveComponent("CameraLabelsNew")!
  const _component_EventItemAttributeLineCross = _resolveComponent("EventItemAttributeLineCross")!
  const _component_Attributes = _resolveComponent("Attributes")!
  const _component_DoubleThumbnail = _resolveComponent("DoubleThumbnail")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['event-item-full label-m', { 'event-item-full_no-acknowledge': _ctx.hideAcknowledge }]),
    onClick: _cache[9] || (_cache[9] = (v) => _ctx.$emit('action', 'show-info'))
  }, [
    (!_ctx.hideAcknowledge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Acknowledge, {
            item: _ctx.item,
            onAction: _cache[0] || (_cache[0] = (...args) => _ctx.$emit('action', ...args)),
            direction: _ctx.verticalAcknowledgeDirection,
            disabled: !_ctx.acknowledgeAllowed
          }, null, 8, ["item", "direction", "disabled"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.eventItemContainerClasses)
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_NHint, {
              placement: "top",
              delayVisible: 100
            }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.make_copy', 'f') + ' "' + _ctx.eventView.id + '"'), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_NIcon, {
                  name: "id",
                  width: "12",
                  height: "14",
                  class: "event-item-full__icon",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.idClickHandler(_ctx.eventView.id)), ["stop"]))
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.date), 1)
          ]),
          _createVNode(_component_CameraLabelsNew, {
            class: "event-item-full__cameras",
            "camera-groups-ids": _ctx.eventView.camera_groups,
            "cameras-ids": _ctx.eventView.cameras,
            type: "text",
            separator: ","
          }, null, 8, ["camera-groups-ids", "cameras-ids"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Attributes, {
              items: _ctx.attributesItems,
              color: _ctx.attributesColor
            }, {
              [_ctx.extraAttributesSlot]: _withCtx(() => [
                (_ctx.hasLineCross)
                  ? (_openBlock(), _createBlock(_component_EventItemAttributeLineCross, {
                      key: 0,
                      item: _ctx.item,
                      classes: "label-2xs"
                    }, null, 8, ["item"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["items", "color"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.fullThumbnailsClasses)
          }, [
            (!!_ctx.licensePlate)
              ? (_openBlock(), _createBlock(_component_DoubleThumbnail, {
                  key: 0,
                  actions: _ctx.thumbnailActions,
                  defaultAction: _ctx.defaultAction,
                  "model-value": _ctx.thumbnail,
                  "optional-model-value": _ctx.licensePlate,
                  onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select'))),
                  onAction: _cache[3] || (_cache[3] = (v) => _ctx.$emit('action', v)),
                  onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["actions", "defaultAction", "model-value", "optional-model-value"]))
              : (_ctx.hasThumbnail)
                ? (_openBlock(), _createBlock(_component_NThumbnail, {
                    key: 1,
                    actions: _ctx.thumbnailActions,
                    defaultAction: _ctx.defaultAction,
                    "model-value": _ctx.thumbnail,
                    onSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('select'))),
                    onAction: _cache[6] || (_cache[6] = (v) => _ctx.$emit('action', v)),
                    onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
                  }, null, 8, ["actions", "defaultAction", "model-value"]))
                : _createCommentVNode("", true),
            (_ctx.isEpisode)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(_ctx.counterClasses)
                }, _toDisplayString(_ctx.item.events_count), 3))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ], 2),
    (_ctx.eventView.matched_card)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([_ctx.cardItemContainerClasses, "event-item-full__card-container"]),
          onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.navigateToCard && _ctx.navigateToCard(...args)), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.eventView.confidence)
                  ? (_openBlock(), _createBlock(_component_Confidence, {
                      key: 0,
                      value: _ctx.eventView.confidence,
                      objects: _ctx.objectsType,
                      direction: "vertical"
                    }, null, 8, ["value", "objects"]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_NThumbnail, {
                "model-value": _ctx.matchedObjectThumbnail,
                "not-found-image-label": _ctx.$t('errors.card.not_found'),
                onClick: _withModifiers(_ctx.showCardFullFrame, ["stop"])
              }, null, 8, ["model-value", "not-found-image-label", "onClick"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                (_ctx.eventView.matched_card)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.formattedMatchedCard), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('common.unknown', 'f')), 1))
              ]),
              (_ctx.licensePlateFromCard)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.licensePlateFromCard), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_WatchListsGroup, {
                  items: _ctx.eventView.matched_lists,
                  "text-color": _ctx.watchListsColor
                }, null, 8, ["items", "text-color"])
              ]),
              (_ctx.card && _ctx.card.comment)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "event-item-full__comment label-xs",
                    ref: "card_comment",
                    style: _normalizeStyle({ '-webkit-line-clamp': _ctx.computedCommentHeight() })
                  }, _toDisplayString(_ctx.card.comment), 5))
                : _createCommentVNode("", true),
              (_ctx.configAclModule.isCIBR() && _ctx.item.case)
                ? (_openBlock(), _createBlock(_component_NButton, {
                    key: 2,
                    icon: "cibr",
                    type: "text",
                    "icon-size": "16",
                    style: _normalizeStyle(_ctx.cardCibrButtonStyle),
                    onAction: _ctx.navigateToCase
                  }, null, 8, ["style", "onAction"]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}