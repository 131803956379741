export default {
  tags: 'Tags',
  status: 'Status',
  statuses: {
    all: 'All',
    new: 'New',
    in_work: 'Pending',
    completed: 'Completed'
  },
  statuses_one: {
    all: 'All',
    new: 'New',
    in_work: 'Pending',
    completed: 'Completed'
  },
  helper: {
    new: 'New',
    auto_accepted: 'Acknowledged automatically',
    accepted_rejected: 'Acknowledged/Rejected',
    in_work: 'Pending',
    comment: 'Comment',
    completed: 'Completed'
  },
  items_empty: 'You don\'t have new alarms',
  reaction: 'Reaction',
  reactions: {
    none: 'None',
    acknowledged: 'Acknowledged',
    rejected: 'Rejected'
  },
  features: {
    gender_v2: 'Gender',
    male: 'Male',
    female: 'Female'
  },
  comments: 'Comments',
  with_comments: 'With comments',
  without_comments: 'Without comments',
  reaction_time: 'Reaction time',
  detection_time: 'Detection time',
  author: 'Processed by',
  comment: 'Comment',
  add_comment: 'Add Comment',
  add_comment_and_complete: 'Add Comment & Complete',
  alarm_info: 'Alarm info',
  new_alarm: 'Alarm info',
  acknowledged: 'Acknowledged',
  rejected: 'Rejected',
  complete_with_comment: 'Add Comment & Complete',
  ack_status: {
    in_work: 'In Work with ',
    acknowledged: 'Completed by ',
    auto_acknowledged: 'It was acknowledged automatically'
  },
  info: 'Information',
  history: 'History',
  details: 'Details',
  acknowledge: 'Acknowledge',
  reject: 'Reject',
  acknowledge_all: 'Acknowledge All',
  new_items: 'New Items',
  auto_acknowledge_mf: '{ count, plural, one {# seconds left before Auto Acknowledge} other {# seconds left before Auto Acknowledge} } ',
  not_found: 'Not Found',
  system: 'system'
};
