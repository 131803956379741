import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import { dataModule } from '@/store/data';
import {lastOf} from "@/pages/reports/utils";
import LoginADForm from "@/pages/login/LoginADForm.vue";

export type AnnexAlarmSimpleStatus = 'new' | 'in_work' | 'completed' | 'unknown';
export type AnnexAlarmAcknowledgeStatus = 'none' | 'in_work' | 'cancel' | 'acknowledged' | 'auto_acknowledged';
export const AnnexAlarmImageTag = ['track', 'faces', 'bodies', 'cars'];

export interface AnnexAlarmAdapted {
  camera: any;
  cameraGroup: any;
  thumbnail: string | undefined;
  fullframe: string | undefined;
  track: any;
  status: AnnexAlarmSimpleStatus;
  acknowledge: AnnexAlarmAcknowledgeStatus;
  features: any;
  isHasCrop: boolean;
  bboxes: number[][];
}

function getCamera(item: AnnexAlarm) {
  const cameras = dataModule.camerasModule.items;
  return cameras.find((camera) => `camera#${camera.id}` === item.stream);
}

function getCameraGroup(item: AnnexAlarm) {
  const camera = getCamera(item);
  if (camera) {
    const groups = dataModule.cameraGroupsModule.items;
    return groups.find((group) => group.id === camera.group);
  }
  return undefined;
}

function getImageByTag(item: AnnexAlarm, tag: string): any {
  if (!item || !item.images) {
    console.warn('Error: Cant get image by tag ', tag, item);
    return '';
  }
  return item.images.find((image: any) => image.tag === tag);
}

function getImageUrlByTag(item: AnnexAlarm, tag: string): string | undefined {
  return getImageByTag(item, tag)?.path || '';
}

function getImageTrack(item: AnnexAlarm): any {
  const legends = getImageByTag(item, 'fullframe')?.legends;
  if (legends && Array.isArray(legends) && legends.length > 0 && legends.find((image: any) => image.tag === 'track')) {
    return legends.find((image: any) => image.tag === 'track').points;
  }
  return [];
}

function getImageBboxes(item:AnnexAlarm):any {
  const legends = getImageByTag(item, 'fullframe')?.legends;
  const result : any[] = [];
  if (legends && Array.isArray(legends) && legends.length > 0) {
    return legends.filter((legend: any) => AnnexAlarmImageTag.includes(legend.tag))
                  .map((legend) => legend.boxes).flat(1);
  }
  return result;
}

function getSimpleStatus(item: AnnexAlarm): AnnexAlarmSimpleStatus {
  if (item.ack_value === -1) {
    return 'new';
  }
  if (item.comment) {
    return 'completed';
  }
  return 'in_work';
}

function getAcknowledgeStatus(item: AnnexAlarm): AnnexAlarmAcknowledgeStatus {
  if (item.ack_value === -1) {
    return 'none';
  }

  if (item.ack_author) {
    if (item.comment) {
      return 'acknowledged';
    } else {
      if (item.ack_value === 1) {
        return 'in_work';
      }
      return 'cancel';
    }
  }

  return 'auto_acknowledged';
}

function adaptItem(item: AnnexAlarm): AnnexAlarmAdapted {
  const objectsReduce = item?.extra?.enrichment?.objects_reduce;
  let features = [];
  if (Array.isArray(objectsReduce)) {
    features = objectsReduce.map((object: any) => {
      return object.attributes?.face_gender || object.attributes?.other_feature_data;
    });
  }

  return {
    camera: getCamera(item),
    cameraGroup: getCameraGroup(item),
    thumbnail: getImageUrlByTag(item, 'normalized') || getImageUrlByTag(item, 'thumbnail') || getImageUrlByTag(item, 'fullframe'), // @todo Replace to "'thumbnail'" after backend fix
    fullframe: getImageUrlByTag(item, 'fullframe'),
    bboxes: getImageBboxes(item),
    track: getImageTrack(item),
    status: getSimpleStatus(item),
    acknowledge: getAcknowledgeStatus(item),
    features,
    isHasCrop: !!getImageUrlByTag(item, 'thumbnail'),
  };
}

export { adaptItem };
