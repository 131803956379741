
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { NButtonGroupItem, NButtonGroupType, NButtonGroupTypes, NButtonSize, NButtonSizes, NButtonType, NButtonTypes } from '@/uikit/buttons/types';
import NButton from './NButton.vue';

@Options({
  name: 'NButtonGroup',
  components: { NButton },
  emits: ['update:modelValue']
})
export default class NButtonGroup extends Vue {
  @Prop({ type: String, default: NButtonGroupTypes.Default })
  readonly type!: NButtonGroupType;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiline!: false;

  @Prop({ type: Boolean, default: false })
  readonly extraPaddings!: false;

  @Prop({ type: Boolean, default: false })
  readonly showAnyButton!: false;

  @Prop({ type: [String, Array], required: false })
  readonly modelValue?: string | number | string[] | number[];

  @Prop({ type: Array, required: true })
  readonly items!: NButtonGroupItem[];

  @Prop({ type: Boolean, default: false })
  readonly initValueOnEmpty!: boolean;

  @Prop({ type: String, default: NButtonTypes.Secondary })
  readonly buttonType!: NButtonType;

  @Prop({ type: String })
  dataQa?: string;

  @Prop({ type: String, default: NButtonSizes.Normal })
  readonly buttonSize!: NButtonSize;

  get model() {
    return !this.modelValue && this.multiple ? [] : this.modelValue;
  }

  get classes(): Record<string, boolean> {
    const base = 'n-button-group';
    return {
      [`${base}_${this.type}`]: true,
      [`${base}_multiline`]: this.multiline,
      [`${base}_extra-paddings`]: this.extraPaddings
    };
  }

  get canShowAnyButton() {
    return this.showAnyButton;
  }

  @Watch('items', { immediate: true, deep: true })
  handleItemsChange() {
    if (this.initValueOnEmpty && this.items.length && !this.items.find((v) => v.value === this.modelValue)) {
      this.selectHandler(this.items[0]);
    }
  }

  isAnySelected() {
    if (this.multiple) {
      if (this.model) {
        return (this.model as string[]).length === 0;
      }
    } else {
      return !this.model;
    }
    return false;
  }

  getSelected(item: NButtonGroupItem): boolean {
    let result;
    if (this.multiple && this.model && (this.model as any)?.findIndex) {
      let index = (this.model as unknown as string[])?.findIndex((v: string) => (item.value ?? item.name) === v);
      result = index > -1;
    } else {
      result = this.model === (item.value ?? item.name);
    }
    return result;
  }

  selectHandler(item: NButtonGroupItem): void {
    let result;

    if (this.multiple) {
      let items = [...(this.model as any[])];
      let index = items.findIndex((v: string) => item.value === v);
      index > -1 ? items.splice(index, 1) : items.push(item.value);
      result = items;
    } else {
      result = item.value ?? item.name;
    }
    item.handler && item?.handler();
    this.$emit('update:modelValue', result);
  }

  clearModel() {
    if (Array.isArray(this.modelValue)) this.$emit('update:modelValue', []);
    else this.$emit('update:modelValue', undefined);
  }
}
