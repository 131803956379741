import { NButton, NInput } from '@/uikit';
import NButtonFileSelect from '@/uikit/buttons/NButtonFileSelect.vue';
import { IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';

export const verifyFormLayout: IFormLayout = [
  [
    {
      classes: 'label-m n-form-w-full',
      i18n_label: 'verify.first_source',
      component: NInput,
      props: function (model: IFormModel) {
        return {
          clearable: true,
          modelValue: model[0].event_url,
          i18n_placeholder: 'verify.first_source_placeholder'
        };
      },
      on: {
        'update:modelValue'(this: any, value: any) {
          this.model[0].event_url = value;
        }
      }
    },
    {
      classes: 'label-m verify__form-button',
      component: NButtonFileSelect,
      props: () => ({ i18n_label: 'verify.upload', type: 'secondary', icon: 'upload' }),
      hidden: (model) => model[0].event_url !== '',
      on: {
        files(this: any, files: any) {
          this.form.$emit('changeFirstFile', files[0]);
        }
      }
    },
    {
      classes: 'label-m verify__form-button',
      component: NButton,
      props: () => ({ i18n_label: 'verify.load', type: 'primary' }),
      hidden: (model) => model[0].event_url === '',
      on: {
        click(this: any) {
          this.form.$emit('firstEventUrl', this.model[0].event_url);
        }
      }
    }
  ],
  [
    {
      classes: 'label-m n-form-w-full',
      i18n_label: 'verify.second_source',
      component: NInput,
      props: function (model: IFormModel) {
        return {
          clearable: true,
          modelValue: model[1].event_url,
          i18n_placeholder: 'verify.second_source_placeholder'
        };
      },
      on: {
        'update:modelValue'(this: any, value: any) {
          this.model[1].event_url = value;
        }
      }
    },
    {
      classes: 'label-m verify__form-button',
      component: NButtonFileSelect,
      props: () => ({ i18n_label: 'verify.upload', type: 'secondary', icon: 'upload' }),
      hidden: (model) => model[1].event_url !== '',
      on: {
        files(this: any, files: any) {
          this.form.$emit('changeSecondFile', files[0]);
        }
      }
    },
    {
      classes: 'label-m verify__form-button',
      component: NButton,
      props: () => ({ i18n_label: 'verify.load', type: 'primary' }),
      hidden: (model) => model[1].event_url === '',
      on: {
        click(this: any) {
          this.form.$emit('secondEventUrl', this.model[1].event_url);
        }
      }
    }
  ]
];
