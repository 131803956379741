import { configModule } from '@/store/config';
import { dataModule } from '@/store/data/index';
import { websocketModule, websocketPuppeteerModule } from '@/store/ws/websocket.module';
import { initAnnex } from '@/thirdpaties/annex/annex.module';

export async function postLoginDataLoad() {
  await dataModule.load();
  websocketModule.connect();
  initAnnex();
  configModule.config.plugins?.puppeteer && websocketPuppeteerModule.connect();
}
