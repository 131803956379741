import { get, set } from 'lodash';
import { UsersService } from '@/api';

export interface IStorageSyncItem {
  instance: any;
  tokens: string[];
  name: string;
}

export class UserStorageModule {
  items: IStorageSyncItem[] = [];
  storage: Record<string, any> | null = null;

  async getUserData() {
    const result = await UsersService.usersMeDataList();
    // console.log('Finish get user data', result);
    this.storage = result.reduce(((m, v) => { m[v.key] = v.value; return m;}), {} as Record<string, any>);
  }

  registerInstance(item: IStorageSyncItem, sync = true) {
    this.items.push(item);
    if (sync) this.syncFromStorage(item);
  }

  async syncToStorageByName(name: string) {
    // console.log('[UserStorage] Sync to storage ', name);
    const item = this.items.find((v) => v.name === name);
    if (item) await this.syncToStorage(item);
    else console.warn(`[syncToUserStorage] instance byName ${name} not found`);
  }

  syncFromStorageByName(name: string) {
    // console.log('[UserStorage] Sync from storage by name ', name);
    const item = this.items.find((v) => v.name === name);
    if (item) this.syncFromStorage(item);
    else console.warn(`[syncFromUserStorage] instance byName ${name} not found`);
  }

  async syncToStorage(item: IStorageSyncItem) {
    if (!this.storage) return console.warn('[syncToUserStorage] this.storage not found');
    // console.log('UserSync tokens', item.tokens);

    const r = item.tokens.reduce((m: Record<string, any>, v: string) => {
      m[v] = get(item.instance, v);
      return m;
    }, {});

    this.storage[item.name] = JSON.stringify(r);
    await UsersService.usersMeDataUpdate(item.name, { key: item.name, value: JSON.stringify(r) ?? '' });
  }

  syncFromStorage(item: IStorageSyncItem) {
    // console.log('[UserStorage] Sync from storage ', item.name, this.storage);
    if (!(this.storage && this.storage[item.name])) return console.warn('[syncToUserStorage] this.storage not found or not set');

    let storageObject = JSON.parse(this.storage[item.name]);
    Object.keys(storageObject).forEach((key: string) => {
      set(item.instance, key, storageObject[key]);
    });
  }

  syncAllFromStorage() {
    this.items.forEach((value) => this.syncFromStorage(value));
  }
}

export const userStorageModule = new UserStorageModule();
