export default {
  external_search: 'Remote search',
  add_to_monitoring: 'Turn on remote alerts',
  add_all_selected_to_monitoring: 'Turn on remote alerts for selected',
  remove_from_monitoring: 'Turn off remote alerts',
  remove_all_selected_from_monitoring: 'Turn off remote alerts for selected',
  monitoring_events: 'Remote alerts',
  monitoring: 'Remote alerting',
  monitoring_letter: 'R',
  monitoring_reason: 'Reason for remote alerting',
  monitoring_reason_placeholder: 'Specify the reason for remote alerting',
  acknowledge: 'acknowledge',
  acknowledge_all: 'Acknowledge all',
  unmatched: 'unmatched',
  new_events: 'New events',
  alert_text: 'All related remote monitoring alerts will be purged',
  agree: 'Agree',
  cancel: 'Cancel',
  source: 'Source',
  remote_monitoring_event: 'Monitoring event'
};
