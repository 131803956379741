
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { HumanCard } from '@/api';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { CaseCluster, EmptyCaseCluster } from '@/api/models/CaseCluster';
import { DefaultModelAclResult, ItemAclResult, ModelAclResult } from '@/store/acl/types';
import NButton from '@/uikit/buttons/NButton.vue';
import ScrollHorizontally from '@/uikit/directives/scroll-horizontally';
import NForm from '@/uikit/forms/NForm.vue';
import { ImageViewerActions } from '@/uikit/image-viewer/types';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { deleteById } from '@/common/deleteFromArray';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import CardItemSmall from '@/components/cards/CardItemSmall.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import ClusterWizardItem from '@/pages/cases/case-cluster-wizard/ClusterWizardItem.vue';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import CaseClusterItemRow from '@/pages/cases/case-clusters/CaseClusterItemRow.vue';
import CaseClusterForm from '@/pages/cases/case-clusters/form/CaseClusterForm.vue';
import CaseAclHelper from '@/pages/cases/CaseAclHelper';

@Options({
  name: 'ClusterWizard',
  components: {
    CardItemRow,
    CardItemSmall,
    CardItemTile,
    CaseClusterForm,
    CaseClusterItem,
    CaseClusterItemRow,
    ClusterItem,
    ClusterWizardItem,
    NButton,
    NForm,
    NModalWindow
  },
  directives: { ScrollHorizontally }
})
export default class ClusterWizard extends Vue {
  @Prop({ type: Object, required: true })
  readonly caseCluster!: CaseCluster;

  showFallback = false;
  showCreateForm = false;
  caseAcl: ItemAclResult | null = null;
  caseAclHelper = new CaseAclHelper();

  // sections
  readonly mergeCaseClustersModule = viewModelRepository.getCaseClustersListViewModel();
  readonly relatedCaseClustersModule = viewModelRepository.getCaseClustersListViewModel();
  readonly relatedHumanCardsModule = viewModelRepository.getCardsHumansListViewModel();

  // photo viewer
  readonly caseEventsModule = viewModelRepository.getCaseEventsListViewModel();

  mergedCaseClusters: CaseCluster[] = [];
  relatedCaseClusters: CaseCluster[] = [];
  relatedHumanCards: HumanCard[] = [];

  get hasCaseError() {
    return !!this.caseAclHelper?.case?.error;
  }

  get itemTitleText() {
    return this.caseCluster.role ? this.$t('cases.participant', 'f') : this.$t('cases.clusters');
  }

  get saveButtonText() {
    return this.caseCluster.role ? this.$t('cases.save_as_participant', 'f') : this.$t('cases.create_new_participant');
  }

  get mainClusterAndSelectedClusters() {
    return [this.caseCluster, ...this.mergedCaseClusters];
  }

  get currentCaseClusters() {
    return this.mergeCaseClustersModule.items.filter((item) => !this.mainClusterAndSelectedClusters.map((item) => item.id).includes(item.id));
  }

  get relatedCaseClustersIds() {
    return this.relatedCaseClusters.map((item) => item.id);
  }

  get allCaseClusters() {
    return this.relatedCaseClustersModule.items.filter((item) => !this.relatedCaseClustersIds.includes(item.id));
  }

  get allHumanCards() {
    return this.relatedHumanCardsModule.items.filter((item) => !this.relatedHumanCards.map((item) => item.id).includes(item.id));
  }

  get modelAcl(): ModelAclResult {
    return {
      view: this.caseAcl?.view,
      add: this.caseAcl?.update,
      update: this.caseAcl?.update,
      delete: this.caseAcl?.delete
    } as ModelAclResult;
  }

  async mounted() {
    // load case acl
    this.caseAcl = await this.caseAclHelper.getCaseAclByCaseId(this.caseCluster.case);
    if (this.caseAclHelper.isCaseArchived) {
      this.showFallback = true;
      return;
    }

    // load related case clusters
    if (this.caseCluster.related_case_clusters?.length) {
      this.relatedCaseClustersModule.filter.current.id_in = this.caseCluster.related_case_clusters.map((el) => el.id);
      await this.relatedCaseClustersModule.get();
      this.relatedCaseClusters = this.relatedCaseClustersModule.items;
      this.relatedCaseClustersModule.filter.current.id_in = [];
    }

    // load related human cards
    if (this.caseCluster.related_human_cards?.length) {
      this.relatedHumanCardsModule.filter.current.id_in = this.caseCluster.related_human_cards.map((el) => el.id);
      await this.relatedHumanCardsModule.get();
      this.relatedHumanCards = this.relatedHumanCardsModule.items;
      this.relatedHumanCardsModule.filter.current.id_in = [];
    }

    // load clusters in current case (for merge clusters)
    const clusterId = this.caseCluster.id;
    this.mergeCaseClustersModule.filter.current.limit = '50';
    this.mergeCaseClustersModule.filter.current.case_in = [this.caseCluster.case];
    this.mergeCaseClustersModule.filter.current.looks_like = `casecluster:${clusterId}`;
    this.mergeCaseClustersModule.filter.current.ordering = '-looks_like_confidence';
    this.mergeCaseClustersModule.filter.current.threshold = 0.5;
    this.mergeCaseClustersModule.filter.current.is_case_participant = false;
    await this.mergeCaseClustersModule.get();

    // load clusters in other cases
    this.relatedCaseClustersModule.filter.current.limit = '50';
    this.relatedCaseClustersModule.filter.current.looks_like = `casecluster:${clusterId}`;
    this.relatedCaseClustersModule.filter.current.ordering = '-looks_like_confidence';
    this.relatedCaseClustersModule.filter.current.case_not_in = [this.caseCluster.case];
    this.relatedCaseClustersModule.filter.current.threshold = 0.5;
    await this.relatedCaseClustersModule.get();

    // load card items
    this.relatedHumanCardsModule.filter.current.limit = '50';
    this.relatedHumanCardsModule.filter.current.looks_like = `casecluster:${clusterId}`;
    this.relatedHumanCardsModule.filter.current.ordering = '-looks_like_confidence';
    this.relatedHumanCardsModule.filter.current.threshold = 0.5;
    await this.relatedHumanCardsModule.get();
  }

  actionHandler(type: string, item: any) {
    switch (type) {
      case 'cluster_add':
        this.mergedCaseClusters.push(item);
        break;
      case 'cluster_close':
        deleteById(this.mergedCaseClusters, item.id);
        break;
      case 'related_case_cluster_add':
        this.relatedCaseClusters.push(item);
        break;
      case 'related_case_cluster_close':
        deleteById(this.relatedCaseClusters, item.id);
        break;
      case 'card_add':
        this.relatedHumanCards.push(item);
        break;
      case 'card_close':
        deleteById(this.relatedHumanCards, item.id);
        break;
    }
  }

  async mergeCaseClusters() {
    const { id } = this.caseCluster;
    const clusters = this.mergedCaseClusters.map((item) => item.id);
    if (clusters.length) {
      try {
        await dataServiceRepository.CaseClustersService.createItemSomethingByAction(id, 'merge', null, { clusters });
      } catch (e) {
        console.warn('[case-clusters] merge error: ', e);
      }
    }
  }

  getPrevCaseClusterConfidence(id: number) {
    const prevConfidence = this.caseCluster.related_case_clusters?.find((v) => v.id === id)?.confidence;
    return prevConfidence || 0;
  }

  getPrevHumanCardConfidence(id: number) {
    const prevConfidence = this.caseCluster.related_human_cards?.find((v) => v.id === id)?.confidence;
    return prevConfidence || 0;
  }

  async saveHandler(validateForm: boolean) {
    if (!this.modelAcl.update) {
      this.$emit('close');
    } else {
      try {
        if (validateForm) {
          if (!this.$refs.form.validate()) {
            return;
          }
        }

        await this.mergeCaseClusters();
        const model = viewModelRepository.getCaseClustersItemViewModel();
        this.caseCluster.related_case_clusters = this.relatedCaseClusters.map((item) => {
          return {
            id: item.id,
            confidence: item.looks_like?.confidence || this.getPrevCaseClusterConfidence(item.id)
          };
        });
        this.caseCluster.related_human_cards = this.relatedHumanCards.map((item) => {
          return {
            id: item.id,
            confidence: item.looks_like_confidence || this.getPrevHumanCardConfidence(item.id)
          };
        });
        model.setItemsState(this.caseCluster);
        await model.save();
        autoUpdateHelper.createHandler(model.name);
        autoUpdateHelper.updateHandler('/cases/', { id: model?.item?.case }, [], { remote: true });
        this.showCreateForm = false;
        this.$emit('close');
      } catch (e) {
        console.warn(e.toString());
      }
    }
  }

  async clusterItemAction(id: any) {
    this.caseEventsModule.filter.current.case_cluster_in = [id];
    await this.caseEventsModule.get();
    const viewerItems = this.caseEventsModule.items.map((v) => ({
      ...v,
      actions: this.modelAcl.update ? [ImageViewerActions.Exclude] : null,
      handlers: { exclude: () => this.excludeClusterEvent(v.id) },
      i18n: { exclude: this.$t('clusters.exclude_from_cluster') }
    }));
    this.$photoViewer.show(viewerItems);
  }

  async excludeClusterEvent(id: number) {
    const result = await dataServiceRepository.CaseEventsService.createItemSomethingByAction(id, 'separate');
    await this.mergeCaseClustersModule.get();
    await autoUpdateHelper.createHandler('/case-clusters/');
    return !!result;
  }
}
