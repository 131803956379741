import { CarCard, CardsService, HumanCard } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';

export default class MatchedCardHelper {
  type: string | undefined = '';
  card: HumanCard | CarCard | null = null;
  currentId: number | null = null;

  get id(): number | null {
    return this.currentId;
  }

  set id(id: number | null) {
    this.currentId = id;
    this.reloadCard();
  }

  get objectType() {
    if (this.type === 'face' || this.type === 'faces' || this.type === 'humans') {
      return 'faces';
    }
    if (this.type === 'body' || this.type === 'bodies') {
      return 'bodies';
    }
    if (this.type === 'car' || this.type === 'cars') {
      return 'cars';
    }
    return '';
  }

  async loadCard(id: number): Promise<HumanCard | CarCard | null> {
    let result = null;
    if (id) {
      const servicePromise = this.objectType === 'cars' ? CardsService.cardsCarsRetrieve(id) : CardsService.cardsHumansRetrieve(id);
      try {
        result = await (servicePromise as Promise<HumanCard | CarCard>);
      } catch (e) {
        console.warn('Load card error', e);
      }
    }
    return result;
  }

  async reloadCard() {
    if (this.currentId) {
      this.card = await this.loadCard(this.currentId);
    } else {
      this.card = null;
    }
  }

  showCard() {
    if (!this.card) {
      console.warn('showCard(), this.card is null');
      return;
    }
    actionHandler.run(ItemsActionNames.ShowItem, {
      type: this.objectType === 'cars' ? MultisidebarItemTypes.CardsCars : MultisidebarItemTypes.CardsHumans,
      rawItem: this.card
    });
  }

  get pageSidebarType() {
    return `cards__${this.type}` as MultisidebarItemType;
  }

  get isSelected() {
    if (this.card && this.card.id) {
      return !!multisidebarModule.getItem(this.pageSidebarType, this.card.id);
    }
    return false;
  }

  get isOpened() {
    if (this.card && this.card.id) {
      return multisidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, this.card.id);
    }
    return false;
  }
}
