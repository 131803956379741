import axios, { AxiosError, AxiosResponse } from 'axios';
import { merge } from 'lodash';
import { reactive } from 'vue';
import { getDelay } from '@/definitions/common/base';
import { IAuthConfig } from '@/definitions/config/auth';
import { ILanguagesConfig } from '@/definitions/config/languages';
import { IServerConfig } from '@/definitions/config/server';
import { IThemesConfig } from '@/definitions/config/theme';
import { applicationModule } from '@/store/application';
import { PageNames } from '@/store/application/page.definitions';
import { configAclModule } from '@/store/config/acl';
import { productConfigs, productDefaultTitles } from '@/store/config/product.configs';
import { languageModule } from '@/store/languages';
import { OverlayObjects } from '@/components/video-player/VideoPlayerOverlay.vue';
import { configLanguageModule } from './languages';
import { LatLngType } from '@/components/map/types';
import { Product } from '@/pages/license_v1/types';
import * as _ from 'lodash';
import { licenseModule } from './LicenseModule';

export type IConfig = {
  auth?: IAuthConfig | null;
  car_card?: any;
  detect_config?: any;
  dicts?: any;
  available_detect_features?: any;
  enable_acl?: boolean;
  external_vms?: any;
  extra_options?: string[];
  human_card?: any;
  language?: string;
  languages?: ILanguagesConfig | null;
  menu?: MenuConfig;
  objects?: any;
  plugins?: Record<string, boolean> | null;
  server?: IServerConfig | null;
  services?: any;
  themes?: IThemesConfig | null;
  title?: string;
  video_player?: any;
  vms?: any;
  counters?: any;
  custom_reports?: ConfigCustomReports[];
  annex?: {
    enabled: boolean;
    apiUrl: string;
    wsUrl: string;
    comments?: string[];
  };
  puppeteer?: PuppeteerConfig;
  map?: MapConfig;
  licenses?: LicensesConfig;
};

export type LicensesConfig = Record<string, Product>;

export type PuppeteerConfig = {
  enable_watch_lists_monitoring?: boolean;
};

export type MapConfig = {
  enabled: boolean;
  default_zoom?: number;
  default_center?: LatLngType;
  maximum_objects_on_map?: number;
  show_cameras_thumbnails?: boolean;
  providers: MapProvider[];
};

export type MapProvider = {
  id: string;
  name: string;
  type: MapProviderType;
  url?: string;
};

export type MapProviderType = 'tile';

export type MenuConfig = {
  disabled_items: string[]; // PageNames
};

export type IConfigState = {
  config: IConfig;
  loading: boolean;
};

export type IConfigFeatures = {
  vms_enabled: boolean;
  external_vms_enabled: boolean;
  events_enabled: boolean;
  clusters_enabled: boolean;
  cameragroups_enabled: boolean;
  cases_enabled: boolean;
  alerts_enabled: boolean;
  lines_enabled: boolean;
  watch_lists_monitoring_enabled: boolean;
  maps_enabled: boolean;
};

export type IConfigPlugins = {
  puppeteer?: boolean;
};

export type ConfigCustomReports = {
  path: string;
  description: string;
};

export type IConfigServices = {
  alerts: any;
  active_directory?: boolean;
  area?: boolean;
  cleanup?: boolean;
  clusters?: boolean;
  counters?: boolean;
  episodes?: boolean;
  proximity_calibration?: boolean;
  reports?: boolean;
  video_archive_events_manager?: boolean;
  vms_cleanup?: boolean;
  webhooks?: boolean;
};

export type ITimelineObjectItem = {
  enabled: boolean;
  limit: number;
  matchedColor: string;
  unmatchedColor: string;
};

export type ITimelineObjects = {
  events: {
    faces: ITimelineObjectItem;
    bodies: ITimelineObjectItem;
    cars: ITimelineObjectItem;
  };
  episodes: {
    humans: ITimelineObjectItem;
    cars: ITimelineObjectItem;
  };
};

export const DefaultCenterMapPoint = { lat: 55.7558, lng: 37.6173 };

export class ConfigModule implements IConfigState {
  config: IConfig = {};
  loading = false;

  get serverUrl() {
    return this.config?.server?.url ?? `${location.origin}/`;
  }

  get features(): IConfigFeatures {
    return {
      vms_enabled: this.config?.vms?.enabled,
      external_vms_enabled: this.config?.external_vms?.enabled,
      events_enabled: !configAclModule.isDisabledMenuItem(PageNames.Events), // @todo isDisabled -> canShowItem!
      clusters_enabled: !configAclModule.isDisabledMenuItem(PageNames.Clusters),
      cameragroups_enabled: !configAclModule.isDisabledMenuItem(PageNames.CameraGroups),
      cases_enabled: !configAclModule.isDisabledMenuItem(PageNames.Cases),
      lines_enabled: !configAclModule.isDisabledMenuItem(PageNames.Lines),
      alerts_enabled: !!(this.config?.annex?.enabled && this.config.licenses?.multi?.features?.alerts?.value),
      watch_lists_monitoring_enabled: !!configAclModule.config?.puppeteer?.enable_watch_lists_monitoring,
      maps_enabled: !!(this.config.map?.enabled && this.config.licenses?.multi?.features?.maps?.value)
    };
  }

  get overlay_objects(): OverlayObjects {
    return this.config?.video_player?.overlay.objects as OverlayObjects;
  }

  get overlay_gdpr(): boolean {
    return this.config?.video_player?.overlay.gdpr as boolean;
  }

  get timeline_min_zoom(): number | undefined {
    return this.config?.video_player?.timeline?.min_zoom;
  }

  get timeline_max_zoom(): number | undefined {
    return this.config?.video_player?.timeline?.max_zoom;
  }

  get canLanguageSelect() {
    return this.config?.languages?.['select-language'] !== false;
  }

  get timeline_objects(): ITimelineObjects | undefined {
    return this.config?.video_player?.timeline?.objects;
  }

  get plugins(): IConfigPlugins {
    return {
      ...this.config.plugins
    };
  }

  get custom_reports(): ConfigCustomReports[] | undefined {
    return this.config.custom_reports;
  }

  get services(): IConfigServices {
    return this.config.services.ffsecurity;
  }

  async loadConfig(): Promise<boolean> {
    const url = `config.json?r=${Math.random()}`;
    this.loading = true;
    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        const remoteConfig = response.data as IConfig;
        const productConfig = productConfigs[applicationModule.product] as Partial<IConfig> | undefined;
        this.config = merge(remoteConfig, productConfig);
        return true;
      })
      .then(() => {
        return this.loadCarModels();
      })
      .catch(async (e: AxiosError) => {
        await getDelay(1000);
        return this.loadConfig();
      })
      .finally(async () => {
        this.loading = false;
      });
  }

  private async loadCarModels(): Promise<boolean> {
    const sourceUrl = this.config.objects?.cars?.features?.model?.url;
    if (!sourceUrl) return true;
    const url = `${sourceUrl}?r=${Math.random()}`;
    return axios
      .get(url)
      .then((response: AxiosResponse) => {
        this.config.objects.cars.features.model = response.data.items;
        return true;
      })
      .catch(() => {
        console.warn("[Config module]: hasn't car models");
        return false;
      });
  }

  async load() {
    await this.loadConfig();
    const languages = await configLanguageModule.loadLanguages();
    languages.forEach((v) => {
      const currentLanguageData = languageModule.items.find((i) => i.name === v.name);
      if (currentLanguageData) {
        merge(currentLanguageData.data, v.data);
      } else {
        languageModule.items.push(v);
      }
    });
    return true;
  }

  get defaultTitle(): string {
    return this.config.themes?.title ?? productDefaultTitles[applicationModule.product];
  }

  // Далее по проекту везде использовать этот метод
  // чтобы не городить велосипеды которые выше типа get defaultTitle()
  // В метод добавить различную отладку когда:
  // - значение не проставлено в конфиг файле
  // - тип данных не совпадает
  // - значение проставлено и отличается от значения по умолчанию
  get<T>(path: string, defaultValue: any = undefined): T {
    return _.get(this.config, path, defaultValue) as T;
  }

  getString(path: string, defaultValue: string) {
    return this.get<string>(path, defaultValue);
  }
  getNumber(path: string, defaultValue: number) {
    return this.get<number>(path, defaultValue);
  }
  getBoolean(path: string, defaultValue: boolean) {
    return this.get<boolean>(path, defaultValue);
  }
}

export const configModule = reactive(new ConfigModule()) as ConfigModule;
