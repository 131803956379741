import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "heading-m" }
const _hoisted_2 = { class: "paragraph-m" }
const _hoisted_3 = { class: "n-confirm__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_ctx.currentDisplay)
    ? (_openBlock(), _createBlock(_component_NModalWindow, {
        key: 0,
        "class-name": "n-confirm",
        size: _ctx.size,
        label: _ctx.label,
        "insert-into-body": _ctx.insertIntoBody
      }, {
        header: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
          _createVNode(_component_NButton, {
            icon: "close",
            type: "secondary",
            round: "",
            class: "n-modal-window__close-button",
            onClick: _ctx.closeHandler
          }, null, 8, ["onClick"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default"),
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.cancelLabel)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  type: "link",
                  onClick: _ctx.closeHandler
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cancelLabel), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_NButton, { onClick: _ctx.confirmHandler }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.confirmLabel), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 3
      }, 8, ["size", "label", "insert-into-body"]))
    : _createCommentVNode("", true)
}