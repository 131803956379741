import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-button__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NCounter = _resolveComponent("NCounter")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    role: "button",
    class: _normalizeClass(_ctx.classes),
    type: _ctx.nativeType,
    disabled: _ctx.disabled,
    "data-qa": `${_ctx.dataQa}.button`,
    onClick: _ctx.clickHandler
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(['n-button__content', { 'n-button__content_invisible': _ctx.loading }]),
        ref: "content"
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 0,
              class: "n-button__icon",
              name: _ctx.icon,
              width: _ctx.iconSize,
              height: _ctx.iconSize
            }, null, 8, ["name", "width", "height"]))
          : _createCommentVNode("", true),
        (_ctx.color)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "n-button__color",
              style: _normalizeStyle(_ctx.colorStyle)
            }, null, 4))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.labelClasses)
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 2),
        (_ctx.counterEnabled)
          ? (_openBlock(), _createBlock(_component_NCounter, {
              key: 2,
              class: "n-button__counter",
              value: _ctx.counter,
              type: _ctx.counterType
            }, null, 8, ["value", "type"]))
          : _createCommentVNode("", true),
        (_ctx.suffixIcon)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 3,
              class: "n-button__icon-suffix",
              name: _ctx.suffixIcon,
              width: _ctx.iconSize,
              height: _ctx.iconSize
            }, null, 8, ["name", "width", "height"]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.loaderComponent), {
              class: _normalizeClass(`n-button__loader-${_ctx.loader}`),
              type: _ctx.loaderType
            }, null, 8, ["class", "type"]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "type", "disabled", "data-qa", "onClick"]))
}