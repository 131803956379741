
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import AnnexAlarmStatusHelper from '@/pages/annex/alarms/AnnexAlarmStatusHelper.vue';
import NHint from '@/uikit/hint/NHint.vue';

@Options({
  name: 'AnnexAlarmStatusHelperIcon',
  components: { AnnexAlarmStatusHelper, NIcon, NHint }
})
export default class AnnexAlarmStatusHelperIcon extends Vue {}
