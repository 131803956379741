import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NButton, {
      icon: "map-select",
      type: _ctx.areaDrawOn ? 'primary' : 'secondary',
      onAction: _ctx.drawToggle
    }, null, 8, ["type", "onAction"]),
    (_ctx.marker && _ctx.actions.length)
      ? (_openBlock(), _createBlock(_component_NDropdown, {
          key: 0,
          class: "map-area-select__actions",
          style: _normalizeStyle(_ctx.actionsPosition),
          manual: "",
          "insert-into-body": false
        }, {
          body: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedActions, (action, i) => {
              return (_openBlock(), _createBlock(_component_NDropdownItem, {
                key: i,
                icon: action.icon,
                onClick: ($event: any) => (_ctx.handleAction(action.name))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(action.i18n_label)), 1)
                ]),
                _: 2
              }, 1032, ["icon", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["style"]))
      : _createCommentVNode("", true)
  ], 64))
}