
import { defineComponent } from 'vue';
import { NModalWindow } from '@/uikit';
import { ReportsPageDownloaderProgressBar } from './components';
import { ReportArchiverImpl, ReportDownloader, ReportDownloaderImpl, ReportSaverImpl, ReportSourceImpl } from './services';
import { useReportsPageDownloaderViewModel } from './setup';

export default defineComponent({
  name: 'ReportsPageDownloader',
  components: {
    NModalWindow,
    ReportsPageDownloaderProgressBar
  },
  setup: () => useReportsPageDownloaderViewModel(createReportDownloader()),
  computed: {
    status() {
      return `${this.$t('reports.downloading_report')} "${this.progress.name}".`;
    },
    header() {
      return `${this.$t('reports.downloading_reports')} (${this.progress.position}/${this.progress.summary})`;
    }
  }
});

function createReportDownloader(): ReportDownloader {
  const archiver = ReportArchiverImpl.create();
  const saver = ReportSaverImpl.create();
  const source = ReportSourceImpl.create();
  return ReportDownloaderImpl.create(archiver, saver, source);
}
