import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert-delivery" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NIcon, {
      name: "ad_screen",
      class: _normalizeClass({ active: _ctx.modelValue?.websocket })
    }, null, 8, ["class"]),
    (_ctx.isTelegramChannelEnabled)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          name: "ad_message",
          class: _normalizeClass({ active: _ctx.modelValue?.telegram })
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}