import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "counter-info"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "n-nowrap" }
const _hoisted_4 = { class: "n-nowrap" }
const _hoisted_5 = { class: "n-nowrap" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "status__hint" }
const _hoisted_8 = { class: "status__description label-s" }
const _hoisted_9 = {
  key: 0,
  class: "n-nowrap"
}
const _hoisted_10 = {
  key: 1,
  class: "n-nowrap"
}
const _hoisted_11 = { class: "status__item" }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonStatus = _resolveComponent("CommonStatus")!
  const _component_CameraStatusDetails = _resolveComponent("CameraStatusDetails")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_ctx.item.health_status)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_NHint, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.item.health_status.code_desc), 1),
              _createVNode(_component_CameraStatusDetails, { details: _ctx.fullStatusItems }, null, 8, ["details"])
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_CommonStatus, {
              color: _ctx.item.health_status.color,
              description: _ctx.item.health_status.code_desc
            }, null, 8, ["color", "description"]),
            (_ctx.item.health_status.statistic)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.statistic.failed_records) + " / ", 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.statistic.total_records), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.statistic.total_records ? ` (${_ctx.statistic.success_percent}%)` : ''), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, "- / -"))
          ]),
          _: 1
        }),
        (_ctx.item.multi_camera)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('counters.multi_camera')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('counters.single_camera')), 1)),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.distanceEnabled)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.translatedCalibrationStatus), 1))
            : (_ctx.isProximityEnabled)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('counters.proximity_not_enabled')), 1)
                ], 64))
              : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}