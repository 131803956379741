import { Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { CarCard, HumanCard } from '@/api';
import { CardTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { EventDetails, ThumbnailPositions } from '@/uikit/thumbnail';

export default class CardItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: Object })
  modelAcl!: any;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: String, required: true })
  type!: string;

  @Prop({ type: String, default: '' })
  objectType!: string;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: Boolean, default: false })
  opened!: boolean;

  @Prop({ type: Boolean, default: true })
  showThumbnail!: boolean;

  @Prop({ type: Boolean, default: true })
  showDate!: boolean;

  @Prop({ type: Boolean, default: true })
  showAttachments!: boolean;

  @Prop({ type: Boolean, default: true })
  showMetafields!: boolean;

  @Prop({ type: Boolean, default: false })
  showConnections!: boolean;

  @Prop({ type: Boolean, default: false })
  showMatchedConnections!: boolean;

  @Prop({ type: Boolean, default: false })
  showRelatedConnections!: boolean;

  @Prop({ type: Boolean, default: false })
  showInteractions!: boolean;

  @Prop({ type: Boolean, default: false })
  showActions!: boolean;

  objectsVM: ListViewModel<any, any> | null = null;

  @Prop({ type: Boolean, default: false })
  readonly canClose!: boolean;

  @Prop({ type: String, default: 'large' })
  readonly thumbnailSize!: 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({ type: String, default: 'rounded' })
  readonly corners!: 'rounded' | 'circle';

  get thumbnailLoading() {
    return this.objectsVM?.loading || false;
  }

  get hasAnyMonitoringRecord() {
    return this.hasCardMonitoringRecord || this.hasWatchListMonitoringRecord;
  }

  get hasCardMonitoringRecord() {
    return monitoringModule.isCardOnMonitoring(this.item.id);
  }

  get hasWatchListMonitoringRecord() {
    return monitoringModule.isSomeWatchListOnMonitoring(this.item.watch_lists);
  }

  @Watch('item.id', { immediate: true })
  async load(id: number) {
    if (!id) return;
    let objectType = this.objectType || dataAssetsModule.getObjectTypeByCardType(this.type);
    this.objectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.item.id, limit: 1, type: objectType, ordering: 'id' });
    await this.loadPuppeteerMonitoringRecord();
  }

  get isPuppeteerEnabled() {
    return this.type === CardTypesMap.Humans && monitoringModule.isPuppeteerEnabled;
  }

  async loadPuppeteerMonitoringRecord(): Promise<void> {
    if (!this.isPuppeteerEnabled) return;
    await monitoringModule.getItemsByCard(this.item.id);
    await monitoringModule.getItemsByWatchLists(this.item.watch_lists);
  }

  get ItemsActionNames() {
    return ItemsActionNames;
  }

  get thumbnail(): string | null {
    const hasItems = this.objectsVM?.items.length;
    return hasItems ? this.objectsVM?.items[0]?.thumbnail : null;
  }

  get name(): string {
    return this.item.name || this.$t('cards.unknown', 'f');
  }

  get comment() {
    return this.item.comment;
  }

  get styles() {
    const opacity = this.item.active ? 1 : 0.5;
    return { opacity };
  }

  get thumbnailActions() {
    const result = [{ icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }];
    return result;
  }

  showFullscreen() {
    this.$photoViewer.show(this.objectsVM?.items[0]);
  }
}
