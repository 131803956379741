import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = {
  key: 0,
  class: "n-input-number__controls"
}
const _hoisted_3 = ["name", "placeholder", "disabled", "autofocus", "accesskey", "readonly"]
const _hoisted_4 = {
  key: 1,
  class: "n-input-number__units label-mono-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass),
    tabindex: _ctx.disabled ? -1 : 0,
    onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
    onTouchend: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
    onFocus: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
  }, [
    (_ctx.controls)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(['n-input-number__increase', { 'n-input-number__increase_disabled': _ctx.increaseDisabled || _ctx.disabled || _ctx.readonly }]),
            onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controlsChangeStart(true))),
            onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args))),
            onMouseout: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args)))
          }, [
            _createVNode(_component_NIcon, {
              name: "chevron-down__padding_none",
              width: "10",
              height: "5"
            })
          ], 34),
          _createElementVNode("div", {
            class: _normalizeClass(['n-input-number__decrease', { 'n-input-number__decrease_disabled': _ctx.decreaseDisabled || _ctx.disabled || _ctx.readonly }]),
            onMousedown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controlsChangeStart(false))),
            onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args))),
            onMouseout: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args)))
          }, [
            _createVNode(_component_NIcon, {
              name: "chevron-down__padding_none",
              width: "10",
              height: "5"
            })
          ], 34)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      autocomplete: "off",
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      class: _normalizeClass(['n-input-number__input', 'label-mono-m', `n-input-number__input_align_${this.textAlign}`]),
      type: "text",
      ref: "input",
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inputModelValue) = $event)),
      inputmode: "numeric",
      disabled: _ctx.disabled,
      autofocus: _ctx.autofocus,
      accesskey: _ctx.accesskey,
      readonly: _ctx.readonly,
      onKeydown: [
        _cache[7] || (_cache[7] = _withKeys(_withModifiers(($event: any) => (_ctx.controlsChangeStart(true)), ["prevent"]), ["up"])),
        _cache[9] || (_cache[9] = _withKeys(_withModifiers(($event: any) => (_ctx.controlsChangeStart(false)), ["prevent"]), ["down"]))
      ],
      onKeyup: [
        _cache[8] || (_cache[8] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args)), ["prevent"]), ["up"])),
        _cache[10] || (_cache[10] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.controlsChangeStop && _ctx.controlsChangeStop(...args)), ["prevent"]), ["down"]))
      ],
      onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.changeHandler && _ctx.changeHandler(...args))),
      onBlur: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.eventProxyHandler && _ctx.eventProxyHandler(...args))),
      onFocus: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.eventProxyHandler && _ctx.eventProxyHandler(...args)))
    }, null, 42, _hoisted_3), [
      [_vModelText, _ctx.inputModelValue]
    ]),
    (_ctx.units.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.units), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "suffix"),
    (_ctx.clearable && _ctx.isModelValueDefined)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 2,
          class: _normalizeClass(['n-input-number__postfix-icon', {'n-input-number__postfix-icon_disabled': _ctx.disabled}]),
          name: "close_small",
          width: "8",
          height: "20",
          onClick: _ctx.clear
        }, null, 8, ["class", "onClick"]))
      : _createCommentVNode("", true)
  ], 42, _hoisted_1))
}