
import { Options, Vue } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';

@Options({
  components: { NIcon },
  emits: ['click']
})
export default class VideoWallPresetSelectItemDeleteButton extends Vue {
  dispatchClickEvent(): void {
    this.$emit('click');
  }
}
