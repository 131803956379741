
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ThumbnailAction } from '@/uikit/thumbnail';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';

@Options({
  name: 'DoubleThumbnail',
  components: { NThumbnail },
  emits: ['action', 'select']
})
export default class DoubleThumbnail extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String, default: 'normal' })
  readonly size!: 'normal' | 'small' | 'semi-small' | 'middle' | 'large' | 'extra-large';

  @Prop({ type: String, default: 'rounded' })
  readonly corners!: 'rounded' | 'circle';

  @Prop({ type: String, default: 'cover' })
  readonly fit!: 'cover' | 'contain';

  @Prop({ type: Array, default: [] })
  readonly actions!: ThumbnailAction[];

  @Prop({ type: String, default: 'click' })
  readonly defaultAction!: string;

  @Prop({ type: Boolean, default: false })
  readonly canClose!: boolean;

  @Prop({ type: String, required: false })
  readonly optionalModelValue?: string;

  hovered: boolean = false;
  optionalHovered: boolean = false;

  get doubleThumbnailClasses() {
    return {
      'double-thumbnail': this.optionalModelValue
    };
  }

  get classes() {
    return {
      full: this.hovered,
      short: this.optionalHovered
    };
  }

  get optionalClasses() {
    return {
      full: this.optionalHovered,
      short: this.hovered
    };
  }

  emitAction(v: string) {
    this.$emit('action', v);
  }
}
