import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { Case, CaseCluster, HumanCard } from '@/api';
import { viewModelRepository } from '@/api/common';
import { firstCase } from '@/common/filters';

export type CaseCardsData = {
  participants: CaseCluster[];
  cases: Case[];
  caseParticipants: CaseCluster[];
  caseCards: HumanCard[];
};

export type GetDataOptions = {
  type: string;
  id: number | string;
  autoload?: boolean;
  limit?: number;
  ordering?: string;
};

export class LoadDataModule {
  async getCardByID(options: GetDataOptions): Promise<any> {
    const result = this.getCardVMByID(options);
    await result.get(options.id);
    return result.item;
  }

  getCardVMByID(options: GetDataOptions): ItemViewModel<any> {
    const result = options.type === 'humans' ? viewModelRepository.getCardsHumansItemViewModel() : viewModelRepository.getCardsCarsItemViewModel();
    if (options.autoload !== false) result.get(options.id);
    return result;
  }

  getCardAttachmentsVMByID(options: GetDataOptions): ListViewModel<any, any> {
    const result =
      options.type === 'humans' ? viewModelRepository.getHumanCardAttachmentsListViewModel() : viewModelRepository.getCarCardAttachmentsListViewModel();
    result.filter.current.card = options.id as any;
    result.filter.current.limit = 100;
    if (options.autoload !== false) result.get();
    return result;
  }

  async getClusterEventsByID(options: GetDataOptions): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let result: ItemViewModel<any> = viewModelRepository['getClusterEvents' + firstCase(options.type) + 'ItemViewModel']();
    await result.get(options.id);
    return result.item;
  }

  getEventVMByID(options: GetDataOptions): ItemViewModel<any> {
    let result;
    switch (options.type) {
      case 'faces':
        result = viewModelRepository.getEventsFacesItemViewModel();
        break;
      case 'bodies':
        result = viewModelRepository.getEventsBodiesItemViewModel();
        break;
      case 'cars':
        result = viewModelRepository.getEpisodesCarsItemViewModel();
        break;
      default:
        throw new Error('getEvent: error, unknown type: ' + options.type);
    }
    if (options.autoload !== false) result.get(options.id);
    return result;
  }

  getEventsLVMByEpisodeID(options: GetDataOptions): ListViewModel<any, any> {
    let result;
    switch (options.type) {
      case 'faces':
        result = viewModelRepository.getEventsFacesListViewModel();
        break;
      case 'bodies':
        result = viewModelRepository.getEventsBodiesListViewModel();
        break;
      case 'cars':
        result = viewModelRepository.getEventsCarsListViewModel();
        break;
      default:
        throw new Error('getEvent: error, unknown type: ' + options.type);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    result.filter.current.episode = options.id;
    result.filter.current.limit = (options.limit ?? '100') as any;
    if (options.autoload !== false) result.get();
    return result;
  }

  getObjectsLVMByCardID(options: GetDataOptions): ListViewModel<any, any> {
    switch (options.type) {
      case 'faces':
        return this.getFaceObjectsLVMByCardID(options);
      case 'bodies':
        return this.getBodiesObjectsLVMByCardID(options);
      case 'cars':
        return this.getCarsObjectsLVMByCardID(options);
      default:
        throw new Error('getObjects: error, unknown type: ' + options.type);
    }
  }

  getClusterEventsLVMByClusterID(options: GetDataOptions): ListViewModel<any, any> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let result = viewModelRepository['getClusterEvents' + firstCase(options.type) + 'ListViewModel']();
    result.filter.current.cluster_in = [Number(options.id)];
    result.filter.current.limit = (options.limit ?? '100') as any;
    result.filter.current.ordering = options.ordering ?? '-id';
    if (options.autoload !== false) result.get();
    return result;
  }

  getObjectsLVMByID(options: GetDataOptions): ListViewModel<any, any> {
    switch (options.type) {
      case 'faces':
        return this.getFaceObjectsLVMByID(options);
      case 'bodies':
        return this.getBodiesObjectsLVMByID(options);
      case 'cars':
        return this.getCarsObjectsLVMByID(options);
      default:
        throw new Error('getObjects: error, unknown type: ' + options.type);
    }
  }

  getFaceObjectsLVMByCardID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsFacesListViewModel();
    this.setObjectLVMFilterByCardID(options, result);
    if (options.autoload !== false) result.get();
    return result;
  }

  getBodiesObjectsLVMByCardID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsBodiesListViewModel();
    this.setObjectLVMFilterByCardID(options, result);
    if (options.autoload !== false) result.get();
    return result;
  }

  getCarsObjectsLVMByCardID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsCarsListViewModel();
    this.setObjectLVMFilterByCardID(options, result);
    if (options.autoload !== false) result.get();
    return result;
  }

  setObjectLVMFilterByCardID(options: GetDataOptions, result: ListViewModel<any, any>) {
    result.filter.current.card = [Number(options.id)];
    result.filter.current.limit = (options.limit ?? '100') as any;
    result.filter.current.ordering = options.ordering ?? '-id';
  }

  getFaceObjectsLVMByID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsFacesListViewModel();
    result.filter.current.id_in = options.id as any;
    result.filter.current.limit = (options.limit ?? '100') as any;
    if (options.autoload !== false) result.get();
    return result;
  }

  getBodiesObjectsLVMByID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsBodiesListViewModel();
    result.filter.current.id_in = options.id as any;
    result.filter.current.limit = (options.limit ?? '100') as any;
    if (options.autoload !== false) result.get();
    return result;
  }

  getCarsObjectsLVMByID(options: GetDataOptions): ListViewModel<any, any> {
    const result = viewModelRepository.getObjectsCarsListViewModel();
    result.filter.current.id_in = options.id as any;
    result.filter.current.limit = (options.limit ?? '100') as any;
    if (options.autoload !== false) result.get();
    return result;
  }

  async getLinkedCaseHumanCardsByCardID(id: number): Promise<CaseCardsData | null> {
    const participantsVM = viewModelRepository.getCaseClustersListViewModel();
    Object.assign(participantsVM.filter.current, { limit: 1000, ordering: '-id', is_case_participant: true, related_human_card: id });
    await participantsVM.get();

    const allCasesArray = participantsVM.items.map((m, v) => m.case, []);
    const casesArray = Array.from(new Set(allCasesArray));
    if (!casesArray.length) return null;

    const casesVM = viewModelRepository.getCasesListViewModel();
    Object.assign(casesVM.filter.current, { limit: 1000, id_in: casesArray });
    await casesVM.get();

    const casesParticipantsVM = viewModelRepository.getCaseClustersListViewModel();
    Object.assign(casesParticipantsVM.filter.current, { limit: 1000, is_case_participant: true, case_in: casesArray });
    await casesParticipantsVM.get();

    const allCardsArray = casesParticipantsVM.items.reduce<number[]>((m, v) => {
      if (Array.isArray(v.related_human_cards)) m = m.concat(v.related_human_cards.map((el) => el.id));
      return m;
    }, []);
    const cardsArray = Array.from(new Set(allCardsArray));

    if (!cardsArray.length) return null;

    const casesCardsVM = viewModelRepository.getCardsHumansListViewModel();
    Object.assign(casesCardsVM.filter.current, { limit: 1000, id_in: cardsArray });
    await casesCardsVM.get();

    return { participants: participantsVM.items, cases: casesVM.items, caseCards: casesCardsVM.items, caseParticipants: casesParticipantsVM.items };
  }
}

export const loadDataModule = new LoadDataModule();
