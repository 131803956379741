import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    placement: "top",
    delayVisible: 100
  }, {
    content: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('common.make_copy', 'f') + ' "' + _ctx.id + '"'), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: "item-id",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copyTextToClipboard(_ctx.id)), ["stop"]))
      }, "#")
    ]),
    _: 1
  }))
}