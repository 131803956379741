import { getNumberValidator } from '@/uikit/forms/validators';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { NCheckbox, NInputNumber } from '@/uikit';
import { Alert } from '@/api';

const settingsSchema: IFormLayout = [
  [
    {
      path: 'ack_interval',
      i18n_label: 'alerts.settings.ack_interval',
      i18n_tooltip: 'alerts.settings.ack_interval__desc',
      classes: 'n-form-w-2 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 300 })],
      component: NInputNumber,
      props: { min: 0, max: 300, textAlign: 'right' }
    }
  ],
  [
    {
      path: 'dedup_interval',
      i18n_label: 'alerts.settings.ddp_interval',
      i18n_tooltip: 'alerts.settings.ddp_interval__desc',
      classes: 'n-form-w-2 n-form-pad-10',
      validators: [getNumberValidator({ required: true, min: 0, max: 300 })],
      component: NInputNumber,
      props: (item: Alert) => ({ disabled: !!item?.filters?.counters, min: 0, max: 300, default: 0, textAlign: 'right' })
    },
    {
      path: 'dedup_enabled',
      i18n_label: 'alerts.settings.reset_ddp_interval',
      i18n_tooltip: 'alerts.settings.reset_ddp_interval__desc',
      classes: 'n-form-w-2 n-form-pad-10',
      component: NCheckbox,
      props: (item: Alert) => ({ disabled: !!item?.filters?.counters, textAlign: 'right' })
    }
  ]
];

export default settingsSchema;
