
import * as L from 'leaflet';
window.L = L;

import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { LMap, LTileLayer } from '@vue-leaflet/vue-leaflet';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { LatLngType } from '@/components/map/types';
import { configModule } from '@/store/config';
import type { LocationEvent, Map as TMap } from 'leaflet';
import { languageModule } from '@/store/languages';
import { localStorageModule } from '@/store/application/local.storage';
import { dialogModule } from '@/store/dialogs/dialogModule';

const DefaultCenter = {
  lat: 51.477928,
  lng: -0.001545
};

const DefaultZoom = 16;

@Options({
  name: 'Map',
  components: {
    NButton,
    NButtonGroup,
    LTileLayer,
    LMap
  },
  emits: ['toTarget', 'locationFound', 'locationError', 'mapReady']
})
export default class Map extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly zoomOn!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly toTargetOn!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly locateOn!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly autoLocateCenter!: boolean;

  @Prop({ type: Object })
  readonly startCenter?: LatLngType;

  @Prop({ type: String, default: 'default' })
  readonly providerId!: string;

  @Prop({ type: String, default: 'mapDefaultStorageKey' })
  readonly storageKey!: string;

  @Prop({ type: Boolean, default: false })
  readonly storeState!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly restoreView!: boolean;

  readonly PreserveProperties = ['storedState'];

  @Ref('mapContainer')
  mapContainer?: HTMLDivElement;

  map: TMap | null = null;

  storedState: { center: LatLngType; zoom: number } | null = null;

  options = {
    attributionControl: false,
    zoomControl: false
  };

  beforeUnmount() {
    if (this.mapContainer) {
      this.mapContainerObserver.unobserve(this.mapContainer);
    }
  }

  created() {
    localStorageModule.registerInstance({ instance: this, name: this.storageKey, tokens: this.PreserveProperties });
  }

  get config() {
    if (!configModule.config.map) {
      console.error('[Map]: set map configuration to use maps!');
    }
    return configModule.config.map;
  }

  get provider() {
    const provider = this.config?.providers.find((v) => v.id === this.providerId);
    if (!provider) {
      console.error(`[Map]: provider with id ${this.providerId} not configured!`);
    }
    return provider;
  }

  get tileUrl() {
    return this.provider?.url;
  }

  get zoomItems() {
    return [
      { value: 1, icon: 'zoom-in' },
      { value: -1, icon: 'zoom-out' }
    ];
  }

  get locateAvailable() {
    return 'geolocation' in navigator;
  }

  get mapContainerObserver() {
    return new ResizeObserver(this.handleMapContainerResize.bind(this));
  }

  handleMapContainerResize() {
    this.map?.invalidateSize();
  }

  handleZoom(zoomOffset: number) {
    this.map?.setZoom(this.map.getZoom() + zoomOffset);
  }

  handleMapReady(map: TMap) {
    this.map = map;
    map.on('locationerror', this.handleLocationError.bind(this));
    map.on('locationfound', this.handleLocationFound.bind(this));
    map.on('zoomend', this.saveStateToStore.bind(this));
    map.on('moveend', this.saveStateToStore.bind(this));

    this.initView();
    if (this.mapContainer) {
      this.mapContainerObserver.observe(this.mapContainer);
    }
    map.pm.setLang(languageModule.currentLocale as any);
    this.$emit('mapReady', this.map);
  }

  initView() {
    if (this.restoreView) {
      localStorageModule.syncFromStorageByName(this.storageKey);
      if (this.storedState) {
        this.map?.setView({ ...this.storedState.center }, this.storedState.zoom);
        return;
      }
    }

    const zoom = this.config?.default_zoom || DefaultZoom;
    if (this.startCenter) {
      this.map?.setView({ ...this.startCenter }, zoom);
    } else if (this.autoLocateCenter && this.locateOn && this.locateAvailable) {
      this.locate();
    } else {
      const defaultCenter = { ...(this.config?.default_center ?? DefaultCenter) };
      this.map?.setView(defaultCenter, zoom);
    }
  }

  handleLocationFound({ latlng }: LocationEvent) {
    this.$emit('locationFound', { map: this.map, latLng: latlng });
  }

  handleLocationError() {
    this.$emit('locationError');
  }

  locate() {
    // eslint-disable-next-line no-undef
    const getLocationHandler = (location: GeolocationPosition) => this.map?.setView({ lat: location.coords.latitude, lng: location.coords.longitude });
    const getLocationErrorHandler = async (error: any) => await dialogModule.alert('common.geolocation_not_available_warning');

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getLocationHandler, getLocationErrorHandler);
    } else {
      getLocationErrorHandler(new Error('Has no geo'));
    }
  }

  saveStateToStore() {
    if (this.map && this.storeState) {
      this.storedState = {
        zoom: this.map.getZoom(),
        center: this.map.getCenter()
      };
      localStorageModule.syncToStorageByName(this.storageKey);
    }
  }
}
