import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "case-form" }
const _hoisted_2 = { class: "heading-2xl" }
const _hoisted_3 = { class: "label-m case-form__header-description" }
const _hoisted_4 = { class: "case-form_l-v-margins" }
const _hoisted_5 = {
  key: 0,
  class: "case-form_l-v-margins"
}
const _hoisted_6 = { class: "case-form_l-v-margins" }
const _hoisted_7 = { class: "case-form_l-v-margins" }
const _hoisted_8 = {
  key: 1,
  class: "case-form_xl-v-margins label-m case-form__footer"
}
const _hoisted_9 = { class: "label-mono-m case-form__value" }
const _hoisted_10 = { class: "label-mono-m case-form__value" }
const _hoisted_11 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NAttachments = _resolveComponent("NAttachments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isNew)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('cases.new_case', 'f')), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('cases.new_case_description')), 1),
          _createVNode(_component_NForm, {
            ref: "infoForm",
            "data-qa": `${_ctx.dataQa}.info`,
            layout: _ctx.$options.informationLayout,
            model: _ctx.item,
            disabled: _ctx.disabled
          }, null, 8, ["data-qa", "layout", "model", "disabled"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_NButton, {
              "data-qa": `${_ctx.dataQa}.create`,
              size: "big",
              onClick: _ctx.saveHandler,
              "suffix-icon": "chevron-right",
              class: "case-form__create-button"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.create', 'f')), 1)
              ]),
              _: 1
            }, 8, ["data-qa", "onClick"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_NForm, {
            ref: "infoForm",
            "data-qa": `${_ctx.dataQa}.info`,
            layout: _ctx.$options.informationLayout,
            model: _ctx.item,
            disabled: _ctx.disabled
          }, null, 8, ["data-qa", "layout", "model", "disabled"]),
          (_ctx.isCurrentUserAdmin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (!_ctx.showPermissions)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 0,
                      "data-qa": `${_ctx.dataQa}.show-permissions`,
                      type: "link",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPermissions = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('cases.set_role_permissions')), 1)
                      ]),
                      _: 1
                    }, 8, ["data-qa"]))
                  : _createCommentVNode("", true),
                (_ctx.showPermissions)
                  ? (_openBlock(), _createBlock(_component_NForm, {
                      key: 1,
                      "data-qa": `${_ctx.dataQa}.permissions`,
                      layout: _ctx.$options.permissionsLayout,
                      model: _ctx.item,
                      disabled: _ctx.disabled
                    }, null, 8, ["data-qa", "layout", "model", "disabled"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_NAttachments, {
              "disallow-attach": _ctx.disabled,
              "disallow-remove": _ctx.disabled,
              "handle-attach": _ctx.uploadAttachmentHandler,
              "handle-remove": _ctx.deleteAttachmentHandler,
              i18n: _ctx.attachmentLabels,
              attachments: _ctx.attachments,
              "onUpdate:attachments": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attachments) = $event))
            }, null, 8, ["disallow-attach", "disallow-remove", "handle-attach", "handle-remove", "i18n", "attachments"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_NButton, {
              class: "case-form__save-button",
              size: "big",
              onClick: _ctx.saveHandler,
              disabled: _ctx.disabled
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.save', 'f')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ]),
          (!_ctx.isNew)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.$t('common.id', 'u')) + " ", 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.item.id), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('common.created', 'f')) + " ", 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$filters.formatDateTime(_ctx.item.created_date)), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('common.created_by')) + " ", 1),
                _createVNode(_component_NButton, {
                  type: "link",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.navigateToUser(_ctx.item.created_by)), ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.dataModule.getUserNameById(_ctx.item.created_by)), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.appModule.settings.debug)
            ? (_openBlock(), _createElementBlock("pre", _hoisted_11, "        " + _toDisplayString(_ctx.item) + "\n      ", 1))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}