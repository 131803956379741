/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { getListViewModel, getItemViewModel, ListViewModel, ItemViewModel } from '../../definitions/view-models';
import { AreaTriggerActivation, EmptyAreaTriggerActivation } from '../models/AreaTriggerActivation';
import { AreaTriggerActivationsFilter, EmptyAreaTriggerActivationsFilter } from '../models/AreaTriggerActivationsFilter';
import { AreaTriggerRecord, EmptyAreaTriggerRecord } from '../models/AreaTriggerRecord';
import { AreaTriggerRecordsFilter, EmptyAreaTriggerRecordsFilter } from '../models/AreaTriggerRecordsFilter';
import { Area, EmptyArea } from '../models/Area';
import { AreasFilter, EmptyAreasFilter } from '../models/AreasFilter';
import { AuditLog, EmptyAuditLog } from '../models/AuditLog';
import { AuditLogsFilter, EmptyAuditLogsFilter } from '../models/AuditLogsFilter';
import { UploadList, EmptyUploadList } from '../models/UploadList';
import { BatchUploadFilter, EmptyBatchUploadFilter } from '../models/BatchUploadFilter';
import { Upload, EmptyUpload } from '../models/Upload';
import { BatchUploadEntryFilter, EmptyBatchUploadEntryFilter } from '../models/BatchUploadEntryFilter';
import { CameraGroup, EmptyCameraGroup } from '../models/CameraGroup';
import { CameraGroupsFilter, EmptyCameraGroupsFilter } from '../models/CameraGroupsFilter';
import { Camera, EmptyCamera } from '../models/Camera';
import { CamerasFilter, EmptyCamerasFilter } from '../models/CamerasFilter';
import { CarCardAttachment, EmptyCarCardAttachment } from '../models/CarCardAttachment';
import { CarCardAttachmentsFilter, EmptyCarCardAttachmentsFilter } from '../models/CarCardAttachmentsFilter';
import { CarCard, EmptyCarCard } from '../models/CarCard';
import { CardsCarsFilter, EmptyCardsCarsFilter } from '../models/CardsCarsFilter';
import { HumanCard, EmptyHumanCard } from '../models/HumanCard';
import { CardsHumansFilter, EmptyCardsHumansFilter } from '../models/CardsHumansFilter';
import { CaseAttachment, EmptyCaseAttachment } from '../models/CaseAttachment';
import { CaseAttachmentsFilter, EmptyCaseAttachmentsFilter } from '../models/CaseAttachmentsFilter';
import { CasePhoto, EmptyCasePhoto } from '../models/CasePhoto';
import { CasePhotosFilter, EmptyCasePhotosFilter } from '../models/CasePhotosFilter';
import { Case, EmptyCase } from '../models/Case';
import { CasesFilter, EmptyCasesFilter } from '../models/CasesFilter';
import { BodyClusterEvent, EmptyBodyClusterEvent } from '../models/BodyClusterEvent';
import { ClusterEventsBodiesFilter, EmptyClusterEventsBodiesFilter } from '../models/ClusterEventsBodiesFilter';
import { CarClusterEvent, EmptyCarClusterEvent } from '../models/CarClusterEvent';
import { ClusterEventsCarsFilter, EmptyClusterEventsCarsFilter } from '../models/ClusterEventsCarsFilter';
import { FaceClusterEvent, EmptyFaceClusterEvent } from '../models/FaceClusterEvent';
import { ClusterEventsFacesFilter, EmptyClusterEventsFacesFilter } from '../models/ClusterEventsFacesFilter';
import { ClusterizationJob, EmptyClusterizationJob } from '../models/ClusterizationJob';
import { ClusterizationJobsFilter, EmptyClusterizationJobsFilter } from '../models/ClusterizationJobsFilter';
import { BodyCluster, EmptyBodyCluster } from '../models/BodyCluster';
import { ClustersBodiesFilter, EmptyClustersBodiesFilter } from '../models/ClustersBodiesFilter';
import { ClustersBodiesContactsFilter, EmptyClustersBodiesContactsFilter } from '../models/ClustersBodiesContactsFilter';
import { ClustersBodiesContactsTracksFilter, EmptyClustersBodiesContactsTracksFilter } from '../models/ClustersBodiesContactsTracksFilter';
import { CarCluster, EmptyCarCluster } from '../models/CarCluster';
import { ClustersCarsFilter, EmptyClustersCarsFilter } from '../models/ClustersCarsFilter';
import { ClustersCarsContactsFilter, EmptyClustersCarsContactsFilter } from '../models/ClustersCarsContactsFilter';
import { ClustersCarsContactsTracksFilter, EmptyClustersCarsContactsTracksFilter } from '../models/ClustersCarsContactsTracksFilter';
import { FaceCluster, EmptyFaceCluster } from '../models/FaceCluster';
import { ClustersFacesFilter, EmptyClustersFacesFilter } from '../models/ClustersFacesFilter';
import { ClustersFacesContactsFilter, EmptyClustersFacesContactsFilter } from '../models/ClustersFacesContactsFilter';
import { ClustersFacesContactsTracksFilter, EmptyClustersFacesContactsTracksFilter } from '../models/ClustersFacesContactsTracksFilter';
import { CounterRecord, EmptyCounterRecord } from '../models/CounterRecord';
import { CounterRecordsFilter, EmptyCounterRecordsFilter } from '../models/CounterRecordsFilter';
import { Counter, EmptyCounter } from '../models/Counter';
import { CountersFilter, EmptyCountersFilter } from '../models/CountersFilter';
import { DeviceBlacklistRecord, EmptyDeviceBlacklistRecord } from '../models/DeviceBlacklistRecord';
import { DeviceBlacklistRecordsFilter, EmptyDeviceBlacklistRecordsFilter } from '../models/DeviceBlacklistRecordsFilter';
import { CarEpisode, EmptyCarEpisode } from '../models/CarEpisode';
import { EpisodesCarsFilter, EmptyEpisodesCarsFilter } from '../models/EpisodesCarsFilter';
import { HumanEpisode, EmptyHumanEpisode } from '../models/HumanEpisode';
import { EpisodesHumansFilter, EmptyEpisodesHumansFilter } from '../models/EpisodesHumansFilter';
import { BodyEvent, EmptyBodyEvent } from '../models/BodyEvent';
import { EventsBodiesFilter, EmptyEventsBodiesFilter } from '../models/EventsBodiesFilter';
import { CarEvent, EmptyCarEvent } from '../models/CarEvent';
import { EventsCarsFilter, EmptyEventsCarsFilter } from '../models/EventsCarsFilter';
import { FaceEvent, EmptyFaceEvent } from '../models/FaceEvent';
import { EventsFacesFilter, EmptyEventsFacesFilter } from '../models/EventsFacesFilter';
import { ExternalVms, EmptyExternalVms } from '../models/ExternalVms';
import { ExternalVmsFilter, EmptyExternalVmsFilter } from '../models/ExternalVmsFilter';
import { VmsPluginCamerasRequest, EmptyVmsPluginCamerasRequest } from '../models/VmsPluginCamerasRequest';
import { ExternalVmsCamerasFilter, EmptyExternalVmsCamerasFilter } from '../models/ExternalVmsCamerasFilter';
import { ExternalVmsEvent, EmptyExternalVmsEvent } from '../models/ExternalVmsEvent';
import { ExternalVmsEventsFilter, EmptyExternalVmsEventsFilter } from '../models/ExternalVmsEventsFilter';
import { ExternalVmsSendEventStatus, EmptyExternalVmsSendEventStatus } from '../models/ExternalVmsSendEventStatus';
import { ExternalVmsSendEventStatusFilter, EmptyExternalVmsSendEventStatusFilter } from '../models/ExternalVmsSendEventStatusFilter';
import { Group, EmptyGroup } from '../models/Group';
import { GroupsFilter, EmptyGroupsFilter } from '../models/GroupsFilter';
import { Webhook, EmptyWebhook } from '../models/Webhook';
import { HooksFilter, EmptyHooksFilter } from '../models/HooksFilter';
import { HumanCardAttachment, EmptyHumanCardAttachment } from '../models/HumanCardAttachment';
import { HumanCardAttachmentsFilter, EmptyHumanCardAttachmentsFilter } from '../models/HumanCardAttachmentsFilter';
import { Line, EmptyLine } from '../models/Line';
import { LinesFilter, EmptyLinesFilter } from '../models/LinesFilter';
import { MetaDictionary, EmptyMetaDictionary } from '../models/MetaDictionary';
import { MetaDictionariesFilter, EmptyMetaDictionariesFilter } from '../models/MetaDictionariesFilter';
import { MetaDictionaryValue, EmptyMetaDictionaryValue } from '../models/MetaDictionaryValue';
import { MetaDictionaryValuesFilter, EmptyMetaDictionaryValuesFilter } from '../models/MetaDictionaryValuesFilter';
import { Notification, EmptyNotification } from '../models/Notification';
import { NotificationsFilter, EmptyNotificationsFilter } from '../models/NotificationsFilter';
import { BodyObjectUpdate, EmptyBodyObjectUpdate } from '../models/BodyObjectUpdate';
import { ObjectsBodiesFilter, EmptyObjectsBodiesFilter } from '../models/ObjectsBodiesFilter';
import { CarObjectUpdate, EmptyCarObjectUpdate } from '../models/CarObjectUpdate';
import { ObjectsCarsFilter, EmptyObjectsCarsFilter } from '../models/ObjectsCarsFilter';
import { FaceObjectUpdate, EmptyFaceObjectUpdate } from '../models/FaceObjectUpdate';
import { ObjectsFacesFilter, EmptyObjectsFacesFilter } from '../models/ObjectsFacesFilter';
import { OnvifCamera, EmptyOnvifCamera } from '../models/OnvifCamera';
import { OnvifCamerasFilter, EmptyOnvifCamerasFilter } from '../models/OnvifCamerasFilter';
import { Permission, EmptyPermission } from '../models/Permission';
import { PermissionsFilter, EmptyPermissionsFilter } from '../models/PermissionsFilter';
import { DailySearchEvent, EmptyDailySearchEvent } from '../models/DailySearchEvent';
import { PuppeteerDailyEventsFilter, EmptyPuppeteerDailyEventsFilter } from '../models/PuppeteerDailyEventsFilter';
import { RemoteMonitoringRecord, EmptyRemoteMonitoringRecord } from '../models/RemoteMonitoringRecord';
import { PuppeteerRemoteMonitoringFilter, EmptyPuppeteerRemoteMonitoringFilter } from '../models/PuppeteerRemoteMonitoringFilter';
import { RemoteMonitoringEvent, EmptyRemoteMonitoringEvent } from '../models/RemoteMonitoringEvent';
import { PuppeteerRemoteMonitoringEventsFilter, EmptyPuppeteerRemoteMonitoringEventsFilter } from '../models/PuppeteerRemoteMonitoringEventsFilter';
import { SearchRequest, EmptySearchRequest } from '../models/SearchRequest';
import { PuppeteerSearchFilter, EmptyPuppeteerSearchFilter } from '../models/PuppeteerSearchFilter';
import { SearchResult, EmptySearchResult } from '../models/SearchResult';
import { PuppeteerSearchEventsFilter, EmptyPuppeteerSearchEventsFilter } from '../models/PuppeteerSearchEventsFilter';
import { VerboseRelationCarCardLink, EmptyVerboseRelationCarCardLink } from '../models/VerboseRelationCarCardLink';
import { RelationLinksCarFilter, EmptyRelationLinksCarFilter } from '../models/RelationLinksCarFilter';
import { VerboseRelationHumanCardLink, EmptyVerboseRelationHumanCardLink } from '../models/VerboseRelationHumanCardLink';
import { RelationLinksHumanFilter, EmptyRelationLinksHumanFilter } from '../models/RelationLinksHumanFilter';
import { Relation, EmptyRelation } from '../models/Relation';
import { RelationsFilter, EmptyRelationsFilter } from '../models/RelationsFilter';
import { Report, EmptyReport } from '../models/Report';
import { ReportsFilter, EmptyReportsFilter } from '../models/ReportsFilter';
import { AuthSession, EmptyAuthSession } from '../models/AuthSession';
import { SessionsFilter, EmptySessionsFilter } from '../models/SessionsFilter';
import { Track, EmptyTrack } from '../models/Track';
import { TracksFilter, EmptyTracksFilter } from '../models/TracksFilter';
import { UserFaceUpdate, EmptyUserFaceUpdate } from '../models/UserFaceUpdate';
import { UserFaceFilter, EmptyUserFaceFilter } from '../models/UserFaceFilter';
import { User, EmptyUser } from '../models/User';
import { UsersFilter, EmptyUsersFilter } from '../models/UsersFilter';
import { VideoArchive, EmptyVideoArchive } from '../models/VideoArchive';
import { VideosFilter, EmptyVideosFilter } from '../models/VideosFilter';
import { WatchList, EmptyWatchList } from '../models/WatchList';
import { WatchListsFilter, EmptyWatchListsFilter } from '../models/WatchListsFilter';
import { DataService, dataServiceFactory, DataServiceFactory } from "@/definitions/services/data.services";
import { startCase } from "@/common/filters";
import {CameraGroupPermissions, EmptyCameraGroupPermissions} from "@/api/models/CameraGroupPermissions";
import {
    EmptyPermissionsCameraGroupsFilter,
    PermissionsCameraGroupsFilter
} from "@/api/models/PermissionsCameraGroupsFilter";
import {EmptyPermissionsWatchListsFilter, PermissionsWatchListsFilter} from "@/api/models/PermissionsWatchListsFilter";
import {EmptyWatchListPermissions, WatchListPermissions} from "@/api/models/WatchListPermissions";
import { CaseEvent, EmptyCaseEvent } from "@/api/models/CaseEvent";
import { CaseEventsFilter, EmptyCaseEventsFilter } from "@/api/models/CaseEventsFilter";
import { CaseCluster, EmptyCaseCluster } from "@/api/models/CaseCluster";
import { CaseClustersFilter, EmptyCaseClustersFilter } from "@/api/models/CaseClustersFilter";
import { CustomReport } from '../models/CustomReports';
import { CasePermissions, EmptyCasePermissions } from '@/api/models/CasePermissions';
import { mockEpisodeOrEvent } from '@/store/mock';
import { Alert, EmptyAlert } from "@/api/models/Alert";
import { AlertsFilter, EmptyAlertsFilter } from "@/api/models/AlertFilter";
import { deleteEmptyFilters } from '@/pages/alerts/alert.definitions';

export class DataServiceRepository {
    constructor(private _dataServiceRepository: DataServiceFactory) {}
    get AreaTriggerActivationsService(): DataService<AreaTriggerActivation, AreaTriggerActivationsFilter> {
        return this._dataServiceRepository.getService('/area-trigger-activations/');
    }
    get AreaTriggerRecordsService(): DataService<AreaTriggerRecord, AreaTriggerRecordsFilter> {
        return this._dataServiceRepository.getService('/area-trigger-records/');
    }
    get AreasService(): DataService<Area, AreasFilter> {
        return this._dataServiceRepository.getService('/areas/');
    }
    get AuditLogsService(): DataService<AuditLog, AuditLogsFilter> {
        return this._dataServiceRepository.getService('/audit-logs/');
    }
    get BatchUploadService(): DataService<UploadList, BatchUploadFilter> {
        return this._dataServiceRepository.getService('/batch-upload/');
    }
    get BatchUploadEntryService(): DataService<Upload, BatchUploadEntryFilter> {
        return this._dataServiceRepository.getService('/batch-upload-entry/');
    }
    get CameraGroupsService(): DataService<CameraGroup, CameraGroupsFilter> {
        return this._dataServiceRepository.getService('/camera-groups/');
    }
    get CamerasService(): DataService<Camera, CamerasFilter> {
        return this._dataServiceRepository.getService('/cameras/');
    }

    get CaseClustersService(): DataService<CaseCluster, CaseClustersFilter> {
        return this._dataServiceRepository.getService('/case-clusters/');
    }

    get CaseEventsService(): DataService<CaseEvent, CaseEventsFilter> {
        return this._dataServiceRepository.getService('/case-events/');
    }

    get CasePhotosService(): DataService<CasePhoto, CasePhotosFilter> {
        return this._dataServiceRepository.getService('/case-photos/');
    }

    get CarCardAttachmentsService(): DataService<CarCardAttachment, CarCardAttachmentsFilter> {
        return this._dataServiceRepository.getService('/car-card-attachments/');
    }
    get CardsCarsService(): DataService<CarCard, CardsCarsFilter> {
        return this._dataServiceRepository.getService('/cards/cars/');
    }
    get CardsHumansService(): DataService<HumanCard, CardsHumansFilter> {
        return this._dataServiceRepository.getService('/cards/humans/');
    }
    get CaseAttachmentsService(): DataService<CaseAttachment, CaseAttachmentsFilter> {
        return this._dataServiceRepository.getService('/case-attachments/');
    }
    get CasesService(): DataService<Case, CasesFilter> {
        return this._dataServiceRepository.getService('/cases/');
    }
    get ClusterEventsBodiesService(): DataService<BodyClusterEvent, ClusterEventsBodiesFilter> {
        return this._dataServiceRepository.getService('/cluster-events/bodies/');
    }
    get ClusterEventsCarsService(): DataService<CarClusterEvent, ClusterEventsCarsFilter> {
        return this._dataServiceRepository.getService('/cluster-events/cars/');
    }
    get ClusterEventsFacesService(): DataService<FaceClusterEvent, ClusterEventsFacesFilter> {
        return this._dataServiceRepository.getService('/cluster-events/faces/');
    }
    get ClusterizationJobsService(): DataService<ClusterizationJob, ClusterizationJobsFilter> {
        return this._dataServiceRepository.getService('/clusterization-jobs/');
    }
    get ClustersBodiesService(): DataService<BodyCluster, ClustersBodiesFilter> {
        return this._dataServiceRepository.getService('/clusters/bodies/');
    }
    get ClustersBodiesContactsService(): DataService<BodyCluster, ClustersBodiesContactsFilter> {
        return this._dataServiceRepository.getService('/clusters/bodies/${id}/contacts/');
    }
    get ClustersBodiesContactsTracksService(): DataService<BodyCluster, ClustersBodiesContactsTracksFilter> {
        return this._dataServiceRepository.getService('/clusters/bodies/${id}/contacts_tracks/');
    }
    get ClustersCarsService(): DataService<CarCluster, ClustersCarsFilter> {
        return this._dataServiceRepository.getService('/clusters/cars/');
    }
    get ClustersCarsContactsService(): DataService<CarCluster, ClustersCarsContactsFilter> {
        return this._dataServiceRepository.getService('/clusters/cars/${id}/contacts/');
    }
    get ClustersCarsContactsTracksService(): DataService<CarCluster, ClustersCarsContactsTracksFilter> {
        return this._dataServiceRepository.getService('/clusters/cars/${id}/contacts_tracks/');
    }
    get ClustersFacesService(): DataService<FaceCluster, ClustersFacesFilter> {
        return this._dataServiceRepository.getService('/clusters/faces/');
    }
    get ClustersFacesContactsService(): DataService<FaceCluster, ClustersFacesContactsFilter> {
        return this._dataServiceRepository.getService('/clusters/faces/${id}/contacts/');
    }
    get ClustersFacesContactsTracksService(): DataService<FaceCluster, ClustersFacesContactsTracksFilter> {
        return this._dataServiceRepository.getService('/clusters/faces/${id}/contacts_tracks/');
    }
    get CounterRecordsService(): DataService<CounterRecord, CounterRecordsFilter> {
        return this._dataServiceRepository.getService('/counter-records/');
    }
    get CountersService(): DataService<Counter, CountersFilter> {
        return this._dataServiceRepository.getService('/counters/');
    }
    get DeviceBlacklistRecordsService(): DataService<DeviceBlacklistRecord, DeviceBlacklistRecordsFilter> {
        return this._dataServiceRepository.getService('/device-blacklist-records/');
    }
    get EpisodesCarsService(): DataService<CarEpisode, EpisodesCarsFilter> {
        return this._dataServiceRepository.getService('/episodes/cars/');
    }
    get EpisodesHumansService(): DataService<HumanEpisode, EpisodesHumansFilter> {
        return this._dataServiceRepository.getService('/episodes/humans/');
    }
    get EventsBodiesService(): DataService<BodyEvent, EventsBodiesFilter> {
        return this._dataServiceRepository.getService('/events/bodies/');
    }
    get EventsCarsService(): DataService<CarEvent, EventsCarsFilter> {
        return this._dataServiceRepository.getService('/events/cars/');
    }
    get EventsFacesService(): DataService<FaceEvent, EventsFacesFilter> {
        return this._dataServiceRepository.getService('/events/faces/');
    }
    get ExternalVmsService(): DataService<ExternalVms, ExternalVmsFilter> {
        return this._dataServiceRepository.getService('/external-vms/');
    }
    get ExternalVmsCamerasService(): DataService<VmsPluginCamerasRequest, ExternalVmsCamerasFilter> {
        return this._dataServiceRepository.getService('/external-vms/${id}/cameras/');
    }
    get ExternalVmsEventsService(): DataService<ExternalVmsEvent, ExternalVmsEventsFilter> {
        return this._dataServiceRepository.getService('/external-vms/events/');
    }
    get ExternalVmsSendEventStatusService(): DataService<ExternalVmsSendEventStatus, ExternalVmsSendEventStatusFilter> {
        return this._dataServiceRepository.getService('/external-vms/send-event-status/');
    }
    get GroupsService(): DataService<Group, GroupsFilter> {
        return this._dataServiceRepository.getService('/groups/');
    }
    get AlertService(): DataService<Alert, AlertsFilter> {
        return this._dataServiceRepository.getService('/alerts/');
    }
    get HooksService(): DataService<Webhook, HooksFilter> {
        return this._dataServiceRepository.getService('/hooks/');
    }
    get HumanCardAttachmentsService(): DataService<HumanCardAttachment, HumanCardAttachmentsFilter> {
        return this._dataServiceRepository.getService('/human-card-attachments/');
    }
    get LinesService(): DataService<Line, LinesFilter> {
        return this._dataServiceRepository.getService('/lines/');
    }
    get MetaDictionariesService(): DataService<MetaDictionary, MetaDictionariesFilter> {
        return this._dataServiceRepository.getService('/meta-dictionaries/');
    }
    get MetaDictionaryValuesService(): DataService<MetaDictionaryValue, MetaDictionaryValuesFilter> {
        return this._dataServiceRepository.getService('/meta-dictionary-values/');
    }
    get NotificationsService(): DataService<Notification, NotificationsFilter> {
        return this._dataServiceRepository.getService('/notifications/');
    }
    get ObjectsBodiesService(): DataService<BodyObjectUpdate, ObjectsBodiesFilter> {
        return this._dataServiceRepository.getService('/objects/bodies/');
    }
    get ObjectsCarsService(): DataService<CarObjectUpdate, ObjectsCarsFilter> {
        return this._dataServiceRepository.getService('/objects/cars/');
    }
    get ObjectsFacesService(): DataService<FaceObjectUpdate, ObjectsFacesFilter> {
        return this._dataServiceRepository.getService('/objects/faces/');
    }
    get OnvifCamerasService(): DataService<OnvifCamera, OnvifCamerasFilter> {
        return this._dataServiceRepository.getService('/onvif-cameras/');
    }
    get PermissionsService(): DataService<Permission, PermissionsFilter> {
        return this._dataServiceRepository.getService('/permissions/');
    }
    get PermissionsCameraGroupsService(): DataService<CameraGroupPermissions, PermissionsCameraGroupsFilter> {
        return this._dataServiceRepository.getService('/permissions/camera-groups/');
    }
    get PermissionsWatchListsService(): DataService<WatchListPermissions, PermissionsWatchListsFilter> {
        return this._dataServiceRepository.getService('/permissions/watch-lists/');
    }
    get PuppeteerDailyEventsService(): DataService<DailySearchEvent, PuppeteerDailyEventsFilter> {
        return this._dataServiceRepository.getService('/puppeteer/daily/events/');
    }
    get PuppeteerRemoteMonitoringService(): DataService<RemoteMonitoringRecord, PuppeteerRemoteMonitoringFilter> {
        return this._dataServiceRepository.getService('/puppeteer/remote-monitoring/');
    }
    get PuppeteerRemoteMonitoringEventsService(): DataService<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter> {
        return this._dataServiceRepository.getService('/puppeteer/remote-monitoring/events/');
    }
    get PuppeteerSearchService(): DataService<SearchRequest, PuppeteerSearchFilter> {
        return this._dataServiceRepository.getService('/puppeteer/search/');
    }
    get PuppeteerSearchEventsService(): DataService<SearchResult, PuppeteerSearchEventsFilter> {
        return this._dataServiceRepository.getService('/puppeteer/search/events/');
    }
    get RelationLinksCarService(): DataService<VerboseRelationCarCardLink, RelationLinksCarFilter> {
        return this._dataServiceRepository.getService('/relation-links/car/');
    }
    get RelationLinksHumanService(): DataService<VerboseRelationHumanCardLink, RelationLinksHumanFilter> {
        return this._dataServiceRepository.getService('/relation-links/human/');
    }
    get RelationsService(): DataService<Relation, RelationsFilter> {
        return this._dataServiceRepository.getService('/relations/');
    }
    get ReportsService(): DataService<Report, ReportsFilter> {
        return this._dataServiceRepository.getService('/reports/');
    }
    get SessionsService(): DataService<AuthSession, SessionsFilter> {
        return this._dataServiceRepository.getService('/sessions/');
    }
    get TracksService(): DataService<Track, TracksFilter> {
        return this._dataServiceRepository.getService('/tracks/');
    }
    get UserFaceService(): DataService<UserFaceUpdate, UserFaceFilter> {
        return this._dataServiceRepository.getService('/user-face/');
    }
    get UsersService(): DataService<User, UsersFilter> {
        return this._dataServiceRepository.getService('/users/');
    }
    get VideosService(): DataService<VideoArchive, VideosFilter> {
        return this._dataServiceRepository.getService('/videos/');
    }
    get WatchListsService(): DataService<WatchList, WatchListsFilter> {
        return this._dataServiceRepository.getService('/watch-lists/');
    }
}
export class ViewModelRepository {
    constructor(private _dataServiceRepository: DataServiceFactory) {}
    getAreaTriggerActivationsListViewModel(): ListViewModel<AreaTriggerActivation, AreaTriggerActivationsFilter> {
        const options = {
            url: '/area-trigger-activations/',
            routeName: 'AreaTriggerActivations',
            emptyItem: EmptyAreaTriggerActivation,
            emptyFilter: EmptyAreaTriggerActivationsFilter
        };
        return getListViewModel(options);
    }
    getAreaTriggerActivationsItemViewModel(): ItemViewModel<AreaTriggerActivation> {
        const options = {
            url: '/area-trigger-activations/',
            routeName: 'AreaTriggerActivations',
            emptyItem: EmptyAreaTriggerActivation
        };
        return getItemViewModel(options);
    }
    getAreaTriggerRecordsListViewModel(): ListViewModel<AreaTriggerRecord, AreaTriggerRecordsFilter> {
        const options = {
            url: '/area-trigger-records/',
            routeName: 'AreaTriggerRecords',
            emptyItem: EmptyAreaTriggerRecord,
            emptyFilter: EmptyAreaTriggerRecordsFilter
        };
        return getListViewModel(options);
    }
    getAreaTriggerRecordsItemViewModel(): ItemViewModel<AreaTriggerRecord> {
        const options = {
            url: '/area-trigger-records/',
            routeName: 'AreaTriggerRecords',
            emptyItem: EmptyAreaTriggerRecord
        };
        return getItemViewModel(options);
    }
    getAreasListViewModel(): ListViewModel<Area, AreasFilter> {
        const options = {
            url: '/areas/',
            routeName: 'Areas',
            emptyItem: EmptyArea,
            emptyFilter: EmptyAreasFilter
        };
        return getListViewModel(options);
    }
    getAreasItemViewModel(): ItemViewModel<Area> {
        const options = {
            url: '/areas/',
            routeName: 'Areas',
            emptyItem: EmptyArea
        };
        return getItemViewModel(options);
    }
    getAuditLogsListViewModel(): ListViewModel<AuditLog, AuditLogsFilter> {
        const options = {
            url: '/audit-logs/',
            routeName: 'AuditLogs',
            emptyItem: EmptyAuditLog,
            emptyFilter: EmptyAuditLogsFilter
        };
        return getListViewModel(options);
    }
    getAuditLogsItemViewModel(): ItemViewModel<AuditLog> {
        const options = {
            url: '/audit-logs/',
            routeName: 'AuditLogs',
            emptyItem: EmptyAuditLog
        };
        return getItemViewModel(options);
    }
    getBatchUploadListViewModel(): ListViewModel<UploadList, BatchUploadFilter> {
        const options = {
            url: '/batch-upload/',
            routeName: 'BatchUpload',
            emptyItem: EmptyUploadList,
            emptyFilter: EmptyBatchUploadFilter
        };
        return getListViewModel(options);
    }
    getBatchUploadItemViewModel(): ItemViewModel<UploadList> {
        const options = {
            url: '/batch-upload/',
            routeName: 'BatchUpload',
            emptyItem: EmptyUploadList
        };
        return getItemViewModel(options);
    }
    getBatchUploadEntryListViewModel(): ListViewModel<Upload, BatchUploadEntryFilter> {
        const options = {
            url: '/batch-upload-entry/',
            routeName: 'BatchUploadEntry',
            emptyItem: EmptyUpload,
            emptyFilter: EmptyBatchUploadEntryFilter
        };
        return getListViewModel(options);
    }
    getBatchUploadEntryItemViewModel(): ItemViewModel<Upload> {
        const options = {
            url: '/batch-upload-entry/',
            routeName: 'BatchUploadEntry',
            emptyItem: EmptyUpload
        };
        return getItemViewModel(options);
    }
    getCameraGroupsListViewModel(): ListViewModel<CameraGroup, CameraGroupsFilter> {
        const options = {
            url: '/camera-groups/',
            routeName: 'CameraGroups',
            emptyItem: EmptyCameraGroup,
            emptyFilter: EmptyCameraGroupsFilter
        };
        return getListViewModel(options);
    }
    getCameraGroupsItemViewModel(): ItemViewModel<CameraGroup> {
        const options = {
            url: '/camera-groups/',
            routeName: 'CameraGroups',
            emptyItem: EmptyCameraGroup
        };
        return getItemViewModel(options);
    }


    getAlertsListViewModel(): ListViewModel<Alert, AlertsFilter> {
        const options = {
            url: '/v1/alert-rules/',
            routeName: 'Alerts',
            emptyItem: EmptyAlert,
            emptyFilter: EmptyAlertsFilter,
            // @todor: Wait for backend fix
            mockItemOnLoad: (item: Alert) => {
                deleteEmptyFilters(item.filters as any);
                return item;
            }
        };
        return getListViewModel(options);
    }

    getAlertsItemViewModel(): ItemViewModel<Alert> {
        const options = {
            url: '/v1/alert-rules/',
            routeName: 'Alerts',
            emptyItem: EmptyAlert
        };
        return getItemViewModel(options);
    }


    getCamerasListViewModel(): ListViewModel<Camera, CamerasFilter> {
        const options = {
            url: '/cameras/',
            routeName: 'Cameras',
            emptyItem: EmptyCamera,
            emptyFilter: EmptyCamerasFilter
        };
        return getListViewModel(options);
    }
    getCamerasItemViewModel(): ItemViewModel<Camera> {
        const options = {
            url: '/cameras/',
            routeName: 'Cameras',
            emptyItem: EmptyCamera,
            excludedChangeKeys: ['health_status', 'face_count', 'body_count', 'car_count', 'frame_width', 'frame_height']
        };
        return getItemViewModel(options);
    }
    getCarCardAttachmentsListViewModel(): ListViewModel<CarCardAttachment, CarCardAttachmentsFilter> {
        const options = {
            url: '/car-card-attachments/',
            routeName: 'CarCardAttachments',
            emptyItem: EmptyCarCardAttachment,
            emptyFilter: EmptyCarCardAttachmentsFilter
        };
        return getListViewModel(options);
    }
    getCarCardAttachmentsItemViewModel(): ItemViewModel<CarCardAttachment> {
        const options = {
            url: '/car-card-attachments/',
            routeName: 'CarCardAttachments',
            emptyItem: EmptyCarCardAttachment
        };
        return getItemViewModel(options);
    }
    getCardsCarsListViewModel(): ListViewModel<CarCard, CardsCarsFilter> {
        const options = {
            url: '/cards/cars/',
            routeName: 'CardsCars',
            emptyItem: EmptyCarCard,
            emptyFilter: EmptyCardsCarsFilter
        };
        return getListViewModel(options);
    }
    getCardsCarsItemViewModel(): ItemViewModel<CarCard> {
        const options = {
            url: '/cards/cars/',
            routeName: 'CardsCars',
            emptyItem: EmptyCarCard
        };
        return getItemViewModel(options);
    }
    getCardsHumansListViewModel(): ListViewModel<HumanCard, CardsHumansFilter> {
        const options = {
            url: '/cards/humans/',
            routeName: 'CardsHumans',
            emptyItem: EmptyHumanCard,
            emptyFilter: EmptyCardsHumansFilter
        };
        return getListViewModel(options);
    }
    getCardsHumansItemViewModel(): ItemViewModel<HumanCard> {
        const options = {
            url: '/cards/humans/',
            routeName: 'CardsHumans',
            emptyItem: EmptyHumanCard
        };
        return getItemViewModel(options);
    }
    getCaseAttachmentsListViewModel(): ListViewModel<CaseAttachment, CaseAttachmentsFilter> {
        const options = {
            url: '/case-attachments/',
            routeName: 'CaseAttachments',
            emptyItem: EmptyCaseAttachment,
            emptyFilter: EmptyCaseAttachmentsFilter
        };
        return getListViewModel(options);
    }
    getCaseAttachmentsItemViewModel(): ItemViewModel<CaseAttachment> {
        const options = {
            url: '/case-attachments/',
            routeName: 'CaseAttachments',
            emptyItem: EmptyCaseAttachment
        };
        return getItemViewModel(options);
    }
    getCaseClustersListViewModel(): ListViewModel<CaseCluster, CaseClustersFilter> {
        const options = {
            url: '/case-clusters/',
            routeName: 'CaseClusters',
            emptyItem: EmptyCaseCluster,
            emptyFilter: EmptyCaseClustersFilter,
        };
        return getListViewModel(options);
    }
    getCaseClustersItemViewModel(): ItemViewModel<CaseCluster> {
        const options = {
            url: '/case-clusters/',
            routeName: 'CaseClusters',
            emptyItem: EmptyCaseCluster,
        };
        return getItemViewModel(options);
    }
    getCaseParticipantsListViewModel(): ListViewModel<CaseCluster, CaseClustersFilter> {
        const options = {
            url: '/case-clusters/',
            routeName: 'CaseClusters',
            emptyItem: EmptyCaseCluster,
            emptyFilter: EmptyCaseClustersFilter,
        };
        const result = getListViewModel(options);
        result.filter.force.is_case_participant = true;
        return result;
    }
    getCaseParticipantsItemViewModel(): ItemViewModel<CaseCluster> {
        const options = {
            url: '/case-clusters/',
            routeName: 'CaseClusters',
            emptyItem: EmptyCaseCluster,
        };
        return getItemViewModel(options);
    }
    getCaseEventsListViewModel(): ListViewModel<CaseEvent, CaseEventsFilter> {
        const options = {
            url: '/case-events/',
            routeName: 'CaseEvents',
            emptyItem: EmptyCaseEvent,
            emptyFilter: EmptyCaseEventsFilter
        };
        return getListViewModel(options);
    }
    getCaseEventsItemViewModel(): ItemViewModel<CaseEvent> {
        const options = {
            url: '/case-events/',
            routeName: 'CaseEvents',
            emptyItem: EmptyCaseEvent
        };
        return getItemViewModel(options);
    }

    getCasePhotosListViewModel(): ListViewModel<CasePhoto, CasePhotosFilter> {
        const options = {
            url: '/case-photos/',
            routeName: 'CasePhotos',
            emptyItem: EmptyCasePhoto,
            emptyFilter: EmptyCasePhotosFilter
        };
        return getListViewModel(options);
    }
    getCasePhotosItemViewModel(): ItemViewModel<CasePhoto> {
        const options = {
            url: '/case-photos/',
            routeName: 'CasePhotos',
            emptyItem: EmptyCasePhoto,
            excludedChangeKeys: ['status', 'file_size']
        };
        return getItemViewModel(options);
    }

    getCasesListViewModel(): ListViewModel<Case, CasesFilter> {
        const options = {
            url: '/cases/',
            routeName: 'Cases',
            emptyItem: EmptyCase,
            emptyFilter: EmptyCasesFilter
            // mockItemOnLoad: mockCase
        };
        return getListViewModel(options);
    }
    getCasesItemViewModel(): ItemViewModel<Case> {
        const options = {
            url: '/cases/',
            routeName: 'Cases',
            emptyItem: EmptyCase
            // mockItemOnLoad: mockCase
        };
        return getItemViewModel(options);
    }
    getClusterEventsBodiesListViewModel(): ListViewModel<BodyClusterEvent, ClusterEventsBodiesFilter> {
        const options = {
            url: '/cluster-events/bodies/',
            routeName: 'ClusterEventsBodies',
            emptyItem: EmptyBodyClusterEvent,
            emptyFilter: EmptyClusterEventsBodiesFilter
        };
        return getListViewModel(options);
    }
    getClusterEventsBodiesItemViewModel(): ItemViewModel<BodyClusterEvent> {
        const options = {
            url: '/cluster-events/bodies/',
            routeName: 'ClusterEventsBodies',
            emptyItem: EmptyBodyClusterEvent
        };
        return getItemViewModel(options);
    }
    getClusterEventsCarsListViewModel(): ListViewModel<CarClusterEvent, ClusterEventsCarsFilter> {
        const options = {
            url: '/cluster-events/cars/',
            routeName: 'ClusterEventsCars',
            emptyItem: EmptyCarClusterEvent,
            emptyFilter: EmptyClusterEventsCarsFilter
        };
        return getListViewModel(options);
    }
    getClusterEventsCarsItemViewModel(): ItemViewModel<CarClusterEvent> {
        const options = {
            url: '/cluster-events/cars/',
            routeName: 'ClusterEventsCars',
            emptyItem: EmptyCarClusterEvent
        };
        return getItemViewModel(options);
    }
    getClusterEventsFacesListViewModel(): ListViewModel<FaceClusterEvent, ClusterEventsFacesFilter> {
        const options = {
            url: '/cluster-events/faces/',
            routeName: 'ClusterEventsFaces',
            emptyItem: EmptyFaceClusterEvent,
            emptyFilter: EmptyClusterEventsFacesFilter
        };
        return getListViewModel(options);
    }
    getClusterEventsFacesItemViewModel(): ItemViewModel<FaceClusterEvent> {
        const options = {
            url: '/cluster-events/faces/',
            routeName: 'ClusterEventsFaces',
            emptyItem: EmptyFaceClusterEvent
        };
        return getItemViewModel(options);
    }
    getClusterizationJobsListViewModel(): ListViewModel<ClusterizationJob, ClusterizationJobsFilter> {
        const options = {
            url: '/clusterization-jobs/',
            routeName: 'ClusterizationJobs',
            emptyItem: EmptyClusterizationJob,
            emptyFilter: EmptyClusterizationJobsFilter
        };
        return getListViewModel(options);
    }
    getClusterizationJobsItemViewModel(): ItemViewModel<ClusterizationJob> {
        const options = {
            url: '/clusterization-jobs/',
            routeName: 'ClusterizationJobs',
            emptyItem: EmptyClusterizationJob
        };
        return getItemViewModel(options);
    }
    getClustersBodiesListViewModel(): ListViewModel<BodyCluster, ClustersBodiesFilter> {
        const options = {
            url: '/clusters/bodies/',
            routeName: 'ClustersBodies',
            emptyItem: EmptyBodyCluster,
            emptyFilter: EmptyClustersBodiesFilter
        };
        return getListViewModel(options);
    }
    getClustersBodiesItemViewModel(): ItemViewModel<BodyCluster> {
        const options = {
            url: '/clusters/bodies/',
            routeName: 'ClustersBodies',
            emptyItem: EmptyBodyCluster
        };
        return getItemViewModel(options);
    }
    getClustersBodiesContactsListViewModel(): ListViewModel<BodyCluster, ClustersBodiesContactsFilter> {
        const options = {
            url: '/clusters/bodies/${id}/contacts/',
            routeName: 'ClustersBodiesContacts',
            emptyItem: EmptyBodyCluster,
            emptyFilter: EmptyClustersBodiesContactsFilter
        };
        return getListViewModel(options);
    }
    getClustersBodiesContactsItemViewModel(): ItemViewModel<BodyCluster> {
        const options = {
            url: '/clusters/bodies/${id}/contacts/',
            routeName: 'ClustersBodiesContacts',
            emptyItem: EmptyBodyCluster
        };
        return getItemViewModel(options);
    }
    getClustersBodiesContactsTracksListViewModel(): ListViewModel<BodyCluster, ClustersBodiesContactsTracksFilter> {
        const options = {
            url: '/clusters/bodies/${id}/contacts_tracks/',
            routeName: 'ClustersBodiesContactsTracks',
            emptyItem: EmptyBodyCluster,
            emptyFilter: EmptyClustersBodiesContactsTracksFilter
        };
        return getListViewModel(options);
    }
    getClustersBodiesContactsTracksItemViewModel(): ItemViewModel<BodyCluster> {
        const options = {
            url: '/clusters/bodies/${id}/contacts_tracks/',
            routeName: 'ClustersBodiesContactsTracks',
            emptyItem: EmptyBodyCluster
        };
        return getItemViewModel(options);
    }
    getClustersCarsListViewModel(): ListViewModel<CarCluster, ClustersCarsFilter> {
        const options = {
            url: '/clusters/cars/',
            routeName: 'ClustersCars',
            emptyItem: EmptyCarCluster,
            emptyFilter: EmptyClustersCarsFilter
        };
        return getListViewModel(options);
    }
    getClustersCarsItemViewModel(): ItemViewModel<CarCluster> {
        const options = {
            url: '/clusters/cars/',
            routeName: 'ClustersCars',
            emptyItem: EmptyCarCluster
        };
        return getItemViewModel(options);
    }
    getClustersCarsContactsListViewModel(): ListViewModel<CarCluster, ClustersCarsContactsFilter> {
        const options = {
            url: '/clusters/cars/${id}/contacts/',
            routeName: 'ClustersCarsContacts',
            emptyItem: EmptyCarCluster,
            emptyFilter: EmptyClustersCarsContactsFilter
        };
        return getListViewModel(options);
    }
    getClustersCarsContactsItemViewModel(): ItemViewModel<CarCluster> {
        const options = {
            url: '/clusters/cars/${id}/contacts/',
            routeName: 'ClustersCarsContacts',
            emptyItem: EmptyCarCluster
        };
        return getItemViewModel(options);
    }
    getClustersCarsContactsTracksListViewModel(): ListViewModel<CarCluster, ClustersCarsContactsTracksFilter> {
        const options = {
            url: '/clusters/cars/${id}/contacts_tracks/',
            routeName: 'ClustersCarsContactsTracks',
            emptyItem: EmptyCarCluster,
            emptyFilter: EmptyClustersCarsContactsTracksFilter
        };
        return getListViewModel(options);
    }
    getClustersCarsContactsTracksItemViewModel(): ItemViewModel<CarCluster> {
        const options = {
            url: '/clusters/cars/${id}/contacts_tracks/',
            routeName: 'ClustersCarsContactsTracks',
            emptyItem: EmptyCarCluster
        };
        return getItemViewModel(options);
    }
    getClustersFacesListViewModel(): ListViewModel<FaceCluster, ClustersFacesFilter> {
        const options = {
            url: '/clusters/faces/',
            routeName: 'ClustersFaces',
            emptyItem: EmptyFaceCluster,
            emptyFilter: EmptyClustersFacesFilter
        };
        return getListViewModel(options);
    }
    getClustersFacesItemViewModel(): ItemViewModel<FaceCluster> {
        const options = {
            url: '/clusters/faces/',
            routeName: 'ClustersFaces',
            emptyItem: EmptyFaceCluster
        };
        return getItemViewModel(options);
    }
    getClustersFacesContactsListViewModel(): ListViewModel<FaceCluster, ClustersFacesContactsFilter> {
        const options = {
            url: '/clusters/faces/${id}/contacts/',
            routeName: 'ClustersFacesContacts',
            emptyItem: EmptyFaceCluster,
            emptyFilter: EmptyClustersFacesContactsFilter
        };
        return getListViewModel(options);
    }
    getClustersFacesContactsItemViewModel(): ItemViewModel<FaceCluster> {
        const options = {
            url: '/clusters/faces/${id}/contacts/',
            routeName: 'ClustersFacesContacts',
            emptyItem: EmptyFaceCluster
        };
        return getItemViewModel(options);
    }
    getClustersFacesContactsTracksListViewModel(): ListViewModel<FaceCluster, ClustersFacesContactsTracksFilter> {
        const options = {
            url: '/clusters/faces/${id}/contacts_tracks/',
            routeName: 'ClustersFacesContactsTracks',
            emptyItem: EmptyFaceCluster,
            emptyFilter: EmptyClustersFacesContactsTracksFilter
        };
        return getListViewModel(options);
    }
    getClustersFacesContactsTracksItemViewModel(): ItemViewModel<FaceCluster> {
        const options = {
            url: '/clusters/faces/${id}/contacts_tracks/',
            routeName: 'ClustersFacesContactsTracks',
            emptyItem: EmptyFaceCluster
        };
        return getItemViewModel(options);
    }
    getCounterRecordsListViewModel(): ListViewModel<CounterRecord, CounterRecordsFilter> {
        const options = {
            url: '/counter-records/',
            routeName: 'CounterRecords',
            emptyItem: EmptyCounterRecord,
            emptyFilter: EmptyCounterRecordsFilter
        };
        return getListViewModel(options);
    }
    getCounterRecordsItemViewModel(): ItemViewModel<CounterRecord> {
        const options = {
            url: '/counter-records/',
            routeName: 'CounterRecords',
            emptyItem: EmptyCounterRecord
        };
        return getItemViewModel(options);
    }
    getCountersListViewModel(): ListViewModel<Counter, CountersFilter> {
        const options = {
            url: '/counters/',
            routeName: 'Counters',
            emptyItem: EmptyCounter,
            emptyFilter: EmptyCountersFilter
        };
        return getListViewModel(options);
    }
    getCountersItemViewModel(): ItemViewModel<Counter> {
        const options = {
            url: '/counters/',
            routeName: 'Counters',
            emptyItem: EmptyCounter
        };
        return getItemViewModel(options);
    }
    getDeviceBlacklistRecordsListViewModel(): ListViewModel<DeviceBlacklistRecord, DeviceBlacklistRecordsFilter> {
        const options = {
            url: '/device-blacklist-records/',
            routeName: 'DeviceBlacklistRecords',
            emptyItem: EmptyDeviceBlacklistRecord,
            emptyFilter: EmptyDeviceBlacklistRecordsFilter
        };
        return getListViewModel(options);
    }
    getDeviceBlacklistRecordsItemViewModel(): ItemViewModel<DeviceBlacklistRecord> {
        const options = {
            url: '/device-blacklist-records/',
            routeName: 'DeviceBlacklistRecords',
            emptyItem: EmptyDeviceBlacklistRecord
        };
        return getItemViewModel(options);
    }
    getEpisodesCarsListViewModel(): ListViewModel<CarEpisode, EpisodesCarsFilter> {
        const options = {
            url: '/episodes/cars/',
            routeName: 'EpisodesCars',
            emptyItem: EmptyCarEpisode,
            emptyFilter: EmptyEpisodesCarsFilter
        };
        return getListViewModel(options);
    }
    getEpisodesCarsItemViewModel(): ItemViewModel<CarEpisode> {
        const options = {
            url: '/episodes/cars/',
            routeName: 'EpisodesCars',
            emptyItem: EmptyCarEpisode
        };
        return getItemViewModel(options);
    }
    getEpisodesHumansListViewModel(): ListViewModel<HumanEpisode, EpisodesHumansFilter> {
        const options = {
            url: '/episodes/humans/',
            routeName: 'EpisodesHumans',
            emptyItem: EmptyHumanEpisode,
            emptyFilter: EmptyEpisodesHumansFilter,
            // mockItemOnLoad: mockEpisodeOrEvent
        };
        return getListViewModel(options);
    }
    getEpisodesHumansItemViewModel(): ItemViewModel<HumanEpisode> {
        const options = {
            url: '/episodes/humans/',
            routeName: 'EpisodesHumans',
            emptyItem: EmptyHumanEpisode
        };
        return getItemViewModel(options);
    }
    getEventsBodiesListViewModel(): ListViewModel<BodyEvent, EventsBodiesFilter> {
        const options = {
            url: '/events/bodies/',
            routeName: 'EventsBodies',
            emptyItem: EmptyBodyEvent,
            emptyFilter: EmptyEventsBodiesFilter
        };
        return getListViewModel(options);
    }
    getEventsBodiesItemViewModel(): ItemViewModel<BodyEvent> {
        const options = {
            url: '/events/bodies/',
            routeName: 'EventsBodies',
            emptyItem: EmptyBodyEvent
        };
        return getItemViewModel(options);
    }
    getEventsCarsListViewModel(): ListViewModel<CarEvent, EventsCarsFilter> {
        const options = {
            url: '/events/cars/',
            routeName: 'EventsCars',
            emptyItem: EmptyCarEvent,
            emptyFilter: EmptyEventsCarsFilter
        };
        return getListViewModel(options);
    }
    getEventsCarsItemViewModel(): ItemViewModel<CarEvent> {
        const options = {
            url: '/events/cars/',
            routeName: 'EventsCars',
            emptyItem: EmptyCarEvent
        };
        return getItemViewModel(options);
    }
    getEventsFacesListViewModel(): ListViewModel<FaceEvent, EventsFacesFilter> {
        const options = {
            url: '/events/faces/',
            routeName: 'EventsFaces',
            emptyItem: EmptyFaceEvent,
            emptyFilter: EmptyEventsFacesFilter,
            // mockItemOnLoad: mockEpisodeOrEvent
        };
        return getListViewModel(options);
    }
    getEventsFacesItemViewModel(): ItemViewModel<FaceEvent> {
        const options = {
            url: '/events/faces/',
            routeName: 'EventsFaces',
            emptyItem: EmptyFaceEvent
        };
        return getItemViewModel(options);
    }
    getExternalVmsListViewModel(): ListViewModel<ExternalVms, ExternalVmsFilter> {
        const options = {
            url: '/external-vms/',
            routeName: 'ExternalVms',
            emptyItem: EmptyExternalVms,
            emptyFilter: EmptyExternalVmsFilter
        };
        return getListViewModel(options);
    }
    getExternalVmsItemViewModel(): ItemViewModel<ExternalVms> {
        const options = {
            url: '/external-vms/',
            routeName: 'ExternalVms',
            emptyItem: EmptyExternalVms
        };
        return getItemViewModel(options);
    }
    getExternalVmsCamerasListViewModel(): ListViewModel<VmsPluginCamerasRequest, ExternalVmsCamerasFilter> {
        const options = {
            url: '/external-vms/${id}/cameras/',
            routeName: 'ExternalVmsCameras',
            emptyItem: EmptyVmsPluginCamerasRequest,
            emptyFilter: EmptyExternalVmsCamerasFilter
        };
        return getListViewModel(options);
    }
    getExternalVmsCamerasItemViewModel(): ItemViewModel<VmsPluginCamerasRequest> {
        const options = {
            url: '/external-vms/${id}/cameras/',
            routeName: 'ExternalVmsCameras',
            emptyItem: EmptyVmsPluginCamerasRequest
        };
        return getItemViewModel(options);
    }
    getExternalVmsEventsListViewModel(): ListViewModel<ExternalVmsEvent, ExternalVmsEventsFilter> {
        const options = {
            url: '/external-vms/events/',
            routeName: 'ExternalVmsEvents',
            emptyItem: EmptyExternalVmsEvent,
            emptyFilter: EmptyExternalVmsEventsFilter
        };
        return getListViewModel(options);
    }
    getExternalVmsEventsItemViewModel(): ItemViewModel<ExternalVmsEvent> {
        const options = {
            url: '/external-vms/events/',
            routeName: 'ExternalVmsEvents',
            emptyItem: EmptyExternalVmsEvent
        };
        return getItemViewModel(options);
    }
    getExternalVmsSendEventStatusListViewModel(): ListViewModel<ExternalVmsSendEventStatus, ExternalVmsSendEventStatusFilter> {
        const options = {
            url: '/external-vms/send-event-status/',
            routeName: 'ExternalVmsSendEventStatus',
            emptyItem: EmptyExternalVmsSendEventStatus,
            emptyFilter: EmptyExternalVmsSendEventStatusFilter
        };
        return getListViewModel(options);
    }
    getExternalVmsSendEventStatusItemViewModel(): ItemViewModel<ExternalVmsSendEventStatus> {
        const options = {
            url: '/external-vms/send-event-status/',
            routeName: 'ExternalVmsSendEventStatus',
            emptyItem: EmptyExternalVmsSendEventStatus
        };
        return getItemViewModel(options);
    }
    getGroupsListViewModel(): ListViewModel<Group, GroupsFilter> {
        const options = {
            url: '/groups/',
            routeName: 'Groups',
            emptyItem: EmptyGroup,
            emptyFilter: EmptyGroupsFilter
        };
        return getListViewModel(options);
    }
    getGroupsItemViewModel(): ItemViewModel<Group> {
        const options = {
            url: '/groups/',
            routeName: 'Groups',
            emptyItem: EmptyGroup
        };
        return getItemViewModel(options);
    }
    getHooksListViewModel(): ListViewModel<Webhook, HooksFilter> {
        const options = {
            url: '/hooks/',
            routeName: 'Hooks',
            emptyItem: EmptyWebhook,
            emptyFilter: EmptyHooksFilter
        };
        return getListViewModel(options);
    }
    getHooksItemViewModel(): ItemViewModel<Webhook> {
        const options = {
            url: '/hooks/',
            routeName: 'Hooks',
            emptyItem: EmptyWebhook
        };
        return getItemViewModel(options);
    }
    getHumanCardAttachmentsListViewModel(): ListViewModel<HumanCardAttachment, HumanCardAttachmentsFilter> {
        const options = {
            url: '/human-card-attachments/',
            routeName: 'HumanCardAttachments',
            emptyItem: EmptyHumanCardAttachment,
            emptyFilter: EmptyHumanCardAttachmentsFilter
        };
        return getListViewModel(options);
    }
    getHumanCardAttachmentsItemViewModel(): ItemViewModel<HumanCardAttachment> {
        const options = {
            url: '/human-card-attachments/',
            routeName: 'HumanCardAttachments',
            emptyItem: EmptyHumanCardAttachment
        };
        return getItemViewModel(options);
    }
    getLinesListViewModel(): ListViewModel<Line, LinesFilter> {
        const options = {
            url: '/lines/',
            routeName: 'Lines',
            emptyItem: EmptyLine,
            emptyFilter: EmptyLinesFilter
        };
        return getListViewModel(options);
    }
    getLinesItemViewModel(): ItemViewModel<Line> {
        const options = {
            url: '/lines/',
            routeName: 'Lines',
            emptyItem: EmptyLine
        };
        return getItemViewModel(options);
    }
    getMetaDictionariesListViewModel(): ListViewModel<MetaDictionary, MetaDictionariesFilter> {
        const options = {
            url: '/meta-dictionaries/',
            routeName: 'MetaDictionaries',
            emptyItem: EmptyMetaDictionary,
            emptyFilter: EmptyMetaDictionariesFilter
        };
        return getListViewModel(options);
    }
    getMetaDictionariesItemViewModel(): ItemViewModel<MetaDictionary> {
        const options = {
            url: '/meta-dictionaries/',
            routeName: 'MetaDictionaries',
            emptyItem: EmptyMetaDictionary
        };
        return getItemViewModel(options);
    }
    getMetaDictionaryValuesListViewModel(): ListViewModel<MetaDictionaryValue, MetaDictionaryValuesFilter> {
        const options = {
            url: '/meta-dictionary-values/',
            routeName: 'MetaDictionaryValues',
            emptyItem: EmptyMetaDictionaryValue,
            emptyFilter: EmptyMetaDictionaryValuesFilter
        };
        return getListViewModel(options);
    }
    getMetaDictionaryValuesItemViewModel(): ItemViewModel<MetaDictionaryValue> {
        const options = {
            url: '/meta-dictionary-values/',
            routeName: 'MetaDictionaryValues',
            emptyItem: EmptyMetaDictionaryValue
        };
        return getItemViewModel(options);
    }
    getNotificationsListViewModel(): ListViewModel<Notification, NotificationsFilter> {
        const options = {
            url: '/notifications/',
            routeName: 'Notifications',
            emptyItem: EmptyNotification,
            emptyFilter: EmptyNotificationsFilter
        };
        return getListViewModel(options);
    }
    getNotificationsItemViewModel(): ItemViewModel<Notification> {
        const options = {
            url: '/notifications/',
            routeName: 'Notifications',
            emptyItem: EmptyNotification
        };
        return getItemViewModel(options);
    }
    getObjectsBodiesListViewModel(): ListViewModel<BodyObjectUpdate, ObjectsBodiesFilter> {
        const options = {
            url: '/objects/bodies/',
            routeName: 'ObjectsBodies',
            emptyItem: EmptyBodyObjectUpdate,
            emptyFilter: EmptyObjectsBodiesFilter
        };
        return getListViewModel(options);
    }
    getObjectsBodiesItemViewModel(): ItemViewModel<BodyObjectUpdate> {
        const options = {
            url: '/objects/bodies/',
            routeName: 'ObjectsBodies',
            emptyItem: EmptyBodyObjectUpdate
        };
        return getItemViewModel(options);
    }
    getObjectsCarsListViewModel(): ListViewModel<CarObjectUpdate, ObjectsCarsFilter> {
        const options = {
            url: '/objects/cars/',
            routeName: 'ObjectsCars',
            emptyItem: EmptyCarObjectUpdate,
            emptyFilter: EmptyObjectsCarsFilter
        };
        return getListViewModel(options);
    }
    getObjectsCarsItemViewModel(): ItemViewModel<CarObjectUpdate> {
        const options = {
            url: '/objects/cars/',
            routeName: 'ObjectsCars',
            emptyItem: EmptyCarObjectUpdate
        };
        return getItemViewModel(options);
    }
    getObjectsFacesListViewModel(): ListViewModel<FaceObjectUpdate, ObjectsFacesFilter> {
        const options = {
            url: '/objects/faces/',
            routeName: 'ObjectsFaces',
            emptyItem: EmptyFaceObjectUpdate,
            emptyFilter: EmptyObjectsFacesFilter
        };
        return getListViewModel(options);
    }
    getObjectsFacesItemViewModel(): ItemViewModel<FaceObjectUpdate> {
        const options = {
            url: '/objects/faces/',
            routeName: 'ObjectsFaces',
            emptyItem: EmptyFaceObjectUpdate
        };
        return getItemViewModel(options);
    }
    getOnvifCamerasListViewModel(): ListViewModel<OnvifCamera, OnvifCamerasFilter> {
        const options = {
            url: '/onvif-cameras/',
            routeName: 'OnvifCameras',
            emptyItem: EmptyOnvifCamera,
            emptyFilter: EmptyOnvifCamerasFilter
        };
        return getListViewModel(options);
    }
    getOnvifCamerasItemViewModel(): ItemViewModel<OnvifCamera> {
        const options = {
            url: '/onvif-cameras/',
            routeName: 'OnvifCameras',
            emptyItem: EmptyOnvifCamera
        };
        return getItemViewModel(options);
    }
    getPermissionsListViewModel(): ListViewModel<Permission, PermissionsFilter> {
        const options = {
            url: '/permissions/',
            routeName: 'Permissions',
            emptyItem: EmptyPermission,
            emptyFilter: EmptyPermissionsFilter
        };
        return getListViewModel(options);
    }
    getPermissionsItemViewModel(): ItemViewModel<Permission> {
        const options = {
            url: '/permissions/',
            routeName: 'Permissions',
            emptyItem: EmptyPermission
        };
        return getItemViewModel(options);
    }
    getPermissionsCameraGroupsListViewModel(): ListViewModel<CameraGroupPermissions, PermissionsCameraGroupsFilter> {
        const options = {
            url: '/permissions/camera-groups/',
            routeName: 'PermissionsCameraGroups',
            emptyItem: EmptyCameraGroupPermissions,
            emptyFilter: EmptyPermissionsCameraGroupsFilter
        };
        return getListViewModel(options);
    }
    getPermissionsCameraGroupsItemViewModel(): ItemViewModel<CameraGroupPermissions> {
        const options = {
            url: '/permissions/camera-groups/',
            routeName: 'PermissionsCameraGroups',
            emptyItem: EmptyCameraGroupPermissions
        };
        return getItemViewModel(options);
    }
    getPermissionsWatchListsListViewModel(): ListViewModel<WatchListPermissions, PermissionsWatchListsFilter> {
        const options = {
            url: '/permissions/watch-lists/',
            routeName: 'PermissionsWatchLists',
            emptyItem: EmptyWatchListPermissions,
            emptyFilter: EmptyPermissionsWatchListsFilter
        };
        return getListViewModel(options);
    }
    getPermissionsWatchListsItemViewModel(): ItemViewModel<WatchListPermissions> {
        const options = {
            url: '/permissions/watch-lists/',
            routeName: 'PermissionsWatchLists',
            emptyItem: EmptyWatchListPermissions
        };
        return getItemViewModel(options);
    }
    getPermissionsCasesListViewModel(): ListViewModel<CasePermissions, PermissionsFilter> {
        const options = {
            url: '/permissions/cases/',
            routeName: 'PermissionsCases',
            emptyItem: EmptyCasePermissions,
            emptyFilter: EmptyPermissionsFilter
        };
        return getListViewModel(options);
    }
    getPermissionsCasesItemViewModel(): ItemViewModel<CasePermissions> {
        const options = {
            url: '/permissions/cases/',
            routeName: 'PermissionsCases',
            emptyItem: EmptyCasePermissions
        };
        return getItemViewModel(options);
    }

    getPuppeteerDailyEventsListViewModel(): ListViewModel<DailySearchEvent, PuppeteerDailyEventsFilter> {
        const options = {
            url: '/puppeteer/daily/events/',
            routeName: 'PuppeteerDailyEvents',
            emptyItem: EmptyDailySearchEvent,
            emptyFilter: EmptyPuppeteerDailyEventsFilter
        };
        return getListViewModel(options);
    }
    getPuppeteerDailyEventsItemViewModel(): ItemViewModel<DailySearchEvent> {
        const options = {
            url: '/puppeteer/daily/events/',
            routeName: 'PuppeteerDailyEvents',
            emptyItem: EmptyDailySearchEvent
        };
        return getItemViewModel(options);
    }
    getPuppeteerRemoteMonitoringListViewModel(): ListViewModel<RemoteMonitoringRecord, PuppeteerRemoteMonitoringFilter> {
        const options = {
            url: '/puppeteer/remote-monitoring/',
            routeName: 'PuppeteerRemoteMonitoring',
            emptyItem: EmptyRemoteMonitoringRecord,
            emptyFilter: EmptyPuppeteerRemoteMonitoringFilter
        };
        return getListViewModel(options);
    }
    getPuppeteerRemoteMonitoringItemViewModel(): ItemViewModel<RemoteMonitoringRecord> {
        const options = {
            url: '/puppeteer/remote-monitoring/',
            routeName: 'PuppeteerRemoteMonitoring',
            emptyItem: EmptyRemoteMonitoringRecord
        };
        return getItemViewModel(options);
    }
    getPuppeteerRemoteMonitoringEventsListViewModel(): ListViewModel<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter> {
        const options = {
            url: '/puppeteer/remote-monitoring/events/',
            routeName: 'PuppeteerRemoteMonitoringEvents',
            emptyItem: EmptyRemoteMonitoringEvent,
            emptyFilter: EmptyPuppeteerRemoteMonitoringEventsFilter
        };
        return getListViewModel(options);
    }
    getPuppeteerRemoteMonitoringEventsItemViewModel(): ItemViewModel<RemoteMonitoringEvent> {
        const options = {
            url: '/puppeteer/remote-monitoring/events/',
            routeName: 'PuppeteerRemoteMonitoringEvents',
            emptyItem: EmptyRemoteMonitoringEvent
        };
        return getItemViewModel(options);
    }
    getPuppeteerSearchListViewModel(): ListViewModel<SearchRequest, PuppeteerSearchFilter> {
        const options = {
            url: '/puppeteer/search/',
            routeName: 'PuppeteerSearch',
            emptyItem: EmptySearchRequest,
            emptyFilter: EmptyPuppeteerSearchFilter
        };
        return getListViewModel(options);
    }
    getPuppeteerSearchItemViewModel(): ItemViewModel<SearchRequest> {
        const options = {
            url: '/puppeteer/search/',
            routeName: 'PuppeteerSearch',
            emptyItem: EmptySearchRequest
        };
        return getItemViewModel(options);
    }
    getPuppeteerSearchEventsListViewModel(): ListViewModel<SearchResult, PuppeteerSearchEventsFilter> {
        const options = {
            url: '/puppeteer/search/events/',
            routeName: 'PuppeteerSearchEvents',
            emptyItem: EmptySearchResult,
            emptyFilter: EmptyPuppeteerSearchEventsFilter
        };
        return getListViewModel(options);
    }
    getPuppeteerSearchEventsItemViewModel(): ItemViewModel<SearchResult> {
        const options = {
            url: '/puppeteer/search/events/',
            routeName: 'PuppeteerSearchEvents',
            emptyItem: EmptySearchResult
        };
        return getItemViewModel(options);
    }
    getRelationLinksCarListViewModel(): ListViewModel<VerboseRelationCarCardLink, RelationLinksCarFilter> {
        const options = {
            url: '/relation-links/car/',
            routeName: 'RelationLinksCar',
            emptyItem: EmptyVerboseRelationCarCardLink,
            emptyFilter: EmptyRelationLinksCarFilter
        };
        return getListViewModel(options);
    }
    getRelationLinksCarItemViewModel(): ItemViewModel<VerboseRelationCarCardLink> {
        const options = {
            url: '/relation-links/car/',
            routeName: 'RelationLinksCar',
            emptyItem: EmptyVerboseRelationCarCardLink
        };
        return getItemViewModel(options);
    }
    getRelationLinksHumanListViewModel(): ListViewModel<VerboseRelationHumanCardLink, RelationLinksHumanFilter> {
        const options = {
            url: '/relation-links/human/',
            routeName: 'RelationLinksHuman',
            emptyItem: EmptyVerboseRelationHumanCardLink,
            emptyFilter: EmptyRelationLinksHumanFilter
        };
        return getListViewModel(options);
    }
    getRelationLinksHumanItemViewModel(): ItemViewModel<VerboseRelationHumanCardLink> {
        const options = {
            url: '/relation-links/human/',
            routeName: 'RelationLinksHuman',
            emptyItem: EmptyVerboseRelationHumanCardLink
        };
        return getItemViewModel(options);
    }
    getRelationsListViewModel(): ListViewModel<Relation, RelationsFilter> {
        const options = {
            url: '/relations/',
            routeName: 'Relations',
            emptyItem: EmptyRelation,
            emptyFilter: EmptyRelationsFilter
        };
        return getListViewModel(options);
    }
    getRelationsItemViewModel(): ItemViewModel<Relation> {
        const options = {
            url: '/relations/',
            routeName: 'Relations',
            emptyItem: EmptyRelation
        };
        return getItemViewModel(options);
    }
    getReportsListViewModel(): ListViewModel<Report, ReportsFilter> {
        const options = {
            url: '/reports/',
            routeName: 'Reports',
            emptyItem: EmptyReport,
            emptyFilter: EmptyReportsFilter
        };
        return getListViewModel(options);
    }
    getReportsItemViewModel(): ItemViewModel<Report> {
        const options = {
            url: '/reports/',
            routeName: 'Reports',
            emptyItem: EmptyReport
        };
        return getItemViewModel(options);
    }
    getSessionsListViewModel(): ListViewModel<AuthSession, SessionsFilter> {
        const options = {
            url: '/sessions/',
            routeName: 'Sessions',
            emptyItem: EmptyAuthSession,
            emptyFilter: EmptySessionsFilter
        };
        return getListViewModel(options);
    }
    getSessionsItemViewModel(): ItemViewModel<AuthSession> {
        const options = {
            url: '/sessions/',
            routeName: 'Sessions',
            emptyItem: EmptyAuthSession
        };
        return getItemViewModel(options);
    }
    getTracksListViewModel(): ListViewModel<Track, TracksFilter> {
        const options = {
            url: '/tracks/',
            routeName: 'Tracks',
            emptyItem: EmptyTrack,
            emptyFilter: EmptyTracksFilter
        };
        return getListViewModel(options);
    }
    getTracksItemViewModel(): ItemViewModel<Track> {
        const options = {
            url: '/tracks/',
            routeName: 'Tracks',
            emptyItem: EmptyTrack
        };
        return getItemViewModel(options);
    }
    getUserFaceListViewModel(): ListViewModel<UserFaceUpdate, UserFaceFilter> {
        const options = {
            url: '/user-face/',
            routeName: 'UserFace',
            emptyItem: EmptyUserFaceUpdate,
            emptyFilter: EmptyUserFaceFilter
        };
        return getListViewModel(options);
    }
    getUserFaceItemViewModel(): ItemViewModel<UserFaceUpdate> {
        const options = {
            url: '/user-face/',
            routeName: 'UserFace',
            emptyItem: EmptyUserFaceUpdate
        };
        return getItemViewModel(options);
    }
    getUsersListViewModel(): ListViewModel<User, UsersFilter> {
        const options = {
            url: '/users/',
            routeName: 'Users',
            emptyItem: EmptyUser,
            emptyFilter: EmptyUsersFilter
        };
        return getListViewModel(options);
    }
    getUsersItemViewModel(): ItemViewModel<User> {
        const options = {
            url: '/users/',
            routeName: 'Users',
            emptyItem: EmptyUser
        };
        return getItemViewModel(options);
    }
    getVideosListViewModel(): ListViewModel<VideoArchive, VideosFilter> {
        const options = {
            url: '/videos/',
            routeName: 'Videos',
            emptyItem: EmptyVideoArchive,
            emptyFilter: EmptyVideosFilter
        };
        return getListViewModel(options);
    }
    getVideosItemViewModel(): ItemViewModel<VideoArchive> {
        const options = {
            url: '/videos/',
            routeName: 'Videos',
            emptyItem: EmptyVideoArchive,
            excludedChangeKeys: [
              'processing_start_date', 'health_status', 'file_size', 'face_count', 'face_cluster_count',
              'body_count', 'body_cluster_count', 'car_count', 'car_cluster_count',
              'progress', '__upload_progress', 'finished'
            ],
        };
        return getItemViewModel(options);
    }
    getWatchListsListViewModel(): ListViewModel<WatchList, WatchListsFilter> {
        const options = {
            url: '/watch-lists/',
            routeName: 'WatchLists',
            emptyItem: EmptyWatchList,
            emptyFilter: EmptyWatchListsFilter
        };
        return getListViewModel(options);
    }
    getWatchListsItemViewModel(): ItemViewModel<WatchList> {
        const options = {
            url: '/watch-lists/',
            routeName: 'WatchLists',
            emptyItem: EmptyWatchList
        };
        return getItemViewModel(options);
    }
}

export const dataServiceRepository = new DataServiceRepository(dataServiceFactory);
export const viewModelRepository = new ViewModelRepository(dataServiceFactory);

export function getViewModelByName<T, F>(modelName: string, type: 'item' | 'list' = 'item'): ItemViewModel<T> | ListViewModel<T, F> {
    const factoryName = `get${snakeToPascalCase(modelName)}${startCase(type)}ViewModel`;
    if (typeof (viewModelRepository as any)[factoryName] === 'undefined') {
        throw new Error(`Can't get model by name: ${modelName}. Expect factory name ${factoryName}`);
    }
    return (viewModelRepository as any)[factoryName]();
}

function snakeToPascalCase(snakeCaseName: string) {
    return startCase(snakeCaseName).replace(/ /g, '');
}
