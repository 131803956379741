import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { IFormLayout } from '@/uikit/forms/NForm.vue';

export type AnnexAlarmItemStatus = 'new' | 'in_work' | 'completed' | 'custom' | '';

export function getAnnexAlarmFiltersBuilder(options: SimpleFilterOptions) {
  const filterSchema = [
    commonFilterSchemaModule.getAnnexAlarmStatus({ ...options }),
    options.small ? commonFilterSchemaModule.getAnnexAlarmStatusHelper({ ...options }) : false,
    commonFilterSchemaModule.getAnnexAlarmReaction({ ...options }),
    commonFilterSchemaModule.getDateTimeRange({ ...options,
      gteProp: 'declared_from',
      lteProp: 'declared_to',
      i18n_label: 'annex_alarms.detection_time'
    }),
    commonFilterSchemaModule.getUserByName({
      path: 'ack_author',
      i18n_label: 'annex_alarms.author',
      i18n_placeholder: 'annex_alarms.author',
      ...options
    }),
    commonFilterSchemaModule.getAnnexAlarmComment({ ...options }),
    commonFilterSchemaModule.getAlertRuleId({ ...options }),
    commonFilterSchemaModule.getId({ ...options, i18n_label: 'alerts.id_alert', i18n_placeholder: 'alerts.id_alert' })
  ].filter((item) => !!item) as IFormLayout;

  return filterSchema;
}
