
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonSelect from "@/uikit/buttons/NButtonSelect.vue";
import {C2VSelectItems} from "@/pages/license_v1/types";
import {ValueItem} from "@/pages/license_v1/components/common/LicenseFeaturesContent.vue";

@Options({
  components: {NButtonSelect, NButton }
})
export default class EmptyContent extends Vue {
  get c2vItems(): ValueItem[] {
    return C2VSelectItems;
  }

  clickHandler(value: string) {
    window.open('mailto:' + value);
  }
}
