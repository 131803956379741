
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { CarEpisode, HumanEpisode } from '@/api';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { NLoadingCircle } from '@/uikit';
import { EventDetails, ThumbnailPositions } from '@/uikit/thumbnail/helpers/enums';
import EventItem from '@/components/events/EventItem.vue';

@Options({
  components: { EventItem, NLoadingCircle }
})
export default class EpisodeEventsPage extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanEpisode | CarEpisode;

  @Prop({ type: String, required: true })
  type!: string;

  module: ListViewModel<any, any> | null = null;
  secondModule: ListViewModel<any, any> | null = null;

  get firstObjectType() {
    return this.type === 'humans' ? 'faces' : 'cars';
  }

  get secondObjectType() {
    return this.type === 'humans' ? 'bodies' : null;
  }

  get iconActions() {
    return [{ icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight }];
  }

  get defaultEventAction() {
    return EventDetails.ShowInfo;
  }

  @Watch('firstObjectType')
  @Watch('item.id', { immediate: true })
  initFirstModule() {
    this.module = loadDataModule.getEventsLVMByEpisodeID({ id: this.item.id, type: this.firstObjectType });
  }

  @Watch('secondObjectType')
  @Watch('item.id', { immediate: true })
  initSecondModule() {
    if (this.secondObjectType) {
      this.secondModule = loadDataModule.getEventsLVMByEpisodeID({ id: this.item.id, type: this.secondObjectType });
    }
  }

  actionHandler(id: string | number, action: string, item: any): void {
    this.$emit('action', action, id, item);
  }

  scrollHandler(): void {
    const el = this.$refs.events;
    const scrollBottom = el ? el.scrollHeight - el.clientHeight - el.scrollTop : '';
    if (scrollBottom && scrollBottom < 200) {
      this.module?.append();
    }
  }

  beforeUnmount() {
    this.module?.reset();
    this.secondModule?.reset();
  }
}
