import { reactive } from 'vue';
import { dataServiceFactory, DataServiceFactory } from '@/definitions/services/data.services';
import { ItemViewModel } from '@/definitions/view-models/item.view.model';
import { ListViewModel } from '@/definitions/view-models/list.view.model';

export interface ItemModelOptions<T> {
  url: string;
  routeName: string;
  aclName?: string;
  emptyItem: T;
  mockItemOnLoad?: (item: any) => any;
  excludedChangeKeys?: string[];
  dispatch?: (action: string, payload: any) => Promise<any>;
}

export interface ListModelOptions<T, F> extends ItemModelOptions<T> {
  emptyFilter: F;
  filterSchema?: any;
  mockItemOnLoad?: (item: any) => any;
}

class ViewModelManager {
  constructor(protected dataServiceFactory: DataServiceFactory) {}

  getListViewModel<T, F>(options: ListModelOptions<T, F>): ListViewModel<T, F> {
    const result = new ListViewModel();
    result.name = options.url;
    result.apiName = urlToSnakeCase(options.url);
    result.aclModelName = options.aclName;
    result.dataService = this.dataServiceFactory.getService(options.url);
    result.init(options.emptyItem, options.emptyFilter, options.filterSchema);
    result.filter.storageKey = result.apiName;
    result.mockItemOnLoad = options.mockItemOnLoad;
    const observableResult = reactive(result) as ListViewModel<T, F>;
    return observableResult;
  }

  getItemViewModel<T>(options: ItemModelOptions<T>): ItemViewModel<T> {
    const result = new ItemViewModel();
    result.name = options.url;
    result.apiName = urlToSnakeCase(options.routeName);
    result.aclModelName = options.aclName;
    result.dataService = this.dataServiceFactory.getService(options.url);
    result.init(options.emptyItem);
    result.mockItemOnLoad = options.mockItemOnLoad;
    result.excludedChangeKeys = options.excludedChangeKeys || [];
    const observableResult = reactive(result) as ItemViewModel<T>;
    return observableResult;
  }
}

export const viewModelFactory = new ViewModelManager(dataServiceFactory);
export const getListViewModel = viewModelFactory.getListViewModel.bind(viewModelFactory);
export const getItemViewModel = viewModelFactory.getItemViewModel.bind(viewModelFactory);
export { ListViewModel, ItemViewModel };

export function urlToSnakeCase(input: string): string {
  let r = input.toLowerCase().slice(1, input.length - 1);
  r = r.replaceAll('/', '_');
  return r;
}

export function toSnakeCase(input: string): string {
  let r = '';
  input.split('').forEach((v, k) => {
    const lowerChar = v.toLowerCase();
    const isUpper = lowerChar !== v;
    r += isUpper && k !== 0 ? '_' : '';
    r += lowerChar;
  });
  return r;
}
