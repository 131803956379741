
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { Camera, CamerasService, VideoArchive } from '@/api';
import { Line } from '@/api/models/Line';
import { LinesFilter } from '@/api/models/LinesFilter';
import { ModelAclResult } from '@/store/acl/types';
import { dataModule } from '@/store/data';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NButtonTypes, NButton, NSwitch, Action } from '@/uikit';
import { NTableBodyCell, NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import { createTableSectionSchemaDecorator } from '@/uikit/table-v2/utils';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import DetectorsInfo from '@/components/lines/DetectorsInfo.vue';
import CameraStatus from '@/pages/data-sources/forms/components/CameraStatus.vue';

@Options({
  name: 'LinesTable',
  components: { ActionsDropdown, ButtonModelCopy, CameraStatus, DetectorsInfo, InfiniteScroll, NButton, NSwitch, NTable },
  emits: []
})
export default class LinesTable extends Vue {
  @Prop({ type: Object, required: true })
  readonly module!: ListViewModel<Line, LinesFilter>;

  @Prop({ type: Array, required: true })
  readonly selectedItemIds!: number[];

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  handleSelect(selectedChanges: Line[]) {
    selectedChanges.forEach((v) => {
      actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: MultisidebarItemTypes.Lines, rawItem: v });
    });
  }

  get selectedItems() {
    return this.module.items.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get columns(): NTableColumn<Line>[] {
    return [
      {
        width: 'max-content',
        head: this.$t('lines.id', 'f'),
        body: ({ model }) => {
          const props = { modelValue: model.id };
          return <ButtonModelCopy {...props} />;
        }
      },
      {
        width: 340,
        head: this.$t('lines.name', 'f'),
        body: ({ model }) => {
          const props = {
            type: NButtonTypes.Text,
            label: model.name,
            class: 'lines__button_link',
            onClick: this.handleAction.bind(this, ItemsActionNames.ShowItem, model)
          };
          return <NButton {...props} />;
        }
      },
      {
        width: 'minmax(200px, 450px)',
        head: this.$t('lines.cameras', 'f'),
        body: ({ model }) => {
          if (model.camera) {
            const camera = this.getCameraByLine(model);
            const props = {
              class: 'lines__button_link',
              label: this.getCameraLabel(model),
              type: NButtonTypes.Text,
              onAction: this.showDataSourceItem.bind(this, model)
            };

            if (camera) {
              return (
                <CameraStatus camera={camera}>
                  <NButton {...props} />
                </CameraStatus>
              );
            }
            return <NButton {...props} />;
          }
          return '';
        }
      },
      {
        width: 'minmax(200px, 450px)',
        head: this.$t('lines.videos', 'f'),
        body: ({ model }) => {
          if (model.video_archive) {
            const props = {
              class: 'label-m lines__button_link',
              label: this.getVideoLabel(model),
              type: NButtonTypes.Text,
              onAction: this.showDataSourceItem.bind(this, model)
            };
            return <NButton {...props} />;
          }
          return '';
        }
      },
      {
        width: 120,
        head: this.$t('lines.detectors', 'f'),
        body: ({ model }) => {
          if (model.camera) {
            return <DetectorsInfo source-type="camera" source-id={model.camera} />;
          } else {
            return <DetectorsInfo source-type="video_archive" source-id={model.video_archive} />;
          }
        }
      },
      {
        width: 50,
        head: '',
        body: ({ model }) => {
          const props = {
            actions: this.getActions(model),
            placement: 'left-start',
            onAction: (action: ItemsActionName) => this.handleAction(action, model)
          };
          return <ActionsDropdown {...props} />;
        }
      }
    ];
  }

  get bodyDecorators() {
    function getCustomClasses({ model }: NTableBodyCell<Line>) {
      return { 'lines__table-cell_inactive': !model.active };
    }
    return [createTableSectionSchemaDecorator({ class: getCustomClasses })];
  }

  getCameraByLine(line: Line) {
    return dataModule.camerasModule.items.find((v: Camera | VideoArchive) => v.id === line.camera);
  }

  getCameraLabel(line: Line) {
    const item = this.getCameraByLine(line);
    return item?.name || line.camera;
  }

  getVideoLabel(line: Line) {
    const item = dataModule.videosModule.items.find((v: Camera | VideoArchive) => v.id === line.video_archive);
    return item?.name || line.video_archive;
  }

  getActions(item: Line): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasActive: true,
        currentActive: item.active,
        hasRestart: Boolean(item.camera),
        hasDelete: true,
        hasLineFilterEvent: true
      })
      .map(actionsModule.computeActionByName);
  }

  showDataSourceItem(line: Line) {
    const id = line.camera ?? line.video_archive;
    const type = line.camera ? MultisidebarItemTypes.Cameras : MultisidebarItemTypes.Videos;
    actionHandler.run(ItemsActionNames.ShowItem, { type, rawItem: id });
  }

  handleAction(action: ItemsActionName, item: Line) {
    if (action === ItemsActionNames.Restart) {
      item.camera && CamerasService.camerasRestartCreate(item.camera);
    } else {
      actionHandler.run(action, { type: MultisidebarItemTypes.Lines, rawItem: item });
    }
  }
}
