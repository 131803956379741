export interface AnnexAlarm {
  declared_at: string;
  tag?: string;
  title: string;
  source: string;
  stream: string;
  comment?: null | string;
  extra?: {
    scopes: string[];
    camera_groups: number[];
    watch_lists: { [key: string]: string };
    line: any;
    match: any;
    counter: boolean;
    object_type: string;
    enrichment: {
      objects_reduce: any[];
    };
  };
  channels?: string;
  ack_interval?: null | string;
  ack_enabled?: boolean;
  id: number;
  rule_id?: number;
  ddp_id: string;
  created_at: string;
  updated_at?: string;
  ack_value: number;
  ack_author?: null | string;
  ack_at?: null | string;
  images?: {
    tag: string;
    path: string;
    id: number;
    alarm_id: number;
    legends: {
      tag: string;
      boxes: number[][];
      points: number[][];
      id: number;
      image_id: number;
    }[];
  }[];
}

export interface AnnexAlarmFilter {
  declared_from?: string;
  declared_to?: string;
  tag?: string;
  title?: string;
  source?: string;
  stream?: string;
  comment?: null | string;
  ack_value?: null | string;
  ack_author?: null | string;
  has_author?: null | boolean;
  has_comment?: null | boolean;
  sort?: null | string;
  page?: number;
  size?: number;
  ordering?: string; // для совместимости со стандартными фильтрами
}

export const EmptyAnnexAlarmFilter: AnnexAlarmFilter = {
  page: 1,
  size: 50,
  ordering: '-id'
};

export const EmptyAnnexAlarm: AnnexAlarm = {
  id: 0,
  ddp_id: '',
  ack_value: 0,
  created_at: new Date().toISOString(),
  declared_at: new Date().toISOString(),
  source: '',
  stream: '',
  title: ''
};
