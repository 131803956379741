import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchEventItem = _resolveComponent("SearchEventItem")!
  const _component_CommonMap = _resolveComponent("CommonMap")!

  return (_ctx.state.displayType !== 'map')
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.module.items, (item) => {
        return (_openBlock(), _createBlock(_component_SearchEventItem, {
          key: item.id,
          class: "search__event-item qwerty2",
          item: item,
          "confidence-class": _ctx.confidenceClass,
          objects: _ctx.state.objectType,
          "episode-type": _ctx.state.episodeType,
          defaultAction: _ctx.defaultEventAction,
          displayType: _ctx.state.displayType,
          "event-selected": _ctx.getIsItemSelected(item),
          "card-selected": _ctx.getIsItemCardSelected(item),
          "opened-item": _ctx.getOpenedItemType(item),
          hideAcknowledge: "",
          display: "",
          onSelect: ($event: any) => (_ctx.selectHandler(item)),
          onAction: (v) => _ctx.actionHandler(v, item)
        }, null, 8, ["item", "confidence-class", "objects", "episode-type", "defaultAction", "displayType", "event-selected", "card-selected", "opened-item", "onSelect", "onAction"]))
      }), 128))
    : (_openBlock(), _createBlock(_component_CommonMap, {
        key: 1,
        config: _ctx.mapConfig
      }, null, 8, ["config"]))
}