import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { NCheckbox } from '@/uikit';
import {configModule} from "@/store/config";

const deliveryChannelsSchema: IFormLayout = [
  {
    path: 'delivery_channels.websocket',
    i18n_label: 'alerts.settings.alert_feed',
    i18n_tooltip: 'alerts.settings.alert_feed__desc',
    classes: 'n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
    props: { disabled: true },
    component: NCheckbox
  },
  {
    path: 'delivery_channels.telegram',
    i18n_label: 'alerts.settings.telegram_channel',
    i18n_tooltip: 'alerts.settings.telegram_channel__desc',
    classes: 'n-form-w-3 n-form-label-horizontal-200 n-form-pad-10',
    component: NCheckbox,
    hidden: () => {
      return !configModule.config.services?.alerts?.telegram;
    }
  }
];

export default deliveryChannelsSchema;
