
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NHint from '../hint/NHint.vue';
import NIcon from '../icons/NIcon.vue';

@Options({
  name: 'NNotFoundImage',
  components: { NHint, NIcon }
})
export default class NNotFoundImage extends Vue {
  @Prop({ type: String, required: true })
  readonly label!: string;

  @Prop({ type: String, default: 'extra-large' })
  readonly size!: 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({ type: String, default: 'rounded' })
  readonly corners!: 'rounded' | 'circle';

  get classes() {
    const result = [];
    if (this.size) result.push('n-thumbnail__' + this.size);
    if (this.corners) result.push('n-thumbnail__' + this.corners);
    return result;
  }
}
