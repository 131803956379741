
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { FaceCluster } from '@/api';
import { PagePaths } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { InteractionsModule } from '@/store/interactions';
import NButton from '@/uikit/buttons/NButton.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import { getClusterContactsFiltersBuilder, getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import InteractionClusterItem from '@/pages/interactions/InteractionClusterItem.vue';
import InteractionItem from '@/pages/interactions/InteractionItem.vue';
import InteractionSection from '@/pages/interactions/InteractionSection.vue';

type OptionalItem = FaceCluster | null;

@Options({
  components: { FiltersBig, InteractionClusterItem, InteractionItem, InteractionSection, NButton }
})
export default class InteractionsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  interactionModule = new InteractionsModule();
  filtersVisible = false;
  filtersVisible1 = false;
  filtersVisible2 = false;
  selectedItems: OptionalItem[] = [null, null, null];

  get clusterBigFilterLayout() {
    const pageType = 'clusters';
    const objectType = 'faces';
    return getClusterFiltersBuilder({ small: false }).getFilterByType(pageType, objectType);
  }

  get clusterContactsBigFilterLayout() {
    const pageType = 'clusters';
    const objectType = 'faces';
    return getClusterContactsFiltersBuilder({ small: false }).getFilterByType(pageType, objectType);
  }

  get pageState() {
    return pageModule.getPageStateByTab(PagePaths.Interactions, this.tab);
  }

  async mounted() {
    const hasFilters = !!this.pageState.filter;
    hasFilters && Object.assign(this.interactionModule.clustersModule.filter.current, this.pageState.filter);
    await this.interactionModule.clustersModule.get();
    hasFilters && this.setFirstItemSelected();
  }

  setFirstItemSelected() {
    const firstItem = this.interactionModule.clustersModule.items[0];
    firstItem && this.toggleCurrent(0, firstItem);
  }

  toggleCurrent(index: number, item: FaceCluster) {
    this.selectedItems[index] = item;
    if (index === 0) {
      this.handleChangeCluster1(this.selectedItems[index]);
    }
    if (index === 1) {
      this.handleChangeCluster2(this.selectedItems[index]);
    }
  }

  displayFilters() {
    this.filtersVisible = true;
  }

  displayFilters1() {
    this.filtersVisible1 = true;
  }

  displayFilters2() {
    this.filtersVisible2 = true;
  }

  @Watch('interactionModule.clustersModule.filter.current', { deep: true })
  changeClusterFilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clustersModule.get();
  }

  @Watch('interactionModule.clusterContactsModule1.filter.current', { deep: true })
  changeCluster1FilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clusterContactsModule1?.get();
  }

  @Watch('interactionModule.clusterContactsModule2.filter.current', { deep: true })
  changeCluster2FilterHandler(v: any, p: any) {
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.interactionModule.clusterContactsModule2?.get();
  }

  handleChangeCluster1(item: OptionalItem) {
    if (item?.id) {
      const module = this.interactionModule.getClusterContactsModule(item.id, this.interactionModule._clusterContactsModule1);
      this.interactionModule.clusterContactsModule1 = this.interactionModule._clusterContactsModule1 = module;
      module?.get();
    } else {
      this.interactionModule.clusterContactsModule1 = undefined;
    }

    this.interactionModule.clusterContactsModule2 = undefined;
    this.selectedItems[1] = null;
    this.selectedItems[2] = null;
  }

  handleChangeCluster2(item: OptionalItem) {
    if (item?.id) {
      const module = this.interactionModule.getClusterContactsModule(item.id, this.interactionModule._clusterContactsModule2);
      this.interactionModule.clusterContactsModule2 = this.interactionModule._clusterContactsModule2 = module;
      module?.get();
    } else {
      this.interactionModule.clusterContactsModule2 = undefined;
    }
    this.selectedItems[2] = null;
  }
}
