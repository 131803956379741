
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import { PointAsArray } from '@/uikit/draw/types';
import { LeafletMouseEvent } from 'leaflet';
import { LatLngType } from '@/components/map/types';

@Options({
  name: 'EventClusterMarker',
  components: {
    LMarker,
    LIcon
  },
  emits: ['select']
})
export default class EventClusterMarker extends Vue {
  @Prop({ type: Object, required: true })
  readonly latLng!: LatLngType;

  @Prop({ type: [String, Number], default: '' })
  readonly label!: string | number;

  @Prop({ type: String, default: '#999' })
  readonly color!: number;

  @Prop({ type: Number, default: 30 })
  readonly radius!: number;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  get computedStyle(): Record<string, any> {
    const result = {
      width: this.radius + 'px',
      height: this.radius + 'px',
      background: this.color
    };
    return result;
  }

  handleClick(event: LeafletMouseEvent) {
    event.originalEvent.stopPropagation();
    this.$emit('select', event);
  }
}
