import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "case-cluster-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.case)
      ? (_openBlock(), _createBlock(_component_NForm, {
          key: 0,
          ref: "form",
          layout: _ctx.informationLayout,
          model: _ctx.item,
          state: _ctx.state,
          disabled: _ctx.disabled
        }, null, 8, ["layout", "model", "state", "disabled"]))
      : _createCommentVNode("", true)
  ]))
}