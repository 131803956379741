
import { Options, Vue } from 'vue-class-component';
import { OnvifCamera, OnvifCamerasService } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import { NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import NText from '@/uikit/text/NText.vue';

@Options({
  name: 'DataSourcesOnvifDiscovery',
  components: { NButton, NTable }
})
export default class DataSourcesOnvifDiscovery extends Vue {
  loading = false;
  items: OnvifCamera[] = [];
  selectedItems = [];

  get counter() {
    return this.items.length && this.$tm('ds.devices_pf', { count: this.items.length });
  }

  get columns(): NTableColumn<OnvifCamera>[] {
    return [
      {
        width: 'minmax(150px, 1fr)',
        head: this.$t('ds.brand', 'f'), // Manufacturer
        body: ({ model }) => {
          const props = { modelValue: model.meta.device.Hostname?.Name };
          return <NText {...props} />;
        }
      },
      {
        width: 'minmax(150px, 1fr)',
        head: this.$t('ds.model', 'f'), // model
        body: ({ model }) => {
          const props = { modelValue: model.meta.device.model };
          return <NText {...props} />;
        }
      },
      {
        width: 'minmax(300px, 1fr)',
        head: this.$t('ds.ip_address', 'f'), // host
        body: ({ model }) => {
          const props = { modelValue: model.meta.host };
          return <NText {...props} />;
        }
      },
      {
        width: 'minmax(150px, 1fr)',
        head: this.$t('ds.port', 'f'), // port
        body: ({ model }) => {
          const props = { modelValue: model.meta.port };
          return <NText {...props} />;
        }
      } /*,
      {
        width: 'minmax(320px, 1fr)',
        head: this.$t('ds.mac_address', 'f'), // mac
        body: ({ model }) => {
          const props = { modelValue: model.meta.port };
          return <NText {...props} />;
        }
      }*/
    ];
  }

  async load() {
    this.loading = true;
    try {
      const result = await OnvifCamerasService.onvifCamerasList();
      this.items = result.results!;
    } finally {
      this.loading = false;
    }
  }

  handleSelect(selected: any) {
    this.selectedItems = selected;
  }

  async mounted() {
    await this.load();
  }

  next() {
    this.$emit('nextStep', this.selectedItems);
  }
}
