
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import { CaseCluster } from '@/api/models/CaseCluster';
import AcknowledgeInfo from '@/components/common/AcknowledgeInfo.vue';
import Acknowledge from '@/components/common/deprecated/Acknowledge.vue';

@Options({
  name: 'CaseClusterAcknowledgment',
  components: { Acknowledge, AcknowledgeInfo }
})
export default class CaseClusterAcknowledgment extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: CaseCluster;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  readonly module = viewModelRepository.getCaseClustersListViewModel();

  async acknowledgeHandler(): Promise<void> {
    const id = this.modelValue.id;
    await this.module.dataService.createItemSomethingByAction(id, 'acknowledge');
    if (this.modelValue.matched_card?.acknowledged !== undefined) {
      this.modelValue.matched_card.acknowledged = true;
    }
  }
}
