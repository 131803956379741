// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { viewModelRepository } from '@/api/common';

const defaultWatchList = {
  active: true,
  name: 'Watch List 1',
  comment: '',
  color: 'ff00cc',
  notify: false,
  acknowledge: false,
  camera_groups: [1],
  face_threshold: 0.0,
  body_threshold: 0.0,
  car_threshold: 0.0,
  ignore_events: false,
  send_events_to_external_vms: false,
  active_after: null,
  active_before: null,
  disable_schedule: {},
  recount_schedule_on: null,
  origin: 'ffsecurity'
};

async function createWatchList(name: string, options: any = {}) {
  const model = viewModelRepository.getWatchListsItemViewModel();
  model.setItemsState({ ...defaultWatchList, name, ...options });
  await model.save();
}

export { createWatchList };
