import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search__item-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_EventItem = _resolveComponent("EventItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Confidence, {
      class: _normalizeClass(_ctx.confidenceClass),
      value: _ctx.item.looks_like_confidence,
      objects: _ctx.objects,
      align: "left",
      type: "number"
    }, null, 8, ["class", "value", "objects"]),
    _createVNode(_component_EventItem, _mergeProps({ class: "search__event-item" }, _ctx.$props, {
      onSelect: _cache[0] || (_cache[0] = () => _ctx.$emit('select', _ctx.item)),
      onAction: _cache[1] || (_cache[1] = (name) => _ctx.$emit('action', name, _ctx.item))
    }), null, 16)
  ]))
}