import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { EditorSection, EditorSections } from './types';

export function isSectionIncludedInArrayBuilder(availableSections?: EditorSection[]) {
  return (section: EditorSection): boolean => {
    if (availableSections?.length && !availableSections.includes(section)) {
      return false;
    } else {
      return true;
    }
  };
}

export function isSectionAvailable(section: EditorSection): boolean {

  switch (section) {
    case EditorSections.FaceEvents:
      return dataAssetsModule.isObjectAvailable('face');
    case EditorSections.BodyEvents:
      return dataAssetsModule.isObjectAvailable('body');
    case EditorSections.CarEvents:
      return dataAssetsModule.isObjectAvailable('car');
    case EditorSections.HumanEpisodes:
      return dataAssetsModule.availableEpisodeTypesMap['human'];
    case EditorSections.CarEpisodes:
      return dataAssetsModule.availableEpisodeTypesMap['car'];
    case EditorSections.Counters:
      return configModule.config.services.ffsecurity.counters;
    default:
      return true;
  }
}
