
import { PropType } from 'vue';
import { Options } from 'vue-class-component';
import { Vue, Prop } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import NSlider from '../slider/NSlider.vue';

export enum NConfidenceStatus {
  Positive = '_positive',
  Acceptable = '_acceptable',
  Negative = '_negative'
}

export type NConfidenceTriggerValues = {
  MinPositive: number | string;
  MaxPositive: number | string;
  MinNegative: number | string;
  MaxNegative: number | string;
};

@Options({
  name: 'NConfidence',
  components: { NSlider }
})
export default class NConfidence extends Vue {
  @Prop({
    type: [Number, String],
    default: 0,
    validator(min: number | string) {
      return min >= 0 && min <= 1;
    }
  })
  readonly min!: number | string;

  @Prop({
    type: [Number, String],
    default: 1,
    validator(max: number | string) {
      return max >= 0 && max <= 1;
    }
  })
  readonly max!: number | string;

  @Prop({
    type: [Number, String],
    validator(value: number | string) {
      return +value > 0;
    }
  })
  readonly step!: number | string;

  @Prop({
    type: Object as PropType<NConfidenceTriggerValues>,
    required: false
  })
  readonly triggerValues?: NConfidenceTriggerValues;

  @Prop({
    type: [Number, String]
  })
  readonly modelValue?: number | string;

  @Prop({ type: String, default: '' })
  readonly header!: '';

  @Prop({ type: Boolean, default: false })
  readonly controls!: false;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Array })
  items?: Array<number | string>;

  currentValue = 0;
  confidenceStatus: NConfidenceStatus = NConfidenceStatus.Positive;

  created() {
    this.currentValue = this.valueNumber;
  }
  get valueNumber() {
    return Number(this.modelValue) || Number(this.items && this.items[0]) || Number(this.min);
  }

  get hasHeader() {
    return Boolean(this.header);
  }

  computeConfidenceStatus(v: number) {
    if (!this.triggerValues) {
      this.confidenceStatus = NConfidenceStatus.Positive;
      return;
    }
    if (v >= this.triggerValues.MinPositive && v <= this.triggerValues.MaxPositive) {
      this.confidenceStatus = NConfidenceStatus.Positive;
    } else if (
      (v < this.triggerValues.MinPositive && v > this.triggerValues.MinNegative) ||
      (v > this.triggerValues.MaxPositive && v < this.triggerValues.MaxNegative)
    ) {
      this.confidenceStatus = NConfidenceStatus.Acceptable;
    } else {
      this.confidenceStatus = NConfidenceStatus.Negative;
    }
  }

  changeHandler(v: number) {
    this.$emit('update:modelValue', v);
  }

  @Watch('modelValue', { immediate: true })
  currentValueWatcher(v: number) {
    this.computeConfidenceStatus(v);
  }

  get cssClass() {
    let base = 'n-confidence';
    return {
      [base]: true,
      [`${base}${this.confidenceStatus}`]: this.confidenceStatus,
      [`${base}_has-header`]: this.hasHeader
    };
  }
}
