
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NHint from '@/uikit/hint/NHint.vue';
import NInput from '@/uikit/input/NInput.vue';
import { SearchSourceViewModel } from '@/pages/search/SearchSourceViewModel.ts';
import NButton from '@/uikit/buttons/NButton.vue';
import { PageTypes } from '@/store/application/page.definitions';
import { MultisidebarCommonItem, MultisidebarItemTypes } from '@/store/multisidebar/types';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  name: 'SearchFromSelect',
  components: { NButton, NButtonGroup, NHint, NInput }
})
export default class SearchFromSelect extends Vue {
  @Prop({ type: Object, required: true })
  readonly sourceModule!: SearchSourceViewModel;

  @Prop({ type: Array, required: false })
  readonly disabledSources?: string[];

  private loadTimeoutId = 0;

  public get state() {
    return this.sourceModule.state;
  }

  public get hasError() {
    return this.sourceModule.hasError;
  }

  public get availableSearchFromItems() {
    return this.sourceModule.availableSearchFromItems.filter((v: any) => {
      return this.disabledSources ? !this.disabledSources.includes(v.value) : true;
    });
  }

  public get availableObjectsItems() {
    return this.sourceModule.availableObjects
      .map((value: any) => ({ value, label: this.$t(`search.${value}`) }))
      .filter((item: any) => (this.state.searchFrom === SearchFromMap.Clusters ? item.value !== 'cars' : item));
  }

  public get isFile() {
    return this.state.searchFrom === SearchFromMap.File;
  }

  get getSourceFromMsbAvailable(): boolean {
    const isSearchFromEvents = this.state.searchFrom === SearchFromMap.Events;
    return this.$applicationModule.settings.presaleDebug && isSearchFromEvents;
  }

  public get objectType() {
    switch (this.state.searchFrom) {
      case SearchFromMap.Cards:
        return this.state.cardType;
      default:
        return this.state.objectType;
    }
  }

  public set objectType(v: string) {
    switch (this.state.searchFrom) {
      case SearchFromMap.Cards:
        this.state.cardType = v;
        break;
      default:
        this.state.cardType = dataAssetsModule.getCardTypeByObjectType(v);
        this.state.objectType = v;
    }
  }

  @Watch('availableObjectsItems')
  availableObjectsItemsWatcher(newItems: { label: any; value: string }[]) {
    const availableValues = newItems.map((item) => item.value);
    if (!availableValues.includes(this.state.objectType) && availableValues.length) {
      this.objectType = availableValues[0];
    }
  }

  getSourcesFromMsb() {
    let msbItemsFaces = multisidebarModule.getItemsByType(MultisidebarItemTypes.EventsFaces);
    let msbItemsBodies = multisidebarModule.getItemsByType(MultisidebarItemTypes.EventsBodies);
    let msbItemsCars = multisidebarModule.getItemsByType(MultisidebarItemTypes.EventsCars);
    let resultMsbItems: MultisidebarCommonItem[] = [];
    let objectsType = this.state.objectType;
    switch (objectsType) {
      case ObjectsTypesMap.Faces:
        resultMsbItems = msbItemsFaces;
        break;
      case ObjectsTypesMap.Bodies:
        resultMsbItems = msbItemsBodies;
        break;
      case ObjectsTypesMap.Cars:
        resultMsbItems = msbItemsCars;
        break;
    }

    if (!resultMsbItems.length) {
      if (msbItemsFaces.length) {
        resultMsbItems = msbItemsFaces;
        objectsType = ObjectsTypesMap.Faces;
      } else if (msbItemsBodies) {
        resultMsbItems = msbItemsBodies;
        objectsType = ObjectsTypesMap.Bodies;
      } else if (msbItemsCars) {
        resultMsbItems = msbItemsCars;
        objectsType = ObjectsTypesMap.Cars;
      }
    }

    const ids = resultMsbItems.map((v) => v.model.item.id);
    if (ids.length) {
      this.state.id = '';
      this.state.detectionId = '';
      this.state.searchFrom = PageTypes.Events;
      this.state.objectType = objectsType;
      this.$nextTick(() => {
        this.state.id = ids.join(', ');
      });
    }
  }
}
