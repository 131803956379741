
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { Camera } from '@/api';
import CameraScreenshot from './CameraScreenshot.vue';
import CameraStatus from '@/pages/data-sources/forms/components/CameraStatus.vue';

@Options({
  name: 'CameraItem',
  components: { CameraStatus, CameraScreenshot, NButton, NIcon, NLoadingCircle }
})
export default class CameraItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Camera;
}
