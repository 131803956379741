
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButton } from '@/uikit/index';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import WebhooksFiltersDivider from '@/pages/webhooks/components/WebhooksFiltersDivider.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';

@Options({
  name: 'FastCommentsForm',
  components: { NTooltip, NTextareaAutosize, NButton, NButtonGroup, NForm, NModalWindow, WebhooksFiltersDivider },
  emits: ['update:modelValue']
})
export default class FastCommentsForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: string[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  add() {
    this.modelValue.push('');
  }

  remove(index: number) {
    this.modelValue.splice(index, 1);
  }

  cleanHandler() {
    const items = new Set();
    this.modelValue.forEach((item, index) => {
      if (!item || items.has(item)) this.modelValue.splice(index, 1);
      items.add(item);
    });
  }
}
