import { render } from 'vue';
import { appEnvironment } from '@/store/application/app.environment';
import { reject } from 'lodash';
import { prop } from 'vue-class-component';

export function renderDialogComponent<T>(ComponentClass: any, props: Record<string, any>): Promise<T> {
  return new Promise(function (resolve, reject) {
    const container = document.body.appendChild(document.createElement('div'));
    const component = <ComponentClass {...props} onClose={closeHandler} onDone={doneHandler} />;
    component.appContext = appEnvironment.context;
    render(component, container);

    function closeHandler(): void {
      render(null, document.body.removeChild(container));
      reject(null);
    }

    function doneHandler(v: T): void {
      resolve(v);
      closeHandler();
    }
  });
}
