
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { Case, CaseStatusEnum, VideoArchive } from '@/api';
import { viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { PageTypes } from '@/store/application/page.definitions';
import { uploadModule } from '@/store/application/upload.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import EmptyPage from '@/components/common/EmptyPage.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import AddNewWizard from '@/pages/data-sources/add-devices/AddNewWizard.vue';
import DataSourcesVideosTable from '@/pages/data-sources/DataSourceVideosTable.vue';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';

@Options({
  name: 'CaseVideos',
  components: { AddNewWizard, DataSourcesVideosTable, EmptyPage, NBaseBar, NButton, SortDropdown, Statistics },
  emits: []
})
export default class CaseVideos extends Vue {
  @Prop({ required: false })
  readonly item!: Case;

  @Prop({ required: true })
  readonly tab!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean = false;

  @Prop({ required: true })
  readonly caseAcl!: ItemAclResult;

  wizardEnabled = false;

  get isCaseArchived() {
    return this.item.status !== CaseStatusEnum.Open;
  }

  get modelAcl() {
    const currentPageModelAcl = aclModule.getModelAcl(this.module);
    const caseAclToVideoAcl: ModelAclResult = { view: this.caseAcl.view, update: this.caseAcl.update, add: this.caseAcl.update, delete: this.caseAcl.delete };
    const archiveAcl: Partial<ModelAclResult> | null = this.isCaseArchived ? { add: false, update: false, delete: false } : {};
    const result = aclModule.mergeMultipleAcl(currentPageModelAcl, archiveAcl, caseAclToVideoAcl);
    return result;
  }

  get module() {
    const result = viewModelRepository.getVideosListViewModel();
    result.aclModelName = 'videoarchive';
    result.filter.current.limit = '1000';
    (result.filter.current as any).case_in = [this.item?.id];
    autoUpdateHelper.addListInstance(result);
    return result;
  }

  get sidebarType() {
    return MultisidebarItemTypes.Videos;
  }

  get sidebarSelectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === this.sidebarType).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get pageType() {
    return PageTypes.CaseVideos;
  }

  async mounted() {
    await this.module.get();
    uploadModule.registerClient(this.tab, this.module);
    uploadModule.syncClient(this.tab);
    if (this.isCaseArchived) {
      this.sidebarModule.disabledActions = true;
    }
  }

  beforeUnmount() {
    uploadModule.unregisterClient(this.tab);
  }

  handleSelect(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
      });
  }

  async uploadCaseHandler(v: any) {
    return this.uploadHandler({ ...v, params: { ...(v?.params || {}), id: this.item.id, face_detector: true } });
  }

  async uploadHandler({ urls, attachments, params }: any) {
    const config: Record<string, number | boolean> = params;
    const settings = await cameraResetModule.getVideoSettings();
    const results: VideoArchive[] = [];
    this.wizardEnabled = false;
    config['camera_group'] = -1;
    results.push(...(await uploadModule.createVideos(this.tab, urls, config, settings)));
    results.push(...(await uploadModule.uploadVideos(this.tab, attachments, config, settings)));

    if (results.length) {
      await this.module.get();
      params.open && (await multisidebarModule.addItemAndOpen(MultisidebarItemTypes.Videos, results[0]));
      await multisidebarModule.addItems(MultisidebarItemTypes.Videos, results);
      await this.module.get();
      await this.module.getStatistics();
      await autoUpdateHelper.updateHandler('/cases/', this.item, [], { remote: true });
    }
  }

  enableWizard() {
    this.wizardEnabled = true;
  }

  closeWizard() {
    this.wizardEnabled = false;
    this.module.get();
  }
}
