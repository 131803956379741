import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';

export function getAuditLogsFilters(options: { small: boolean }): IFormLayoutItem[] {
  return [
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getRequestIPAddress(options),
    commonFilterSchemaModule.getDeviceUIDInput(options),
    commonFilterSchemaModule.getAuditObjectTypeSelect(options),
    commonFilterSchemaModule.getAuditActionTypeSelect(options),
    commonFilterSchemaModule.getObjectIdInput(options),
    commonFilterSchemaModule.getUserLogin(options)
  ];
}
