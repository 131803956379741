
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';

@Options({
  name: 'AuditLogsImageIcon',
  components: { NIcon }
})
export default class AuditLogsImageIcon extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: Object, required: true })
  readonly bbox!: any;

  get isShowIcon() {
    return this.modelValue && !this.modelValue.endsWith('/');
  }

  showImage() {
    const item = { fullframe: this.modelValue, bboxes: [this.bbox] };
    this.$photoViewer.show(item);
  }
}
