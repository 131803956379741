
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButton from '../../../../../uikit/buttons/NButton.vue';
import NDoubleButton from '../../../../../uikit/buttons/NDoubleButton.vue';
import NDropdown from '../../../../../uikit/dropdown/NDropdown.vue';
import NDropdownItem from '../../../../../uikit/dropdown/NDropdownItem.vue';
import NDropdownItemDivider from '../../../../../uikit/dropdown/NDropdownItemDivider.vue';
import NIcon from '../../../../../uikit/icons/NIcon.vue';
import NInput from '../../../../../uikit/input/NInput.vue';
import { FilterValue, PresetFilter } from './types';

@Options({
  name: 'NFilterManager',
  components: { NButton, NDoubleButton, NDropdown, NDropdownItem, NDropdownItemDivider, NIcon, NInput }
})
export default class NFilterManager extends Vue {
  @Prop({ type: Array, default: [] })
  readonly items!: PresetFilter[];

  @Prop({ type: String, default: '' })
  readonly headerLabel!: '';

  @Prop({ type: String, default: '' })
  readonly footerLabel!: '';

  @Prop({ type: String, default: '' })
  readonly placeholder!: '';

  @Prop({ type: String, required: false })
  readonly selected?: string;

  @Prop({ type: Boolean, default: false })
  readonly disableButton?: boolean;

  currentItems: PresetFilter[] = this.items;
  displaySaveButton = false;
  newFilter = '';

  @Watch('items')
  handleItemsChange(v: PresetFilter[]) {
    this.currentItems = v;
  }

  hideHandler() {
    this.newFilter = '';
    this.displaySaveButton = false;
  }

  selectHandler(item: FilterValue) {
    this.$emit('select', item);
  }

  addHandler() {
    this.displaySaveButton = true;
    const newPreset = {
      name: this.newFilter,
      id: new Date().getTime().toString(32),
      deleted: false
    };
    this.$emit('add', newPreset);
    this.newFilter = '';
    this.displaySaveButton = false;
  }

  deleteHandler(id: string) {
    const deletedItem = this.currentItems.find((item) => item.id === id);
    if (deletedItem) {
      deletedItem.deleted = true;
    }
    this.$emit('delete', id);
  }

  inputHandler() {
    if (this.newFilter) {
      this.displaySaveButton = true;
      this.$nextTick(() => {
        this.$refs.saveButton?.$el?.focus();
      });
    }
  }

  undoHandler(id: string) {
    const undoneItem = this.currentItems.find((item) => item.id === id);
    if (undoneItem) {
      undoneItem.deleted = false;
    }
    this.$emit('undo', id);
  }

  dropdownToggleHandler(value: boolean) {
    if (!value) this.$emit('hide');
  }
}

export function getRandom(min: number, max: number) {
  const minInteger = Math.ceil(min);
  const maxInteger = Math.floor(max);
  return Math.floor(Math.random() * (maxInteger - minInteger + 1)) + minInteger;
}
