
import { Options, Vue } from 'vue-class-component';
import { Interval, IntervalData, IntervalProducts, LicenseCounter, LicenseFeatureStatus, LicenseFeatureStatusItems } from '@/pages/license_v1/types';
import LicenseLabel from '@/pages/license_v1/components/common/LicenseLabel.vue';
import { licenseModule } from '@/store/config/LicenseModule';
import LicenseCircle from '@/pages/license_v1/components/common/LicenseCircle.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { copyTextToClipboard } from '@/uikit/helpers';

@Options({
  components: { NButton, LicenseCircle, LicenseLabel }
})
export default class IntervalsContent extends Vue {
  get usageReport() {
    return licenseModule.usageReport;
  }

  get licenseIds() {
    return Object.keys(this.usageReport || {});
  }

  getLicenseIntervals(id: string): IntervalData | undefined {
    return this.usageReport?.[id];
  }

  getIntervalCounters(value: IntervalProducts) {
    const result: Record<string, LicenseCounter> = {};
    Object.keys(value).forEach((product: string) => {
      const counterNames = Object.keys(value[product]);
      counterNames.forEach((name: string) => {
        result[name] = value[product][name];
      });
    });
    return Object.entries(result).map((v) => {
      return { name: v[0], counter: v[1] };
    });
  }

  getIntervalClass(isActive: boolean, counter: LicenseCounter) {
    let result: LicenseFeatureStatus = LicenseFeatureStatusItems.Disabled;
    if (isActive) {
      const usagePercent = (counter.used || 0) / (counter.available || 0);
      if (usagePercent <= 1) {
        if (usagePercent > 0.8) {
          result = LicenseFeatureStatusItems.Warning;
        } else {
          result = LicenseFeatureStatusItems.Success;
        }
      }
    }
    return {
      [`intervals-table__${result}`]: true
    };
  }

  isActiveInterval(item: Interval): boolean {
    return this.computeIntervalStatus(item) === LicenseFeatureStatusItems.Success;
  }

  computeIntervalStatus(item: Interval): LicenseFeatureStatus {
    const sinceDate = new Date(item.since);
    const endDate = item.till ? new Date(item.till) : null;
    const currentDate = new Date();
    if (currentDate.getTime() < sinceDate.getTime()) {
      return LicenseFeatureStatusItems.Disabled;
    }
    if (endDate ? currentDate.getTime() > endDate.getTime() : false) {
      return LicenseFeatureStatusItems.Error;
    }
    return LicenseFeatureStatusItems.Success;
  }

  getFeatureAvailableCount(name: string, activeLimits: Interval | undefined): number {
    if (!activeLimits?.counters) return 0;

    let r: LicenseCounter = { used: 0, available: 0 };
    Object.values(activeLimits.counters).find((v: Record<string, LicenseCounter>) => {
      if (v[name]) {
        r = v[name];
        return true;
      }
    });
    return r.available || 0;
  }

  copy(value: string) {
    copyTextToClipboard(value);
  }
}
