import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e6708b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-reports" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsBar = _resolveComponent("SettingsBar")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsBar, {
        "search-query": _ctx.searchQuery,
        onSearch: _cache[0] || (_cache[0] = (query) => _ctx.handleSearch(query)),
        "has-create": false,
        "total-count": _ctx.items.length
      }, null, 8, ["search-query", "total-count"]),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, key) => {
          return (_openBlock(), _createBlock(_component_NButton, {
            key: key,
            label: item.description,
            onAction: ($event: any) => (_ctx.handleAction(item)),
            class: "custom-reports__btn",
            loading: item.loading,
            disabled: item.loading
          }, null, 8, ["label", "onAction", "loading", "disabled"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}