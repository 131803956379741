import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.tr('errors.play_sound')), 1),
    _createVNode(_component_NButton, {
      type: "link",
      onClick: _ctx.clickHandler,
      class: "report-notification__link"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tr('common.unmute')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}