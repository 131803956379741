import { createCameraGroup } from '@/pages/devtools/helpers/camera-groups';
import { createCamera } from '@/pages/devtools/helpers/cameras';
import { createHumanCard } from '@/pages/devtools/helpers/cards';
import { buildMethodForGetObjectId } from '@/pages/devtools/helpers/common';
import { createWatchList } from '@/pages/devtools/helpers/watch-lists';

const availableObjectScheme = [
  {
    one: 'group',
    many: 'camera_groups',
    create: (options: any) => createCameraGroup(options.name, options),
    find: buildMethodForGetObjectId('CameraGroups')
  },
  {
    one: 'camera',
    many: 'cameras',
    create: (options: any) => createCamera(options.name, options.url, options),
    find: buildMethodForGetObjectId('Cameras')
  },
  {
    one: 'watch_list',
    many: 'watch_lists',
    create: (options: any) => createWatchList(options.name, options),
    find: buildMethodForGetObjectId('WatchLists')
  },
  {
    one: 'human_card',
    many: 'human_cards',
    create: (options: any) => createHumanCard(options.name, options.photoUrl, options),
    find: buildMethodForGetObjectId('CardsHumans')
  }
];

const typeByField: any = {};
const createByType: any = {};
const findByType: any = {};
availableObjectScheme.forEach((item) => {
  typeByField[item.one] = item.many;
  typeByField[item.many] = item.many;
  createByType[item.many] = item.create;
  findByType[item.many] = item.find;
});

async function searchAndReplaceFieldValue(type: string, query: any) {
  const find = findByType[type];
  console.log('[!] Get object id:', type, JSON.stringify(query));
  return await find(query);
}

async function createObject(type: string, object: any) {
  const create = createByType[type];
  console.log('[!] Create object:', type, JSON.stringify(object));
  await create(object);
}

async function createObjectsByType(schema: any, type: string) {
  console.log('process type:', type);
  const objects = schema[type];

  if (!objects) {
    console.log('type not exists!', type);
    return;
  }

  for (const object of objects) {
    console.log('process object:', object);
    if (object._created) {
      continue;
    }
    const fields = Object.keys(object);
    for (const field of fields) {

      const fieldType = typeByField[field];
      console.log('process field:', field, fieldType, typeByField);

      if (fieldType) {
        console.log('recursive start:', field);
        await createObjectsByType(schema, fieldType);
        console.log('recursive end:', field);

        const fieldValue = object[field];
        if (fieldValue instanceof Array) {
          for (let i = 0; i < fieldValue.length; i++) {
            fieldValue[i] = await searchAndReplaceFieldValue(fieldType, fieldValue[i]);
          }
        } else {
          object[field] = await searchAndReplaceFieldValue(fieldType, object[field]);
        }
      }
    }
    await createObject(type, object);
    object._created = true;
  }
}

async function createObjectsByJsonScheme(jsonScheme: any) {
  const types = availableObjectScheme.map((item) => item.many);
  for (const type of types) {
    await createObjectsByType(jsonScheme, type);
  }
}

/*
const testJsonScheme = {
  'camera-groups': [
    {
      name: 'Test group 1'
    }
  ],
  'watch-lists': [
    {
      name: 'Test watch list 1'
    }
  ],
  cameras: [
    {
      name: 'QA1',
      url: 'rtsp://',
      card: { name: 'anna' },
      'camera-groups': [{ name: 'Test group 1' }]
    }
  ],
  cards: [
    {
      name: 'Anna 123123',
      photoUrl: 'https://i.ibb.co/8g5Xtm3/Cqvo-AWEESc.jpg'
    }
  ]
};

console.log(JSON.stringify(testJsonScheme, null, '  '));
*/

export { createObjectsByJsonScheme };
