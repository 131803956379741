
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Debounce } from '@/common/debounce-decorator';

@Options({
  name: 'InfiniteScroll',
  emit: ['loadMore']
})
export default class InfiniteScroll extends Vue {
  @Prop({ type: [Object, Array], default: () => {} })
  readonly filters!: any;

  @Prop({ type: String, default: '' })
  readonly nextPage!: string;

  @Prop({ type: Number, default: 0 })
  readonly itemsHash!: string;

  observer: IntersectionObserver | null = null;
  isVisible = true;
  lastItemsHash = 0;

  @Watch('filters', { deep: true })
  scrollToTop() {
    for (let node = this.$el; node !== null; node = node.parentNode) {
      if (node.scrollHeight > node.clientHeight) {
        node.scrollTop = 0;
        break;
      }
    }
  }

  @Watch('nextPage', { immediate: true })
  autoEmitOnPageChange() {
    this.nextPage && this.loadMore();
  }

  @Watch('itemsHash')
  @Debounce(1000)
  itemsHashWatcher(newItemsHash: number) {
    if (this.lastItemsHash !== newItemsHash) {
      this.lastItemsHash = newItemsHash;
      this.loadMore();
    }
  }

  mounted() {
    const options = {
      threshold: 0.1
    };
    this.clear();
    this.observer = new IntersectionObserver(this.intersect, options);
    this.observer.observe(this.$el);
  }

  beforeUnmount() {
    this.clear();
  }

  intersect(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      this.isVisible = entry.isIntersecting;
      this.loadMore();
    });
  }

  loadMore() {
    this.isVisible && this.$emit('loadMore');
  }

  clear() {
    this.observer?.disconnect();
  }
}
