import intersection from 'lodash/intersection';
import { FaceEventsFilters, HumanEpisodesFilters } from '@/api';
import { getSatisfyEventFeatures } from '@/store/events/features.filter';
import { isoStringToDate } from '@/common/filters';
import { isDefined } from '@/common/utils';
import { FaceFeatures } from '@/components/common/types';
import { ObjectsType } from '@/store/application/data.assets';

export function satisfyEventFilter(event: any, filter: any, objects: ObjectsType): boolean {
  let satisfy = true;

  if (satisfy && filter.id) {
    satisfy = filter.id === event.id;
  }

  if (satisfy && filter.id_in) {
    const id = String(event.id);
    satisfy = Array.isArray(filter.id_in) ? (filter.id_in.length ? filter.id_in.includes(id) : true) : String(filter.id_in) === id;
  }

  if (satisfy && filter.episode) {
    satisfy = String(filter.episode) === String(event.episode);
  }

  if (satisfy && filter.matched_card_in) {
    const id = String(event.matched_card);
    const currentFilter = filter.matched_card_in;
    satisfy = Array.isArray(filter.id_in) ? (currentFilter.length ? currentFilter.includes(id) : true) : String(currentFilter) === id;
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.no_match) {
    satisfy = filter.no_match === 'True' ? !event.matched_card : !!event.matched_card;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups.length) {
    satisfy = filter.camera_groups.find((v: number) => v === event.camera_group);
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v: number) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);
    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  if (satisfy && filter.video_archive) {
    satisfy = satisfyListOrSingleFilterNumber(filter.video_archive, event.video_archive);
  }

  if (satisfy && filter.bs_type) {
    satisfy = filter.bs_type === event.bs_type;
  }

  if (satisfy && filter.line) {
    satisfy = satisfyListOrSingleFilterNumber(filter.line, event.line);
  }

  if (satisfy && filter.line_crossing_direction) {
    satisfy = satisfyListOrSingleFilterString(filter.line_crossing_direction, event.line_crossing_direction);
  }

  if (satisfy && filter.backward_line_crossing) {
    satisfy = filter.backward_line_crossing === 'True' ? event.backward_line_crossing : !event.backward_line_crossing;
  }

  satisfy = satisfy && getSatisfyEventFeatures(event, filter, objects);
  satisfy = satisfy && (objects === 'faces' ? doesEventSatisfyHeadposeFilter(event.features ?? {}, filter) : true);
  return satisfy;
}

export function doesEventSatisfyHeadposeFilter(
  { headpose_pitch, headpose_yaw }: Partial<FaceFeatures>,
  filter: FaceEventsFilters | HumanEpisodesFilters
): boolean {
  return [
    isDefined(filter.headpose_pitch_angle_gte) && isDefined(headpose_pitch) ? headpose_pitch.name >= filter.headpose_pitch_angle_gte : true,
    isDefined(filter.headpose_pitch_angle_lte) && isDefined(headpose_pitch) ? headpose_pitch.name <= filter.headpose_pitch_angle_lte : true,
    isDefined(filter.headpose_yaw_angle_gte) && isDefined(headpose_yaw) ? headpose_yaw.name >= filter.headpose_yaw_angle_gte : true,
    isDefined(filter.headpose_yaw_angle_lte) && isDefined(headpose_yaw) ? headpose_yaw.name <= filter.headpose_yaw_angle_lte : true
  ].every(Boolean);
}

function satisfyListOrSingleFilterNumber(filter: number[] | number, value: number) {
  return Array.isArray(filter) ? filter.length === 0 || filter.includes(value) : Number(filter) === value;
}

function satisfyListOrSingleFilterString(filter: string[] | string, value: string) {
  return Array.isArray(filter) ? filter.length === 0 || filter.includes(value) : String(filter) === value;
}
