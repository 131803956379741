import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseCard = _resolveComponent("LicenseCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenses, (item) => {
      return (_openBlock(), _createBlock(_component_LicenseCard, {
        key: item.license_id,
        item: item
      }, null, 8, ["item"]))
    }), 128))
  ]))
}