import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "license-resource-table" }
const _hoisted_2 = { class: "license-resource-table__header" }
const _hoisted_3 = { class: "license-resource-table__header align-right" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "license-resource-table__header align-right" }
const _hoisted_6 = { class: "license-resource-table__cell" }
const _hoisted_7 = { class: "license-resource-table__cell align-right" }
const _hoisted_8 = { class: "license-resource-table__cell align-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseLabel = _resolveComponent("LicenseLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('license.resource_names')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showCurrent)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('license.used')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('license.limits')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsMap, (item, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `r-${item.name}`
      }, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getLabel(key)), 1),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.showCurrent)
            ? (_openBlock(), _createBlock(_component_LicenseLabel, {
                key: 0,
                status: _ctx.getStatus(item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.current || 0), 1)
                ]),
                _: 2
              }, 1032, ["status"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(item.value), 1)
      ], 64))
    }), 128))
  ]))
}