import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "case-cluster-acknowledgment"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Acknowledge = _resolveComponent("Acknowledge")!
  const _component_AcknowledgeInfo = _resolveComponent("AcknowledgeInfo")!

  return (_ctx.modelValue?.matched_card)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Acknowledge, {
          disabled: _ctx.disabled || _ctx.modelValue.matched_card?.acknowledged,
          item: _ctx.modelValue.matched_card,
          onAction: _ctx.acknowledgeHandler
        }, null, 8, ["disabled", "item", "onAction"]),
        _createVNode(_component_AcknowledgeInfo, { item: _ctx.modelValue }, null, 8, ["item"])
      ]))
    : _createCommentVNode("", true)
}