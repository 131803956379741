
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NInput, NIcon } from '@/uikit';

@Options({
  components: {
    NIcon,
    NInput
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallInput extends Vue {
  @Prop({ type: [String, Number], required: true })
  readonly modelValue!: string | number;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: String })
  readonly placeholder?: string;

  @Prop({ type: String, default: '' })
  readonly alphabet!: string;

  get model() {
    return this.modelValue;
  }

  set model(value) {
    this.$emit('update:modelValue', value);
  }
}
