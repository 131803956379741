import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "license-page"
}
const _hoisted_2 = { class: "license-page__tabs" }
const _hoisted_3 = { class: "license-page__tabs__actions" }
const _hoisted_4 = { class: "license-page__content" }
const _hoisted_5 = { class: "license-page__content-subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NButtonSelect = _resolveComponent("NButtonSelect")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_EmptyContent = _resolveComponent("EmptyContent")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      (_ctx.hasLicense)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_NTabs, {
                modelValue: _ctx.tab,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
                items: _ctx.tabItems,
                postfix: true,
                "has-bottom-border": false
              }, {
                postfix: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_NButton, {
                      type: "secondary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionHandler('upload')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('license.actions.upload_file')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NButtonSelect, {
                      "model-value": _ctx.downloadC2V,
                      "onUpdate:modelValue": _ctx.handleDownloadC2V,
                      items: _ctx.c2vItems,
                      type: "secondary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('license.actions.download_c2v')), 1)
                      ]),
                      _: 1
                    }, 8, ["model-value", "onUpdate:modelValue", "items"])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "items"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.contentComponent), { licenses: _ctx.licenses }, null, 8, ["licenses"])),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('license.last_updated_at')) + " " + _toDisplayString(_ctx.$filters.formatDateTime((_ctx.licenseData?.time || 0) * 1e3)), 1)
            ])
          ]))
        : (_openBlock(), _createBlock(_component_EmptyContent, {
            key: 1,
            onAction: _ctx.actionHandler
          }, null, 8, ["onAction"]))
    ]),
    _: 1
  }))
}