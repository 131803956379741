const mimeTypesMap: Record<string, string> = {
  'image/bmp': 'bmp',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/webp': 'webp'
};

export function mimeTypeToFileExtension(mimeType: string): string {
  return mimeTypesMap[mimeType] || '';
}
