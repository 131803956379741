
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Camera } from '@/api';
import CameraStatus from '@/pages/data-sources/forms/components/CameraStatus.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import { actionsModule } from '@/store/data/ActionsModule';
import { Action } from '@/uikit';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import { aclModule } from '@/store/acl';
import { AclModelNames } from '@/store/acl/types';
import { configModule } from '@/store/config';

@Options({
  components: { CameraScreenshot, CameraStatus, ActionsDropdown }
})
export default class DataSourceTableRowItem extends Vue {
  @Prop({ type: Object })
  item!: Camera;

  get modelAcl() {
    return aclModule.getModelAclByName(AclModelNames.Camera);
  }

  get showThumbnail() {
    return configModule.config?.map?.show_cameras_thumbnails ?? true;
  }

  get actions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasActive: true,
        currentActive: this.item.active,
        hasRestart: true,
        hasDelete: true,
        hasCameraReset: true
      })
      .map(actionsModule.computeActionByName);
  }
}
