export default {
  all: 'All',
  current: 'Current',
  common: 'Common',
  disabled: 'DISABLED',
  enabled: 'Enabled',
  generated: 'Generated',
  intervals: 'Intervals',
  last_updated: 'Last updated',
  left: 'left',
  no: 'No',
  second_ago_pf: 'seconds ago',
  license_id: 'License ID',
  services: 'Services',
  license: 'License',
  source: 'File',
  type: 'Type of license',
  valid: 'Valid',
  invalid: 'Invalid',
  yes: 'Yes',
  resource_names: 'Resources',
  resources: {
    cameras: 'Cameras',
    extapi: 'Extraction API',
    objects_tntapi: 'Objects TNT API',
    cameras_body: 'Cameras: Body',
    cameras_car: 'Cameras: Car',
    cameras_face: 'Cameras: Face',
    cameras_video_recording: 'Cameras: Video recording'
  },
  expiry_date: 'Expiry date',
  updated_date: 'Updated date',
  used: 'Used',
  limits: 'Limits',
  groups: {
    functional: 'Functional modules',
    detections: 'Object and attribute detection'
  },
  subgroups: {
    face: 'Face attributes',
    body: 'Body attributes',
    car: 'Vehicle attributes',
    head: 'Head attributes',
    other: 'Other attributes'
  },
  extra_name: 'Extra',
  valid_items: 'Valid',
  invalid_items: 'Invalid',
  nearest_date: 'Nearest expiry date',
  latest_date: 'Latest expiry date',
  tabs: {
    common: 'General',
    licenses: 'Licenses',
    intervals: 'Intervals',
    services: 'Services'
  },
  actions: {
    buy_or_update: 'Buy or update',
    download_c2v: 'Download C2V',
    upload_file: 'Upload file'
  },
  start_date: 'Start date',
  end_date: 'End date',
  status: 'Status',
  counters: 'Counters',
  empty_license_description: 'License is not found.',
  last_updated_at: 'Last update',
  disabled_feature_description: 'Contact an administrator or support to enable this feature in your license',
  last_update: 'Last update',
  last_update_seconds_ago: '{ value, plural, =0 {right now} one {# second ago} other {# seconds ago} }',
  featureNames: {
    all_attrs: 'All attributes',
    body_bags: 'Bags',
    body_emben: 'Body emben',
    body_other_attrs: 'Other attributes',
    body_protective_equipment: 'Protective equipment',
    car_categories: 'Vehicle category',
    car_description: 'Vehicle description',
    car_emben: 'Vehicle emben',
    car_license_plate: 'License plate',
    car_license_plate_visibility: 'License plate visibility',
    car_orientation: 'Vehicle orientation',
    car_other_attrs: 'Other attributes',
    car_special_types: 'Special vehicle',
    car_weight_types: 'Vehicle weight and body size',
    face_age: 'Age',
    face_eyes_attrs: 'Eyes',
    face_beard: 'Beard',
    face_emotions: 'Emotions',
    face_gender: 'Gender',
    face_glasses: 'Glasses',
    face_headpose: 'Headpose',
    face_liveness: 'Liveness',
    face_medmask: 'Medical mask',
    tntapi_fast_index: 'TNT API Fast Index',
    video_recording: 'Video recording',
    'sec-external-vms': 'External VMS',
    'sec-genetec': 'Genetec',
    'bi-analytics': 'Bi Analytics',
    'line-crossing': 'Line Crossing',
    maps: 'Maps',
    alerts: 'Alerts'
  },
  contact_description: 'To buy a license, please contact the sales department at',
  invalid_license_description: 'License is not valid. Please contact an administrator or support for more information.',
  contact_email: 'info@ntechlab.com',
  expired: 'Expired'
};
