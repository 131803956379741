
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButtonModelCopy from '@/uikit/buttons/NButtonModelCopy.vue';
import { NButtonSize, NButtonSizes } from '@/uikit/buttons/types';

@Options({
  name: 'ButtonModelCopy',
  components: { NButtonModelCopy }
})
export default class ButtonModelCopy extends Vue {
  @Prop({ type: String, default: NButtonSizes.Normal })
  readonly size!: NButtonSize;
}
