
import { Options, Vue } from 'vue-class-component';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { PagePaths, PageState, PageTypes } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NTable from '@/uikit/table/NTable.vue';
import EntityBlank from '@/components/common/EntityBlank.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import { filterItemsByName, sortByField } from '@/pages/helper';
import ListPage from '@/pages/ListPage.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { webhooksTableSchema } from './webhooks-table-schema';

const AclModelName = 'webhook';

@Options({
  name: 'WebhooksPage',
  components: {
    EntityBlank,
    InfiniteScroll,
    ListPage,
    NLoadingCircle,
    NTable,
    SettingsBar,
    SettingsPageLayout
  }
})
export default class WebhooksPage extends Vue {
  loading = false;
  sortField = '';
  searchQuery = '';

  get state(): PageState {
    const tab = 'webhooks';
    return pageModule.getPageStateByTab(PagePaths.Hooks, tab);
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get modelAcl() {
    this.module.aclModelName = AclModelName;
    return aclModule.getModelAcl(this.module);
  }

  get items() {
    let items = this.searchQuery.length > 0 ? filterItemsByName(this.module.items, this.searchQuery) : this.module.items;
    this.sortField && items.sort(sortByField(this.sortField));
    return items;
  }

  get columns() {
    return webhooksTableSchema;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get selectedItems() {
    return this.sidebarModule.getItemsByType(this.pageSidebarType);
  }

  get currentItem() {
    return this.sidebarModule.currentItem?.model.item;
  }

  get pageSidebarType() {
    return MultisidebarItemTypes.Webhooks;
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.Webhooks).map((v) => v.model.item.id);
  }

  mounted() {
    this.module.filter.current.limit = 1e3;
    this.load();
    autoUpdateHelper.addListInstance(this.module);
  }

  handleTableSort(columnName: string) {
    this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = this.sortField;
    this.load();
  }

  async actionHandler({ row, path }: any): Promise<void> {
    const id = row.id;
    if (path === 'active') {
      if (row.active) {
        await actionHandler.run(ItemsActionNames.Activate, { type: this.pageSidebarType, rawItem: { ...row, active: false } });
      } else {
        await actionHandler.run(ItemsActionNames.Deactivate, { type: this.pageSidebarType, rawItem: { ...row, active: true } });
      }
    } else {
      await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
    }
    return;
  }

  async load(): Promise<void> {
    this.loading = true;
    try {
      await this.module.get();
    } finally {
      this.loading = false;
    }
  }

  resetFilters() {
    this.module.resetFilters();
  }

  async handleCreate(): Promise<void> {
    await this.sidebarModule.addNewItemByType(this.pageSidebarType);
  }

  handleSelectChanges(changes: number[]) {
    changes
      ?.map((id) => (this.module.items as any[]).find((v: any) => v.id === id))
      .forEach((item) => actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: PageTypes.Hooks, rawItem: item }));
  }

  selectHandler(ids: Array<number>) {
    this.module.items.forEach((item) => {
      if (ids.includes(item.id)) {
        actionHandler.run(ItemsActionNames.AddItem, { type: this.pageSidebarType, rawItem: item });
      } else {
        const selectedSidebarModuleItem = this.sidebarModule.items.find((sidebarItem) => sidebarItem.model.item.id === item.id);
        if (selectedSidebarModuleItem) {
          actionHandler.run(ItemsActionNames.Remove, selectedSidebarModuleItem);
        }
      }
    });
  }
}
