import { get, intersection } from 'lodash';
import { getSatisfyEventFeatures } from '@/store/events/features.filter';
import * as filters from '@/common/filters';
import { doesEventSatisfyHeadposeFilter } from './satisfy.event.filter';
import { ObjectsTypesMap } from '@/store/application/data.assets';

export function satisfyEpisodeFilter(episode: any, filter: any, objects: string): boolean {
  let satisfy = true;
  const event = episode.last_face_event || episode.last_body_event || episode.last_car_event;

  if (satisfy && filter.has_face) {
    satisfy = !!(episode.best_face_event || episode.last_face_event);
  }

  if (satisfy && filter.has_body) {
    satisfy = !!(episode.best_body_event || episode.last_body_event);
  }

  if (satisfy && filter.id_in) {
    const id = String(episode.id);
    satisfy = Array.isArray(filter.id_in) ? (filter.id_in.length ? filter.id_in.includes(id) : true) : String(filter.id_in) === id;
  }

  if (satisfy && filter.matched_card_in) {
    const id = String(episode.matched_card);
    const currentFilter = filter.matched_card_in;
    satisfy = Array.isArray(filter.id_in) ? (currentFilter.length ? currentFilter.includes(id) : true) : String(currentFilter) === id;
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? episode.acknowledged : !episode.acknowledged;
  }

  if (satisfy && filter.has_face) {
    satisfy = !!(episode.best_face_event || episode.last_face_event);
  }

  if (satisfy && filter.has_body) {
    satisfy = !!(episode.best_body_event || episode.last_body_event);
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.no_face_match) {
    const hasMatch = !!episode.matched_face_event;
    satisfy = filter.no_face_match === 'True' ? !hasMatch : hasMatch;
  }

  if (satisfy && filter.no_body_match) {
    const hasMatch = !!episode.matched_body_event;
    satisfy = filter.no_body_match === 'True' ? !hasMatch : hasMatch;
  }

  if (satisfy && filter.no_car_match) {
    const hasMatch = !!event.matched_card;
    satisfy = filter.no_car_match === 'True' ? !hasMatch : hasMatch;
  }

  if (satisfy && filter.camera_groups.length) {
    satisfy = filter.camera_groups.find((v: number) => v === event.camera_group);
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v: number) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.gender?.length) {
    let r = get(event, 'features.gender.name'),
      item = filter.gender.find((v: string) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.events_count_gte) {
    satisfy = episode.events_count >= filter.events_count_gte;
  }

  if (satisfy && filter.video_archive?.length) {
    satisfy = Number(filter.video_archive) === event.video_archive;
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }
  if (objects === 'humans') {
    satisfy = satisfy && getSatisfyEventFeatures(episode.last_face_event, filter, ObjectsTypesMap.Faces);
    satisfy = satisfy && getSatisfyEventFeatures(episode.last_body_event, filter, ObjectsTypesMap.Bodies);
  } else {
    satisfy = satisfy && getSatisfyEventFeatures(episode.last_car_event, filter, ObjectsTypesMap.Cars);
  }
  satisfy = satisfy && (objects === 'humans' ? doesEventSatisfyHeadposeFilter(event.face_features ?? {}, filter) : true);
  return satisfy;
}
