export default {
  objectTypes: {
    alertrule: 'alert',
    uploadlist: 'batch upload',
    line: 'lines',
    camera: 'camera',
    cameragroup: 'camera group',
    watchlist: 'watch list',
    listevent: 'event',
    videoarchive: 'video',
    group: 'role',
    'face and face': 'face verification',
    'car and car': 'vehicle verification',
    'body and body': 'body verification',
    humancard: 'individuals records',
    carcard: 'vehicle records',
    facecluster: 'face clusters',
    bodycluster: 'body clusters',
    carcluster: 'vehicle clusters',
    humanepisode: 'people episodes',
    carepisode: 'vehicle episodes',
    counter: 'counter',
    faceobject: 'record face image',
    bodyobject: 'record body image',
    carobject: 'record vehicle image',
    faceevent: 'face event',
    bodyevent: 'body event',
    carevent: 'vehicle event',
    license: 'license',
    report: 'report',
    settings: 'settings',
    user: 'user',
    webhook: 'webhook',
    ffsecauthsession: 'Session',
    deviceblacklistrecord: 'Blocklist record',
    area: 'area',
    case: 'case',
    caseattachment: 'case attachment',
    caseevent: 'case event',
    casecluster: 'case cluster',
    casephoto: 'case photo',
    caseparticipant: 'case participant',
    humancardattachment: 'individual record attachment',
    carcardattachment: 'vehicle record attachment',
    caseparticipantattachment: 'case participant attachment',
    searchrequest: 'search request',
    remotemonitoringrecord: 'remote monitoring record',
    remotemonitoringevent: 'remote alerting event'
  },
  user: 'user',
  request_ip: 'IP address',
  device_uid: 'device ID',
  action_type: 'action',
  object_type: 'object',
  object_id: 'object ID',
  created_date: 'time',
  details: 'details',
  copy: 'copy',
  clipboard_success: 'Copied to clipboard',
  select_object: 'select object',
  select_action: 'select action',
  input_request_ip: 'enter IP address',
  input_device_uid: 'enter device ID',
  input_object_id: 'enter object ID',
  input_user: 'enter username',
  date_and_time: 'Date and time',
  request_ip_is_invalid: 'IP address is invalid',
  image: 'Image',
  actionTypes: {
    ad_auth: 'active directory authentication',
    destroy: 'delete',
    partial_update: 'edit',
    auth_upload: 'upload video',
    acknowledge: 'acknowledge all',
    acknowledge_all: 'acknowledge all',
    purge_all: 'purge all',
    upload: 'upload',
    basic_auth: 'authentication',
    create: 'add',
    search: 'search',
    purge: 'purge',
    restart: 'restart',
    video_auth_renew: 'face check',
    verify: 'verify',
    update: 'update',
    video_auth: 'face authentication',
    merge: 'merge',
    list: 'list',
    close_multiple_sessions: 'close multiple sessions',
    update_search: 'update search',
    separate: 'separate'
  }
};
