import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "annex-alarms-page__empty" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonPage = _resolveComponent("CommonPage")!

  return (_openBlock(), _createBlock(_component_CommonPage, {
    class: "annex-alarms-page",
    state: _ctx.commonPageState
  }, {
    "items-empty": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          width: "198",
          height: "158",
          src: require('@/pages/annex/assets/undraw_warning_re_eoyh 1.svg')
        }, null, 8, _hoisted_2),
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('annex_alarms.items_empty')), 1)
      ])
    ]),
    _: 1
  }, 8, ["state"]))
}