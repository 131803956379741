import { MultisidebarItemType } from '@/store/multisidebar/types';
import { Vue } from 'vue-class-component';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';

export type MapMarkerColors = {
  new: string;
  default: string;
  old: string;
};

export type MapMarkerColorKey = keyof MapMarkerColors;

export type MapMarkerColorsConfig = {
  default: MapMarkerColors;
  colors?: Record<string, string>;
};

export type CommonMapConfig = {
  sidebarTitle_i18n?: string;
  mode?: string;
  showTracks?: boolean;
  showHasCones?: boolean;
  hasCones?: boolean;
  trackKeys?: number[],
  module: ItemViewModel<any> | ListViewModel<any, any>;
  msbType: MultisidebarItemType;
  centerPoint: LatLng;
  colorsConfig: MapMarkerColorsConfig;
  ItemMarker: Vue<any, any, any>;
  ClusterMarker: Vue<any, any, any>;
  PopupItem: Vue<any, any, any>;
  itemProps?: any;
  ListItem: Vue<any, any, any>;
  panelItemProps?: any;
  actionHandler: any;
  minZoom?: number;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export const CommonMapTypes = {
  Default: 'default',
  Search: 'search'
} as const;

export type CommonMapType = typeof CommonMapTypes[keyof typeof CommonMapTypes];
