
import { Options, Vue } from 'vue-class-component';
import { NButton } from '@/uikit';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import { ImageGalleryItem } from '@/uikit/image-viewer/types';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import ImageGallery from '@/components/image-viewer/ImageGallery.vue';
import { PhotoViewerController } from '@/components/photo-viewer/PhotoViewerController';
import { PhotoViewerItem } from '@/components/photo-viewer/types';

@Options({
  name: 'PhotoViewer',
  components: { ImageGallery, NBaseBar, NButton, NThumbnail }
})
export default class PhotoViewer extends Vue {
  private showItems = false;

  get photoViewer() {
    return this.$photoViewer as PhotoViewerController;
  }

  get activeItemIndex() {
    return this.photoViewer.options.activeItemIndex;
  }

  set activeItemIndex(v: number) {
    this.photoViewer.options.activeItemIndex = v;
  }

  get items() {
    return this.photoViewer.options.items;
  }

  get thumbnails() {
    return this.items.map((v) => v.thumbnail);
  }

  get galleryItems() {
    return this.items.map((v) => {
      const { thumbnail, fullframe, ...galleryItem } = v;
      (galleryItem as ImageGalleryItem).src = fullframe;
      return galleryItem;
    });
  }

  exclude(item: ImageGalleryItem) {
    this.photoViewer.options.items = this.photoViewer.options.items.filter((v) => v.fullframe !== item.src);
  }

  handleClose() {
    this.$photoViewer.hide();
  }

  showItemFullframe(i: number) {
    this.activeItemIndex = i;
    this.showItems = false;
  }

  mounted() {
    this.$refs.root.focus();
  }
}
