
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WatchList } from '@/api';
import { dataModule } from '@/store/data';
import { WatchListItemTypes, WatchListItemMarkers, WatchListItemMarker, WatchListItemType, WatchListTextSizes, WatchListTextSize } from './watch-list-types';
import WatchListItem from './WatchListItem.vue';

@Options({
  name: 'WatchListLabels',
  components: { WatchListItem }
})
export default class WatchListsGroup extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: number[];

  @Prop({ type: String, default: WatchListItemTypes.Full })
  readonly type!: WatchListItemType;

  @Prop({ type: String, default: WatchListItemMarkers.Stick })
  readonly marker!: WatchListItemMarker;

  @Prop({ type: String, required: false })
  readonly textColor?: string;

  @Prop({ type: String, default: WatchListTextSizes.Small })
  readonly textSize!: WatchListTextSize;

  @Prop({ type: Number, default: 3 })
  readonly maxRows!: number;

  @Prop({ type: Boolean, default: false })
  readonly tagView!: boolean;

  get isSingleItemMode() {
    return this.type === WatchListItemTypes.Short && this.watchLists.length === 1;
  }

  get watchLists(): WatchList[] {
    const watchLists = dataModule.watchListsModule.items;
    const items = watchLists.filter((watchList) => this.items.includes(watchList.id));
    return this.type === WatchListItemTypes.Full ? items : items.slice(0, this.maxRows);
  }

  get classes(): Record<string, boolean> {
    return {
      [`watch-lists_${this.type}`]: true,
      'watch-lists_single-item-mode': this.isSingleItemMode,
      'watch-lists_with-overflow-count': this.showRowsOverflowCount
    };
  }

  get showRowsOverflowCount() {
    return this.type === WatchListItemTypes.Short && !this.isSingleItemMode && this.rowsOverflowCount > 0;
  }

  get rowsOverflowCount() {
    return this.items.length - this.maxRows;
  }
}
