// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import axios from 'axios';
import { DetectService, ObjectsService } from '@/api';
import { viewModelRepository } from '@/api/common';
import { DetectResult } from '@/components/detection/types';

const defaultCard = {
  active: true,
  filled: false,
  created_date: '',
  modified_date: '',
  name: 'Card 1',
  comment: 'Comment 1',
  watch_lists: [1],
  looks_like: null,
  meta: {},
  looks_like_confidence: 0,
  active_after: null,
  active_before: null,
  recount_schedule_on: '',
  face_objects: 0,
  body_objects: 0,
  face_cluster: 0,
  body_cluster: 0,
  links_to_relations: []
};

async function createHumanCard(name: string, photoUrl: string, options: any = {}) {
  try {
    const blob = await axios.get(photoUrl, { responseType: 'blob' });
    const file = new File([blob.data], 'image.jpg', { type: blob.data.type });
    const payload = {
      photo: file,
      attributes: { face: {} }
    };

    const result = (await DetectService.detectCreate(payload as any)) as DetectResult;
    const objects = { ...result.objects };

    const detectItem = objects['face'][0];

    const model = viewModelRepository.getCardsHumansItemViewModel();
    model.setItemsState({ ...defaultCard, name, ...options });
    const newCard = await model.save();

    ObjectsService.objectsFacesCreate({ card: newCard.id, source_photo: file, detect_id: detectItem.id });
  } catch (e) {
    throw new Error('add card error');
  }
}

export { createHumanCard };
