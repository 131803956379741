
import {Options, Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {
  ExcludedFeatureNames,
  License,
  LicenseExtraMap,
  LicenseFeaturesMap,
  LicenseFeatureStatus,
  LicenseFeatureStatusItems,
  LicenseResourcesMap,
  Product
} from '@/pages/license_v1/types';
import LicenseFeaturesContent from "@/pages/license_v1/components/common/LicenseFeaturesContent.vue";
import LicenseResourceTable from "@/pages/license_v1/components/common/LicenseResourceTable.vue";
import LicenseTag from "@/pages/license_v1/components/common/LicenseTag.vue";
import LicenseExtraContent from '@/pages/license_v1/components/common/LicenseExtraContent.vue';
import {licenseModule} from '@/store/config/LicenseModule';
import NButton from '@/uikit/buttons/NButton.vue';
import {copyTextToClipboard} from '@/uikit/helpers';

export type FeatureItem = {
  name: string;
  status: LicenseFeatureStatus;
};

@Options({
  components: {NButton, LicenseExtraContent, LicenseResourceTable, LicenseFeaturesContent, LicenseTag}
})
export default class LicensePage extends Vue {
  @Prop({type: Object, required: true})
  item!: License;

  get extraMap() {
    return Object.values(this.item.products).reduce((m: Record<string, any>, v: Product) => {
      Object.assign(m, v.extra);
      return m;
    }, {}) as LicenseExtraMap;
  }

  get featuresMap(): LicenseFeaturesMap {
    const featuresMap = Object.values(this.item.products).reduce((m: Record<string, any>, v: Product) => {
      Object.assign(m, v.features);
      return m;
    }, {}) as LicenseFeaturesMap;

    const licenseExtra = this.extraMap;

    const results: LicenseFeaturesMap = Object.assign({}, featuresMap);

    Object.keys(licenseExtra || {}).forEach((featureName: string) => {
      results[featureName] = {value: licenseExtra[featureName] as boolean};
    });

    ExcludedFeatureNames.forEach((name) => delete results[name]);

    return results;
  }

  get featureStatusesMap(): Record<string, LicenseFeatureStatus> {
    const result = Object.keys(this.featuresMap).reduce((m: Record<string, LicenseFeatureStatus>, v: string) => {
      const available = this.featuresMap[v]?.value;
      m[v] = available ? this.licenseStatus : LicenseFeatureStatusItems.Disabled;
      return m;
    }, {});
    return result;
  }

  get resourcesMap() {
    return Object.values(this.item.products).reduce((m: Record<string, any>, v: Product) => {
      Object.keys(v.resources).forEach((name: string) => {
        if (m[name]) {
          m[name].current += v.resources[name].current;
          m[name].value += v.resources[name].value;
        } else {
          m[name] = {...v.resources[name]};
        }
      });
      return m;
    }, {}) as LicenseResourcesMap;

  }

  get licenseStatus() {
    return licenseModule.getLicenseStatus(this.item);
  }

  get licenseTooltip() {
    return licenseModule.getLicenseTooltipText(this.licenseStatus);
  }

  isValid(item: License) {
    return licenseModule.isValidLicense(item);
  }

  formatLicenseExpireDate(value: number) {
    const time = value * 1e3;
    const currentTime = new Date().getTime();
    const dateTimeString = this.$filters.formatDateTime(time);
    return time >= currentTime ? dateTimeString : this.$t('common.expired');
  }

  copy(value: string) {
    copyTextToClipboard(value);
  }
}
