import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cluster-wizard-item" }
const _hoisted_2 = {
  key: 1,
  class: "cluster-wizard-item__hover"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.confidence)
      ? (_openBlock(), _createBlock(_component_Confidence, {
          key: 0,
          value: _ctx.confidence,
          type: "number",
          objects: 'faces',
          class: _normalizeClass(["cluster-wizard-item__looks-like", { 'cluster-wizard-item__looks-like_case': _ctx.caseId }])
        }, null, 8, ["value", "class"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.canAdd)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NButton, {
            icon: "participant-add",
            type: "secondary",
            widen: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('action', 'add'))),
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('cases.add')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}