import { dataAssetsModule } from '@/store/application/data.assets.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { NInput } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { FormValidatorNames, IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import NText from '@/uikit/text/NText.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import DataSourcesOnvifAuth from '@/pages/data-sources/components/DataSourcesOnvifAuth.vue';
import DataSourcesOnvifProfileStreams from '@/pages/data-sources/components/DataSourcesOnvifProfileStreams.vue';

export const formLayoutHeaders: IFormLayout = [
  [
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'ds.camera_name',
        overflowType: 'none'
      }
    },
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'ds.ds_camera_group',
        overflowType: 'none'
      }
    },
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'ds.stream',
        overflowType: 'none'
      }
    },
    {
      classes: 'n-form-pad-10',
      component: NText,
      props: {
        i18n_modelValue: 'ds.ip_address',
        overflowType: 'none'
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'ds.faces',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'ds.bodies',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText,
      props: {
        i18n_modelValue: 'ds.cars',
        overflowType: 'none'
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NText
    }
  ]
];
export const formLayoutContent: IFormLayout = [
  [
    {
      classes: 'n-form-pad-10',
      path: 'name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }],
      props(model: any) {
        return {
          disabled: model.metaOnvif.need_auth
        };
      }
    },
    {
      classes: 'n-form-pad-10',
      path: 'group',
      component: CameraGroupName,
      validators: [{ name: FormValidatorNames.Required }],
      props(model: any) {
        return {
          model,
          disabled: model.metaOnvif.need_auth
        };
      }
    },
    {
      classes: 'n-form-pad-10',
      path: 'url',
      component: DataSourcesOnvifProfileStreams,
      props(model: any) {
        return {
          model: model.metaOnvif,
          disabled: model.metaOnvif.need_auth
        };
      }
    },
    {
      classes: 'n-form-pad-10',
      path: 'ip_address',
      component: NText
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: IFormContext) {
        cameraResetModule.toggleDetector(this.model, 'face');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors?.face;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: IFormContext) {
        cameraResetModule.toggleDetector(this.model, 'body');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors.body;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: NCheckbox,
      encode: function (this: IFormContext) {
        cameraResetModule.toggleDetector(this.model, 'car');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors.car;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    },
    {
      classes: 'n-form-pad-10 n-form-control-center',
      component: DataSourcesOnvifAuth,
      props(model: any) {
        return {
          modelForm: model.metaOnvif
        };
      },
      on: {
        updateFormModel: function (this: IFormContext, value: any) {
          this.form.$emit('updateFormModel', value);
        }
      }
    }
  ]
];
