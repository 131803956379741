/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type AuditLog = {
    readonly id: number;
    related_entities: Record<string, str_int_float_bool_object_array_null>;
    action_details: Record<string, str_int_float_bool_object_array_null>;
    readonly object_id: string | null;
    /**
     * Date of action
     */
    readonly created_date: string;
    /**
     * Id of the user who took action
     */
    user_id?: number | null;
    /**
     * Username of the user who took action
     */
    user_login?: string | null;
    /**
     * IP address from which the request came
     */
    request_ip: string;
    /**
     * Unique device identifier
     */
    device_uid?: string | null;
    /**
     * Type of object with which the action was performed
     */
    object_type: string;
    /**
     * Object action type
     */
    action_type: string;

    fullframe?: string;
    thumbnail?: string;
};
export const EmptyAuditLog: AuditLog = {
    id: 0,
    related_entities: {},
    action_details: {},
    object_id: '',
    /**
     * Date of action
     */
    created_date: '',
    /**
     * Id of the user who took action
     */
    user_id: 0,
    /**
     * Username of the user who took action
     */
    user_login: '',
    /**
     * IP address from which the request came
     */
    request_ip: '',
    /**
     * Unique device identifier
     */
    device_uid: '',
    /**
     * Type of object with which the action was performed
     */
    object_type: '',
    /**
     * Object action type
     */
    action_type: '',
};
