export default {
  matched_event: 'Matched event',
  matched_episode: 'Matched episode',
  unmatched_event: 'Unmatched event',
  unmatched_episode: 'Unmatched episode',
  all_events_acknowledged_confirm: 'Do you want to acknowledge all events?',
  all_episodes_acknowledged_confirm: 'Do you want to acknowledge all episodes?',
  angle_range_is_invalid: 'Enter a value from -90° to 90°',
  map_tracks_button_description: 'Show tracks'
};
