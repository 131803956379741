
import { Options, Vue } from 'vue-class-component';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import { DisplayTypes, PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { aclModule } from '@/store/acl';
import CommonPage from '@/components/common/page/CommonPage.vue';
import { CommonPageState } from '@/components/common/page/CommonPageState';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import AnnexAlarmItemTile from '@/pages/annex/alarms/AnnexAlarmItemTile.vue';
import AnnexAlarmItemRow from '@/pages/annex/alarms/AnnexAlarmItemRow.vue';
import { annexAlarmActionHandler } from '@/pages/annex/alarms/AnnexAlarmActionHandler';
import { alertTableSchema } from '@/pages/alerts/AlertTableSchema';
import { getAlertsFiltersBuilder } from '@/pages/alerts/AlertsFilterBuilder';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import { actionsModule } from '@/store/data/ActionsModule';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import AlertWizard from '@/pages/alerts/AlertWizard.vue';
import { Alert } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';

@Options({
  name: 'AlertsPage',
  components: { AlertWizard, CommonPage, SettingsBar, SettingsPageLayout }
})
export default class AlertsPage extends Vue {
  commonPageState = new CommonPageState();
  newItemModule?: ItemViewModel<Alert>;
  showNewItemWizard = false;

  get state(): PageState {
    const tab = 'alerts';
    return pageModule.getPageStateByTab(PagePaths.Alerts, tab);
  }

  get module() {
    const module = viewModelRepository.getAlertsListViewModel();
    if (module) {
      module.aclModelName = 'alertrule';
      module.filter.force.limit = '1000'; // todo: type bug string
    }
    return module;
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  mounted() {
    autoUpdateHelper.addListInstance(this.module);

    this.commonPageState.module = this.module;
    this.commonPageState.pageSidebarType = MultisidebarItemTypes.Alerts;
    this.commonPageState.serializeToLocalStorageAsKey = 'alerts_state';

    this.commonPageState.module.filter.storageKey = 'alerts_filter'; // TODO: refactor this!

    this.commonPageState.addNew.show = true;
    this.commonPageState.addNew.label = this.$t('alerts.new_alert');
    this.commonPageState.addNew.handler = (() => this.addNewHandler()) as any;

    this.commonPageState.filter.show = true;
    this.commonPageState.filter.schemeSmall = getAlertsFiltersBuilder({ small: true });
    this.commonPageState.filter.schemeLarge = getAlertsFiltersBuilder({ small: false });

    this.commonPageState.showStatistics = true;
    this.commonPageState.sortTypes = dataAssetsModule.getSortTypes({ id: true, name: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));

    this.commonPageState.displayType = DisplayTypes.Table;
    this.commonPageState.tableSchema = () => {
      return [
        ...alertTableSchema,
        {
          header: {
            classes: ['bottom-border'],
            component: NTableColumnCell,
            props: {}
          },
          content: {
            path: 'active',
            component: ActionsDropdown,
            props: (model: any) => {
              const modelAcl = aclModule.getModelAcl(this.module);
              return {
                actions: actionsModule.getAndComputeActions(modelAcl, {
                  hasActive: true,
                  hasDelete: true,
                  hasDuplicate: true,
                  currentActive: model.active
                }),
                placement: 'left-start',
                onAction: (action: ItemsActionName) => this.commonPageState.actionHandler(action, model)
              };
            }
          }
        }
      ];
    };

    this.commonPageState.componentForShort = Object.freeze(AnnexAlarmItemTile);
    this.commonPageState.componentForFull = Object.freeze(AnnexAlarmItemRow);
    this.commonPageState.actionHandler = this.actionHandler.bind(this);
  }

  async actionHandler(action: string, row: any) {
    await actionHandler.run(action as ItemsActionName, { type: this.commonPageState.pageSidebarType, rawItem: row });
  }

  beforeUnmount() {
    autoUpdateHelper.removeListInstance(this.module);
    //if (this.disposeFilterWatcher) this.disposeFilterWatcher();
  }

  addNewHandler() {
    this.newItemModule = viewModelRepository.getAlertsItemViewModel();
    this.showNewItemWizard = true;
    return Promise.resolve(true);
  }

  saveItem() {
    this.newItemModule!.save();
  }

  closeWizard() {
    this.showNewItemWizard = false;
  }
}
