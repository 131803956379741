
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { PageTypes } from '@/store/application/page.definitions';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell, ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import EntityBlank from '@/components/common/EntityBlank.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SortSection from '@/components/common/SortSection.vue';
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import { getCounterFilters } from '@/pages/counters/counterFiltersBuilder';
import CounterForm from '@/pages/counters/CounterForm.vue';
import CounterRecords from '@/pages/counters/CounterRecords.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import columns from './schemes/counters-columns';
import { sortModule } from '@/store/data/SortModule';
import { Counter } from '@/api';

@Options({
  name: 'CountersPage',
  components: {
    CounterForm,
    CounterRecords,
    EntityBlank,
    FilterSection,
    InfiniteScroll,
    NButton,
    NInput,
    NTable,
    NTabs,
    PageContentLayout,
    SortSection
  }
})
export default class CountersPage extends Vue {
  readonly module = viewModelRepository.getCountersListViewModel();

  state = {
    pageType: PageTypes.Counters
  };

  autoUpdateTimer = new SimpleTimer();

  beforeMount() {
    sortModule.readyPromise.then(() => {
      const ordering = sortModule.get(this.module.name);
      if (ordering) this.module.filter.current.ordering = ordering;
    });
  }

  get columns() {
    let result = [...columns];
    if (!configModule.config.counters?.show_proximity) result.splice(6, 1);
    return result;
  }


  get smallFiltersLayer() {
    return getCounterFilters({ small: true });
  }

  get largeFiltersLayer() {
    return getCounterFilters();
  }

  get modelAcl() {
    this.module.aclModelName = 'counter';
    return aclModule.getModelAcl(this.module);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.Counters).map((v) => v.model.item.id);
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler() {
    this.module.get();
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  created() {}

  mounted() {
    autoUpdateHelper.addListInstance(this.module);
    this.module.get();
    this.sidebarModule.contentOverlap = true;

    const updateInterval = configModule.config.counters?.update_interval || 30;
    this.autoUpdateTimer.setInterval(() => {
      this.module.get();
    }, updateInterval * 1000);
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.autoUpdateTimer.clear();
    this.sidebarModule.contentOverlap = false;
  }

  addNewCounter() {
    this.sidebarModule.addNewItemByType(MultisidebarItemTypes.Counters);
  }

  handleSelectChanges(changes: number[]) {
    changes
      ?.map((id) => (this.module.items as any[]).find((v: any) => v.id === id))
      .forEach((item) =>
        actionHandler.run(ItemsActionNames.ToggleSelectItem, {
          type: MultisidebarItemTypes.Counters,
          rawItem: item
        })
      );
  }

  cellClickHandler(row: Counter, cell: ITableCell) {
    const actionPayload = { type: MultisidebarItemTypes.Counters, rawItem: row };
    if (cell.path === 'name') {
      actionHandler.run(ItemsActionNames.ShowItem, actionPayload);
    }
  }

  handleAction(payload: ITableCellChangePayload) {
    const actionPayload = { type: MultisidebarItemTypes.Counters, rawItem: payload.row };

    switch (payload.path) {
      case 'id':
        actionHandler.run(ItemsActionNames.ShowItem, actionPayload);
        break;
      case 'active':
        payload.type === 'change' && actionHandler.run(ItemsActionNames.UpdateActiveField, actionPayload);
        break;
    }
  }
}
