import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "services-table",
      style: _normalizeStyle(_ctx.tableStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceNames, (name) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "services-table__header heading-m",
          key: name
        }, _toDisplayString(name), 1))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceNames, (name) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `r-${name}`,
          class: "services-table__cell"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getServicesByName(name), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.ip,
              class: "services-table__cell-item"
            }, _toDisplayString(item.ip), 1))
          }), 128))
        ]))
      }), 128))
    ], 4)
  ]))
}