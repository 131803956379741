
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { BodyCluster, CarCluster, FaceCluster } from '@/api';
import { ThumbnailAction } from '@/uikit/thumbnail';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import { ClusterOpenedItem } from '@/components/clusters/types';

@Options({
  components: { ClusterItem }
})
export default class ClusterItemProxy extends Vue {
  @Prop({ type: Object, required: true })
  item!: FaceCluster | BodyCluster | CarCluster;

  @Prop({ type: Number })
  index?: number;

  @Prop({ type: String, required: true })
  objects!: string;

  @Prop({ type: String, default: 'clusters' })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: String })
  defaultAction?: ItemsActionName;

  @Prop({ type: Boolean, default: false })
  useDefaultActions!: boolean;

  @Prop({ type: Array, default: () => [] })
  actions!: ThumbnailAction[];

  @Prop({ type: Boolean, default: false })
  clusterSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: String, default: '' })
  openedItem!: ClusterOpenedItem;

  @Prop({ type: Boolean, default: false })
  display!: boolean;

  get classes() {
    const base = 'cluster-item-proxy';
    return {
      [base + '_' + this.displayType]: true
    };
  }
}
