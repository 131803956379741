import intersection from 'lodash/intersection';
import * as filters from '@/common/filters';
const isoStringToDate = filters.isoStringToDate;

export function applyFilterForItem(item: any, filter: any): boolean {
  const filterKeys = Object.keys(filter);
  
  for (let i = 0; i < filterKeys.length; i++) {
    const filterKey = filterKeys[i];
    const filterValue = filter[filterKey];

    if (['ordering', 'page', 'size'].includes(filterKey)) {
      continue;
    }

    if (filterValue === '' || typeof filterValue === 'undefined' || (Array.isArray(filterValue) && !filterValue.length)) {
      continue;
    }

    const filterKey3 = filterKey.substr(0, filterKey.length - 3);
    const filterKey4 = filterKey.substr(0, filterKey.length - 4);

    // TODO: remove  || filterKey === 'declared_to'  || filterKey === 'declared_from' !!!!!!

    if (filterKey.endsWith('_date_lte') || filterKey === 'declared_to') {
      const filterDate = isoStringToDate(filterValue);
      const itemDate = isoStringToDate(item[filterKey4]);
      if (itemDate.getTime() <= filterDate.getTime()) {
        return false;
      }
    }

    if (filterKey.endsWith('_date_gte') || filterKey === 'declared_from') {
      const filterDate = isoStringToDate(filterValue);
      const itemDate = isoStringToDate(item[filterKey4]);
      if (itemDate.getTime() >= filterDate.getTime()) {
        return false;
      }
    }

    if (filterKey.endsWith('_date_lt')) {
      const filterDate = isoStringToDate(filterValue);
      const itemDate = isoStringToDate(item[filterKey3]);
      if (itemDate.getTime() < filterDate.getTime()) {
        return false;
      }
    }

    if (filterKey.endsWith('_date_gt')) {
      const filterDate = isoStringToDate(filterValue);
      const itemDate = isoStringToDate(item[filterKey3]);
      if (itemDate.getTime() > filterDate.getTime()) {
        return false;
      }
    }

    if (filterKey.endsWith('_lte')) {
      if (item[filterKey4] <= filterValue) {
        return false;
      }
    }

    if (filterKey.endsWith('_gte')) {
      if (item[filterKey4] >= filterValue) {
        return false;
      }
    }

    if (filterKey.endsWith('_lt')) {
      if (item[filterKey3] < filterValue) {
        return false;
      }
    }

    if (filterKey.endsWith('_gt')) {
      if (item[filterKey3] > filterValue) {
        return false;
      }
    }

    if (filterKey.startsWith('has_')) {
      const targetKey = filterKey.substr(4);
      const hasTargetKey = !!item[targetKey];
      if (hasTargetKey !== filterValue) {
        return false;
      }
      continue;
    }

    if (filterKey === 'rule_id_in') {
      if (!filterValue.includes(String(item.rule_id))) {
        return false;
      }
      continue;
    }

    if (filterKey === 'name_contains') {
      const name = (item.name + '' + item.title).toLocaleLowerCase();
      const nameContains = filterValue.toLocaleLowerCase();
      if (name.indexOf(nameContains) === -1) {
        return false;
      }
      continue;
    }

    if (Array.isArray(filterValue) && filterValue.length) {
      if (Array.isArray(item[filterKey])) {
        if (!intersection(filterValue, item[filterKey]).length) {
          return false;
        }
      } else {
        if (!(filterValue as any[]).includes(item[filterKey])) {
          return false;
        }
      }
      continue;
    }

    if (filterValue != item[filterKey]) {
      return false;
    }
  }

  return true;
}
