/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CasePermissions = {
    readonly id: number;
    /**
     * Case name, up to 256 characters
     */
    readonly name: string;
    permissions?: Record<string, string>;
};
export const EmptyCasePermissions: CasePermissions = {
    id: 0,
        /**
        * Case name, up to 256 characters
        */
    name: '',
    permissions: {},
};