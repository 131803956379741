
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { CarCard, HumanCard } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { applicationModule } from '@/store/application';
import { CardTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { PageNames, PagePaths } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { PageViewModel } from '@/store/application/page.view.model';
import { workspaceModule } from '@/store/application/workspace';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';
import { canShowPage } from '@/store/menu';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NConfirm from '@/uikit/modal-window/NConfirm.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import CardGroupRow from '@/components/cards/CardGroupRow.vue';
import CardGroupTile from '@/components/cards/CardGroupTile.vue';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import ClusterItemTile from '@/components/cards/demo/ClusterItemTile.vue';
import EpisodeItemRow from '@/components/cards/demo/EpisodeItemRow.vue';
import DisplayTypesButtonGroup from '@/components/common/DisplayTypesButtonGroup.vue';
import { smallFilterManagerModule } from '@/components/common/filter/filter-manager/SmallFiltersModule';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import { getCardFiltersBuilder } from '@/pages/cards/filters/CardFiltersBuilder';
import ExternalSearchRequestCreate from '@/pages/external-search/requests/ExternalSearchRequestCreate.vue';
import ListPage from '@/pages/ListPage.vue';

@Options({
  components: {
    CardGroupRow,
    CardGroupTile,
    CardItemProxy,
    ClusterItemTile,
    DisplayTypesButtonGroup,
    EpisodeItemRow,
    ExternalSearchRequestCreate,
    FilterSection,
    ListPage,
    NButton,
    NButtonGroup,
    NConfirm,
    NModalWindow,
    SortDropdown,
    Statistics
  }
})
export default class CardsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  pageVM: PageViewModel<any, any> = new PageViewModel<any, any>({ tab: String(Math.random()), path: PagePaths.Cards });

  intersectionResultItem?: IntersectionResultItem | null = null;

  get selectedItems() {
    return this.sidebarModule.getItemsByType(this.pageSidebarType);
  }

  get pageSidebarType() {
    const { pageType, cardType } = this.state;
    return `${pageType}_${cardType}` as MultisidebarItemType;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get currentItem() {
    return multisidebarModule.currentItemItem;
  }

  get ItemsActionNames() {
    return ItemsActionNames;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get state() {
    return this.pageVM.state;
  }

  get module(): ListViewModel<any, any> {
    return this.pageVM.module as ListViewModel<any, any>;
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get smallFilterLayout() {
    const { cardType } = this.state;
    return getCardFiltersBuilder({ small: true }).getFilterByType(cardType!);
  }

  get bigFilterLayout() {
    const { cardType } = this.state;
    return getCardFiltersBuilder({ small: false }).getFilterByType(cardType!);
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get classes(): Record<string, boolean> {
    return {
      [`page-items__container_${this.state.displayType}`]: true
    };
  }

  get isCurrentTab() {
    return workspaceModule.current === this.state.tab;
  }

  get isShort() {
    return this.state.displayType === 'short';
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get isPuppeteerEnabled() {
    return this.state.cardType === CardTypesMap.Humans && this.monitoringModule.isPuppeteerEnabled;
  }

  get isShowConnections() {
    return configModule.features.cases_enabled && aclModule.getAccess('ffsecurity.view_case');
  }

  get hasInteractions(): boolean {
    return this.state.cardType === CardTypesMap.Humans && canShowPage(PageNames.Interactions);
  }

  get smallFilterFields() {
    return smallFilterManagerModule.items[this.pageSidebarType];
  }

  setSmallFilterFields(v: string[]) {
    smallFilterManagerModule.setFiltersBySource(this.pageSidebarType, v, true);
  }

  created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    this.pageVM = reactive(pageModule.getPageViewModel(route)) as PageViewModel<any, any>;
  }

  mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.card-item-proxy',
      id: this.tab
    });
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  @Watch('module', { deep: false })
  changeModuleHandler(v: any, p: any) {
    this.intersectionResultItem?.reset();
    autoUpdateHelper.removeListInstance(p);
    autoUpdateHelper.addListInstance(v);
  }

  @Watch('module.loading')
  loadingHandler(v: boolean, p: boolean) {
    if (!v) {
      this.intersectionResultItem?.reset();
      this.intersectionResultItem?.syncObservableTargetsNextTick();
    }
  }

  @Watch('module.appending')
  appendingHandler(v: boolean, p: boolean) {
    if (!v) this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (!this.isCurrentTab) return;
    if (v?.page !== p?.page) return;
    if (v?.limit !== p?.limit) return;
    this.module.debouncedGet();
    this.pageVM.state.filter = this.module.filter.currentClean;
  }

  // get checkSelectedItemsOnMonitoring() {
  //   if (this.selectedItems.length) {
  //     return this.selectedItems.some(({ model }) => {
  //       return !!this.monitoringModule.getItemsByCardId(Number(model.item.id)).length;
  //     });
  //   }
  //   return false;
  // }

  actionHandler(id: string | number, action: ItemsActionName, payload?: any) {
    actionHandler.run(action, payload);
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, card.id);
  }

  // addSelectedToMonitoring() {
  //   let selectedNonMonitoringCards = this.selectedItems
  //     .filter(({ model }) => !this.monitoringModule.getItemsByCardId(model.item.id).length)
  //     .map(({ model }) => model.item);
  //   this.selectedItems.length && (this.addToMonitoringItems = selectedNonMonitoringCards);
  // }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  get modelAcl() {
    this.module.aclModelName = this.state.cardType === CardTypesMap.Humans ? 'humancard' : 'carcard';
    return aclModule.getModelAcl(this.module);
  }

  addNew() {
    this.sidebarModule.addNewItemByType(this.pageSidebarType);
  }
}
