import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "intervals-header" }
const _hoisted_2 = { class: "heading-m" }
const _hoisted_3 = { class: "intervals-table" }
const _hoisted_4 = { class: "intervals-table__header" }
const _hoisted_5 = { class: "intervals-table__header align-center" }
const _hoisted_6 = { class: "intervals-table__header" }
const _hoisted_7 = { class: "intervals-table__header" }
const _hoisted_8 = { class: "intervals-table__cell" }
const _hoisted_9 = { class: "intervals-table__cell align-center" }
const _hoisted_10 = { class: "intervals-table__cell" }
const _hoisted_11 = { class: "intervals-table__cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_LicenseCircle = _resolveComponent("LicenseCircle")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenseIds, (id) => {
    return (_openBlock(), _createElementBlock("div", {
      key: id,
      class: "intervals"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('license.license')) + ": ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(id), 1),
        _createVNode(_component_NButton, {
          type: "link",
          icon: "copy",
          onClick: ($event: any) => (_ctx.copy(id)),
          class: "copy-button"
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('license.start_date')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('license.status')), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('license.end_date')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('license.counters')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLicenseIntervals(id)?.by_interval, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: `r-${item.since}`
          }, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$filters.formatDateTime(item.since)), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_LicenseCircle, {
                status: _ctx.computeIntervalStatus(item)
              }, null, 8, ["status"])
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(item.till ? _ctx.$filters.formatDateTime(item.till) : '...'), 1),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getIntervalCounters(item.counters), ({ name, counter }) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: name,
                  class: _normalizeClass(
              _ctx.getIntervalClass(_ctx.isActiveInterval(item), {
                used: counter.used,
                available: _ctx.getFeatureAvailableCount(name, _ctx.getLicenseIntervals(id)?.active_limits)
              })
            )
                }, _toDisplayString(name) + " : " + _toDisplayString(counter.used || 0) + " / " + _toDisplayString(_ctx.isActiveInterval(item) ? _ctx.getFeatureAvailableCount(name, _ctx.getLicenseIntervals(id)?.active_limits) : counter.available ?? '-'), 3))
              }), 128))
            ])
          ], 64))
        }), 128))
      ])
    ]))
  }), 128))
}