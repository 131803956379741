import { PointAsArray } from '@/uikit/draw/types';
import { MapMarkerColors, MapMarkerColorsConfig } from '@/components/map/CommonMapConfig';
import { hashCode } from '@/uikit/helpers';
import Color from 'color';

export function getConePath(angle: number, radius: number, center: PointAsArray) {
  const [a, b] = getAnglePoints(angle, radius, center);
  const largeArcFlag = angle > 180 ? 1 : 0;

  return ['M', ...center, 'L', ...a, 'A', radius, radius, 1, largeArcFlag, 1, ...b, 'L', ...center].join(' ');
}

export function getAnglePoints(angle: number, radius: number, center: PointAsArray) {
  const halfAngle = angle / 2;
  const a = getPointOnCircle(center, halfAngle, radius);
  const b = getPointOnCircle(center, -1 * halfAngle, radius);
  return [a, b];
}

export function getAngle(pointA: PointAsArray, pointB: PointAsArray, pointC: PointAsArray, fullRange = true) {
  const vectorAB = [pointB[0] - pointA[0], pointB[1] - pointA[1]];
  const vectorBC = [pointC[0] - pointB[0], pointC[1] - pointB[1]];

  const dotProduct = vectorAB[0] * vectorBC[0] + vectorAB[1] * vectorBC[1];
  const magnitudeAB = Math.sqrt(vectorAB[0] ** 2 + vectorAB[1] ** 2);
  const magnitudeBC = Math.sqrt(vectorBC[0] ** 2 + vectorBC[1] ** 2);

  const cosTheta = dotProduct / (magnitudeAB * magnitudeBC);
  let angleInRadians = Math.acos(cosTheta);

  // Cross product to determine the sign of the angle
  const crossProduct = vectorAB[0] * vectorBC[1] - vectorAB[1] * vectorBC[0];
  if (fullRange && crossProduct < 0) {
    angleInRadians = 2 * Math.PI - angleInRadians;
  }

  // Convert radians to degrees in the range of 0 to 360
  let angleInDegrees = (angleInRadians * 180) / Math.PI;
  if (angleInDegrees < 0) {
    angleInDegrees += 360;
  }

  return angleInDegrees;
}

export function getPointOnCircle(start: PointAsArray, angle: number, radius: number) {
  const rads = (Math.PI * angle) / 180;
  const x = start[0] - radius * Math.sin(rads);
  const y = start[1] - radius * Math.cos(rads);
  return [x, y];
}

export function isPointInPolygon([x, y]: PointAsArray, polygon: PointAsArray[]) {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0],
      yi = polygon[i][1];
    const xj = polygon[j][0],
      yj = polygon[j][1];

    const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function computeColors(key: any, colorsConfig: MapMarkerColorsConfig): MapMarkerColors {
  let colorsGroup = colorsConfig.default as any;
  if (key && colorsConfig.colors) {
    const colorKeys = Object.keys(colorsConfig.colors);
    const len = colorKeys.length - 1;
    const hash = hashCode(String(key));
    const index = hash % len;
    const resultColor = colorsConfig.colors[colorKeys[index]];
    colorsGroup = {
      new: Color(resultColor).lighten(0.5).hex(),
      default: resultColor,
      old: Color(resultColor).darken(0.5).hex()
    };
  }
  return colorsGroup;
}

export function computeColorsByPosition (position: number, colorsConfig: MapMarkerColorsConfig): MapMarkerColors {
  let colorsGroup = colorsConfig.default as any;
  if (colorsConfig.colors) {
    const colorKeys = Object.keys(colorsConfig.colors);
    const len = colorKeys.length - 1;
    const index = position % len;
    const resultColor = colorsConfig.colors[colorKeys[index]];
    colorsGroup = {
      new: Color(resultColor).lighten(0.5).hex(),
      default: resultColor,
      old: Color(resultColor).darken(0.5).hex()
    };
  }
  return colorsGroup;
}


export function rotatePoint([x, y]: PointAsArray, angle: number, [xOrigin, yOrigin]: PointAsArray = [0, 0]): PointAsArray {
  const radians = (angle * Math.PI) / 180;

  const translatedX = x - xOrigin;
  const translatedY = y - yOrigin;

  const rotatedX = translatedX * Math.cos(radians) + translatedY * Math.sin(radians);
  const rotatedY = -translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

  const x1 = rotatedX + xOrigin;
  const y1 = rotatedY + yOrigin;

  return [x1, y1];
}
