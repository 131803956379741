import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "VideoPlayerSettings" }
const _hoisted_2 = { class: "n-flex" }
const _hoisted_3 = { class: "VideoPlayerSettings__label" }
const _hoisted_4 = { class: "VideoPlayerSettings__value" }
const _hoisted_5 = { class: "n-flex" }
const _hoisted_6 = { class: "VideoPlayerSettings__label" }
const _hoisted_7 = { class: "VideoPlayerSettings__value" }
const _hoisted_8 = { class: "n-flex" }
const _hoisted_9 = { class: "VideoPlayerSettings__label" }
const _hoisted_10 = { class: "VideoPlayerSettings__value" }
const _hoisted_11 = { class: "n-flex" }
const _hoisted_12 = { class: "VideoPlayerSettings__label" }
const _hoisted_13 = { class: "VideoPlayerSettings__value" }
const _hoisted_14 = { class: "n-flex" }
const _hoisted_15 = { class: "VideoPlayerSettings__label" }
const _hoisted_16 = { class: "VideoPlayerSettings__value" }
const _hoisted_17 = {
  key: 1,
  class: "n-flex VideoPlayerSettings__checkboxes"
}
const _hoisted_18 = { class: "VideoPlayerSettings__checkbox" }
const _hoisted_19 = { class: "VideoPlayerSettings__checkbox" }
const _hoisted_20 = { class: "VideoPlayerSettings__checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!
  const _component_NInputNumber = _resolveComponent("NInputNumber")!
  const _component_NSlider = _resolveComponent("NSlider")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.settings.canChangeQuality)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('video_player.resolution')), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_NSelect, {
                modelValue: _ctx.settings.resolution,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.resolution) = $event)),
                items: _ctx.resolutionsItems
              }, null, 8, ["modelValue", "items"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('video_player.video_quality')), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_NInputNumber, {
                modelValue: _ctx.settings.videoQuality,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.videoQuality) = $event)),
                min: 1,
                max: 100
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_NSlider, {
              modelValue: _ctx.settings.videoQuality,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.videoQuality) = $event)),
              min: 1,
              max: 100,
              hasInput: false,
              precision: 0,
              step: 1
            }, null, 8, ["modelValue"])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('video_player.brightness')), 1),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_NInputNumber, {
          modelValue: _ctx.settings.brightness,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settings.brightness) = $event)),
          min: 0,
          max: 100
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_NSlider, {
        modelValue: _ctx.settings.brightness,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settings.brightness) = $event)),
        min: 0,
        max: 100,
        hasInput: false,
        precision: 0
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('video_player.contrast')), 1),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_NInputNumber, {
          modelValue: _ctx.settings.contrast,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.settings.contrast) = $event)),
          min: 0,
          max: 100
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_NSlider, {
        modelValue: _ctx.settings.contrast,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.settings.contrast) = $event)),
        min: 0,
        max: 100,
        hasInput: false,
        precision: 0
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('video_player.saturation')), 1),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_NInputNumber, {
          modelValue: _ctx.settings.saturation,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.settings.saturation) = $event)),
          min: 0,
          max: 100
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_NSlider, {
        modelValue: _ctx.settings.saturation,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.settings.saturation) = $event)),
        min: 0,
        max: 100,
        hasInput: false,
        precision: 0
      }, null, 8, ["modelValue"])
    ]),
    (_ctx.settings.canChangeObjects)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_NCheckbox, {
              modelValue: _ctx.settings.bboxObjects.faces,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.settings.bboxObjects.faces) = $event)),
              label: _ctx.$t('video_player.faces')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_NCheckbox, {
              modelValue: _ctx.settings.bboxObjects.bodies,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.settings.bboxObjects.bodies) = $event)),
              label: _ctx.$t('video_player.bodies')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_NCheckbox, {
              modelValue: _ctx.settings.bboxObjects.cars,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.settings.bboxObjects.cars) = $event)),
              label: _ctx.$t('video_player.cars')
            }, null, 8, ["modelValue", "label"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}