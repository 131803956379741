import { NDateInput, NInput } from '@/uikit';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { getDateValidator, getRequiredValidator } from '@/uikit/forms/validators';
import NText from '@/uikit/text/NText.vue';

const DatePlaceholder = '15.06.2036';
const TimePlaceholder = '12:00';
const MillisecondsInMinute = 60000;

export const informationLayout: IFormLayout = [
  {
    path: '__uuid',
    classes: 'n-form-w-6 n-form-pad-10 control-m n-form-label-horizontal-auto-100',
    i18n_label: 'common.uuid',
    component: NText,
    validators: [{ name: 'required' }]
  },
  {
    path: 'reason',
    classes: 'n-form-w-6 n-form-pad-10 control-m n-form-label-horizontal-auto-100',
    i18n_label: 'common.reason',
    component: NInput,
    props: {
      i18n_placeholder: 'common.reason'
    },
    validators: [getRequiredValidator()]
  },
  {
    path: 'expire_date',
    classes: 'n-form-w-3 n-form-pad-10 control-m n-form-label-horizontal-auto-100',
    i18n_label: 'common.expires',
    component: NDateInput,
    props: {
      timeEnabled: true,
      min: computeGraterDateByMinutes(),
      reset: true,
      datePlaceholder: DatePlaceholder,
      timePlaceholder: TimePlaceholder
    },
    validators: [
      getRequiredValidator(),
      getDateValidator({
        min: () => new Date(),
        i18n_message: 'errors.date.greater_than_current'
      })
    ]
  }
];

function computeGraterDateByMinutes(date = new Date(), minutes = 1) {
  return new Date(date.getTime() + minutes * MillisecondsInMinute);
}
