
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CameraGroup } from '@/api';
import { dataModule } from '@/store/data';
import NSelect from '@/uikit/select/NSelect.vue';

const VideoArchiveGroupId = -1;

@Options({
  name: 'CameraGroupName',
  components: { NSelect }
})
export default class CameraGroupName extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Boolean, default: false })
  readonly accent?: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly?: boolean;

  @Prop({ type: Boolean, default: true })
  readonly excludeVideoArchive?: boolean;

  get items() {
    const videoArchiveGroupFilter = (item: CameraGroup) =>
      this.excludeVideoArchive ? this.modelValue === VideoArchiveGroupId || item.id !== VideoArchiveGroupId : true;

    return dataModule.cameraGroupsModule.items.filter(videoArchiveGroupFilter).map((item) => ({
      label: item.name,
      value: item.id
    }));
  }

  updateValue(value: number) {
    this.$emit('update:modelValue', value);
  }

  get cameraGroupName() {
    const dataItems = dataModule.cameraGroupsModule.items;
    const cameraGroup = dataItems.find((item) => item.id === this.modelValue);
    if (cameraGroup) return cameraGroup.name;
    return '[cameraGroup:not found]';
  }
}
