import { isEmpty } from 'lodash';
import { reactive } from 'vue';
import { VerifyService } from '@/api';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import { VerifyModel, DetectionItem, Position } from '@/store/verify/types';
import { extractFullFrameFromEvent, parseUrl } from '@/store/verify/utils';
import { getExternalImage, isUrlContainsState } from '@/pages/search/helpers';

const createEmptyVerifyModel = (): VerifyModel => [
  { event_url: '', file: null, detection_source: null },
  { event_url: '', file: null, detection_source: null }
];

export class VerifyModule {
  public model: VerifyModel = createEmptyVerifyModel();
  public result: any = {};
  public loading = false;

  get detectionsSources(): (DetectionItem | null)[] {
    return this.model.filter((i) => i.detection_source).map((i) => i.detection_source);
  }

  get canVerify() {
    const detections = this.detectionsSources;
    return detections && detections.length > 1 && detections[0]?.object === detections[1]?.object;
  }

  public static create() {
    return reactive(new this);
  }

  public addFiles(files: File | File[], index?: number) {
    if (typeof index === 'number') {
      this.addFilesByIndex(files as File, index);
      return;
    }

    if (Array.isArray(files)) {
      this.addFilesAsArray(files);
    } else {
      if (this.model[0].file) {
        this.model[1].file = files;
      } else {
        this.model[0].file = files;
      }
    }
  }

  private addFilesByIndex(files: File, index: number) {
    this.model[index].file = files;
    this.model[index].detection_source = null;
  }

  private addFilesAsArray(files: File[]) {
    files.forEach((file: File, i: number) => {
      if (i > 1) return;
      this.model[i].file = file;
    });
  }

  public addDetectionSource(source: DetectionItem, index: number) {
    this.model[index].detection_source = isEmpty(source) ? null : source;
    if (this.canVerify) this.verify();
  }

  private getVerifyObject(detectionItem: DetectionItem | null) {
    return `detection:${detectionItem?.detectionId}`;
  }

  private async verify() {
    const detections = this.detectionsSources;
    this.loading = true;
    try {
      const verifyResult = await VerifyService.verifyRetrieve(
        this.getVerifyObject(detections[Position.First]),
        undefined,
        this.getVerifyObject(detections[Position.Second])
      );
      this.result.confidence = verifyResult.confidence;
      this.result.object = detections[Position.First] && ObjectsSingleToMulti[detections[Position.First]!.object];
    } finally {
      this.loading = false;
    }
  }

  public async eventUrlHandler(url: string, index: number) {
    try {
      this.loading = true;
      let file = null;
      const hasState = isUrlContainsState(url);

      if (hasState) {
        file = await this.parseUrlAndReturnFile(url);
      } else {
        const blob = await getExternalImage(url);
        file = new File([blob], `source-image-${index}.jpeg`, { type: 'image/jpeg'});
      }
      this.addFiles(file, index);
    } finally {
      this.loading = false;
    }
  }

  async parseUrlAndReturnFile(url: string): Promise<File> {
    const parsedUrl = parseUrl(url);
    return extractFullFrameFromEvent(parsedUrl);
  }
}
