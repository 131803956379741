
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import { aclModule } from '@/store/acl';
import { configModule } from '@/store/config';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';

@Options({
  name: 'CardOrUnmatched',
  components: { CardItemRow, Confidence, ConfidenceDelimiter }
})
export default class CardOrUnmatched extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedCard;

  @Prop({ type: Boolean, default: false })
  readonly deleted!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;


  get multisidebarModule() {
    return multisidebarModule;
  }

  get pageSidebarType() {
    return MultisidebarItemTypes.CardsHumans;
  }

  get modelAcl() {
    return aclModule.getModelAclByName('humancard');
  }

  get isShowConnections() {
    return configModule.features.cases_enabled && aclModule.getAccess('ffsecurity.view_case');
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.multisidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, card.id);
  }

  handleAction(name: string) {
    switch (name) {
      case 'show-fullframe':
        this.$emit('display-card-image', this.item.faceObject?.source_photo);
        break;
      case 'card-navigate':
        RouterModule.navigateToCard(this.item.id, 'humans');
        break;
    }
  }
}
