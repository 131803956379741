
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { PageNames } from '@/store/application/page.definitions';
import { languageModule } from '@/store/languages';
import { websocketModule, websocketPuppeteerModule } from '@/store/ws/websocket.module';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import WorkspaceProxyView from '@/components/common/WorkspaceProxyView.vue';
import CenterLayout from '@/components/layouts/CenterLayout.vue';
import WorkspaceLayout from '@/components/layouts/WorkspaceLayout.vue';
import PhotoViewer from '@/components/photo-viewer/PhotoViewer.vue';
import ReportsForm from '@/components/reports-form/ReportsForm.vue';
import VideoPlayerDialog from '@/components/video-player/VideoPlayerDialog.vue';
import SessionMonitor from '@/pages/login/SessionMonitor.vue';
import { alertModule } from './store/application/alert.module';
import { workspaceModule } from './store/application/workspace';
import { dialogController } from '@/common/DialogController';

@Options({
  components: { CenterLayout, NModalWindow, PhotoViewer, ReportsForm, SessionMonitor, VideoPlayerDialog, WorkspaceLayout }
})
export default class App extends Vue {
  get isLoginPage() {
    return this.$route.name === PageNames.Login;
  }

  getProxyComponent(Component: any, tab: any) {
    const props = { tab };
    return (
      <WorkspaceProxyView {...props}>
        <Component {...props} />
      </WorkspaceProxyView>
    );
  }

  get workspaceModule() {
    return workspaceModule;
  }

  get languageModule() {
    return languageModule;
  }

  get websocketPuppeteerModule() {
    return websocketPuppeteerModule;
  }

  get websocketModule() {
    return websocketModule;
  }

  get dialogController() {
    return dialogController;
  }

  @Watch('websocketPuppeteerModule.notify')
  @Watch('websocketModule.notify')
  handlerWebsocketPuppeteerModule(value: any) {
    alertModule.isPlay = !!value;
  }

  @Watch('isLoginPage')
  changeLoginPage(v: boolean, p: boolean) {
    if (v) alertModule.stop();
  }
}
