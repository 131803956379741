
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { FaceObjectUpdate, HumanCard } from '@/api';
import { actionHandler } from '@/store/data/ActionHandler';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { NButton, NLoadingCircle } from '@/uikit';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import Confidence from '@/components/common/Confidence.vue';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail';
import { configModule } from '@/store/config';

@Options({
  name: 'MatchedCardInfo',
  components: { Confidence, NButton, NLoadingCircle, NThumbnail }
})
export default class MatchedCardInfo extends Vue {
  @Prop({ type: String, required: true })
  type = '';

  @Prop({ type: Object, required: true })
  objectType = '';

  @Prop({ type: Number, required: true })
  id!: number;

  @Prop({ type: [Number, String], required: true })
  objectId!: number | string;

  @Prop({ type: Number, required: false })
  confidence?: number;

  cardVM: ItemViewModel<any> | null = null;
  attachmentsVM: ListViewModel<any, any> | null = null;
  objectVM: ListViewModel<any, any> | null = null;

  created() {
    this.load();
  }

  async load() {
    this.cardVM = null;
    this.attachmentsVM = null;
    this.objectVM = null;

    if (!this.id) return;

    this.cardVM = loadDataModule.getCardVMByID({ id: this.id, type: this.type });

    if (this.objectId) {
      this.objectVM = loadDataModule.getObjectsLVMByID({ id: this.objectId, type: this.objectType, limit: 1 });
    } else {
      this.objectVM = loadDataModule.getObjectsLVMByCardID({ id: this.id, type: this.objectType, limit: 1 });
    }

    this.attachmentsVM = loadDataModule.getCardAttachmentsVMByID({ id: this.id, type: this.type });
  }

  get card(): HumanCard | undefined {
    return this.cardVM && this.cardVM.item;
  }

  get attachments(): any[] {
    return this.attachmentsVM?.items || [];
  }

  get thumbnail(): string | undefined {
    const object: FaceObjectUpdate | undefined = (this.objectVM?.items || [])[0] as any;
    return object ? object.thumbnail : undefined;
  }

  get thumbnailActions() {
    const result = [
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];
    return result;
  }

  @Watch('id')
  changeIdHandler() {
    this.load();
  }

  openFile(path: string) {
    window.open(path, '_blank');
  }

  navigateToCard() {
    actionHandler.run(ItemsActionNames.ShowItem, { type: `cards_${this.type}`, rawItem: this.card!.id });
  }

  showFullscreen() {
    const object: FaceObjectUpdate | undefined = (this.objectVM?.items || [])[0] as any;
    this.$photoViewer.show(object);
  }
}
