
import { Options, Vue } from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import { scaleBox } from '@/uikit/bbox/helpers';
import { BboxConfig } from '@/uikit/bbox/types';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import cropImage from '@/common/cropImage';
import { closeFullscreen, openFullscreen } from '@/common/utils';
import DetectionImageViewer from '@/components/detection/DetectionImageViewer.vue';

@Options({
  name: 'DetectionDialog',
  components: { DetectionImageViewer, NButton, NModalWindow },
  provide: function () {
    return {
      fullscreenComponent: this
    };
  },
  emits: ['close', 'apply']
})
export default class DetectionDialog extends Vue {
  @Prop({ type: Object, required: true })
  readonly imageFile!: any;

  @Prop({ type: Object })
  readonly options?: any;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly selectByDefault!: boolean;

  @Prop({ type: String })
  readonly customButtonLabel?: string;

  @Prop({ type: Array })
  readonly actions?: string[];

  @Prop({ type: Number, default: 1 })
  readonly thumbnailScale!: number;

  @Prop({ type: Boolean, default: false })
  readonly lockApplyButton!: boolean;

  private selectedBboxes: BboxConfig[] = [];
  private applying = false;
  private loading = true;
  private notFound = false;

  get fullscreenTarget(): HTMLElement {
    return this.$refs.modalWindow?.getElement();
  }

  get buttonLabel() {
    return this.customButtonLabel ?? 'common.apply';
  }

  handleNotFound() {
    this.loading = false;
    this.notFound = true;
  }

  selectBBoxes(value: BboxConfig[]) {
    this.loading = false;
    this.selectedBboxes = value;
  }

  async applyBbox() {
    if (this.applying || this.lockApplyButton) return;
    this.applying = true;
    const croppedBboxes = await Promise.all(
      this.selectedBboxes.map(async (item: BboxConfig) => {
        const box = scaleBox(item.box, this.thumbnailScale);
        const thumbnail = await cropImage(item.meta.imageUrl!, box.w, box.h, box.x, box.y);
        return { ...item, thumbnail };
      })
    );
    this.$emit('apply', croppedBboxes);
    this.applying = false;
  }

  closeHandler() {
    this.$emit('close');
  }
}
