import {
  EmptyAuditLogsPageState,
  EmptyBlockListPageState,
  EmptyCardPageState,
  EmptyCasesPageState,
  EmptyCasePageState,
  EmptyClusterPageState,
  EmptyDatasourcePageState,
  EmptyDefaultPageState,
  EmptyEventPageState,
  EmptyExternalMonitoringEventsPage,
  EmptyExternalVMSPageState,
  EmptyLinesPageState,
  EmptyParticipantsPageState,
  EmptySearchPageState,
  EmptySessionPageState,
  EmptyVideoWallPageState,
  EmptyWebhooksPageState,
  PagePath,
  PagePaths,
  PageState,
  CustomReportsPageState, EmptyAlarmsPageState, EmptyAlertsPageState
} from '@/store/application/page.definitions';
import cloneDeep from 'lodash/cloneDeep';

export function getPageStateByPath(pagePath: PagePath): PageState {
  let result = null;
  switch (pagePath) {
    case PagePaths.Events:
      result = cloneDeep(EmptyEventPageState);
      break;
    case PagePaths.Cards:
      result = cloneDeep(EmptyCardPageState);
      break;
    case PagePaths.Clusters:
      result = cloneDeep(EmptyClusterPageState);
      break;
    case PagePaths.Cases:
      result = cloneDeep(EmptyCasesPageState);
      break;
    case PagePaths.AuditLogs:
      result = cloneDeep(EmptyAuditLogsPageState);
      break;
    case PagePaths.Sessions:
      result = cloneDeep(EmptySessionPageState);
      break;
    case PagePaths.Search:
      result = cloneDeep(EmptySearchPageState);
      break;
    case PagePaths.BlockList:
      result = cloneDeep(EmptyBlockListPageState);
      break;
    case PagePaths.RemoteMonitoringEvents:
      result = cloneDeep(EmptyExternalMonitoringEventsPage);
      break;
    case PagePaths.Participants:
      result = cloneDeep(EmptyParticipantsPageState);
      break;
    case PagePaths.ExternalVms:
      result = cloneDeep(EmptyExternalVMSPageState);
      break;
    case PagePaths.CustomReports:
      result = cloneDeep(CustomReportsPageState);
      break;
    case PagePaths.Alerts:
      result = cloneDeep(EmptyAlertsPageState);
      break;
    case PagePaths.Hooks:
      result = cloneDeep(EmptyWebhooksPageState);
      break;
    case PagePaths.DataSources:
      result = cloneDeep(EmptyDatasourcePageState);
      break;
    case PagePaths.VideoWall:
      result = cloneDeep(EmptyVideoWallPageState);
      break;
    case PagePaths.Lines:
      result = cloneDeep(EmptyLinesPageState);
      break;
    case PagePaths.Alarms:
      result = cloneDeep(EmptyAlarmsPageState);
      break;
    default:
      console.warn(`[pageModule] Cant get page module by path of "${pagePath}", use EmptyDefaultPageState`);
      result = cloneDeep(EmptyDefaultPageState);
      result.pagePath = pagePath;
      break;
  }

  return result;
}
