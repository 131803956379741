
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NForm, { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { NButton, NModalWindow } from '@/uikit';

@Options({
  name: 'CustomReportDialog',
  components: { NForm, NButton, NModalWindow },
  emits: ['done', 'close']
})
export default class CustomReportDialog extends Vue {
  @Prop({ type: [String] })
  readonly title?: string;

  @Prop({ type: Boolean, default: true })
  readonly insertIntoBody!: true;

  @Prop({ type: Object, required: true })
  readonly schema!: IFormLayoutItem[];

  @Prop({ type: Object, required: false })
  readonly defaults: Record<string, any> = {};

  model: any = {};

  created() {
    if (Object.keys(this.defaults).length) this.model = { ...this.defaults };
  }

  closeHandler() {
    this.$emit('close');
  }

  createHandler() {
    const isValid = this.$refs.form.validateAndDisplayErrors();
    if (isValid) this.$emit('done', this.model);
  }
}
