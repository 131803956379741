import { dataAssetsModule } from '@/store/application/data.assets.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { NInput, NInputNumber } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import {FormValidatorNames, IFormContext, IFormLayout, IFormModel} from '@/uikit/forms/NForm.vue';
import { getNumberValidator, getUrlValidator } from '@/uikit/forms/validators';
import NText from '@/uikit/text/NText.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';

export const formLayoutHeaders: IFormLayout = [
  [
    {
      classes: 'form-table-devices__header-item',
      component: NText,
      props: {
        i18n_modelValue: 'common.general_information'
      }
    },
    {
      classes: ' ',
      component: NCheckbox,
      props: {
        i18n_label: 'common.faces'
      },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        return this.model['faces'] = value;
      },
      decode: function (this: IFormContext) {
        return this.model?.['faces'];
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: ' ',
      component: NCheckbox,
      props: {
        i18n_label: 'common.bodies'
      },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        return this.model['bodies'] = value;
      },
      decode: function (this: IFormContext) {
        return this.model?.['bodies'];
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: ' ',
      component: NCheckbox,
      props: {
        i18n_label: 'common.cars'
      },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        return this.model['cars'] = value;
      },
      decode: function (this: IFormContext) {
        return this.model?.['cars'];
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    }
  ]
];
export const formLayoutContent: IFormLayout = [
  [
    {
      path: 'name',
      classes: 'form-table-devices__content-item',
      i18n_label: 'common.name',
      component: NInput,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      path: 'group',
      i18n_label: 'common.camera_group',
      classes: 'form-table-devices__content-item',
      component: CameraGroupName,
      validators: [{ name: FormValidatorNames.Required }]
    },
    {
      path: 'url',
      i18n_label: 'common.url',
      classes: 'n-form-w-4 form-table-devices__content-item',
      component: NInput,
      validators: [getUrlValidator(), { name: FormValidatorNames.Required }]
    },
    {
      path: 'latitude',
      classes: 'form-table-devices__content-item',
      i18n_label: 'common.latitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -90, max: 90 },
      validators: [getNumberValidator({ required: false, min: -90, max: 90, float: true })]
    },
    {
      path: 'longitude',
      classes: '   form-table-devices__content-item',
      i18n_label: 'common.longitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -180, max: 180 },
      validators: [getNumberValidator({ required: false, min: -180, max: 180, float: true })]
    },
    {
      path: 'azimuth',
      i18n_label: 'common.azimuth',
      classes: 'form-table-devices__content-item',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: 0, max: 359.9 },
      validators: [getNumberValidator({ required: false, min: 0, max: 359.9, float: true })]
    },
    {
      path: 'angle_of_view',
      i18n_label: 'ds.angle_of_view',
      classes: 'form-table-devices__content-item',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 1, min: 0, max: 359 },
      validators: [getNumberValidator({ required: false, min: 0, max: 359, float: true })]
    },
    {
      path: 'address',
      i18n_label: 'common.address',
      classes: 'form-table-devices__content-item',
      component: NInput
    },
    {
      classes: ' ',
      component: NCheckbox,
      encode: function (this: IFormContext) {
        cameraResetModule.toggleDetector(this.model, 'face');
      },
      decode: function (this: IFormContext, model) {
        return !!this.model.stream_settings.detectors?.face;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('face');
      }
    },
    {
      classes: ' ',
      component: NCheckbox,
      encode: function (this: any) {
        cameraResetModule.toggleDetector(this.model, 'body');
      },
      decode: function (this: any) {
        return !!this.model.stream_settings.detectors?.body;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('body');
      }
    },
    {
      classes: ' ',
      component: NCheckbox,
      encode: function (this: any) {
        cameraResetModule.toggleDetector(this.model, 'car');
      },
      decode: function (this: any) {
        return !!this.model.stream_settings.detectors?.car;
      },
      hidden: function () {
        return !Object.keys(dataAssetsModule.availableObjectsMap).includes('car');
      }
    }
  ]
];
