export default {
  matched_event: 'событие с совпадением',
  matched_episode: 'эпизод с совпадением',
  unmatched_event: 'событие без совпадения',
  unmatched_episode: 'эпизод без совпадения',
  all_events_acknowledged_confirm: 'Вы хотите принять все события?',
  all_episodes_acknowledged_confirm: 'Вы хотите принять все эпизоды?',
  angle_range_is_invalid: 'Укажите значение от -90° до 90°',
  map_tracks_button_description: 'Отобразить треки'
};
