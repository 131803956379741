
import { Options, Vue } from 'vue-class-component';
import {LicenseFeatureStatus, LicenseFeatureStatusItems, LicenseResourcesMap, Resource} from '@/pages/license_v1/types';
import { Prop } from 'vue-property-decorator';
import LicenseLabel from "@/pages/license_v1/components/common/LicenseLabel.vue";

@Options({
  components: {LicenseLabel}
})
export default class LicenseResourceTable extends Vue {
  @Prop({ required: true })
  itemsMap!: LicenseResourcesMap;

  @Prop({ required: false, default: () => true })
  showCurrent = true;

  getLabel(name: string) {
    return this.$t('license.resources.' + name);
  }

  getStatus(item: Resource): LicenseFeatureStatus {
    const usagePercent = (item.current || 0) / item.value;
    let result: LicenseFeatureStatus = LicenseFeatureStatusItems.Success;
    if (usagePercent > 0.9) {
      result = LicenseFeatureStatusItems.Error;
    } else if (usagePercent > 0.8) {
      result = LicenseFeatureStatusItems.Warning;
    }
    return result;
  }
}
