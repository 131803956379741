// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { viewModelRepository } from '@/api/common';

const defaultCamera = {
  name: 'Camera 1',
  group: 1,
  url: 'rtsp://123.123.123.123/camera',
  stream_settings: {
    detectors: {
      face: {
        filter_max_size: 8192,
        filter_min_quality: 0.5,
        filter_min_size: 60,
        fullframe_crop_rot: false,
        fullframe_use_png: false,
        jpeg_quality: 95,
        overall_only: false,
        post_best_track_frame: true,
        post_best_track_normalize: true,
        post_first_track_frame: false,
        post_last_track_frame: false,
        realtime_post_every_interval: false,
        realtime_post_first_immediately: false,
        realtime_post_interval: 1,
        roi: '',
        track_interpolate_bboxes: true,
        track_max_duration_frames: 0,
        track_miss_interval: 1,
        track_overlap_threshold: 0.25,
        track_send_history: false,
        tracker_type: 'simple_iou',
        track_deep_sort_matching_threshold: 0.65,
        track_deep_sort_filter_unconfirmed_tracks: true
      },
      body: {
        filter_max_size: 8192,
        filter_min_quality: 0.6,
        filter_min_size: 70,
        fullframe_crop_rot: false,
        fullframe_use_png: false,
        jpeg_quality: 95,
        overall_only: false,
        post_best_track_frame: true,
        post_best_track_normalize: true,
        post_first_track_frame: false,
        post_last_track_frame: false,
        realtime_post_every_interval: false,
        realtime_post_first_immediately: false,
        realtime_post_interval: 1,
        roi: '',
        track_interpolate_bboxes: true,
        track_max_duration_frames: 0,
        track_miss_interval: 1,
        track_overlap_threshold: 0.25,
        track_send_history: false,
        tracker_type: 'simple_iou',
        track_deep_sort_matching_threshold: 0.65,
        track_deep_sort_filter_unconfirmed_tracks: true
      }
    }
  }
};

async function createCamera(this: DevToolsScriptContext, name: string, url: string, options: any = {}) {
  const model = viewModelRepository.getCamerasItemViewModel();
  model.setItemsState({ ...defaultCamera, name, url, ...options });
  await model.save();
}

export { createCamera };
