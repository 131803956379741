import { ItemViewModel } from '@/definitions/view-models';
import { NAttachment } from '@/uikit/attachments';
import { getExternalImage } from '@/pages/search/helpers';

export class SearchAttachmentItemViewModel extends ItemViewModel<NAttachment> {
  async get(url: string): Promise<boolean> {
    this.loading = true;
    try {
      const externalImage = await getExternalImage(url);
      const result: NAttachment = {
        id: Math.random(),
        file: new File([externalImage], 'loaded-image.jpg', { type: 'image/jpeg' }),
        name: url,
        status: 'done',
        progress: 0,
        size: 0
      };
      this.setItemsState(result);
    } catch (e: unknown) {
      this.loadError = e;
    } finally {
      this.loading = false;
    }
    return true;
  }
}
