/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CasePhotosFilter = {
            /**
            * Select case photos related to these cases
            */
        case_in?: Array<number>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select case photos with provided ids
            */
        id_in?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Select case photos where the SQL condition `name ILIKE name_contains` is true
            */
        name_contains?: string;
            /**
            * Which field to use when ordering the results. Available fields: `id`, `created_date`, `name`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select case photos with these statuses
            */
        status?: Array<'completed' | 'downloaded' | 'failed'>;
}
export const EmptyCasePhotosFilter: CasePhotosFilter = {
        case_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        id_in: [],
        limit: '20',
        name_contains: undefined,
        ordering: '-id',
        page: undefined,
        status: [],
}
