import { DisplayType, DisplayTypes, PageType, PageTypes } from '@/store/application/page.definitions';
import { dataModule } from '@/store/data';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { OptionalIFormLayout } from '@/components/common/filter/filters/types';
import { configModule } from '@/store/config';

export function getDataSourceCamerasFilter(options: { small: boolean; pageType?: PageType, displayType?: DisplayType }): OptionalIFormLayout[] {
  const items: (OptionalIFormLayout | null)[] = [commonFilterSchemaModule.getNameContains(options)];

  switch (options.pageType) {
    case PageTypes.Videos:
      items.push(commonFilterSchemaModule.getCameraGroups(options));
      items.push(commonFilterSchemaModule.getCreatedDateTimeRange(options));
      break;
    case PageTypes.Cameras:
      items.push(commonFilterSchemaModule.getCameras({ ...options, path: 'id_in' }));
      items.push(commonFilterSchemaModule.getCameraGroups({ ...options, items: dataModule.cameraGroupsWithoutVideoGroups }));
      items.push(commonFilterSchemaModule.getActiveStatus(options), commonFilterSchemaModule.getCameraStatus(options));
      items.push(commonFilterSchemaModule.getCameraLocation(options));
      if (options.displayType === DisplayTypes.Map) items.push(commonFilterSchemaModule.getLimit(options, configModule.config.map?.maximum_objects_on_map ?? 200));
      break;
    case PageTypes.ExternalDetectors:
      items.push(commonFilterSchemaModule.getCameraGroups({ ...options, items: dataModule.cameraGroupsWithoutVideoGroups }));
      if (options.displayType === DisplayTypes.Map) items.push(commonFilterSchemaModule.getLimit(options, configModule.config.map?.maximum_objects_on_map ?? 200));
      break;
  }


  return items.filter((v) => !!v) as OptionalIFormLayout[];
}
