import { EpisodeTypesMap, ObjectsTypesMap } from '@/store/application/data.assets';
import { PageTypes } from '@/store/application/page.definitions';
import { getCounterRecordFilters } from '@/pages/counters/counterFiltersBuilder';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import { EditorSections } from '@/pages/webhooks/components/types';

export function webhooksFiltersEditorVisualBuilder(options = { small: false }) {
  return {
    [EditorSections.FaceEvents]: getEventFiltersBuilder(options, true).getFilterByType(PageTypes.Events, ObjectsTypesMap.Faces, ''),
    [EditorSections.BodyEvents]: getEventFiltersBuilder(options, true).getFilterByType(PageTypes.Events, ObjectsTypesMap.Bodies, ''),
    [EditorSections.CarEvents]: getEventFiltersBuilder({ ...options, cardType: 'cars' }, true).getFilterByType(PageTypes.Events, ObjectsTypesMap.Cars, ''),
    [EditorSections.HumanEpisodes]: getEventFiltersBuilder(options, true).getFilterByType(PageTypes.Episodes, '', EpisodeTypesMap.Humans),
    [EditorSections.CarEpisodes]: getEventFiltersBuilder({ ...options, cardType: 'cars' }, true).getFilterByType(PageTypes.Episodes, '', EpisodeTypesMap.Cars),
    [EditorSections.Counters]: getCounterRecordFilters({ small: options.small }, [], true)
  };
}
