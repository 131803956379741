import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';
import { createObjectsByJsonScheme } from '@/pages/devtools/DevToolsScriptJson';
import { createCameraGroup } from '@/pages/devtools/helpers/camera-groups';
import { createCamera } from '@/pages/devtools/helpers/cameras';
import { createHumanCard } from '@/pages/devtools/helpers/cards';
import { buildMethodForDelete, buildMethodForFindAll } from '@/pages/devtools/helpers/common';
import { createWatchList } from '@/pages/devtools/helpers/watch-lists';

export default class DevToolsScript {
  name = '';
  code = '';
  error = '';
  loading = false;
  status = 'No started';

  static parseAllScripts(source: string) {
    const scripts = [];

    try {
      JSON.parse(source);
      const script = new DevToolsScript();
      script.name = 'Json schema';
      script.code = source;
      scripts.push(script);
    } catch (e) {
      const regex = /\n\/\/ BEGIN (.+)\n([^]+?)\n\/\/ END/g;
      let matches = source.replaceAll('\r\n', '\n').matchAll(regex);
      for (const match of matches) {
        const script = new DevToolsScript();
        script.name = match[1];
        script.code = match[2];
        scripts.push(script);
      }
    }
    return scripts;
  }

  static getContext() {
    return {
      viewModelRepository,
      configModule,
      createObjectsByJsonScheme,

      findAllCameras: buildMethodForFindAll('Cameras'),
      findAllWatchLists: buildMethodForFindAll('WatchLists'),
      findAllCards: buildMethodForFindAll('CardsHumans'),
      findCameraGroup: buildMethodForFindAll('CameraGroups'),

      deleteCamera: buildMethodForDelete('Cameras'),
      deleteWatchList: buildMethodForDelete('WatchLists'),
      deleteCard: buildMethodForDelete('CardsHumans'),
      deleteCameraGroup: buildMethodForDelete('CameraGroups'),

      createCamera,
      createWatchList,
      createHumanCard,
      createCameraGroup
    };
  }

  runScript() {
    try {
      this.loading = true;
      this.status = 'Started';
      const context = { ...DevToolsScript.getContext() } as any;
      context.cbError = (e: Error) => {
        this.error = e.message;
      };
      context.cbFinally = () => {
        this.loading = false;
        this.status = 'Finished';
      };
      let tempCode = this.code;
      if (tempCode.trimStart().startsWith('{')) {
        tempCode = `const jsonScheme = ${tempCode}; await this.createObjectsByJsonScheme(jsonScheme);`;
      }
      const asyncCode = `(async () => { try { ${tempCode} } catch (e) { this.cbError(e); } finally { this.cbFinally(); } })();`;
      const f = new Function(asyncCode);
      f.call(context);
    } catch (e) {
      this.error = e.message;
    }
  }
}
