
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import AnnexAlarmTimer from '@/pages/annex/alarms/AnnexAlarmTimer.vue';
import { adaptItem } from '@/pages/annex/alarms/AnnexAlarmAdapter';
import NIcon from '@/uikit/icons/NIcon.vue';
import { timeOffsetCalculator } from '@/common/TimeOffsetCalculator';

@Options({
  name: 'AnnexAlarmAcknowledgeInfo',
  components: { NIcon, AnnexAlarmTimer }
})
export default class AnnexAlarmAcknowledgeInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: any;

  get adaptedItem() {
    return adaptItem(this.item);
  }

  get timeFrom() {
    const currentTime = timeOffsetCalculator.getServerTime() / 1000;
    const createdTime = new Date(this.item.declared_at).getTime() / 1000;
    const expiredTime = createdTime + Number(this.item.ack_interval);
    return Math.max(Math.round(expiredTime - currentTime), 0);
  }

  get acknowledgeMessage() {
    if (this.adaptedItem.acknowledge === 'in_work' || this.adaptedItem.acknowledge === 'cancel') {
      return this.$t('annex_alarms.ack_status.in_work') + this.item.ack_author;
    }
    if (this.adaptedItem.acknowledge === 'acknowledged') {
      return this.$t('annex_alarms.ack_status.acknowledged') + this.item.ack_author;
    }
    if (this.adaptedItem.acknowledge === 'auto_acknowledged') {
      return this.$t('annex_alarms.ack_status.auto_acknowledged');
    }
    return '';
  }

  timerFinished() {
    if (this.adaptedItem.acknowledge === 'none') {
      this.item.ack_value = 1;
    }
  }
}
