
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'CasesEmptyPage',
  components: { NButton }
})
export default class CasesEmptyPage extends Vue {
  @Prop({ type: String, required: true })
  readonly i18n_title!: string;

  @Prop({ type: String, required: true })
  readonly i18n_description!: string;

  @Prop({ type: String, required: true })
  readonly i18n_buttonText!: string;

  @Prop({ type: String, required: true })
  readonly imageType!: string;

  @Prop({ type: Boolean, default: false, required: false })
  readonly disabledAddButton!: boolean;
}
