
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import TypedTimer from 'typedtimer';

@Options({
  name: 'AnnexAlarmTimer'
})
export default class AnnexAlarmTimer extends Vue {
  @Prop({ type: String, required: true })
  readonly i18n_message!: string;

  @Prop({ type: Number, default: 0 })
  readonly from!: number;

  @Prop({ type: Number, default: 0 })
  readonly to!: number;

  @Prop({ type: Number, default: 1 })
  readonly step!: number;

  timer = new TypedTimer();
  value = 0;

  get formattedMessage() {
    return this.$tm(this.i18n_message, { count: this.value });
  }

  runTimer() {
    this.timer.clear();
    if (this.step == 0) {
      return;
    }
    this.value = this.from;
    this.timer.setInterval(() => {
      if ((this.step > 0 && this.value >= this.to) || (this.step < 0 && this.value <= this.to)) {
        this.timer.clear();
        this.$emit('finished');
        return;
      }
      this.value += this.step;
    }, 1000);
  }

  mounted() {
    this.runTimer();
  }

  beforeUnmount() {
    this.timer.clear();
  }
}
