
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';

@Options({
  name: 'VideoPlayerDialog',
  components: { NModalWindow, VideoPlayer }
})
export default class VideoPlayerDialog extends Vue {}
