
import { Options, Vue } from 'vue-class-component';
import { NButton, NDropdown, NDropdownItem } from '@/uikit';
import { Prop, Watch } from 'vue-property-decorator';
import { sortModule } from '@/store/data/SortModule';
import NCheckboxIcon from '@/uikit/checkbox/NCheckboxIcon.vue';

export type CommonItem = {
  i18n_label: string;
  value: string;
};

@Options({
  name: 'SortDropdown',
  components: { NDropdown, NButton, NDropdownItem, NCheckboxIcon }
})
export default class SortDropdown extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: Array<CommonItem>;

  @Prop({ type: String })
  readonly selected: string = '';

  @Prop({ type: String })
  readonly pinKey?: string;

  @Prop({ type: Boolean })
  readonly pin: boolean = false;

  @Prop({ type: String })
  modelValue = '';

  private pinnedValue?: string | null = null;

  @Watch('pinKey')
  pinKeyHandler() {
    this.pinnedValue = sortModule.get(this.pinKey);
  }

  created() {
    if (this.pin) sortModule.readyPromise.then(this.init);
  }

  init() {
    this.pinnedValue = sortModule.get(this.pinKey);
  }

  get button_i18n_label() {
    return this.selectedItem?.i18n_label || 'common.any';
  }

  get selectedItem() {
    return this.items.find(({ value }) => value === this.modelValue);
  }

  selectHandler(v: string) {
    this.$emit('update:modelValue', v);
    if (this.pin && this.pinKey && this.pinnedValue) sortModule.save(this.pinKey, v);
  }

  togglePin(value: boolean) {
    if (value) {
      sortModule.save(this.pinKey!, this.modelValue);
      this.pinnedValue = this.modelValue;
    } else {
      sortModule.delete(this.pinKey!);
      this.pinnedValue = undefined;
    }
  }
}
