
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { aclModule } from '@/store/acl';
import { PagePath, PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { multisidebarModule } from '@/store/multisidebar';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NTable from '@/uikit/table/NTable.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SettingsMenu from '@/components/common/settings/SettingsMenu.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import BlocklistForm from '@/pages/blocklist/forms/BlocklistForm.vue';
import ListPage from '@/pages/ListPage.vue';
import { sessionsFilters } from '@/pages/sessions/forms/small-filter';
import SessionsAndBlocklistTabs from '@/pages/sessions/tabs/SessionsAndBlocklistTabs.vue';
import { AclModelNames } from '@/pages/sessions/tabs/tabs-helper';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { columns } from './sessions-table-schema';

@Options({
  components: {
    BlocklistForm,
    FilterSection,
    InfiniteScroll,
    ListPage,
    NButton,
    NButtonGroup,
    NForm,
    NInput,
    NLoadingCircle,
    NTable,
    SessionsAndBlocklistTabs,
    SettingsBar,
    SettingsMenu,
    SettingsPageLayout
  }
})
export default class SessionsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  sortField = '';
  showModalWindow = false;
  selectedItemIds: number[] = [];

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get blocklistModule(): ListViewModel<any, any> {
    pageModule.getPageModule(this.blocklistState).aclModelName = AclModelNames.blocklist_records;
    return pageModule.getPageModule(this.blocklistState) as unknown as ListViewModel<any, any>;
  }

  get blocklistState(): PageState {
    const tab = 'blacklist';
    return pageModule.getPageStateByTab(PagePaths.BlockList, tab);
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.blocklistModule);
  }

  get state(): PageState {
    const tab = 'sessions';
    return pageModule.getPageStateByTab(PagePaths.Sessions, tab);
  }

  get items() {
    return this.module.items;
  }

  get columns() {
    return columns;
  }

  get selectedItems() {
    return this.module.items.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  activated() {
    this.sidebarModule.contentOverlap = true;
  }

  mounted() {
    this.sidebarModule.contentOverlap = true;
  }

  deactivated() {
    this.sidebarModule.contentOverlap = false;
  }

  beforeUnmount() {
    this.sidebarModule.contentOverlap = false;
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  get getSmallFilterLayout() {
    return sessionsFilters;
  }

  get blockAvailable() {
    return aclModule.getAccess('ffsecurity.add_deviceblacklistrecord');
  }

  get terminateAvailable() {
    return aclModule.getAccess('ffsecurity.delete_all_own_sessions');
  }

  handleModalWindowClose(actionComplete: boolean) {
    this.showModalWindow = false;
    actionComplete && this.navigateToBlocklistRecords();
  }

  handleTableSort(columnName: string) {
    this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    this.module.filter.current.ordering = this.sortField;
    this.module.get();
  }

  blockAll() {
    this.showModalWindow = true;
  }

  async terminateAll() {
    for (let id of this.selectedItemIds) {
      await this.module.delete(id);
    }
    await this.module.get();
  }

  private navigateToBlocklistRecords(): void {
    this.$router.push(
      RouterModule.getRouteLocation({
        path: PagePaths.BlockList,
        tab: this.$route.query.tab ?? ''
      })
    );
  }

  @Watch('module.filter.current', { deep: true })
  currentFilterHandler() {
    this.module.get();
  }

  @Watch('$route.path')
  changeRoutePath(v: PagePath) {
    if (v === PagePaths.Sessions) {
      this.module.get();
    }
  }
}
