import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "participant-role__readonly"
}
const _hoisted_2 = { class: "control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonSelect = _resolveComponent("NButtonSelect")!

  return (_ctx.readonly && _ctx.currentItem)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: "participant-role__color",
          style: _normalizeStyle({ backgroundColor: _ctx.currentItem.color })
        }, null, 4),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.currentItem.label), 1)
      ]))
    : (_openBlock(), _createBlock(_component_NButtonSelect, {
        key: 1,
        items: _ctx.items,
        "model-value": _ctx.modelValue,
        "onUpdate:modelValue": _ctx.handleUpdate,
        type: "secondary"
      }, null, 8, ["items", "model-value", "onUpdate:modelValue"]))
}