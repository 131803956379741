
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { NButtonGroupItem, NButtonGroupTypes, NButtonTypes } from '@/uikit/buttons/types';
import NSelect from '@/uikit/select/NSelect.vue';
import { ISelectItem, NSelectModelValue } from '@/uikit/select/types';

@Options({
  name: 'FilterTagsToSelect',
  components: { NButtonGroup, NSelect }
})
export default class FilterTagsToSelect extends Vue {
  @Prop({ type: String, default: NButtonGroupTypes.Default })
  readonly type!: string;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiple!: false;

  @Prop({ type: Boolean, default: false })
  readonly multiline!: false;

  @Prop({ type: Boolean, default: false })
  readonly showAnyButton!: false;

  @Prop({ type: [Object, Array, String], required: true })
  readonly modelValue!: string | number | string[] | number[] | NSelectModelValue;

  @Prop({ type: Array, default: () => [] })
  readonly items!: NButtonGroupItem[] | ISelectItem[];

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: Boolean, default: false })
  readonly clearIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly closeIcon!: boolean;

  @Prop({ type: String, default: 'value' })
  readonly keyField!: string;

  @Prop({ type: Function })
  readonly loadItems!: (value: any) => ISelectItem[] | Promise<ISelectItem[]>;

  @Prop({ type: Function })
  readonly searchItems!: (value: string) => ISelectItem[] | Promise<ISelectItem[]>;

  @Prop({ type: String })
  readonly placeholder?: string;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly selectonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly checkCurrent!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hideChevron!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly allowCreate!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly fillValueOnSelect!: boolean;

  get component() {
    return this.items.length > 10 ? NSelect : NButtonGroup;
  }

  get props() {
    return {
      ...this.$props,
      type: NButtonGroupTypes.Separated,
      buttonType: NButtonTypes.Outline,
      multiple: true,
      multiline: true,
      'show-any-button': true
    };
  }

  changeHandler(v: number) {
    this.$emit('update:modelValue', v);
  }
}
