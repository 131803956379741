import { CaseCluster } from '@/api';
import { render } from 'vue';
import ClusterWizard from '@/pages/cases/case-cluster-wizard/ClusterWizard.vue';
import { appEnvironment } from '@/store/application/app.environment';

type ResultFunction = (value: boolean) => void;

export class ClusterWizardController {
  protected getContainer(): HTMLElement {
    return document.body.appendChild(document.createElement('div'));
  }

  create(item: CaseCluster): Promise<boolean> {
    const result = new Promise<boolean>((resolve, reject) => {
      const container = this.getContainer();
      const component = this.getComponent(item, resultHandler);
      render(component, container);

      function resultHandler(value: boolean): void {
        resolve(value);
        render(null, document.body.removeChild(container));
      }
    });
    return result;
  }

  protected getComponent(item: CaseCluster, resultHandler: ResultFunction): JSX.Element {
    const props = {
      caseCluster: item,
      onClose: () => resultHandler(false)
    };
    const component = <ClusterWizard {...props} />;
    component.appContext = appEnvironment.context;
    return component;
  }
}

export const clusterWizardController = new ClusterWizardController();
