import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { ref: "anchor" }
const _hoisted_2 = { class: "multisidebar-group__counter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createElementVNode("div", {
      class: "multisidebar-group",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createVNode(_component_NButton, {
        class: "multisidebar-group__link",
        icon: _ctx.group.icon,
        type: "text",
        onAction: _ctx.scrollToAnchor
      }, null, 8, ["icon", "onAction"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NButton, {
          class: "multisidebar-group__counter-close",
          icon: "close",
          type: "secondary",
          onAction: _ctx.close
        }, null, 8, ["onAction"]),
        _createVNode(_component_NButton, {
          class: "multisidebar-group__counter-value label-mono-s",
          label: _ctx.itemsCount,
          type: _ctx.group.hasCurrent ? 'primary' : 'secondary',
          round: ""
        }, null, 8, ["label", "type"])
      ]),
      _createVNode(_component_NDropdown, { placement: "bottom-end" }, {
        toggle: _withCtx(({ toggle }) => [
          _createVNode(_component_NButton, {
            class: "multisidebar-group__actions",
            icon: "more-vertical",
            type: "text",
            disabled: _ctx.disabledActions,
            onAction: toggle
          }, null, 8, ["disabled", "onAction"])
        ]),
        body: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, ({ label, name, icon }) => {
            return (_openBlock(), _createBlock(_component_NDropdownItem, {
              key: name,
              icon: icon || 'empty',
              onClick: ($event: any) => (_ctx.$emit('action', name))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(label), 1)
              ]),
              _: 2
            }, 1032, ["icon", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ], 4)
  ], 64))
}