
import { watch, WatchStopHandle } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { eventModule, ModuleEvent, ModuleEventTypes } from '@/api/common/eventModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { CommonModel, MultisidebarItem as Item } from '@/store/multisidebar/types';
import { MultisidebarItemAttributes, MultisidebarItemConfig } from '@/store/multisidebar/types';
import { websocketModule } from '@/store/ws/websocket.module';
import NButton from '@/uikit/buttons/NButton.vue';
import { imageLoad } from '@/uikit/image-viewer/image-helpers';

const DefaultAttributes: MultisidebarItemAttributes = {
  thumbnailType: 'common'
};

@Options({
  name: 'MultisidebarItem',
  components: { NButton },
  emits: ['action']
})
export default class MultisidebarItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Item<CommonModel<any>>;

  @Prop({ type: Object, required: true })
  readonly config!: MultisidebarItemConfig<CommonModel<any>>;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  public attributes: MultisidebarItemAttributes = { ...DefaultAttributes };

  protected thumbnailSrc = '';
  protected loading = false;
  protected observer?: IntersectionObserver;
  protected unwatch?: WatchStopHandle;

  get hasChanges() {
    return this.item.model.hasChanges && !this.loading;
  }

  get firstLetter() {
    if (this.loading) return '';
    return this.attributes.label ? String(this.attributes.label).charAt(0) : 'N';
  }

  @Watch('hasChanges')
  loadAttributesOnChanges(v: boolean, p: boolean) {
    if (!v && p) this.loadAttributes();
  }

  @Watch('selected')
  scrollIntoView(v: true) {
    v && this.$refs.item?.scrollIntoView({ behavior: this.item.model.loaded ? 'smooth' : 'auto', block: 'center' });
  }

  @Watch('autoUpdateEventModule.current')
  currentEventHandler(e: ModuleEvent) {
    if (e.type === ModuleEventTypes.Update && this.item?.model?.name === e.modelName && this.item?.model?.item?.id === e.item?.id) {
      this.loadAttributes();
    }
  }

  get autoUpdateEventModule() {
    return eventModule;
  }

  async loadAttributes() {
    try {
      this.loading = true;
      Object.assign(this.attributes, await this.config.itemAttributesLoader(this.item));
      await this.initThumbnailSrc();
    } catch (e) {
      console.warn('[multisidebar]: loadAttributes failed');
    } finally {
      this.loading = false;
    }
  }

  async initThumbnailSrc() {
    if (this.attributes.thumbnailSrc) {
      let thumbnailSrc = '';
      switch (typeof this.attributes.thumbnailSrc) {
        case 'string':
          thumbnailSrc = this.attributes.thumbnailSrc;
          await imageLoad(thumbnailSrc);
          break;
        case 'function':
          thumbnailSrc = await this.attributes.thumbnailSrc();
          break;
        case 'undefined':
          break;
        default:
          throw new Error('Unknown thumbnailSrc type. Expect "string", "function" or "undefined", got: ' + typeof this.attributes.thumbnailSrc);
      }

      this.thumbnailSrc = thumbnailSrc;
    }
  }

  async lazyLoad(entries: IntersectionObserverEntry[]) {
    const visible = entries[0].isIntersecting;
    if (!visible) return;
    if (this.item.model.loaded) {
      this.observer?.disconnect();
      await this.loadAttributes();
    } else {
      const id = this.item.model.item.id;
      this.loading = true;
      try {
        await this.item.model.get(id);
        this.item.model.loaded = true;
      } catch (e) {
        this.loading = false;
      } finally {
        this.observer?.disconnect();
      }
      await this.loadAttributes();
    }
  }

  toggle() {
    actionHandler.run(ItemsActionNames.ToggleCurrent, this.item);
  }

  remove() {
    actionHandler.run(ItemsActionNames.Remove, this.item);
  }

  created() {
    if (this.config.websocketUpdateProperties?.length && this.config.websocketUpdateHandler) {
      this.config.websocketUpdateProperties.forEach((property) => {
        if (typeof websocketModule[property] !== 'undefined') {
          this.unwatch = watch(() => websocketModule[property], this.config.websocketUpdateHandler!.bind(this, this.item, property));
        }
      });
    }
  }

  mounted() {
    this.observer = new IntersectionObserver(this.lazyLoad.bind(this));
    this.observer?.observe(this.$refs.item);
  }

  beforeUnmount() {
    this.observer?.disconnect();
    this.unwatch?.();
  }
}
