import { Camera, CamerasService, DetectorParameters, InlineStreamSettings, VideoArchive } from '@/api';
import { EmptyInlineStreamSettings } from '@/api/models/InlineStreamSettings';
import { clone } from 'lodash';

export type CameraResetType = typeof CameraResetTypes[keyof typeof CameraResetTypes];
export type CameraDetectorType = 'face' | 'body' | 'car';

function isCamera(item: any): item is Camera {
  return item.face_threshold !== undefined || item.body_threshold !== undefined || item.car_threshold !== undefined;
}

export const CameraResetTypes = {
  Full: 'full',
  Advanced: 'advanced',
  Zone: 'zone',
  Map: 'map',
  Detectors: 'detectors',
  FaceDetector: 'face_detector',
  BodyDetector: 'body_detector',
  CarDetector: 'car_detector'
} as const;

const CameraMapFields = ['latitude', 'longitude', 'azimuth', 'angle_of_view', 'pitch'];
type DataSource = Camera | VideoArchive;

class CameraResetModule {
  private loaded = false;
  private defaultSettings: InlineStreamSettings = EmptyInlineStreamSettings;

  async getCameraSettings(): Promise<InlineStreamSettings> {
    if (!this.loaded) await this.loadDefaultSettings();
    return this.defaultSettings;
  }

  async getVideoSettings(): Promise<InlineStreamSettings> {
    const defaultParameters = await this.getCameraSettings();
    const parameters = clone(defaultParameters);
    parameters.stream_settings.disable_drops = true;
    return parameters;
  }

  public async reset(item: DataSource, type: CameraResetType = CameraResetTypes.Full) {
    if (!this.loaded) await this.loadDefaultSettings();
    if (type === CameraResetTypes.Full) {
      this.resetAdvanced(item);
      this.resetZone(item);
      this.resetDetectors(item);
      this.resetMap(item);
    } else {
      switch (type) {
        case CameraResetTypes.Advanced:
          this.resetAdvanced(item);
          break;
        case CameraResetTypes.Detectors:
          this.resetDetectors(item);
          break;
        case CameraResetTypes.Map:
          this.resetMap(item);
          break;
        case CameraResetTypes.Zone:
          this.resetZone(item);
          break;
        case CameraResetTypes.FaceDetector:
          this.resetDetector(item, 'face');
          break;
        case CameraResetTypes.BodyDetector:
          this.resetDetector(item, 'body');
          break;
        case CameraResetTypes.CarDetector:
          this.resetDetector(item, 'car');
          break;
        default:
          console.warn('[ds] CameraResetModule: unknown reset type.');
      }
    }
  }

  public async setDetector(item: DataSource, detectorType: CameraDetectorType, value: boolean) {
    if (!this.loaded) await this.loadDefaultSettings();
    if (item.stream_settings?.detectors) {
      if (!value) {
        item.stream_settings.detectors[detectorType] = null;
        if (isCamera(item)) this.resetDetectorThreshold(item, detectorType);
      } else {
        item.stream_settings.detectors[detectorType] = { ...this.defaultSettings.stream_settings.detectors?.[detectorType] };
      }
    }
  }


  public async toggleDetector(item: DataSource, detectorType: CameraDetectorType) {
    if (!this.loaded) await this.loadDefaultSettings();
    if (item.stream_settings?.detectors) {
      if (item.stream_settings.detectors[detectorType]) {
        item.stream_settings.detectors[detectorType] = null;
        if (isCamera(item)) this.resetDetectorThreshold(item, detectorType);
      } else {
        item.stream_settings.detectors[detectorType] = { ...this.defaultSettings.stream_settings.detectors?.[detectorType] };
      }
    }
  }

  private resetDetectors(item: DataSource) {
    if (item.stream_settings?.detectors) {
      Object.keys(item.stream_settings.detectors).forEach((detectorType) => {
        this.resetDetector(item, detectorType as CameraDetectorType);
      });
    }
  }

  private resetDetector(item: DataSource, detectorType: CameraDetectorType) {
    if (item.stream_settings?.detectors?.[detectorType]) {
      const defaultSettings = { ...this.defaultSettings.stream_settings.detectors![detectorType] } as DetectorParameters;
      defaultSettings.roi = item.stream_settings.detectors[detectorType]?.roi;
      item.stream_settings.detectors[detectorType] = defaultSettings;
    }
    if (isCamera(item)) this.resetDetectorThreshold(item, detectorType);
  }

  private resetDetectorThreshold(item: Camera, detectorType: CameraDetectorType) {
    const thresholdProp = `${detectorType}_threshold` as 'face_threshold' | 'body_threshold' | 'car_threshold';
    if (typeof item[thresholdProp] !== 'undefined') {
      item[thresholdProp] = null;
    }
  }

  private resetAdvanced(item: DataSource) {
    if (item.stream_settings) {
      const defaultStreamSettings = {
        ...this.defaultSettings.stream_settings,
        detectors: item.stream_settings.detectors,
        enable_liveness: item.stream_settings.enable_liveness,
        enable_recorder: item.stream_settings.enable_recorder,
        rot: item.stream_settings.rot
      };
      item.stream_settings = defaultStreamSettings;
    }
    if ('single_pass' in item) {
      item.single_pass = this.defaultSettings.single_pass;
    }
    if ('router_url' in item) {
      item.router_url = '';
    }
  }

  private resetZone(item: DataSource) {
    if (item.stream_settings?.detectors) {
      item.stream_settings.rot = '';
      for (let detectorType in item.stream_settings.detectors) {
        if (item.stream_settings.detectors[detectorType as CameraDetectorType]) {
          item.stream_settings.detectors[detectorType as CameraDetectorType]!.roi = '';
        }
      }
    }
  }

  private resetMap(item: DataSource) {
    Object.assign(item, Object.fromEntries(CameraMapFields.map((v) => [v, null])));
    if ((item as Camera).address) (item as Camera).address = '';
  }

  private async loadDefaultSettings() {
    this.defaultSettings = await CamerasService.camerasDefaultParametersRetrieve();
    this.loaded = true;
  }
}

export const cameraResetModule = new CameraResetModule();
