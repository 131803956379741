import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-or-unmatched__confidence"
}
const _hoisted_2 = {
  key: 1,
  class: "card-or-unmatched__unmatched heading-3xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_CardItemRow = _resolveComponent("CardItemRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-or-unmatched", { deleted: _ctx.deleted }])
  }, [
    (_ctx.item)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.confidence)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_Confidence, {
                  direction: "vertical",
                  value: _ctx.confidence
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_CardItemRow, {
            type: "humans",
            item: _ctx.item,
            confidence: _ctx.confidence,
            "model-acl": _ctx.modelAcl,
            "show-more": false,
            selected: !!_ctx.multisidebarModule.getItem(_ctx.pageSidebarType, _ctx.item.id),
            opened: _ctx.getIsCardOpened(_ctx.item),
            showConnections: _ctx.item.has_case_cluster && _ctx.isShowConnections,
            showInteractions: false,
            showAttachments: false,
            onSelect: _cache[0] || (_cache[0] = () => _ctx.multisidebarModule.addItemAndOpen(_ctx.pageSidebarType, _ctx.item)),
            closeable: false,
            onAction: _ctx.handleAction
          }, null, 8, ["item", "confidence", "model-acl", "selected", "opened", "showConnections", "onAction"])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('external_search.unmatched', 'u')), 1))
  ], 2))
}