
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import NButton from '@/uikit/buttons/NButton.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { hasParentElementDataName } from '@/uikit/utils';
import CardAttachments from '@/components/cards/CardAttachments.vue';
import CardDate from '@/components/cards/CardDate.vue';
import CardItem from '@/components/cards/CardItem';
import CardMetafields from '@/components/cards/CardMetafields.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';

@Options({
  name: 'CardItemSmall',
  components: { ActionsDropdown, CardAttachments, CardDate, CardMetafields, NButton, NThumbnail, WatchListsGroup }
})
export default class CardItemSmall extends CardItem {

  get formattedName() {
    return this.$filters.shortString(this.name, 125);
  }

  get formattedComment() {
    return this.$filters.shortString(this.comment, 1500);
  }

  get classes() {
    return {
      'card-item-small': true,
      'card-item-small__selected': this.selected,
      'card-item-small__opened': this.opened
    };
  }

  actionHandler(v: ItemsActionName) {
    actionHandler.run(v, { type: `cards_${this.type}`, rawItem: this.item });
  }

  clickHandler(e: Event) {
    let isActionClick = hasParentElementDataName(e.target as HTMLElement, 'actions');
    if (!isActionClick) this.$emit('select');
  }
}
