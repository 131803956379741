
import { defineComponent, PropType } from 'vue';
import { isDefined } from '@/common/utils';
import { ReportSizeInBytes } from '../../../types';
import { ReportsPageTableColumnLabelMono } from './common';
import { formatReportSize } from './utils';

export default defineComponent({
  name: 'ReportsPageTableColumnSize',
  components: { ReportsPageTableColumnLabelMono },
  props: {
    size: {
      default: null,
      type: Number as PropType<ReportSizeInBytes | null>
    }
  },
  computed: {
    formattedSize(): string {
      return isDefined(this.size) ? this.formatReportSize(this.size) : '-';
    }
  },
  methods: {
    formatReportSize(size: ReportSizeInBytes) {
      const result = formatReportSize(size);
      return `${result.size} ${this.$t(`reports.${result.measure}`, 'f')}`;
    }
  }
});
