
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NIcon from '@/uikit/icons/NIcon.vue';
import {configModule} from "@/store/config";

@Options({
  name: 'AlertDelivery',
  components: { NIcon }
})
export default class AlertDelivery extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: Record<string, boolean>;

  get isTelegramChannelEnabled() {
    return configModule.config.services?.alerts?.telegram;
  }
}
