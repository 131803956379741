export default {
  fallback_title: 'Извлеките дело из архива',
  fallback_description: 'Если вы хотите работать с кластерами, пожалуйста, извлеките дело из архива и зайдите сюда снова',
  clusters_in_current_case: 'Кластеры этого происшествия',
  clusters_in_other_cases: 'Кластеры других происшествий',
  participant_info: 'Сведения об участнике',
  participant: 'Участник',
  enter_comment: 'Введите комментарий',
  case_info: 'Сведения о происшествии',
  case_name: 'Название происшествия',
  date_incident: 'Дата происшествия',
  case_date: 'Дата КУСП',
  acknowledgment: 'Принять',
  create_first_case: 'Новая запись о происшествии',
  create_first_video: 'Добавить видео',
  create_first_photo: 'Добавить фото',
  case: 'запись о происшествии',
  new_case: 'новая запись о происшествии',
  new_case_description: 'Вы создаёте новую запись о происшествии. Укажите её название, номер и дату КУСП (при наличии), а также фактическую дату происшествия',
  new_video: 'новое видео',
  new_video_description: 'Пожалуйста, загрузите первое видео',
  new_photo: 'новая фотография',
  new_photo_description: 'Пожалуйста, загрузите первое фото. Поддерживаемые форматы: JPG, PNG, WEBP, HEIC',
  incident_date: 'Дата происшествия',
  record_id: 'Номер КУСП',
  record_id_short: 'КУСП',
  record_date: 'Дата КУСП',
  set_role_permissions: 'Установить права доступа',
  filter_all: 'все',
  filter_open: 'открытые',
  filter_archived: 'архивные',
  status_open: 'открыто',
  status_archived: 'в архиве',
  status_archiving: 'архивация',
  status_dearchiving: 'разархивация',
  status_deleting: 'удаление',
  status_error: 'ошибка',
  name: 'название',
  type: 'Тип',
  connections: 'связи',
  information: 'сведения',
  cluster: 'кластер',
  witness: 'Свидетель',
  victim: 'Потерпевший',
  suspect: 'Подозреваемый',
  irrelevant: 'Нерелевантный',
  comment: 'комментарий',
  related_cards: 'Соответствующие записи картотеки',
  related_participants: 'Соответствующие участники',
  clusters: 'Кластеры',
  participants: 'Участники',
  participants_and_clusters: 'Участники и кластеры',
  participants_in_current_case: 'Участники этого происшествия',
  participants_in_other_cases: 'Участники других происшествий',
  card_index: 'Картотека',
  wizard_help_text:
    'Вы анализируете изображение человека, автоматически распознанного при обработке видеоданных с места происшествия. Если данное лицо релевантно исследуемому происшествию, пометьте его как участника. Если вы уверены, что данное лицо совпадает с другими уникальными людьми в этом происшествии, или с записями картотеки, или с участниками других происшествий, установите соответствующую связь при помощи кнопки «Добавить»',
  create_new_participant: 'Отметить как участника',
  add: 'Добавить',
  save_and_done: 'Сохранить и закрыть',
  close: 'Закрыть',
  save_participant: 'Сохранить',
  save_as_participant: 'Сохранить участника',
  case_id: 'ID происшествия',
  enter_case_id: 'Введите ID происшествия',
  participant_name: 'Имя участника',
  enter_participant_name: 'Введите имя участника',
  role: 'Роль',
  date_time_participant: 'Дата и время создания участника',
  upload: 'Загрузить',
  add_and_process: 'Добавить и обработать',
  photo_status__downloaded: 'Загружено',
  photo_status__completed: 'Выполнено',
  photo_status__failed: 'Ошибка',
  select_photos: 'Выберите фотографии',
  reset_participant_confirm: 'Вы действительно хотите сбросить участника?'
};
