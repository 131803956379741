/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RemoteMonitoringEvent = {
    readonly id: number;
    /**
     * External face ID
     */
    readonly external_face_id: string;
    /**
     * Remote monitoring event face object
     */
    readonly face_object_id: string;
    /**
     * Remote monitoring event puppet ID
     */
    readonly puppet_id: string;
    /**
     * Remote monitoring event card
     */
    readonly card: number;
    /**
     * Remote monitoring event camera
     */
    readonly camera: string;
    /**
     * Remote monitoring event matched threshold
     */
    readonly confidence: number;
    /**
     * Remote monitoring event bbox
     */
    readonly bbox: Record<string, any>;
    /**
     * Remote monitoring event labels
     */
    readonly labels: Record<string, any>;
    /**
     * Remote monitoring event timestamp
     */
    readonly external_timestamp: string;
    /**
     * Remote monitoring event created date
     */
    readonly created_date: string;
    /**
     * Remote monitoring event fullframe image
     */
    readonly fullframe: string;
    /**
     * Remote monitoring event thumbnail image
     */
    readonly thumbnail: string;
    /**
     * Is event acknowledged or not
     */
    readonly acknowledged: string;

    name?: string;
    address?: string;
    latitude?: number;
    longitude?: number;
    azimuth?: number;
};
export const EmptyRemoteMonitoringEvent: RemoteMonitoringEvent = {
    id: 0,
    /**
     * External face ID
     */
    external_face_id: '',
    /**
     * Remote monitoring event face object
     */
    face_object_id: '',
    /**
     * Remote monitoring event puppet ID
     */
    puppet_id: '',
    /**
     * Remote monitoring event card
     */
    card: 0,
    /**
     * Remote monitoring event camera
     */
    camera: '',
    /**
     * Remote monitoring event matched threshold
     */
    confidence: 0,
    /**
     * Remote monitoring event bbox
     */
    bbox: {},
    /**
     * Remote monitoring event labels
     */
    labels: {},
    /**
     * Remote monitoring event timestamp
     */
    external_timestamp: '',
    /**
     * Remote monitoring event created date
     */
    created_date: '',
    /**
     * Remote monitoring event fullframe image
     */
    fullframe: '',
    /**
     * Remote monitoring event thumbnail image
     */
    thumbnail: '',
    /**
     * Is event acknowledged or not
     */
    acknowledged: '',

    name: '',
    address: '',
    longitude: undefined,
    latitude: undefined,
    azimuth: undefined
};
