/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CaseClusterLooksLike } from './CaseClusterLooksLike';
import { BestEvent, MatchedCard, RelatedItem } from '@/components/case_clusters/types';

export type CaseCluster = {
    readonly id: number;
    /**
     * Case cluster creation date
     */
    readonly created_date: string;
    /**
     * Case cluster's video archives list
     */
    readonly video_archives: Array<number>;
    /**
     * Case events count
     */
    readonly events_count: number;
    /**
     * Average case events quality
     */
    readonly events_quality: number;
    /**
     * Best case event
     */
    readonly best_event: BestEvent | Record<string, any>;
    /**
     * Best case event quality
     */
    readonly best_event_quality: number;
    readonly looks_like: CaseClusterLooksLike | null;
    readonly looks_like_confidence: number;
    /**
     * Related case
     */
    readonly case: number;
    /**
     * Enabled event features
     */
    readonly features: Record<string, any>;
    /**
     * Case participant name, up to 256 characters
     */
    name?: string | null;
    /**
     * Comment, up to 2048 characters
     */
    comment?: string | null;
    role?: string | null;
    related_human_cards?: Array<RelatedItem>;
    related_case_clusters?: Array<RelatedItem>;
    case_photos: number[],
    matched_card?: MatchedCard,
    is_case_participant: boolean
};
export const EmptyCaseCluster: CaseCluster = {
    id: 0,
        /**
        * Case cluster creation date
        */
    created_date: '',
        /**
        * Case cluster's video archives list
        */
    video_archives: [],
        /**
        * Case events count
        */
    events_count: 0,
        /**
        * Average case events quality
        */
    events_quality: 0,
        /**
        * Best case event
        */
    best_event: {},
        /**
        * Best case event quality
        */
    best_event_quality: 0,
    looks_like: null,
    looks_like_confidence: 0,
        /**
        * Related case
        */
    case: 0,
        /**
        * Enabled event features
        */
    features: {},
        /**
        * Case participant name, up to 256 characters
        */
    name: null,
        /**
        * Comment, up to 2048 characters
        */
    comment: null,
    role: null,
    related_human_cards: [],
    related_case_clusters: [],
    case_photos: [],
    is_case_participant: false
};
