import { PageNames } from '@/store/application/page.definitions';

export const productDefaultTitles: Record<string, string> = {
  multi: 'FindFace Multi 2.2.0',
  cibr: 'FindFace CIBR'
};

export const productConfigs: Record<string, any /* circular dep IConfig */> = {
  multi: {},
  cibr: {
    menu: {
      disabled_items: [
        PageNames.Events,
        PageNames.DataSources,
        PageNames.VideoWall,
        PageNames.Clusters,
        PageNames.Lines,
        PageNames.Counters,
        PageNames.Interactions,
        PageNames.CameraGroups,
        PageNames.Analytics,
        PageNames.BIApp,
        PageNames.AlarmApp,
        PageNames.ExternalVms,
        PageNames.Webhooks
      ]
    }
  }
};
