
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Filter } from '@/definitions/view-models/filter';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import { FilterValue } from '@/components/common/filter/filter-manager';
import { smallFilterManagerModule } from '@/components/common/filter/filter-manager/SmallFiltersModule';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import FilterManager from '@/components/common/filter/manager/FilterManager.vue';

@Options({
  components: { FilterManager, FiltersBig, FiltersSmall, NButton }
})
export default class FilterSection extends Vue {
  @Prop({ type: Object, required: true })
  readonly filter!: Filter<any>;

  @Prop({ type: Array, required: true })
  readonly schemeSmall!: any;

  @Prop({ type: Array })
  readonly schemeLarge?: any;

  @Prop({ type: Boolean, default: false })
  readonly hasReport!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasFilterManager!: boolean;

  @Prop({ type: Boolean, default: false })
  private hasPinnedFields!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly supportSearch!: boolean;

  @Prop({ type: [Object, Array] })
  readonly reportFilter: any;

  isFilterVisible = false;

  get hasFilterChanges() {
    return this.filter.hasChanges;
  }

  get enabledFields() {
    if (this.hasPinnedFields) {
      return smallFilterManagerModule.items[this.filter.storageKey];
    }
    return undefined;
  }

  setEnabledFields(v: string[]) {
    smallFilterManagerModule.setFiltersBySource(this.filter.storageKey, v, true);
  }

  selectFilter(v: FilterValue) {
    this.filter.setCurrentByString(v?.value ?? '');
  }

  resetFilters() {
    this.filter.reset();
  }

  showReportDialog() {
    const multiSidebarType = this.filter.storageKey as MultisidebarItemType;
    this.$reportsForm.show(multiSidebarType, this.reportFilter || this.filter.current);
  }
}
