
import { capitalize } from 'lodash';
import { computed, defineComponent } from 'vue';
import NButton from '../buttons/NButton.vue';
import dayjs from '../datetime/dayjs';
import NDropdown from '../dropdown/NDropdown.vue';
import NDropdownItem from '../dropdown/NDropdownItem.vue';

export default defineComponent({
  components: { NButton, NDropdown, NDropdownItem },
  props: {
    activeMonthNumber: {
      type: Number,
      default: 0
    }
  },
  emits: {
    change(v: number) {
      return v >= 0 || v <= 11;
    }
  },
  setup(props, { emit }) {
    const localeData = dayjs.localeData();
    const months = computed(() => localeData.months().map(capitalize));
    const activeMonth = computed(() => months.value[props.activeMonthNumber]);

    const selectHandler = (month: number) => emit('change', month);

    return {
      activeMonth,
      selectHandler,
      months
    };
  }
});
