import { EpisodeTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageTypes } from '@/store/application/page.definitions';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { filterRecursive } from '@/uikit/helpers';
import { attributeFilterSchemaModule } from '@/components/common/filter/filters/AttributeFilterSchemaModule';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { OptionalIFormLayout, SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { configModule } from '@/store/config';

function getDividerWebhookAdapter(isWebhook = false) {
  const classes = isWebhook ? 'label-m' : 'heading-xl';
  return (options: SimpleFilterOptions) => ({ ...options, classes: `${options.classes} ${classes}` });
}

export function getEventFiltersBuilder(options: { small: boolean; cardType?: string }, isWebhook = false) {
  const getDividerOptions = getDividerWebhookAdapter(isWebhook); // TODO: ???
  const availableObjects = dataAssetsModule.availableObjectsTypedMap;
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getMatchedLists(options),
    isWebhook ? undefined : commonFilterSchemaModule.getAcknowledged({ ...options }),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    isWebhook ? undefined : commonFilterSchemaModule.getDateTimeRange(options),
    isWebhook ? undefined : commonFilterSchemaModule.getVideoArchiveId(options),
    commonFilterSchemaModule.getMatchedCardSelect({ ...options })
  ];

  const lines: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getLineId(options),
    commonFilterSchemaModule.getLineCrossDirection(options),
    isWebhook ? commonFilterSchemaModule.getBackwardLineCrossingWebhooks(options) : commonFilterSchemaModule.getBackwardLineCrossing(options)
  ];

  const faceFeatures: OptionalIFormLayout[] = availableObjects.face ? attributeFilterSchemaModule.getFaceAttributes(options, isWebhook) : [];
  const bodyFeatures: OptionalIFormLayout[] = availableObjects.body ? attributeFilterSchemaModule.getBodyAttributes(options, true, isWebhook) : [];
  const carFeatures: OptionalIFormLayout[] = availableObjects.car ? attributeFilterSchemaModule.getCarAttributes(options, isWebhook) : [];

  let faceHeadposeFeatures: OptionalIFormLayout[] = availableObjects.face
    ? [attributeFilterSchemaModule.getFaceHeadposeYaw(options), attributeFilterSchemaModule.getFaceHeadposePitch(options)]
    : [];

  faceHeadposeFeatures = faceHeadposeFeatures.filter((v) =>
    attributeFilterSchemaModule.isFeaturePresentInConfigByFormItem(v as IFormLayoutItem | undefined, attributeFilterSchemaModule.faceFeatures)
  );

  const commonEvents: OptionalIFormLayout[] = [
    isWebhook ? commonFilterSchemaModule.getDirectMatchWithConfidence({ ...options }) : commonFilterSchemaModule.getMatches({ ...options }),
    ...commonAll,
    isWebhook ? undefined : commonFilterSchemaModule.getEpisodeId(options),
    isWebhook ? undefined : commonFilterSchemaModule.getEventId(options),
    commonFilterSchemaModule.getBsType({ ...options, multiple: isWebhook }),
    ...lines
  ];

  function getFilterByType(pageType: string, objectType: string, episodeType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [];
    if (pageType === PageTypes.Events) {
      let carOrientation;
      /*
      [carOrientation] = filterRecursive([carOrientation], (v: any) =>
        attributeFilterSchemaModule.isFeaturePresentInConfigByFormItem(v, attributeFilterSchemaModule.carFeatures)
      );
       */

      switch (objectType) {
        case 'faces':
          filterSchema = [...commonEvents, commonFilterSchemaModule.getAttributesDivider(getDividerOptions(options)), ...faceFeatures, ...faceHeadposeFeatures];
          break;
        case 'bodies':
          filterSchema = [...commonEvents, commonFilterSchemaModule.getAttributesDivider(getDividerOptions(options)), ...bodyFeatures];
          break;
        case 'cars':
          carOrientation = isWebhook
            ? attributeFilterSchemaModule.getCarOrientationWithConfidence(options)
            : attributeFilterSchemaModule.getCarOrientation(options);
          filterSchema = [...commonEvents, commonFilterSchemaModule.getAttributesDivider(getDividerOptions(options)), ...carFeatures, carOrientation];
          break;
      }
    }
    if (pageType === PageTypes.Episodes) {
      const bodiesEnabled = configModule.getBoolean('objects.bodies.enabled', false);
      switch (episodeType) {
        case EpisodeTypesMap.Humans:
          filterSchema = [
            isWebhook ? undefined : commonFilterSchemaModule.getFaceBodyFilter(options),
            isWebhook ? commonFilterSchemaModule.getEpisodeAllowedTypes(options) : undefined,
            isWebhook ? commonFilterSchemaModule.getFaceDirectMatch({ ...options }) : commonFilterSchemaModule.getFaceMatches({ ...options }),
            bodiesEnabled
              ? isWebhook
                ? commonFilterSchemaModule.getBodyDirectMatch({ ...options })
                : commonFilterSchemaModule.getBodyMatches({ ...options })
              : undefined,
            ...commonAll,
            isWebhook ? undefined : commonFilterSchemaModule.getEpisodeIdInEpisodes(options),
            commonFilterSchemaModule.getEventsCount(options)
          ];

          if (!isWebhook) {
            filterSchema = filterSchema.concat([
              commonFilterSchemaModule.getFaceAttributesDivider(getDividerOptions(options)),
              ...faceFeatures,
              commonFilterSchemaModule.getBodyAttributesDivider(getDividerOptions(options)),
              ...bodyFeatures
            ]);
          }
          break;
        case EpisodeTypesMap.Cars:
          filterSchema = [
            isWebhook ? commonFilterSchemaModule.getEpisodeAllowedTypes(options) : undefined,
            isWebhook ? commonFilterSchemaModule.getCarDirectMatch({ ...options }) : commonFilterSchemaModule.getCarMatches({ ...options }),
            ...commonAll,
            // commonFilterSchemaModule.getEpisodeIdInEpisodes(options),
            commonFilterSchemaModule.getEventsCount(options)
          ];

          if (!isWebhook) {
            filterSchema = filterSchema.concat([commonFilterSchemaModule.getCarAttributesDivider(getDividerOptions(options)), ...carFeatures]);
          }
          break;
      }
    }

    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}
