
import { merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { Camera, CamerasService } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ModelAclResult } from '@/store/acl/types';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { websocketModule } from '@/store/ws/websocket.module';
import Map from '@/components/map/Map.vue';
import CameraMarker from '@/components/map/CameraMarker.vue';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { multisidebarModule } from '@/store/multisidebar';
import { actionHandler, convertToMsbItem } from '@/store/data/ActionHandler';
import { isDefined } from '@/uikit/utils';
import NButton from '@/uikit/buttons/NButton.vue';
import MapProviderSelect from '@/components/map/MapProviderSelect.vue';
import { NTableBodyCell, NTableColumn } from '@/uikit/table-v2';
import { dataModule } from '@/store/data';
import { actionsModule } from '@/store/data/ActionsModule';
import NTable from '@/uikit/table-v2/NTable.vue';
import NText from '@/uikit/text/NText.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import { CameraStatus } from '@/pages/data-sources/forms/components';
import { Action } from '@/uikit';
import { createTableSectionSchemaDecorator } from '@/uikit/table-v2/utils';
import Supercluster, { ClusterProperties } from 'supercluster';
import { LeafletMouseEvent, Map as TMap } from 'leaflet';
import * as GeoJSON from 'geojson';
import { AreaSelectActionPayload, LatLngType } from '@/components/map/types';
import CameraClusterMarker from '@/components/map/CameraClusterMarker.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import DataSourcesCamerasGrid from '@/pages/data-sources/components/DataSourcesCamerasGrid.vue';
import ClickOutside from '@/uikit/directives/click-outside';
import MapAreaSelect from '@/components/map/MapAreaSelect.vue';
import { isPointInPolygon } from '@/components/map/helpers';
import MapSearch from '@/components/map/MapSearch.vue';
import MapZones, { MapView } from '@/components/map/MapZones.vue';
import CameraPopup from '@/components/map/CameraPopup.vue';
import { dialogController } from '@/common/DialogController';
import { RouterModule } from '@/store/router';
import { ObjectsTypesMap } from '@/store/application/data.assets';
import {PageType, PageTypes} from "@/store/application/page.definitions";

type CameraPropId = { camera_id: number };
interface ClusterItem extends ClusterProperties {
  latLng: LatLngType;
}
type ClusterRecord = { id: string; is_cluster: boolean; payload: Camera | ClusterItem };
type PopupState = {
  container: null | HTMLElement;
  visible: boolean;
  reference: null | HTMLDivElement;
  items: Camera[];
};

const ClustersMaxZoom = 18;
const DefaultLimit = '20';
const TopLimit = '200';

function getDefaultPopupState(): PopupState {
  return {
    container: null,
    visible: false,
    reference: null,
    items: []
  };
}

function getCameraClusterId(id: number) {
  return `cluster--camera--${id}`;
}

function getClusterId(id: number) {
  return `cluster--${id}`;
}

@Options({
  name: 'DataSourcesCamerasMap',
  components: {
    MapZones,
    MapSearch,
    MapAreaSelect,
    DataSourcesCamerasGrid,
    NTooltip,
    CameraClusterMarker,
    MapProviderSelect,
    NButton,
    CameraMarker,
    Map,
    NTable
  },
  emits: ['select', 'setFilter', 'setMapView', 'updateMapRect'],
  directives: { ClickOutside }
})
export default class DataSourcesCamerasMap extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: Camera[];

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: number[];

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Object })
  readonly mapView?: { center: LatLngType; zoom: number };

  @Prop({ type: String })
  readonly pageType!: PageType;

  map: TMap | null = null;
  clusters: Array<Supercluster.PointFeature<CameraPropId> | Supercluster.ClusterFeature<CameraPropId>> = [];
  cameraConesOn = true;
  leftBarOn = true;
  providerId = 'default';
  lastCameraIdClickedInBar: number | null = null;
  areaDrawOn: boolean = false;

  popupState: PopupState = getDefaultPopupState();
  currentMapView: any = {};

  mounted() {
    this.setTopLimit();
  }

  beforeUnmount() {
    this.map?.off('moveend', this.updateClusters.bind(this));
    this.map?.off('movestart', this.resetPopup.bind(this));
    this.setDefaultLimit();
  }

  @Watch('websocketModule.cameraUpdated')
  updateCamera(updatedItem: Camera) {
    const item = this.availableItems.find((v) => v.id === updatedItem.id);
    if (item) {
      merge(item, updatedItem);
      autoUpdateHelper.updateHandler('/cameras/', item);
    }
  }

  @Watch('availableItems')
  updateClustersOnItemsChange() {
    this.updateClusters();
  }

  get sidebarHeaderText() {
    const token = this.pageType === PageTypes.ExternalDetectors ? 'common.external_detectors' : 'common.cameras';
    return this.$t(token, 'f');
  }

  get areaActions() {
    return [
      { name: 'open', i18n_label: 'ds.open_all', icon: 'link-circle' },
      { name: 'filter', i18n_label: 'ds.add_to_filter', icon: 'filter' },
      { name: 'filterEvents', i18n_label: 'ds.filter_events', icon: 'filter' }
    ];
  }

  get clusterIndex() {
    const clusterOptions = { radius: 60, maxZoom: ClustersMaxZoom };
    const index = new Supercluster<CameraPropId, CameraPropId>(clusterOptions);
    const clusterPoints = this.availableItems.map((v) => ({
      type: 'Feature' as const,
      geometry: {
        type: 'Point' as const,
        coordinates: [v.longitude, v.latitude]
      } as GeoJSON.Point,
      properties: { camera_id: v.id }
    }));
    index.load(clusterPoints);
    return index;
  }

  get camerasMap() {
    return Object.fromEntries(this.availableItems.map((v) => [v.id, v]));
  }

  get clusterItems(): ClusterRecord[] {
    return this.clusters.map((v) => {
      if (v.properties.camera_id) {
        return { id: getCameraClusterId(v.properties.camera_id), is_cluster: false, payload: this.camerasMap[v.properties.camera_id] };
      }

      return {
        id: getClusterId((v.properties as ClusterProperties).cluster_id),
        is_cluster: true,
        payload: {
          ...(v.properties as ClusterProperties),
          latLng: {
            lng: v.geometry.coordinates[0],
            lat: v.geometry.coordinates[1]
          }
        }
      };
    });
  }

  get availableItems() {
    return this.items.filter((v) => isDefined(v.latitude) && isDefined(v.longitude));
  }

  get websocketModule() {
    return websocketModule;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get bodyDecorators() {
    const getCustomClasses = ({ model }: NTableBodyCell<Camera>) => {
      return { 'datasource-cameras-map__table-cell_selected': this.getIsCameraOpened(model) };
    };
    return [createTableSectionSchemaDecorator({ class: getCustomClasses })];
  }

  get columns(): NTableColumn<Camera>[] {
    return [
      {
        width: 120,
        body: ({ model }) => {
          const props = { width: 100, displayWidth: '100px', modelValue: model.screenshot, hasPlay: true };
          return <CameraScreenshot {...props} />;
        }
      },
      {
        width: 'auto',
        body: ({ model }) => {
          const nameProps = {
            modelValue: model.name,
            class: 'datasource-cameras-map__description-name',
            onClick: this.handleTableCameraClick.bind(this, model)
          };
          const groupProps = { modelValue: dataModule.cameraGroupsModule.items.find((v) => v.id === model.group)?.name || '' };
          return (
            <div class="datasource-cameras-map__description">
              { this.pageType === PageTypes.Cameras ? <CameraStatus camera={model} showStatusLabel={false} /> : null }
              <NText {...nameProps} />
              <NText {...groupProps} />
            </div>
          );
        }
      },
      {
        width: 'auto',
        body: ({ model }) => {
          const dropdownProps = {
            actions: this.getActions(model),
            placement: 'left-start',
            onAction: (action: ItemsActionName) => this.actionHandler(action, model)
          };
          return (
            <div class="datasource-cameras-map__actions-cell">
              <ActionsDropdown class="datasource-cameras-map__actions" {...dropdownProps} />
            </div>
          );
        }
      }
    ];
  }

  handleAreaAction({ name, coordinates }: AreaSelectActionPayload) {
    const affectedCameras = this.availableItems.filter((v) => isPointInPolygon([v.latitude as number, v.longitude as number], coordinates));
    switch (name) {
      case 'open':
        affectedCameras.map((v) => actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: v }));
        break;
      case 'filter':
        this.$emit('setFilter', { id_in: affectedCameras.map((v) => v.id) });
        break;
      case 'filterEvents':
        RouterModule.navigateToEvents(ObjectsTypesMap.Faces, { cameras: affectedCameras.map((v) => v.id) });
        break;
    }
  }

  updateClusters() {
    if (!this.map) return;
    const bounds = this.map.getBounds();
    const bbox: GeoJSON.BBox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    const zoom = this.map.getZoom();
    this.clusters = this.clusterIndex.getClusters(bbox, zoom);
  }

  resetPopup() {
    this.popupState = getDefaultPopupState();
  }

  updateView() {
    if (this.map) {
      this.currentMapView = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom()
      };
      this.$emit('setMapView', this.currentMapView);
    }
  }

  updateMapRect() {
    if (!this.map) return;
    const bounds = this.map.getBounds();
    this.$emit('updateMapRect', {
      latitude_gte: bounds.getSouth(),
      latitude_lte: bounds.getNorth(),
      longitude_gte: bounds.getWest(),
      longitude_lte: bounds.getEast()
    });
  }

  handleMapReady(map: TMap) {
    map.on('moveend', this.updateClusters.bind(this));
    map.on('moveend', this.updateView.bind(this));
    map.on('dragend', this.updateView.bind(this));
    map.on('movestart', this.resetPopup.bind(this));
    if (this.mapView?.center) {
      map.setView(this.mapView.center, this.mapView.zoom);
    }
    this.map = map;
    this.updateView();
  }

  handleSearchSelect(result?: { lat: number; lng: number }) {
    if (result) {
      this.map?.setView(result);
      this.updateView();
    }
  }

  handleViewChanged(data: MapView) {
    if (this.map && data) {
      this.map.setView(data.center, data.zoom);
    }
  }

  handleCameraClick(model: Camera) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: model });
  }

  handleTableCameraClick(model: Camera) {
    if (this.lastCameraIdClickedInBar === model.id) {
      actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: model });
    }
    this.lastCameraIdClickedInBar = model.id;
    this.map?.setView({ lat: model.latitude as number, lng: model.longitude as number });

    const cameraClusterId = getCameraClusterId(model.id);
    const hasBareCamera = !!this.clusterItems.find((clusterItem) => clusterItem.id === cameraClusterId);
    if (!hasBareCamera) {
      this.map?.setView({ lat: model.latitude as number, lng: model.longitude as number }, ClustersMaxZoom);
    }
  }

  handleClusterClick(event: LeafletMouseEvent, item: ClusterRecord) {
    const { cluster_id, point_count } = item.payload as ClusterProperties;
    const clusterPoints = this.clusterIndex.getLeaves(cluster_id, point_count);

    this.popupState.container = this.map?.getContainer() ?? null;
    this.popupState.items = clusterPoints.map((v) => this.camerasMap[v.properties.camera_id]);
    this.popupState.reference = event.originalEvent.target as HTMLDivElement;
    this.popupState.visible = true;
  }

  getActions(item: Camera): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasActive: true,
        currentActive: item.active,
        hasRestart: true,
        hasDelete: true,
        hasCameraReset: true
      })
      .map(actionsModule.computeActionByName);
  }

  setTopLimit() {
    this.$emit('set-filter', { limit: TopLimit });
  }

  setDefaultLimit() {
    this.$emit('set-filter', { limit: DefaultLimit });
  }

  getIsCameraOpened(camera: Camera) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(MultisidebarItemTypes.Cameras, camera.id);
  }

  async actionHandler(v: ItemsActionName, item: Camera) {
    const requiredSaveActionItems: ItemsActionName[] = [
      ItemsActionNames.CameraResetAll,
      ItemsActionNames.CameraResetFull,
      ItemsActionNames.CameraResetAdvanced,
      ItemsActionNames.CameraResetMap,
      ItemsActionNames.CameraResetDetectors,
      ItemsActionNames.CameraResetZone
    ];
    const executeSaveAction = requiredSaveActionItems.includes(v);

    if (v === ItemsActionNames.Restart) {
      item && CamerasService.camerasRestartCreate(item.id);
    } else {
      const payload = await convertToMsbItem({ type: MultisidebarItemTypes.Cameras, rawItem: item });
      await actionHandler.run(v, payload);
      if (executeSaveAction) await actionHandler.run(ItemsActionNames.Save, payload);
    }
  }
}
