
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { AnnexAlarmAcknowledgeStatus } from '@/pages/annex/alarms/AnnexAlarmAdapter';

@Options({
  name: 'AnnexAlarmAcknowledge',
  components: { NIcon, NButton }
})
export default class AnnexAlarmAcknowledge extends Vue {
  @Prop({ type: String, required: true })
  readonly status!: AnnexAlarmAcknowledgeStatus;

  @Prop({ type: String, required: true })
  readonly orientation!: string;

  get classes() {
    return {
      vertical: this.orientation === 'vertical',
      horizontal: this.orientation === 'horizontal'
    };
  }
}
