import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["data-qa"]
const _hoisted_2 = {
  key: 0,
  class: "n-table__empty label-m"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTableCell = _resolveComponent("NTableCell")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['n-table', { 'n-table_hover': _ctx.hover, 'n-table_active-row': _ctx.activeRow }]),
    style: _normalizeStyle(_ctx.styles),
    "data-qa": _ctx.dataQa
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnCells, (columnCell, i) => {
      return (_openBlock(), _createBlock(_component_NTableCell, {
        key: i,
        cell: columnCell,
        onAction: _ctx.actionHandler,
        onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort', $event))),
        sort: _ctx.getSort(columnCell),
        "data-qa": `${_ctx.dataQa}.header`
      }, null, 8, ["cell", "onAction", "sort", "data-qa"]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentCells, (contentCell, rowIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["n-table__row", _ctx.computeRowClass ? _ctx.computeRowClass(contentCell, rowIndex) : undefined]),
        key: 'row' + rowIndex
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contentCell, (contentCell2, cellIndex) => {
          return (_openBlock(), _createBlock(_component_NTableCell, {
            key: 'col' + cellIndex,
            cell: contentCell2,
            disabled: _ctx.getItemDisabled(contentCell2.row, cellIndex),
            "data-qa": `${_ctx.dataQa}.cell`,
            onAction: _ctx.actionHandler,
            onClick: ($event: any) => (_ctx.cellClickHandler(contentCell2, rowIndex, cellIndex))
          }, null, 8, ["cell", "disabled", "data-qa", "onAction", "onClick"]))
        }), 128))
      ], 2))
    }), 128)),
    (!_ctx.contentCells.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('common.no_data', 'f')), 1))
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}