import { intersection } from 'lodash';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { User } from '@/api';
import { AclModuleAbstract, Conditions, ConditionType, ItemAclResult, ModelAclResult, PermissionsMap } from '@/store/acl/types';
import { configAclModule } from '@/store/config/acl';
import { dataModule } from '@/store/data';

const AdminGroupId = 1;
const AdminUserId = 1;

function downgradeToMaxAcl(result: ItemAclResult, maxAcl: ItemAclResult) {
  (Object.keys(maxAcl) as (keyof ItemAclResult)[]).forEach((permission) => {
    if (typeof result[permission] !== 'undefined' && !maxAcl[permission]) {
      result[permission] = false;
    }
  });

  return result;
}

export class AclModule implements AclModuleAbstract {
  getAccess(permissionNames: string | string[] | undefined, condition: ConditionType = 'and') {
    const skipChecking = !permissionNames || !configAclModule.getEnabledAcl() || dataModule.isCurrentUserAdmin;
    if (skipChecking) return true;
    const permissionsArray = Array.isArray(permissionNames) ? permissionNames : [permissionNames];
    const permissionsList = dataModule.currentUserModule.item?.permissions || [];
    const permissionInUserPermissionsList = intersection(permissionsArray, permissionsList);
    const resultLength = permissionInUserPermissionsList.length;
    const hasAccess = condition === Conditions.And ? permissionsArray.length === resultLength : resultLength > 0;
    return hasAccess;
  }

  get maxAcl(): ModelAclResult {
    return {
      add: true,
      view: true,
      update: true,
      delete: true
    };
  }

  getModelAclByName(modelName: string, prefix: string = 'ffsecurity'): ModelAclResult {
    if (dataModule.isCurrentUserAdmin) return this.maxAcl;

    const permissionsList = dataModule.currentUserModule.item?.permissions;
    return {
      add: permissionsList?.includes(`${prefix}.add_${modelName}`) ?? false,
      view: permissionsList?.includes(`${prefix}.view_${modelName}`) ?? false,
      update: permissionsList?.includes(`${prefix}.change_${modelName}`) ?? false,
      delete: permissionsList?.includes(`${prefix}.delete_${modelName}`) ?? false
    };
  }

  getModelAcl<T, F>(model: ListViewModel<T, F> | ItemViewModel<T>): ModelAclResult {
    if (!model.hasAcl) throw new Error('Passed model hasn\'t ACL name!');
    if (dataModule.isCurrentUserAdmin) return this.maxAcl;

    const permissionsList = dataModule.currentUserModule.item?.permissions;
    let result = {
      add: permissionsList?.includes(model.aclAddPermissionName) ?? false,
      view: permissionsList?.includes(model.aclViewPermissionName) ?? false,
      update: permissionsList?.includes(model.aclUpdatePermissionName) ?? false,
      delete: permissionsList?.includes(model.aclDeletePermissionName) ?? false
    };
    return result;
  }

  getItemAclByUserProperty(itemId: number, permissionProperty: keyof User, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: true, update: false, delete: false };
    const user = dataModule.currentUserModule.item;
    const isSuperUser = user?.id === AdminUserId || user?.primary_group === AdminGroupId || user?.groups?.includes(AdminGroupId);
    const isNewItem = itemId <= -1000;
    if (!user) throw new Error('Can\'t get user!');

    if (isSuperUser) {
      result.view = result.update = result.delete = true;
      return result;
    } else if (isNewItem) {
      return { ...maxAcl };
    }

    const permissionsMap = user[permissionProperty] as PermissionsMap;
    if (!permissionsMap) throw new Error(`Can't get permissions for property "${permissionProperty}" in current user data!`);

    switch (permissionsMap[itemId]) {
      case 'edit':
        result.view = result.update = result.delete = true;
        break;
      case 'view':
        result.view = true;
        break;
    }
    return downgradeToMaxAcl(result, maxAcl);
  }

  getItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: false, update: false, delete: false };

    const user = dataModule.currentUserModule.item;
    if (!user) throw new Error('Can\'t get user!');

    let userGroups = user.groups ? [user.primary_group, ...user.groups] : [user.primary_group];

    if (userGroups.includes(AdminGroupId)) {
      result.view = result.update = result.delete = true;
      return result;
    }

    userGroups.forEach((group) => {
      if (groupPermissionsMap[group]) {
        switch (groupPermissionsMap[group]) {
          case 'edit':
            result.view = result.update = result.delete = true;
            break;
          case 'view':
            result.view = true;
            break;
        }
      }
    });

    return downgradeToMaxAcl(result, maxAcl);
  }

  getCaseItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult {
    const result: ItemAclResult = { view: false, update: false, delete: false };
    const user = dataModule.currentUserModule.item;

    if (!user) throw new Error('Can\'t get user!');

    let userGroups = user.groups ? [user.primary_group, ...user.groups] : [user.primary_group];

    if (userGroups.includes(AdminGroupId)) {
      result.view = result.update = result.delete = true;
      return result;
    }

    userGroups.forEach((group) => {
      if (groupPermissionsMap[group]) {
        switch (groupPermissionsMap[group]) {
          case 'delete':
            result.view = result.update = result.delete = true;
            break;
          case 'edit':
            result.view = result.update = true;
            break;
          case 'view':
            result.view = true;
            break;
        }
      }
    });

    return downgradeToMaxAcl(result, maxAcl);
  }

  getWatchLisEditPermission(ids: number[] | undefined): boolean {
    const permissions = dataModule.currentUserModule.item?.watch_list_permissions || {};
    let result = (ids || []).reduce<boolean>((m, v) => {
      if (m) return m;
      return permissions[v] === 'edit';
    }, false);
    return result;
  }

  mergeAcl(first: ModelAclResult, second: ModelAclResult): ModelAclResult {
    return {
      view: first.view && second.view,
      update: first.update && second.update,
      add: first.add && second.add,
      delete: first.delete && second.delete
    };
  }

  mergeMultipleAcl(...items: Partial<ModelAclResult>[]): ModelAclResult {
    const reduceByProp = (prop: keyof ModelAclResult) => items.reduce<boolean>((m, v) => ((v[prop] === false || !m)? false : m), true);

    return {
      view: reduceByProp('view'),
      update: reduceByProp('update'),
      add: reduceByProp('add'),
      delete: reduceByProp('delete')
    };
  }

}

export const aclModule: AclModule = new AclModule();
