import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-small-angle-range__label control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterAngleRange = _resolveComponent("FilterAngleRange")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['filter-small-angle-range', { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_FilterAngleRange, {
      model: _ctx.model,
      "gte-field-name": _ctx.gteFieldName,
      "lte-field-name": _ctx.lteFieldName,
      plain: true,
      disabled: _ctx.disabled
    }, null, 8, ["model", "gte-field-name", "lte-field-name", "disabled"])
  ], 2))
}