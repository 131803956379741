import { ItemViewModel } from '@/definitions/view-models';
import { reactive } from 'vue';

export const ModuleEventTypes = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete'
} as const;

export type ModuleEventType = typeof ModuleEventTypes[keyof typeof ModuleEventTypes];

export type ModuleEvent = {
  type: ModuleEventType;
  modelName: string;
  item?: any;
  model?: ItemViewModel<any>;
};

export class EventModule {
  current?: ModuleEvent;

  build(type: ModuleEventType, name: string, item?: any) {
    return {
      type,
      modelName: name,
      item: item
    };
  }
}

export const eventModule = reactive(new EventModule());
