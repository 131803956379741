
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from './NButton.vue';

@Options({
  name: 'NButtonModel',
  components: { NButton },
  emits: ['update:modelValue']
})
export default class NButtonModel extends Vue {
  @Prop({ type: [String, Number], default: '' })
  readonly modelValue!: string | number;

  get computedProps() {
    const { modelValue, ...props } = this.$props as any;

    return {
      label: String(modelValue),
      ...props
    };
  }

  handleClick() {
    this.$emit('update:modelValue', this.modelValue);
  }
}
