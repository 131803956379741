import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "datasource-cameras-grid__header" }
const _hoisted_2 = {
  class: "datasource-cameras-grid__content",
  ref: "content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_CameraItem = _resolveComponent("CameraItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['datasource-cameras-grid', { 'datasource-cameras-grid_full-width': _ctx.items.length > 2 }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NButton, {
        type: "text",
        counter: _ctx.items.length,
        onAction: _ctx.openAll
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ds.open_all')), 1)
        ]),
        _: 1
      }, 8, ["counter", "onAction"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_CameraItem, {
          key: item.id,
          item: item,
          onClick: ($event: any) => (_ctx.openItem(item))
        }, null, 8, ["item", "onClick"]))
      }), 128))
    ], 512)
  ], 2))
}