
import { merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import type { ExternalVms } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ExternalVmsFilter } from '@/api/models/ExternalVmsFilter';
import { aclModule } from '@/store/acl';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import NTableOutdated from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import NText from '@/uikit/text/NText.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import enrichExternalVms from '@/pages/external-vms/enrich-external-vms';
import ExternalVMSStatus from '@/pages/external-vms/ExternalVmsStatus.vue';
import { EnrichedExternalVms } from '@/pages/external-vms/types';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';

@Options({
  components: {
    ActionsDropdown,
    InfiniteScroll,
    NForm,
    NLoadingCircle,
    NTable,
    NTableOutdated,
    NTabs,
    SettingsBar,
    SettingsPageLayout,
    SortDropdown
  }
})
export default class ExternalVmsPage extends Vue {
  loading = false;
  enrichedItems: EnrichedExternalVms[] = [];

  get computedVMSItems() {
    return this.items.map((v: ExternalVms) => {
      const enrichedItem = this.enrichedItems.find((i) => i.id === v.id);
      return enrichedItem || v;
    });
  }

  get selectedEnrichedItems() {
    return this.enrichedItems.filter((v) => this.selectedItemIds.includes(v.id));
  }

  get selectedItemIds(): number[] {
    return this.sidebarModule.items.filter((v) => v.type === MultisidebarItemTypes.ExternalVms).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get sidebarType() {
    return MultisidebarItemTypes.ExternalVms;
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get state(): PageState {
    const tab = 'external-vms';
    return pageModule.getPageStateByTab(PagePaths.ExternalVms, tab);
  }

  get items(): ExternalVms[] {
    return this.module.items;
  }

  get columns(): NTableColumn<EnrichedExternalVms>[] {
    return [
      {
        width: 'max-content',
        head: this.$t('common.id'),
        body: ({ model }) => {
          const props = { modelValue: model.id };
          return <ButtonModelCopy {...props} />;
        }
      },
      {
        width: 150,
        head: this.$t('external_vms.name'),
        body: ({ model }) => {
          const props = { type: 'link', align: 'left', modelValue: model.verbose_name, 'onUpdate:modelValue': this.handleNameClick.bind(this, model) };
          return <NButtonModel {...props} />;
        }
      },
      {
        width: 150,
        head: this.$t('external_vms.vms'),
        body: ({ model }) => {
          const props = { modelValue: model.name };
          return <NText {...props} />;
        }
      },
      {
        width: 80,
        head: this.$t('external_vms.version'),
        body: ({ model }) => {
          const props = { modelValue: model.version };
          return <NText {...props} />;
        }
      },
      {
        width: 'minmax(100px, 1fr)',
        head: this.$t('external_vms.ip_address'),
        body: ({ model }) => {
          const props = { modelValue: model.options?.server_api_url };
          return <NText {...props} />;
        }
      },
      {
        width: 'minmax(100px, 1fr)',
        head: this.$t('external_vms.status'),
        body: ({ model }) => {
          const props = { modelValue: model.health_status };
          return <ExternalVMSStatus {...props} />;
        }
      },
      {
        width: 160,
        head: this.$t('external_vms.created'),
        body: ({ model }) => <NDateTimeLabel modelValue={model.created_date} multiline={false} size={'xs'} />
      },
      {
        width: 50,
        head: '',
        body: ({ model }) => {
          const dropdownProps = {
            actions: this.actions,
            placement: 'left-start',
            onAction: (action: ItemsActionName) => this.actionHandler(action, model)
          };
          return (
            <div class="datasource-camera-table__actions-cell">
              <ActionsDropdown class="datasource-camera-table__actions" {...dropdownProps} />
            </div>
          );
        }
      }
    ];
  }

  get modelAcl() {
    this.module.aclModelName = 'externalvms';
    return aclModule.getModelAcl<ExternalVms, ExternalVmsFilter>(this.module);
  }

  get actions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasDelete: true
      })
      .map(actionsModule.computeActionByName);
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ created_date: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  @Watch('module.filter.current.ordering')
  changeOrderHandler() {
    this.module.debouncedGet();
  }

  actionHandler(v: ItemsActionName, rawItem: ExternalVms) {
    actionHandler.run(v, { type: MultisidebarItemTypes.ExternalVms, rawItem });
  }

  handleSearch(query: string) {
    if (this.module.filter.current.verbose_name !== query) {
      this.module.filter.current.verbose_name = query;
      this.module.debouncedGet();
    }
  }

  handleCreate() {
    this.sidebarModule.addNewItemByType(this.sidebarType);
  }

  handleSelect(selectedChanges: EnrichedExternalVms[]) {
    selectedChanges.forEach((v) => {
      actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: this.sidebarType, rawItem: v });
    });
  }

  handleNameClick(model: EnrichedExternalVms) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.ExternalVms, rawItem: model });
  }

  async load() {
    this.loading = true;
    try {
      await this.module.get();
      this.enrichedItems = [];
      await this.enrichVmsItems();
    } finally {
      this.loading = false;
    }
  }

  async append(): Promise<void> {
    this.loading = true;
    try {
      await this.module.append();
      await this.enrichVmsItems();
    } finally {
      this.loading = false;
    }
  }

  async enrichVmsItems(): Promise<void> {
    const items = this.items.slice(this.enrichedItems.length);
    this.enrichedItems = [...this.enrichedItems, ...(await enrichExternalVms(items))];
  }

  mounted() {
    this.load();
    autoUpdateHelper.addListInstance(this.module);
  }
}
