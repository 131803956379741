
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import { ISelectItem } from '@/uikit';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'UserRealNameSelect',
  components: { NSelect }
})
export default class UserRealNameSelect extends Vue {
  // todo: fix this component; init value don't work on page load
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String })
  readonly prefixIcon?: string;

  get module() {
    return viewModelRepository.getUsersListViewModel();
  }

  async loadItems(value: string): Promise<ISelectItem[]> {
    this.module.filter.current.id_in = [];
    this.module.filter.current.real_name_contains = '';
    if (value) {
      this.module.filter.current.real_name_contains = value;
    }
    await this.module.get();
    return this.module.items.map((item) => {
      return {
        value: item.real_name,
        label: item.real_name
      };
    });
  }

  updateValue(value: string) {
    this.$emit('update:modelValue', value);
  }
}
