import { reactive } from 'vue';
import { Camera, CameraGroup, CarEpisode, HumanEpisode, VideoArchive } from '@/api';
import { aclModule } from '@/store/acl';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { languageModule } from '@/store/languages';
import { canShowMenuItem } from '@/store/menu';
import { launcherItems } from '@/store/menu/launcher.items';
import { NButtonGroupItem } from '@/uikit/buttons/types';
import { configAclModule } from '../config/acl';
import {
  CardSingleType,
  CardsSingleToMulti,
  CardType,
  DisplayTypeItems,
  EpisodesSingleToMulti,
  EventTypes,
  EventTypesMap,
  ObjectsSingleToMulti,
  ObjectType,
  SearchFromMap,
  SearchPageTypes,
  SortOption,
  SortOptions
} from '@/store/application/data.assets';

function getOptionItem(optionName: keyof SortOptions, isDesc = false) {
  const orderSuffix = isDesc ? 'desc' : 'asc';
  let label = optionName,
    value = optionName;

  if (optionName === 'idAsCreatedDate') {
    label = 'created_date';
    value = 'id';
  }

  return { i18n_label: `sorts.${label}_${orderSuffix}`, value: isDesc ? `-${value}` : value };
}

function getOptionOrders(option: SortOption) {
  return typeof option === 'boolean' ? { desc: true, asc: true } : option;
}

export class DataAssetsModule {
  get eventPageTypeButtonItems(): NButtonGroupItem[] {
    return EventTypes.filter((v) => (v === EventTypesMap.Episodes ? this.availableEpisodeTypes.length : this.availableEventObjects.length)).map(
      (v: string) => ({ value: v, label: languageModule.getTranslatedToken(`common.${v}`, 'f') })
    );
  }

  get clusterObjectTypeButtonItems(): NButtonGroupItem[] {
    return this.availableClusterObjects
      .map((v: string) => ObjectsSingleToMulti[v])
      .map((v: string) => ({ value: v, label: languageModule.getTranslatedToken(`common.${v}`, 'f') }));
  }

  get eventObjectTypeButtonItems(): NButtonGroupItem[] {
    return this.availableEventObjects
      .map((v: string) => ObjectsSingleToMulti[v])
      .map((v: string) => ({ value: v, label: languageModule.getTranslatedToken(`common.${v}`, 'f') }));
  }

  get episodeTypeButtonItems(): NButtonGroupItem[] {
    return this.availableEpisodeTypes
      .map((v: string) => EpisodesSingleToMulti[v])
      .map((v: string) => ({ value: v, label: languageModule.getTranslatedToken(`common.${v}`, 'f') }));
  }

  get cardTypeButtonItems(): NButtonGroupItem[] {
    return this.availableCardTypes.map((v) => CardsSingleToMulti[v]).map((v) => ({ value: v, label: languageModule.getTranslatedToken(`common.${v}`, 'f') }));
  }

  get clustersTypeButtonItems(): NButtonGroupItem[] {
    return this.clusterObjectTypeButtonItems;
  }

  get displayTypes(): NButtonGroupItem[] {
    return DisplayTypeItems;
  }

  get searchFromTypes(): NButtonGroupItem[] {
    return Object.values(SearchFromMap)
      .filter(this.isItemEnabled)
      .map((v: string) => ({
        value: v,
        label: languageModule.getTranslatedToken(`search.${v}`, 'f')
      }));
  }

  get searchPageTypes(): NButtonGroupItem[] {
    return SearchPageTypes.filter(this.isItemEnabled).map((v: string) => ({
      value: v,
      label: languageModule.getTranslatedToken(`search.${v}`, 'f')
    }));
  }

  isItemEnabled(name: string) {
    const searchName = name === 'case_participants' ? 'case_clusters' : name; // TODO: can be removed in future
    if (configAclModule.isDisabledMenuItem('cases') && searchName === 'case_clusters') {
      return false;
    }
    const launcherItem = launcherItems.find((v) => v.name === searchName);
    const isAllowedByLauncherItemPermissions = launcherItem ? canShowMenuItem(launcherItem) : true;
    return isAllowedByLauncherItemPermissions;
  }

  /* Available sorts */
  getSortTypes(options: SortOptions = { created_date: true }): any[] {
    const sortedOptions: (keyof SortOptions)[] = ['looks_like_confidence', 'created_date', 'id', 'idAsCreatedDate', 'name', 'confidence', 'external_timestamp'];
    const result: any[] = [];

    sortedOptions.forEach((optionName) => {
      const option = options[optionName];
      if (option) {
        const { desc, asc } = getOptionOrders(option);
        desc && result.push(getOptionItem(optionName, true));
        asc && result.push(getOptionItem(optionName));
      }
    });

    return result;
  }

  /* Objects */

  get objects(): string[] {
    return ['face', 'body', 'car'];
  }

  getAvailableSomethingObjectsMap(modelBase: string): Record<string, boolean> {
    const result: Record<string, boolean> = {};
    this.availableObjects.forEach((v) => {
      const permissions = dataModule.currentUserModule.item?.permissions || [];
      const permissionName = `ffsecurity.view_${v}${modelBase}`;
      const available = permissions.includes(permissionName);
      if (available) result[v] = available;
    });
    return result;
  }

  get availableEventObjectsMap(): Record<string, boolean> {
    return this.getAvailableSomethingObjectsMap('event');
  }

  get availableEventObjects(): string[] {
    return Object.keys(this.availableEventObjectsMap);
  }

  get availableClusterObjectsMap(): Record<string, boolean> {
    return this.getAvailableSomethingObjectsMap('cluster');
  }

  get availableClusterObjects(): string[] {
    return Object.keys(this.availableClusterObjectsMap);
  }

  get availableObjectsMap(): Record<string, boolean> {
    const result: Record<string, boolean> = {};
    this.objects.forEach((v) => {
      const configObjectsFieldName = ObjectsSingleToMulti[v];
      const hasObject = configModule.config.objects?.[configObjectsFieldName]?.enabled === true;
      if (hasObject) result[v] = hasObject;
    });
    return result;
  }

  get availableObjectsTypedMap(): Record<ObjectType, boolean> {
    return this.availableObjectsMap as any;
  }

  get availableObjects(): string[] {
    return Object.keys(this.availableObjectsMap);
  }

  get availableObjectsConfidence(): Record<string, number> {
    const FieldName = 'confidence_display';
    const result: Record<string, number> = {};

    this.availableObjects.forEach((v) => {
      const configObjectsFieldName = ObjectsSingleToMulti[v];
      result[v] = configModule.config.objects[configObjectsFieldName][FieldName];
    });
    return result;
  }

  get detectFeatures() {
    return this.availableObjects.reduce((acc: any, object: string) => {
      const features = configModule.config?.detect_config?.[object] || {};
      return { ...acc, [object]: features };
    }, {});
  }

  confidenceLabel(object: string, value: number) {
    const objectConfidence = configModule.config.objects[object].confidence_display;
    const currentLocale = languageModule.currentLocale;
    const findConfidenceElement = objectConfidence.find((item: any) => value <= item.confidence);
    const lastConfidence = objectConfidence[objectConfidence.length - 1].label[currentLocale];
    return findConfidenceElement ? findConfidenceElement.label[currentLocale] : lastConfidence;
  }

  /* Episodes */

  get episodeTypesMap(): Record<string, boolean> {
    const hasHuman = this.availableObjectsMap.face || this.availableObjectsMap.body;
    const hasCar = this.availableObjectsMap.car;
    const result: Record<string, boolean> = {};
    if (hasHuman) result.human = true;
    if (hasCar) result.car = true;
    return result;
  }

  get availableEpisodeTypesMap(): Record<string, boolean> {
    const result: Record<string, boolean> = {};
    Object.keys(this.episodeTypesMap).forEach((v) => {
      const permissions = dataModule.currentUserModule.item?.permissions || [];
      const available = permissions.includes(`ffsecurity.view_${v}episode`);
      if (available) result[v] = available;
    });
    return result;
  }

  get availableEpisodeTypes(): string[] {
    return Object.keys(this.availableEpisodeTypesMap);
  }

  /* Card */

  get cardTypesMap(): Record<string, boolean> {
    const hasHuman = this.availableObjectsMap.face || this.availableObjectsMap.body;
    const hasCar = this.availableObjectsMap.car;
    const result: Record<string, boolean> = {};
    if (hasHuman) result.human = true;
    if (hasCar) result.car = true;
    return result;
  }

  get availableCardTypesMap(): Record<CardType, boolean> {
    const result: Record<string, boolean> = {};
    Object.keys(this.cardTypesMap).forEach((v) => {
      const available = aclModule.getAccess(`ffsecurity.view_${v}card`);
      if (available) result[v] = available;
    });
    return result;
  }

  get availableCardTypes() {
    return Object.keys(this.availableCardTypesMap) as CardSingleType[];
  }

  getObjectTypeByCardType(v: string) {
    return v === 'humans' ? 'faces' : 'cars';
  }

  getObjectTypeByEpisodeType(v: string) {
    return v === 'humans' ? 'faces' : 'cars';
  }

  getObjectTypeByEpisode(v: HumanEpisode | CarEpisode): string {
    const carEpisode = v as CarEpisode;
    const humanEpisode = v as HumanEpisode;
    const isCarEpisode = carEpisode.best_car_event || carEpisode.last_car_event;
    const isHumanBodyEpisode = humanEpisode.best_body_event || humanEpisode.last_body_event;
    return isCarEpisode ? 'cars' : isHumanBodyEpisode ? 'bodies' : 'faces';
  }

  getCardTypeByEpisodeType(v: string) {
    return v;
  }

  getCardTypeByObjectType(v: string) {
    return v === 'faces' || v === 'bodies' ? 'humans' : 'cars';
  }

  getEpisodeTypeByObjectType(v: string) {
    return v === 'faces' || v === 'bodies' ? 'humans' : 'cars';
  }

  isObjectAvailable(objectName: string) {
    return this.availableObjects.includes(objectName);
  }

  getCameraById(id: number): Camera | undefined {
    const dataItems = dataModule.camerasModule.items;
    return dataItems.find((v) => v.id === id);
  }

  getVideoArchiveById(id: number): VideoArchive | undefined {
    const dataItems = dataModule.videosModule.items;
    return dataItems.find((v) => v.id === id);
  }

  getCameraGroupById(id: number): CameraGroup | undefined {
    const dataItems = dataModule.cameraGroupsModule.items;
    return dataItems.find((v) => v.id === id);
  }
}

export const dataAssetsModule = reactive(new DataAssetsModule());
