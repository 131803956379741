import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-thread-id"]
const _hoisted_2 = { class: "n-notification__body label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": _ctx.transitionEnterClass,
    "leave-active-class": "n-notification--fade-out"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        ref: "root",
        class: _normalizeClass(['n-notification', `n-notification_${_ctx.size}`, _ctx.customClass]),
        "data-thread-id": _ctx.threadId
      }, [
        (_ctx.uploading)
          ? (_openBlock(), _createBlock(_component_NLoadingCircle, {
              key: 0,
              class: "n-notification__icon"
            }))
          : (_ctx.type)
            ? (_openBlock(), _createBlock(_component_NIcon, {
                key: 1,
                class: "n-notification__icon",
                name: _ctx.type,
                width: "24",
                height: "24"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.hasRenderer)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.content()), {
                key: 0,
                onClose: _ctx.removeNotification
              }, null, 8, ["onClose"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.content), 1)
              ], 64))
        ]),
        (_ctx.showClose)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 2,
              class: "n-notification__close",
              icon: "close",
              type: "link",
              onClick: _ctx.removeNotification
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.action)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: "n-notification__action control-m",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.callToAction && _ctx.callToAction(...args)))
            }, _toDisplayString(_ctx.action), 1))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1), [
        [_vShow, _ctx.visible]
      ])
    ]),
    _: 1
  }, 8, ["enter-active-class"]))
}