
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NForm, { AnyFunction, IFormLayout, IFormLayoutItem, IFormLayoutRow } from '../forms/NForm.vue';

export interface IFormLayoutBlock {
  layout: IFormLayout;
  display?: boolean | AnyFunction;
  disabled?: boolean | AnyFunction;
}
export type IFormLayoutBlocks = IFormLayoutBlock[];

@Options({
  name: 'NFormBlocks',
  components: { NForm }
})
export default class NFormBlocks extends Vue {
  @Prop({ type: Object, required: true })
  readonly blocks!: IFormLayoutBlocks;

  @Prop({ type: Object })
  readonly model!: any;

  @Prop({ type: Object })
  readonly state!: any;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  disableItem(item: IFormLayoutItem) {
    const clonedItem = { ...item };
    clonedItem.props = { ...clonedItem.props, disabled: true };
    return clonedItem;
  }

  get layout() {
    let result: IFormLayout = [];
    this.blocks.forEach((block: IFormLayoutBlock) => {
      if (block.display instanceof Function ? block.display(this.model, this.state) : block.display !== false) {
        if (block.disabled instanceof Function ? block.disabled(this.model, this.state) : block.disabled === true) {
          result = result.concat(
            block.layout.map((row: IFormLayoutRow) => {
              return row instanceof Array ? row.map((item) => this.disableItem(item)) : this.disableItem(row);
            })
          );
        } else {
          result = result.concat(block.layout);
        }
      }
    });
    return result;
  }

  validate(): boolean {
    return this.$refs.form.validate();
  }

  focus(): void {
    return this.$refs.form.focus();
  }

  displayErrors() {
    return this.$refs.form.displayErrors();
  }

  focusInHandler(e: FocusEvent) {
    return this.$refs.form.focusInHandler(e);
  }

  validateAndDisplayErrors() {
    if (!this.validate()) {
      this.displayErrors();
      return false;
    }
    return true;
  }
}
