
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NDropdownItem, NDropdown } from '../dropdown';
import { ISelectItem } from '../select/types';
import NButton from './NButton.vue';
import { NButtonSize, NButtonSizes, NButtonType, NButtonTypes } from './types';

@Options({
  name: 'NButtonSelect',
  components: { NButton, NButtonType: NButtonTypes, NDropdown, NDropdownItem },
  emits: ['update:modelValue']
})
export default class NButtonSelect extends Vue {
  @Prop({ type: [String, Number], default: '' })
  public readonly modelValue!: string | number;

  @Prop({ type: Array, default: [] })
  public readonly items!: ISelectItem[];

  @Prop({ type: String, default: NButtonTypes.Primary })
  public readonly type!: NButtonType;

  @Prop({ type: String, default: NButtonSizes.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: false;

  get currentItem() {
    return this.items.find((v) => v.value === this.modelValue);
  }

  get color() {
    return this.currentItem?.color || '';
  }

  get label() {
    return this.currentItem?.label || '';
  }
}
