import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarContentDebug = _resolveComponent("MultisidebarContentDebug")!
  const _component_MultisidebarContentNotFound = _resolveComponent("MultisidebarContentNotFound")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!

  return (_ctx.currentItem && _ctx.module.active)
    ? (_openBlock(), _createElementBlock("div", {
        class: "multisidebar-content",
        key: _ctx.currentItem?.id
      }, [
        (_ctx.debugMode)
          ? (_openBlock(), _createBlock(_component_MultisidebarContentDebug, {
              key: 0,
              sidebarItem: _ctx.currentItem,
              itemAcl: _ctx.itemAcl
            }, null, 8, ["sidebarItem", "itemAcl"]))
          : _createCommentVNode("", true),
        (_ctx.ready)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentItemConfig.contentComponent), {
              key: 1,
              sidebarItem: _ctx.currentItem,
              sidebarSharedState: _ctx.currentSharedState,
              modelAcl: _ctx.modelAcl,
              itemAcl: _ctx.itemAcl,
              "data-qa": _ctx.dataQa
            }, null, 8, ["sidebarItem", "sidebarSharedState", "modelAcl", "itemAcl", "data-qa"]))
          : (_ctx.notFound)
            ? (_openBlock(), _createBlock(_component_MultisidebarContentNotFound, {
                key: 2,
                sidebarItem: _ctx.currentItem,
                "data-qa": _ctx.dataQa + '.not-found'
              }, null, 8, ["sidebarItem", "data-qa"]))
            : (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "multisidebar-content__loader",
                "data-qa": _ctx.dataQa + '.loading'
              }, [
                _createVNode(_component_NLoadingCircle)
              ], 8, _hoisted_1))
      ]))
    : _createCommentVNode("", true)
}