import Hammer from 'hammerjs';
import { Ref } from 'vue';
import { EventManager } from './EventManager';
import { ScaleOptions } from './types';

export class Zoomer {
  protected hammer: HammerManager;
  protected eventManager: EventManager;
  protected lastPinchScale: number;

  constructor(protected container: Element, protected scale: Ref<number>, protected options: ScaleOptions) {
    this.hammer = new Hammer(this.container as HTMLElement);
    this.hammer.get('pinch').set({ enable: true });
    this.eventManager = new EventManager();
    this.lastPinchScale = scale.value;
    this.bindHandlers();
  }

  public destruct() {
    this.reset();
    this.hammer.destroy();
    this.eventManager.unbindAll();
  }

  protected bindHandlers() {
    this.hammer.on('pinchmove', this.pinchMove.bind(this));
    this.hammer.on('pinchend pinchcancel', this.pinchEnd.bind(this));
    this.eventManager.bind(this.container, 'wheel', this.handleWheel, undefined, this);
    this.eventManager.bind(this.container, 'dblclick', this.reset, undefined, this);
  }

  protected pinchMove(e: HammerInput) {
    const scale = e.scale * this.lastPinchScale;
    this.scale.value = this.getLimitedScale(scale);
  }

  protected pinchEnd() {
    this.lastPinchScale = this.scale.value;
  }

  protected handleWheel(e: WheelEvent) {
    e.stopPropagation();
    const scale = this.scale.value - e.deltaY * this.options.wheelStep;
    this.scale.value = this.getLimitedScale(scale);
  }

  protected getLimitedScale(scale: number) {
    return Math.min(Math.max(this.options.min, scale), this.options.max);
  }

  protected reset() {
    this.scale.value = this.options.default;
  }
}
