import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "features__header"
}
const _hoisted_2 = {
  key: 0,
  class: "feature-item__content"
}
const _hoisted_3 = { class: "feature-item__name" }
const _hoisted_4 = {
  key: 1,
  class: "feature-item__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.header)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString(_ctx.$t('attributes') + ':'), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formattedFeatureNames, (name) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: name }, [
        (_ctx.getFeatureDisplay(_ctx.formattedFeatures[name]))
          ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
              (_ctx.formattedFeatures[name].confidence)
                ? (_openBlock(), _createBlock(_component_NHint, {
                    key: 0,
                    content: _ctx.formattedFeatures[name].confidence,
                    "delay-hidden": 0,
                    placement: "left"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedFeatures[name].content), 1)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formattedFeatures[name].content), 1))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}