
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import DevToolsScript from '@/pages/devtools/DevToolsScript';

@Options({
  name: 'DevToolsPage',
  components: { NButton, NIcon, NTextareaAutosize }
})
export default class DevToolsPage extends Vue {
  scripts: DevToolsScript[] = [];
  lsKey = 'DevToolsScriptSource';

  fileHandler(event: Event) {
    const target = event.target as HTMLInputElement;
    const fileList = target.files;
    if (fileList && fileList.length) {
      const file = fileList[0];

      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const source = event?.target?.result;
        if (source) {
          this.scripts = DevToolsScript.parseAllScripts(source as string);
          if (this.scripts.length) {
            window.localStorage.setItem(this.lsKey, source as string);
          } else {
            window.localStorage.removeItem(this.lsKey);
          }
        }
      });
      reader.readAsText(file);
    }
  }

  clear() {
    this.scripts = [];
    window.localStorage.removeItem(this.lsKey);
  }

  mounted() {
    const source = window.localStorage.getItem(this.lsKey);
    if (source) {
      this.scripts = DevToolsScript.parseAllScripts(source as string);
    }
  }
}
