import { CustomReport } from '../models/CustomReports';
import { request as __request } from '../core/request';
import { CancelablePromise } from '../core/CancelablePromise';

export class CustomReportsService {
  public static customReportsCreate(path: string): CancelablePromise<CustomReport> {
    return __request({
      method: 'POST',
      path: path,
      mediaType: 'application/json'
    });
  }
}
