export type EditorMode = typeof EditorModes[keyof typeof EditorModes];
export const EditorModes = {
  Visual: 'visual',
  Json: 'json'
};

export type EditorSection = typeof EditorSections[keyof typeof EditorSections];
export const EditorSections = {
  FaceEvents: 'face_events',
  BodyEvents: 'body_events',
  CarEvents: 'car_events',
  HumanEpisodes: 'human_episodes',
  CarEpisodes: 'car_episodes',
  Counters: 'counters'
};

export type AlertMode = 'alert' | 'webhook';
