
import { Component } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NFormItem from '../forms/NFormItem.vue';
import './NForm.styl';
import { flatten } from 'lodash';
import get from 'lodash/get';

export type AnyFunction = (...args: any[]) => any;

// TODO: fix this any
export type IFormModel = any | number | string | { [key: number]: IFormModel } | { [key: string]: IFormModel };
export type IFormValidator = {
  name?: string;
  handler?: (c: IFormContext) => boolean;
  i18n_message?: string;
  message?: string;
  i18n_options?: any;
  placement?: string;
  replaceArray?: any[];
};

export interface IFormLayoutItem {
  name?: string;
  path?: string;
  classes?: string;
  label?: string;
  i18n_label?: string;
  i18n_placeholder?: string;
  component: Component;
  props?: Record<string, any>;
  on?: Record<string, AnyFunction>;
  group?: string;
  encode?: (model: IFormModel, value: any) => void; // save value to model
  decode?: (model: IFormModel) => any; // parse value from model

  validators?: IFormValidator[];

  hidden?: boolean | AnyFunction;
  tooltip?: string;
  i18n_tooltip?: string;
  tooltipPlacement?: string;
  errorPlacements?: string[];
  errorStyles?: any;
  checkForEmpty?: AnyFunction;
  dataQa?: string;
}

export type IFormLayoutRow = IFormLayoutItem | IFormLayoutItem[];
export type IFormLayout = IFormLayoutRow[];
export type IFormOptionalLayout = (IFormLayoutRow | null)[];

export type IFormError = {
  i18n_message?: string;
  message?: string;
  i18n_options?: any;
  placement?: string;
  value: any;
  replaceArray?: any[];
};

export interface IFormContext {
  form: NForm;
  layout: IFormLayout;
  model: IFormModel;
  state: any;
  item?: IFormLayoutItem;
}

export const FormValidatorNames = {
  Required: 'required'
};

export const FormValidators: IFormValidator[] = [
  {
    name: FormValidatorNames.Required,
    i18n_message: 'errors.required.field',
    handler: (context: IFormContext) => {
      const value = context.item?.path && get(context.model, context.item.path);
      const result = Boolean(Array.isArray(value) ? value.length : value) || value === false;
      return result;
    }
  }
];

@Options({
  name: 'NForm',
  components: { NFormItem }
})
export default class NForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly layout!: IFormLayout;

  @Prop({ type: Object, required: true })
  readonly model!: IFormModel;

  @Prop({ type: Object, default: () => {} })
  readonly state!: any;

  @Prop({ type: Boolean, default: false })
  readonly inlineForm!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly floatForm!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly requiredOnly!: boolean;

  @Prop({ type: Array, default: null })
  readonly enabledFields: string[] | null = null;

  @Prop({ type: String })
  readonly dataQa?: string;

  showErrors = false;

  get hasEnabledFields(): boolean {
    return !!this.enabledFields;
  }

  getIsCheckboxEnabled(value: IFormLayoutItem): boolean {
    const fieldId = value.path || value.name || '';
    return this.hasEnabledFields && fieldId ? this.enabledFields?.includes(fieldId) ?? false : false;
  }

  get context(): IFormContext {
    return {
      form: this,
      layout: this.layout,
      model: this.model,
      state: this.state
    };
  }

  get rowErrors(): (IFormError | null)[][] {
    return this.rows.map((items: IFormLayoutItem[]) => {
      return items.map(this.validateItem);
    });
  }

  getIsItemRequired(item: IFormLayoutItem): boolean {
    return !!item.validators?.find((v) => v.name === 'required');
  }

  computeDisabled(item: IFormLayoutItem) {
    let itemFooDisabled = item.props instanceof Function ? item.props.call(this.context, this.model).disabled : false;
    return item.props?.disabled || itemFooDisabled || this.disabled;
  }

  computeValidator(validator: IFormValidator) {
    let result = validator.name ? FormValidators.find((item) => item.name === validator.name) : validator;
    return { ...result, ...validator };
  }

  validateItem(item: IFormLayoutItem): IFormError | null {
    const validators = item.validators;
    const result =
      validators?.reduce<IFormError | null>((m: IFormError | null, validator: IFormValidator) => {
        const value = get(this.model, item.path!);
        const computedValidator = this.computeValidator(validator);
        const context = { ...this.context, item };
        const valid = computedValidator.handler?.(context);
        return valid
          ? m
          : {
              i18n_message: computedValidator.i18n_message,
              message: computedValidator.message,
              i18n_options: computedValidator.i18n_options,
              value,
              placement: computedValidator.placement,
              replaceArray: computedValidator.replaceArray
            };
      }, null) || null;
    return result;
  }

  get rows(): IFormLayoutItem[][] {
    return this.layout
      .map((item: IFormLayoutRow) => {
        return Array.isArray(item) ? item : [item];
      })
      .map((items: IFormLayoutItem[]) => {
        return this.requiredOnly ? items.filter((v) => this.getIsItemRequired(v)) : items;
      })
      .filter((items: IFormLayoutItem[]) => {
        return !!items.length;
      });
  }

  get itemsAsObject() {
    const itemsObject: Record<string, IFormLayoutItem> = {};
    this.rows.forEach((item: IFormLayoutItem[]) => {
      item.forEach((subItem: IFormLayoutItem) => {
        if (subItem.path) {
          itemsObject[subItem.path] = subItem;
        }
      });
    });
    return itemsObject;
  }

  get errors(): IFormError[] {
    return flatten(this.rowErrors).filter((v: IFormError | null) => v !== null) as IFormError[];
  }

  validateAndDisplayErrors(): boolean {
    const result = this.validate();
    if (!result) this.displayErrors();
    return result;
  }

  validate(): boolean {
    return !this.errors.length;
  }

  focus(): void {
    this.$refs.formItems.find((v: NFormItem) => v.canFocus)?.focusOnControl();
  }

  displayErrors() {
    this.showErrors = true;
  }

  focusInHandler(e: FocusEvent) {
    this.showErrors = false;
  }

  updateEnabledFields(v: { id: string; enabled: boolean }) {
    let r = [...this.enabledFields!];
    if (v.enabled) {
      r.push(v.id);
    } else {
      r = r.filter((i) => i !== v.id);
    }
    this.$emit('update:enabledFields', r);
  }
}
