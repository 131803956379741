import { cloneDeep } from 'lodash';
import { getLogger } from 'loglevel';
import { reactive } from 'vue';
import { CarEpisode, CaseCluster, HumanEpisode, VideoArchive } from '@/api';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { viewModelRepository } from '@/api/common';
import { throttleWithArgs } from '@/common/utils';
import { NotificationsModule } from '@/store/application/notifications';
import { ExceptionsModule } from '@/store/application/exceptions';
import { languageModule } from '@/store/languages';

const logger = getLogger('[ws]');
logger.setLevel('warn');

export const WsMessageTypes = {
  Empty: '',
  Ping: 'ping',
  LicenseAlert: 'license_alert',
  TokenInvalid: 'token_invalid',

  VideoArchiveProgress: 'video_archive_progress',
  VideoArchiveVMStatus: 'video_archive_vm_status',
  CameraVmStatus: 'camera_vm_status',

  EventCreated: 'event_created',
  EventUpdated: 'event_updated',

  MonitoringEventCreated: 'monitoring_event_created',
  MonitoringEventUpdated: 'monitoring_event_updated',
  RemoteMonitoringNotificationAcknowledged: 'remote_monitoring_notification_acknowledged',

  EpisodeOpen: 'episode_open',
  EpisodeUpdated: 'episode_updated',
  EpisodeClose: 'episode_close',
  EpisodeEvent: 'episode_event',

  ClusterCreated: 'cluster_created',
  ClusterUpdated: 'cluster_updated',

  HasUnacknowledged: 'unacknowledged',
  AllEventsAcknowledged: 'events_acknowledged',

  ModelEvent: 'model_event',

  CaseCreated: 'case_created',
  CaseUpdated: 'case_updated',
  CaseDeleted: 'case_deleted',

  CaseClusterCreated: 'case_cluster_created',
  CaseClusterUpdated: 'case_cluster_updated',
  CaseClusterDeleted: 'case_cluster_deleted'
};

const WsEndpoints = {
  events: 'events',
  puppeteerEvents: 'puppet-events'
};

const EmptyObjectData = {
  face: null,
  car: null,
  body: null
};

const EmptyEpisodeData = {
  human: null,
  car: null
};

const WsRefreshTimeout = 3600 * 1e3;

const EmptySubscriptions = {
  ws_cases: false,
  ws_events: false,
  ws_notifications: false,
  ws_unacknowledged_events: true,
  ws_episodes: false,
  ws_clusters: false,
  ws_case_clusters: false
};

type WsSubscriptionsType = typeof EmptySubscriptions;

export class WebsocketModule {
  connected = false;
  time?: Date;
  attempts = 0;

  videoArchivesById: Record<string, VideoArchive> = {};
  episodes: (HumanEpisode | CarEpisode)[] = [];

  monitoringEvent = {};
  updatedMonitoringEvent = {};

  event = cloneDeep(EmptyObjectData);
  eventUpdated = cloneDeep(EmptyObjectData);

  episode: any = cloneDeep(EmptyEpisodeData);
  episodeUpdated: any = cloneDeep(EmptyEpisodeData);

  cluster = cloneDeep(EmptyObjectData);

  licenseMessage = '';

  unacknowledged: any;
  notify: any;
  acknowledgedAll: boolean = true;

  camera = {};
  cameraUpdated = {};
  videoUpdated = {};
  casePhotoUpdated = {};
  remoteMonitoringEventUpdated = {};

  tokenInvalid = false;

  private connectTimeoutIndex = 0;
  private refreshTimeoutIndex = 0;

  subscriptions: WsSubscriptionsType = { ...EmptySubscriptions };

  constructor(public endpoint: string) {
    const bindedSyncCase = this.syncCase.bind(this);
    this.throttledSyncCase = throttleWithArgs(bindedSyncCase, 4000);
  }

  get exceptions() {
    const notifications = NotificationsModule.create();
    const exceptions = ExceptionsModule.create({ language: languageModule, notifications });
    return exceptions;
  }

  get wsUrl(): string {
    return (configModule.serverUrl + this.endpoint + '/').replace('http', 'ws');
  }

  get token() {
    return authModule.token;
  }

  get wsRefreshTimeout() {
    return +localStorage.debugWsRefreshTimeout > 1e3 ? +localStorage.debugWsRefreshTimeout : WsRefreshTimeout;
  }

  connect(): void {
    logger.info('[ws] connect', this.wsUrl, this.token, ', refresh time(ms): ', this.wsRefreshTimeout);
    try {
      if (!this.token) {
        logger.error('[ws] Try to connect without authorization');
      }

      let ws = new WebSocket(this.wsUrl);
      ws.onopen = (...rest) => this.wsOpenHandler(ws, ...rest);
      ws.onclose = (...rest) => this.wsCloseHandler(ws, ...rest);
      ws.onerror = (...rest) => this.wsCloseHandler(ws, ...rest);
    } catch (e) {
      logger.error('[ws:error] in connect ', e);
    }
  }

  wsOpenHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws] connected', this.wsUrl);
    this.connected = true;
    this.time = new Date();
    this.attempts = 0;
    this.updateSubscriptions = () => this.wsUpdateSubscriptions(ws);
    ws.onmessage = (e) => this.wsMessageHandler(ws, e);
    clearTimeout(this.refreshTimeoutIndex);
    this.refreshTimeoutIndex = setTimeout(() => ws.close(), this.wsRefreshTimeout) as any as number;
  }

  resetSubscriptions() {
    this.subscriptions = Object.assign(this.subscriptions, EmptySubscriptions);
  }

  updateSubscriptions() {
    logger.info('[ws:updateSubscriptions] mock method that will be updated');
  }

  wsUpdateSubscriptions(ws: WebSocket) {
    logger.info('[ws:send] updateSubscriptions', this.subscriptions);
    this.wsSend(ws, { type: 'patch_msg_groups', data: { msg_groups: this.subscriptions } });
  }

  wsCloseHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws] closed', this.wsUrl);
    ws.onopen = ws.onclose = ws.onmessage = null;
    this.time = undefined;
    this.connected = false;
    this.attempts++;
    clearTimeout(this.connectTimeoutIndex);
    clearTimeout(this.refreshTimeoutIndex);
    if (this.token) {
      this.connectTimeoutIndex = setTimeout(this.connect.bind(this), 1000) as any as number;
    }
  }

  wsSend(ws: WebSocket, message: any) {
    ws.send(JSON.stringify(message));
  }

  wsMessageHandler(ws: WebSocket, e: MessageEvent) {
    const message = JSON.parse(e.data);
    const messageData: any = message.data;
    logger.info('[ws:messageHandler] ', message.type, message.data);

    switch (message.type) {
      case WsMessageTypes.Empty:
        this.wsSend(ws, { type: 'auth', data: { token: authModule.token, msg_groups: this.subscriptions } });
        break;
      case WsMessageTypes.Ping:
        this.wsSend(ws, { type: 'pong', data: { ...messageData, pong_date: new Date().toISOString() } });
        break;

      case WsMessageTypes.CameraVmStatus:
        this.handleCameraUpdate(message.data);
        break;

      case WsMessageTypes.VideoArchiveProgress:
      case WsMessageTypes.VideoArchiveVMStatus:
        if (messageData['video_archive_id']) messageData.id = Number(messageData['video_archive_id']);
        this.videoUpdated = messageData;
        autoUpdateHelper.updateHandler('/videos/', messageData);
        if (messageData.error) this.displayCaseVideoError(messageData);
        break;

      case WsMessageTypes.EpisodeOpen:
      case WsMessageTypes.EpisodeClose:
      case WsMessageTypes.EpisodeEvent:
        this.handleEpisode(message.data);
        break;

      case WsMessageTypes.EpisodeUpdated:
        this.handleEpisodeUpdate(message.data);
        break;

      case WsMessageTypes.EventCreated:
        this.handleEvent(message.data);
        break;

      case WsMessageTypes.EventUpdated:
        this.handleEventUpdate(message.data);
        break;

      case WsMessageTypes.MonitoringEventCreated:
        this.handleMonitoringEvent(message.data);
        break;

      case WsMessageTypes.MonitoringEventUpdated:
        this.handleUpdateMonitoringEvent(message.data);
        break;

      case WsMessageTypes.ClusterCreated:
      case WsMessageTypes.ClusterUpdated:
        this.handleCluster(message.data);
        break;

      case WsMessageTypes.LicenseAlert:
        this.handleLicenseAlert(message.data);
        break;

      case WsMessageTypes.HasUnacknowledged:
        this.handlerUnacknowledged(message.data);
        break;

      case WsMessageTypes.RemoteMonitoringNotificationAcknowledged:
        this.handleRemoteMonitoringAcknowledgedNotification(message.data);
        break;

      case WsMessageTypes.CaseUpdated:
        autoUpdateHelper.updateHandler('/cases/', message.data?.case);
        break;

      case WsMessageTypes.TokenInvalid:
        this.handleTokenInvalid();
        break;

      case WsMessageTypes.CaseClusterCreated:
        this.handleCaseClusterCreate(message.data?.case_cluster);
        break;

      case WsMessageTypes.CaseClusterUpdated:
        this.handleCaseClusterUpdate(message.data?.case_cluster);
        break;

      case WsMessageTypes.CaseClusterDeleted:
        autoUpdateHelper.deleteHandler('/case-clusters/', message.data?.case_cluster?.id);
        break;

      default:
        logger.log('[ws:message] handler not found for ', message.type, message);
        break;
    }
  }

  handleCaseClusterCreate(value: CaseCluster) {
    autoUpdateHelper.createHandler('/case-clusters/', value); //-> case
    this.syncCaseClusterRelations(value);
  }

  handleCaseClusterUpdate(value: CaseCluster) {
    autoUpdateHelper.updateHandler('/case-clusters/', value);
    this.syncCaseClusterRelations(value);
  }

  syncCaseClusterRelations(value: CaseCluster) {
    this.throttledSyncCase(value.case);
  }

  async throttledSyncCase(id: number) {
    throw new Error('not implemented');
  }

  async syncCase(id: number) {
    const itemViewModel = viewModelRepository.getCasesItemViewModel();
    await itemViewModel.get(id);
    autoUpdateHelper.updateHandler('/cases/', itemViewModel.item);
  }

  handleTokenInvalid() {
    this.tokenInvalid = true;
  }

  handleCameraUpdate(data: any) {
    this.cameraUpdated = data;
  }

  handleVideoUpdate(data: any) {
    this.videoUpdated = data;
  }

  handleRemoteMonitoringAcknowledgedNotification(data: any) {
    this.updatedMonitoringEvent = data?.notification;
  }

  handleEvent(data: any) {
    this.event = data;
  }

  handleEventUpdate(data: any) {
    this.eventUpdated = data;
  }

  handleMonitoringEvent(data: any) {
    this.monitoringEvent = data;
  }

  handleUpdateMonitoringEvent(data: any) {
    this.updatedMonitoringEvent = data;
  }

  handleEpisode(data: any) {
    const { episode_type, episode } = data;
    this.episode = { [episode_type]: episode };
  }

  handleEpisodeUpdate(data: any) {
    const { episode_type, episode } = data;
    this.episodeUpdated = { [episode_type]: episode };
  }

  handleCluster(data: any) {
    this.cluster = data;
  }

  handleLicenseAlert({ message }: { message: string }) {
    this.licenseMessage = message;
  }

  handlerUnacknowledged(data: any) {
    this.unacknowledged = data.all;
    this.notify = data.notify;
    this.acknowledgedAll = !this.unacknowledged;
  }

  displayCaseVideoError(item: VideoArchive) {
    this.exceptions.notifyThrownException(new Error(item.error + ` [video.id: ${item.id}]`), { duration: 0, traceDisabled: true });
    if (item.case) this.syncCase(item.case);
  }
}

export const websocketModule = reactive(new WebsocketModule(WsEndpoints.events));
export const websocketPuppeteerModule = reactive(new WebsocketModule(WsEndpoints.puppeteerEvents));
