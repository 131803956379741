
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CommonPageState } from '@/components/common/page/CommonPageState';

@Options({
  name: 'CommonItem'
})
export default class CommonItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: string;

  @Prop({ type: Object, required: true })
  readonly pageState!: CommonPageState;
}
