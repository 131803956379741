import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "map__button-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonSelect = _resolveComponent("NButtonSelect")!

  return (_ctx.items.length > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_NButtonSelect, {
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _ctx.handleUpdate,
          items: _ctx.items,
          type: "secondary"
        }, null, 8, ["model-value", "onUpdate:modelValue", "items"])
      ]))
    : _createCommentVNode("", true)
}