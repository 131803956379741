
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl/types';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { ActionI18N } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';

@Options({
  name: 'MultisidebarHeaderActions',
  components: { ActionsDropdown, NButton, NDropdown, NDropdownItem },
  emits: ['action']
})
export default class MultisidebarHeaderActions extends Vue {
  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly currentItemAcl!: ItemAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Array, default: () => [] })
  readonly actions!: ActionI18N[];

  @Prop({ type: Boolean, default: false, required: false })
  readonly disableActions!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasToggle!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly hasClose!: boolean;

  @Prop({ type: String, required: true })
  readonly dataQa!: string;

  get hiddenActions() {
    return this.actions.filter((action) => action.hidden);
  }

  get visibleActions() {
    return this.actions.filter((action) => !action.hidden);
  }

  dispatchActionClose() {
    this.dispatchAction(ItemsActionNames.RemoveCurrentItem);
  }

  dispatchActionToggle() {
    this.dispatchAction(ItemsActionNames.ToggleCurrent);
  }

  dispatchAction(action: string) {
    this.$emit('action', action);
  }
}
