
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';

@Options({
  name: 'SortSection',
  components: { SortDropdown, Statistics }
})
export default class SortSection extends Vue {
  @Prop({ type: Object, required: true })
  module!: ListViewModel<any, any>;

  @Prop({ type: Boolean })
  readonly pin: boolean = false;

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }
}
