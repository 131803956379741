
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButton, NInput } from '@/uikit';

@Options({
  name: 'HeaderBar',
  components: { NButton, NInput },
  emits: ['update:searchText']
})
export default class HeaderBar extends Vue {
  @Prop({ type: Number })
  readonly total!: number;

  @Prop({ type: Number, required: true })
  readonly currentCount!: number;

  @Prop({ type: String, default: '' })
  readonly searchText!: string;

  @Prop({ type: Boolean, default: false })
  readonly addButton!: boolean;

  addNewGroup() {
    this.$emit('add');
  }

  dispatchUpdateSearchText(searchText: string): void {
    this.$emit('update:searchText', searchText);
  }
}
