
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { applicationModule } from '@/store/application';
import { ObjectsMultiToSingle, ObjectsType, ObjectType } from '@/store/application/data.assets';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { PagePaths, PageState, PageTypes } from '@/store/application/page.definitions';
import { PageViewModel } from '@/store/application/page.view.model';
import { satisfyClusterFilter } from '@/store/clusters/satisfy.cluster.filter';
import { CommonCluster, CommonClusterFilter } from '@/store/clusters/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import { websocketModule } from '@/store/ws/websocket.module';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NDoubleButton from '@/uikit/buttons/NDoubleButton.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NTable from '@/uikit/table/NTable.vue';
import { isDefined } from '@/common/utils';
import ClusterItemProxy from '@/components/clusters/ClusterItemProxy.vue';
import { AnyCluster, ClusterOpenedItems } from '@/components/clusters/types';
import { smallFilterManagerModule } from '@/components/common/filter/filter-manager/SmallFiltersModule';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import FilterManager from '@/components/common/filter/manager/FilterManager.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import ClustersSidebar from '@/pages/clusters/ClustersSidebar.vue';
import { getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import ListPage from '@/pages/ListPage.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';

@Options({
  name: 'ClustersPage',
  components: {
    ClusterItemProxy,
    ClustersSidebar,
    FilterManager,
    FilterSection,
    FiltersBig,
    FiltersSmall,
    ListPage,
    NButton,
    NButtonGroup,
    NDoubleButton,
    NForm,
    NTable,
    PageContentLayout,
    SortDropdown,
    Statistics
  }
})
export default class ClustersPage extends Vue {
  pageVM: PageViewModel<any, any> = new PageViewModel({ tab: String(Math.random()), path: PagePaths.Clusters });
  intersectionResultItem?: IntersectionResultItem | null = null;

  @Prop({ type: String, required: true })
  tab!: string;

  get state(): PageState {
    return this.pageVM.state;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get pageSidebarType() {
    const { pageType, objectType } = this.state;
    return `${pageType}_${objectType}` as MultisidebarItemType;
  }

  get cardsSidebarType() {
    return `${PageTypes.Cards}_${this.cardType}`;
  }

  get cardType() {
    return dataAssetsModule.getCardTypeByObjectType(this.state.objectType!);
  }

  get currentItemId() {
    return this.sidebarModule.currentItem?.id;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get module(): ListViewModel<any, any> {
    return this.pageVM.module;
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ created_date: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get bigFilterLayout() {
    const { pageType, objectType } = this.state;
    return getClusterFiltersBuilder({ small: false, cardType: this.cardType }).getFilterByType(pageType, objectType!);
  }

  get smallFilterLayout() {
    const { pageType, objectType } = this.state;
    return getClusterFiltersBuilder({ small: true, cardType: this.cardType }).getFilterByType(pageType, objectType!);
  }

  get hasCustomOrdering() {
    const ordering = this.module.filter.current.ordering;
    return ordering && ordering !== '-id' && ordering !== '-created_date';
  }

  get websocketModule() {
    return websocketModule;
  }

  @Watch('module')
  changeModuleHandler(v: any, p: any) {
    if (v) autoUpdateHelper.addListInstance(v);
    if (p && v) this.copyCommonFilters(p.filter.current, v.filter.current);
    this.intersectionResultItem?.reset();
    autoUpdateHelper.addListInstance(v);
  }

  @Watch('module.loading')
  loadingHandler(v: boolean, p: boolean) {
    if (!v) {
      this.intersectionResultItem?.reset();
      this.intersectionResultItem?.syncObservableTargetsNextTick();
    }
  }

  @Watch('module.appending')
  appendingHandler(v: boolean, p: boolean) {
    if (!v) this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('module.filter.current.ordering')
  changeOrderingHandler() {
    this.module.get();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    if (v?.limit !== p.limit) return;
    this.module.debouncedGet();
  }

  @Watch('websocketModule.cluster', { deep: true })
  websocketEventHandler({ cluster, cluster_type }: { cluster: CommonCluster; cluster_type: ObjectType }) {
    const canAdd = this.state.playing && !this.hasCustomOrdering;
    if (!canAdd) return;
    const objectType = ObjectsMultiToSingle[this.state.objectType || ''];
    if (cluster_type !== objectType) return;
    const satisfy = satisfyClusterFilter(cluster, this.module.filter.current, this.state.objectType as ObjectsType);
    if (!satisfy) {
      console.warn('Cluster is not satisfied: ', cluster, this.module.filter.current);
      return;
    }

    const existing = this.doesItemExist(cluster.id);
    isDefined(existing) ? Object.assign(existing, cluster) : this.module.items.unshift(cluster);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  togglePlaying() {
    this.module.filter.current.created_date_lte = this.pageVM.state.playing ? new Date().toISOString() : undefined;
    this.pageVM.togglePlaying();
  }

  limitItemsCount() {
    const scrollTop = this.$refs.listPage?.getScrollTop();
    const limit = 100;
    const allowedScrollValue = scrollTop >= 0 && scrollTop <= 200;
    const isLimitExceeded = this.module.items.length > limit * 2;
    if (allowedScrollValue && isLimitExceeded) this.module.items.splice(limit, 1e6);
  }

  doesItemExist(id: number) {
    return this.module.items.find((item: CommonCluster) => item.id === id) ?? null;
  }

  copyCommonFilters(from: CommonClusterFilter, to: CommonClusterFilter) {
    const items: Array<keyof CommonClusterFilter> = ['camera_groups', 'cameras', 'case_in', 'episodes', 'has_card', 'pinned', 'matched_lists'];
    items.forEach((name) => {
      to[name] = from[name] as any;
    });
  }

  getOpenedTypeByCluster(cluster: AnyCluster) {
    if (!this.currentItemId) return '';
    if (this.currentItemId === generateMultisidebarId(this.pageSidebarType, cluster.id)) return ClusterOpenedItems.Cluster;
    if (this.currentItemId === generateMultisidebarId(this.cardsSidebarType, cluster.card)) return ClusterOpenedItems.Card;
  }

  created() {
    this.initPageViewModel();
  }

  mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.cluster-item-proxy',
      id: this.tab
    });
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  initPageViewModel() {
    this.pageVM = new PageViewModel({ tab: this.tab, path: PagePaths.Clusters });
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  async actionHandler(id: number, action: string, payload?: any): Promise<void> {
    if (action === ItemsActionNames.ShowInteractions) {
      RouterModule.navigateToInteractions({ id_in: id });
      return;
    }

    if (action === ItemsActionNames.ShowInfo) {
      await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
      return;
    }

    if (action === ItemsActionNames.ShowPlayer) {
      const best_event = payload?.best_event;
      if (best_event) {
        const timeFrom = new Date(best_event.created_date).getTime() / 1000;
        this.$videoPlayer.playArchive(best_event.camera, timeFrom - 3);
      }
      return;
    }

    if (action === ItemsActionNames.ShowFullScreen) {
      const best_event = payload?.best_event;
      if (best_event) {
        this.$photoViewer.show(best_event);
      }
      return;
    }

    switch (action) {
      case ItemsActionNames.Acknowledge:
        // await this.acknowledgeHandler(id, payload);
        break;

      case ItemsActionNames.NavigateToCard:
        this.navigateToCard(payload.id);
        break;
    }
  }

  navigateToCard(id: string | number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: this.cardsSidebarType, rawItem: id });
  }
}
