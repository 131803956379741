
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import NForm from '@/uikit/forms/NForm.vue';
import { informationLayout1, informationLayout2 } from './schema';

@Options({
  name: 'CaseClusterForm',
  components: { NForm }
})
export default class CaseClusterForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: any;

  @Prop({ type: Boolean, default: false })
  showAcknowledge!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  get informationLayout() {
    return this.showAcknowledge ? [...informationLayout1, ...informationLayout2] : informationLayout1;
  }

  readonly module = viewModelRepository.getCasesItemViewModel();

  get state() {
    return {
      caseCluster: this.item,
      case: this.module.item
    };
  }

  validate() {
    return this.$refs.form.validateAndDisplayErrors();
  }

  mounted() {
    const caseId = this.item?.case;
    if (caseId) {
      this.module.get(caseId);
    }
  }
}
