import { reactive } from 'vue';
import { ListViewModel } from '@/definitions/view-models';
import { viewModelRepository } from '@/api/common';
import { aclModule } from '@/store/acl';
import { CardTypesMap, ObjectsMultiToSingle, ObjectsTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageNames, PageTypes, SearchPageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';

export class SearchResultsViewModel {
  constructor(public state: SearchPageState) {
  }

  get isCaseClustersPage() {
    return this.state.pageType === PageTypes.CaseClusters;
  }

  get isCardsPage() {
    return this.state.pageType === PageTypes.Cards;
  }

  get module(): ListViewModel<any, any> {
    let result = pageModule.getPageModule(this.state, { defaultFilters: { limit: 100 } });
    return result as unknown as ListViewModel<any, any>;
  }

  get searchPageTypes() {
    return dataAssetsModule.searchPageTypes.filter((item) => this.state.objectType !== ObjectsTypesMap.Cars || item.value !== PageTypes.Clusters);
  }

  get looksLike(): string[] {
    const ids = (this.state.detectionId || '').split(', ').filter((v) => !!v);
    if (!ids?.length) return [];
    if (this.state.searchFrom === 'cards') return this.state.cardLooksLike || [];

    const filePrefix = `detection:`;
    const eventPrefix = `${ObjectsMultiToSingle[this.state.objectType]}event:`;
    const clusterPrefix = `${ObjectsMultiToSingle[this.state.objectType]}cluster:`;
    const caseClusterPrefix = `casecluster:`;
    let prefix = '';

    switch (this.state.searchFrom) {
      case 'file':
        prefix = filePrefix;
        break;
      case 'events':
        prefix = eventPrefix;
        break;
      case 'clusters':
        prefix = clusterPrefix;
        break;
      case PageNames.CaseClusters:
        prefix = caseClusterPrefix;
        break;
      default:
        return [];
    }
    return ids.map((id) => prefix + id);
  }

  get modelAcl() {
    if (this.isCardsPage) {
      this.module.aclModelName = this.state.cardType === CardTypesMap.Humans ? 'humancard' : 'carcard';
      return aclModule.getModelAcl(this.module);
    }
    return {};
  }

  static create(state: SearchPageState) {
    return reactive(new SearchResultsViewModel(state));
  }
}

