import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "n-slider__body" }
const _hoisted_2 = {
  key: 0,
  class: "n-slider__header label-m"
}
const _hoisted_3 = {
  class: "n-slider__content",
  ref: "content"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NHint = _resolveComponent("NHint")!
  const _component_NInputNumber = _resolveComponent("NInputNumber")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cssClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.header) + " " + _toDisplayString(_ctx.barStyle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "n-slider__track",
          ref: "track",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)), ["stop","prevent"])),
          onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
        }, null, 544),
        _createElementVNode("div", {
          class: "n-slider__thunk",
          ref: "thunk",
          tabindex: "0",
          onMousedown: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mouseDownHandler && _ctx.mouseDownHandler(...args)), ["stop","prevent"])),
          onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.focusHandler && _ctx.focusHandler(...args))),
          onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args))),
          onKeydown: [
            _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.keydownRightHandler && _ctx.keydownRightHandler(...args)), ["stop","prevent"]), ["right"])),
            _cache[6] || (_cache[6] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.keydownLeftHandler && _ctx.keydownLeftHandler(...args)), ["stop","prevent"]), ["left"]))
          ],
          style: _normalizeStyle(_ctx.barStyle)
        }, [
          _createVNode(_component_NHint, {
            content: _ctx.tooltip,
            class: "tooltip",
            "visible-mode": "manual",
            "is-visible": _ctx.drag
          }, null, 8, ["content", "is-visible"])
        ], 36)
      ], 512),
      (_ctx.hasInput)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_NInputNumber, {
              min: _ctx.min,
              max: _ctx.max,
              step: _ctx.computedStep,
              modelValue: _ctx.modelValue,
              disabled: _ctx.disabled,
              textAlign: "right",
              "onUpdate:modelValue": _ctx.setValue
            }, null, 8, ["min", "max", "step", "modelValue", "disabled", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}