import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "map",
  ref: "mapContainer"
}
const _hoisted_2 = { class: "map__controls" }
const _hoisted_3 = {
  key: 0,
  class: "map__button-wrapper"
}
const _hoisted_4 = {
  key: 1,
  class: "map__button-wrapper"
}
const _hoisted_5 = {
  key: 2,
  class: "map__button-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LTileLayer = _resolveComponent("LTileLayer")!
  const _component_LMap = _resolveComponent("LMap")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LMap, {
      options: _ctx.options,
      onReady: _ctx.handleMapReady
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LTileLayer, { url: _ctx.tileUrl }, null, 8, ["url"]),
        (_ctx.map)
          ? _renderSlot(_ctx.$slots, "default", {
              key: 0,
              map: _ctx.map
            })
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["options", "onReady"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.toTargetOn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NButton, {
              type: "secondary",
              icon: "target",
              size: "big",
              onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toTarget', _ctx.map)))
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.locateOn && _ctx.locateAvailable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_NButton, {
              type: "secondary",
              icon: "location-nav",
              size: "big",
              onAction: _ctx.locate
            }, null, 8, ["onAction"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.zoomOn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_NButtonGroup, {
              class: "map__zoom",
              items: _ctx.zoomItems,
              "button-size": "big",
              "onUpdate:modelValue": _ctx.handleZoom
            }, null, 8, ["items", "onUpdate:modelValue"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}