export default {
  male: 'man',
  female: 'woman',
  male_pf: '{ count, plural, one {# man} other {# men}}',
  female_pf: '{ count, plural, one {# woman} other {# women}}',
  returning: 'returning',
  new: 'new',
  records_pf: '{ count, plural, one {# record} other {# records}}',
  age_pf: '{ count, plural, one {# year} other {# years}}',
  visitors_pf: '{ count, plural, one {# visitor} other {# visitors}}',
  visits_pf: '{ count, plural, one {# visit} other {# visits}}',
  new_visits_pf: '{ count, plural, one {# new visit} other {# new visits}}',
  returning_visits_pf: '{ count, plural, one {# returning visit} other {# returning visits}}',
  precision: 'scale interval',
  return_interval: 'minimum time between visits',
  returns_number: 'number of returns',
  visitors: 'visitors',
  visits: 'visits',
  enable: 'enable',
  gender: 'gender',
  average_age: 'Average age',
  cameras: 'cameras',
  total: 'total',
  unique: 'unique'
};
