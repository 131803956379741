import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-input" }
const _hoisted_2 = { class: "filter-input__label control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_NInput, {
      class: "filter-input__input",
      plain: true,
      placeholder: _ctx.placeholder || _ctx.$t('common.enter'),
      alphabet: _ctx.alphabet,
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      clearable: true,
      "data-qa": _ctx.dataQa
    }, null, 8, ["placeholder", "alphabet", "modelValue", "data-qa"])
  ]))
}