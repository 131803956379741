
import { defineComponent } from 'vue';
import { bool, object } from 'vue-types';
import { Exception } from '@/store/application/exceptions';
import { LanguageModule } from '@/store/languages';

export default defineComponent({
  name: 'ExceptionNotification',
  props: {
    exception: object<Exception>().isRequired,
    language: object<LanguageModule>().isRequired,
    traceDisabled: bool().def(false)
  },
  computed: {
    i18n() {
      return this.language.getTranslatedToken;
    }
  }
});
