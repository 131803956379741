/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RemoteMonitoringRecord = {
    readonly id: number;
    /**
     * Creator ID
     */
    readonly created_by: number;
    /**
     * Remote monitoring record creation date
     */
    readonly created_date: string;
    /**
     * Remote monitoring record modified date
     */
    readonly modified_date: string;
    /**
     * Remote monitoring record reason
     */
    reason: string;
    /**
     * Remote monitoring record card
     */
    card?: number;
    watch_list?: number;
    readonly is_deleted: boolean;
    /**
     * Owning role
     */
    readonly owner: number;
};
export const EmptyRemoteMonitoringRecord: RemoteMonitoringRecord = {
    id: 0,
    /**
     * Creator ID
     */
    created_by: 0,
    /**
     * Remote monitoring record creation date
     */
    created_date: '',
    /**
     * Remote monitoring record modified date
     */
    modified_date: '',
    /**
     * Remote monitoring record reason
     */
    reason: '',
    /**
     * Remote monitoring record card
     */
    card: 0,

    watch_list: undefined,

    is_deleted: false,
    /**
     * Owning role
     */
    owner: 0,
};
