import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "license-extra" }
const _hoisted_2 = { class: "license-extra__name heading-m" }
const _hoisted_3 = { class: "license-extra__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseTag = _resolveComponent("LicenseTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('license.extra_name')), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_LicenseTag, { key: item }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getLabel(item)), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}