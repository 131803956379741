
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CardConfidence from '@/components/cards/CardConfidence.vue';

@Options({
  name: 'CardGroupRow',
  components: { CardConfidence }
})
export default class CardGroupRow extends Vue {
  @Prop({ type: Number, required: true })
  readonly confidence!: number;
}
