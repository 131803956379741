import { cloneDeep } from 'lodash';
import { getLogger } from 'loglevel';
import { reactive } from 'vue';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { AutoUpdateHelper, autoUpdateHelper } from '@/api/common/auto-update-helper';
import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import { AnnexAlarmModelName } from '@/thirdpaties/annex/annex.module';

const logger = getLogger('[ws:annex]');
logger.setLevel('warn');

type AnnexMessage = {
  service: string;
  payload?: {
    // send to AUTH
    secret?: any;

    // response to AUTH
    error?: string;
    user?: string;

    // if action = SIG / ACK
    order?: {
      action?: string;
      payload?: any;
    };
    commit?: any;
  };
};

export const AnnexWsActions = {
  Ping: 'ping',
  Ack: 'ACK',
  Sig: 'SIG'
};

export const AnnexWsServiceTypes = {
  Auth: 'AUTH',
  Alarmer: 'ALARMER'
};

const WsEndpoints = {
  Alerts: ''
};

const WsRefreshTimeout = 3600 * 1e3;

export class AnnexWebsocketModule {
  connected = false;
  time?: Date;
  attempts = 0;

  alarm?: Partial<AnnexAlarm> | null = null;
  onNewItemReceived!: (item: AnnexAlarm) => void;

  unacknowledged: any;
  notify: any;
  acknowledgedAll: boolean = true;
  tokenInvalid = false;

  private connectTimeoutIndex = 0;
  private refreshTimeoutIndex = 0;

  constructor(public endpoint: string) {}

  get wsUrl(): string {
    const annexWsUrl = '/annex/';
    let result = annexWsUrl.startsWith('ws://') ? annexWsUrl : configModule.serverUrl.replace('http', 'ws') + annexWsUrl;
    return result;
  }

  get token() {
    return authModule.token;
  }

  get wsRefreshTimeout() {
    return +localStorage.debugWsRefreshTimeout > 1e3 ? +localStorage.debugWsRefreshTimeout : WsRefreshTimeout;
  }

  connect(): void {
    logger.info('[ws:annex] connect', this.wsUrl, this.token, ', refresh time(ms): ', this.wsRefreshTimeout);
    try {
      if (!this.token) {
        logger.error('[ws:annex] Try to connect without authorization');
      }

      let ws: WebSocket = new WebSocket(this.wsUrl);
      ws.onopen = (...rest) => this.wsOpenHandler(ws, ...rest);
      ws.onclose = (...rest) => this.wsCloseHandler(ws, ...rest);
      ws.onerror = (...rest) => this.wsCloseHandler(ws, ...rest);
    } catch (e) {
      logger.error('[ws:annex:error] in connect ', e);
    }
  }

  wsOpenHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws:annex] connected', this.wsUrl);
    this.connected = true;
    this.time = new Date();
    this.attempts = 0;
    ws.onmessage = (e) => this.wsMessageHandler(ws, e);
    this.wsSend(ws, this.getAuthMessage());
    this.sendMessage = (v) => this.wsSend(ws, v);
    clearTimeout(this.refreshTimeoutIndex);
    this.refreshTimeoutIndex = setTimeout(() => ws.close(), this.wsRefreshTimeout) as any as number;
  }

  getAckMessage(payload: Partial<AnnexAlarm>) {
    const computedPayload: any = {
      ddp_id: payload.ddp_id,
      ack_value: payload.ack_value,
      ack_author: payload.ack_author,
      comment: payload.comment
    };

    Object.keys(computedPayload).forEach((key: string) => {
      if (computedPayload[key] === undefined || computedPayload[key] === null) delete computedPayload[key];
    });

    const msgPayload = {
      order: {
        action: 'ACK',
        payload: computedPayload
      }
    };
    return {
      service: AnnexWsServiceTypes.Alarmer,
      payload: msgPayload
    };
  }

  getAuthMessage(): AnnexMessage {
    return { service: AnnexWsServiceTypes.Auth, payload: { secret: { tag: 'TOKEN', token: authModule.token } } };
  }

  wsCloseHandler(ws: WebSocket, ...rest: any[]) {
    logger.info('[ws:annex] closed', this.wsUrl);
    ws.onopen = ws.onclose = ws.onmessage = null;
    this.time = undefined;
    this.connected = false;
    this.attempts++;
    clearTimeout(this.connectTimeoutIndex);
    clearTimeout(this.refreshTimeoutIndex);
    if (this.token) {
      this.connectTimeoutIndex = setTimeout(this.connect.bind(this), 1000) as any as number;
    }
  }

  sendMessage(payload: any) {
    logger.info('[ws:sendMessage] mock method that will be updated');
  }

  wsSend(ws: WebSocket, message: any) {
    ws.send(JSON.stringify(message));
  }

  wsMessageHandler(ws: WebSocket, e: MessageEvent) {
    const message: AnnexMessage = JSON.parse(e.data);
    const orderAction = message?.payload?.order?.action;
    const orderPayload = message?.payload?.order?.payload || {};
    const hasAccessOrderToPayload = !!Object.keys(orderPayload).length;
    logger.info('[ws:annex:messageHandler] ', message, orderPayload);

    switch (message.service) {
      case AnnexWsServiceTypes.Auth:
        this.tokenInvalid = !!message.payload?.error;
        break;
      case AnnexWsServiceTypes.Alarmer:
        if (!hasAccessOrderToPayload) break;
        this.alarm = orderPayload;
        if (orderAction === AnnexWsActions.Ack) {
          autoUpdateHelper.updateHandler(AnnexAlarmModelName, orderPayload);
        } else if (orderAction === AnnexWsActions.Sig) {
          // @todo refactor this on backend
          Object.assign(orderPayload, message?.payload?.commit || {});

          if (this.onNewItemReceived instanceof Function) {
            this.onNewItemReceived(orderPayload);
            return;
          }

          autoUpdateHelper.createHandler(AnnexAlarmModelName, orderPayload);
        }
        break;
      default:
        logger.warn('[ws:annex:messageHandler] unsupported service', message.service);
        break;
    }
    /*    switch (message.type) {
      case AnnexWsMessageTypes.Ping:
        this.wsSend(ws, { type: 'pong', data: { ...messageData, pong_date: new Date().toISOString() } });
        break;

      case AnnexWsMessageTypes.AlertIn:
        // this.handleCameraUpdate(message.data);
        break;

      case AnnexWsMessageTypes.UpdateIn:
        // this.handleCameraUpdate(message.data);
        break;

      default:
        logger.log('[ws:annex:message] handler not found for ', message.type, message);
        break;
    }*/
  }
}

export const annexWebsocketModule = reactive(new AnnexWebsocketModule(WsEndpoints.Alerts)) as AnnexWebsocketModule;
