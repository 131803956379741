
import { defineComponent } from 'vue';
import { aclModule } from '@/store/acl';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import type { Action } from '@/uikit/types';

export default defineComponent({
  name: 'ReportsPageActions',
  components: { NButtonGroup },
  emits: ['delete', 'download', 'select-all', 'unselect-all', 'update'],
  computed: {
    hasDelete() {
      return aclModule.getAccess('ffsecurity.delete_report');
    },
    hasUpdate() {
      return aclModule.getAccess('ffsecurity.change_report');
    },
    actions(): Action[] {
      const actions = [
        {
          handler: this.dispatchDownloadEvent,
          label: this.$t('reports.download', 'f'),
          name: 'download'
        }
      ];

      const updateAction = {
        handler: this.dispatchUpdateEvent,
        label: this.$t('reports.update', 'f'),
        name: 'update'
      };

      const deleteAction = {
        handler: this.dispatchDeleteEvent,
        label: this.$t('reports.delete', 'f'),
        name: 'delete'
      };

      this.hasUpdate && actions.push(updateAction);
      this.hasDelete && actions.push(deleteAction);

      return actions;
    }
  },
  methods: {
    dispatchDeleteEvent(): void {
      this.$emit('delete');
    },
    dispatchDownloadEvent(): void {
      this.$emit('download');
    },
    dispatchUpdateEvent(): void {
      this.$emit('update');
    }
  }
});
