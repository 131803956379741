import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "annex-alarm-features" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (feature && feature.result)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "annex-alarm-features__feature",
              title: feature.result.confidence
            }, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`annex_alarms.features.${_ctx.slug(feature.model)}`)) + ":", 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t(`annex_alarms.features.${_ctx.slug(feature.result.name)}`)), 1)
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}