import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "annex-alarm-acknowledge__icon"
}
const _hoisted_2 = {
  key: 2,
  class: "annex-alarm-acknowledge__icon"
}
const _hoisted_3 = {
  key: 3,
  class: "annex-alarm-acknowledge__icon"
}
const _hoisted_4 = {
  key: 4,
  class: "annex-alarm-acknowledge__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["annex-alarm-acknowledge", _ctx.classes])
  }, [
    (_ctx.status === 'none')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_NButton, {
            type: "primary",
            icon: "annex_success",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('action', 'ack_success')), ["stop"]))
          }),
          _createVNode(_component_NButton, {
            type: "secondary",
            icon: "annex_failure",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('action', 'ack_failure')), ["stop"]))
          })
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.status === 'in_work')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_NIcon, { name: "annex_in_work" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'cancel')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NIcon, { name: "annex_cancel" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'acknowledged')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_NIcon, { name: "annex_done_all" })
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'auto_acknowledged')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_NIcon, { name: "annex_done_all_auto" })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}