import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelect = _resolveComponent("NSelect")!

  return (_openBlock(), _createBlock(_component_NSelect, {
    "prefix-icon": "search",
    "close-icon": !!_ctx.selected,
    "search-items": _ctx.delayedSearch,
    "model-value": _ctx.selected,
    "hide-chevron": "",
    "key-field": "",
    onClose: _ctx.clear,
    "onUpdate:modelValue": _ctx.handleSelectResult
  }, null, 8, ["close-icon", "search-items", "model-value", "onClose", "onUpdate:modelValue"]))
}