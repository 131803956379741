import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "annex-alarms-page__empty" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonPage = _resolveComponent("CommonPage")!
  const _component_AlertWizard = _resolveComponent("AlertWizard")!
  const _component_SettingsPageLayout = _resolveComponent("SettingsPageLayout")!

  return (_openBlock(), _createBlock(_component_SettingsPageLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_CommonPage, {
        class: "annex-alarms-page",
        state: _ctx.commonPageState
      }, {
        "items-empty": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              width: "198",
              height: "158",
              src: require('@/pages/annex/assets/undraw_warning_re_eoyh 1.svg')
            }, null, 8, _hoisted_2),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('alerts.empty_list')), 1)
          ])
        ]),
        _: 1
      }, 8, ["state"]),
      (_ctx.showNewItemWizard && _ctx.newItemModule)
        ? (_openBlock(), _createBlock(_component_AlertWizard, {
            key: 0,
            item: _ctx.newItemModule.item,
            onClose: _ctx.closeWizard,
            onSave: _ctx.saveItem
          }, null, 8, ["item", "onClose", "onSave"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}