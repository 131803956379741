import { AuthSession } from '@/api';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import IconWithHint from '@/components/common/IconWithHint.vue';
import SessionsItem, { SessionsItemTypes } from '@/components/common/SessionsItem.vue';

export const columns: ITableColumn[] = [
  {
    // width: '260px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'common.uuid',
        name: 'uuid'
      }
    },
    content: {
      path: 'uuid',
      component: SessionsItem,
      props: (item: AuthSession) => ({ name: item.uuid, type: SessionsItemTypes.Small })
    }
  },
  {
    header: {
      classes: ['align-center', 'bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.user', name: 'user__username', sortable: true }
    },
    content: {
      path: 'user',
      classes: ['align-center'],
      component: SessionsItem,
      props: (item: AuthSession) => ({ name: item.user__username })
    }
  },
  {
    header: {
      classes: ['align-center', 'bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.device', name: 'mobile', sortable: true }
    },
    content: {
      path: 'device',
      classes: ['align-center'],
      component: IconWithHint,
      props: (item: AuthSession) => ({ name: item.mobile ? 'smartphone' : 'computer', content: item.device_info, maxWidth: '243' })
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.ip', name: 'ip' }
    },
    content: {
      path: 'ip',
      classes: ['align-items-center'],
      component: SessionsItem,
      props: (item: AuthSession) => ({ name: item.ip, type: SessionsItemTypes.Small })
    }
  },
  {
    header: {
      classes: ['align-center', 'bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.status', name: 'status', sortable: true }
    },
    content: {
      path: 'status',
      classes: ['align-center'],
      component: SessionsItem,
      props: (item: AuthSession) => ({ i18n_name: `sessions.${item.status}`, i18nName: item.status })
    }
  },
  {
    width: '110px',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: { i18n_label: 'common.last_ping', name: 'last_ping_date', sortable: true, align: 'left' }
    },
    content: {
      path: 'last_ping_date',
      component: NDateTimeLabel,
      classes: ['align-left'],
      props: {
        multiline: true,
        size: 'xs'
      }
    }
  }
];
