
import router from '@/router';
import { Options, Vue } from 'vue-class-component';
import { PagePaths } from '@/store/application/page.definitions';
import { languageModule } from '@/store/languages';
import { RouterModule } from '@/store/router';
import { NButton } from '@/uikit';
const t = languageModule.getTranslatedToken;

@Options({
  name: 'ReportNotification',
  components: { NButton }
})
export default class ReportNotification extends Vue {
  clickHandler() {
    router.push(RouterModule.getRouteLocation({ path: PagePaths.Reports }));
  }
  get successfulReportCreationMessage() {
    return t('reports.successful_report_creation');
  }
  get createLabel() {
    return t('reports.reports_page');
  }
}
