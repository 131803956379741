import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import { VideoWallPageState } from '@/pages/video-wall/types';

export const DisplayTypes = {
  Default: 'default',
  Short: 'short',
  Full: 'full',
  Table: 'table',
  Map: 'map'
} as const;

export type DisplayType = typeof DisplayTypes[keyof typeof DisplayTypes];

export const PageTypes = {
  Alarms: 'alarms',
  Analytics: 'analytics',
  AuditLogs: 'audit-logs',
  BlockList: 'block-list',
  CameraGroups: 'camera-groups',
  Cameras: 'cameras',
  Cards: 'cards',
  Case: 'case',
  CaseVideos: 'case-videos',
  Cases: 'cases',
  CaseClusters: 'case_clusters',
  CaseParticipants: 'case_participants',
  ClusterEvents: 'cluster-events',
  Clusters: 'clusters',
  CounterRecords: 'counter-records',
  Counters: 'counters',
  Episodes: 'episodes',
  Events: 'events',
  ExternalDetectors: 'external-detectors',
  ExternalSearch: 'external-search',
  ExternalVms: 'external-vms',
  CustomReports: 'custom-reports',
  ExternalVmsCameras: 'external-vms-cameras',
  Alerts: 'alert-rules',
  Hooks: 'hooks',
  Interactions: 'interactions',
  Interface: 'interface',
  License: 'license',
  Lines: 'lines',
  None: 'none',
  Participants: 'participants',
  RemoteMonitoringEvents: 'remote-monitoring-events',
  RemoteDailyEvents: 'remote-daily-events',
  Reports: 'reports',
  Roles: 'roles',
  Search: 'search',
  Sessions: 'sessions',
  Users: 'users',
  Verify: 'verify',
  VideoWall: 'video-wall',
  Videos: 'videos',
  WatchLists: 'watch-lists',
  DevTools: 'devtools',
  UiComponents: 'uicomponents'
} as const;

export const PageNames = {
  Alarms: PageTypes.Alarms,
  Analytics: PageTypes.Analytics,
  AuditLogs: PageTypes.AuditLogs,
  BlockList: PageTypes.BlockList,
  CameraGroups: PageTypes.CameraGroups,
  Cards: PageTypes.Cards,
  Case: PageTypes.Case,
  Cases: PageTypes.Cases,
  CaseClusters: PageTypes.CaseClusters,
  CaseParticipants: PageTypes.CaseParticipants,
  Clusters: PageTypes.Clusters,
  Counters: PageTypes.Counters,
  DataSources: 'data-sources',
  Events: PageTypes.Events,
  ExternalSearch: PageTypes.ExternalSearch,
  ExternalVms: PageTypes.ExternalVms,
  CustomReports: PageTypes.CustomReports,
  Alerts: PageTypes.Alerts,
  Hooks: PageTypes.Hooks,
  Interactions: PageTypes.Interactions,
  Interface: PageTypes.Interface,
  Launcher: 'launcher',
  License: PageTypes.License,
  Lines: PageTypes.Lines,
  Login: 'login',
  NotFound: 'not-found',
  Participants: PageTypes.Participants,
  RemoteMonitoringEvents: PageTypes.RemoteMonitoringEvents,
  RemoteDailyEvents: PageTypes.RemoteDailyEvents,
  Reports: PageTypes.Reports,
  Roles: PageTypes.Roles,
  Search: PageTypes.Search,
  Sessions: PageTypes.Sessions,
  Settings: 'settings',
  Users: PageTypes.Users,
  Verify: PageTypes.Verify,
  VideoWall: PageTypes.VideoWall,
  WatchLists: PageTypes.WatchLists,
  DevTools: PageTypes.DevTools,
  UiComponents: PageTypes.UiComponents,
  BIApp: 'bi-app',
  AlarmApp: 'alarm-app',
  ApiDocumentation: 'api-docs',
  Webhooks: 'webhooks'
} as const;

export type PageName = typeof PageNames[keyof typeof PageNames];
export type SearchFrom = typeof PageNames.Events | typeof PageNames.Cards | typeof PageNames.Clusters | typeof PageNames.CaseClusters | 'file';

export type ListModelCreationOptions = {
  defaultFilters: any;
};

function generatePathsFromRoutes(routes: any): PagePathsMap {
  const pathsMap: any = { Launcher: '/' };
  for (const key in routes) {
    if (key !== 'Launcher' && key !== 'NotFound') {
      pathsMap[key] = `/${routes[key]}`;
    }
  }

  return pathsMap;
}

type PagePathsMap = Omit<{ [K in keyof typeof PageNames]: `/${typeof PageNames[K]}` }, 'NotFound' | 'Launcher'> & { Launcher: '/' };
export type PagePath = PagePathsMap[keyof PagePathsMap];
export const PagePaths: PagePathsMap = generatePathsFromRoutes(PageNames);

export type PageRoute = typeof PageNames[keyof typeof PageNames];
export type PageType = typeof PageTypes[keyof typeof PageTypes] | string;

export type PageState = {
  tab: string;
  pagePath: PagePath; // /data-sources
  pageType: PageType; // cameras | videos | external-detectors
  cardType?: string;
  objectType?: string;
  episodeType?: string;
  displayType?: DisplayType;
  id?: string | number;
  filter?: Record<string, any>;
  defaultAction?: string;
  showLeftBar?: boolean;
  showOverlay?: boolean;
  showMainHeader?: boolean;
  playing?: boolean;
  searchFrom?: SearchFrom;
  subPage?: string;
  additionalId?: number;
  caseClusterFilters?: number;
};

export type SearchPageState = PageState & {
  cardType: string;
  objectType: string;
  episodeType: string;
  detectionId?: string;
  cardLooksLike?: string[];
};

export type EventsPageState = PageState & {
  objectType: string;
  episodeType: string;
  displayType: DisplayType;
};

export const CustomReportsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.CustomReports,
  pageType: PageTypes.CustomReports
};

export const EmptyDefaultPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Launcher,
  pageType: PageTypes.None
};

export const EmptyLinesPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Lines,
  pageType: PageTypes.Lines
};

export const EmptyDatasourcePageState: PageState = {
  tab: '',
  pagePath: PagePaths.DataSources,
  displayType: DisplayTypes.Full,
  pageType: PageTypes.Cameras
};

export const EmptyAlarmsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Alarms,
  pageType: PageTypes.Alarms
};

export const EmptyEventPageState: EventsPageState = {
  tab: '',
  pagePath: PagePaths.Events,
  pageType: PageTypes.Episodes,
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayTypes.Default,
  defaultAction: EventDetails.ShowInfo,
  playing: true
};

export const EmptyCardPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Cards,
  pageType: PageTypes.Cards,
  cardType: 'humans',
  displayType: DisplayTypes.Default,
  defaultAction: EventDetails.ShowInfo
};

export const EmptyClusterPageState: PageState = {
  tab: '',
  playing: true,
  pagePath: PagePaths.Clusters,
  pageType: PageTypes.Clusters,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo
};

export const EmptyParticipantsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Participants,
  pageType: PageTypes.Participants,
  objectType: 'faces',
  defaultAction: EventDetails.ShowInfo
};

export const EmptyCasesPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Cases,
  pageType: PageTypes.Cases,
  showOverlay: false
};

export const EmptyCasePageState: PageState = {
  tab: '',
  pagePath: PagePaths.Case,
  pageType: 'information',
  showOverlay: false
};

export const EmptyAuditLogsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.AuditLogs,
  pageType: PageTypes.AuditLogs,
  displayType: DisplayTypes.Default
};

export const EmptySessionPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Sessions,
  pageType: PageTypes.Sessions,
  showLeftBar: true,
  showOverlay: false
};

export const EmptyBlockListPageState: PageState = {
  tab: '',
  pagePath: PagePaths.BlockList,
  pageType: PageTypes.BlockList,
  defaultAction: EventDetails.ShowInfo,
  showLeftBar: true,
  showOverlay: false
};

export const EmptySearchPageState: SearchPageState = {
  tab: '',
  pagePath: PagePaths.Search,
  pageType: PageTypes.Cards,
  cardType: 'humans',
  objectType: 'faces',
  episodeType: 'humans',
  displayType: DisplayTypes.Default,
  defaultAction: EventDetails.ShowInfo,
  showOverlay: false,
  detectionId: '',
  id: '',
  searchFrom: PageNames.Cards
};

export const EmptyExternalMonitoringEventsPage: PageState = {
  tab: '',
  pagePath: PagePaths.RemoteMonitoringEvents,
  pageType: PageTypes.None,
  playing: true
};

export const EmptyExternalVMSPageState: PageState = {
  tab: '',
  pagePath: PagePaths.ExternalVms,
  pageType: PageTypes.ExternalVms,
  showLeftBar: true,
  showOverlay: false
};

export const EmptyAlertsPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Alerts,
  pageType: PageTypes.Alerts,
  playing: true
};


export const EmptyWebhooksPageState: PageState = {
  tab: '',
  pagePath: PagePaths.Hooks,
  pageType: PageTypes.Hooks,
  playing: true
};

export const EmptyVideoWallPageState: VideoWallPageState = {
  layout: 'videowall-and-sidebar',
  pagePath: PagePaths.VideoWall,
  pageType: PageTypes.VideoWall,
  preset: {
    cameraIds: [null],
    id: null,
    layout: '1_camera',
    name: ''
  },
  tab: ''
};
