
import {Options, Vue} from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import {LeafletEvent, Map as TMap, Marker} from 'leaflet';
import NButton from '@/uikit/buttons/NButton.vue';
import {PointAsArray} from '@/uikit/draw/types';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import {ActionI18N} from '@/uikit';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';

const ShapePolygon = 'Polygon';

@Options({
  name: 'MapAreaSelect',
  components: {
    NDropdownItem,
    NDropdown,
    ActionsDropdown,
    NButton
  },
  emits: ['action']
})
export default class MapAreaSelect extends Vue {
  @Prop({type: Object, required: true})
  public readonly map!: TMap;

  @Prop({type: Array, default: () => []})
  readonly actions!: ActionI18N[];

  @Prop({type: Boolean})
  readonly areaDrawOn = false;

  marker: Marker | null = null;
  actionsPosition = {};

  mounted() {
    this.map.on('pm:create', this.handleFigureAdd as any);
    this.map.pm.setGlobalOptions({
      panes: { vertexPane: 'popupPane', layerPane: 'popupPane' }
    });
  }

  beforeUnmount() {
    this.map.off('pm:create', this.handleFigureAdd as any);
  }

  get computedActions(): ActionI18N[] {
    return [...this.actions, {name: 'close', i18n_label: 'common.cancel', icon: 'close'}];
  }

  handleFigureAdd(event: { shape: string; marker: Marker; event: LeafletEvent }) {
    if (event.shape === ShapePolygon) {
      this.marker = event.marker;
      const point = this.map.latLngToContainerPoint(this.polygonCoordinates[0] as PointAsArray);
      this.actionsPosition = {top: point.y + 'px', left: point.x + 'px'};
    } else {
      console.warn('[Map Area Select] unsupported shape: ' + event.shape);
    }
  }

  handleAction(name: string) {
    this.$emit('action', {name, coordinates: this.polygonCoordinates});
    this.clear();
  }

  get polygonCoordinates() {
    const coordinates = (this.marker?.toGeoJSON()?.geometry?.coordinates ?? []) as unknown as PointAsArray[][];
    if (coordinates.length) {
      return coordinates[0]?.map(([x, y]) => [y, x]);
    }
    console.error('[Map Area Select] Can`t get area coordinates!');
    return [];
  }

  clear() {
    this.map.pm.disableDraw(ShapePolygon);
    this.marker?.remove();
    this.marker = null;
    this.$emit('update:areaDrawOn', false);
  }

  drawToggle() {
    this.$emit('update:areaDrawOn', !this.areaDrawOn);
  }

  @Watch('areaDrawOn')
  handleDraw() {
    if (this.areaDrawOn) {
      this.map.pm.enableDraw(ShapePolygon, { snapDistance: 5 });
    } else {
      this.clear();
    }
  }
}
