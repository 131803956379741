
import { Options, Vue } from 'vue-class-component';
import {
  DetectionGroupItems, DetectionOtherFeatureNames,
  LicenseFeaturesMap, FunctionalFeatureNames,
  LicenseFeatureGroupItems,
  LicenseFeatureStatus, LicenseFeatureStatusItems
} from '@/pages/license_v1/types';
import { Prop } from 'vue-property-decorator';
import LicenseTag from '@/pages/license_v1/components/common/LicenseTag.vue';

export type ValueItem = {
  label: string;
  value: string;
};

@Options({
  components: { LicenseTag }
})
export default class LicenseFeaturesContent extends Vue {
  @Prop({ required: true })
  itemsMap!: LicenseFeaturesMap;

  @Prop({ required: true })
  statusesMap!: Record<string, LicenseFeatureStatus>;

  get groups(): ValueItem[] {
    return Object.values(LicenseFeatureGroupItems).map((v) => ({ label: this.$t('license.groups.' + v, 'f'), value: v }));
  }

  get detectionGroups(): ValueItem[] {
    return Object.values(DetectionGroupItems).map((v) => ({ label: this.$t('license.subgroups.' + v, 'f'), value: v }));
  }

  getSubGroups(group: ValueItem): ValueItem[] {
    switch (group.value) {
      case LicenseFeatureGroupItems.Detections:
        return this.detectionGroups;
      default:
        return [group];
    }
  }

  getFunctionalFeatureNames(): string[] {
    return FunctionalFeatureNames.filter((name: string) => !!this.itemsMap[name]);
  }

  getOtherDetectionFeatureNames(): string[] {
    const allFeatures = ([] as string[]).concat(this.getFunctionalFeatureNames(), this.getDetectionFeatureNames());
    const allFeaturesMap = Object.fromEntries(allFeatures.map((name: string) => [name, true]));
    const result = Object.keys(this.itemsMap).filter((name: string) => !allFeaturesMap[name]);
    return result;
  }

  getDetectionFeatureNames(): string[] {
    const detectionPrefixes = Object.values(DetectionGroupItems);
    return Object.keys(this.itemsMap).filter((name: string) => !!detectionPrefixes.find((prefix: string) => name.startsWith(prefix)));
  }

  getFeatureItems(group: ValueItem) {
    const groupValue = group.value;
    let result: string[] = [];
    if (groupValue === LicenseFeatureGroupItems.Functional) {
      result = this.getFunctionalFeatureNames();
    } else if (Object.values(DetectionGroupItems).includes(groupValue)) {
      if (groupValue === DetectionGroupItems.Other) {
        result = this.getOtherDetectionFeatureNames();
      } else {
        result = Object.keys(this.itemsMap).filter((name: string) => name.startsWith(groupValue));
      }
    }

    return result.map((name: string) => this.computeFeatureItemByName(name));
  }

  computeFeatureItemByName(name: string) {
    const status = this.statusesMap[name] || LicenseFeatureStatusItems.Disabled;
    const tooltip = status === LicenseFeatureStatusItems.Disabled ? this.$t('license.disabled_feature_description') : undefined;

    return {
      label: this.getFeatureName(name),
      value: name,
      status,
      tooltip
    };
  }

  getFeatureName(name: string) {
    const prefix = 'license.featureNames.';
    let result = this.$t(prefix + name, 'f');
    return result.indexOf(prefix) === -1 ? result : name;
  }
}
