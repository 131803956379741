
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import enrichCards, { EnrichedCard } from '@/components/cards/enrich-cards';
import { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import ExternalSearchRequestForm from '@/pages/external-search/requests/ExternalSearchRequestForm.vue';

@Options({
  name: 'ExternalSearchRequestCreate',
  components: { ExternalSearchRequestForm, NButton, NModalWindow }
})
export default class ExternalSearchRequestCreate extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: HumanCard;

  itemUpdated: EnrichedCard | null = null;

  async mounted() {
    [this.itemUpdated] = await enrichCards([this.item]);
  }

  closeForm(item: EnrichedSearchRequest) {
    this.$emit('close');
    RouterModule.navigateToExternalSearch(item.id);
  }
}
