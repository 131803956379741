
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NHint from '@/uikit/hint/NHint.vue';
import { copyTextToClipboard } from '@/uikit/helpers';

@Options({
  name: 'ItemId',
  components: { NHint }
})
export default class ItemId extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  copyTextToClipboard(text: string) {
    copyTextToClipboard(text);
  }
}
