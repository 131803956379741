import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "datasource-photos-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NTable, {
      "onUpdate:selectedChanges": _ctx.dispatchSelect,
      columns: _ctx.columns,
      content: _ctx.items,
      selected: _ctx.selectedItems,
      "selections-schema": _ctx.createIdTableSelectionColumn,
      selections: "",
      hoverable: ""
    }, null, 8, ["onUpdate:selectedChanges", "columns", "content", "selected", "selections-schema"])
  ]))
}