
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import ExternalSearchRequests from '@/pages/external-search/requests/ExternalSearchRequests.vue';
import ExternalSearchResults from '@/pages/external-search/results/ExternalSearchResults.vue';
import PageContentLayout from '@/pages/PageContentLayout.vue';

@Options({
  name: 'ExternalSearchPage',
  components: {
    ExternalSearchRequests,
    ExternalSearchResults,
    NButton,
    NInput,
    PageContentLayout
  }
})
export default class ExternalSearchPage extends Vue {
  name_contains = '';
  searchRequestId: number = -1;

  displayImage(src: string): void {
    this.$photoViewer.show({ fullframe: src });
  }

  getSearchRequestIdFromQuery() {
    const { id } = JSON.parse(this.$route.query?.pageState as string);
    if (id && !Array.isArray(id)) this.searchRequestId = parseInt(id);
  }

  mounted() {
    this.getSearchRequestIdFromQuery();
  }
}
