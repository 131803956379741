export default {
  alerts: 'Менеджер тревог',
  new_alert: 'Новая тревога',
  alert_rules: 'Правила тревог',
  new_alert_rule: 'Новое правило тревог',
  id: 'ID',
  id_alert: 'ID тревожного сообщения',
  id_alert_rule: 'ID тревоги',
  name: 'Название',
  camera: 'Камера',
  camera_group: 'Группа камер',
  tags: 'Метки',
  delivery: 'Доставка',
  delivery_channels: 'Каналы доставки',
  tabs: {
    general: 'Общее',

    'alert-rules': 'Условия тревоги',
    settings: 'Настройки',
    'delivery-channels': 'Каналы доставки',
    'fast-comments': 'Быстрые комментарии'
  },
  settings: {
    ack_interval: 'Интервал автопринятия',
    ack_interval__desc: 'Значение должно быть от 0 до 300',
    ddp_interval: 'Интервал дедупликации',
    ddp_interval__desc: 'Значение должно быть от 0 до 300',
    reset_ack_interval: 'Сбросить интервал автопринятия',
    reset_ack_interval__desc: 'Сбросить интервал автопринятия',
    reset_ddp_interval: 'Включить дедупликацию',
    reset_ddp_interval__desc: 'Включите данную настройку, чтобы в указанном интервале времени не получать дублирующие события распознавания объекта',
    alert_feed: 'Тревожный монитор',
    alert_feed__desc: 'Получение уведомлений о тревогах в Тревожный монитор и Журнал тревог. Включено по умолчанию, настройка не подлежит изменению.',
    telegram_channel: 'Telegram',
    telegram_channel__desc: 'Получение уведомлений о тревогах в Telegram. Требуется настройка (см. документацию).',
    email_channel__desc: 'Email канал описание',
    email_channel: 'Email'
  },
  deliveryChannels: {
    alarm_screen: 'Тревожный монитор',
    telegram: 'Telegram',
    email: 'Электронная почта',
    external_system: 'Внешняя система'
  },
  noComments: 'Без комментариев',
  cancel: 'Отменить',
  addComment: 'Добавить комментарий',
  addCommentAndComplete: 'Добавить комментарий и завершить',
  ownComment: 'Свой комментарий',
  add_fast_comment: 'Добавить быстрый комментарий',
  reset_alert_rule_confirmation: 'Вы потеряете предыдущие настройки условий тревоги, если выберете другой раздел. Вы действительно хотите продолжить?',
  empty_alert_rule_error: 'Требуется настроить условия тревоги',
  empty_list: 'Вы не создали ни одной тревоги'
};
