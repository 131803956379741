import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "n-thumbnail__close"
}
const _hoisted_3 = {
  key: 1,
  class: "n-thumbnail__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingDots = _resolveComponent("NLoadingDots")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NNotFoundImage = _resolveComponent("NNotFoundImage")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["n-thumbnail n-thumbnail_center", _ctx.wrapClasses])
      }, [
        _createVNode(_component_NLoadingDots, { type: "inverted" })
      ], 2))
    : (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["n-thumbnail", _ctx.wrapClasses])
        }, [
          (_ctx.modelValue && !_ctx.loadingError)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass(["n-thumbnail__image", _ctx.imageClasses]),
                alt: "",
                src: _ctx.modelValue,
                onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadingError = true))
              }, null, 42, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "n-thumbnail__actions",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emitAction(_ctx.compDefaultAction)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, i) => {
              return (_openBlock(), _createBlock(_component_NButton, {
                key: i,
                round: "",
                icon: action.icon,
                transparent: !(action.selected || _ctx.defaultAction === action.name),
                class: _normalizeClass(`n-thumbnail__action n-thumbnail__action_${action.position} n-thumbnail__action_${action.name}`),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                onAction: ($event: any) => (_ctx.emitAction(action.name))
              }, null, 8, ["icon", "transparent", "class", "onAction"]))
            }), 128)),
            (_ctx.canClose)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_NButton, {
                    icon: "close",
                    type: "secondary",
                    round: "",
                    onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
                    onAction: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitAction('close')))
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.loadingError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_NIcon, {
                    name: "file-broken",
                    width: "50",
                    height: "50"
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : (_openBlock(), _createBlock(_component_NNotFoundImage, {
          key: 2,
          label: _ctx.computedNotFoundImageLabel,
          size: _ctx.size
        }, null, 8, ["label", "size"]))
}