import { getDelay } from '@/definitions/common/base';
import { ItemViewModel } from '@/definitions/view-models';
import { CameraGroup, Case, WatchList } from '@/api';
import { getViewModelByName } from '@/api/common';
import { CameraGroupPermissions } from '@/api/models/CameraGroupPermissions';
import { CasePermissions } from '@/api/models/CasePermissions';
import { WatchListPermissions } from '@/api/models/WatchListPermissions';

export const PermissionsModelNamesMap = {
  WatchLists: 'permissions_watch_lists',
  CameraGroups: 'permissions_camera_groups',
  Cases: 'permissions_cases'
} as const;

export type PermissionModelName = typeof PermissionsModelNamesMap[keyof typeof PermissionsModelNamesMap];
const PermissionsField = 'permissions';

/**
 * Prevent permission sending on common entity endpoint
 * Save entity it's self
 * And then save permissions on special entity's permissions endpoint
 * todo: refactor or remove this huck when we get a time
 */
export async function saveItemAndPermissions(
  itemVM: ItemViewModel<CameraGroup | WatchList | Case>,
  modelName: PermissionModelName,
  itemSave: () => Promise<CameraGroup | WatchList | Case>
) {
  if (!itemVM.item) return;
  const changes = itemVM.changes;
  const permissions = itemVM.item.permissions;
  const hasPermissionsChanges = changes.includes(PermissionsField);
  const hasItemChanges = changes.filter((v) => v !== PermissionsField).length > 0;

  if (hasItemChanges) {
    delete itemVM.item.permissions;
    await itemSave();
    itemVM.item.permissions = permissions;
  }

  if (hasPermissionsChanges && permissions) {
    // setVmPermissions(itemVM, null); // todo: prevent blinking on permissions tab, uncomment if you get 400 error
    await getDelay(160);
    try {
      await itemSave();
      setVmPermissions(itemVM, permissions as any);
      const permissionsVM = getViewModelByName(modelName) as ItemViewModel<any>;
      permissionsVM.setItemsState({ id: itemVM.item.id, permissions });
      const permissionsPayload = await permissionsVM.save();
      setVmPermissions(itemVM, permissionsPayload.permissions);
    } catch (e) {
      console.warn('[cases] Can`t set permissions: ', e);
    }
  }

  return itemVM.item;
}

export async function loadAndSyncPermissions(itemVM: ItemViewModel<CameraGroup | WatchList | Case>, modelName: PermissionModelName) {
  if (!itemVM?.isNew && itemVM.item) {
    const permissionVM = getViewModelByName(modelName) as ItemViewModel<CameraGroupPermissions | WatchListPermissions | CasePermissions>;
    await permissionVM.get(itemVM.item.id);
    permissionVM.item?.permissions && setVmPermissions(itemVM, permissionVM.item.permissions);
  }
}

function setVmPermissions(vm: ItemViewModel<CameraGroup | WatchList | Case>, permissions: Record<string, any> | null) {
  if (permissions) {
    vm.item && (vm.item.permissions = { ...permissions });
    vm.originalItem && (vm.originalItem.permissions = { ...permissions });
  } else {
    vm.item && delete vm.item.permissions;
    vm.originalItem && delete vm.originalItem.permissions;
  }
}

export async function loadPermissions(id: number, modelName: PermissionModelName): Promise<CasePermissions | null> {
  if (id) {
    const permissionVM = getViewModelByName(modelName) as ItemViewModel<CameraGroupPermissions | WatchListPermissions | CasePermissions>;
    await permissionVM.get(id);
    return permissionVM.item!;
  } else {
    return null;
  }
}
