import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "license-general" }
const _hoisted_2 = { class: "license-general__header" }
const _hoisted_3 = { class: "license-general__section-vertical" }
const _hoisted_4 = {
  key: 0,
  class: "license-general__item"
}
const _hoisted_5 = { class: "license-general__item-label_active" }
const _hoisted_6 = { class: "license-general__item-value" }
const _hoisted_7 = {
  key: 1,
  class: "license-general__item"
}
const _hoisted_8 = { class: "license-general__item-label_active" }
const _hoisted_9 = { class: "license-general__item-value" }
const _hoisted_10 = { class: "license-general__section-horizontal" }
const _hoisted_11 = {
  key: 0,
  class: "license-general__item"
}
const _hoisted_12 = { class: "license-general__item-label" }
const _hoisted_13 = { class: "license-general__item-value" }
const _hoisted_14 = { class: "license-general__item" }
const _hoisted_15 = { class: "license-general__item-label" }
const _hoisted_16 = { class: "license-general__item-value" }
const _hoisted_17 = { class: "license-general__list" }
const _hoisted_18 = { class: "license-general__list__header" }
const _hoisted_19 = { class: "license-general__list__header" }
const _hoisted_20 = { class: "license-general__list__header" }
const _hoisted_21 = { class: "license-general__list__header" }
const _hoisted_22 = { class: "license-general__list__cell" }
const _hoisted_23 = { class: "license-general__list__cell" }
const _hoisted_24 = { class: "license-general__list__cell" }
const _hoisted_25 = { class: "license-general__list__cell" }
const _hoisted_26 = { class: "license-general__summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseTag = _resolveComponent("LicenseTag")!
  const _component_LicenseResourceTable = _resolveComponent("LicenseResourceTable")!
  const _component_LicenseFeaturesContent = _resolveComponent("LicenseFeaturesContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.validCount || _ctx.requireAttentionCount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('license.valid_items')), 1),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.validCount)
                  ? (_openBlock(), _createBlock(_component_LicenseTag, {
                      key: 0,
                      size: "small",
                      status: 'success'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.validCount), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.requireAttentionCount)
                  ? (_openBlock(), _createBlock(_component_LicenseTag, {
                      key: 1,
                      size: "small",
                      status: 'warning'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.requireAttentionCount), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.invalidCount)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('license.invalid_items')), 1),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_LicenseTag, {
                  size: "small",
                  status: 'error'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.invalidCount), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_ctx.showNearestDate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('license.nearest_date')), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.formatLicenseExpireDate(this.nearestDate)), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('license.latest_date')), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.formatLicenseExpireDate(this.latestDate)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('license.license_id')), 1),
      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('license.expiry_date')), 1),
      _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('license.type')), 1),
      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('license.source')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenses, (item) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: `l-${item.license_id}`
        }, [
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_LicenseTag, {
              status: _ctx.getLicenseStatus(item),
              tooltip: _ctx.getLicenseTooltipText(_ctx.getLicenseStatus(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.license_id), 1)
              ]),
              _: 2
            }, 1032, ["status", "tooltip"])
          ]),
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.formatLicenseExpireDate(item.expire_date)), 1),
          _createElementVNode("div", _hoisted_24, _toDisplayString(item.type), 1),
          _createElementVNode("div", _hoisted_25, _toDisplayString(item.source), 1)
        ], 64))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createVNode(_component_LicenseResourceTable, { itemsMap: _ctx.resourcesMap }, null, 8, ["itemsMap"]),
      _createVNode(_component_LicenseFeaturesContent, {
        itemsMap: _ctx.featuresMap,
        statusesMap: _ctx.featureStatusesMap
      }, null, 8, ["itemsMap", "statusesMap"])
    ])
  ]))
}