
import { Options, Vue } from 'vue-class-component';
import { licenseModule } from '@/store/config/LicenseModule';
import { ServiceInfo } from '@/pages/license_v1/types';

@Options({
  components: {}
})
export default class ServicesContent extends Vue {

  get tableStyle() {
    return { 'grid-template-columns': `repeat(${this.serviceNames.length}, minmax(200px, 1fr))` };
  }

  get licenseData() {
    return licenseModule.licenseData;
  }

  get serviceNames() {
    let keysMap = this.licenseData?.services?.reduce((m: any, v) => {
      m[v.name.toLowerCase()] = true;
      return m;
    }, {}) || {};
    return Object.keys(keysMap);
  }

  getServicesByName(name: string): ServiceInfo[] {
    return this.licenseData?.services?.filter((v) => v.name.toLowerCase() === name) || [];
  }
}
