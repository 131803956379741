
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  name: 'RemoteMonitoringItemProxy'
})
export default class RemoteMonitoringItemProxy extends Vue {
  @Prop({ type: Number })
  index?: number;

  @Prop({ type: Boolean, default: false })
  display!: boolean;
}
