import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "audit-logs-image-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShowIcon)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          name: "picture",
          onClick: _ctx.showImage
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}