
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NInputNumber from '@/uikit/input-number/NInputNumber.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import NSlider from '@/uikit/slider/NSlider.vue';
import VideoPlayerState from '@/components/video-player/VideoPlayerState';

@Options({
  name: 'VideoPlayerSettings',
  components: { NCheckbox, NInputNumber, NSelect, NSlider }
})
export default class VideoPlayerSettings extends Vue {
  @Prop({ type: Object, required: true })
  readonly settings!: VideoPlayerState;

  get resolutionsItems() {
    return this.settings.resolutions.map((item) => ({ label: item, value: item }));
  }
}
