
import { Options } from 'vue-class-component';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import Confidence from '@/components/common/Confidence.vue';
import CardDate from '@/components/cards/CardDate.vue';
import AnnexAlarmItem from '@/pages/annex/alarms/AnnexAlarmItem';
import AnnexAlarmAcknowledge from '@/pages/annex/alarms/AnnexAlarmAcknowledge.vue';
import AnnexAlarmAcknowledgeInfo from '@/pages/annex/alarms/AnnexAlarmAcknowledgeInfo.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import AnnexAlarmFeatures from '@/pages/annex/alarms/AnnexAlarmFeatures.vue';
import ItemId from '@/pages/annex/alarms/ItemId.vue';

@Options({
  name: 'AnnexAlarmItemRow',
  components: { ItemId, AnnexAlarmFeatures, NIcon, AnnexAlarmAcknowledge, AnnexAlarmAcknowledgeInfo, CardDate, Confidence, NThumbnail, CardItemRow }
})
export default class AnnexAlarmItemRow extends AnnexAlarmItem {}
