/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Status266Enum } from './Status266Enum';
export type CasePhoto = {
    readonly id: number;
    /**
     * Case photo creation date
     */
    readonly created_date: string;
    /**
     * Case photo modification date
     */
    readonly modified_date: string;
    /**
     * Case photo name, up to 256 characters
     */
    readonly name: string;
    /**
     * Comment, up to 2048 characters
     */
    comment?: string | null;
    readonly status?: Status266Enum;
    /**
     * Case photo status message
     */
    readonly status_msg: Record<string, any>;
    /**
     * Related case
     */
    case: number;
    /**
     * Case photo thumbnail
     */
    readonly thumbnail: string;
    /**
     * Case photo fullframe
     */
    fullframe: string;
    /**
     * Case photo size
     */
    readonly file_size: number;
    /**
     * Count of case clusters created from this case photo
     */
    readonly case_cluster_count: number | null;
};
export const EmptyCasePhoto: CasePhoto = {
    id: 0,
        /**
        * Case photo creation date
        */
    created_date: '',
        /**
        * Case photo modification date
        */
    modified_date: '',
        /**
        * Case photo name, up to 256 characters
        */
    name: '',
        /**
        * Comment, up to 2048 characters
        */
    comment: null,
    status: undefined,
        /**
        * Case photo status message
        */
    status_msg: {},
        /**
        * Related case
        */
    case: 0,
        /**
        * Case photo thumbnail
        */
    thumbnail: '',
        /**
        * Case photo fullframe
        */
    fullframe: '',
        /**
        * Case photo size
        */
    file_size: 0,
        /**
        * Count of case clusters created from this case photo
        */
    case_cluster_count: null,
};
