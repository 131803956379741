import { isFinite } from 'lodash';
import { Point, Size } from '../bbox/types';
import { FitFillResult, LayerStyle, ScaleOptions } from './types';

export function imageLoad(src: string): Promise<HTMLImageElement> {
  return new Promise((r, j) => {
    const image = new Image();
    image.onerror = (e) => j(e);
    image.onload = () => r(image);
    image.src = src;
  });
}

export function getIsImageOverflowContainer(contentSize: Size, containerSize: Size): boolean {
  return contentSize.width > containerSize.width || contentSize.height > containerSize.height;
}

export function getComputedScaleOptions(contentSize: Size, containerSize: Size): FitFillResult {
  const widthAspectRatio = containerSize.width / contentSize.width;
  const heightAspectRatio = containerSize.height / contentSize.height;

  if (isFinite(widthAspectRatio) && isFinite(heightAspectRatio)) {
    return {
      fit: Math.min(widthAspectRatio, heightAspectRatio),
      fill: Math.max(widthAspectRatio, heightAspectRatio),
      min: Math.min(widthAspectRatio, heightAspectRatio) * 0.3,
      wheelStep: (Math.min(widthAspectRatio, heightAspectRatio) * 0.05) / 100
    };
  } else {
    return { fit: 1, fill: 1, min: 1, wheelStep: 0.05 };
  }
}

const defaultScaleOptions = {
  default: 1,
  fit: 1,
  fill: 1,
  min: 0.2,
  max: 4,
  wheelStep: 5 * 1e-4
};

export function getScaleOptions(contentSize: Size, containerSize: Size): ScaleOptions {
  const options = Object.assign({}, defaultScaleOptions, getComputedScaleOptions(contentSize, containerSize));
  if (getIsImageOverflowContainer(contentSize, containerSize)) {
    options.default = options.fit;
  }
  return options;
}

export function getLayoutSizeStyle(size: Size, offset: Point, scale: number = 1): LayerStyle {
  return {
    transform: `translate(calc(-50% + ${offset.x}px), calc(-50% + ${offset.y}px))`,
    width: `${size.width * scale}px`,
    height: `${size.height * scale}px`
  };
}
