import { MatchedEventItemComponentProps, UnmatchedEventItemComponentProps } from '@/pages/uicomponents/components/event/items';
import { AcknowledgeAndEventSelectedProps, AcknowledgeProps, CardOpenedProps, EventOpenedProps, EventSelectedProps } from '@/pages/uicomponents/types';
import EventItem from '@/components/events/EventItem.vue';

export const eventComponentsGroup = {
  name: 'Events Unmatched and Matched',
  propConfigs: [null, AcknowledgeProps, EventSelectedProps, EventOpenedProps, CardOpenedProps, AcknowledgeAndEventSelectedProps],
  items: [
    {
      name: 'Unmatched short',
      component: EventItem,
      layout: 'horizontal',
      props: UnmatchedEventItemComponentProps
    },
    {
      name: 'Unmatched full',
      component: EventItem,
      props: { ...UnmatchedEventItemComponentProps, displayType: 'full' }
    },
    {
      name: 'Matched short',
      component: EventItem,
      layout: 'horizontal',
      props: MatchedEventItemComponentProps
    },
    {
      name: 'Matched full',
      component: EventItem,
      props: { ...MatchedEventItemComponentProps, displayType: 'full' }
    }
  ]
};
