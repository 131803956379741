import IterableWeakMap from '@/api/common/IterableWeakMap';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { getLogger } from 'loglevel';
import { ModuleEventTypes, eventModule } from '@/api/common/eventModule';
import { getDelay } from '@/definitions/common/base';
import { throttleWithArgs } from '@/common/utils';

const logger = getLogger('[auto-update-helper]');
logger.setLevel('warn');

type ListModule = ListViewModel<any, any>;
type ItemModule = ItemViewModel<any>;
type AutoUpdateModuleOptions = {
  remote?: boolean;
  listViewModelRemoteResolver?: any;
};

export class AutoUpdateHelper {
  iterableListVMWeakMap = new IterableWeakMap([]);
  iterableItemVMWeakMap = new IterableWeakMap([]);

  constructor() {
    const handler = this.updateHandler.bind(this);
    this.throttleWithArgsUpdateHandler = throttleWithArgs(handler, 4000);
  }

  addListInstance(module: ListModule) {
    const hasModule = this.iterableListVMWeakMap.get(module);
    if (!hasModule) {
      logger.info(':add list and listVM weak map size:', module.name, this.listModules().length);
      this.iterableListVMWeakMap.set(module, true);
    }
    return module;
  }

  removeListInstance(module: ListModule | undefined) {
    logger.info(':remove list and listVM weak map size:', module?.name, this.listModules().length);
    if (module) this.iterableListVMWeakMap.delete(module);
  }

  addItemInstance(module: ItemModule) {
    const hasModule = this.iterableItemVMWeakMap.get(module);
    if (!hasModule) {
      this.iterableItemVMWeakMap.set(module, true);
      logger.info(':add item and itemVM weak map size:', this.itemModules().length);
    }
    return module;
  }

  removeItemInstance(module: ListModule | undefined) {
    logger.info(':remove item and itemVM weak map size:', module?.name, this.itemModules().length);
    if (module) this.iterableItemVMWeakMap.delete(module);
  }

  protected listModules(): ListModule[] {
    return Array.from(this.iterableListVMWeakMap.keys());
  }

  protected itemModules(): ItemModule[] {
    return Array.from(this.iterableItemVMWeakMap.keys());
  }

  async createHandler(name: string, newItem?: any, exclude: any[] = []) {
    logger.info(':create start ', name, newItem);
    const modules = this.listModules();
    for (const module of modules) {
      const isSupported = this.isSupportedListVM(module, name, exclude);
      if (isSupported) {
        logger.info(':create execute ', name, newItem, module);
        await module.autoUpdateDebounceGet();
      }
    }
  }

  async throttleWithArgsUpdateHandler(name: string, newItem: any, exclude: any[] = [], options: AutoUpdateModuleOptions = {}): Promise<any> | never {
    throw new Error('not implemented');
  }

  async updateHandler(name: string, newItem: any, exclude: any[] = [], options: AutoUpdateModuleOptions = {}) {
    logger.info(':update start ', name, JSON.stringify(newItem));
    const modules = this.listModules();
    const itemModules = this.itemModules();
    eventModule.current = eventModule.build(ModuleEventTypes.Update, name, newItem);

    for (const module of modules) {
      const isSupported = this.isSupportedListVM(module, name, exclude);
      if (!isSupported) continue;
      const sourceItem = module.items.find((item: any) => item.id === newItem.id);
      if (sourceItem && newItem) {
        const remoteResolverResult = options.listViewModelRemoteResolver && options.listViewModelRemoteResolver(module);
        logger.info(':update LIST execute ', name, sourceItem, ' remote: ', options.remote && remoteResolverResult);
        if (options.remote && remoteResolverResult) {
          module.autoUpdateDebounceGet();
        } else {
          Object.assign(sourceItem, newItem);
        }
      }
    }

    for (const module of itemModules) {
      const isSupported = this.isSupportedItemVM(module, name, exclude);
      if (isSupported && newItem.id === module.item.id) {
        logger.info(':update ITEM execute ', name, module, ', remote: ', options.remote);
        if (options.remote) {
          getDelay(100).then(() => module.get(module.item.id, true));
        } else {
          module.setItemsState(newItem, null, true);
        }
      }
    }
  }

  async deleteHandler(name: string, id: any, exclude: any[] = []) {
    logger.info(':delete start ', name, id, this.listModules());
    const modules = this.listModules();
    for (const module of modules) {
      const isSupported = this.isSupportedListVM(module, name, exclude);
      if (isSupported) {
        logger.info(':delete execute ', name, id);
        module.items = module.items.filter((v) => v.id !== id);
        await module.autoUpdateDebounceGet();
      }
    }
  }

  isSupportedListVM(module: ListViewModel<any, any>, name: string, exclude: any[] = []) {
    return module.autoUpdateWithHelper && module.name === name && !exclude.includes(module);
  }

  isSupportedItemVM(module: ItemViewModel<any>, name: string, exclude: any[] = []) {
    return module.autoUpdateWithHelper && module.name === name && !exclude.includes(module);
  }

  async updateIvmModulesByLvmModule(module: ListViewModel<any, any>) {
    for (let i = 0; i < module.items.length; i++) {
      await autoUpdateHelper.updateHandler(module.name, module.items[i]);
    }
  }
}

export const autoUpdateHelper = new AutoUpdateHelper();
