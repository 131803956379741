/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CaseStatusEnum {
    Open = 'open',
    Archived = 'archived',
    Archiving = 'archiving',
    Dearchiving = 'dearchiving',
    Deleting = 'deleting'
}
