import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTooltip = _resolveComponent("NTooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref: _ctx.tagChange,
      class: _normalizeClass(_ctx.classes)
    }, null, 2),
    (_ctx.tooltip && _ctx.tag)
      ? (_openBlock(), _createBlock(_component_NTooltip, {
          key: 0,
          reference: _ctx.tag,
          placement: "top",
          mode: "hover"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.tooltip), 1)
          ]),
          _: 1
        }, 8, ["reference"]))
      : _createCommentVNode("", true)
  ], 64))
}