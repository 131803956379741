
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { applicationModule } from '@/store/application';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { DisplayType, DisplayTypes } from '@/store/application/page.definitions';

@Options({
  name: 'DisplayTypes',
  components: { NButtonGroup }
})
export default class DisplayTypesButtonGroup extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: Array, default: () => [DisplayTypes.Full, DisplayTypes.Short] })
  readonly availableItems!: DisplayType[];

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get computedItems() {
    return dataAssetsModule.displayTypes.filter((v) => this.availableItems.includes(v.value as DisplayType));
  }

  get displayType() {
    return this.modelValue;
  }

  set displayType(value: any) {
    this.$emit('update:modelValue', value);
  }

  updateDisplayType() {
    if (this.displayType === DisplayTypes.Default) {
      const preferredDisplayType = this.availableItems.includes(applicationModule.settings.ui.list_view as DisplayType)
        ? applicationModule.settings.ui.list_view
        : this.availableItems[0];
      this.displayType = preferredDisplayType;
    }
  }

  created() {
    this.updateDisplayType();
  }

  @Watch('modelValue')
  modelValueWatcher() {
    this.updateDisplayType();
  }
}
