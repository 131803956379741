import attentionSoundUrl from '@/assets/sounds/attention.mp3';
import { h } from '@vue/runtime-core';
import { reactive, watch } from 'vue';
import notify from '@/uikit/notification/helpers/notification';
import AlertNotification from '@/components/notification-header/AlertNotification.vue';

const attentionSound = new Audio(attentionSoundUrl);

if (attentionSound) {
  attentionSound.volume = 1;
}

export class AlertModule {
  isPlay: boolean = false;
  isMute: boolean = false;
  isDisabled: boolean = false;

  play() {
    const alert = attentionSound.play();
    if (alert !== undefined) {
      alert
        .then(() => {
          attentionSound.loop = true;
          this.isDisabled = false;
        })
        .catch((e) => {
          this.isDisabled = true;
          return notify({ content: () => h(AlertNotification), showClose: true, duration: 1e4 });
        });
    } else {
      this.isDisabled = true;
    }
  }

  stop() {
    attentionSound.pause();
  }

  toggleMute() {
    this.isMute = !this.isMute;
  }

  get isPlayAndNotMute() {
    return this.isPlay && !this.isMute;
  }

  isPlayAndNotMuteChanged(value: boolean) {
    if (value) {
      this.play();
    } else {
      this.stop();
    }
  }
}

export const alertModule = reactive(new AlertModule());

watch(
  () => alertModule.isPlayAndNotMute,
  (value) => {
    alertModule.isPlayAndNotMuteChanged(value);
  }
);
