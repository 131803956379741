
import { defineComponent, PropType } from 'vue';
import { Report } from '@/api';
import NTable, { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import { ReportFormat } from '../../types';
import { extractReportFormats } from '../../utils';
import {
  ReportsPageTableColumnId,
  ReportsPageTableColumnModifiedDate,
  ReportsPageTableColumnName,
  ReportsPageTableColumnRecordsNumber,
  ReportsPageTableColumnSize,
  ReportsPageTableColumnStatus,
  ReportsPageTableColumnType
} from './components';

export default defineComponent({
  name: 'ReportsPageTable',
  components: { NTable },
  props: {
    items: {
      required: true,
      type: Array as PropType<Report[]>
    },
    selectedIds: {
      required: true,
      type: Array as PropType<Report['id'][]>
    }
  },
  emits: ['download', 'sort'],
  computed: {
    columns(): ITableColumn[] {
      return [
        {
          width: 'max-content',
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.id', name: 'id', sortable: true }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnId,
            props: ({ id }: Report) => ({ id })
          }
        },
        {
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.name', name: 'name' }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnName,
            props: ({ name }: Report) => ({ name })
          }
        },
        {
          width: '200px',
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.type', name: 'type' }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnType,
            props: ({ type }: Report) => ({ type })
          }
        },
        {
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: {
              i18n_label: 'reports.modified',
              name: 'modified_date',
              sortable: true
            }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnModifiedDate,
            props: ({ modified_date }: Report) => ({ modifiedDate: modified_date })
          }
        },
        {
          width: '70px',
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.records', name: 'records' }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnRecordsNumber,
            props: ({ records_number }: Report) => ({ recordsNumber: records_number })
          }
        },
        {
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.size', name: 'size', sortable: true }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnSize,
            props: (report: Report) => ({ size: report.size })
          }
        },
        {
          header: {
            classes: ['reports-page-table__header'],
            component: NTableColumnCell,
            props: { i18n_label: 'reports.status', name: 'status' }
          },
          content: {
            classes: ['reports-page-table__column'],
            component: ReportsPageTableColumnStatus,
            props: (report: Report) => ({
              formats: extractReportFormats(report),
              onDownload: (format: ReportFormat) => this.dispatchDownloadEvent(report, format),
              status: report.status
            })
          }
        }
      ];
    }
  },
  methods: {
    dispatchDownloadEvent(report: Report, format: ReportFormat): void {
      this.$emit('download', report, format);
    },
    dispatchSortEvent(property: keyof Report): void {
      this.$emit('sort', property);
    }
  }
});
