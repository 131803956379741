
import { Options, Vue } from 'vue-class-component';
import { C2VSelectItems, License, LicenseData, LicensePageTab, LicensePageTabs } from '@/pages/license_v1/types';
import EmptyContent from '@/pages/license_v1/components/EmptyContent.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import GeneralContent from '@/pages/license_v1/components/GeneralContent.vue';
import { LicenseModule, licenseModule } from '@/store/config/LicenseModule';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import LicensesContent from '@/pages/license_v1/components/LicensesContent.vue';
import ServicesContent from '@/pages/license_v1/components/ServicesContent.vue';
import IntervalsContent from '@/pages/license_v1/components/IntervalsContent.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { fromFs } from '@/uikit/attachments';
import NButtonSelect from '@/uikit/buttons/NButtonSelect.vue';
import { ValueItem } from '@/pages/license_v1/components/common/LicenseFeaturesContent.vue';

@Options({
  components: { NButtonSelect, NButton, SettingsPageLayout, NTabs, GeneralContent, EmptyContent }
})
export default class LicensePage extends Vue {
  tab: LicensePageTab = LicensePageTabs.Common;

  updateInterval = 0;
  downloadC2V = '';

  get tabItems() {
    const filterTab = (v: string) => {
      return v === LicensePageTabs.Intervals ? licenseModule.usageReport && licenseModule.checkHasIntervals() : true;
    };
    return Object.values(LicensePageTabs).filter(filterTab).map((v: string) => ({
      label: this.$t(`license.tabs.${v}`),
      value: v,
      name: v
    }));
  }

  get contentComponent() {
    let result = GeneralContent;

    switch (this.tab) {
      case LicensePageTabs.Licenses:
        result = LicensesContent;
        break;
      case LicensePageTabs.Intervals:
        result = IntervalsContent;
        break;
      case LicensePageTabs.Services:
        result = ServicesContent;
        break;
    }

    return result;
  }

  get hasLicense() {
    return this.licenses?.length || false;
  }

  get licenses(): License[] | undefined {
    return licenseModule.licenseData?.licenses;
  }

  get licenseData(): LicenseData | null {
    return licenseModule.licenseData;
  }

  get c2vItems(): ValueItem[] {
    return C2VSelectItems;
  }

  async actionHandler(name: string) {
    switch (name) {
      case 'buy':
        open(LicenseModule.Paths.LicenseStore, '_blank');
        break;
      case 'upload':
        await this.upload();
        break;
      case 'download_c2v_sentinel':
        await licenseModule.downloadC2V(LicenseModule.Paths.C2V_Sentinel);
        break;
      case 'download_c2v_guardant':
        await licenseModule.downloadC2V(LicenseModule.Paths.C2V_Guardant);
        break;
    }
  }

  async upload() {
    const [file] = await fromFs('*/*', false);
    await licenseModule.importLicense(file);
  }

  mounted() {
    this.updateInterval = setInterval(() => licenseModule.loadLicenses(), 5000) as any;
  }

  beforeUnmount() {
    clearInterval(this.updateInterval);
  }

  handleDownloadC2V(type: string) {
    this.actionHandler('download_c2v_' + type);
  }
}
