import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "camera-item",
  style: {"width":"100px"}
}
const _hoisted_2 = {
  key: 0,
  class: "camera-item__record"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraStatus = _resolveComponent("CameraStatus")!
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CameraStatus, {
      class: "camera-item__status",
      camera: _ctx.item,
      "show-status-label": false,
      "status-type": "vertical-line"
    }, null, 8, ["camera"]),
    (_ctx.item.stream_settings.enable_recorder)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_CameraScreenshot, {
      class: "camera-item__screenshot",
      "model-value": _ctx.item.screenshot,
      "display-width": "90px",
      width: 90,
      "refresh-on-click": false
    }, null, 8, ["model-value"])
  ]))
}