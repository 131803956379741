
import { computed, defineComponent, PropType, toRefs } from 'vue';
import { getLayoutSizeStyle } from '@/uikit/image-viewer/image-helpers';
import NBboxDistances from '../bbox/NBboxDistances.vue';
import { Point, Size } from '../bbox/types';
import { BboxDistance } from '../bbox/types';

export default defineComponent({
  name: 'NImageViewerBboxDistancesLayer',
  components: { NBboxDistances },
  props: {
    distances: { type: Array as PropType<BboxDistance[]>, required: true },
    size: { type: Object as PropType<Size>, required: true },
    scale: { type: Number, default: 1 },
    offset: { type: Object as PropType<Point>, default: () => ({ x: 0, y: 0 }) }
  },
  emits: ['select'],
  setup(props) {
    const style = computed(() => getLayoutSizeStyle(props.size, props.offset, props.scale));

    return {
      ...toRefs(props),
      style
    };
  }
});
