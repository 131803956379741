/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CountersFilter = {
    /**
     * Select only active counters (`true`) or inactive (`false`) counters
     */
    active?: boolean;
    /**
     * Select counters with this `proximity_calibration_status`
     */
    calibration_status?: 'AWAITING_PROXIMITY_MODEL' | 'AWAITING_SYNC' | 'CALIBRATED' | 'COLLECTING_CALIBRATION_DATA' | 'NOT_CALIBRATED';
    /**
     * Get counters that are associated with cameras from `camera_group`.
     */
    camera_groups?: Array<number>;
    /**
     * Get counters that are associated with `camera`. Pass comma separated values to select counters related to multiple `camera`s
     */
    cameras?: Array<number>;
    /**
     * Select counters with `count_interval` **greater than or equal** to this value
     */
    count_interval_gte?: number;
    /**
     * Select counters with `count_interval` **less than or equal** to this value
     */
    count_interval_lte?: number;
    /**
     * Select objects with `created_date` **greater** than this value
     */
    created_date_gt?: string;
    /**
     * Select objects with `created_date` **greater than or equal** to this value
     */
    created_date_gte?: string;
    /**
     * Select objects with created in last N days
     */
    created_date_last_n_days?: number;
    /**
     * Select objects with `created_date` **less** than this value
     */
    created_date_lt?: string;
    /**
     * Select objects with `created_date` **less than or equal** to this value
     */
    created_date_lte?: string;
    /**
     * Select objects with created in last Nth week (including Sunday and Saturday)
     */
    created_date_nth_full_week?: number;
    /**
     * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     */
    created_date_nth_work_week?: number;
    /**
     * Select counters that count cars
     */
    detect_cars?: boolean;
    /**
     * Select counters that count faces
     */
    detect_faces?: boolean;
    /**
     * Select only counters with (`true`) or without (`false`) proximity detection
     */
    detect_proximity?: boolean;
    /**
     * Select counters that count silhouettes
     */
    detect_silhouettes?: boolean;
    /**
     * Select counters with provided ids
     */
    id_in?: Array<number>;
    /**
     * Number of results to return per page.
     */
    limit?: string;
    /**
     * Select counters related to multiple cameras
     */
    multi_camera?: boolean;
    /**
     * Select counters where the SQL condition `name ILIKE name_contains` is true
     */
    name_contains?: string;
    /**
     * Which field to use when ordering the results. Available fields: `created_date`, `id`
     */
    ordering?: string;
    /**
     * Pagination cursor value.
     */
    page?: string;
}
export const EmptyCountersFilter: CountersFilter = {
    active: undefined,
    calibration_status: undefined,
    camera_groups: [],
    cameras: [],
    count_interval_gte: undefined,
    count_interval_lte: undefined,
    created_date_gt: undefined,
    created_date_gte: undefined,
    created_date_last_n_days: undefined,
    created_date_lt: undefined,
    created_date_lte: undefined,
    created_date_nth_full_week: undefined,
    created_date_nth_work_week: undefined,
    detect_cars: undefined,
    detect_faces: undefined,
    detect_proximity: undefined,
    detect_silhouettes: undefined,
    id_in: [],
    limit: '20',
    multi_camera: undefined,
    name_contains: undefined,
    page: undefined,
    ordering: '-id'
}
