
import { Options, Vue } from 'vue-class-component';
import Confidence from '@/components/common/Confidence.vue';
import EventItem from '@/components/events/EventItem.vue';
import { Prop } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { FaceEvent, FaceEventsFilters } from '@/api';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { EventOpenedItem, EventOpenedItems } from '@/components/events/types';
import { PageState } from '@/store/application/page.definitions';
import { MultisidebarItemType } from '@/store/multisidebar/types';
import { CommonMapConfig } from '@/components/map/CommonMapConfig';
import { configModule } from '@/store/config';
import { colorsConfig } from '@/components/map/colors';
import EventMarker from '@/components/events/map/EventMarker.vue';
import EventClusterMarker from '@/components/events/map/EventClusterMarker.vue';
import SmallEventItem from '@/components/events/map/SmallEventItem.vue';
import CommonMap from '@/components/map/CommonMap.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import SearchEventItem from '@/pages/search/components/SearchEventItem.vue';

type ModelType = FaceEvent;
type FilterType = FaceEventsFilters;

@Options({
  components: { SearchEventItem, CommonMap, EventItem, Confidence }
})
export default class EventResultPage extends Vue {
  @Prop({ type: Object, required: true })
  module!: ListViewModel<ModelType, FilterType>;

  @Prop({ type: Object, required: true })
  state!: PageState;

  @Prop({ type: String, required: true })
  cardsSidebarType!: MultisidebarItemType;

  @Prop({ type: String, required: true })
  pageSidebarType!: MultisidebarItemType;

  @Prop({ type: String, required: true })
  defaultEventAction: string = ItemsActionNames.ShowInfo;

  @Prop({ type: String, required: false })
  confidenceClass?: string;

  get mapConfig() {
    const result: CommonMapConfig = {
      sidebarTitle_i18n: 'common.events',
      mode: 'search',
      showTracks: true,
      module: this.module,
      msbType: this.pageSidebarType as any,
      centerPoint: configModule.config.map?.default_center || { lat: 53.5555, lng: 53.5555 },
      colorsConfig: colorsConfig,
      ItemMarker: EventMarker,
      ClusterMarker: EventClusterMarker,
      PopupItem: SmallEventItem,
      ListItem: SearchEventItem,
      itemProps: {
        hideAcknowledge: true,
        display: true,
        objects: this.state.objectType,
        episodeType: this.state.episodeType,
        type: this.state.pageType,
        acknowledgeAllowed: false,
        defaultAction: ItemsActionNames.ShowInfo,
        eventSelected: this.getIsItemSelected,
        cardSelected: this.getIsItemCardSelected,
        openedItem: this.getOpenedItemType
      },
      actionHandler: this.actionHandler
    };

    return result;
  }

  getIsItemSelected(item: ModelType): boolean {
    const id = generateMultisidebarId(this.pageSidebarType, item.id);
    return !!multisidebarModule.getItemByMsbId(id);
  }

  getIsItemCardSelected(item: ModelType): boolean {
    const id = item.matched_card ? generateMultisidebarId(this.cardsSidebarType, item.matched_card) : null;
    return id ? !!multisidebarModule.getItemByMsbId(id) : false;
  }

  getOpenedItemType(item: ModelType): EventOpenedItem | null {
    const currentMsbId = multisidebarModule.currentItem?.id;
    const itemId = generateMultisidebarId(this.pageSidebarType, item.id);
    const cardId = item.matched_card ? generateMultisidebarId(this.cardsSidebarType, item.matched_card) : null;
    let result = null;
    if (currentMsbId === itemId) result = EventOpenedItems.Item;
    else if (currentMsbId === cardId) result = EventOpenedItems.Card;
    return result;
  }

  selectHandler(item: ModelType) {
    // multisidebarModule.toggleItemSelect(this.pageSidebarType, item);
  }

  actionHandler(v: string, item: ModelType) {
    this.$emit('action', v, item);
  }
}
