
import { Options, Vue } from 'vue-class-component';
import { licenseModule } from '@/store/config/LicenseModule';
import LicenseCard from '@/pages/license_v1/components/common/LicenseCard.vue';

@Options({
  components: { LicenseCard }
})
export default class LicensesContent extends Vue {
  get licenseData() {
    return licenseModule.licenseData;
  }

  get licenses() {
    const items = (this.licenseData?.licenses || []);
    const result = [...items].sort((a, b) => a.expire_date > b.expire_date ? -1 : 1);
    return result;
  }
}
