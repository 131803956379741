import { reactive } from 'vue';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { License, LicenseData, LicenseFeatureStatus, LicenseFeatureStatusItems, Resource, UsageReport } from '@/pages/license_v1/types';
import { AxiosError } from 'axios';
import { throwError } from '@/uikit/dropdown/helpers/common';
import { getDelay } from '@/definitions/common/base';
import { configModule } from '@/store/config/index';
import { translate } from '@/store/languages';

export const LicenseFeatures = {
  LineCrossing: 'line-crossing',
  BiAnalytics: 'bi-analytics',
  Alerts: 'alerts'
} as const;

export type LicenseFeatureType = typeof LicenseFeatures[keyof typeof LicenseFeatures];
export const AttentionInterval = 60 * 24 * 3600 * 1e3;

export class LicenseModule {
  static Paths = {
    // License: '/licenses-v2.json',
    // UsageReport: '/usage-report-v2.json',
    License: '/ntls/v1/licenses.json',
    UsageReport: '/ntls/v1/usage-report.json',
    Import: '/ntls/import/',
    C2V_Sentinel: 'ntls/c2v',
    C2V_Guardant: 'ntls/c2v/guardant',
    LicenseStore: 'https://findface.pro'
  } as const;

  constructor() {
    this.isValidLicense = this.isValidLicense.bind(this);
    this.isRequireAttentionLicense = this.isRequireAttentionLicense.bind(this);
  }

  licenseData: LicenseData | null = null;
  usageReport: UsageReport | null = null;

  error?: Error;

  checkHasIntervals() {
    return !!Object.values(this.usageReport || {}).find((v) => v.active_limits || v.by_interval?.length);
  }

  getFeatureValue(key: string) {
    return !!this.licenseData?.products?.multi?.features?.[key]?.value;
  }

  getFeatureValueExtra(key: string) {
    return !!this.licenseData?.products?.multi?.extra?.[key];
  }

  get features(): Record<LicenseFeatureType, boolean> {
    return {
      [LicenseFeatures.LineCrossing]: this.getFeatureValue(LicenseFeatures.LineCrossing) || this.getFeatureValueExtra(LicenseFeatures.LineCrossing),
      [LicenseFeatures.BiAnalytics]: this.getFeatureValue(LicenseFeatures.BiAnalytics) || this.getFeatureValueExtra(LicenseFeatures.BiAnalytics),
      [LicenseFeatures.Alerts]: this.getFeatureValue(LicenseFeatures.Alerts) || this.getFeatureValueExtra(LicenseFeatures.Alerts)
    };
  }

  async loadLicenses() {
    const axios = dataServiceFactory.getAxiosInstance();
    try {
      const result = await axios.get(LicenseModule.Paths.License);
      this.licenseData = result.data as LicenseData;
    } catch (e: unknown) {
      this.error = e as Error;
    }
  }

  async loadUsageReport() {
    const axios = dataServiceFactory.getAxiosInstance();
    try {
      const result = await axios.get(LicenseModule.Paths.UsageReport);
      this.usageReport = result.data as UsageReport;
    } catch (e: unknown) {
      this.error = e as Error;
    }
  }

  async importLicense(license: File) {
    const arrayBuffer = await license.arrayBuffer();
    const axios = dataServiceFactory.getAxiosInstance();
    return axios
      .post<any>(LicenseModule.Paths.Import, arrayBuffer, {
        headers: {
          'Content-Length': arrayBuffer.byteLength,
          'Content-Type': 'application/octet-stream'
        }
      })
      .then(async ({ data }) => {
        await getDelay(5000);
        await configModule.loadConfig();
        return data;
      })
      .catch((e: AxiosError) => e.response?.data ?? throwError('Unable to import the license file.'));
  }

  downloadC2V(path: string): Promise<string> {
    const axios = dataServiceFactory.getAxiosInstance();
    return axios.get<string>(path).then(({ data }) => {
      const content = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
      const link = document.createElement('a');
      link.setAttribute('href', content);
      link.setAttribute('download', 'license.c2v');
      document.body.appendChild(link);
      link.click();
      return data;
    });
  }

  getLicenseStatus(item: License): LicenseFeatureStatus {
    let result: LicenseFeatureStatus = LicenseFeatureStatusItems.Disabled;
    if (this.isValidLicense(item)) {
      result = LicenseFeatureStatusItems.Success;
    } else if (this.isRequireAttentionLicense(item)) {
      result = LicenseFeatureStatusItems.Warning;
    } else {
      result = LicenseFeatureStatusItems.Error;
    }
    return result;
  }

  getLicenseTooltipText(value: LicenseFeatureStatus): string {
    let token = '';
    switch (value) {
      case LicenseFeatureStatusItems.Error:
        token = 'license.invalid_license_description';
        break;

      default:
        break;
    }
    return token ? translate(token) : '';
  }

  isValidLicense(value: License) {
    return value.valid?.valid && this.getAttentionRemainingTime(value) > 0;
  }

  isRequireAttentionLicense(value: License) {
    return value.valid?.valid && this.getAttentionRemainingTime(value) <= 0;
  }

  isInvalidLicense(valid: License) {
    return !valid.valid?.valid;
  }

  getAttentionRemainingTime(value: License) {
    return value?.expire_date * 1000 - new Date().getTime() - AttentionInterval;
  }

  getResourceStatus(item: Resource): LicenseFeatureStatus {
    const usagePercent = (item.current || 0) / item.value;
    let result: LicenseFeatureStatus = LicenseFeatureStatusItems.Success;
    if (usagePercent > 0.9) {
      result = LicenseFeatureStatusItems.Error;
    } else if (usagePercent > 0.8) {
      result = LicenseFeatureStatusItems.Warning;
    }
    return result;
  }
}

export const licenseModule = reactive(new LicenseModule());
