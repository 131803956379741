
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NTag from '@/uikit/tag/NTag.vue';

@Options({
  name: 'TableItemTags',
  components: { NTag }
})
export default class TableItemTags extends Vue {
  @Prop({ type: Array, required: true })
  readonly modelValue!: string[];

  get items() {
    return this.modelValue || [];
  }
}
