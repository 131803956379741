export default {
  source_group: 'группа источника',
  videos_and_media: 'Видео и медиа',
  videos_and_media_description_1: 'Загрузите медиафайлы',
  videos_and_media_description_2: 'Изображения “.jpg, .png и т.д.”',
  videos_and_media_description_3: 'Видео “.mp4, .mov, .avi и т.д.”',
  upload_first_media: 'загрузите первое видео',
  drag_and_drop_files: 'перетащите файлы для загрузки или',
  or: 'или',
  select_files: 'выберите файлы',
  next: 'далее',
  upload: 'загрузка',
  cancel: 'отмена',
  process_immediately: 'Обработать видео сразу после загрузки',
  detectors: 'детекторы',
  process_current: 'Обработать',
  process_all_selected: 'Обработать выбранные',
  stop_process_current: 'Остановить обработку',
  stop_process_all_selected: 'Остановить обработку выбранных',
  reset_params_all_selected: 'Сбросить параметры обработки у выбранных',
  processing: 'Обработка',
  uploading: 'Загрузка',
  file_urls: 'Ссылки на файлы',
  file_urls_placeholder: 'Введите ссылки на файлы',
  file_select_description: 'Используйте Enter, чтобы добавить несколько ссылок на файлы',
  processing_speed: 'скорость обработки',
  maximum: 'максимальная',
  time: 'время',
  episodes: 'эпизоды',
  in_total: 'всего',
  back: 'назад',
  status__completed: 'Выполнено',
  status__uploading: 'Загрузка',
  status__processing: 'Обработка',
  status__awaiting: 'Ожидание',
  status__waiting_for_sync: 'Ожидание',
  status__starting: 'Начало обработки',
  status__no_source: 'Нет источника',
  status__inprogress: 'Обработка',
  status__interrupted: 'Прервано',
  status__misconfigured: 'Ошибка конфигурации',
  status__too_many_cameras: 'Ошибка лицензии',
  status__disabled: 'Готово к обработке',
  status__not_started: 'Ошибка старта',
  status__license_error: 'Ошибка лицензии',
  status__error: 'Ошибка'
};
