
import { Options, Vue } from 'vue-class-component';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { Camera } from '@/api';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import CameraItem from '@/components/data-source/CameraItem.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { DomEvent } from 'leaflet';

@Options({
  name: 'DataSourcesCamerasGrid',
  components: { CameraItem, NButton }
})
export default class DataSourcesCamerasGrid extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: Camera[];

  mounted() {
    DomEvent.disableScrollPropagation(this.$refs.content);
  }

  openAll() {
    this.items.forEach(this.openItem);
  }

  openItem(item: Camera) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: item });
  }
}
