import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-new-items__header" }
const _hoisted_2 = { class: "heading-2xl add-new-items-header__text" }
const _hoisted_3 = { class: "add-new-items__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    "has-margins": false,
    layout: "",
    customSize: "80vw"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('cases.select_photos', 'f')), 1),
        _createVNode(_component_NButton, {
          icon: "close",
          type: "secondary",
          round: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        })
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NAttachments, {
          i18n: _ctx.attachmentsI18n,
          "disallow-attach": "",
          drag: "",
          "hide-amount": "",
          multiple: "",
          attachments: _ctx.items,
          "onUpdate:attachments": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.items) = $event))
        }, null, 8, ["i18n", "attachments"])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_NButton, {
        class: "add-new-items__select-button",
        size: "big",
        onClick: _ctx.selectHandler,
        disabled: !_ctx.isSelectEnabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('cases.add_and_process', 'f')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ]),
    _: 1
  }))
}