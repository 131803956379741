export default {
  interactions: 'Interactions',
  merge_all_selected: 'Merge',
  circle: 'circle',
  interactions_analyzis: 'interaction tracking',
  use_last_event: 'interaction search based on the episode last event',
  contact_threshold_seconds: 'Maximum gap between the appearance of individuals to consider them connected, seconds',
  first_event_date: 'First cluster event',
  event_date: 'Cluster event',
  alert_text: 'Do you want merge all selected clusters?',
  agree: 'Agree',
  cancel: 'Cancel',
  exclude_from_cluster: 'Split off',
  is_case_participant: 'Participant',
  only_clusters: 'Only clusters',
  only_participants: 'Only participants'
};
