type FeatureKeys =
  | 'sec-external-vms'
  | 'sec-genetec'
  | 'all_attrs'
  | 'body_bags'
  | 'body_emben'
  | 'body_other_attrs'
  | 'body_protective_equipment'
  | 'car_categories'
  | 'car_description'
  | 'car_emben'
  | 'car_license_plate'
  | 'car_license_plate_visibility'
  | 'car_orientation'
  | 'car_other_attrs'
  | 'car_special_types'
  | 'car_trash'
  | 'car_weight_types'
  | 'crowd_count'
  | 'face_age'
  | 'face_beard'
  | 'face_countries'
  | 'face_emotions'
  | 'face_gender'
  | 'face_glasses'
  | 'face_headpose'
  | 'face_liveness'
  | 'face_look'
  | 'face_medmask'
  | 'head_motohelmet'
  | 'tntapi_fast_index'
  | 'video_recording'
  | 'maps'
  | string;

type ResourceKeys = 'cameras' | 'extapi' | 'objects_tntapi' | string;

type ExtraKeys = 'bi-analytics' | 'line-crossing' | string;

export const FunctionalFeatureNames: FeatureKeys[] = ['sec-external-vms', 'sec-genetec', 'video_recording', 'line-crossing', 'bi-analytics', 'alerts', 'maps'];
export const DetectionOtherFeatureNames: FeatureKeys[] = ['fire', 'smoking'];

export type Feature = {
  value: boolean;
};

export type Resource = {
  current: number;
  value: number;
};

export type LicenseExtraMap = {
  [key in ExtraKeys]: boolean | string | number;
};

export type LicenseFeaturesMap = {
  [key in FeatureKeys]: Feature;
};

export type LicenseResourcesMap = {
  [key in ResourceKeys]: Resource;
};

export type Product = {
  extra: LicenseExtraMap;
  features: LicenseFeaturesMap;
  resources: LicenseResourcesMap;
};

export type License = {
  expire_date: number;
  generated: number;
  last_updated: number;
  license_id: string;
  products: Record<string, Product>;
  source: string;
  type: string;
  valid: {
    description: string;
    valid: boolean;
  };
};

export const LicensePageTabs = {
  Common: 'common',
  Licenses: 'licenses',
  Intervals: 'intervals',
  Services: 'services'
} as const;

export type LicensePageTab = typeof LicensePageTabs[keyof typeof LicensePageTabs];

export const LicenseFeatureGroupItems = {
  Functional: 'functional',
  Detections: 'detections'
};

export const DetectionGroupItems = {
  Face: 'face',
  Head: 'head',
  Body: 'body',
  Car: 'car',
  Other: 'other'
};

export const LicenseFeatureStatusItems = {
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Disabled: 'disabled'
} as const;

export type LicenseFeatureStatus = typeof LicenseFeatureStatusItems[keyof typeof LicenseFeatureStatusItems];

export type ServiceInfo = {
  ip: string;
  name: string;
}

export type LicenseData = {
  licenses: License[];
  products: Record<string, Product>;
  services: ServiceInfo[];
  time: number;
}


export type LicenseCounter = {
  used: number;
  available?: number;
};

export type ProductCounters = Record<string, LicenseCounter>;
export type IntervalProducts = Record<string, ProductCounters>;

export type Interval = {
  since: string;
  till: string | null;
  counters: IntervalProducts;
};

export type IntervalData = {
  by_interval: Interval[];
  active_limits: Interval;
};

export type UsageReport = Record<string, IntervalData>;

export const C2VSelectItems = [
  { label: 'Sentinel', value: 'sentinel' },
  { label: 'Guardant', value: 'guardant' }
];

export const ExcludedFeatureNames = ['face_countries', 'face_look'];
