import { configModule } from '@/store/config';
import { alphabeticalSort, computeFieldI18nItems, computeFieldItems } from '@/common/utilsFilterSchema';
import {
  configButtonGroup,
  configCarModel,
  configInput,
  configLicensePlateInput,
  configLicensePlateInputSmall,
  configMultipleInputSmall,
  configSelect,
  configSelectAsTags,
  configSelectAsTagsSmall,
  configSmallCarModel,
  configureAge,
  configureAngle,
  configureConfidence,
  configureRange,
  configureSmallAge,
  configureSmallAngle,
  SimpleFilterOptions
} from '@/components/common/filter/filters/types';
import { isDefined } from '@/definitions/services/helpers';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { filterRecursive } from '@/uikit/helpers';
import { addRowClasses, ConfidenceOptions, decodeFormItemName, decodeScore, entcodeScore, getListFieldWithConfidence, Positions } from './utils';

export class AttributeFilterSchemaModule {
  get faceFeatures() {
    return configModule.config.objects?.faces?.features;
  }

  get bodyFeatures() {
    return configModule.config.objects?.bodies?.features;
  }

  get carFeatures() {
    return configModule.config.objects?.cars?.features;
  }

  get attributePresentInConfigMap(): Record<string, string> {
    return {
      liveness_gte: 'liveness',

      license_plate_number_contains: 'license_plate_number',
      license_plate_number_color_confidence_gte: 'license_plate_number_color',
      category_confidence_gte: 'category_type',
      orientation_confidence_gte: 'orientation',
      weight_type_confidence_gte: 'weight_type',

      gender_score_gte: 'gender',
      age_group_score_gte: 'age_group',
      helmet_type_score_gte: 'helmet_type',
      vest_type_score_gte: 'vest_type',

      eyes_attrs_score_gte: 'eyes_attrs',
      fall_score_gte: 'fall',
    };
  }

  getFaceAttributes(options: SimpleFilterOptions, isWebhook = false, hasLiveness = true, isCluster = false): (IFormLayoutItem | IFormLayoutItem[] | undefined)[] {
    const faceAttributes = [
      this.getFaceAge(options),
      this.getFaceGender(options),
      this.getFaceBeard(options),
      isCluster ? undefined : ( isWebhook ? this.getFaceEyesWithConfidence(options) : this.getFaceEyes(options) ),
      this.getFaceGlasses(options),
      this.getFaceEmotions(options),
      isWebhook ? this.getFaceLivenessWithConfidence(options) : hasLiveness ? this.getFaceLiveness(options) : undefined,
      this.getFaceMedmask(options)
    ];

    return filterRecursive(faceAttributes, (v) => this.isFeaturePresentInConfigByFormItem(v, this.faceFeatures));
  }

  getBodyAttributes(options: SimpleFilterOptions, hasBags = true, isWebhook = false, isCluster = false): (IFormLayoutItem | IFormLayoutItem[] | undefined)[] {
    const part1 = [
      isWebhook ? this.getBodyGenderWithConfidence(options) : this.getBodyGender(options),
      isWebhook ? this.getBodyAgeGroupWithConfidence(options) : this.getBodyAgeGroup(options),
      this.getBodyHeadwear(options),
      isWebhook ? this.getBodyVestTypeWithConfidence(options) : this.getBodyVestType(options),
      isWebhook ? this.getBodyHelmetTypeWithConfidence(options) : this.getBodyHelmetType(options),
      this.getBodyTopColor(options),
      this.getBodyBottomColor(options),
      this.getBodyDetailedUpperClothes(options),
      this.getBodyLowerClothes(options),
      this.getBodyUpperClothes(options)
    ];
    const bags = [this.getBodyBagBack(options), this.getBodyBagHand(options)];
    const part2 = isCluster ? [] : [
      isWebhook ? this.getBodyFallWithConfidence(options) : this.getBodyFall(options),
      this.getBodyHandfaceSmoking(options),
      this.getBodyHandfacePhoneUse(options),
      this.getBodyHandfacePhoneCall(options)
    ];

    const bodyAttributes = [...part1, ...(hasBags ? bags : []), ...part2];

    return filterRecursive(bodyAttributes, (v) => this.isFeaturePresentInConfigByFormItem(v, this.bodyFeatures));
  }

  getCarAttributes(options: SimpleFilterOptions, isWebhook = false): (IFormLayoutItem | IFormLayoutItem[] | undefined)[] {
    const carAttributes = [
      this.getCarMake(options),
      this.getCarModel(options),
      this.getCarBody(options),
      this.getCarColor(options),
      this.getCarLicensePlateCountry(options),
      isWebhook ? this.getCarLicensePlateNumber(options) : this.getCarLicensePlateNumberContains(options),
      this.getCarLicensePlateRegion(options),
      isWebhook ? this.getCarLicensePlateNumberColorWithConfidence(options) : this.getCarLicensePlateNumberColor(options),
      this.getCarSpecialVehicleType(options),
      isWebhook ? this.getCarCategoryTypeWithConfidence(options) : this.getCarCategoryType(options),
      isWebhook ? this.getCarWeightTypeWithConfidence(options) : this.getCarWeightType(options)
    ];

    return filterRecursive(carAttributes, (v) => this.isFeaturePresentInConfigByFormItem(v, this.carFeatures));
  }

  isFeaturePresentInConfigByFormItem(formItem: IFormLayoutItem | undefined, config: Record<string, any> | undefined): boolean {
    if (!formItem) return false;
    const pathKey = formItem?.path;
    const [nameKey] = decodeFormItemName(formItem?.name || '');
    const items = [pathKey, nameKey];
    const isPresent = items.reduce<boolean>((m, keyName: string | undefined) => {
      if (keyName) {
        keyName = this.attributePresentInConfigMap[keyName] ?? keyName;
        return m || Boolean(config?.[keyName] !== undefined);
      }
      return m;
    }, false);

    if (!isPresent) {
      console.log(
        '[filters] Filter of ' + pathKey + '/' + formItem?.name + '/' + formItem?.i18n_label + '/' + formItem?.component?.name + ' is not configured.'
      );
    }
    return isPresent;
  }

  getFaceAge(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      i18n_label: 'features.faces_age'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configureSmallAge(resultOptions) : configureAge(resultOptions);
  }

  getFaceBeard(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'beard',
      i18n_label: 'features.faces_beard',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.faceFeatures?.beard, 'features.faces_beard')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceEmotions(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'emotions',
      i18n_label: 'features.faces_emotions',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.faceFeatures.emotions, 'features.faces_emotions')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceGender(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'gender',
      i18n_label: 'features.faces_gender',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.faceFeatures.gender, 'features.faces_gender')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceEyes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'eyes_attrs',
      i18n_label: 'features.faces_eyes_attrs',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.eyes_attrs, 'features.faces_eyes_attrs')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceEyesWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'eyes_attrs',
      configPrimaryField: this.getFaceEyes.bind(this),
      confidenceFieldName: 'eyes_attrs_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getFaceGlasses(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'glasses',
      i18n_label: 'features.faces_glasses',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.glasses, 'features.faces_glasses')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceHeadposeYaw(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      name: 'headpose_yaw',
      i18n_label: 'features.faces_headpose_yaw'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small
      ? configureSmallAngle(resultOptions, 'headpose_yaw_angle_gte', 'headpose_yaw_angle_lte')
      : configureAngle(resultOptions, 'headpose_yaw_angle_gte', 'headpose_yaw_angle_lte');
  }

  getFaceHeadposePitch(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      name: 'headpose_pitch',
      i18n_label: 'features.faces_headpose_pitch'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small
      ? configureSmallAngle(resultOptions, 'headpose_pitch_angle_gte', 'headpose_pitch_angle_lte')
      : configureAngle(resultOptions, 'headpose_pitch_angle_gte', 'headpose_pitch_angle_lte');
  }

  getFaceLiveness(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'liveness',
      i18n_label: 'features.faces_liveness',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.liveness, 'features.faces_liveness')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getFaceLivenessWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'liveness',
      configPrimaryField: this.getFaceLiveness.bind(this),
      confidenceFieldName: 'liveness_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getFaceMedmask(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'medmask',
      i18n_label: 'features.faces_medmask',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.faceFeatures.medmask, 'features.faces_medmask')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  // Body

  getBodyTopColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'top_color',
      i18n_label: 'features.bodies_top_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.top_color, 'features.bodies_top_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBottomColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bottom_color',
      i18n_label: 'features.bodies_bottom_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.bottom_color, 'features.bodies_bottom_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyDetailedUpperClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'detailed_upper_clothes',
      i18n_label: 'features.bodies_detailed_upper_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.detailed_upper_clothes, 'features.bodies_detailed_upper_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHeadwear(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'headwear',
      i18n_label: 'features.bodies_headwear',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.headwear, 'features.bodies_headwear')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyLowerClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'lower_clothes',
      i18n_label: 'features.bodies_lower_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.lower_clothes, 'features.bodies_lower_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyUpperClothes(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'upper_clothes',
      i18n_label: 'features.bodies_upper_clothes',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.upper_clothes, 'features.bodies_upper_clothes')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyFall(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'fall',
      i18n_label: 'features.bodies_fall',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.fall, 'features.bodies_fall')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyFallWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'fall',
      configPrimaryField: this.getBodyFall.bind(this),
      confidenceFieldName: 'fall_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getBodyHandfaceSmoking(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'handface_smoking',
      i18n_label: 'features.bodies_handface_smoking',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.handface_smoking, 'features.bodies_handface_smoking')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHandfacePhoneUse(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'handface_phone_use',
      i18n_label: 'features.bodies_handface_phone_use',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.handface_phone_use, 'features.bodies_handface_phone_use')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHandfacePhoneCall(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'handface_phone_call',
      i18n_label: 'features.bodies_handface_phone_call',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.handface_phone_call, 'features.bodies_handface_phone_call')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBagBack(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bag_back',
      i18n_label: 'features.bodies_bag_back',
      anyItem: true,
      readonly: true,
      multiple: true,
      items: computeFieldI18nItems(this.bodyFeatures?.bag_back, 'features.bodies_bag_back')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyBagHand(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'bag_hand',
      i18n_label: 'features.bodies_bag_hand',
      anyItem: true,
      readonly: true,
      multiple: true,
      items: computeFieldI18nItems(this.bodyFeatures?.bag_hand, 'features.bodies_bag_hand')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyAgeGroup(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'age_group',
      i18n_label: 'features.bodies_age_group',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.age_group, 'features.bodies_age_group')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyAgeGroupWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'age_group',
      configPrimaryField: this.getBodyAgeGroup.bind(this),
      confidenceFieldName: 'age_group_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getBodyGender(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'body_gender',
      name: 'gender',
      i18n_label: 'features.bodies_gender',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.bodyFeatures.gender, 'features.bodies_gender')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyGenderWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'body_gender',
      configPrimaryField: this.getBodyGender.bind(this),
      confidenceFieldName: 'gender_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getBodyVestType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'vest_type',
      i18n_label: 'features.bodies_vest_type',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.bodyFeatures.vest_type, 'features.bodies_vest_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyVestTypeWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'vest_type',
      configPrimaryField: this.getBodyVestType.bind(this),
      confidenceFieldName: 'vest_type_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getBodyVestTypeScore(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'vest_type_score',
      i18n_label: 'features.bodies_vest_type_score'
    };
    const resultOptions = Object.assign(baseOptions, options);
    const gteObject = {
      fieldName: 'vest_type_score_gte',
      min: 0,
      max: 100
    };
    const lteObject = {
      fieldName: 'vest_type_score_lte',
      min: 0,
      max: 100
    };
    return configureRange(resultOptions, gteObject, lteObject, decodeScore, entcodeScore);
  }

  getBodyHelmetType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'helmet_type',
      i18n_label: 'features.bodies_helmet_type',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.bodyFeatures.helmet_type, 'features.bodies_helmet_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getBodyHelmetTypeWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'helmet_type',
      configPrimaryField: this.getBodyHelmetType.bind(this),
      confidenceFieldName: 'helmet_type_score_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getBodyHelmetTypeScore(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'helmet_type_score',
      i18n_label: 'features.bodies_helmet_type_score'
    };

    const resultOptions = Object.assign(baseOptions, options);
    const gteObject = {
      fieldName: 'helmet_type_score_gte',
      min: 0,
      max: 100
    };
    const lteObject = {
      fieldName: 'helmet_type_score_lte',
      min: 0,
      max: 100
    };
    return configureRange(resultOptions, gteObject, lteObject, decodeScore, entcodeScore);
  }

  // Car

  getCarMake(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'make',
      i18n_label: 'features.cars_make',
      i18n_placeholder: 'common.choose_make',
      multiple: true,
      items: computeFieldItems(this.carFeatures.make)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelectAsTags(resultOptions);
  }

  getCarModel(options: SimpleFilterOptions): IFormLayoutItem | undefined {
    const baseOptions: SimpleFilterOptions = {
      path: 'model',
      name: 'make',
      i18n_label: 'features.cars_model',
      i18n_placeholder: 'common.choose_model',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSmallCarModel(resultOptions) : configCarModel(resultOptions);
  }

  getCarBody(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'body',
      i18n_label: 'features.cars_body',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.body, 'features.cars_body')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'color',
      i18n_label: 'features.cars_color',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.color, 'features.cars_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateCountry(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_country',
      i18n_label: 'features.cars_license_plate_country',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: alphabeticalSort(computeFieldI18nItems(this.carFeatures.license_plate_country, 'features.cars_license_plate_country')),
      i18n_placeholder: 'common.select_country'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateNumberContains(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number_contains',
      i18n_label: 'features.cars_license_plate_number',
      i18n_placeholder: 'common.enter_number_plate',
      i18n_tooltip: 'common.enter_number_plate_contains_description'
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configLicensePlateInputSmall(resultOptions) : configLicensePlateInput(resultOptions);
  }

  getCarLicensePlateNumber(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number',
      i18n_label: 'features.cars_license_plate_number',
      i18n_placeholder: 'common.enter_number_plate',
      i18n_tooltip: 'common.enter_number_plate_strict_description',
      multiple: true
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configMultipleInputSmall(resultOptions) : configInput(resultOptions);
  }

  getCarLicensePlateRegion(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_region',
      i18n_label: 'features.cars_license_plate_region',
      i18n_placeholder: 'common.select_region',
      items: computeFieldI18nItems(this.carFeatures.license_plate_region, 'features.cars_license_plate_region'),
      anyItem: true,
      multiple: true,
      readonly: false
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configSelect(resultOptions);
  }

  getCarLicensePlateNumberColor(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'license_plate_number_color',
      i18n_label: 'features.cars_license_plate_number_color',
      anyItem: true,
      multiple: true,
      readonly: true,
      items: computeFieldI18nItems(this.carFeatures.license_plate_number_color, 'features.cars_license_plate_number_color', true)
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarLicensePlateNumberColorWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'license_plate_number_color',
      configPrimaryField: this.getCarLicensePlateNumberColor.bind(this),
      confidenceFieldName: 'license_plate_number_color_confidence_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getCarSpecialVehicleType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'special_vehicle_type',
      i18n_label: 'features.cars_special_vehicle_type',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.special_vehicle_type, 'features.cars_special_vehicle_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarCategoryType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'category_type',
      i18n_label: 'features.cars_category',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.category_type, 'features.cars_category')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarCategoryTypeWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'category_type',
      configPrimaryField: this.getCarCategoryType.bind(this),
      confidenceFieldName: 'category_confidence_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getCarWeightType(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'weight_type',
      i18n_label: 'features.cars_weight_type',
      readonly: false,
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures.weight_type, 'features.cars_weight_type')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarWeightTypeWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'weight_type',
      configPrimaryField: this.getCarWeightType.bind(this),
      confidenceFieldName: 'weight_type_confidence_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getCarOrientation(options: SimpleFilterOptions): IFormLayoutItem {
    const baseOptions: SimpleFilterOptions = {
      path: 'orientation',
      i18n_label: 'features.cars_orientation',
      anyItem: true,
      multiple: true,
      items: computeFieldI18nItems(this.carFeatures?.orientation, 'features.cars_orientation')
    };
    const resultOptions = Object.assign(baseOptions, options);
    return options?.small ? configSelectAsTagsSmall(resultOptions) : configButtonGroup(resultOptions);
  }

  getCarOrientationWithConfidence(options: SimpleFilterOptions): IFormLayoutItem[] {
    const confidenceOptions: ConfidenceOptions = {
      primaryFieldName: 'orientation',
      configPrimaryField: this.getCarOrientation.bind(this),
      confidenceFieldName: 'orientation_confidence_gte'
    };
    return getListFieldWithConfidence(options, confidenceOptions);
  }

  getConfidence(options: SimpleFilterOptions) {
    return configureConfidence(options);
  }
}

export const attributeFilterSchemaModule = new AttributeFilterSchemaModule();
