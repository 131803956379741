import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fast-comments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTextareaAutosize = _resolveComponent("NTextareaAutosize")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "fast-comments__item",
        key: index
      }, [
        _createVNode(_component_NTextareaAutosize, {
          ref_for: true,
          ref: "noCommentField",
          modelValue: _ctx.modelValue[index],
          "onUpdate:modelValue": ($event: any) => ((_ctx.modelValue[index]) = $event),
          onBlur: _ctx.cleanHandler,
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur", "disabled"]),
        _createVNode(_component_NButton, {
          type: "secondary",
          icon: "trash",
          onClick: ($event: any) => (_ctx.remove(index)),
          disabled: _ctx.disabled
        }, null, 8, ["onClick", "disabled"])
      ]))
    }), 128)),
    _createVNode(_component_NButton, {
      type: "link",
      icon: "add",
      onClick: _ctx.add,
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('alerts.add_fast_comment')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"])
  ]))
}