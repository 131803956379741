
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import { formatConfidence } from '@/common/filters';
import Confidence from '@/components/common/Confidence.vue';

@Options({
  name: 'ClusterWizardItem',
  components: { Confidence, NButton }
})
export default class ClusterWizardItem extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly confidence!: number;

  @Prop({ type: Boolean, default: true })
  readonly canAdd!: boolean;

  @Prop({ type: Number })
  readonly caseId!: number;

  @Prop({ type: Boolean, default: false, required: false })
  readonly disabled!: boolean;

  get formatedConfidence() {
    return formatConfidence(this.confidence);
  }
}
