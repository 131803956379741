import { nextTick } from 'vue';
import { ListViewModel } from '@/definitions/view-models';
import { PagePath, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { getRandomId } from '@/store/router';

export type PageViewModelOptions = {
  tab: string;
  path: PagePath;
  aclName?: string;
  pageState?: Partial<PageState>;
  filter?: any;
  id?: string;
  getIsItemFormValid?: () => boolean;
};

export function parseQueryObjectToPageOptions(query: any) {
  const result: Partial<PageViewModelOptions> & { tab: string } = { tab: getRandomId() };
  const { tab, pageState } = query;
  if (tab) result.tab = tab;
  if (pageState) result.pageState = JSON.parse(pageState);
  return result;
}

export class PageViewModel<T, F> {
  protected readonly aclName?: string;
  public readonly state: PageState;
  public getIsItemFormValid?: () => boolean;

  constructor(options: PageViewModelOptions) {
    this.togglePlaying = this.togglePlaying.bind(this);
    const state = Object.assign(pageModule.getPageStateByTab(options.path, options.tab), options.pageState, { tab: options.tab });
    this.state = state;
    this.applyState();
  }

  get dataQa() {
    return this.state.pageType;
  }

  applyState() {
    const { filter } = this.state;
    nextTick(() => {
      if (filter) {
        Object.assign(this.module.filter.current, filter);
      }
    });
  }

  get moduleKey(): string {
    return pageModule.getModelsKey(this.state);
  }

  get module(): ListViewModel<T, F> /*| ItemViewModel<T>*/ {
    return pageModule.getPageModule(this.state) as any as ListViewModel<T, F>;
  }

  togglePlaying() {
    this.state.playing = !this.state.playing;
    if (this.state.playing) this.module.debouncedGet();
  }

  setFilterFromString(v: string) {
    this.module.filter.setCurrentByString(v);
  }
}
