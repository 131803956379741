
import { Options, Vue } from 'vue-class-component';
import { LicenseFeatureStatusItems } from '@/pages/license_v1/types';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {}
})
export default class LicenseLabel extends Vue {
  @Prop({ type: String, default: LicenseFeatureStatusItems.Disabled })
  status!: string;

  @Prop({ type: String, default: LicenseFeatureStatusItems.Disabled })
  size: string = 'medium';

  get classes() {
    const base = 'license-label';
    return {
      [base]: true,
      [`${base}_${this.status}`]: true,
      [`${base}_size_${this.size}`]: true
    };
  }
}
