
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import EventItemFull from '@/components/events/EventItemFull.vue';
import EventItemShort from '@/components/events/EventItemShort.vue';
import { EventOpenedItem, EventOrEpisode } from '@/components/events/types';

@Options({
  name: 'EventItem',
  components: { EventItemFull, EventItemShort }
})
export default class EventItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: EventOrEpisode;

  @Prop({ type: Number })
  index?: number;

  @Prop({ type: Boolean, default: false })
  display!: boolean;

  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  @Prop({ type: String, required: true })
  objects!: string;

  @Prop({ type: String, required: true })
  episodeType!: string;

  @Prop({ type: String, default: 'clusters' })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: Boolean, default: false })
  eventSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: String, default: '' })
  openedItem!: EventOpenedItem;

  @Prop({ type: String })
  defaultAction?: EventDetails;

  @Prop({ type: Array })
  iconActions?: [];

  @Prop({ type: Boolean, default: false })
  hideAcknowledge!: boolean;

  @Prop({ type: Boolean, default: true })
  acknowledgeAllowed!: boolean;

  get isShort() {
    return this.displayType === 'short';
  }

  get classes() {
    const base = 'event-item-proxy';
    return {
      [base + '_' + this.displayType]: true
    };
  }
}
