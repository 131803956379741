import { dataServiceFactory } from '@/definitions/services/data.services';

class TimeOffsetCalculator {
  isLoading = false;
  isUpdated = false;
  clientTimeToServerTime = 0;

  async update() {
    if (this.isLoading) {
      return;
    }
    try {
      this.isLoading = true;
      const clientTime1 = new Date().getTime();
      const axios = dataServiceFactory.getAxiosInstance();
      const { data } = await axios.get('/current-datetime');
      const serverTime = new Date(data.current_datetime).getTime();
      const clientTime2 = new Date().getTime();
      this.clientTimeToServerTime = serverTime - (clientTime1 + clientTime2) / 2;
      this.isUpdated = true;
      console.log('ClientTimeToServerTime (ms)', this.clientTimeToServerTime);
    } catch (e) {
      this.isUpdated = false;
      console.log(e);
    }
    this.isLoading = false;
  }

  updateOnce() {
    if (this.isUpdated) {
      return;
    }
    this.update();
  }

  getServerTime() {
    return new Date().getTime() + this.clientTimeToServerTime;
  }
}

const timeOffsetCalculator = new TimeOffsetCalculator();
export { TimeOffsetCalculator, timeOffsetCalculator };
