/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CaseEventsFilter = {
            /**
            * Select objects where the `case_cluster` id takes one of these values
            */
        case_cluster_in?: Array<number>;

        case_photo_in?: Array<number>;

        video_archive_in?: Array<number>;

          /**
            * Select case events related to these `cases`
            */
        case_in?: Array<number | null>;
            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select cluster events with provided ids
            */
        id_in?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Какое поле использовать для сортировки результатов.
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
}
export const EmptyCaseEventsFilter: CaseEventsFilter = {
        case_cluster_in: [],
        case_photo_in: [],
        video_archive_in: [],
        case_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        id_in: [],
        limit: '20',
        ordering: undefined,
        page: undefined,
}
