import { ItemsActionNames, ItemsActionIcons, ItemsActionName } from '@/definitions/app/item.actions.name';
import { AclResult, ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { languageModule } from '@/store/languages';
import { Action } from '@/uikit';
import { aclModule } from '../acl';

export type ActionsModuleOptions = Partial<Record<ActionsModuleOptionName, boolean>>;
export type ActionsModuleOptionName = typeof ActionsModuleOptionsNames[keyof typeof ActionsModuleOptionsNames];

export const ActionsModuleOptionsNames = {
  currentActive: 'currentActive',
  hasActive: 'hasActive',
  hasCameraReset: 'hasCameraReset',
  hasVideoReset: 'hasVideoReset',
  hasCardFilterEvent: 'hasCardFilterEvent',
  hasCameraFilterEvent: 'hasCameraFilterEvent',
  hasLineFilterEvent: 'hasLineFilterEvent',
  hasChanges: 'hasChanges',
  hasDelete: 'hasDelete',
  hasDeleteCard: 'hasDeleteCard',
  hasClusterMerge: 'hasClusterMerge',
  hasCreateCard: 'hasCreateCard',
  hasCreateParticipant: 'hasCreateParticipant',
  hasResetParticipant: 'hasResetParticipant',
  hasInteractions: 'hasInteractions',
  hasPuppeteer: 'hasPuppeteer',
  hasPuppeteerExternalSearch: 'hasPuppeteerExternalSearch',
  hasAddToMonitoring: 'hasAddToMonitoring',
  hasRemoveFromMonitoring: 'hasRemoveFromMonitoring',
  hasRestart: 'hasRestart',
  hasSave: 'hasSave',
  hasSearch: 'hasSearch',
  hasDeleteFile: 'hasDeleteFile',
  hasShowPlayer: 'hasShowPlayer',
  isArchivable: 'isArchivable',
  hasProcess: 'hasProcess',
  hasStopProcess: 'hasStopProcess',
  updateSupported: 'updateSupported',
  hasDuplicate: 'hasDuplicate'
} as const;

const ActionAdditionalTranslationsMap: Partial<Record<ItemsActionName, string>> = {
  [ItemsActionNames.AddAllToMonitoring]: 'remote_monitoring.add_all_selected_to_monitoring',
  [ItemsActionNames.AddToMonitoring]: 'remote_monitoring.add_to_monitoring',
  [ItemsActionNames.CameraResetAdvanced]: 'ds.camera_reset_advanced',
  [ItemsActionNames.CameraResetAll]: 'videos.reset_params_all_selected',
  [ItemsActionNames.CameraResetDetectors]: 'ds.camera_reset_detectors',
  [ItemsActionNames.CameraResetFull]: 'ds.camera_reset_all',
  [ItemsActionNames.CameraResetMap]: 'ds.camera_reset_map',
  [ItemsActionNames.CameraResetZone]: 'ds.camera_reset_zone',
  [ItemsActionNames.CameraReset]: 'ds.camera_reset',
  [ItemsActionNames.ClusterMergeAll]: 'clusters.merge_all_selected',
  [ItemsActionNames.DeleteVideoFileAll]: 'ds.delete_video_files_only',
  [ItemsActionNames.DeleteVideoFile]: 'ds.delete_video_files_only',
  [ItemsActionNames.ExternalSearch]: 'remote_monitoring.external_search',
  [ItemsActionNames.FilterCardEvent]: 'common.filter_events',
  [ItemsActionNames.FilterLineFaceEvents]: 'common.filter_face_events',
  [ItemsActionNames.FilterLineBodyEvents]: 'common.filter_body_events',
  [ItemsActionNames.FilterLineCarEvents]: 'common.filter_car_events',
  [ItemsActionNames.MonitoringEvents]: 'remote_monitoring.monitoring_events',
  [ItemsActionNames.ProcessAll]: 'videos.process_all_selected',
  [ItemsActionNames.Process]: 'videos.process_current',
  [ItemsActionNames.RemoveAllFromMonitoring]: 'remote_monitoring.remove_all_selected_from_monitoring',
  [ItemsActionNames.RemoveFromMonitoring]: 'remote_monitoring.remove_from_monitoring',
  [ItemsActionNames.ShowInteractions]: 'clusters.interactions',
  [ItemsActionNames.StopProcessAll]: 'videos.stop_process_all_selected',
  [ItemsActionNames.StopProcess]: 'videos.stop_process_current',
  [ItemsActionNames.VideoReset]: 'ds.camera_reset'
};

export class ActionsModule {
  public getMultipleItemsActions(modelAcl: ModelAclResult, options: ActionsModuleOptions) {
    const acl = modelAcl;
    const result: ItemsActionName[] = [];
    const updateSupported: boolean = !(options?.updateSupported === false);

    if (acl.update && updateSupported) {
      if (acl.update && options.hasProcess) {
        result.push(ItemsActionNames.ProcessAll);
      }

      if (acl.update && options.hasStopProcess) {
        result.push(ItemsActionNames.StopProcessAll);
      }

      if (acl.update && options.hasCameraReset) {
        result.push(ItemsActionNames.CameraResetAll);
      }

      if (options.hasChanges) {
        result.push(ItemsActionNames.SaveAll);
      }

      if (options.hasActive) {
        result.push(ItemsActionNames.ActivateAll);
        result.push(ItemsActionNames.DeactivateAll);
      }
    }

    if (options.isArchivable) {
      result.push(ItemsActionNames.OpenAll);
      result.push(ItemsActionNames.ArchiveAll);
    }

    if (options.hasPuppeteer && configModule.plugins.puppeteer) {
      if (arePermissionsGrantedForMonitoringAdding()) {
        result.push(ItemsActionNames.AddAllToMonitoring);
      }
      if (arePermissionsGrantedForMonitoringRemoving()) {
        result.push(ItemsActionNames.RemoveAllFromMonitoring);
      }
    }

    if (options.hasClusterMerge && acl.update) {
      result.push(ItemsActionNames.ClusterMergeAll);
    }

    if (options.hasDeleteFile && acl.delete) {
      result.push(ItemsActionNames.DeleteVideoFileAll);
    }

    if (options.hasDelete && acl.delete) {
      result.push(ItemsActionNames.DeleteAll);
    }

    if (options.hasDeleteCard) {
      result.push(ItemsActionNames.DeleteCardsInAll);
    }

    return result;
  }

  public getItemActions(acl: ItemAclResult | ModelAclResult, options: ActionsModuleOptions) {
    const currentAcl = (acl || {}) as AclResult;
    const result: ItemsActionName[] = [];
    const hasEvents = configModule.features.events_enabled;

    if (options.hasSave && currentAcl.update && options.hasChanges) {
      result.push(ItemsActionNames.Save);
    }

    if (options.hasSearch) {
      result.push(ItemsActionNames.Search);
    }

    if (options.hasResetParticipant) {
      result.push(ItemsActionNames.ResetParticipant);
    }

    if (options.hasCreateCard) {
      result.push(ItemsActionNames.CreateCard);
    }

    if (options.hasCreateParticipant) {
      result.push(ItemsActionNames.CreateParticipant);
    }

    if (options.hasCardFilterEvent && hasEvents) {
      result.push(ItemsActionNames.FilterCardEvent);
    }

    if (options.hasCameraFilterEvent && hasEvents) {
      result.push(ItemsActionNames.FilterCameraEvent);
    }

    if (options.hasLineFilterEvent && hasEvents) {
      const availableObjectsMap = dataAssetsModule.availableObjectsMap;
      availableObjectsMap.face && result.push(ItemsActionNames.FilterLineFaceEvents);
      availableObjectsMap.body && result.push(ItemsActionNames.FilterLineBodyEvents);
      availableObjectsMap.car && result.push(ItemsActionNames.FilterLineCarEvents);
    }

    if (currentAcl.update && options.hasProcess) {
      result.push(ItemsActionNames.Process);
    }

    if (currentAcl.update && options.hasStopProcess) {
      result.push(ItemsActionNames.StopProcess);
    }

    if (currentAcl.update && options.hasActive) {
      if (options.currentActive) {
        result.push(ItemsActionNames.Deactivate);
      } else {
        result.push(ItemsActionNames.Activate);
      }
    }

    if (options.hasClusterMerge && currentAcl.update) {
      result.push(ItemsActionNames.ClusterMergeAll);
    }

    if (options.hasInteractions) {
      result.push(ItemsActionNames.ShowInteractions);
    }

    if (options.hasDeleteFile && currentAcl.delete) {
      result.push(ItemsActionNames.DeleteVideoFile);
    }

    if (options.hasDelete && currentAcl.delete) {
      result.push(ItemsActionNames.Delete);
    }

    if (options.hasDeleteCard) {
      result.push(ItemsActionNames.DeleteCards);
    }

    if (options.hasRestart && currentAcl.add) {
      result.push(ItemsActionNames.Restart);
    }

    if (currentAcl.update && options.hasCameraReset) {
      result.push(ItemsActionNames.CameraReset);
    }

    if (currentAcl.update && options.hasVideoReset) {
      result.push(ItemsActionNames.VideoReset);
    }

    if (configModule.plugins.puppeteer) {

      if (options.hasAddToMonitoring && arePermissionsGrantedForMonitoringAdding()) {
        result.push(ItemsActionNames.AddToMonitoring);
      }

      if (options.hasRemoveFromMonitoring && arePermissionsGrantedForMonitoringRemoving()) {
        result.push(ItemsActionNames.RemoveFromMonitoring);
        result.push(ItemsActionNames.MonitoringEvents);
      }

      if (options.hasPuppeteerExternalSearch) {
        result.push(ItemsActionNames.ExternalSearch);
      }
    }

    if (options.hasShowPlayer) {
      result.push(ItemsActionNames.ShowPlayer);
    }

    if (options.hasDuplicate && currentAcl.add) {
      result.push(ItemsActionNames.Duplicate);
    }

    return result;
  }

  public computeActionByName(name: ItemsActionName): Action {
    const defaultPrefix = 'common.';
    const defaultI18NLabel = defaultPrefix + name.replace(/-/g, '_').replace('_current', '').replace('_all', '_all_selected');
    const i18nLabel = ActionAdditionalTranslationsMap[name] || defaultI18NLabel;
    const action = { name, label: languageModule.getTranslatedToken(i18nLabel, 'f'), hidden: true };
    if (name === ItemsActionNames.CameraReset) computeCameraResetChildren(action);
    if (name === ItemsActionNames.VideoReset) computeVideoResetChildren(action);
    return action;
  }

  getAndComputeActions(acl: ItemAclResult | ModelAclResult, options: ActionsModuleOptions) {
    return this.getItemActions(acl, options).map(this.computeActionByName);
  }

  public addIconToAction(action: Action) {
    action.icon = ItemsActionIcons[action.name as ItemsActionName] ?? '';
    return action;
  }

  public makeActionVisible(action: Action) {
    action.hidden = false;
    return action;
  }
}

function computeCameraResetChildren(cameraAction: Action) {
  cameraAction.children = [
    ItemsActionNames.CameraResetFull,
    ItemsActionNames.CameraResetAdvanced,
    ItemsActionNames.CameraResetZone,
    ItemsActionNames.CameraResetDetectors,
    ItemsActionNames.CameraResetMap
  ].map((v) => actionsModule.computeActionByName(v));
}

function computeVideoResetChildren(cameraAction: Action) {
  cameraAction.children = [
    ItemsActionNames.CameraResetFull,
    ItemsActionNames.CameraResetAdvanced,
    ItemsActionNames.CameraResetZone,
    ItemsActionNames.CameraResetDetectors
  ].map((v) => actionsModule.computeActionByName(v));
}

function arePermissionsGrantedForMonitoringAdding(): boolean {
  return aclModule.getAccess('ffsecurity_puppeteer.add_remotemonitoringrecord');
}

function arePermissionsGrantedForMonitoringRemoving(): boolean {
  return aclModule.getAccess('ffsecurity_puppeteer.delete_remotemonitoringrecord');
}

export const actionsModule = new ActionsModule();
