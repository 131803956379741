import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "license-features" }
const _hoisted_2 = { class: "license-feature-group__name heading-m" }
const _hoisted_3 = {
  key: 0,
  class: "license-feature-subgroup__name label-m"
}
const _hoisted_4 = { class: "license-feature-subgroup__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseTag = _resolveComponent("LicenseTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (featureGroup) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "license-feature-group",
        key: `g-${featureGroup.value}`
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(featureGroup.label), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSubGroups(featureGroup), (subGroup) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "license-feature-group__content",
            key: 'sg-' + subGroup.value
          }, [
            (_ctx.getFeatureItems(subGroup).length)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (featureGroup.value !== subGroup.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(subGroup.label), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFeatureItems(subGroup), (featureItem) => {
                      return (_openBlock(), _createBlock(_component_LicenseTag, {
                        key: `tag-${featureItem.name}`,
                        status: featureItem.status,
                        tooltip: featureItem.tooltip
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(featureItem.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["status", "tooltip"]))
                    }), 128))
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}