
import { Options, Vue } from 'vue-class-component';
import { componentsData } from './components.data';
import { cloneDeep, set } from 'lodash';

@Options({
  name: 'UiComponentsPage',
  components: {}
})
export default class UiComponentsPage extends Vue {
  get componentsData() {
    return componentsData;
  }

  getLayoutClass(value?: string) {
    return value ? `ui-components__item-view_${value}` : undefined;
  }

  getPropsDescription(value?: Record<string, any>) {
    return value ? Object.keys(value || {}).join(', ') : 'none';
  }

  applyProps(base: Record<string, any>, props?: null | Record<string, any>) {
    const result = cloneDeep(base);
    if (props) Object.keys(props).forEach((key) => set(result, key, props[key]));
    return result;
  }
}
