import { dataAssetsModule } from '@/store/application/data.assets.module';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { attributeFilterSchemaModule } from '@/components/common/filter/filters/AttributeFilterSchemaModule';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { OptionalIFormLayout, SimpleFilterOptions } from '@/components/common/filter/filters/types';

export function getCaseFiltersBuilder(options: SimpleFilterOptions) {
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getHasUnacknowledgedCaseClusters(options),
    commonFilterSchemaModule.getRecordIdContains(options),
    commonFilterSchemaModule.getDateRange({ ...options, i18n_label: 'common.creation_date' }),
    commonFilterSchemaModule.getId(options)
  ];
  return commonAll;
}

export function getCaseParticipantsFilters(options: SimpleFilterOptions) {
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getNameContains(options),
    commonFilterSchemaModule.getCaseRole(options),
    commonFilterSchemaModule.getDateTimeRange(options)
  ];
  return commonAll;
}

export function getCaseClusterFiltersBuilder(options: SimpleFilterOptions) {
  const availableObjects = dataAssetsModule.availableObjectsTypedMap;
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getIsCaseParticipant(options),
    commonFilterSchemaModule.getAcknowledged({ ...options, path: 'acknowledged' }),
    commonFilterSchemaModule.getHasCard(options),
    commonFilterSchemaModule.getMatchedLists(options),
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getId(options),
    commonFilterSchemaModule.getPhotoId(options),
    commonFilterSchemaModule.getVideoId(options)
  ];

  const faceFeatures: OptionalIFormLayout[] = availableObjects.face ? attributeFilterSchemaModule.getFaceAttributes(options, false, false) : [];
  const bodyFeatures: OptionalIFormLayout[] = availableObjects.body ? attributeFilterSchemaModule.getBodyAttributes(options, false) : [];
  const carFeatures: OptionalIFormLayout[] = availableObjects.car ? attributeFilterSchemaModule.getCarAttributes(options) : [];

  function getFilterByType(objectType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [];
    switch (objectType) {
      case 'faces':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...faceFeatures];
        break;
      case 'bodies':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...bodyFeatures];
        break;
      case 'cars':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...carFeatures];
        break;
    }
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}

export function getClusterFiltersBuilder(options: SimpleFilterOptions) {
  const availableObjects = dataAssetsModule.availableObjectsTypedMap;
  const commonAll: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getHasCard(options),
    commonFilterSchemaModule.getMatchedLists(options),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    commonFilterSchemaModule.getCardSelect(options),
    commonFilterSchemaModule.getDateTimeRange(options),
    commonFilterSchemaModule.getFirstClusterEventDateTimeRange(options),
    commonFilterSchemaModule.getClusterEventDateTimeRange(options),
    commonFilterSchemaModule.getId(options)
    /* commonFilterSchemaModule.getHasCaseParticipants(options) @todor: make allowed modules by user & config provider */
  ];

  const faceFeatures: OptionalIFormLayout[] = availableObjects.face ? attributeFilterSchemaModule.getFaceAttributes(options, false, false, true) : [];
  const bodyFeatures: OptionalIFormLayout[] = availableObjects.body ? attributeFilterSchemaModule.getBodyAttributes(options, false, false, true) : [];
  const carFeatures: OptionalIFormLayout[] = availableObjects.car ? attributeFilterSchemaModule.getCarAttributes(options) : [];

  function getFilterByType(pageType: string, objectType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [];
    switch (objectType) {
      case 'faces':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...faceFeatures];
        break;
      case 'bodies':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...bodyFeatures];
        break;
      case 'cars':
        filterSchema = [...commonAll, commonFilterSchemaModule.getAttributesDivider(options), ...carFeatures];
        break;
    }
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}

export function getClusterContactsFiltersBuilder(options: SimpleFilterOptions) {
  const commonAll: OptionalIFormLayout[] = [commonFilterSchemaModule.getUseLastEvent(options), commonFilterSchemaModule.getContactThreshold(options)];

  function getFilterByType(pageType: string, objectType: string): IFormLayoutItem[] {
    let filterSchema: OptionalIFormLayout[] = [...commonAll, ...getClusterFiltersBuilder(options).getFilterByType(pageType, objectType)];
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}
