
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

export interface AnnexAlarmFeaturesItem {
  model: string;
  extractor: string;
  result: {
    name: string;
    confidence: number;
  };
}

@Options({
  name: 'AnnexAlarmFeatures'
})
export default class AnnexAlarmFeatures extends Vue {
  @Prop({ type: Object, required: true })
  readonly features!: AnnexAlarmFeaturesItem[];

  slug(str: string) {
    return String(str).replace(/[^a-z0-9]+/g, '_');
  }
}
