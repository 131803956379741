import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_DataSourcesVideosTable = _resolveComponent("DataSourcesVideosTable")!
  const _component_EmptyPage = _resolveComponent("EmptyPage")!
  const _component_AddNewWizard = _resolveComponent("AddNewWizard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.module.loaded && _ctx.module.items.length)
      ? (_openBlock(), _createBlock(_component_NBaseBar, {
          key: 0,
          class: "case-videos__header"
        }, {
          start: _withCtx(() => [
            _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"])
          ]),
          end: _withCtx(() => [
            (_ctx.modelAcl.add)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  onAction: _ctx.enableWizard,
                  icon: "upload",
                  type: "secondary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('ds.upload', 'f')), 1)
                  ]),
                  _: 1
                }, 8, ["onAction"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.module.loading || _ctx.module.items.length)
      ? (_openBlock(), _createBlock(_component_DataSourcesVideosTable, {
          key: 1,
          "model-acl": _ctx.modelAcl,
          items: _ctx.module.items,
          item: _ctx.item,
          "selected-items": _ctx.sidebarSelectedItemIds,
          onSelect: _ctx.handleSelect,
          onUpdateSubPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateSubPage', $event)))
        }, null, 8, ["model-acl", "items", "item", "selected-items", "onSelect"]))
      : (_openBlock(), _createBlock(_component_EmptyPage, {
          key: 2,
          i18n_title: "cases.new_video",
          i18n_description: "cases.new_video_description",
          i18n_buttonText: "cases.create_first_video",
          imageType: "--image-empty-video",
          disabledAddButton: _ctx.isCaseArchived || !_ctx.modelAcl.add,
          onCreate: _ctx.enableWizard
        }, null, 8, ["disabledAddButton", "onCreate"])),
    (_ctx.wizardEnabled)
      ? (_openBlock(), _createBlock(_component_AddNewWizard, {
          key: 3,
          modelValue: this.pageType,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.pageType) = $event)),
          "enable-data-source-selection": false,
          onUpload: _ctx.uploadCaseHandler,
          onClose: _ctx.closeWizard
        }, null, 8, ["modelValue", "onUpload", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}