import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "n-button-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NDropdown, { placement: "bottom" }, {
      toggle: _withCtx(({ toggle }) => [
        _createVNode(_component_NButton, {
          type: _ctx.type,
          size: _ctx.size,
          "suffix-icon": "chevron-down",
          onClick: toggle,
          color: _ctx.color,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default"),
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 2
        }, 1032, ["type", "size", "onClick", "color", "disabled"])
      ]),
      body: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_NDropdownItem, {
            key: item.value,
            icon: item.icon,
            color: item.color,
            onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["icon", "color", "onClick"]))
        }), 128))
      ]),
      _: 3
    })
  ]))
}