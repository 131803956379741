
export const NTabItemSize: Record<string, string> = {
  normal: 'm',
  big: 'l'
};

import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import NCounter from '../../counters/NCounter.vue';
import NIcon from '../../icons/NIcon.vue';

@Options({
  components: {
    NCounter,
    NIcon
  },
  emits: ['tabSelect', 'prefixAction', 'suffixAction']
})
export default class NTabItem extends Vue {
  NIconDefaultParams = {
    Width: 18,
    Height: 18
  };

  @Prop({ type: String, default: 'normal' })
  size!: string;

  @Prop({ type: Number, default: 0 })
  tabIndex!: number;

  @Prop({ type: String, default: '' })
  prefixIcon?: string;

  @Prop({ type: String, default: '' })
  suffixIcon?: string;

  @Prop({ type: String, default: 'Tab' })
  label!: string;

  @Prop({ type: Number })
  counter?: number;

  @Prop({ type: Boolean, default: false })
  active!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: String, default: 'horizontal' })
  direction?: string;

  focused = false;

  get classes(): Record<string, boolean> {
    const result = {
      [`control-${this.tabSize}`]: true,
      'n-tab_active': this.active,
      'n-tab_disabled': this.disabled,
      'n-tab_vertical': this.direction === 'vertical'
    };
    return result;
  }

  handleActive() {
    if (!this.disabled) {
      this.$emit('tabSelect');
    }
  }

  keydownHandler(e: KeyboardEvent) {
    if (this.focused && e.key === 'Enter') this.handleActive();
  }

  @Emit('prefixAction')
  handlePrefixAction() {}

  @Emit('suffixAction')
  handleSuffixAction() {}

  get tabSize() {
    return NTabItemSize[this.size] || NTabItemSize.normal;
  }
}
