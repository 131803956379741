
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { imageLoader } from '@/store/image-loader';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import { getDelay } from '@/definitions/common/base';

const minHeight = '90px';
const maxHeight = '480px';
const maxWidth = '640px';

@Options({
  name: 'CameraScreenshot',
  components: { NButton, NIcon, NLoadingCircle }
})
export default class CameraScreenshot extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: String, default: '' })
  readonly refreshKey!: string;

  @Prop({ type: Boolean, default: false })
  readonly hasPlay!: boolean;

  @Prop({ type: String, default: '160px' })
  readonly displayWidth!: string;

  @Prop({ type: Number, default: 160 })
  readonly width!: number;

  @Prop({ type: Number, default: 0 })
  readonly height!: number;

  @Prop({ type: Number, default: 3000 })
  readonly delay!: number;

  @Prop({ type: Boolean, default: 0 })
  readonly showErrorState!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly refreshOnClick!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  protected loading = false;
  protected error = false;

  get style() {
    return {
      width: this.displayWidth
    };
  }

  get imageScreenshotStyle() {
    return {
      margin: '0 auto',
      display: 'block',
      maxHeight: this.width > 0 ? this.width + 'px' : maxHeight,
      maxWidth: this.width > 0 ? this.width + 'px' : maxWidth,
      visibility: this.loading || this.error ? 'hidden' : 'visible'
    };
  }

  getUrl() {
    return this.modelValue + (this.width ? '?width=' + this.width : '') + (this.height ? '&height=' + this.height : '');
  }

  async load(resetCache = false) {
    if (this.disabled) return;

    if (this.showErrorState) {
      this.loading = false;
      return;
    }
    try {
      this.error = false;
      this.loading = true;
      await getDelay(this.delay);
      this.$refs.img.src = await this.loadImage(resetCache);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async loadImage(resetCache = false) {
    const url = this.getUrl();
    return url.indexOf('case_photos') > -1 ? this.modelValue : await imageLoader.get(this.getUrl(), resetCache);
  }

  refreshScreenshot() {
    this.refreshOnClick && !this.loading && this.load(true);
  }

  mounted() {
    this.load();
  }

  @Watch('modelValue')
  modelValueWatcher() {
    this.load();
  }

  @Watch('refreshKey')
  refreshKeyWatcher() {
    this.refreshScreenshot();
  }

  @Watch('showErrorState')
  showErrorStateWatcher() {
    this.refreshScreenshot();
  }

  play() {
    const cameraId = this.modelValue.match(/\/cameras\/(\d+)\/screenshot\//);
    const videoId = this.modelValue.match(/\/videos\/(\d+)\/screenshot\//);
    if (cameraId) {
      this.$videoPlayer.playCamera(Number(cameraId[1]));
    } else if (videoId) {
      this.$videoPlayer.playArchive(Number(videoId[1]), 0);
    }
  }
}
