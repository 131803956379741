
import { Options, Prop, Vue } from 'vue-property-decorator';
import type { Camera, CameraGroup } from '@/api';
import VideoWallCameraListGroupHeader from './VideoWallCameraListGroupHeader.vue';
import VideoWallCameraListGroupItem from './VideoWallCameraListGroupItem.vue';

@Options({
  components: {
    VideoWallCameraListGroupHeader,
    VideoWallCameraListGroupItem
  }
})
export default class VideoWallCameraListGroup extends Vue {
  @Prop({ required: true, type: Array })
  readonly cameras!: Camera[];
  @Prop({ required: true, type: Object })
  readonly group!: CameraGroup;
  expanded: boolean = false;
}
