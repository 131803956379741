
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NHint, NIcon } from '@/uikit/index';

@Options({
  name: 'NTableProgressCell',
  components: { NIcon, NHint }
})
export default class NTableProgressCell extends Vue {
  @Prop({ type: Number, default: 0 })
  readonly floatValue = 0; // 0 - 1

  @Prop({ type: String, default: '' })
  readonly label = '';

  @Prop({ type: String, default: '' })
  readonly error = '';

  get percentValue() {
    return Math.round(this.floatValue * 100);
  }
}
