
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NewItemIdStart } from '@/store/multisidebar';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';

@Options({
  name: 'RemoteMonitoringItemCommonFields',
  components: { ButtonModelCopy, NDateTimeLabel }
})
export default class RemoteMonitoringItemCommonFields extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringEvent;

  get isNew() {
    return this.item.id <= NewItemIdStart;
  }
}
