import { reactive, watch } from 'vue';
import { getDelay } from '@/definitions/common/base';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { getViewModelByName, viewModelRepository } from '@/api/common';
import { CardSingleType, CardsSingleToMulti, CardTypesMap, ObjectsSingleToMulti, ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageState, SearchPageState } from '@/store/application/page.definitions';
import { NAttachment, NAttachments } from '@/uikit/attachments';
import { Debounce } from '@/common/debounce-decorator';
import { getCurrentItemStateFromUrl, getExternalImage } from '@/pages/search/helpers';
import { SearchAttachmentItemViewModel } from '@/pages/search/SearchAttachmentItemViewModel';

export class SearchSourceViewModel {
  constructor(public state: SearchPageState) {
    watch(() => this.modelType, this.clearModel.bind(this), { immediate: true });
  }

  public bboxThumbnail: any;

  private urlParseError = false;
  private _sourceVM: ItemViewModel<any> | null = null;

  public get isFile(): boolean {
    return this.state.searchFrom === SearchFromMap.File;
  }

  public get loading() {
    return this.sourceVM?.loading;
  }

  public get loaded() {
    return this.sourceVM?.loaded;
  }

  public get hasError() {
    return this.sourceVM?.loadError || this.urlParseError;
  }

  public get sourceItem() {
    return this.sourceVM?.item;
  }

  public get availableObjects() {
    let availableObject: string[] = [];
    switch (this.state.searchFrom) {
      case SearchFromMap.Events:
        availableObject = dataAssetsModule.availableObjects;
        break;
      case SearchFromMap.Clusters:
        availableObject = dataAssetsModule.availableClusterObjects;
        break;
      case SearchFromMap.Cards:
        availableObject = dataAssetsModule.availableCardTypes;
        break;
    }
    return availableObject.map((v) => CardsSingleToMulti[v as CardSingleType] || ObjectsSingleToMulti[v]);
  }

  public get availableSearchFromItems() {
    return dataAssetsModule.searchFromTypes;
  }

  @Debounce(500)
  public async load(source?: string | NAttachment | null) {
    this.resetErrors();
    if (typeof source === 'object') {
      this.state.searchFrom = SearchFromMap.File;
      await getDelay(100);
      this.sourceVM?.setItemsState(source as NAttachment);
    } else if (typeof source === 'string' && /^(http(s)?):/.test(source)) {
      const sourceState = getCurrentItemStateFromUrl(source);
      const hasState = Object.keys(sourceState).length;
      if (hasState) {
        const hasSupportedPageType = this.availableSearchFromItems.find((v) => v.value === sourceState.pageType);
        if (hasSupportedPageType) {
          this.setStateByUrlParseResult(sourceState);
          await this.loadItem();
        } else {
          this.urlParseError = true;
        }
      } else {
        this.state.searchFrom = SearchFromMap.File;
        this.clearModel();
        await this.loadItem();
      }
    } else {
      if (this.state.searchFrom !== SearchFromMap.File) {
        await this.loadItem();
      }
    }
  }

  private setStateByUrlParseResult(itemState: Partial<PageState>) {
    if (itemState.pageType && itemState.objectType && itemState.id && this.availableSearchFromItems.find((v) => v.value === itemState.pageType)) {
      const result: Record<string, any> = {
        id: itemState.id,
        objectType: itemState.objectType,
        cardType: itemState.objectType === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        episodeType: itemState.objectType === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        searchFrom: itemState.pageType
      };
      Object.assign(this.state, result);
    }
  }

  get modelType() {
    const { searchFrom, objectType, cardType } = this.state;
    if (!searchFrom) return null;
    if (searchFrom == SearchFromMap.File) return 'file';
    if (searchFrom == SearchFromMap.CaseClusters) return 'case_clusters';
    if (searchFrom === SearchFromMap.Events && !objectType) return null;
    if ((searchFrom === SearchFromMap.Cluster || searchFrom === SearchFromMap.Cards) && !cardType) return null;
    return this.state.searchFrom === SearchFromMap.Cards ? `${searchFrom}_${cardType}` : `${searchFrom}_${objectType}`;
  }

  get sourceVM() {
    if (this.modelType) {
      if (!this._sourceVM) {
        this._sourceVM = this.getNewModel();
      }
    }
    return this._sourceVM;
  }

  clearModel() {
    this._sourceVM = null;
  }

  getNewModel() {
    return this.isFile ? new SearchAttachmentItemViewModel() : (getViewModelByName(this.modelType!) as ItemViewModel<any>);
  }

  private async loadItem() {
    if (this.state.id) {
      try {
        const ids = (String(this.state.id) || '').split(',').map((v: string) => v.trim()).filter((v: string) => !!v);
        for (const id of ids) {
          await this.sourceVM?.get(id);
        }
        if (!this.isFile) this.state.detectionId = String(ids.join(', '));
      } catch (e) {
        if (!e.isAxiosError) {
          throw e;
        }
      }
    } else {
      this.sourceVM?.clear();
      this.state.detectionId = '';
    }
  }

  public resetErrors() {
    this.urlParseError = false;
    this.sourceVM && (this.sourceVM.loadError = null);
  }

  public static create(state: SearchPageState): SearchSourceViewModel {
    return reactive(new SearchSourceViewModel(state)) as SearchSourceViewModel;
  }
}
