import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "annex-alarm-status-helper-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_AnnexAlarmStatusHelper = _resolveComponent("AnnexAlarmStatusHelper")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NHint, {
      type: "annex-alarm-status-helper",
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NIcon, { name: "info" })
      ]),
      content: _withCtx(() => [
        _createVNode(_component_AnnexAlarmStatusHelper)
      ]),
      _: 1
    })
  ]))
}