import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "items-view__loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Statistics = _resolveComponent("Statistics")!
  const _component_SortDropdown = _resolveComponent("SortDropdown")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_DataSourcesPhotosTable = _resolveComponent("DataSourcesPhotosTable")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!
  const _component_EmptyPage = _resolveComponent("EmptyPage")!
  const _component_AddNewItemsModal = _resolveComponent("AddNewItemsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.module.loading || _ctx.module.items.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_NBaseBar, null, {
            start: _withCtx(() => [
              _createVNode(_component_Statistics, { module: _ctx.module }, null, 8, ["module"]),
              _createVNode(_component_SortDropdown, {
                items: _ctx.sortTypes,
                modelValue: _ctx.module.filter.current.ordering,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.module.filter.current.ordering) = $event))
              }, null, 8, ["items", "modelValue"])
            ]),
            end: _withCtx(() => [
              (_ctx.modelAcl.add)
                ? (_openBlock(), _createBlock(_component_NButton, {
                    key: 0,
                    onAction: _ctx.displayAddNewModal,
                    icon: "upload",
                    type: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('cases.upload')), 1)
                    ]),
                    _: 1
                  }, 8, ["onAction"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_DataSourcesPhotosTable, {
            "model-acl": _ctx.modelAcl,
            items: _ctx.module.items,
            "selected-items": _ctx.sidebarSelectedItemIds,
            isCaseArchived: _ctx.isCaseArchived,
            onSelect: _ctx.handleSelect
          }, null, 8, ["model-acl", "items", "selected-items", "isCaseArchived", "onSelect"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.module.loading || _ctx.module.appending)
              ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_InfiniteScroll, {
            filters: _ctx.module.filter.current,
            onLoadMore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.module.append()))
          }, null, 8, ["filters"])
        ], 64))
      : (_openBlock(), _createBlock(_component_EmptyPage, {
          key: 1,
          i18n_title: "cases.new_photo",
          i18n_description: "cases.new_photo_description",
          i18n_buttonText: "cases.create_first_photo",
          imageType: "--image-empty-photo",
          disabledAddButton: !_ctx.modelAcl.add,
          onCreate: _ctx.displayAddNewModal
        }, null, 8, ["disabledAddButton", "onCreate"])),
    (_ctx.addNewDialogVisisble)
      ? (_openBlock(), _createBlock(_component_AddNewItemsModal, {
          key: 2,
          onAdd: _ctx.addNewHandler,
          onClose: _ctx.hideAddNewModal
        }, null, 8, ["onAdd", "onClose"]))
      : _createCommentVNode("", true)
  ], 64))
}