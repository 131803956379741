
import { Options, Prop, Vue } from 'vue-property-decorator';
import NIcon from '../icons/NIcon.vue';
import type { NAttachmentsI18n } from './types';

@Options({
  components: { NIcon },
  emits: ['attach']
})
export default class NAttachmentsPicker extends Vue {
  @Prop({ required: true, type: Number })
  readonly amount!: number;
  @Prop({ required: true, type: Object })
  readonly i18n!: Readonly<Required<NAttachmentsI18n>>;

  get attachText() {
    return this.amount ? this.i18n.attach_more : this.i18n.attach_first_one;
  }

  dispatchAttachEvent(): void {
    this.$emit('attach');
  }
}
