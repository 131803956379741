
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import { PointAsArray } from '@/uikit/draw/types';
import { generateV4UUID } from '@/common/uuid';
import { LatLngType } from '@/components/map/types';
import { FaceEvent, HumanEpisode } from '@/api';
import { MapMarkerColorKey, MapMarkerColors, MapMarkerColorsConfig } from '@/components/map/CommonMapConfig';
import { computeColors } from '@/components/map/helpers';

const FullRadius = 5;
const SizeOffset = 6;

@Options({
  name: 'EventMarker',
  components: {
    LMarker,
    LIcon
  },
  emits: ['select']
})
export default class EventMarker extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: HumanEpisode | FaceEvent;

  @Prop({ type: Object, required: true })
  readonly colorsConfig!: MapMarkerColorsConfig;

  @Prop({ type: Object, required: true })
  readonly latLng!: LatLngType;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly opened!: boolean;

  @Prop({ type: Number, default: 0 })
  readonly rotation!: number;

  readonly clickTargetDataId = 'event-marker-click-target';
  readonly gradientId = generateV4UUID();

  private currentTime = new Date().getTime();
  private syncTimeInterval = 0;

  get type(): string {
    const difference = (this.currentTime - new Date(this.item.created_date).getTime()) / 1e3;
    const maxNewDifference = 22; // Presentation 1800, production 22
    const maxDefaultDifference = 1800; // Presentation 3600 * 2, production 1800
    let result = 'default';
    if (difference < maxNewDifference) {
      result = 'new';
    } else if (difference > maxDefaultDifference) {
      result = 'old';
    }
    return result;
  }

  get openedCircleClass() {
    return {
      'event-marker__opened-circle': true,
      'event-marker__opened-circle_open': this.opened,
      'event-marker__opened-circle_select': this.selected
    };
  }

  get iconClass() {
    return {
      'event-marker__icon': true,
      ['event-marker__icon_selected']: this.selected
    };
  }

  get fullRadius() {
    return FullRadius;
  }

  get center(): PointAsArray {
    return [this.size / 2, this.size / 2];
  }

  get size() {
    return (this.fullRadius + SizeOffset) * 2;
  }

  get iconSvgStyle() {
    return {
      // filter: `drop-shadow(0px 0px 10px ${this.computedColor})`
    };
  }

  handleClick(e: any) {
    if (e.originalEvent.target.dataset?.id === this.clickTargetDataId) {
      this.$emit('select');
    }
  }

  get itemColors() {
    const color = computeColors(this.item.matched_card, this.colorsConfig).default;
    return {
      fillColor: color,
      borderColor: color
    };
  }

  get isNew() {
    return this.type === 'new';
  }

  mounted() {
    if (this.isNew) this.syncTimeInterval = setInterval(() => (this.currentTime = new Date().getTime()), 1000) as any;
  }

  beforeDestroy() {
    if (this.syncTimeInterval) clearInterval(this.syncTimeInterval);
  }
}
