
import { isEmpty } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { OnvifCamerasService } from '@/api';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import notify from '@/uikit/notification/helpers/notification';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'DataSourcesOnvifProfileStreams',
  components: { NLoadingCircle, NSelect }
})
export default class DataSourcesOnvifProfileStreams extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;

  @Prop({ type: Object, required: true })
  readonly model!: any;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  private loading = false;
  private profileToken = '';

  get streams() {
    if (isEmpty(this.model.meta) || isEmpty(this.model.meta.media)) {
      return [];
    }
    return this.model.meta.media.Profiles.map((item: any) => ({ label: item.Name, value: item.token }));
  }

  get modelId() {
    return this.model.id;
  }

  @Watch('profileToken')
  async handleProfileToken() {
    this.loading = true;
    try {
      const id = this.modelId;
      await OnvifCamerasService.onvifCamerasStartStreamingCreate(id, {
        profile_token: this.profileToken
      });
      const streams = await OnvifCamerasService.onvifCamerasStreamUriRetrieve(id, this.profileToken);
      this.$emit('update:modelValue', streams.Uri);
    } catch (e) {
      await notify({ content: this.$t('errors.server.check_onvif'), showClose: true, type: 'error' });
    } finally {
      this.loading = false;
    }
  }
}
