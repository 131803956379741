import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "annex-alarm-acknowledge-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnexAlarmTimer = _resolveComponent("AnnexAlarmTimer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.adaptedItem.acknowledge === 'none')
      ? (_openBlock(), _createBlock(_component_AnnexAlarmTimer, {
          key: _ctx.timeFrom,
          from: _ctx.timeFrom,
          to: 0,
          step: -1,
          i18n_message: "annex_alarms.auto_acknowledge_mf",
          onFinished: _ctx.timerFinished
        }, null, 8, ["from", "onFinished"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(_ctx.acknowledgeMessage), 1)
        ], 64))
  ]))
}