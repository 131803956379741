import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "case-cluster-item" }
const _hoisted_2 = { class: "case-cluster-item__case-name label-m" }
const _hoisted_3 = {
  key: 0,
  class: "case-cluster-item__case-name label-m"
}
const _hoisted_4 = { class: "case-cluster-item__confidence" }
const _hoisted_5 = { class: "case-cluster-item__icon-container" }
const _hoisted_6 = {
  key: 1,
  class: "case-cluster-item__confidence"
}
const _hoisted_7 = { class: "case-cluster-item__card-name" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "case-cluster-item__card-name_unknown"
}
const _hoisted_10 = {
  key: 3,
  class: "case-cluster-item__reaction"
}
const _hoisted_11 = {
  key: 4,
  class: "case-cluster-item__participant-block"
}
const _hoisted_12 = {
  key: 0,
  class: "case-cluster-item__name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_WatchListLabelsShort = _resolveComponent("WatchListLabelsShort")!
  const _component_Acknowledge = _resolveComponent("Acknowledge")!
  const _component_ParticipantRole = _resolveComponent("ParticipantRole")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showCase && _ctx.parentCase)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "case-cluster-item__case",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('caseClick')), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.parentCase.name), 1),
          (!_ctx.hideCaseDate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$filters.formatDate(_ctx.parentCase.incident_date)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["case-cluster-item__container", _ctx.clusterClasses])
    }, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "thumbnailConfidence")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_NThumbnail, {
          modelValue: _ctx.item?.best_event?.thumbnail,
          corners: _ctx.inSidebar ? 'rounded' : _ctx.isParticipant ? 'circle' : 'rounded',
          actions: _ctx.actions,
          onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select'))),
          onAction: _cache[2] || (_cache[2] = (v) => _ctx.$emit('action', v))
        }, null, 8, ["modelValue", "corners", "actions"])
      ]),
      (_ctx.showEventsCount)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "case-cluster-item__events-count label-mono-s",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('action', 'ShowEvents')), ["stop"]))
          }, _toDisplayString(_ctx.item.events_count), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showConfidence && _ctx.item.matched_card?.confidence)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Confidence, {
            value: _ctx.item.matched_card.confidence,
            objects: _ctx.objects
          }, null, 8, ["value", "objects"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showCard && _ctx.card)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "case-cluster-item__card",
          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.$emit('cardClick', this.card)), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.cardName)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.cardName), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('common.unknown', 'f')), 1))
          ]),
          (_ctx.card?.watch_lists?.length)
            ? (_openBlock(), _createBlock(_component_WatchListLabelsShort, {
                key: 0,
                type: "short",
                items: _ctx.card?.watch_lists
              }, null, 8, ["items"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showAcknowledge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.item.matched_card)
            ? (_openBlock(), _createBlock(_component_Acknowledge, {
                key: 0,
                modelValue: _ctx.item.matched_card.acknowledged,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.matched_card.acknowledged) = $event)),
                onlyToTrue: true,
                disabled: _ctx.disabled || !_ctx.canChange,
                onAction: _cache[6] || (_cache[6] = (...args) => _ctx.$emit('action', ...args))
              }, null, 8, ["modelValue", "disabled"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isParticipant && (_ctx.showName || _ctx.showRole))
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_ctx.showName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.item.name), 1))
            : _createCommentVNode("", true),
          (_ctx.showRole)
            ? (_openBlock(), _createBlock(_component_ParticipantRole, {
                key: 1,
                modelValue: _ctx.item.role,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.role) = $event)),
                readonly: true
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}