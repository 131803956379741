
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import { dataModule } from '@/store/data';
import { NButton } from '@/uikit';
import { EventOrEpisode } from '@/components/events/types';

@Options({
  name: 'AcknowledgeInfo',
  components: { NButton }
})
export default class AcknowledgeInfo extends Vue {
  @Prop({ type: Object as PropType<EventOrEpisode>, required: true })
  readonly item!: EventOrEpisode;

  get module() {
    const module = viewModelRepository.getUsersListViewModel();
    module.aclModelName = 'user';
    return module;
  }

  get dataModule() {
    return dataModule;
  }

  get acknowledgedDate() {
    return this.$filters.formatDateTime(this.item.acknowledged_date);
  }

  get acknowledgedBy() {
    return this.item.acknowledged_by && this.dataModule.getUserNameById(this.item.acknowledged_by);
  }

  get users() {
    return dataModule.usersModule.items;
  }

  get acknowledgedByUser() {
    return this.users.find((user) => user.real_name === this.acknowledgedBy);
  }

  navigateToUser() {
    this.$emit('action', ItemsActionNames.NavigateToUser, this.acknowledgedByUser);
  }
}
