import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';
import TimelineObject, { IObjectItem, ObjectItemType } from '../timeline-object';

export default class TimelineEventsCars extends TimelineObject {
  items: IObjectItem[] = [];
  type = ObjectItemType.Event;
  model = viewModelRepository.getEventsCarsListViewModel();

  protected async load(cameras: number[], timeFrom: number, timeTo: number): Promise<void> {
    this.model.filter.current.limit = configModule.timeline_objects?.events.cars.limit;
    this.model.filter.current.cameras = cameras;
    this.model.filter.current.created_date_gte = new Date(timeFrom * 1000).toISOString();
    this.model.filter.current.created_date_lte = new Date(timeTo * 1000).toISOString();
    await this.model.get();
    this.isLoadedAll = this.model.next_page === null;
    const newItems = this.model.items.map((item) => {
      const from = new Date(item.created_date).getTime() / 1000;
      return {
        id: item.id,
        type: ObjectItemType.Event,
        line: 0,
        color: item.matched ? configModule.timeline_objects?.events.cars.matchedColor : configModule.timeline_objects?.events.cars.unmatchedColor,
        timeFrom: from,
        timeTo: from,
        metadata: {
          quality: item.quality,
          thumbnail: item.thumbnail
        }
      };
    });
    this.mergeById(this.items, newItems);
  }

  protected draw(timeFrom: number, timeTo: number) {
    super.draw(timeFrom, timeTo);
  }
}
