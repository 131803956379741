import { PageNames, PagePaths } from '@/store/application/page.definitions';
import { MenuItem } from '@/store/menu/menu';
import { configModule } from '../config';

export const launcherItems: MenuItem[] = [
  {
    icon: 'video-wall',
    name: PageNames.VideoWall,
    i18n: 'menu.video_wall',
    i18n_description: 'menu.video_wall_desc',
    path: PagePaths.VideoWall,
    meta: {
      permissions: ['ffsecurity.view_camera']
    }
  },
  {
    icon: 'cards',
    name: PageNames.Cards,
    i18n: 'menu.cards',
    i18n_description: 'menu.cards_desc',
    path: PagePaths.Cards,
    meta: {
      permissions: ['ffsecurity.view_humancard', 'ffsecurity.view_carcard']
    }
  },
  {
    icon: 'cibr',
    name: PageNames.Cases,
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_desc',
    path: PagePaths.Cases,
    meta: {
      permissions: ['ffsecurity.view_case']
    }
  },
  {
    icon: 'external-search',
    name: PageNames.ExternalSearch,
    i18n: 'menu.external_search',
    i18n_description: 'menu.external_search_desc',
    path: PagePaths.ExternalSearch,
    meta: {
      permissions: ['ffsecurity_puppeteer.view_searchrequest'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'eye-open',
    name: PageNames.RemoteMonitoringEvents,
    i18n: 'menu.remote_monitoring_events',
    i18n_description: 'menu.remote_monitoring_events_desc',
    path: PagePaths.RemoteMonitoringEvents,
    meta: {
      permissions: ['ffsecurity_puppeteer.view_remotemonitoringrecord'],
      plugins: {
        puppeteer: true
      }
    }
  },
  {
    icon: 'search-alt',
    name: PageNames.Search,
    i18n: 'menu.search',
    i18n_description: 'menu.search_desc',
    path: PagePaths.Search,
    meta: {
      permissions: [
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent',
        'ffsecurity.view_humancard',
        'ffsecurity.view_carcard'
      ]
    }
  },
  {
    icon: 'counters',
    name: PageNames.Counters,
    i18n: 'menu.counters',
    i18n_description: 'menu.counters_desc',
    path: PagePaths.Counters,
    meta: {
      permissions: ['ffsecurity.view_counter']
    }
  },
  {
    icon: 'lines',
    name: PageNames.Lines,
    i18n: 'menu.lines',
    i18n_description: 'menu.lines_desc',
    path: PagePaths.Lines,
    meta: {
      permissions: ['ffsecurity.view_line'],
      licensePath: 'multi.features.line-crossing'
    }
  },
  {
    icon: 'camera',
    name: PageNames.DataSources,
    i18n: 'menu.data_sources',
    i18n_description: 'menu.data_sources_desc',
    path: PagePaths.DataSources,
    meta: {
      permissions: ['ffsecurity.view_camera', 'ffsecurity.view_videoarchive']
    }
  },
  {
    icon: 'episodes',
    name: PageNames.Events,
    i18n: 'menu.episodes_and_events',
    i18n_description: 'menu.episodes_and_events_desc',
    path: PagePaths.Events,
    meta: {
      permissions: [
        'ffsecurity.view_humanepisode',
        'ffsecurity.view_carepisode',
        'ffsecurity.view_faceevent',
        'ffsecurity.view_bodyevent',
        'ffsecurity.view_carevent'
      ]
    }
  },
  {
    icon: 'clusters',
    name: PageNames.Clusters,
    i18n: 'menu.clusters',
    i18n_description: 'menu.clusters_desc',
    path: PagePaths.Clusters,
    meta: {
      permissions: ['ffsecurity.view_facecluster', 'ffsecurity.view_bodycluster', 'ffsecurity.view_carcluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'relations',
    name: PageNames.Interactions,
    i18n: 'menu.relations',
    i18n_description: 'menu.relations_desc',
    path: PagePaths.Interactions,
    meta: {
      permissions: ['ffsecurity.view_facecluster'],
      services: {
        clusters: true
      }
    }
  },
  {
    icon: 'statistics',
    name: PageNames.Analytics,
    i18n: 'menu.analytics',
    i18n_description: 'menu.analytics_desc',
    path: PagePaths.Analytics,
    meta: {
      permissions: ['ffsecurity.view_faceevent']
    }
  },
  {
    icon: 'done-all',
    name: PageNames.Verify,
    i18n: 'menu.verify',
    i18n_description: 'menu.verify_desc',
    path: PagePaths.Verify,
    meta: {}
  },
  {
    icon: 'create-report',
    name: PageNames.Reports,
    i18n: 'menu.reports',
    i18n_description: 'menu.reports_desc',
    path: PagePaths.Reports,
    meta: {
      permissions: ['ffsecurity.view_report']
    }
  },
  {
    icon: 'audit-log',
    name: PageNames.AuditLogs,
    i18n: 'menu.audit_logs',
    i18n_description: 'menu.audit_logs',
    path: PagePaths.AuditLogs,
    meta: {
      permissions: ['ffsecurity.view_auditlog']
    }
  },
  {
    icon: 'bi-analitycs',
    name: PageNames.BIApp,
    i18n: 'menu.bi_app',
    i18n_description: 'menu.bi_app_desc',
    meta: {
      configPath: 'bi_url',
      licensePath: 'multi.features.bi-analytics'
    }
  },
  {
    icon: 'alarm-screen',
    name: PageNames.AlarmApp,
    i18n: 'menu.alarm_app',
    i18n_description: 'menu.alarm_app_desc',
    meta: {
      configPath: 'alarm_app_url',
      licensePath: 'multi.features.alerts'
    },
    enabled: () => {
      return !!configModule.config.annex?.enabled;
    }
  },
  {
    icon: 'cibr',
    name: PageNames.Case,
    path: PagePaths.Case,
    i18n: 'menu.cibr',
    i18n_description: 'menu.cibr_desc',
    meta: {
      menu: false
    }
  },
  {
    icon: 'vp-settings',
    name: PageNames.Settings,
    i18n: 'menu.settings',
    i18n_description: 'menu.settings_desc',
    path: PagePaths.Settings,
    meta: {}
  },
  {
    icon: 'alerts',
    name: 'alarms',
    path: PagePaths.Alarms,
    i18n: 'menu.alarms',
    i18n_description: 'menu.alarms_desc',
    meta: {
      licensePath: 'multi.features.alerts'
    },
    enabled: () => {
      return !!configModule.config.annex?.enabled;
    }
  },
  {
    icon: 'devtools',
    name: 'devtools',
    path: PagePaths.DevTools,
    i18n: 'menu.devtools',
    i18n_description: 'menu.devtools_desc',
    meta: {
      dev: true
    }
  },
  {
    icon: 'devtools',
    name: 'uicomponents',
    path: PagePaths.UiComponents,
    i18n: 'menu.uicomponents',
    i18n_description: 'menu.uicomponents_desc',
    meta: {
      dev: true
    }
  }
];
