import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { User } from '@/api';

export type PermissionsMap = Record<number, 'view' | 'edit' | 'delete'>;

export type ItemAclResult = {
  view: boolean;
  update: boolean;
  delete: boolean;
};

export type ModelAclResult = {
  add: boolean;
  view: boolean;
  update: boolean;
  delete: boolean;
};

export type AclResult = ModelAclResult;

export const DefaultModelAclResult = {
  add: true,
  view: true,
  update: true,
  delete: true
};

export const DefaultItemAclResult = {
  view: true,
  update: true,
  delete: true
};

export const AclPuppeteerModelNames = {
  ExternalSearch: 'searchrequest'
};

export const AclModelNames = {
  FaceObject: 'faceobject',
  BodyObject: 'bodyobject',
  CarObject: 'carobject',
  FaceCluster: 'facecluster',
  BodyCluster: 'bodycluster',
  CarCluster: 'carcluster',
  Camera: 'camera'
};

export const AclPrefixNames = {
  Puppeteer: 'ffsecurity_puppeteer'
};

export const Conditions = {
  And: 'and',
  Or: 'or'
} as const;

export type ConditionType = 'and' | 'or'; // Conditions.And | Conditions.Or;

export interface AclModuleAbstract {
  getAccess(permissionNames: string | string[] | undefined, condition: ConditionType): boolean;

  getModelAclByName(modelName: string, prefix: string): ModelAclResult;

  getModelAcl<T, F>(model: ListViewModel<T, F> | ItemViewModel<T>): ModelAclResult;

  getItemAclByUserProperty(itemId: number, permissionProperty: keyof User, maxAcl: ItemAclResult): ItemAclResult;

  getItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult;

  getCaseItemAclByUserGroupMap(groupPermissionsMap: PermissionsMap, maxAcl: ItemAclResult): ItemAclResult;

  mergeAcl(first: ModelAclResult, second: ModelAclResult): ModelAclResult;
}
