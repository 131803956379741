export default {
  search_results_updated: 'Search results updated',
  search_reason: 'Search reason',
  enter_reason_for_search: 'Enter a search reason',
  confidence_edge: 'Confidence threshold',
  interval: 'Interval',
  results_limit: 'Results limit',
  active_until: 'Active until',
  done: 'Done',
  save_result: 'Save result',
  in_total: 'in total',
  event_mf: '{ count, plural, one {# event} other {# events} } ',
  unmatched: 'unmatched',
  back: 'Back'
};
