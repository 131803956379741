import { PicturesEnum, ReportFormatEnum, TypeEnum } from '@/api';
import { dataModule } from '@/store/data';
import { NInput } from '@/uikit';
import NCheckboxGroup from '@/uikit/checkbox/NCheckboxGroup.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { mapObjectToSelectItem } from '@/common/utilsFilterSchema';

const PicturesArray = Object.values(PicturesEnum);
const ReportFormatArray = [ReportFormatEnum.XLS, ReportFormatEnum.CSV, ReportFormatEnum.JSON];

export function nameInputSchemaItem() {
  return {
    path: 'name',
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    i18n_label: 'common.name',
    component: NInput,
    validators: [{ name: 'required' }]
  };
}

export function reportFormatCheckboxGroupSchemaItem() {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    component: NCheckboxGroup,
    path: 'report_format',
    i18n_label: 'reports.report_format',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        items: ReportFormatArray.map((item) => {
          return { value: item, i18n_label: `reports.${item}` };
        })
      };
    },
    validators: [{ name: 'required' }]
  };
}

export function reportTypeSelectSchemaItem(standardReportValue: string, workTimeReportValue: string = standardReportValue + '-in-out') {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    component: NSelect,
    path: 'type',
    i18n_label: 'reports.report_type',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        items: [
          { value: standardReportValue, i18n_label: 'reports.standard_report' },
          { value: workTimeReportValue, i18n_label: 'reports.work_time_report' }
        ]
      };
    },
    validators: [{ name: 'required' }]
  };
}

export function picturesSelectSchemaItem() {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-10',
    component: NSelect,
    path: 'pictures',
    i18n_label: 'reports.save_report_images_as',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        items: PicturesArray.map((item) => {
          return { value: item, i18n_label: `reports.${item}` };
        })
      };
    },
    validators: [{ name: 'required' }]
  };
}

export function camerasInSelectSchemaItem(selectedCameraOutId?: number | undefined) {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    component: NSelect,
    path: 'meta.cameras_in',
    i18n_label: 'reports.camera_entry',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        multiple: true,
        items: mapObjectToSelectItem(dataModule.camerasModule.items.filter(({ id }) => id !== selectedCameraOutId))
      };
    },
    validators: [{ name: 'required' }]
  };
}

export function camerasOutSelectSchemaItem(selectedCameraInId?: number | undefined) {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    component: NSelect,
    path: 'meta.cameras_out',
    i18n_label: 'reports.camera_exit',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        multiple: true,
        items: mapObjectToSelectItem(dataModule.camerasModule.items.filter(({ id }) => id !== selectedCameraInId))
      };
    },
    validators: [{ name: 'required' }]
  };
}

export function areaIdInputSchemaItem() {
  return {
    path: 'filters.area',
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    i18n_label: 'reports.id_areas',
    component: NInput,
    validators: [{ name: 'required' }]
  };
}

export function dataTypeSelectSchemaItem() {
  return {
    classes: 'n-form-w-6 n-form-pad-20 control-m n-form-label-horizontal-auto-100',
    component: NSelect,
    path: 'type',
    i18n_label: 'reports.analytic_select_data',
    props() {
      return {
        i18n_placeholder: 'common.select',
        selectonly: true,
        checkCurrent: true,
        items: [
          { value: TypeEnum.KYC, i18n_label: 'reports.analytic_output_data' },
          { value: TypeEnum.FaceClusterEvents, i18n_label: 'reports.analytic_input_data' }
        ]
      };
    },
    validators: [{ name: 'required' }]
  };
}
