
import { defineComponent, PropType } from 'vue';
import { Report, ReportStatusEnum } from '@/api';
import { ReportFormat } from '../../../types';
import { ReportsPageTableColumnLabel } from './common';
import ReportsPageTableColumnStatusDownload from './ReportsPageTableColumnStatusDownload.vue';

export default defineComponent({
  name: 'ReportsPageTableColumnStatus',
  components: {
    ReportsPageTableColumnLabel,
    ReportsPageTableColumnStatusDownload
  },
  props: {
    status: {
      required: true,
      type: String as PropType<Report['status']>
    },
    formats: {
      required: true,
      type: Array as PropType<ReportFormat[]>
    }
  },
  emits: ['download'],
  computed: {
    classes() {
      const root = 'reports-page-table-column-status__label';
      return {
        [root]: true,
        [`${root}_${this.status}`]: true
      };
    },
    content() {
      return this.$t(`reports.${this.status}`, 'f');
    },
    isDownloadAvailable() {
      return this.status === ReportStatusEnum.COMPLETED || this.status === ReportStatusEnum.UPLOADED;
    }
  },
  methods: {
    dispatchDownloadEvent(format: ReportFormat): void {
      this.$emit('download', format);
    }
  }
});
