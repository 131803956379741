
import { Options, Vue } from 'vue-class-component';
import { licenseModule } from '@/store/config/LicenseModule';
import LicenseFeaturesContent from '@/pages/license_v1/components/common/LicenseFeaturesContent.vue';
import {
  ExcludedFeatureNames,
  License,
  LicenseFeaturesMap,
  LicenseFeatureStatus,
  LicenseFeatureStatusItems,
  LicenseResourcesMap,
  Product
} from '@/pages/license_v1/types';
import LicenseTag from '@/pages/license_v1/components/common/LicenseTag.vue';
import LicenseExtraContent from '@/pages/license_v1/components/common/LicenseExtraContent.vue';
import LicenseResourceTable from '@/pages/license_v1/components/common/LicenseResourceTable.vue';

@Options({
  components: { LicenseResourceTable, LicenseExtraContent, LicenseTag, LicenseFeaturesContent }
})
export default class GeneralCard extends Vue {

  get nearestDate() {
    const firstLicenseExpireDate = this.latestDate ?? 0;
    const currentTime = new Date().getTime() / 1e3;
    return this.licenseData?.licenses?.reduce((m, v) => {
      let result = Math.min(m, v.expire_date);
      if (result >= currentTime) m = result;
      return m;
    }, firstLicenseExpireDate);
  }

  get latestDate() {
    return this.licenseData?.licenses?.reduce((m, v) => {
      m = Math.max(m, v.expire_date);
      return m;
    }, 0);
  }

  get showNearestDate() {
    return this.nearestDate && this.nearestDate !== this.latestDate;
  }

  get licenses() {
    const items = (this.licenseData?.licenses || []);
    const result = [...items].sort((a, b) => a.expire_date > b.expire_date ? -1 : 1);
    return result;
  }

  getLicenseStatus(item: License) {
    return licenseModule.getLicenseStatus(item);
  }

  getLicenseTooltipText(value: LicenseFeatureStatus) {
    return licenseModule.getLicenseTooltipText(value);
  }

  get validCount() {
    return this.licenseData?.licenses?.filter(licenseModule.isValidLicense).length;
  }

  get requireAttentionCount() {
    return this.licenseData?.licenses?.filter(licenseModule.isRequireAttentionLicense).length;
  }

  get invalidCount() {
    return this.licenseData?.licenses?.filter(licenseModule.isInvalidLicense).length;
  }

  get computedFeatureStatuses() {
    const results: Record<string, string[]> = {};
    const licenses = this.licenseData?.licenses;
    licenses?.forEach((license: License) => {
      const licenseStatus = licenseModule.getLicenseStatus(license);
      const licenseExtra = Object.values(license.products || {}).reduce((m: Record<string, any>, v: Product) => {
        Object.assign(m, v.extra);
        return m;
      }, {});

      const licenseFeatures = Object.values(license.products || {}).reduce((m: Record<string, any>, v: Product) => {
        Object.assign(m, v.features);
        return m;
      }, {});

      Object.keys(licenseFeatures).forEach((featureName) => {
        const feature = licenseFeatures[featureName];
        const featureStatus = feature?.value ? licenseStatus : LicenseFeatureStatusItems.Disabled;
        if (results[featureName]) {
          results[featureName].push(featureStatus);
        } else {
          results[featureName] = [featureStatus];
        }
      });

      Object.keys(licenseExtra || {}).forEach((featureName: string) => {
        const feature = licenseExtra[featureName];
        const featureStatus = feature ? licenseStatus : LicenseFeatureStatusItems.Disabled;
        if (results[featureName]) {
          results[featureName].push(featureStatus);
        } else {
          results[featureName] = [featureStatus];
        }
      });
    });
    return results;
  }

  get featuresMap(): LicenseFeaturesMap {
    const result = Object.keys(this.computedFeatureStatuses).reduce<LicenseFeaturesMap>((m: LicenseFeaturesMap, v: string) => {
      m[v] = { value: true };
      return m;
    }, {});
    ExcludedFeatureNames.forEach((name) => delete result[name]);
    return result;
  }

  get featureStatusesMap(): Record<string, LicenseFeatureStatus> {
    return Object.entries(this.computedFeatureStatuses).reduce((m: any, [name, statuses]) => {
      m[name] = this.filterPrimaryStatus(statuses as any) as LicenseFeatureStatus;
      return m;
    }, {});
  }

  get resourcesMap() {
    return Object.values(this.licenseData?.products || {}).reduce((m: Record<string, any>, v: Product) => {
      Object.keys(v.resources).forEach((name: string) => {
        if (m[name]) {
          m[name].current += v.resources[name].current;
          m[name].value += v.resources[name].value;
        } else {
          m[name] = { ...v.resources[name] };
        }
      });
      return m;
    }, {}) as LicenseResourcesMap;

  }

  get licenseData() {
    return licenseModule.licenseData;
  }

  isValid(item: License) {
    return licenseModule.isValidLicense(item);
  }

  formatLicenseExpireDate(value: number) {
    const time = value * 1e3;
    const currentTime = new Date().getTime();
    const dateTimeString = this.$filters.formatDateTime(time);
    return time >= currentTime ? dateTimeString : this.$t('common.expired');
  }

  filterPrimaryStatus(items: LicenseFeatureStatus[]) {
    const priority = [LicenseFeatureStatusItems.Success, LicenseFeatureStatusItems.Warning, LicenseFeatureStatusItems.Error, LicenseFeatureStatusItems.Disabled];
    let result = priority.find((item: LicenseFeatureStatus) => items.includes(item));
    return result;
  }
}
