
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NButton from '@/uikit/buttons/NButton.vue';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';

@Options({
  name: 'ExternalSearchRequestInfo',
  components: { NButton, NDateTimeLabel }
})
export default class ExternalSearchRequestInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EnrichedSearchRequest;

  get dataModule() {
    return dataModule;
  }

  showResults() {
    this.$emit('action', 'show-results');
  }

  navigateToUser(id: number) {
    const user = dataModule.getUserById(id);
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Users, rawItem: user });
  }
}
