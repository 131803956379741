
import { Options, Vue } from 'vue-class-component';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import { Prop } from 'vue-property-decorator';
import { configModule } from '@/store/config';
import NButtonSelect from '@/uikit/buttons/NButtonSelect.vue';

@Options({
  name: 'CameraMarker',
  components: {
    NButtonSelect,
    LMarker,
    LIcon
  },
  emits: ['update:modelValue']
})
export default class CameraMarkerEditable extends Vue {
  @Prop({ type: String, default: 'default' })
  public readonly modelValue!: string;

  get config() {
    if (!configModule.config.map) {
      console.error('[Map]: set map configuration to use maps!');
    }
    return configModule.config.map;
  }

  get providers() {
    return this.config?.providers;
  }

  get items() {
    return (this.providers ?? []).map((v) => ({
      value: v.id,
      label: v.name
    }));
  }

  handleUpdate(v: string) {
    this.$emit('update:modelValue', v);
  }
}
