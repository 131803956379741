import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "license-card" }
const _hoisted_2 = { class: "license-card-general" }
const _hoisted_3 = { class: "license-card-general__info" }
const _hoisted_4 = { class: "license-card-general__id" }
const _hoisted_5 = { class: "license-card-general__status" }
const _hoisted_6 = { class: "license-card-info" }
const _hoisted_7 = { class: "license-card-info-item" }
const _hoisted_8 = { class: "license-card-info-item__label" }
const _hoisted_9 = { class: "license-card-info-item__value" }
const _hoisted_10 = { class: "license-card-info-item" }
const _hoisted_11 = { class: "license-card-info-item__label" }
const _hoisted_12 = { class: "license-card-info-item__value" }
const _hoisted_13 = { class: "license-card-info" }
const _hoisted_14 = { class: "license-card-info-item" }
const _hoisted_15 = { class: "license-card-info-item__label" }
const _hoisted_16 = { class: "license-card-info-item__value" }
const _hoisted_17 = { class: "license-card-info-item" }
const _hoisted_18 = { class: "license-card-info-item__label" }
const _hoisted_19 = { class: "license-card-info-item__value" }
const _hoisted_20 = { class: "license-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_LicenseTag = _resolveComponent("LicenseTag")!
  const _component_LicenseResourceTable = _resolveComponent("LicenseResourceTable")!
  const _component_LicenseFeaturesContent = _resolveComponent("LicenseFeaturesContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('license.license')) + ": ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.license_id), 1),
        _createVNode(_component_NButton, {
          type: "link",
          icon: "copy",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copy(_ctx.item.license_id))),
          class: "copy-button"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LicenseTag, {
          status: _ctx.licenseStatus,
          tooltip: _ctx.licenseTooltip
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.valid?.valid ? this.$t('license.valid') : this.$t('license.invalid')), 1)
          ]),
          _: 1
        }, 8, ["status", "tooltip"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('license.expiry_date')) + ":", 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatLicenseExpireDate(_ctx.item.expire_date)), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('license.last_update')), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$tm('license.last_update_seconds_ago', { value: _ctx.item.last_updated })), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('license.type')) + ":", 1),
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.item.type), 1)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('license.source')) + ":", 1),
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.item.source), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_LicenseResourceTable, {
        showCurrent: false,
        itemsMap: _ctx.resourcesMap
      }, null, 8, ["itemsMap"]),
      _createVNode(_component_LicenseFeaturesContent, {
        itemsMap: _ctx.featuresMap,
        statusesMap: _ctx.featureStatusesMap
      }, null, 8, ["itemsMap", "statusesMap"])
    ])
  ]))
}