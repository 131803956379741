import { reactive } from 'vue';
import notify from '@/uikit/notification/helpers/notification';
import { NotificationOptions } from '@/uikit/notification/helpers/types';

const DEFAULT_DURATION = 8e3;

export class NotificationsModule {
  duration: number;

  static create(duration = DEFAULT_DURATION) {
    return reactive(new this(duration)) as NotificationsModule;
  }

  private constructor(duration: number) {
    this.duration = duration;
  }

  notify(config: NotificationOptions) {
    notify({ duration: this.duration, ...config });
  }
}
