export default {
  process_duration: 'Process duration',
  success_records: 'Success records',
  failed_records: 'Failed records',
  total_records: 'Total records',
  success_percent: 'Success percent',
  count_silhouettes: 'Counts bodies',
  single_camera: 'single camera',
  multi_camera: 'several cameras',
  clear: 'Clear',
  information: 'Info',
  id: 'ID',
  name: 'Name',
  info: 'Info',
  faces: 'Faces',
  bodies: 'Bodies',
  cars: 'Vehicles',
  active: 'Active',
  create_counters: 'Add new counter',
  records: 'Screenshots',
  roi: 'ROI',
  frame: 'Frame',
  rect: 'Rectangle',
  detect_proximity: 'measure distance',
  detect_bodies: 'count bodies',
  detect_cars: 'count vehicles',
  detect_faces: 'count faces',
  calibration_time: 'calibration time',
  calibrate_counter: 'calibrate counter',
  proximity_not_enabled: 'distance measurement disabled',
  proximity_enabled: 'distance measurement enabled',
  distance: 'distance',
  distance_measurement: 'distance measurement',
  distance_is_not_detected: 'not detected',
  min_distance: 'minimum distance',
  max_distance: 'maximum distance',
  avg_distance: 'average distance',
  calibration_status__not_calibrated: 'not calibrated',
  calibration_status__awaiting_sync: 'waiting for synchronization',
  calibration_status__collecting_calibration_data: 'Сollecting calibration data',
  calibration_status__awaiting_proximity_model: 'creating calibration pattern for distance measurement',
  calibration_status__calibrated: 'calibrated',
  calibration_status__calibration_failed: 'calibration failed',
  stop_calibration: 'delete calibration',
  collect_track_data_seconds: 'Calibration duration (seconds)',
  with_detect_proximity: 'with distance measurement',
  without_detect_proximity: 'without distance measurement',
  minimum: 'minimum',
  maximum: 'maximum',
  average: 'average',
  count_within_interval_seconds: 'Count interval, seconds',
  counter: 'Counter',
  select_counter: 'Select counter',
  counter_id: 'Counter ID',
  counter_record_id: 'Counter entry ID',
  enter_counter_id: 'Enter counter ID',
  enter_counter_record_id: 'Enter counter entry ID',
  count_faces: 'Counts faces',
  count_cars: 'Counts vehicles',
  save_changes_helper: 'Save changes to activate the calibration button after turning on the body detector',
  not_found_items: 'Counters not found',
  counters_no_items: 'You don\'t have any counters'
};
