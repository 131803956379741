import router from '@/router';
import get from 'lodash/get';
import { aclModule } from '@/store/acl';
import { applicationModule } from '@/store/application';
import { PageName, PageNames, PagePath, PagePaths } from '@/store/application/page.definitions';
import { workspaceModule } from '@/store/application/workspace';
import { configModule } from '@/store/config';
import { configAclModule } from '@/store/config/acl';
import { licenseModule } from '@/store/config/LicenseModule';
import { launcherItems } from '@/store/menu/launcher.items';
import { MenuItem, MenuItemMeta } from '@/store/menu/menu';
import { settingsItems } from '@/store/menu/settings.items';
import { RouterModule } from '@/store/router';
import { dataModule } from '../data';

export function fillQuery(menuItem: MenuItem) {
  const settingsLocation = RouterModule.getRouteLocation({ path: PagePaths.Settings, tab: router.currentRoute?.value?.query.tab });
  if (typeof settingsLocation !== 'string') {
    settingsLocation.query && (menuItem.query = settingsLocation.query);
  }
  return menuItem;
}

function fillServerPath(menuItem: MenuItem) {
  const serverUrl = configModule.config.server?.url ?? '/';
  if (menuItem.serverPath && !menuItem.serverPath.startsWith(serverUrl)) {
    menuItem.serverPath = serverUrl + menuItem.serverPath;
  }
  return menuItem;
}

function enabledByConfigPath(menuItem: MenuItem) {
  const configPath = menuItem?.meta?.configPath;
  if (configPath) {
    const url = (configModule.config as any)[configPath];
    return !!url;
  }
  return true;
}

function enabledByLicensePath(menuItem: MenuItem) {
  const path = menuItem?.meta?.licensePath;
  if (path) {
    const enabled: boolean = get(configModule.config.licenses as any, path, false);
    return enabled;
  }
  return true;
}

function checkAdmin(adminAccess: boolean | undefined): boolean {
  const user = dataModule.currentUserModule.item;
  const isSuperUser = user?.primary_group === 1 || user?.groups?.includes(1);
  return !adminAccess || !!isSuperUser;
}

export function canShowPage(name: PageName): boolean {
  const item = launcherItems.find((v) => v.name === name);
  return item ? canShowMenuItem(item) : true;
}

export function canShowMenuItem(menuItem: MenuItem): boolean {
  const { meta, name, enabled } = menuItem;
  const hasAccessByAcl = aclModule.getAccess(meta.permissions, 'or');
  const hasAccessByPlugin = configAclModule.hasPlugins(meta.plugins);
  const hasAccessByService = configAclModule.hasServices(meta.services);
  const isEnabledByNotEmptyConfigPath = enabledByConfigPath(menuItem);
  const isNotDisabled = !configAclModule.isDisabledMenuItem(name);
  const isAllowedByDevMode = menuItem.meta?.dev ? applicationModule.settings.debug : true;
  const isIncludedInLicense = enabledByLicensePath(menuItem);
  const isEnabledByParamOrFunction = computeEnabled(enabled);
  const isAdminAccess = checkAdmin(meta.admin);
  const isInMenu = !(meta?.menu === false);
  const results = [
    isAllowedByDevMode,
    hasAccessByAcl,
    hasAccessByPlugin,
    hasAccessByService,
    isNotDisabled,
    isInMenu,
    isAdminAccess,
    isIncludedInLicense,
    isEnabledByParamOrFunction,
    isEnabledByNotEmptyConfigPath
  ];
  return results.reduce((v, m) => m && v, true);
}

export function canShowTab(menuItem: MenuItem): boolean {
  const { meta, name, enabled } = menuItem;
  const hasAccessByAcl = aclModule.getAccess(meta.permissions, 'or');
  const hasAccessByPlugin = configAclModule.hasPlugins(meta.plugins);
  const hasAccessByService = configAclModule.hasServices(meta.services);
  const isAllowedByDevMode = menuItem.meta?.dev ? applicationModule.settings.debug : true;
  const isEnabled = computeEnabled(enabled);
  const results = [isAllowedByDevMode, hasAccessByAcl, hasAccessByPlugin, hasAccessByService, isEnabled];
  return results.reduce((v, m) => m && v, true);
}

function computeEnabled(enabled?: boolean | (() => boolean)) {
  if (enabled === undefined) return true;
  return typeof enabled === 'function' ? enabled() : enabled;
}

export function getItems(includeAdditional = false /* depricated */, includeSettings = false) {
  const menuItems = [...launcherItems];
  includeSettings && menuItems.push(...settingsItems);
  return menuItems.filter(canShowMenuItem).map(fillServerPath);
}

export function getItemsInTabs(includeAdditional = false /* depricated */, includeSettings = false) {
  const menuItems = [...launcherItems];
  includeSettings && menuItems.push(...settingsItems);
  return menuItems.filter(canShowTab).map(fillServerPath);
}

export function getSettingsItems() {
  return settingsItems.filter(canShowMenuItem).map(fillServerPath).map(fillQuery);
}

export function formatMenuItems($t: any, items: MenuItem[]) {
  return items.map((item: MenuItem) => {
    return {
      ...item,
      title: $t(item.i18n),
      description: item.i18n_description && $t(item.i18n_description)
    };
  });
}

export function launchMenuItem(item: MenuItem) {
  const configPath = item?.meta?.configPath;
  if (configPath) {
    const url = (configModule.config as any)[configPath];
    if (url) {
      window.open(url);
      return;
    }
  }
  if (item.path) {
    workspaceModule.addItem(item.path);
    return;
  }
  console.warn('Empty menu item url!');
}
