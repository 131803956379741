import { isBoolean } from 'lodash';
import { computed, onActivated, onBeforeUnmount, onDeactivated, onMounted, reactive, ref, unref } from 'vue';
import { DataService } from '@/definitions/services/data.services';
import { Report, ReportStatusEnum } from '@/api';
import { ReportsFilter } from '@/api/models/ReportsFilter';
import { asDefined, isStringWithValue } from '@/common/utils';
import { Nullable } from '../setup';
import { Repeater } from './repeater';
import { ReportId, ReportPage, ReportPageCursor, ReportSortableFields, ReportSorting } from './types';
import { computeReportPageCursor, computeReportPageCursors, isEmpty, lastOf } from './utils';

export function useReportsPageViewModel(service: DataService<Report, ReportsFilter>) {
  const pages = ref<ReportPage[]>([]);
  const reports = computed(extractReportsFromPages);
  const selectedIds = ref<ReportId[]>([]);
  const sorting = reactive<ReportSorting>({
    created_date: null,
    id: null,
    modified_date: null,
    size: null
  });
  const sortableFields = computed(computeSortingParameter);
  const repeater = new Repeater(1e4, load);
  const searchQuery = ref('');
  const loading = ref(false);

  onActivated(start);
  onBeforeUnmount(pause);
  onDeactivated(pause);
  onMounted(start);

  function pause(): void {
    repeater.pause();
  }

  function start(): void {
    repeater.start();
  }

  async function load(): Promise<void> {
    try {
      pages.value = await Promise.all(computeReportPageCursors(unref(pages)).map(fetchPage));
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  async function loadNextPage(): Promise<void> {
    const cursor = computeNextPageCursor();
    isStringWithValue(cursor) && unref(pages).push(await fetchPage(cursor));
  }

  async function deleteSelected(): Promise<void> {
    await Promise.all(unref(selectedIds).map((id) => service.delete(id)))
      .then(clearSelections)
      .then(load);
  }

  function selectAll(): void {
    selectedIds.value = unref(reports).map(({ id }) => id);
  }

  function clearSelections(): void {
    selectedIds.value = [];
  }

  async function updateSelected(): Promise<void> {
    const actions = unref(selectedIds).map((id) => service.createItemSomethingByAction(id, 'restart'));
    await Promise.all(actions).then(clearSelections).then(load);
  }

  function flushPages(): void {
    pages.value = [];
  }

  async function sort(field: ReportSortableFields): Promise<void> {
    Object.keys(sorting)
      .filter((v) => v !== field)
      .forEach((v: string) => {
        sorting[v as ReportSortableFields] = null;
      });
    (sorting[field] = !sorting[field]), flushPages(), await load();
  }

  function computeNextPageCursor(): Nullable<ReportPageCursor> {
    const page = !isEmpty(unref(pages)) ? lastOf(unref(pages)) : null;
    return page && computeReportPageCursor(page);
  }

  function fetchPage(cursor: ReportPageCursor): Promise<ReportPage> {
    return service.getList({ page: cursor, ordering: unref(sortableFields), name_contains: searchQuery.value });
  }

  function extractReportsFromPages(): Report[] {
    return unref(pages)
      .map(({ results }) => results ?? [])
      .flat();
  }

  function getSelectedReadyReports(): Report[] {
    return unref(selectedIds)
      .map((id) => asDefined(unref(reports).find((report) => report.id === id)))
      .filter((report) => report.status === ReportStatusEnum.COMPLETED || report.status === ReportStatusEnum.UPLOADED);
  }

  function computeSortingParameter(): string {
    return Object.entries(sorting)
      .filter(([_field, enabled]) => isBoolean(enabled))
      .map(([field, enabled]) => `${enabled ? '' : '-'}${field}`)
      .join();
  }

  return {
    clearSelections,
    deleteSelected,
    getSelectedReadyReports,
    load,
    loadNextPage,
    reports,
    selectAll,
    selectedIds,
    sort,
    updateSelected,
    searchQuery,
    loading
  };
}
