
import { isFinite } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Point, Size } from '@/uikit/bbox/types';
import { drawPointCircle, drawPolyLine, drawText } from '@/uikit/draw/draw-utils';
import { castToMinimumDistance, filterInsignificantPoints, getLineReversPoints, getPointsDistance } from '@/uikit/draw/math-utils';
import { Colors, PointAsArray, PolyLine, PolyLineStyleColor, SingleLine, Sizes } from '@/uikit/draw/types';
import { getLayoutSizeStyle } from '@/uikit/image-viewer/image-helpers';
import { LineTrackProp } from '@/uikit/image-viewer/types';

function getDefaultTrackGradient() {
  return [
    [0, 0, 255],
    [255, 0, 0]
  ];
}

function scalePoint(point: PointAsArray, scale: number) {
  if (isFinite(scale)) {
    return [point[0] * scale, point[1] * scale];
  }
}

const TrackPointsSignificanceThreshold = 5;
const ReversePointsMinimumDistance = 40;

@Options({
  name: 'NImageViewerLineLayer',
  components: {},
  emits: []
})
export default class NImageViewerLineLayer extends Vue {
  @Prop({ type: Object, required: true })
  readonly size!: Size;

  @Prop({ type: Object, default: () => ({ x: 0, y: 0 }) })
  readonly offset!: Point;

  @Prop({ type: Number, default: 1 })
  readonly scale!: number;

  @Prop({ type: Array, default: () => [] })
  readonly line?: LineTrackProp;

  @Prop({ type: Array, default: () => [] })
  readonly track?: LineTrackProp;

  @Prop({ type: Array, default: getDefaultTrackGradient })
  readonly trackStyle?: PolyLineStyleColor;

  @Ref('canvasContainer')
  canvasContainer: HTMLDivElement | null = null;

  canvas: HTMLCanvasElement | null = null;
  canvasContext: CanvasRenderingContext2D | null = null;
  computedLine: PolyLine | null = null;
  computedTrack: PolyLine | null = null;

  get scaledLine() {
    return this.computedLine?.map((v) => scalePoint(v, this.scale)) as PolyLine;
  }

  get scaledTrack() {
    return this.computedTrack
      ? (filterInsignificantPoints(this.computedTrack, TrackPointsSignificanceThreshold).map((v) => scalePoint(v, this.scale)) as PolyLine)
      : null;
  }

  get style() {
    return getLayoutSizeStyle(this.size, this.offset, this.scale);
  }

  get directionPoints() {
    return this.computedLine && getLineReversPoints(this.computedLine)?.map((v) => scalePoint(v, this.scale));
  }

  @Watch('line', { immediate: true })
  async computeLine() {
    if (this.line) {
      this.computedLine = typeof this.line === 'function' ? await this.line() : this.line;
      this.draw();
    }
  }

  @Watch('track', { immediate: true })
  async computeTrack() {
    if (this.track) {
      this.computedTrack = typeof this.track === 'function' ? await this.track() : this.track;
      this.draw();
    }
  }

  @Watch('scale')
  @Watch('size', { deep: true, immediate: true })
  resizeCanvas() {
    if (this.canvas) {
      this.canvas.width = this.size.width * this.scale;
      this.canvas.height = this.size.height * this.scale;
    } else {
      console.warn("[Image Viewer]: Can't set canvas size. Canvas property undefined!");
    }
    this.draw();
  }

  createCanvas() {
    this.canvas = document.createElement('canvas');
    this.canvasContext = this.canvas.getContext('2d');
    this.canvasContainer?.appendChild(this.canvas);
    this.resizeCanvas();
  }

  clearCanvas() {
    this.canvasContext && this.canvasContext.clearRect(0, 0, this.canvas!.width, this.canvas!.height);
  }

  draw() {
    this.clearCanvas();
    this.drawLine();
    this.drawTrack();
  }

  drawLine() {
    if (!this.canvasContext || !this.scaledLine?.length) return;
    const ctx = this.canvasContext;
    drawPolyLine(ctx, this.scaledLine, { strokeStyle: Colors.White90, lineWidth: 4, lineCap: 'round' });
    drawPolyLine(ctx, this.scaledLine, { fillStyle: Colors.Accent, lineWidth: 1, lineCap: 'round' });

    if (this.directionPoints) {
      let [a, b] = this.directionPoints as SingleLine;
      const distance = getPointsDistance(a, b);
      if (distance < ReversePointsMinimumDistance) {
        [a, b] = castToMinimumDistance(a, b, distance, ReversePointsMinimumDistance);
      }
      drawPointCircle(ctx, a, Sizes.PointSizeBig, { fillStyle: Colors.PointA, strokeStyle: Colors.White90 });
      drawText(ctx, 'A', a, Sizes.PointTextBig, { fillStyle: Colors.White90 });
      drawPointCircle(ctx, b, Sizes.PointSizeBig, { fillStyle: Colors.PointB, strokeStyle: Colors.White90 });
      drawText(ctx, 'B', b, Sizes.PointTextBig, { fillStyle: Colors.White90 });
    }
  }

  drawTrack() {
    if (!this.canvasContext || !this.scaledTrack?.length) return;
    const ctx = this.canvasContext;
    if (this.trackStyle !== undefined) {
      drawPolyLine(ctx, this.scaledTrack, { fillStyle: this.trackStyle as any, strokeStyle: this.trackStyle as any, lineCap: 'round', dotsSize: 4 });
    } else {
      console.warn('[Image Viewer]: track gradient/color undefined!');
    }
  }

  mounted() {
    this.createCanvas();
    this.draw();
  }
}
