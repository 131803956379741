
import dayjs, { Dayjs, UnitType } from 'dayjs';
import { ComponentPublicInstance, computed, ComputedRef, defineComponent, nextTick, onMounted, PropType, reactive, Ref, ref, toRefs, unref, watch } from 'vue';
import { isString } from '@/uikit/utils';
import { setToLimits } from '../datetime/set-to-limits';
import { IDateSource, DateTimeFormats, DateTimeFormat, EmitType, EmitTypes } from '../datetime/types';
import { useDropdownOnClickOutsideListener, useDropdownOnEscapeListener, useDropdownToggler } from '../dropdown/helpers/engines';
import NInput from '../input/NInput.vue';
import dateTimeMasker from './date-time-masker';
import NDatePicker from './NDatePicker.vue';
import NTimeInput from './NTimeInput.vue';

export default defineComponent({
  components: { NDatePicker, NInput, NTimeInput },
  props: {
    modelValue: { type: [Number, String, Date] },
    min: { type: [Number, String, Date] },
    max: { type: [Number, String, Date] },
    isDateDisabled: { type: Function },
    timeEnabled: { type: Boolean, default: false },
    secondsEnabled: { type: Boolean, default: false },
    emitType: { type: String as PropType<EmitType>, default: EmitTypes.DateObject },
    emitStringFormat: { type: String as PropType<DateTimeFormat>, default: DateTimeFormats.ISO },
    name: { type: String, default: '' },
    plain: { type: Boolean },
    dateFormat: { type: String as PropType<DateTimeFormat>, default: DateTimeFormats.date },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    timeOnClear: { type: [String, Boolean], default: false },
    accesskey: { type: String },
    datePlaceholder: { type: String },
    timePlaceholder: { type: String },
    dataQa: { type: String }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const state = reactive({
      date: dayjs() as Dayjs | null,
      time: dayjs() as Dayjs | null,
      dateUserInput: '',
      nInput: null as ComponentPublicInstance<unknown, { input: HTMLInputElement }> | null,
      nTimeInput: null as ComponentPublicInstance<unknown, { input: HTMLInputElement; isValid: ComputedRef<boolean>; hidePicker: () => void }> | null,
      autoInsertDelimiter: true
    });

    onMounted(() => state.dateUserInput && setNativeValue(state.dateUserInput));

    const root = ref<HTMLDivElement | null>(null);
    const body = ref<HTMLDivElement | null>(null);
    const { hide: hideDatePicker, show: showDatePicker, isShown: datePickerShown } = useDropdownToggler(root, body, ref('bottom-start'));
    useDropdownOnClickOutsideListener(root, body, hideDatePicker);
    useDropdownOnEscapeListener(hideDatePicker);

    const isValid = computed(() => getIsInputValid() && getIsInRange());
    const minDate = computed(() => (props.min ? dayjs(props.min) : null));
    const maxDate = computed(() => (props.max ? dayjs(props.max) : null));
    const selectedDates = computed(() => (state.date ? [state.date.toDate()] : []));
    const inputElement = computed(() => (state.nInput ? state.nInput.input : null));
    const dateTime = computed(() => {
      let dateTime: Dayjs | null = null;
      if (state.date) {
        dateTime = state.date.clone();
        if (props.timeEnabled && state.time) {
          let units = ['hour', 'minute', 'second'];
          for (let i in units) {
            let unit = units[i] as UnitType;
            dateTime = dateTime.set(unit, state.time.get(unit));
          }
        }
      }
      return dateTime;
    });

    watch(() => state.date, refreshTime);
    function refreshTime(d: Dayjs | null) {
      if (!d) return;
      if (state.time) {
        let units = ['year', 'months', 'day'];
        for (let i in units) {
          let unit = units[i] as UnitType;
          state.time = state.time.set(unit, d.get(unit));
        }
      } else {
        state.time = d.clone();
      }
    }

    watch(() => props.modelValue, valueHandler, { immediate: true });
    function valueHandler(v: IDateSource | undefined) {
      let dateUserInput = '';
      if (v === undefined || v === '') {
        state.date = null;
        state.time = null;
      } else {
        let date = dayjs(v);
        if (date.isValid()) {
          state.date = date;
          state.time = date.clone();
          dateUserInput = date.format(props.dateFormat);
        } else {
          state.date = null;
          state.time = null;
          dateUserInput = '';
        }
      }
      state.dateUserInput = dateUserInput;
      setNativeValue(dateUserInput);
    }

    function getIsInputValid() {
      let timeValid = state.nTimeInput && state.nTimeInput.isValid,
        dateEnteredValid = state.dateUserInput.length === props.dateFormat.length && state.date && state.date.isValid(),
        dateEmptyValid = state.dateUserInput === '' && state.date === null && (!props.timeEnabled || state.time === null);

      if (dateEnteredValid) return props.timeEnabled ? Boolean(timeValid && state.time) : true;
      if (dateEmptyValid) return props.timeEnabled ? state.time === null : true;
      return false;
    }

    function getIsInRange() {
      if (!dateTime.value) return true;
      let isAfterMin = true;
      if (minDate.value) isAfterMin = dateTime.value.isSame(minDate.value) || dateTime.value.isAfter(minDate.value);
      let isBeforeMax = true;
      if (maxDate.value) isBeforeMax = dateTime.value.isSame(maxDate.value) || dateTime.value.isBefore(maxDate.value);

      return isAfterMin && isBeforeMax;
    }

    const dateInputHandler = (v: string) => {
      if (v !== state.dateUserInput) {
        let maskerResult = convertToDate(v);
        state.dateUserInput = maskerResult.value;
        setNativeValue(maskerResult.value, maskerResult.caretPosition);
        if (state.dateUserInput.length === props.dateFormat.length) {
          let date = dayjs(state.dateUserInput, props.dateFormat);
          state.date = date.isValid() ? setToLimits(date, minDate.value, maxDate.value) : null;
        } else {
          state.date = null;
          if (!v) {
            state.time = null;
            emitChange();
          }
        }
      }
    };

    const dateChangeHandler = () => emitChange();

    const dateSelectHandler = (date: Date) => {
      state.date = setToLimits(dayjs(date), minDate.value, maxDate.value);
      state.dateUserInput = state.date.format(props.dateFormat);
      setNativeValue(state.dateUserInput);
      emitChange();
      hideDatePicker();
    };

    const timeChangeHandler = (time: Date | null) => {
      if (isString(props.timeOnClear) && state.date && time === null) {
        time = dayjs(props.timeOnClear, 'HH:mm').toDate();
      }
      state.time = time ? dayjs(time) : null;
      if (!state.dateUserInput && time) {
        const currentDate = new Date();
        dateSelectHandler(currentDate);
      }
      emitChange();
    };

    function determineValue(dateTime: Ref<Dayjs | null>) {
      let value = unref(dateTime);
      if (!value) return value;

      return props.emitType === EmitTypes.DateObject ? value.toDate() : value.format(props.emitStringFormat);
    }

    async function emitChange() {
      await nextTick();
      isValid.value && emit('update:modelValue', determineValue(dateTime));
    }

    function convertToDate(v: string) {
      let caretPosition = inputElement.value ? inputElement.value.selectionEnd : v.length;
      let maskFormat = props.dateFormat.replace(/\w/g, '0'),
        date = dateTimeMasker(v, maskFormat, caretPosition, state.autoInsertDelimiter);
      return date;
    }

    function setNativeValue(value: string, caretPosition: null | number = null) {
      if (inputElement.value) {
        inputElement.value.value = value;
        if (caretPosition !== null) {
          inputElement.value.setSelectionRange(caretPosition, caretPosition);
        }
      }
    }

    return {
      ...toRefs(state),
      ...toRefs(props),
      root,
      body,
      showDatePicker,
      datePickerShown,
      selectedDates,
      dateSelectHandler,
      dateInputHandler,
      dateChangeHandler,
      hideDatePicker,
      timeChangeHandler
    };
  },
  methods: {
    focus(): void {
      this.$refs.nInput?.focus && this.$refs.nInput!.focus();
    }
  }
});
