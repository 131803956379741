export default {
  cluster_events: 'Cluster events',
  add_new: 'Add new record',
  duplicate_card: 'It looks like the record already exists',
  ignore_this_warning: 'Ignore this warning',
  add_photo_to_existing_card: 'add to this record',
  duplicate: 'duplicate',
  unknown: 'Unknown',
  autocards: 'Autorecords',
  face_objects: 'Faces',
  face_clusters: 'Face clusters',
  face_cluster_events: 'Face cluster events',
  body_objects: 'Bodies',
  body_clusters: 'Body clusters',
  body_cluster_events: 'Body cluster events',
  car_objects: 'Vehicles',
  car_clusters: 'Vehicle clusters',
  car_cluster_events: 'Vehicle cluster events',
  connections: 'Connections',
  locations: 'Location Data',
  info: 'Info',
  human_case_clusters: 'Cases',
  matched_case_clusters: 'Case Clusters',
  related_case_clusters: 'Related Cases',
  license_plate: 'License plate number',
  case_human_cards: 'Related Records',
  upload_photo: 'Upload photo',
  select_files: 'select files',
  select_file: 'select file',
  select_folder: 'select a folder',
  or: 'or',
  no_objects: 'no object in photo',
  success: 'success',
  detection_error: 'detection error',
  in_queue: 'in the queue',
  prefix_name: 'Name prefix',
  suffix_name: 'Name postfix',
  filename_as_name: 'Use file name',
  prefix_comment: 'Comment prefix',
  suffix_comment: 'Comment postfix',
  photo_group: 'Group photo',
  parallel_upload: 'Parallel upload',
  selected: 'Selected',
  uploaded: 'Uploaded',
  errors: 'Errors',
  reject: 'Reject',
  biggest: 'Save the biggest object',
  all: 'All',
  batch_cards_upload__after_try: 'batch record upload',
  do_you_have_many_cards: 'Too many records to create? Try',
  upload: 'upload',
  start: 'start',
  pause: 'pause',
  continue: 'continue',
  name: 'record name',
  active: 'record active',
  create_card_from_event: 'Create record from event',
  create_new_card: 'Create new record',
  similar_cards: 'Similar records',
  bu_images: 'Images',
  bu_csv: 'CSV',
  bu_download_sample: 'Download CSV file sample',
  enter_cards_data: 'Vehicle record data',
  enter_cards_format: 'Record name;License plate number;Comment',
  enter_cards_description: 'Write a CSV formatted list right in the field above. Press Enter to begin a new line. Supported format: "Record name;License plate number;Comment"'
};
