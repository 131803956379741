
import { Options, Vue } from 'vue-class-component';
import { LicenseFeatureStatusItems } from '@/pages/license_v1/types';
import { Prop, Ref } from 'vue-property-decorator';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import { VNode } from 'vue';

@Options({
  components: { NTooltip }
})
export default class LicenseCircle extends Vue {
  @Prop({ type: String, default: LicenseFeatureStatusItems.Disabled })
  status!: string;

  @Prop({ type: String })
  tooltip?: string;

  tag: VNode | null = null;

  tagChange(value: VNode) {
    this.tag = value;
  }

  get classes() {
    const base = 'license-circle';
    return {
      [base]: true,
      [`${base}_${this.status}`]: true
    };
  }
}
