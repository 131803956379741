import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "data-source-table-row" }
const _hoisted_2 = { class: "data-source-table-row__image-container" }
const _hoisted_3 = {
  key: 0,
  class: "data-source-table-row__record"
}
const _hoisted_4 = { class: "data-source-table-row__info" }
const _hoisted_5 = { class: "heading-m" }
const _hoisted_6 = { class: "label-s" }
const _hoisted_7 = { class: "data-source-table-row__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraStatus = _resolveComponent("CameraStatus")!
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!
  const _component_ActionsDropdown = _resolveComponent("ActionsDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CameraStatus, {
          class: "data-source-table-row__status",
          camera: _ctx.item,
          "show-status-label": false,
          "status-type": "vertical-line"
        }, null, 8, ["camera"]),
        (_ctx.item.active && _ctx.item.stream_settings.enable_recorder)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        _createVNode(_component_CameraScreenshot, {
          disabled: !_ctx.showThumbnail,
          class: "data-source-table-row__screenshot",
          "model-value": _ctx.item.screenshot,
          "display-width": "100px",
          width: 100,
          "refresh-on-click": false
        }, null, 8, ["disabled", "model-value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.name), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.item.comment), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_ActionsDropdown, {
        actions: _ctx.actions,
        onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v, _ctx.item))
      }, null, 8, ["actions"])
    ])
  ]))
}