
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CameraRequest, CamerasService } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { multisidebarModule } from '@/store/multisidebar';
import NButton from '@/uikit/buttons/NButton.vue';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTable from '@/uikit/table/NTable.vue';
import { IDataSourceDevice } from '@/pages/data-sources/add-devices/DataSourcesDevices.vue';
import { formLayoutContent, formLayoutHeaders } from './device-form-schema';
import {differenceOf} from "@/definitions/common/utils";
import {ObjectsMultiToSingle} from "@/store/application/data.assets";
import {cameraResetModule} from "@/store/camera/camera.reset.module";

@Options({
  name: 'DataSourcesDevicesNext',
  components: { NButton, NForm, NIcon, NTable }
})
export default class DataSourcesDevicesNext extends Vue {
  @Prop({ type: Array, required: true })
  readonly devices!: IDataSourceDevice[];

  isCreateStarted = false;

  headerModel = {
    faces: false,
    bodies: false,
    cars: false
  };
  previousModel = null;

  get sidebar() {
    return multisidebarModule;
  }

  get deviceCount() {
    return this.devices.length;
  }

  get headers(): IFormLayout {
    return formLayoutHeaders;
  }
  get schema(): IFormLayout {
    return formLayoutContent;
  }

  isValid() {
    const { form } = this.$refs;
    const result = form.filter((item: any) => {
      !item.validate() && item.displayErrors();
      return item.validate();
    });

    return result.length === form.length;
  }

  async createCameras() {
    const requests = this.devices.map((cameraItem) => CamerasService.camerasCreate(cameraItem as CameraRequest));
    const cameras = await Promise.allSettled(requests);
    await autoUpdateHelper.createHandler('/cameras/');
    return cameras;
  }

  onlyCreateCameras() {
    if (this.isValid()) {
      this.isCreateStarted = true;
      this.createCameras().finally(() => {
        this.$emit('close');
      });
    }
  }

  createAndConfig() {
    if (this.isValid()) {
      this.isCreateStarted = true;
      this.createCameras()
        .then((res) => {
          const cameras = res.map((camera) => camera.status === 'fulfilled' && camera.value);
          this.sidebar.addItemsAndOpen('cameras', cameras);
        })
        .finally(() => {
          this.$emit('close');
        });
    }
  }

  @Watch('devices', { deep: true })
  deviceChangeHandler() {

  }

  @Watch('headerModel', { deep: true })
  headerModelChangeHandler(v: any, p: any) {
    const diff = differenceOf(v, this.previousModel || {});
    this.previousModel = { ...v };
    Object.keys(diff).forEach((key) => {
      for (let item of this.devices) {
        this.updateItem(item, key, diff[key]);
      }
    });
  }

  updateItem(item: any, property: string, value: boolean) {
    const singlePropName = ObjectsMultiToSingle[property];
    cameraResetModule.setDetector(item, singlePropName as any, value);
  }
}
