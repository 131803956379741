
import { Options, Vue } from 'vue-class-component';
import { dataModalController } from '@/store/application/data.modal.module';
import NButton from '@/uikit/buttons/NButton.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';

@Options({
  components: {
    NButton,
    NCheckbox,
    NModalWindow
  }
})
export default class DataModal extends Vue {
  get dataModal() {
    return dataModalController;
  }

  get size() {
    return NModalWindowSize.Medium;
  }
}
