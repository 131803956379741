
export const AlertRuleTabItems = {
  General: 'general',
  AlertRules: 'alert-rules',
  Settings: 'settings',
  DeliveryChannels: 'delivery-channels',
  FastComments: 'fast-comments'
} as const;

export const AlertRuleTabs: AlertRuleTabItem[] = [
  AlertRuleTabItems.General,
  AlertRuleTabItems.AlertRules,
  AlertRuleTabItems.Settings,
  AlertRuleTabItems.DeliveryChannels,
  AlertRuleTabItems.FastComments
];

export type AlertRuleTabItem = typeof AlertRuleTabItems[keyof typeof AlertRuleTabItems];

export function deleteEmptyFilters(filters: Record<string, Record<string, any>>): void {
  Object.keys(filters).forEach((key: string) => {
    if (!filters[key]) {
      delete filters[key];
      return;
    }
    const itemKeys = Object.keys(filters[key]);
    itemKeys.forEach((itemKey: string) => {
      const itemValue = filters[key][itemKey];
      if (itemValue === null || itemValue === undefined) delete filters[key][itemKey];
    });
  });
}
