
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import ThumbnailItem from '@/components/common/ThumbnailItem.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import { Prop } from 'vue-property-decorator';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import NTooltip from '@/uikit/hint/NTooltip.vue';

@Options({
  name: 'AlertsCommentsDialog',
  components: { NTooltip, NTextareaAutosize, CardItemTile, NTextDelimiter, ThumbnailItem, NModalWindow, NButton },
  emits: ['save', 'close']
})
export default class AlertsCommentsDialog extends Vue {
  localItems: string[] = [];
  isInput = false;
  selectedComment = '';
  isError = false;
  noCommentField: HTMLElement | null = null;

  @Prop({ required: false, type: Boolean, default: false })
  readonly isCompleted: boolean = false;

  @Prop({ required: false, type: Array, default: [] })
  readonly items?: string[];

  saveComment() {
    if (this.isInput) {
      if (this.selectedComment === '') {
        this.isError = true;
        setTimeout(() => (this.isError = false), 2000);
        return;
      }
    }
    if (this.selectedComment === '') this.selectedComment = this.$t('alerts.noComments');
    this.$emit('action', this.selectedComment);
  }

  toggleInput() {
    this.isInput = true;
    this.selectedComment = '';
  }

  toggleSelect(value: string) {
    if (this.isInput) {
      this.isInput = false;
      this.selectedComment = '';
    }
    this.selectedComment = value;
  }

  mounted() {
    this.localItems = this.items || [];
  }
}
