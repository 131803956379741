import { Vue } from 'vue-class-component';
import CommonItem from '@/components/common/page/CommonItem.vue';
import { ListViewModel } from '@/definitions/view-models';
import { CommonPagePlaying } from '@/components/common/page/CommonPagePlaying';
import { IFormLayout } from '@/uikit/forms/NForm.vue';

type ActionHandlerFunction = (action: string, item: any) => void;
import { MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';
import { DisplayType, DisplayTypes } from '@/store/application/page.definitions';
import { ITableColumn } from '@/uikit/table/NTable.vue';

type AddNewType = {
  show: boolean;
  handler: any;
  icon: string;
  label: string;
};

interface FilterOptions {
  show: boolean;
  hasReport: boolean;
  hasFilterManager: boolean;
  hasPinnedFields: boolean;
  schemeSmall: IFormLayout;
  schemeLarge: IFormLayout;
}

export class CommonPageState {
  module!: ListViewModel<any, any>;
  pageSidebarType: MultisidebarItemType = MultisidebarItemTypes.None;

  filter: FilterOptions = {
    show: false,
    hasReport: false,
    hasFilterManager: true,
    hasPinnedFields: true,
    schemeSmall: [],
    schemeLarge: []
  };

  addNew: AddNewType = {
    show: false,
    handler: undefined,
    icon: 'add',
    label: 'Add new'
  };

  sortTypes: any[] = [];

  // @to-do: объединить в displayType:{}
  displayTypeShow = false;
  displayType: DisplayType = DisplayTypes.Default;

  actionHandler: ActionHandlerFunction = () => console.log('action handler!');

  componentForShort: Vue<any> = CommonItem;
  componentForFull: Vue<any> = CommonItem;

  tableSchema: ITableColumn[] | (() => ITableColumn[]) = [];
  get computedTableSchema() {
    return this.tableSchema instanceof Function ? this.tableSchema() : this.tableSchema;
  }

  playing = new CommonPagePlaying();

  autoUpdate = 0;

  showStatistics = false;
  showAcknowledgeAll = false;

  serializeToLocalStorageAsKey = '';

  showTabs = false;
  tab = '';
  tabs = [];

  showSubTabs = false;
  subTab = '';
  subTabs = [];

  serialize(): string {
    return JSON.stringify({
      tab: this.tab,
      subTab: this.subTab,
      displayType: this.displayType,
      playingActive: this.playing.active
    });
  }

  unserialize(jsonData: string | null) {
    if (!jsonData) {
      return;
    }
    const data = JSON.parse(jsonData) || {};
    if (data.tab) {
      this.tab = data.tab;
    }
    if (data.subTab) {
      this.subTab = data.subTab;
    }
    if (data.displayType) {
      this.displayType = data.displayType;
    }
    if (data.playingActive) {
      this.playing.active = data.playingActive;
    }
  }
}
