
import { Options, Vue } from 'vue-class-component';
import { alertModule } from '@/store/application/alert.module';
import { languageModule } from '@/store/languages';
import { NButton } from '@/uikit';
const t = languageModule.getTranslatedToken;

@Options({
  name: 'AlertNotification',
  components: { NButton }
})
export default class AlertNotification extends Vue {
  clickHandler() {
    alertModule.play();
    this.$emit('close');
  }

  tr(val: string): string {
    return t(val);
  }
}
