
import { cloneDeep } from 'lodash';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { PuppeteerRemoteMonitoringEventsFilter } from '@/api/models/PuppeteerRemoteMonitoringEventsFilter';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { PagePaths, PageState, PageTypes } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { PageViewModel } from '@/store/application/page.view.model';
import { websocketPuppeteerModule } from '@/store/ws/websocket.module';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import FilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import CardSelect from '@/pages/external-search/requests/CardSelect.vue';
import ListPage from '@/pages/ListPage.vue';
import { satisfyPuppeteerRemoteMonitoringEvent } from './helpers';
import RemoteMonitoringEventItem from './RemoteMonitoringEventItem.vue';
import RemoteMonitoringEventTile from '@/pages/remote-monitoring/RemoteMonitoringEventTile.vue';
import EventItem from '@/components/events/EventItem.vue';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { getRemoteMonitoringFiltersBuilder } from '@/pages/remote-monitoring/filters.builder';
import { EventOpenedItems } from '@/components/events/types';
import { aclModule } from '@/store/acl';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import Statistics from '@/components/common/Statistics.vue';
import RemoteMonitoringEventItemProxy from '@/pages/remote-monitoring/RemoteMonitoringEventItemProxy.vue';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';

@Options({
  name: 'RemoteMonitoringEventsPage',
  components: {
    RemoteMonitoringEventItemProxy,
    Statistics,
    FilterSection,
    EventItem,
    RemoteMonitoringEventTile,
    CardSelect,
    FilterDateRange,
    ListPage,
    NBaseBar,
    NButton,
    RemoteMonitoringEventItem
  }
})
export default class RemoteMonitoringEventsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  private newEvents: RemoteMonitoringEvent[] = [];
  private pageVM: PageViewModel<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter> = new PageViewModel<
    RemoteMonitoringEvent,
    PuppeteerRemoteMonitoringEventsFilter
  >({
    tab: String(Math.random()),
    path: PagePaths.RemoteMonitoringEvents
  });

  private intersectionResultItem?: IntersectionResultItem | null = null;

  get displayItemsMap(): Record<string, boolean> | null {
    return this.intersectionResultItem?.displayItemsMap ?? null;
  }

  get state(): PageState {
    return this.pageVM.state as any;
  }

  get module() {
    return this.pageVM.module as ListViewModel<RemoteMonitoringEvent, PuppeteerRemoteMonitoringEventsFilter>;
  }

  get websocketModule() {
    return websocketPuppeteerModule;
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get playing() {
    return this.pageVM.state.playing;
  }

  get items() {
    return this.playing ? [...this.newEvents, ...this.module.items] : this.module.items;
  }

  get currentFilter() {
    return this.module.filter.current;
  }

  get selectedItems() {
    return this.sidebarModule.getItemsByType(MultisidebarItemTypes.PuppeteerRemoteMonitoringEvents);
  }

  get selectedCardItems() {
    return this.sidebarModule.getItemsByType(MultisidebarItemTypes.CardsHumans);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get acknowledgeAllowed() {
    try {
      return this.module.acl.update;
    } catch (e) {
      console.warn('AcknowledgeAllowed error', e);
      return true;
    }
  }

  get pageSidebarType() {
    return MultisidebarItemTypes.PuppeteerRemoteMonitoringEvents;
  }

  get smallFilterLayout() {
    return getRemoteMonitoringFiltersBuilder({ small: true });
  }

  get bigFilterLayout() {
    return getRemoteMonitoringFiltersBuilder({ small: false });
  }

  async created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    this.pageVM = reactive(pageModule.getPageViewModel(route)) as PageViewModel<any, any>;
    this.module.aclModelName = 'remotemonitoringrecord';
    this.module.aclPrefix = 'ffsecurity_puppeteer';
    this.module.aclModule = aclModule;
    autoUpdateHelper.addListInstance(this.module);
  }

  async mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.remote-monitoring-item-proxy',
      id: this.tab
    });
    this.syncIntersections(false);
  }

  @Watch('module.loading')
  syncIntersections(v: boolean) {
    if (v) return;
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('module.appending')
  appendingHandler(v: boolean, p: boolean) {
    if (v) return;
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  getIsItemSelected(item: RemoteMonitoringEvent) {
    const msbId = generateMultisidebarId(MultisidebarItemTypes.PuppeteerRemoteMonitoringEvents, item.id);
    return !!this.selectedItems.find((v) => v.id === msbId);
  }

  getIsCardSelected(item: RemoteMonitoringEvent) {
    const msbId = generateMultisidebarId(MultisidebarItemTypes.CardsHumans, item.card);
    return !!this.selectedCardItems.find((v) => v.id === msbId);
  }

  getOpenedItemType(item: RemoteMonitoringEvent) {
    const currentMsbId = this.sidebarModule.currentId;
    const eventMsbId = generateMultisidebarId(this.pageSidebarType, item.id);
    const cardMsbId = generateMultisidebarId(MultisidebarItemTypes.CardsHumans, item.card);
    if (!currentMsbId) return '';
    if (currentMsbId === eventMsbId) return EventOpenedItems.Item;
    if (currentMsbId === cardMsbId) return EventOpenedItems.Card;
  }

  @Watch('websocketModule.acknowledgedAll')
  unacknowledgedHandler(v: boolean) {
    if (v) this.refresh();
  }

  @Watch('websocketModule.updatedMonitoringEvent')
  monitoringEventChanged(v: RemoteMonitoringEvent) {
    const item = this.items.find((item: RemoteMonitoringEvent) => item.id === v.id);
    if (item) {
      Object.assign(item, v);
    }
  }

  togglePlaying() {
    this.pageVM.togglePlaying();
    this.currentFilter.created_date_lte = this.playing ? undefined : new Date().toISOString();
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  async acknowledgeAll() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeAllCreate();
    await this.module.get();
  }

  @Watch('websocketModule.monitoringEvent', { deep: false })
  handleNewEvent(remoteMonitoringEvent: RemoteMonitoringEvent) {
    const satisfyFilters = satisfyPuppeteerRemoteMonitoringEvent(this.module.filter.current, remoteMonitoringEvent);
    if (satisfyFilters) {
      this.newEvents.unshift(remoteMonitoringEvent);
      this.intersectionResultItem?.syncObservableTargetsNextTick();
      this.module.debouncedGetStatistic();
    }
  }

  @Watch('websocketModule.updateMonitoringEvent', { deep: true })
  handleUpdateEvent(remoteMonitoringEvent: RemoteMonitoringEvent) {
    if (satisfyPuppeteerRemoteMonitoringEvent(this.currentFilter, remoteMonitoringEvent)) {
      let event = this.items.find((v) => v.id === remoteMonitoringEvent.id);
      if (event) {
        Object.assign(event, remoteMonitoringEvent);
      }
    }
  }

  @Watch('currentFilter', { deep: true })
  async refresh() {
    await this.module.debouncedGet();
    this.newEvents = [];
  }

  @Watch('currentFilter.created_date_lte')
  handlerFilterDateLte(v: any, p: any) {
    if (v) {
      this.pageVM.state.playing = false;
    } else {
      this.pageVM.state.playing = true;
    }
  }

  async actionHandler(action: string, payload: any) {
    switch (action) {
      case 'info':
      case ItemsActionNames.ShowInfo:
      case ItemsActionNames.ShowItem:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: payload });
        break;
      case ItemsActionNames.ShowFullScreen:
        this.$photoViewer.show(payload);
        break;
      case ItemsActionNames.Acknowledge:
        // await this.acknowledgeHandler(id, payload);
        break;
      case ItemsActionNames.NavigateToCard:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.CardsHumans, rawItem: payload.card });
        break;
      default:
        console.warn('Unsupported action ', action, payload);
        break;
    }
  }
}
