
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { applicationModule } from '@/store/application';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { DisplayType, DisplayTypes } from '@/store/application/page.definitions';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { configModule } from '@/store/config';
import { NButtonGroupItem } from '@/uikit/buttons/types';

@Options({
  name: 'InterfaceSettingsPage',
  components: { NTooltip, NButtonGroup, SettingsPageLayout }
})
export default class InterfaceSettingsPage extends Vue {
  private currentListView = DisplayTypes.Short;

  reference: any = null;
  referenceChange(reference: any) {
    this.reference = reference;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get displayTypes() {
    const result = [...dataAssetsModule.displayTypes];
    if (!configModule.features.maps_enabled) {
      result.splice(2,1);
    }
    return result;
  }

  get applicationModule() {
    return applicationModule;
  }

  async created() {
    this.currentListView = applicationModule.settings.ui.list_view;
  }

  @Watch('currentListView')
  currentListViewHandler(v: any) {
    this.applicationModule.toggleListView(v);
  }
}
