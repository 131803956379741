import { OptionalIFormLayout, SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';

export function getRemoteMonitoringFiltersBuilder(options: SimpleFilterOptions) {
  const result: OptionalIFormLayout[] = [
    commonFilterSchemaModule.getCardSelect({ ...options, onMonitoring: true }),
    commonFilterSchemaModule.getWatchLists({ ...options }),
    commonFilterSchemaModule.getRemoteMonitoringEventDateRange(options)
  ];
  return result;
}
