
import { isNumber } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import { NButton, NInputNumber } from '@/uikit';

const AngleGteMin = -90;
const AngleGteMax = 90;

const AngleLteMin = -90;
const AngleLteMax = 90;

function castNullToUndefined(v: null | number) {
  return v === null ? undefined : v;
}

@Options({
  name: 'FilterAngleRange',
  components: {
    NButton,
    NInputNumber
  }
})
export default class FilterAngleRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: Object, required: true })
  gteFieldName!: string;

  @Prop({ type: Object, required: true })
  lteFieldName!: string;

  @Prop({ type: Boolean, default: false })
  plain = false;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  private gteValue: number | undefined = AngleGteMax;
  private lteValue: number | undefined = AngleLteMin;

  created() {
    [this.gteValue, this.lteValue] = [this.model[this.gteFieldName], this.model[this.lteFieldName]];
  }

  get angleGteMin() {
    return AngleGteMin;
  }

  get angleGteMax() {
    return AngleGteMax;
  }

  get angleLteMin() {
    return AngleLteMin;
  }

  get angleLteMax() {
    return AngleLteMax;
  }

  get clearable() {
    return isNumber(this.gteValue) || isNumber(this.lteValue);
  }

  reset() {
    this.gteValue = undefined;
    this.lteValue = undefined;
    if (this.model[this.gteFieldName]) this.model[this.gteFieldName] = undefined;
    if (this.model[this.lteFieldName]) this.model[this.lteFieldName] = undefined;
  }

  @Watch('model', { deep: true })
  modelChangeHandler() {
    if (this.model[this.lteFieldName] !== this.lteValue) this.lteValue = this.model[this.lteFieldName];
    if (this.model[this.gteFieldName] !== this.gteValue) this.gteValue = this.model[this.gteFieldName];
  }

  changeHandlerGte(scoreValue: number | null) {
    this.gteValue = castNullToUndefined(scoreValue);
    Object.assign(this.model, { [this.gteFieldName]: this.gteValue });
  }

  changeHandlerLte(scoreValue: number | null) {
    this.lteValue = castNullToUndefined(scoreValue);
    Object.assign(this.model, { [this.lteFieldName]: this.lteValue });
  }
}
