import get from 'lodash/get';
import { CommonCluster } from '@/store/clusters/types';
import { ObjectsType, ObjectsTypesMap } from '@/store/application/data.assets';

const features: Record<string, string[]> = {
  [ObjectsTypesMap.Faces]: ['emotions', 'gender', 'glasses', 'beard', 'liveness', 'look', 'medmask', 'eyes_attrs'],
  [ObjectsTypesMap.Bodies]: [
    'headwear',
    'upper_clothes',
    'detailed_upper_clothes',
    'top_color',
    'lower_clothes',
    'bottom_color',
    'bag_back',
    'bag_hand',
    'bag_ground',
    'vest_type',
    'helmet_type',
    'gender',
    'age_group',
    'fall',
    'handface_smoking',
    'handface_phone_call',
    'handface_phone_use'
  ],
  [ObjectsTypesMap.Cars]: [
    'color',
    'body',
    'make',
    'model',
    'license_plate_number',
    'license_plate_country',
    'license_plate_number_color',
    'license_plate_region',
    'special_vehicle_type',
    'category',
    'weight_type',
    'orientation'
  ]
};

type Dictionary = Record<string, string>;
const featureNameToFilterNameMap: Record<string, Dictionary> = {
  [ObjectsTypesMap.Bodies]: {
    gender: 'body_gender'
  },
  [ObjectsTypesMap.Cars]: {
    license_plate_number: 'license_plate_number_contains',
    category: 'category_type'
  }
};

export function getSatisfyEventFeatures(event: any, filter: any, objects: ObjectsType): boolean {
  return features[objects].reduce<boolean>((m, featureName) => {
    const filterName = featureNameToFilterNameMap?.[objects]?.[featureName] ?? featureName,
      filterValue = filter[filterName],
      isArrayFilterValue = Array.isArray(filterValue),
      hasFilterValue = filterValue && filterValue.length;

    if (!m || !hasFilterValue) return m;
    const featureValue = get(event, `features.${featureName}.name`);
    const result = isArrayFilterValue ? !!filterValue.find((i: string) => i === featureValue) : featureValue.indexOf(filterValue) > -1;
    return result;
  }, true);
}

export function getSatisfyClusterFeatures(cluster: CommonCluster, filter: any, objects: ObjectsType) {
  return features[objects].reduce<boolean>((m, featureName) => {
    const filterName = featureNameToFilterNameMap?.[objects]?.[featureName] ?? featureName,
      filterValue = filter[filterName],
      isArrayFilterValue = Array.isArray(filterValue),
      hasFilterValue = filterValue && filterValue.length;

    if (!m || !hasFilterValue) return m;
    const featureValue = get(cluster, featureName);
    return isArrayFilterValue ? !!filterValue.find((i: string) => i === featureValue) : featureValue.indexOf(filterValue) > -1;
  }, true);
}
