
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { AuthService } from '@/api';
import { PagePaths } from '@/store/application/page.definitions';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { computeSessionMonitorConfig, detectTokenState, getUserDirection, recordTokenRenewalVideo, TokenState, UserDirection } from './session-monitor-helpers';

@Options({
  name: 'SessionMonitor'
})
export default class SessionMonitor extends Vue {
  timeout = 0;

  get config() {
    if (configModule.config.auth) {
      return computeSessionMonitorConfig(configModule.config.auth);
    }
    throw new Error("Can't start session monitor without auth config.");
  }

  get currentUser() {
    return dataModule.currentUserModule.item;
  }

  @Watch('currentUser.id', { immediate: true })
  handleUserIdChange(id: number | undefined, previousId: number | undefined) {
    if (this.config.enabled) {
      this.toggleSchedulerState(id, previousId);
    }
  }

  toggleSchedulerState(id: number | undefined, previousId: number | undefined) {
    switch (getUserDirection(id, previousId)) {
      case UserDirection.Out:
        this.disableTokenRenewal();
        break;
      case UserDirection.In:
        this.scheduleTokenRenewal();
        break;
    }
  }

  scheduleTokenRenewal() {
    this.timeout = window.setTimeout(this.attemptTokenRenewal, this.config.intervalInMs);
  }

  disableTokenRenewal() {
    clearTimeout(this.timeout);
    this.timeout = 0;
  }

  attemptTokenRenewal() {
    switch (this.detectTokenState()) {
      case TokenState.Valid:
        return this.scheduleTokenRenewal();
      case TokenState.Expired:
        return this.logout();
      case TokenState.ExpiresSoon:
        return this.renewToken();
    }
  }

  detectTokenState() {
    const { expiresAt, intervalInMs, durationInMs } = this.config;
    return detectTokenState(Date.now(), expiresAt, intervalInMs, durationInMs);
  }

  renewToken(attempt = 1) {
    return recordTokenRenewalVideo()
      .then(this.videoAuthRenew)
      .then(this.scheduleTokenRenewal)
      .catch(() => this.handleTokenRenewalError(attempt));
  }

  async videoAuthRenew(video: Blob) {
    const loginResult = await AuthService.authVideoAuthRenewCreate({ video });
    authModule.setTokenAuthExpires(loginResult.token_expiration_datetime);
  }

  async handleTokenRenewalError(attempt: number) {
    if (attempt < this.config.attempts) {
      await this.renewToken(attempt + 1);
    } else {
      await this.logout();
    }
  }

  async logout() {
    await authModule.logout();
    this.$router.push(PagePaths.Login);
  }
}
