
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CarCard, CarCardAttachment, HumanCard, HumanCardAttachment } from '@/api';
import { CardsMultiToSingle, CardType } from '@/store/application/data.assets';
import { AttachmentModule } from '@/store/data/AttachmentModule';
import { NAttachment, NAttachments } from '@/uikit/attachments';

@Options({
  name: 'CardAttachments',
  components: { NAttachments }
})
export default class CardAttachments extends Vue {
  @Prop({ type: Object, required: true })
  item!: HumanCard | CarCard;

  @Prop({ type: String, required: true })
  type!: CardType;

  get attachmentModule() {
    return new AttachmentModule({
      url: `/${CardsMultiToSingle[this.type]}-card-attachments/`,
      source_id: this.item.id,
      source_form_field_name: 'card',
      source_filter_name: 'card'
    });
  }

  get attachments(): ReadonlyArray<NAttachment> {
    return (this.attachmentModule.module.items as ReadonlyArray<CarCardAttachment | HumanCardAttachment>).map<NAttachment>((attachment) => ({
      file: attachment.file,
      id: attachment.id,
      name: attachment.name ?? '',
      progress: 100,
      size: attachment.size,
      status: 'done'
    }));
  }

  mounted() {
    this.attachmentModule.loadAttachments();
  }
}
