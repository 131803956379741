import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: _ctx.to }, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(['n-tooltip', _ctx.className, { 'n-tooltip_view': _ctx.hasView }]),
      ref: "floating",
      style: _normalizeStyle(_ctx.wrapStyles)
    }, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.visibleArrow)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "n-tooltip__arrow",
            ref: "arrow",
            style: _normalizeStyle(_ctx.arrowStyles)
          }, null, 4))
        : _createCommentVNode("", true)
    ], 6), [
      [_vShow, _ctx.shown]
    ])
  ], 8, ["to"]))
}