import {
  ApiIdType,
  dataServiceFactory,
  DefaultHTTPService,
  ItemPromiseResponse,
  ListPromiseResponse,
  ListResponseData,
  StatisticResponse
} from '@/definitions/services/data.services';
import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import qs from 'qs';

interface AnnexListResponseData<T> {
  items: T[];
  page: number;
  pages: number;
  size: number;
  total: number;
}

export class AnnexHttpService<T, F> extends DefaultHTTPService<T, F> {
  getList(filters: Partial<F> | undefined): ListPromiseResponse<T> {
    const adaptedFilters = this.adaptFilters(filters);
    return super.getList(adaptedFilters).then((v) => this.adaptListResponse(v as any, filters));
  }

  getStatistics(filters: Partial<F> | undefined): StatisticResponse {
    const adaptedFilters = this.adaptFilters(filters);
    return super.getStatistics(adaptedFilters);
  }

  getListByAction(id: ApiIdType, action: string, filters: any): ListPromiseResponse<any> {
    const adaptedFilters = this.adaptFilters(filters);
    return super.getListByAction(id, action, adaptedFilters).then((v) => this.adaptListResponse(v as any, filters));
  }

  update(id: ApiIdType, data: Partial<T>): ItemPromiseResponse<T> {
    return this.axios({ url: `${this.url}/${id}/`, method: 'patch', data }).then(this.adaptResult);
  }

  adaptFilters(filters: any) {
    const result = { ...filters };
    if (result.ordering) {
      result.sort = result.ordering;
      delete result.ordering;
    }
    if (result.name_contains) {
      result.title = result.name_contains;
      delete result.name_contains;
    }
    return result;
  }

  adaptListResponse(v: AnnexListResponseData<T>, filters?: Partial<F>): ListResponseData<T> {
    const createNextPageUrl = () => this.url + '?' + qs.stringify({ ...filters, page: v.page + 1 });
    const result = {
      total: v.total,
      results: v.items,
      next_page: v.page < v.pages ? createNextPageUrl() : null
    };

    return result;
  }
}

export class AnnexApi {
  get alarmsDataService() {
    return new AnnexHttpService<AnnexAlarm, any>(dataServiceFactory.getAnnexAxiosInstance(), '/alarms/');
  }
}

export const annexApi = new AnnexApi();
