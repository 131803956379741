import debounce from 'lodash/debounce';
import { createDecorator } from 'vue-class-component';

function Debounce(delay: number) {
  return (target: any, key: string) => {
    const isClassComponentVueInstance = !!target?.created;
    if (isClassComponentVueInstance) {
      return createDecorator((options, k) => {
        const originalMethod = options.methods[key];
        options.methods[key] = debounce(originalMethod, delay);
      })(target, key);
    } else {
      return {
        configurable: true,
        enumerable: true,
        value: debounce(target[key], delay)
      };
    }
  };
}

export { Debounce };
