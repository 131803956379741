export default {
  alerts: 'Alert manager',
  new_alert: 'New alert',
  alert_rules: 'Alert rules',
  new_alert_rule: 'New alert rule',
  id: 'ID',
  id_alert: 'Alert message ID',
  id_alert_rule: 'Alert ID',
  name: 'Name',
  camera: 'Camera',
  camera_group: 'Camera Group',
  tags: 'Tags',
  delivery: 'Delivery',
  delivery_channels: 'Delivery channels',
  tabs: {
    general: 'General',
    'alert-rules': 'Alert rules',
    settings: 'Settings',
    'delivery-channels': 'Delivery channels',
    'fast-comments': 'Fast comments'
  },
  settings: {
    ack_interval: 'Acknowledgment Interval',
    ack_interval__desc: 'The value must be between 0 and 300',
    ddp_interval: 'Deduplication Interval',
    ddp_interval__desc: 'The value must be between 0 and 300',
    reset_ack_interval: 'Reset Acknowledge Interval',
    reset_ack_interval__desc: 'Reset Acknowledge Interval',
    reset_ddp_interval: 'Enable deduplication',
    reset_ddp_interval__desc: 'Enable deduplication to exclude coinciding object recognition events within the specified deduplication interval',
    alert_feed: 'Alarm Monitor',
    alert_feed__desc: 'Receive alarm notifications in the Alarm Monitor and Alarm Feed. The setting is enabled by default and cannot be configured.',
    telegram_channel: 'Telegram',
    telegram_channel__desc: 'Receive alarm notifications in Telegram. Requires configuration (see documentation).',
    email: 'Email',
    email_channel__desc: 'Email Channel Description'
  },
  deliveryChannels: {
    alarm_screen: 'Alarm Monitor',
    telegram: 'Telegram',
    email: 'Email',
    external_system: 'External System'
  },
  noComments: 'Without comment',
  cancel: 'Cancel',
  addComment: 'Add comment',
  addCommentAndComplete: 'Add comment & Complete',
  ownComment: 'Your comment',
  add_fast_comment: 'Add Fast Comment',
  reset_alert_rule_confirmation: 'You will lose your current alert rule configuration if you select another section. Are you sure you want to continue?',
  empty_alert_rule_error: 'You have to configure an alert rule.',
  empty_list: 'You have not configured any alerts'
};
