import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f329e73e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alerts-comments-dialog" }
const _hoisted_2 = { class: "alerts-comments-dialog__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NTextareaAutosize = _resolveComponent("NTextareaAutosize")!
  const _component_NTooltip = _resolveComponent("NTooltip")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createBlock(_component_NModalWindow, {
    label: _ctx.$t(_ctx.isCompleted ? 'alerts.addComment' : 'alerts.addCommentAndComplete', 'f'),
    onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close'))),
    size: "medium"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NButton, {
          type: "outline",
          class: _normalizeClass(["alerts-comments-dialog__no-comments-btn", { 'alerts-comments-dialog__no-comments-btn_selected': _ctx.selectedComment === _ctx.$t('alerts.noComments') }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSelect(_ctx.$t('alerts.noComments'))))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('alerts.noComments')), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localItems, (item) => {
          return (_openBlock(), _createBlock(_component_NButton, {
            key: item,
            selected: _ctx.selectedComment === item,
            type: "outline",
            class: "alerts-comments-dialog__comment",
            onClick: ($event: any) => (_ctx.toggleSelect(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item), 1)
            ]),
            _: 2
          }, 1032, ["selected", "onClick"]))
        }), 128)),
        (!_ctx.isInput)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: "outline",
              class: "alerts-comments-dialog__comment",
              onClick: _ctx.toggleInput
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('alerts.ownComment')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : (_openBlock(), _createBlock(_component_NTextareaAutosize, {
              key: 1,
              ref: "noCommentField",
              modelValue: _ctx.selectedComment,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedComment) = $event)),
              onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isError = false))
            }, null, 8, ["modelValue"])),
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_NTooltip, {
              key: 2,
              reference: _ctx.$refs.noCommentField.$el
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('errors.required.field')), 1)
              ]),
              _: 1
            }, 8, ["reference"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_NButton, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveComment()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.isCompleted ? 'alerts.addComment' : 'alerts.addCommentAndComplete')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NButton, {
            type: "secondary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('alerts.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["label"]))
}