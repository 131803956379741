import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "camera-status__hint" }
const _hoisted_2 = { class: "camera-status__description label-s" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraStatusBrief = _resolveComponent("CameraStatusBrief")!
  const _component_CameraStatusDetails = _resolveComponent("CameraStatusDetails")!
  const _component_NHint = _resolveComponent("NHint")!

  return (_openBlock(), _createBlock(_component_NHint, {
    class: "camera-status",
    placement: "bottom",
    type: "dark"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.statusDescription), 1),
        _createVNode(_component_CameraStatusDetails, { details: _ctx.details }, null, 8, ["details"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CameraStatusBrief, {
        color: _ctx.camera.health_status.code,
        status: _ctx.showStatusLabel ? _ctx.camera.health_status.status : '',
        type: _ctx.statusType
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["color", "status", "type"])
    ]),
    _: 3
  }))
}