import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { dataModule } from '@/store/data';
import { mapObjectToSelectItem } from '@/common/utilsFilterSchema';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { CameraGroup } from '@/api';

const statusItems = [
  { value: 'new', i18n_label: 'annex_alarms.statuses.new' },
  { value: 'in_work', i18n_label: 'annex_alarms.statuses.in_work' },
  { value: 'completed', i18n_label: 'annex_alarms.statuses.completed' }
];

const deliveryChannels = [
  { value: 'websocket', i18n_label: 'alerts.deliveryChannels.alarm_screen' },
  { value: 'telegram', i18n_label: 'alerts.deliveryChannels.telegram' }
  /*  { value: 'email', i18n_label: 'alerts.deliveryChannels.email' },
  { value: 'external_system', i18n_label: 'alerts.deliveryChannels.external_system' }*/
];

export function getAlertsFiltersBuilder(options: SimpleFilterOptions) {
  const loadTags = async () => {
    const axios = dataServiceFactory.getAxiosInstance();
    const result = await axios.get('/v1/alert-rules/tags/');
    return (result.data?.tags || []).map((v: string) => ({ value: v, label: v }));
  };

  const loadCameraGroups = async () => {
    await dataModule.cameraGroupsModule.firstLoadingPromise;
    return dataModule.cameraGroupsWithoutVideoGroups.map((v: CameraGroup) => ({ value: v.id, label: v.name }));
  };

  const loadCameras = async () => {
    await dataModule.camerasModule.firstLoadingPromise;
    return mapObjectToSelectItem(dataModule.camerasModule.items);
  };

  return [
    commonFilterSchemaModule.getDateRange({ ...options, i18n_label: 'common.creation_date' }),
    commonFilterSchemaModule.getUser({ ...options, path: 'created_by' }),
    commonFilterSchemaModule.getActiveStatus({ ...options }),
    commonFilterSchemaModule.getCameraGroups({ ...options, path: 'camera_group_in', loadItems: loadCameraGroups }),
    commonFilterSchemaModule.getCameras({ ...options, path: 'camera_in', loadItems: loadCameras }),
    commonFilterSchemaModule.getTags({ ...options, path: 'tag_in', loadItems: loadTags }),
    commonFilterSchemaModule.getAnnexAlarmReaction({
      ...options,
      path: 'delivery_channel',
      i18n_label: 'alerts.delivery_channels',
      i18n_placeholder: 'alerts.delivery_channels',
      items: deliveryChannels
    })
  ].filter((item) => !!item) as IFormLayout;
}
