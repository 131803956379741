
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceLabel from '@/components/common/ConfidenceLabel.vue';

@Options({ name: 'VerifyResults', components: { Confidence, ConfidenceLabel } })
export default class VerifyResults extends Vue {
  @Prop({ type: Number })
  confidence!: any;

  @Prop({ type: String })
  object = '';

  @Prop({ type: Boolean, default: false })
  canVerify = false;

  get label() {
    return dataAssetsModule.confidenceLabel(this.object, this.confidence);
  }

  get resultLabel() {
    const confidenceFixed = this.confidence.toFixed(3);
    const confidenceResult = confidenceFixed > 0.9 ? confidenceFixed : confidenceFixed.slice(1);
    return `${this.$t('verify.matched_score')} ${confidenceResult} (${this.label})`;
  }
}
