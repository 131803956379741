
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import { viewModelRepository } from '@/api/common';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { aclModule } from '@/store/acl';
import { configModule } from '@/store/config';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NButton, NDateTimeLabel } from '@/uikit';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';

@Options({
  name: 'RemoteMonitoringEventItem',
  components: { CardItemProxy, CardItemTile, Confidence, ConfidenceDelimiter, NButton, NDateTimeLabel, NThumbnail },
  emits: ['showImage']
})
export default class RemoteMonitoringEventItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringEvent;

  @Prop({ type: String, default: '' })
  reasons!: string;

  private cardModule = viewModelRepository.getCardsHumansItemViewModel();
  private hasCard = true;

  get multisidebarModule() {
    return multisidebarModule;
  }

  get pageSidebarType() {
    return MultisidebarItemTypes.CardsHumans;
  }

  get card() {
    return this.cardModule.item;
  }

  get modelAcl() {
    return aclModule.getModelAclByName('humancard');
  }

  get isShowConnections() {
    return configModule.features.cases_enabled && aclModule.getAccess('ffsecurity.view_case');
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.multisidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, card.id);
  }

  mounted() {
    this.loadCard();
  }

  showItemImage() {
    this.$photoViewer.show(this.item);
  }

  showCardImage() {
    let objectsVM = this.$refs.card.objectsVM;
    if (objectsVM && objectsVM.items?.length) {
      this.$photoViewer.show(objectsVM.items[0]);
    }
  }

  loadCard() {
    if (this.item.card) {
      try {
        this.cardModule.get(this.item.card);
      } catch (e) {
        this.hasCard = false;
      }
    } else {
      this.hasCard = false;
    }
  }

  async acknowledge() {
    await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeCreate(this.item.id);
    Object.assign(this.item, { acknowledged: true });
  }
}
