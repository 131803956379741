export default {
  external_search: 'Удалённый поиск',
  add_to_monitoring: 'Поставить на мониторинг',
  add_all_selected_to_monitoring: 'Поставить выбранные на мониторинг',
  remove_from_monitoring: 'Снять с мониторинга',
  remove_all_selected_from_monitoring: 'Снять выбранные с мониторинга',
  monitoring_events: 'События мониторинга',
  monitoring: 'На мониторинге',
  monitoring_letter: 'М',
  monitoring_reason: 'Причина постановки на мониторинг',
  monitoring_reason_placeholder: 'Укажите причину для постановки на мониторинг',
  acknowledge: 'подтвердить',
  acknowledge_all: 'подтвердить все',
  unmatched: 'нет совпадений',
  new_events: 'Новые события',
  alert_text: 'Все связанные события удаленного мониторинга будут удалены',
  agree: 'Согласен',
  cancel: 'Отмена',
  source: 'Источник',
  remote_monitoring_event: 'Событие мониторинга'
};
