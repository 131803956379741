import { cloneDeep, set } from 'lodash';
import { EventOpenedProps, EventSelectedProps } from '@/pages/uicomponents/types';
import AnnexAlarmItemRow from '@/pages/annex/alarms/AnnexAlarmItemRow.vue';

type OnePropVariations = any[];
type PropsVariations = Record<string, OnePropVariations>;

function buildPropsVariation(props: any, variations: PropsVariations, data: number[]) {
  const propsCopy = cloneDeep(props);
  const variationKeys = Object.keys(variations);
  for (let i = 0; i < data.length; i++) {
    const variationKey = variationKeys[i];
    const variationValue = variations[variationKey][data[i]];
    set(propsCopy, variationKey, variationValue);
  }
  return propsCopy;
}

function buildPropsInDifferentVariations(props: any, variations: PropsVariations) {
  const result: any = {
    name: 'Annex Alarms',
    propConfigs: [null, EventOpenedProps, EventSelectedProps],
    items: []
  };

  const variationKeys = Object.keys(variations);
  const totalVariations = variationKeys.reduce((previousValue: number, currentValue: string) => {
    return previousValue * variations[currentValue].length;
  }, 1);
  console.log('totalVariations: ', totalVariations);

  for (let i = 0; i < totalVariations; i++) {
    let value = i;
    let power = totalVariations;
    const size = variationKeys.length;
    const data = new Array(size).fill(0); // zero filled array
    for (let j = 0; j < size; j++) {
      power = power / variations[variationKeys[j]].length;
      data[j] = Math.floor(value / power);
      value = value % power;
    }

    result.items.push({
      name: `variation: ${i} ${data}`,
      component: AnnexAlarmItemRow,
      layout: 'horizontal',
      props: buildPropsVariation(props, variations, data)
    });
    // console.log(buildPropsVariation(props, variations, data));
  }

  return result;
}

const annexData = {
  color: 'danger', // accent, warning, error
  name: 'Missing helmet123',
  timerFrom: 123,
  timerTo: 0,
  acknowledge: 'done_all_auto', // buttons, done_all, done_all, done_all_auto
  confidence: 0.8,
  reasons: '123',
  thumbnail:
    'https://sun1-98.userapi.com/s/v1/if2/0-JvI_wsRrDcwi86nNDp5mohLMzntbi9gaT9in' +
    'gtYoLIZrttVq9UwGKcB5jVlFO5FRpGT7-6ubn4aEWB-IABFqMB.jpg?quality=96&crop=172,' +
    '153,983,983&as=50x50,100x100,200x200,400x400&ava=1&u=jZOuDcrhMkThiqWtlMPp9' +
    'fHhpj_bO3lgQlso-GRud_4&cs=200x200',
  created_date: '2023-12-27T16:20:21.343854Z',
  modified_date: '2024-01-16T11:39:45.629345Z'
};

const realAnnex = {
  declared_at: '2024-01-12T11:18:15+00:00',
  tag: 'multi',
  title: 'Webhook 3',
  source: 'multi',
  stream: 'camera#1',
  status: 'OK',
  comment: 'My comment message is that current time 0.25534784606657746',
  extra: {
    scopes: ['ffsecurity.view_faceevent'],
    camera_groups: [1],
    watch_lists: { '-1': 'Unmatched' },
    line: null,
    match: false,
    counter: false
  },
  channels: '100',
  ddp_id: '127.0.0.1 | 3',
  ddp_interval: null,
  ddp_reset: false,
  ack_interval: null,
  ack_enabled: false,
  id: 1,
  created_at: '2024-01-12T11:18:15.841908+00:00',
  updated_at: '2024-01-22T15:40:30.868880+00:00',
  ack_value: -1,
  ack_author: null,
  ack_at: null,
  images: [
    { tag: 'thumbnail', path: 'http://172.17.47.186/uploads/event/face/2024/01/12/11/18/bC/111815_face_thumbnail_Y1h2pL.jpg', id: 1, alarm_id: 1, legends: [] },
    {
      tag: 'fullframe',
      path: 'http://172.17.47.186/uploads/event/face/2024/01/12/11/18/a2/111815_face_full_frame_1DSXi3.jpg',
      id: 2,
      alarm_id: 1,
      legends: [{ tag: 'track', boxes: [[829, 185, 883, 265]], points: [], id: 1, image_id: 2 }]
    }
  ]
};

const propsData = {
  item: realAnnex,
  selected: false,
  opened: false
};

const variations: PropsVariations = {
  'item.color': ['accent', 'warning', 'danger'],
  'item.acknowledge': ['buttons', 'done_all', 'done_all', 'done_all_auto'],
  selected: [true, false],
  opened: [true, false]
};

const annexAlarmGroup = buildPropsInDifferentVariations(propsData, variations);

export default annexAlarmGroup;
