
import { nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { BodyCluster, CarCard, CarCluster, CaseCluster, FaceCluster, HumanCard, Settings } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper.ts';
import { applicationModule } from '@/store/application';
import { CardTypesMap, ObjectsMultiToSingle, ObjectsSingleToMulti, ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { DataAssetsModule, dataAssetsModule } from '@/store/application/data.assets.module';
import {
  DisplayType,
  DisplayTypes,
  EmptySearchPageState,
  PagePaths,
  PageTypes,
  SearchPageState
} from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { MultisidebarItemType, MultisidebarItemTypes, MultisidebarRawItemPayload } from '@/store/multisidebar/types';
import { NConfidence } from '@/uikit';
import NAlertBadge from '@/uikit/alert-badge/NAlertBadge.vue';
import { NAttachment } from '@/uikit/attachments';
import { BboxConfig } from '@/uikit/bbox/types';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import NThemeImage from '@/uikit/image/NThemeImage.vue';
import NInput from '@/uikit/input/NInput.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { EventDetails } from '@/uikit/thumbnail';
import { Debounce } from '@/common/debounce-decorator';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import { ClusterOpenedItems } from '@/components/clusters/types';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceLabel from '@/components/common/ConfidenceLabel.vue';
import DisplayTypesButtonGroup from '@/components/common/DisplayTypesButtonGroup.vue';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import { eventEpisodeAdapter } from '@/components/events/adapter';
import EventItem from '@/components/events/EventItem.vue';
import EventItemShort from '@/components/events/EventItemShort.vue';
import { AnyEvent, EventOpenedItems, EventOrEpisode } from '@/components/events/types';
import { getCardFiltersBuilder as getCardFilters } from '@/pages/cards/filters/CardFiltersBuilder';
import ClusterWizard from '@/pages/cases/case-cluster-wizard/ClusterWizard.vue';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import { getCaseClusterFiltersBuilder, getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import ListPage from '@/pages/ListPage.vue';
import SearchFromSelect from '@/pages/search/SearchFromSelect.vue';
import SearchOverlay from '@/pages/search/SearchOverlay.vue';
import { SearchResultsViewModel } from '@/pages/search/SearchResultsViewModel.ts';
import { SearchSourceViewModel } from '@/pages/search/SearchSourceViewModel';
import SearchSidebar from './SearchSidebar.vue';
import NLoadingDots from '@/uikit/loading/NLoadingDots.vue';
import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';
import EventResultPage from '@/pages/search/EventResultPage.vue';

function removeFilterByPath(path: string) {
  return (filter: Record<string, any>) => filter.path !== path;
}

@Options({
  name: 'SearchPage',
  components: {
    EventResultPage,
    NLoadingDots,
    CardItemProxy,
    CaseClusterItem,
    ClusterItem,
    ClusterWizard,
    Confidence,
    ConfidenceLabel,
    DetectionDialog,
    DisplayTypesButtonGroup,
    EventItem,
    EventItemShort,
    FilterSection,
    InfiniteScroll,
    ListPage,
    NAlertBadge,
    NButton,
    NButtonGroup,
    NInput,
    NModalWindow,
    NThemeImage,
    SearchFromSelect,
    SearchOverlay,
    SearchSidebar,
    SortDropdown,
    Statistics
  }
})
export default class SearchPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  private isDetectionDialogVisible = false;
  private state = pageModule.getPageStateByTab(PagePaths.Search, this.tab || `tab_${Math.random()}`) as SearchPageState;
  private sourceModule = SearchSourceViewModel.create(this.state);
  private resultsModule = SearchResultsViewModel.create(this.state);

  get module(): ListViewModel<any, any> {
    return this.resultsModule.module as ListViewModel<any, any>;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get showEmpty() {
    return !this.module || !this.module.filter.current.looks_like?.length || (!this.module.loading && this.module.loaded && this.module.items.length === 0);
  }

  get isCaseClustersPage() {
    return this.state.pageType === PageTypes.CaseClusters || this.state.pageType === PageTypes.CaseParticipants;
  }

  get isEventsPage() {
    return this.state.pageType === PageTypes.Events;
  }

  get isClustersPage() {
    return this.state.pageType === PageTypes.Clusters;
  }

  get isCardsPage() {
    return this.state.pageType === PageTypes.Cards;
  }

  get currentItemId() {
    return this.sidebarModule.currentItem?.id;
  }

  get pageSidebarType() {
    const { pageType, objectType, cardType } = this.state;
    if (pageType === PageTypes.Cards) {
      return `${pageType}_${cardType}`;
    } else if (pageType === PageTypes.CaseClusters) {
      return MultisidebarItemTypes.CaseClusters;
    }
    return `${pageType}_${objectType}`;
  }

  get cardsSidebarType() {
    const { cardType } = this.state;
    return `${PageTypes.Cards}_${cardType}`;
  }

  get debugMode() {
    return applicationModule.settings.debug;
  }

  get canCreateReport() {
    return !this.isCaseClustersPage;
  }

  get sortTypes(): any[] {
    if (this.state.pageType === PageTypes.Events) {
      return dataAssetsModule.getSortTypes({ looks_like_confidence: { desc: true }, idAsCreatedDate: true });
    }
    return dataAssetsModule.getSortTypes({ looks_like_confidence: { desc: true }, created_date: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get attachment(): NAttachment | undefined {
    return this.sourceModule.isFile ? this.sourceModule.sourceVM?.item : undefined;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get getAllowed() {
    return (
      !this.sourceModule.loading &&
      !this.sourceModule.hasError &&
      (this.sourceModule.sourceItem || this.sourceModule.bboxThumbnail) &&
      this.resultsModule.looksLike.length &&
      !this.showMultiselectWarning
    );
  }

  get showMultiselectWarning() {
    return this.state.pageType === PageTypes.Events && ['id', '-id'].includes(this.module.filter.current.ordering) && this.resultsModule.looksLike.length > 1;
  }

  get defaultEventAction() {
    return EventDetails.ShowInfo;
  }

  get viewerItems() {
    return this.module.items.map((v: any) => eventEpisodeAdapter(v, this.state.objectType));
  }

  get hasRowView() {
    return this.state.pageType === PageTypes.Cards || this.state.pageType === PageTypes.Events;
  }

  get availableDisplayTypes() {
    const result: DisplayType[] = [DisplayTypes.Short, DisplayTypes.Full];
    if (this.state.pageType === PageTypes.Events && configModule.features.maps_enabled) result.push(DisplayTypes.Map);
    return result;
  }

  get confidenceClass() {
    return {
      search__confidence: true,
      search__confidence_row: this.hasRowView && this.state.displayType === DisplayTypes.Full
    };
  }

  get pageClasses(): Record<string, boolean> {
    return {
      'search_map': this.state.displayType === 'map'
    };
  }

  @Watch('state.id', { immediate: true })
  handleStateIdResetInFileMode(v: string) {
    if (!this.state.showOverlay && this.state.searchFrom === SearchFromMap.File && (v === '' || !this.sourceModule.bboxThumbnail)) {
      this.backToStart();
    }
  }

  @Watch('state.searchFrom')
  handleSearchFrom(v: string, p: string) {
    if (v !== SearchFromMap.File) {
      this.state.id = '';
    } else if (this.state.searchFrom === SearchFromMap.File && !this.state.showOverlay) {
      this.state.showOverlay = true;
      this.state.id = '';
      this.sourceModule.clearModel();
      this.sourceModule.bboxThumbnail = null;
    }
  }

  @Watch('state.id')
  @Watch('state.searchFrom')
  @Watch('state.objectType')
  @Watch('state.cardType')
  stateChangeHandler(v: string, p: string) {
    nextTick(() => {
      this.sourceModule.load(this.state.id as any);
    });
  }

  @Watch('resultsModule.looksLike', { immediate: true, deep: true })
  loadResultsOnDetectionChange(v: string) {
    this.updateFilter();
    this.module.reset();
    this.get(true);
  }

  @Watch('state.pageType')
  pageTypeHandler(value: string) {
    this.state.displayType = DisplayTypes.Default;
    this.updateFilter();
    this.get(true);
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    console.log('Updated filter ', JSON.stringify(v), JSON.stringify(p));
    if (v?.page !== p?.page) return;
    this.get();
  }

  @Watch('attachment')
  showDetectionDialog(v?: NAttachment, p?: NAttachment) {
    this.attachment && (this.isDetectionDialogVisible = true);
  }

  @Watch('sourceModule.loaded')
  @Watch('sourceModule.loading')
  sourceItemLoadedHandler(value: boolean) {
    console.log('Search module loaded', value);
    if (this.sourceModule.loaded && !this.sourceModule.isFile) this.state.showOverlay = false;
  }

  getOpenedTypeByCluster(cluster: BodyCluster | FaceCluster | CarCluster) {
    if (!this.currentItemId) return '';
    if (this.currentItemId === generateMultisidebarId(this.pageSidebarType, cluster.id)) return ClusterOpenedItems.Cluster;
    if (this.currentItemId === generateMultisidebarId(this.cardsSidebarType, cluster.card)) return ClusterOpenedItems.Card;
  }

  getOpenedTypeByEvent(event: AnyEvent) {
    if (!this.currentItemId) return '';
    if (this.currentItemId === generateMultisidebarId(this.pageSidebarType, event.id)) return EventOpenedItems.Item;
    if (event.matched_card && this.currentItemId === generateMultisidebarId(this.cardsSidebarType, event.matched_card)) return EventOpenedItems.Card;
  }

  getIsItemOpened(item: CaseCluster | HumanCard | CarCard) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.pageSidebarType, item.id);
  }

  selectBBOX(bboxes: BboxConfig[]) {
    const bbox = bboxes[0];
    if (bbox.meta?.type && bbox.meta?.id) {
      const type = bbox.meta?.type;
      const types = ObjectsSingleToMulti[type];
      const result: Record<string, any> = {
        detectionId: bbox.meta?.id,
        objectType: types,
        cardType: types === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        episodeType: types === ObjectsTypesMap.Cars ? CardTypesMap.Cars : CardTypesMap.Humans,
        searchFrom: SearchFromMap.File
      };
      Object.assign(this.state, result);
      this.state.id = this.attachment?.name;
      this.state.showOverlay = false;
      this.sourceModule.bboxThumbnail = bbox.thumbnail;
      console.log('Apply bbox and close overlay');
      this.closeDetectionDialog();
    } else {
      console.warn('[search] Unknown bbox type or id in meta: ' + JSON.stringify(bbox));
    }
  }

  closeDetectionDialog() {
    this.isDetectionDialogVisible = false;
  }

  backToStart() {
    Object.assign(this.state, EmptySearchPageState, { searchFrom: this.state.searchFrom, pageType: this.state.pageType, tab: this.state.tab, id: '' });
    this.state.showOverlay = true;
  }

  updateFilter() {
    const thresholdProperty = `${ObjectsMultiToSingle[this.state.objectType]}_confidence_threshold` as keyof Settings;
    const filter = {
      looks_like: this.resultsModule.looksLike,
      ordering: '-looks_like_confidence',
      threshold: settingsItemModule.item?.[thresholdProperty]
    };
    const empty = {
      looks_like: this.resultsModule.looksLike,
      ordering: '-looks_like_confidence',
      threshold: settingsItemModule.item?.[thresholdProperty]
    };

    Object.assign(this.module.filter.empty, empty);
    Object.assign(this.module.filter.current, filter);
  }

  get smallFilterLayout() {
    const { pageType, objectType, episodeType, cardType } = this.state;
    const confidenceField = {
      component: NConfidence,
      i18n_tooltip: 'ds.params.confidence_face_desc',
      tooltipPlacement: 'bottom',
      path: 'threshold'
    };
    const limitFilter = commonFilterSchemaModule.getLimit({ small: true });
    let resultByType: IFormLayoutItem[] = [];

    if (pageType === PageTypes.CaseClusters || pageType === PageTypes.CaseParticipants) {
      resultByType = [...getCaseClusterFiltersBuilder({ small: true }).getFilterByType(ObjectsTypesMap.Faces)];
    } else if (pageType === PageTypes.Cards && cardType) {
      resultByType = [...getCardFilters({ small: true }).getFilterByType(cardType).filter(removeFilterByPath('has_face_objects'))];
    } else {
      const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
      resultByType = [
        ...getEventFiltersBuilder({
          small: true,
          cardType: cardTypeByObjectType
        }).getFilterByType(pageType, objectType, episodeType)
      ];
    }
    return [confidenceField, ...resultByType, limitFilter];
  }

  get bigFilterLayout(): IFormLayoutItem[] {
    const { pageType, objectType, episodeType, cardType } = this.state;
    let result: IFormLayoutItem[] = [];
    if (pageType === PageTypes.CaseClusters) {
      result = getCaseClusterFiltersBuilder({ small: false }).getFilterByType(ObjectsTypesMap.Faces);
    } else if (pageType === PageTypes.Cards && cardType) {
      result = getCardFilters({ small: false }).getFilterByType(cardType).filter(removeFilterByPath('has_face_objects'));
    } else if (pageType === PageTypes.Cases) {
      result = [];
    } else {
      const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
      result = getEventFiltersBuilder({ small: false, cardType: cardTypeByObjectType }).getFilterByType(pageType, objectType, episodeType);
    }
    const limitFilter = commonFilterSchemaModule.getLimit({ small: false });
    result.push(limitFilter);
    return result;
  }

  computeCardTypeByObjectType(objectType: string) {
    return objectType === 'cars' ? 'cars' : 'humans';
  }

  getClustersBigFilterLayout() {
    const { pageType, objectType } = this.state;
    const cardTypeByObjectType = this.computeCardTypeByObjectType(objectType);
    const result = getClusterFiltersBuilder({ small: false, cardType: cardTypeByObjectType }).getFilterByType(pageType, objectType);
    const limitFilter = commonFilterSchemaModule.getLimit({ small: false });
    result.push(limitFilter);
    return result;
  }

  get bigFilterLayoutByPageType() {
    return this.isClustersPage ? this.getClustersBigFilterLayout() : this.bigFilterLayout;
  }

  navigateToCard(id: string | number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: this.cardsSidebarType, rawItem: id });
  }

  async openSearchTypeInTab(item: any) {
    const msbPayload: MultisidebarRawItemPayload = { type: `${this.state.pageType}_${this.state.objectType}` as MultisidebarItemType, rawItem: item };
    const caseCluster = item as CaseCluster;

    switch (this.state.pageType) {
      case PageTypes.Cards:
        msbPayload.type = `${this.state.pageType}_${this.state.cardType}` as MultisidebarItemType;
        break;
      case PageTypes.CaseClusters:
        if (caseCluster.role?.length) {
          msbPayload.type = MultisidebarItemTypes.CaseClusters;
        } else {
          this.$clusterWizardController.create(caseCluster);
          return;
        }
        break;
    }
    actionHandler.run(ItemsActionNames.ShowItem, msbPayload);
  }

  @Debounce(500)
  get(resetState = false) {
    if (this.getAllowed) {
      this.module.get({ resetState });
    }
  }

  async actionHandler(id: string | number, action: string, payload?: any): Promise<void> {
    const event = payload?.best_face_event || payload?.best_body_event || payload?.best_car_event || payload;
    switch (action) {
      case ItemsActionNames.Select:
        multisidebarModule.toggleItemSelect(this.pageSidebarType as MultisidebarItemType, payload);
        break;
      case ItemsActionNames.ShowEvents:
        this.showClusterEvents(id as any);
        break;
      case ItemsActionNames.ShowInfo:
      case ItemsActionNames.ShowItem:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
        break;
      case ItemsActionNames.ShowFullScreen:
        this.showItemFullscreen(payload);
        break;
      case ItemsActionNames.ShowPlayer:
        if (event) {
          const timeFrom = new Date(event.created_date).getTime() / 1000;
          this.$videoPlayer.playArchive(event.camera, timeFrom - 3);
        }
        break;
      case ItemsActionNames.NavigateToCard:
        await this.navigateToCard(payload.matched_card);
        break;
      default:
        console.warn('Unsupported action ', id, action, payload);
        break;
    }
  }

  async showClusterEvents(id: number) {
    const caseEventsModule = viewModelRepository.getCaseEventsListViewModel();
    caseEventsModule.filter.current.case_cluster_in = [id];
    await caseEventsModule.get();
    const viewerItems = caseEventsModule.items.map((v) => ({
      ...v
    }));
    this.$photoViewer.show(viewerItems);
  }

  showItemFullscreen(item: EventOrEpisode) {
    const activeItemIndex = this.viewerItems.findIndex((v) => v.id == item.id);
    if (activeItemIndex !== -1) {
      this.$photoViewer.show(this.viewerItems, { activeItemIndex });
    }
  }

  async init() {
    if (this.state.id) {
      await this.sourceModule.load(String(this.state.id));
    } else {
      this.state.showOverlay = true;
    }
  }

  mounted() {
    this.init();
    autoUpdateHelper.addListInstance(this.module);
  }

  beforeUnmount() {
    this.sourceModule.resetErrors();
  }

  deactivated() {
    this.sourceModule.resetErrors();
  }
}
