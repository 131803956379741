import eventItemData from './item.json';

const UnmatchedProps = {
  confidence: null,
  matched_card: null,
  matched_lists: [-1]
};

export const UnmatchedEventItemComponentProps = {
  item: Object.assign({}, eventItemData, UnmatchedProps),
  display: true
};

export const MatchedEventItemComponentProps = {
  item: eventItemData,
  display: true
};
