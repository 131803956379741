
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NIcon } from '@/uikit';
import FilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import { DateTimeRangeMode } from '@/uikit/datetime/NDateTimeRange.vue';

@Options({
  components: {
    FilterDateRange,
    NIcon
  },
  emits: ['close', 'update:modelValue']
})
export default class FilterSmallDataRange extends Vue {
  @Prop({ type: Object, required: true })
  model!: any;

  @Prop({ type: String, default: 'created_date_gte' })
  readonly gteProp!: string;

  @Prop({ type: String, default: 'created_date_lte' })
  readonly lteProp!: string;

  @Prop({ type: String })
  readonly label?: string;

  @Prop({ type: String })
  readonly dataQa?: string;

  @Prop({ type: String, default: 'dateTime' })
  readonly mode: DateTimeRangeMode = DateTimeRangeMode.DateTime;
}
