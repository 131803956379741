
import { Options, Vue } from 'vue-class-component';
import {Prop} from "vue-property-decorator";
import {License} from "@/pages/license_v1/types";
import LicenseCard from "@/pages/license_v1/components/common/LicenseCard.vue";
import GeneralCard from '@/pages/license_v1/components/common/GeneralCard.vue';

@Options({
  components: { GeneralCard, LicenseCard}
})
export default class GeneralContent extends Vue {
}
