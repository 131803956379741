import { reactive, render } from 'vue';
import { viewModelRepository } from '@/api/common';
import { configModule } from '@/store/config';
import { RemoteMonitoringRecordRequest } from '@/api/models/RemoteMonitoringRecordRequest';
import { HumanCard, WatchList } from '@/api';
import { appEnvironment } from '@/store/application/app.environment';
import { RemoteMonitoringRecord } from '@/api/models/RemoteMonitoringRecord';

type BooleanRecordMap = Record<string, boolean>;

class MonitoringModule {
  protected cards: HumanCard[] = [];
  protected watchLists: WatchList[] = [];

  protected dialogTimeoutId = 0;

  public remoteMonitoringLvm = viewModelRepository.getPuppeteerRemoteMonitoringListViewModel();
  public remoteMonitoringItemsByCard: Record<string, RemoteMonitoringRecord[]> = {};
  public remoteMonitoringItemsByWatchList: Record<string, RemoteMonitoringRecord[]> = {};

  public get isPuppeteerEnabled() {
    return !!configModule.config.plugins?.puppeteer;
  }

  get cardInRemoteMonitoring(): BooleanRecordMap {
    const reducer = (m: BooleanRecordMap, v: string) => { m[v] = this.remoteMonitoringItemsByCard[v]?.length > 0; return m; };
    return Object.keys(this.remoteMonitoringItemsByCard).reduce(reducer, {});
  }

  get watchListInRemoteMonitoring(): BooleanRecordMap {
    const reducer = (m: BooleanRecordMap, v: string) => { m[v] = this.remoteMonitoringItemsByWatchList[v]?.length > 0; return m; };
    return Object.keys(this.remoteMonitoringItemsByWatchList).reduce(reducer, {});
  }

  public addCardToMonitoring(card: HumanCard) {
    this.cards.push(card);
    if (this.dialogTimeoutId) clearTimeout(this.dialogTimeoutId);
    this.dialogTimeoutId = window.setTimeout(() => this.showAddDialog());
  }

  public addWatchListToMonitoring(watchList: WatchList) {
    this.watchLists.push(watchList);
    if (this.dialogTimeoutId) clearTimeout(this.dialogTimeoutId);
    this.dialogTimeoutId = window.setTimeout(() => this.showAddDialog());
  }

  protected async showAddDialog() {
    const container = document.body.appendChild(document.createElement('div'));
    const component = await this.getAddDialogComponent(closeHandler);
    render(component, container);
    this.clearDialogState();

    function closeHandler() {
      render(null, container);
      document.body.removeChild(container);
    }
  }

  protected clearDialogState() {
    this.cards = [];
    this.watchLists = [];
  }

  protected async getAddDialogComponent(closeHandler: () => void): Promise<JSX.Element> {
    const props = {
      items: this.cards,
      watchLists: this.watchLists,
      onClose: () => closeHandler()
    };
    const CardAddMonitoringAddDialog = (await import('./CardMonitoringAddDialog.vue') as any).default;
    const component = <CardAddMonitoringAddDialog {...props} />;
    component.appContext = appEnvironment.context;
    return component;
  }

  public async create(item: RemoteMonitoringRecordRequest) {
    await this.remoteMonitoringLvm.create(item);
    if (item.card) {
      return await this.getItemsByCard(item.card, true);
    } else if (item.watch_list) {
      return await this.getItemsByWatchList(item.watch_list, true);
    }
  }

  public async getItemsByCard(id: number, force = false) {
    if (this.remoteMonitoringItemsByCard[id] === undefined || force) {
      const listModel = viewModelRepository.getPuppeteerRemoteMonitoringListViewModel();
      listModel.filter.current.card = id;
      try {
        await listModel.get();
        const items = listModel.items.filter((item) => !item.is_deleted);
        this.remoteMonitoringItemsByCard[id] = items;
      } catch (e) {
        console.warn('[remoteMonitoringRecord] get error ', e, ' of card ', id);
        this.remoteMonitoringItemsByCard[id] = [];
      }
    }
    return this.remoteMonitoringItemsByCard[id] || [];
  }

  public async getItemsByWatchLists(ids: number[], force = false) {
    return await Promise.all((ids || []).map((id) => this.getItemsByWatchList(id, force)));
  }

  public async getItemsByWatchList(id: number, force = false) {
    if (this.remoteMonitoringItemsByWatchList[id] === undefined || force) {
      const listModel = viewModelRepository.getPuppeteerRemoteMonitoringListViewModel();
      listModel.filter.current.watch_list = id;
      try {
        await listModel.get();
        const items = listModel.items.filter((item) => !item.is_deleted && item.watch_list === Number(id));
        this.remoteMonitoringItemsByWatchList[id] = items;
      } catch (e) {
        console.warn('[remoteMonitoringRecord] get error ', e, ' of card ', id);
        this.remoteMonitoringItemsByWatchList[id] = [];
      }
    }
    return this.remoteMonitoringItemsByWatchList[id] || [];
  }

  isCardOnMonitoring(id: number) {
    if (id === undefined) {
      return false;
    }
    return this.cardInRemoteMonitoring[id];
  }

  isSomeWatchListOnMonitoring(ids: number[]) {
    return (ids || []).reduce((m, v) => { m = m || this.watchListInRemoteMonitoring[v]; return m; }, false);
  }

  public async deleteByWatchLists(ids: number[]) {
    for (let id of ids) {
      const items = this.remoteMonitoringItemsByWatchList[id] || [];
      for (let item of items) {
        if (!item.is_deleted && item.watch_list) {
          await this.remoteMonitoringLvm.delete(item.id);
        }
      }
      await this.getItemsByWatchList(id, true);
    }
  }

  public async deleteByCards(ids: number[]) {
    for (let id of ids) {
      const items = this.remoteMonitoringItemsByCard[id] || [];
      for (let item of items) {
        if (!item.is_deleted && item.card) {
          await this.remoteMonitoringLvm.delete(item.id);
        }
      }
      await this.getItemsByCard(id, true);
    }
  }

  public async getReasonsByCardId(cardId: number) {
    const items = await this.getItemsByCard(cardId);
    const reasons = items.map((v) => v.reason);
    return reasons.join(', ');
  }

  public clear() {
    this.remoteMonitoringLvm.reset();
    this.remoteMonitoringItemsByCard = {};
    this.remoteMonitoringItemsByWatchList = {};
  }
}

export const monitoringModule = reactive(new MonitoringModule());
