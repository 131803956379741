import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-item-small__thumbnail" }
const _hoisted_2 = { class: "card-item-small__content" }
const _hoisted_3 = { class: "card-item-small__name" }
const _hoisted_4 = { class: "card-item-small__name_SIDEEFFECT card-item-small__ellipsis heading-m" }
const _hoisted_5 = {
  key: 0,
  class: "card-item__monitoring label-s"
}
const _hoisted_6 = {
  key: 0,
  class: "card-item-small__ellipsis label-mono-m"
}
const _hoisted_7 = {
  key: 1,
  class: "card-item-small__comment card-item-small__ellipsis label-xs"
}
const _hoisted_8 = {
  key: 0,
  class: "card-item-small__close"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showThumbnail)
        ? (_openBlock(), _createBlock(_component_NThumbnail, {
            key: 0,
            modelValue: _ctx.thumbnail,
            size: "small",
            corners: "rounded"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formattedName), 1),
        (_ctx.hasAnyMonitoringRecord)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('remote_monitoring.monitoring_letter')), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.item.license_plate_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.item.license_plate_number), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_WatchListsGroup, {
        class: "card-item-small__watch-lists",
        items: _ctx.item.watch_lists
      }, null, 8, ["items"]),
      (_ctx.comment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.formattedComment), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canClose)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_NButton, {
            icon: "close",
            type: "secondary",
            round: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            onAction: _cache[1] || (_cache[1] = () => _ctx.$emit('action', 'close'))
          })
        ]))
      : _createCommentVNode("", true)
  ], 6))
}