export default {
  interactions: 'Взаимодействия',
  merge_all_selected: 'Объединить',
  circle: 'круг',
  interactions_analyzis: 'анализ взаимодействий',
  use_last_event: 'поиск связей по последнему событию эпизода',
  contact_threshold_seconds: 'максимальный промежуток между появлениями людей, чтобы считать их связанными, секунды',
  first_event_date: 'Первое событие кластера',
  event_date: 'Событие кластера',
  alert_text: 'Объединить все выбранные кластеры?',
  agree: 'Согласен',
  cancel: 'Отмена',
  exclude_from_cluster: 'Отделить',
  is_case_participant: 'Участники',
  only_clusters: 'Только кластеры',
  only_participants: 'Только участники'
};
