export default {
  faces_age: 'возраст',
  faces_beard: 'борода',
  faces_beard__beard: 'борода',
  faces_beard__bristle: 'щетина',
  faces_beard__mustache: 'усы',
  faces_beard__none: 'борода отсутствует',
  faces_emotions: 'эмоции',
  faces_emotions__angry: 'злость',
  faces_emotions__disgust: 'отвращение',
  faces_emotions__fear: 'страх',
  faces_emotions__happy: 'счастье',
  faces_emotions__neutral: 'нейтральное выражение',
  faces_emotions__sad: 'грусть',
  faces_emotions__surprise: 'удивление',
  faces_gender: 'пол',
  faces_gender__female: 'женщина',
  faces_gender__male: 'мужчина',

  faces_eyes_attrs: 'Состояние глаз',
  faces_eyes_attrs__opened: 'Глаза открыты',
  faces_eyes_attrs__closed: 'Глаза закрыты',
  faces_eyes_attrs__occluded: 'Глаза перекрыты',

  faces_glasses: 'Очки',
  faces_glasses__eye: 'Медицинские очки',
  faces_glasses__sun: 'Солнечные очки',
  faces_glasses__other: 'Другие очки',
  faces_glasses__none: 'Очки отсутствуют',

  faces_liveness: 'витальность',
  faces_liveness__fake: 'изображение',
  faces_liveness__real: 'истинное лицо',
  faces_look: 'происхождение',
  faces_look__black: 'черный',
  faces_look__white: 'белый',
  faces_look__asian: 'азиатское',
  faces_look__indian: 'индийское',
  faces_medmask: 'медицинская маска',
  faces_medmask__correct: 'маска надета правильно',
  faces_medmask__incorrect: 'маска надета неправильно',
  faces_medmask__none: 'маска отсутствует',
  faces_headpose_yaw: 'поворот',
  faces_headpose_pitch: 'наклон',
  cars_body: 'тип кузова',
  cars_body__cab: 'пикап',
  cars_body__convertible: 'кабриолет',
  cars_body__coupe: 'купе',
  cars_body__crossover: 'кроссовер',
  cars_body__limousine: 'лимузин',
  cars_body__minibus: 'микроавтобус',
  cars_body__minivan: 'минивэн',
  cars_body__sedan: 'седан',
  cars_body__suv: 'внедорожник',
  cars_body__wagon: 'универсал',
  cars_body__coming_soon: 'скоро выйдет',
  cars_body__unknown: 'тип кузова неизвестен',
  cars_color: 'цвет кузова',
  cars_color__unknown: 'цвет кузова неизвестен',
  cars_color__beige: 'бежевый',
  cars_color__black: 'черный',
  cars_color__blue: 'синий',
  cars_color__brown: 'коричневый',
  cars_color__cyan: 'голубой',
  cars_color__gold: 'золотистый',
  cars_color__green: 'зеленый',
  cars_color__grey: 'серый',
  cars_color__orange: 'оранжевый',
  cars_color__pink: 'розовый',
  cars_color__purple: 'пурпурный',
  cars_color__red: 'красный',
  cars_color__silver: 'серебристый',
  cars_color__violet: 'фиолетовый',
  cars_color__white: 'белый',
  cars_color__yellow: 'желтый',
  cars_color__coming_soon: 'скоро выйдет',
  cars_license_plate_country: 'страна',
  cars_license_plate_number: 'регистрационный номер',
  cars_license_plate_number__unknown: 'регистрационный номер неизвестен',
  cars_license_plate_region: 'регион',
  cars_license_plate_region__unknown: 'Регион номера неизвестен',
  cars_license_plate_region__ADH: 'ADH (Абу-Даби)',
  cars_license_plate_region__AJM: 'AJM (Аджман)',
  cars_license_plate_region__DXB: 'DXB (Дубай)',
  cars_license_plate_region__FUJ: 'FUJ (Фуджейра)',
  cars_license_plate_region__RAK: 'RAK (Рас Эль Хайма)',
  cars_license_plate_region__SHJ: 'SHJ (Шарджа)',
  cars_license_plate_region__UAK: 'UAK (Умм-аль-Кайвайн)',
  'cars_license_plate_region__TH-10': 'TH-10 (Бангкок (официально – Крунг Тхеп Маха Накхон))',
  'cars_license_plate_region__TH-11': 'TH-11 (Самутпракан)',
  'cars_license_plate_region__TH-12': 'TH-12 (Нонтхабури)',
  'cars_license_plate_region__TH-13': 'TH-13 (Патхумтхани)',
  'cars_license_plate_region__TH-14': 'TH-14 (Аюттхая)',
  'cars_license_plate_region__TH-15': 'TH-15 (Ангтхонг)',
  'cars_license_plate_region__TH-16': 'TH-16 (Лопбури)',
  'cars_license_plate_region__TH-17': 'TH-17 (Сингбури)',
  'cars_license_plate_region__TH-18': 'TH-18 (Чайнат)',
  'cars_license_plate_region__TH-19': 'TH-19 (Сарабури)',
  'cars_license_plate_region__TH-20': 'TH-20 (Чонбури)',
  'cars_license_plate_region__TH-21': 'TH-21 (Районг)',
  'cars_license_plate_region__TH-22': 'TH-22 (Чантхабури)',
  'cars_license_plate_region__TH-23': 'TH-23 (Трат)',
  'cars_license_plate_region__TH-24': 'TH-24 (Чаченгсау)',
  'cars_license_plate_region__TH-25': 'TH-25 (Прачинбури)',
  'cars_license_plate_region__TH-26': 'TH-26 (Накхоннайок)',
  'cars_license_plate_region__TH-27': 'TH-27 (Сакэу)',
  'cars_license_plate_region__TH-30': 'TH-30 (Накхонратчасима)',
  'cars_license_plate_region__TH-31': 'TH-31 (Бурирам)',
  'cars_license_plate_region__TH-32': 'TH-32 (Сурин)',
  'cars_license_plate_region__TH-33': 'TH-33 (Сисакет)',
  'cars_license_plate_region__TH-34': 'TH-34 (Убонратчатхани)',
  'cars_license_plate_region__TH-35': 'TH-35 (Ясотхон)',
  'cars_license_plate_region__TH-36': 'TH-36 (Чайяпхум)',
  'cars_license_plate_region__TH-37': 'TH-37 (Амнатчарен)',
  'cars_license_plate_region__TH-38': 'TH-38 (Бынгкан)',
  'cars_license_plate_region__TH-39': 'TH-39 (Нонгбуалампху)',
  'cars_license_plate_region__TH-40': 'TH-40 (Кхонкэн)',
  'cars_license_plate_region__TH-41': 'TH-41 (Удонтхани)',
  'cars_license_plate_region__TH-42': 'TH-42 (Лей)',
  'cars_license_plate_region__TH-43': 'TH-43 (Нонгкхай)',
  'cars_license_plate_region__TH-44': 'TH-44 (Махасаракхам)',
  'cars_license_plate_region__TH-45': 'TH-45 (Ройет)',
  'cars_license_plate_region__TH-46': 'TH-46 (Каласин)',
  'cars_license_plate_region__TH-47': 'TH-47 (Саконнакхон)',
  'cars_license_plate_region__TH-48': 'TH-48 (Накхонпханом)',
  'cars_license_plate_region__TH-49': 'TH-49 (Мукдахан)',
  'cars_license_plate_region__TH-50': 'TH-50 (Чиангмай)',
  'cars_license_plate_region__TH-51': 'TH-51 (Лампхун)',
  'cars_license_plate_region__TH-52': 'TH-52 (Лампанг)',
  'cars_license_plate_region__TH-53': 'TH-53 (Уттарадит)',
  'cars_license_plate_region__TH-54': 'TH-54 (Пхрэ)',
  'cars_license_plate_region__TH-55': 'TH-55 (Нан)',
  'cars_license_plate_region__TH-56': 'TH-56 (Пхаяу)',
  'cars_license_plate_region__TH-57': 'TH-57 (Чианграй)',
  'cars_license_plate_region__TH-58': 'TH-58 (Мэхонгсон)',
  'cars_license_plate_region__TH-60': 'TH-60 (Накхонсаван)',
  'cars_license_plate_region__TH-61': 'TH-61 (Утхайтхани)',
  'cars_license_plate_region__TH-62': 'TH-62 (Кампхэнгпхет)',
  'cars_license_plate_region__TH-63': 'TH-63 (Так)',
  'cars_license_plate_region__TH-64': 'TH-64 (Сукхотхай)',
  'cars_license_plate_region__TH-65': 'TH-65 (Пхитсанулок)',
  'cars_license_plate_region__TH-66': 'TH-66 (Пхичит)',
  'cars_license_plate_region__TH-67': 'TH-67 (Пхетчабун)',
  'cars_license_plate_region__TH-70': 'TH-70 (Ратбури)',
  'cars_license_plate_region__TH-71': 'TH-71 (Канчанабури)',
  'cars_license_plate_region__TH-72': 'TH-72 (Супханбури)',
  'cars_license_plate_region__TH-73': 'TH-73 (Накхонпатхом)',
  'cars_license_plate_region__TH-74': 'TH-74 (Самутсакхон)',
  'cars_license_plate_region__TH-75': 'TH-75 (Самутсонгкхрам)',
  'cars_license_plate_region__TH-76': 'TH-76 (Пхетбури)',
  'cars_license_plate_region__TH-77': 'TH-77 (Прачуапкхирикхан)',
  'cars_license_plate_region__TH-80': 'TH-80 (Накхонситхаммарат)',
  'cars_license_plate_region__TH-81': 'TH-81 (Краби)',
  'cars_license_plate_region__TH-82': 'TH-82 (Пхангнга)',
  'cars_license_plate_region__TH-83': 'TH-83 (Пхукет)',
  'cars_license_plate_region__TH-84': 'TH-84 (Сураттхани)',
  'cars_license_plate_region__TH-85': 'TH-85 (Ранонг)',
  'cars_license_plate_region__TH-86': 'TH-86 (Чумпхон)',
  'cars_license_plate_region__TH-90': 'TH-90 (Сонгкхла)',
  'cars_license_plate_region__TH-91': 'TH-91 (Сатун)',
  'cars_license_plate_region__TH-92': 'TH-92 (Транг)',
  'cars_license_plate_region__TH-93': 'TH-93 (Пхаттхалунг)',
  'cars_license_plate_region__TH-94': 'TH-94 (Паттани)',
  'cars_license_plate_region__TH-95': 'TH-95 (Яла)',
  'cars_license_plate_region__TH-96': 'TH-96 (Наратхиват)',
  cars_make: 'марка',
  cars_make__unknown: 'марка неизвестна',
  cars_model: 'модель',
  cars_model__unknown: 'модель неизвестна',
  cars_special_vehicle_type: 'спецтранспорт',
  cars_special_vehicle_type__unknown: 'спецназначение неизвестно',
  cars_special_vehicle_type__police: 'Полиция (вкл. ДПС)',
  cars_special_vehicle_type__ambulance: 'Скорая помощь (вкл. реанимобиль)',
  cars_special_vehicle_type__rescue_service: 'Автомобили МЧС и пожарные машины',
  cars_special_vehicle_type__taxi: 'Такси (вкл. грузотакси)',
  cars_special_vehicle_type__not_special: 'Не спецтранспорт',
  cars_special_vehicle_type__military: 'Военная техника (вкл. Росгвардию)',
  cars_special_vehicle_type__road_service: 'Коммунальная, строительная техника (вкл. ЦОДД)',
  cars_special_vehicle_type__other_special: 'Остальной спецтранспорт',
  cars_special_vehicle_type__route_transport: 'Маршрутный транспорт',
  cars_special_vehicle_type__car_sharing: 'Каршеринг',
  cars_special_vehicle_type__gas_service: 'Газоспасательные и аварийно-спасательные службы',
  cars_special_vehicle_type__cash_in_transit: 'Инкассация',
  cars_category: 'Категория ТС',
  cars_category__A: 'Мотоцикл, скутер, квадроцикл',
  cars_category__B: 'Легковой автомобиль',
  cars_category__BE: 'Легковой автомобиль с прицепом',
  cars_category__C: 'Грузовик',
  cars_category__CE: 'Грузовик с прицепом',
  cars_category__D: 'Автобус',
  cars_category__DE: 'Сочленённый автобус',
  cars_category__other: 'ТС других категорий',
  cars_category__unknown: 'Категория ТС не распознана',
  cars_weight_type: 'Вес и размер ТС',
  cars_weight_type__unknown: 'Вес и размер ТС неизвестны',
  cars_weight_type__B_light: 'Легковые ТС до 3.5 т.',
  cars_weight_type__B_heavy: 'Грузовики до 3.5 т.',
  cars_weight_type__C_light: 'Грузовики до 3.5-12 т.',
  cars_weight_type__C_heavy: 'Тяжелые грузовики свыше 12 т.',
  cars_weight_type__D_light: 'Одиночные автобусы',
  cars_weight_type__D_long: 'Сочленённые автобусы',
  cars_weight_type__other: 'ТС другой весовой категории',
  cars_orientation: 'Ракурс ТС',
  cars_orientation__unknown: 'Ракурс ТС неизвестен',
  cars_orientation__front: 'Вид спереди',
  cars_orientation__back: 'Вид сзади',
  cars_orientation__side: 'Вид сбоку',
  bodies_bottom_color: 'цвет низа одежды',
  bodies_bottom_color__beige: 'бежевый',
  bodies_bottom_color__black: 'черный',
  bodies_bottom_color__blue: 'синий',
  bodies_bottom_color__brown: 'коричневый',
  bodies_bottom_color__green: 'зеленый',
  bodies_bottom_color__grey: 'серый',
  bodies_bottom_color__lightblue: 'голубой',
  bodies_bottom_color__orange: 'оранжевый',
  bodies_bottom_color__pink: 'розовый',
  bodies_bottom_color__purple: 'пурпурный',
  bodies_bottom_color__red: 'красный',
  bodies_bottom_color__white: 'белый',
  bodies_bottom_color__yellow: 'желтый',
  bodies_bottom_color__violet: 'фиолетовый',
  bodies_top_color: 'цвет верха одежды',
  bodies_top_color__beige: 'бежевый',
  bodies_top_color__black: 'черный',
  bodies_top_color__blue: 'синий',
  bodies_top_color__brown: 'коричневый',
  bodies_top_color__green: 'зеленый',
  bodies_top_color__grey: 'серый',
  bodies_top_color__lightblue: 'голубой',
  bodies_top_color__orange: 'оранжевый',
  bodies_top_color__pink: 'розовый',
  bodies_top_color__purple: 'пурпурный',
  bodies_top_color__red: 'красный',
  bodies_top_color__white: 'белый',
  bodies_top_color__yellow: 'желтый',
  bodies_top_color__violet: 'фиолетовый',
  bodies_detailed_upper_clothes: 'тип верха одежды',
  bodies_detailed_upper_clothes__jacket: 'куртка',
  bodies_detailed_upper_clothes__coat: 'пальто',
  bodies_detailed_upper_clothes__sleeveless: 'безрукавка',
  bodies_detailed_upper_clothes__sweatshirt: 'толстовка',
  'bodies_detailed_upper_clothes__t-shirt': 'футболка',
  bodies_detailed_upper_clothes__shirt: 'рубашка',
  bodies_detailed_upper_clothes__dress: 'платье',
  bodies_headwear: 'головной убор',
  bodies_headwear__hat: 'шапка, шляпа, кепка',
  bodies_headwear__none: 'отсутствует',
  bodies_headwear__hood: 'капюшон, платок',
  bodies_lower_clothes: 'низ одежды',
  bodies_lower_clothes__pants: 'брюки',
  bodies_lower_clothes__obscured: 'неопределённый',
  bodies_lower_clothes__skirt: 'юбка',
  bodies_lower_clothes__shorts: 'шорты',
  bodies_upper_clothes: 'верх одежды',
  bodies_upper_clothes__long_sleeves: 'длинные рукава',
  bodies_upper_clothes__short_sleeves: 'короткие рукава',
  bodies_upper_clothes__without_sleeves: 'без рукавов',
  bodies_vest_type: 'жилет',
  'bodies_vest_type__green/yellow': 'зеленый/желтый',
  bodies_vest_type__orange: 'оранжевый',
  bodies_vest_type__none: 'отсутствует',
  bodies_vest_type__not_visible: 'не видно',
  bodies_vest_type_score: 'Жилет. Точность распознавания',
  bodies_helmet_type: 'каска',
  bodies_helmet_type__white: 'белая',
  'bodies_helmet_type__red/orange': 'красная/оранжевая',
  bodies_helmet_type__other: 'другая',
  bodies_helmet_type__none: 'отсутствует',
  bodies_helmet_type__not_visible: 'не видно',
  bodies_helmet_type_score: 'Каска. Точность распознавания',
  bodies_bag_back: 'сумка на спине',
  bodies_bag_back__back: 'Есть',
  bodies_bag_back__none: 'Отсутствует',
  bodies_bag_hand: 'сумка в руке',
  bodies_bag_hand__hand: 'Есть',
  bodies_bag_hand__none: 'Отсутствует',
  bodies_bag_ground: 'сумка на полу',
  bodies_bag_ground__ground: 'Есть',
  bodies_bag_ground__none: 'Отсутствует',
  bodies_gender: 'пол по силуэту',
  bodies_gender__male: 'мужчина',
  bodies_gender__female: 'женщина',
  bodies_age_group: 'возраст по силуэту',
  'bodies_age_group__0-16': '0-16 лет',
  'bodies_age_group__17-35': '17-35 лет',
  'bodies_age_group__36-50': '36-50 лет',
  'bodies_age_group__50+': '50+ лет',

  bodies_handface_smoking: 'курение',
  bodies_handface_smoking__none: 'Отсутствует',
  bodies_handface_smoking__smoking: 'Есть',

  bodies_handface_phone_call: 'разговор по телефону',
  bodies_handface_phone_call__none: 'Отсутствует',
  bodies_handface_phone_call__phone_call: 'Есть',

  bodies_handface_phone_use: 'использование телефона',
  bodies_handface_phone_use__none: 'Отсутствует',
  bodies_handface_phone_use__phone_use: 'Есть',

  bodies_fall: 'падение',
  bodies_fall__fall: 'Есть',
  bodies_fall__none: 'Отсутствует',

  cars_license_plate_country__unknown: 'Страна номера неизвестна',
  cars_license_plate_country__RU: 'RUS (Россия)',
  cars_license_plate_country__AE: 'UAE (ОАЭ)',
  cars_license_plate_country__KZ: 'KZ (Казахстан)',
  cars_license_plate_country__GE: 'GE (Грузия)',
  cars_license_plate_country__SA: 'KSA (Саудовская Аравия)',
  cars_license_plate_country__VN: 'VN (Вьетнам)',
  cars_license_plate_country__BY: 'BY (Беларусь)',
  cars_license_plate_country__UA: 'UA (Украина)',
  cars_license_plate_country__AM: 'AM (Армения)',
  cars_license_plate_country__KG: 'KG (Кыргызстан)',
  cars_license_plate_country__UZ: 'UZ (Узбекистан)',
  cars_license_plate_country__BR: 'BR (Бразилия)',
  cars_license_plate_country__IN: 'IND (Индия)',
  cars_license_plate_country__AZ: 'AZ (Азербайджан)',
  cars_license_plate_country__EST: 'EST (Эстония)',
  cars_license_plate_country__FIN: 'FIN (Финляндия)',
  cars_license_plate_country__LT: 'LT (Литва)',
  cars_license_plate_country__LV: 'LV (Латвия)',
  cars_license_plate_country__MD: 'MD (Молдавия)',
  cars_license_plate_country__MX: 'MEX (Мексика)',
  cars_license_plate_country__AR: 'RA (Аргентина)',
  cars_license_plate_country__SRB: 'SRB (Сербия)',
  cars_license_plate_country__TJ: 'TJ (Таджикистан)',
  cars_license_plate_country__TM: 'TM (Туркменистан)',
  cars_license_plate_country__Country_1: 'неизвестна',
  cars_license_plate_country__Country_2: 'неизвестна',
  cars_license_plate_country__Country_3: 'неизвестна',
  cars_license_plate_country__Country_4: 'неизвестна',
  cars_license_plate_country__Country_5: 'неизвестна',
  cars_license_plate_country__TH: 'T (Таиланд)',
  cars_license_plate_country__CZ: 'CZ (Чехия)',
  cars_license_plate_country__PK: 'PK (Пакистан)',
  cars_license_plate_country__CN: 'CN (Китай)',
  cars_license_plate_country__EG: 'ET (Египет)',
  cars_license_plate_country__JP: 'J (Япония)',
  cars_license_plate_country__MN: 'MGL (Монголия)',
  cars_license_plate_country__MA: 'MA (Марокко)',
  cars_license_plate_country__PS: 'PS (Палестина)',
  cars_license_plate_country__SG: 'SGP (Сингапур)',
  cars_license_plate_country__KR: 'ROK (Южная Корея)',
  cars_license_plate_country__BH: 'BRN (Бахрейн)',
  cars_license_plate_country__CO: 'CO (Колумбия)',
  cars_license_plate_country__PE: 'PE (Перу)',
  cars_license_plate_country__QA: 'Q (Катар)',
  cars_license_plate_country__TN: 'TN (Тунис)',
  cars_license_plate_country__TR: 'TR (Турция)',
  cars_license_plate_number_color: 'цвет номера',
  cars_license_plate_number_color__white: 'Белый номер',
  cars_license_plate_number_color__yellow: 'Желтый номер',
  cars_license_plate_number_color__blue: 'Голубой номер',
  cars_license_plate_number_color__green: 'Зеленый номер',
  cars_license_plate_number_color__grey: 'Серый номер',
  cars_license_plate_number_color__black: 'Черный номер',
  cars_license_plate_number_color__unknown: 'Цвет номера неизвестен'
};
