import { pick } from 'lodash';
import { differenceOf } from '@/definitions/common/utils';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { viewModelRepository } from '@/api/common';

// import DevToolsScript from '@/pages/devtools/DevToolsScript';
// type DevToolsScriptContext = ReturnType<typeof DevToolsScript.getContext>;
// example: return async function (this: DevToolsScriptContext, item: any) {}

export function buildMethodForCreate(moduleName: string) {
  return async function (item: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const model: ItemViewModel<any> = viewModelRepository[`get${moduleName}ItemViewModel`]();
    model.setItemsState(item);
    await model.save();
  };
}

export function buildMethodForDelete(moduleName: string) {
  return async function (id: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const model: ItemViewModel<any> = viewModelRepository[`get${moduleName}ItemViewModel`]();
    await model.delete(id);
  };
}

export function buildMethodForFindAll(moduleName: string) {
  return async function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const model: ListViewModel<any> = viewModelRepository[`get${moduleName}ListViewModel`]();
    await model.get();
    return model.items;
  };
}

export function buildMethodForGetObjectId(moduleName: string) {
  return async function (query: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const model: ListViewModel<any> = viewModelRepository[`get${moduleName}ListViewModel`]();
    // @todo: Add support for filters by name on the backend
    function findItemByQuery(item: any) {
      const diffObj = differenceOf(pick(item, Object.keys(query)), query);
      const isEqual = Object.keys(diffObj).length === 0;
      return isEqual;
    }
    model.filter.current = { ...model.filter.current, ...query };
    model.filter.current.limit = 1e3;
    await model.get();
    if (model.items.length) {
      const resultItem = model.items.find(findItemByQuery);
      return resultItem?.id;
    }
    return null;
  };
}
