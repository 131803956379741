import { Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail';
import { configModule } from '@/store/config';
import { adaptItem } from '@/pages/annex/alarms/AnnexAlarmAdapter';
import { annexAlarmActionHandler } from '@/pages/annex/alarms/AnnexAlarmActionHandler';
import { CommonPageState } from '@/components/common/page/CommonPageState';
import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import MatchedCardHelper from '@/common/MatchedCardHelper';

export default class AnnexAlarmItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: AnnexAlarm;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly opened!: boolean;

  @Prop({ type: Object, required: true })
  readonly pageState!: CommonPageState;

  matchedCardHelper = new MatchedCardHelper();

  get adaptedItem() {
    return adaptItem(this.item);
  }

  get formattedDate(): string {
    return this.$filters.formatDateTime(this.item.declared_at);
  }

  get color() {
    if (this.selected) {
      return 'accent';
    }
    if (this.adaptedItem.acknowledge === 'none') {
      return 'danger';
    }
    if (this.adaptedItem.acknowledge === 'auto_acknowledged') {
      return 'warning';
    }
    return '';
  }

  get classes() {
    return {
      'item-color-scheme__selected': this.selected,
      'item-color-scheme__opened': this.opened,
      'item-color-scheme__accent': this.color === 'accent',
      'item-color-scheme__warning': this.color === 'warning',
      'item-color-scheme__danger': this.color === 'danger'
    };
  }

  get declaredDateFormated(): string {
    return this.$filters.formatDateTime(this.item.declared_at);
  }

  get thumbnailActions() {
    const result = [
      { icon: 'check', name: EventTypes.Select, position: ThumbnailPositions.TopLeft, selected: this.selected },
      { icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];

    if (configModule.features.vms_enabled && this.adaptedItem.camera?.name) {
      result.push({ icon: 'play', name: EventDetails.ShowPlayer, position: ThumbnailPositions.BottomLeft });
    }
    return result;
  }

  get confidence() {
    return this.item.extra?.match?.confidence;
  }

  async mounted() {
    this.matchedCardHelper.type = this.item.extra?.object_type;
    this.matchedCardHelper.id = this.item.extra?.match?.card_id;
  }

  get cardId() {
    return this.item.extra?.match?.card_id;
  }
  @Watch('cardId')
  async changeCardHandler(v: number | null) {
    this.matchedCardHelper.type = this.item.extra?.object_type;
    this.matchedCardHelper.id = v;
  }

  thumbnailActionHandler(action: string) {
    if (action === 'click') {
      annexAlarmActionHandler(ItemsActionNames.ShowFullScreen, this.item);
      return;
    }
    annexAlarmActionHandler(action, this.item);
  }

  actionHandler(action: string) {
    annexAlarmActionHandler(action, this.item);
  }
}
