
import { Options, Vue } from 'vue-class-component';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { WatchList } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { dataModule } from '@/store/data';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { columns } from './watch-lists-columns';

const AclModelName = 'watchlist';
const AclItemPropertyName = 'watch_list_permissions';

const ActionPathNames = {
  Name: 'name',
  Active: 'active'
};

function filterItemsByName(items: WatchList[], query: string) {
  const re = new RegExp(query, 'i');
  return items.filter((item) => re.test(item.name));
}

function sortByField(fieldName: any) {
  let [field, direction] = fieldName.charAt(0) === '-' ? [fieldName.slice(1), -1] : [fieldName, 1];
  return function (a: any, b: any) {
    return a[field].localeCompare(b[field]) * direction;
  };
}

const SidebarType = MultisidebarItemTypes.WatchLists;

@Options({
  components: {
    NForm,
    NLoadingCircle,
    NTable,
    NTabs,
    SettingsBar,
    SettingsPageLayout
  }
})
export default class WatchListsPage extends Vue {
  sortField = '';
  searchQuery = '';

  get module() {
    dataModule.watchListsModule.aclModelName = AclModelName;
    return dataModule.watchListsModule;
  }

  get items() {
    let items = this.searchQuery.length > 0 ? filterItemsByName(this.module.items, this.searchQuery) : this.module.items;
    this.sortField && items.sort(sortByField(this.sortField));
    return items;
  }

  get columns() {
    return columns;
  }

  get selectedItemsIds() {
    return this.sidebarModule.getItemsByType(SidebarType).map((v) => v.model.item.id);
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get modelAcl() {
    return aclModule.getModelAcl(this.module);
  }

  get maxAcl() {
    return { view: this.modelAcl.view, update: this.modelAcl.update, delete: this.modelAcl.delete };
  }

  get itemsAcl() {
    return this.items.map((item) => ({
      itemId: item.id,
      acl: aclModule.getItemAclByUserProperty(item.id, AclItemPropertyName, this.maxAcl)
    }));
  }

  get disabledItemIds() {
    return this.itemsAcl.filter(({ acl }) => !acl.update).map(({ itemId }) => itemId);
  }

  mounted() {
    this.module.get();
    autoUpdateHelper.addListInstance(this.module);
  }

  handleTableAction(payload: ITableCellChangePayload) {
    if (payload.type === 'change') {
      switch (payload.path) {
        case ActionPathNames.Active:
          actionHandler.run(ItemsActionNames.UpdateActiveField, { type: SidebarType, rawItem: payload.row });
          break;
        case ActionPathNames.Name:
          actionHandler.run(ItemsActionNames.ShowItem, { type: SidebarType, rawItem: payload.row });
      }
    }
  }

  handleTableSort(columnName: string) {
    if (columnName === 'name') {
      this.sortField = this.sortField === columnName ? `-${columnName}` : columnName;
    }
  }

  handleSelectChanges(changesSelectionIds: number[]) {
    this.module.items
      .filter((v) => changesSelectionIds.includes(v.id))
      .forEach((v) => {
        actionHandler.run(ItemsActionNames.ToggleSelectItem, { type: SidebarType, rawItem: v });
      });
  }

  handleCreate() {
    this.sidebarModule.addNewItemByType(SidebarType);
  }
}
