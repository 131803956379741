import { watch } from 'vue';
import { ExceptionsModule } from '@/store/application/exceptions';
import { NotificationsModule } from '@/store/application/notifications';
import { languageModule } from '@/store/languages';
import { websocketModule } from '@/store/ws/websocket.module';

class LicenseNotificationModule {
  private shownMessages: string[] = [];

  constructor(public exceptionsModule: ExceptionsModule) {}

  public tryNotify(message: string) {
    if (!this.shownMessages.includes(message)) {
      this.shownMessages.push(message);
      this.exceptionsModule.notifyThrownException(new Error(message), { duration: 0, traceDisabled: true });
    }
  }
}

const exceptionModule = ExceptionsModule.create({
  language: languageModule,
  notifications: NotificationsModule.create()
});

export const licenseNotificationModule = new LicenseNotificationModule(exceptionModule);

export function initLicenseNotificationModule() {
  watch(() => websocketModule.licenseMessage, licenseNotificationModule.tryNotify.bind(licenseNotificationModule));
}
