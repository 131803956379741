import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, { placement: "bottom-end" }, {
    toggle: _withCtx(({ toggle }) => [
      _createVNode(_component_NButton, {
        type: "text",
        onClick: toggle,
        class: "ring",
        size: "normal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NIcon, {
            name: "ring",
            class: _normalizeClass({ ring__mute: _ctx.isPlay }),
            width: "15",
            height: "15.36"
          }, null, 8, ["class"]),
          (_ctx.isPlay)
            ? (_openBlock(), _createBlock(_component_NIcon, {
                key: 0,
                name: "ring-unmute",
                class: "ring__unmute",
                width: "29.31",
                height: "11.6"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NDropdownItem, {
        icon: _ctx.muteIcon,
        onClick: _ctx.toggleMute,
        disabled: _ctx.isDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.muteLabel), 1)
        ]),
        _: 1
      }, 8, ["icon", "onClick", "disabled"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, ({ label, name, icon }) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          key: name,
          icon: icon,
          onClick: () => _ctx.actionHandler(name)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(label), 1)
          ]),
          _: 2
        }, 1032, ["icon", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}