
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { aclModule } from '@/store/acl';
import { AclPrefixNames, AclPuppeteerModelNames } from '@/store/acl/types';
import { ModelAclResult } from '@/store/acl/types';
import NButton from '@/uikit/buttons/NButton.vue';
import { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';

@Options({
  name: 'ExternalSearchRequestActions',
  components: { NButton }
})
export default class ExternalSearchRequestActions extends Vue {
  @Prop({ type: Object })
  item!: EnrichedSearchRequest;

  get modelAcl(): ModelAclResult {
    return aclModule.getModelAclByName(AclPuppeteerModelNames.ExternalSearch, AclPrefixNames.Puppeteer);
  }
}
