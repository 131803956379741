/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CaseClustersFilter = {
            /**
            * Select case clusters where feature `beard` takes one of these values
            */
        beard?: Array<'beard' | 'none'>;
            /**
            * Select case clusters related to these cases
            */
        case_in?: Array<number | null>;

        case_not_in?: Array<number | null>;

            /**
            * Select objects with `created_date` **greater** than this value
            */
        created_date_gt?: string;
            /**
            * Select objects with `created_date` **greater than or equal** to this value
            */
        created_date_gte?: string;
            /**
            * Select objects with `created_date` in last N days
            */
        created_date_last_n_days?: number;
            /**
            * Select objects with `created_date` **less** than this value
            */
        created_date_lt?: string;
            /**
            * Select objects with `created_date` **less than or equal** to this value
            */
        created_date_lte?: string;
            /**
            * Select objects with `created_date` in last Nth week (including Sunday and Saturday)
            */
        created_date_nth_full_week?: number;
            /**
            * Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
            */
        created_date_nth_work_week?: number;
            /**
            * Select case clusters where feature `emotions` takes one of these values
            */
        emotions?: Array<'angry' | 'disgust' | 'fear' | 'happy' | 'neutral' | 'sad' | 'surprise'>;
            /**
            * Select case clusters where case events count is **greater or equal than or equal** to this value
            */
        events_count_gte?: number;
            /**
            * Select case clusters where case events count is **less or equal than or equal** to this value
            */
        events_count_lte?: number;
            /**
            * Select case clusters where average case event quality is **greater or equal than or equal** to this value
            */
        events_quality_gte?: number;
            /**
            * Select case clusters where average case event quality is **less or equal than or equal** to this value
            */
        events_quality_lte?: number;
            /**
            * Select case clusters where feature `gender` takes one of these values
            */
        gender?: Array<'female' | 'male'>;
            /**
            * Select case clusters where feature `glasses` takes one of these values
            */
        glasses?: Array<'eye' | 'none' | 'sun'>;
            /**
            * Select case clusters with provided ids
            */
        id_in?: Array<number>;
        case_photo_in?: Array<number>;
        video_archive_in?: Array<number>;
            /**
            * Number of results to return per page.
            */
        limit?: string;
            /**
            * Select case clusters where feature `liveness` takes one of these values
            */
        liveness?: Array<'fake' | 'real'>;
            /**
            * Select case clusters where feature `look` takes one of these values
            */
        look?: Array<string>;
            /**
            * Select case clusters that contain object similar to: (`detection:<detection id>`, `{face, body or car}event:<event id>`, `{face, body or car}object:<object id>`, `{face, body or car}cluster:<cluster id>` or `caseparticipant:<caseparticipant id>`)
            */
        looks_like?: string;
            /**
            * Select case clusters where feature `medmask` takes one of these values
            */
        medmask?: Array<'correct' | 'incorrect' | 'none'>;
            /**
            * Select case clusters where the SQL condition `name ILIKE name_contains` is true
            */
        name_contains?: string;
            /**
            * Какое поле использовать для сортировки результатов. Available fields: `id`, `created_date`, `looks_like_confidence`
            */
        ordering?: string;
            /**
            * Pagination cursor value.
            */
        page?: string;
            /**
            * Select case clusters relates to this human card
            */
        related_human_card?: number;
            /**
            * Select case clusters where `type` takes one of these values
            */
        role_in?: Array<'irrelevant' | 'suspect' | 'victim' | 'witness' | null>;
            /**
            * Similarity threshold for `looks_like`
            */
        threshold?: number;

        is_case_participant?: boolean;
}
export const EmptyCaseClustersFilter: CaseClustersFilter = {
        beard: [],
        case_in: [],
        case_not_in: [],
        created_date_gt: undefined,
        created_date_gte: undefined,
        created_date_last_n_days: undefined,
        created_date_lt: undefined,
        created_date_lte: undefined,
        created_date_nth_full_week: undefined,
        created_date_nth_work_week: undefined,
        emotions: [],
        events_count_gte: undefined,
        events_count_lte: undefined,
        events_quality_gte: undefined,
        events_quality_lte: undefined,
        gender: [],
        glasses: [],
        id_in: [],
        limit: '20',
        liveness: [],
        look: [],
        looks_like: undefined,
        medmask: [],
        name_contains: undefined,
        ordering: undefined,
        page: undefined,
        related_human_card: undefined,
        role_in: [],
        threshold: undefined,
        is_case_participant: undefined
}
