import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map-zones" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFilterManager = _resolveComponent("NFilterManager")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NFilterManager, _mergeProps(_ctx.labels, {
      items: _ctx.items,
      selected: _ctx.selectedId,
      disableButton: !_ctx.lastSelectedZone,
      onAdd: _ctx.addHandler,
      onSelect: _ctx.selectHandler,
      onDelete: _ctx.deleteHandler,
      onUndo: _ctx.undoHandler,
      onHide: _ctx.hideHandler,
      onClick: _ctx.openLastZone
    }), {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.lastSelectedZone?.name || _ctx.$t('common.zones')), 1)
      ]),
      _: 1
    }, 16, ["items", "selected", "disableButton", "onAdd", "onSelect", "onDelete", "onUndo", "onHide", "onClick"])
  ]))
}