import intersection from 'lodash/intersection';
import { ObjectsType, ObjectsTypesMap} from '@/store/application/data.assets';
import { CommonCluster, CommonClusterFilter } from '@/store/clusters/types';
import { getSatisfyClusterFeatures } from '@/store/events/features.filter';
import { isoStringToDate } from '@/common/filters';

export function satisfyClusterFilter(cluster: CommonCluster, filter: CommonClusterFilter, objects: ObjectsType) {
  let satisfy = true;

  if (satisfy && filter.id_in) {
    const id = String(cluster.id);
    satisfy = Array.isArray(filter.id_in) ? (filter.id_in.length ? (filter.id_in as any).includes(id) : true) : String(filter.id_in) === id;
  }

  if (satisfy && filter.card) {
    satisfy = filter.card === cluster.card;
  }

  if (satisfy && filter.has_card) {
    satisfy = (filter.has_card as unknown as string) === 'True' ? !!cluster.card : !cluster.card;
  }

  if (satisfy && filter.matched_lists?.length) {
    satisfy = intersection(filter.matched_lists, cluster.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups?.length) {
    satisfy = intersection(filter.camera_groups, cluster.camera_groups).length > 0;
  }

  if (satisfy && filter.cameras?.length) {
    satisfy = intersection(filter.cameras, cluster.cameras).length > 0;
  }

  if (satisfy && filter.created_date_lte) {
    let filterGTEDate = isoStringToDate(filter.created_date_lte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_gte) {
    let filterGTEDate = isoStringToDate(filter.created_date_gte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.first_event_date_lte) {
    let filterLteDate = isoStringToDate(filter.first_event_date_lte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.first_event_date_gte) {
    let filterGTEDate = isoStringToDate(filter.first_event_date_gte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.event_date_lte) {
    let filterLteDate = isoStringToDate(filter.event_date_lte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.event_date_gte) {
    let filterGTEDate = isoStringToDate(filter.event_date_gte),
      clusterDate = isoStringToDate(cluster.created_date);

    satisfy = clusterDate.getTime() >= filterGTEDate.getTime();
  }

  satisfy = satisfy && getSatisfyClusterFeatures(cluster, filter, ObjectsTypesMap.Faces);
  return satisfy;
}
