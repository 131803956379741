
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';

export enum DirectionEnum {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

@Options({
  name: 'Acknowledge',
  components: { NButton }
})
export default class Acknowledge extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly modelValue!: boolean;

  @Prop({ type: String, default: DirectionEnum.Horizontal })
  direction!: DirectionEnum;

  @Prop({ type: Boolean, default: false })
  readonly onlyToTrue!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  get text() {
    return this.isVertical ? '' : this.$t(`common.${this.modelValue ? 'acknowledged' : 'acknowledge'}`, 'f');
  }

  get icon() {
    return this.modelValue ? 'done-all' : this.isVertical ? 'unknown' : '';
  }

  get isVertical() {
    return this.direction === DirectionEnum.Vertical;
  }

  get isDisabled() {
    return this.disabled || (this.onlyToTrue && this.modelValue);
  }

  get classes(): Record<string, boolean> {
    return {
      acknowledge: true,
      acknowledge__vertical: this.direction === DirectionEnum.Vertical,
      acknowledge__horizontal: this.direction === DirectionEnum.Horizontal
    };
  }

  toggleAcknowledge() {
    const value = !this.modelValue;
    if (!value && this.onlyToTrue) {
      return;
    }
    this.$emit('update:modelValue', value);
    this.$emit('action', 'acknowledge', { value });
  }
}
