import { BodyEvent, CarEvent, FaceEvent, Track } from '@/api';
import { dataServiceRepository } from '@/api/common';
import { convertSomeBoxToBox } from '@/uikit/bbox/helpers';
import { BboxConfig, SomeBox } from '@/uikit/bbox/types';
import { PhotoViewerItem, PhotoViewerSourceItem } from '@/components/photo-viewer/types';

function getFullframe(item: PhotoViewerSourceItem) {
  const fullframe = item.fullframe || item.source_photo;
  return fullframe;
/*   const isValidFullframe = fullframe && (typeof fullframe === 'string' || (typeof fullframe === 'object' && fullframe instanceof Blob));
  if (isValidFullframe) {
  }
  throw Error('Fullframe not found for item id: ' + item.id); */
}

function getBboxes(item: PhotoViewerSourceItem) {
  if (item.bboxes) return convertToBBoxConfigs(item.bboxes);
  if (item.bbox) return convertToBBoxConfigs([item.bbox]);
  try {
    return convertToBBoxConfigs([item] as any);
  } catch (e) {
    //
  }
  return [];
}

export function convertToBBoxConfigs(boxes: SomeBox[]): BboxConfig[] {
  return boxes
    .filter((v) => !!v)
    .map((v) => {
      return {
        box: convertSomeBoxToBox(v),
        meta: {}
      };
    });
}

function determineTrackEventId(item: PhotoViewerSourceItem) {
  if (item.lines?.length) {
    return item.lines[0].event_id;
  } else if (item.best_face_event || item.best_body_event || item.best_car_event) {
    const event = item.best_face_event || item.best_body_event || item.best_car_event;
    return isEvent(event) ? event.id : event;
  } else if (item.line || item.detector_params?.track) {
    return item.id;
  }
  return null;
}

function getHasLine(item: PhotoViewerSourceItem) {
  return !!(item.lines?.length || item.line);
}

type GeneralEvent = FaceEvent | BodyEvent | CarEvent;
function isEvent(value: GeneralEvent | string): value is GeneralEvent {
  return (value as GeneralEvent).id !== undefined;
}

function getLineAndTrackSimple(item: PhotoViewerSourceItem) {
  const result: any = {};
  if (item.linePoints && Array.isArray(item.linePoints)) {
    result.line = item.linePoints;
  }
  if (item.trackPoints && Array.isArray(item.trackPoints)) {
    result.track = item.trackPoints;
  }
  return result;
}

function getLineAndTrackLoaders(item: PhotoViewerSourceItem) {
  const trackEventId = determineTrackEventId(item);
  const hasLine = getHasLine(item);

  if (trackEventId) {
    let track: Track | null = null;
    const resolveTrack = async () => {
      if (!track) {
        const tracks = await dataServiceRepository.TracksService.getList({ event_id: trackEventId });
        if (tracks.results?.[0]) {
          track = tracks.results[0];
        }
      }
      return track;
    };

    const loadTrack = async () => (await resolveTrack())?.track_points;
    const loadLine = hasLine ? async () => (await resolveTrack())?.line_points : null;

    return { line: loadLine, track: loadTrack };
  }
  return { line: null, track: null };
}

export function convertSourcesToItems(items: PhotoViewerSourceItem[]) {
  return items.map((v) => {
    return {
      actions: v.actions,
      handlers: v.handlers,
      i18n: v.i18n,
      fullframe: getFullframe(v),
      thumbnail: v.thumbnail,
      bboxes: getBboxes(v),
      ...getLineAndTrackLoaders(v),
      ...getLineAndTrackSimple(v)
    } as PhotoViewerItem;
  });
}
