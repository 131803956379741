import TypedTimer from 'typedtimer';
import { VmsService } from '@/api';
import ResolveOutsidePromise from '@/common/ResolveOutsidePromise';
import TimelineObject, { IObjectItem } from '../timeline-object';

export default class TimelineChunks extends TimelineObject {
  items: IObjectItem[] = [];
  timer = new TypedTimer();
  loadStarted = false;
  loadPromise: any = null;

  async load(cameras: number[], timeFrom: number, timeTo: number): Promise<void> {
    if (this.loadPromise) {
      await this.loadPromise.promise;
      return;
    }
    this.loadStarted = true;
    this.loadPromise = new ResolveOutsidePromise();
    const currentTime = new Date().getTime();
    const isoTimeFrom = new Date(0).toISOString();
    const isoTimeTo = new Date(currentTime).toISOString();
    this.items = [];
    try {
      const response = await VmsService.vmsTimelineRetrieve(isoTimeFrom, isoTimeTo, cameras[0], '1s', null);
      for (const item of response.timeline) {
        const id = `id_${item.time_from}`;
        const from = new Date(item.time_from).getTime() / 1000;
        const to = new Date(item.time_to).getTime() / 1000;
        const chunk: IObjectItem = {
          id,
          line: 0,
          color: 'rgba(181,189,215,0.5)',
          timeFrom: from,
          timeTo: to,
          metadata: {}
        };
        this.items.push(chunk);
      }
    } catch (e) {
      console.log('load timeline chunks: error');
      this.loadPromise.reject();
      this.loadPromise = null;
    }

    if (this.items.length) {
      const lastItem = this.items[this.items.length - 1];
      const isRecording = Math.abs(lastItem.timeTo - currentTime / 1000) < 10;
      if (isRecording) {
        this.setAutoContinue(lastItem);
      } else {
        this.clearAutoContinue();
      }
    }
    this.isLoadedAll = true;

    if (this.loadPromise) {
      this.loadPromise.resolve();
      this.loadPromise = null;
    }
  }

  async loadIfEmpty(cameraId: number) {
    if (!this.loadStarted) {
      await this.load([cameraId], 0, 0);
    }
  }

  protected draw(timeFrom: number, timeTo: number) {
    super.draw(timeFrom, timeTo);
  }

  setAutoContinue(lastItem: IObjectItem) {
    this.timer.setInterval(() => {
      lastItem.timeTo = new Date().getTime() / 1000;
    }, 1000);
  }

  clearAutoContinue() {
    this.timer.clear();
  }
}
