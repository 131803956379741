
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NButton } from '@/uikit';
import { NAttachment, NAttachments, NAttachmentsI18n } from '@/uikit/attachments';
import NForm from '@/uikit/forms/NForm.vue';
import { getMultipleVideoUrlValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';

@Options({
  components: { NAttachments, NButton, NForm, NInput },
  emits: ['upload', 'close']
})
export default class SelectVideoForm extends Vue {
  @Prop({ required: false })
  readonly item?: any;

  items: NAttachment[] = [];
  urlsModel = { urls: '' };

  get urlFormSchema() {
    return [
      {
        path: 'urls',
        classes: 'n-form-w-full control-m',
        component: NTextareaAutosize,
        props: { i18n_placeholder: 'videos.file_urls_placeholder' },
        validators: [getMultipleVideoUrlValidator()]
      }
    ];
  }

  get isNextEnabled() {
    return this.items.length || this.urlsModel.urls;
  }

  get attachmentsI18n(): NAttachmentsI18n {
    return {
      droparea_label: this.$t('videos.drag_and_drop_files', 'f'),
      droparea_link_label: this.$t('videos.select_files', 'f'),
      removing_confirmation: this.$t('common.removing_confirmation'),
      yes: this.$t('common.yes', 'f'),
      cancel: this.$t('common.cancel', 'f')
    };
  }

  clickHandler() {
    const valid = this.$refs.urlsForm?.validate();
    if (!valid) {
      this.$refs.urlsForm?.displayErrors();
      return;
    }
    const payload = {
      urls: this.urlsModel.urls.split(/\n/).filter((v) => !!v),
      attachments: this.items
    };
    this.$emit('upload', payload);
  }
}
