import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-image-gallery__actions-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NImageViewer = _resolveComponent("NImageViewer")!

  return (_openBlock(), _createBlock(_component_NImageViewer, {
    src: _ctx.currentItem.src,
    zoomable: _ctx.zoomable,
    draggable: _ctx.draggable,
    bboxes: _ctx.currentItem.bboxes,
    distances: _ctx.currentItem.distances,
    line: _ctx.currentItem.line,
    track: _ctx.currentItem.track,
    handlers: _ctx.currentItem.handlers,
    i18n: _ctx.currentItem.i18n,
    "bbox-selectable": _ctx.bboxSelectable,
    "error-message": _ctx.errorMessage,
    actions: _ctx.actions,
    "image-count": _ctx.items.length,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('close'))),
    onExclude: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('exclude', _ctx.currentItem)))
  }, {
    "top-left": _withCtx(() => [
      (_ctx.items.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.hasLeftTopArrows)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  icon: "chevron-left",
                  type: "secondary-alt",
                  round: "",
                  onAction: _ctx.prev
                }, null, 8, ["onAction"]))
              : _createCommentVNode("", true),
            (_ctx.hasLeftTopArrows)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 1,
                  icon: "chevron-right",
                  type: "secondary-alt",
                  round: "",
                  onClick: _ctx.next,
                  class: "n-image-gallery__actions-top-next"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.itemsButton)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 2,
                  icon: "items",
                  type: "secondary-alt",
                  round: "",
                  onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('show-items')))
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.hasMiddleArrows)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            icon: "scroll-left",
            type: "link",
            class: "n-image-gallery__arrow n-image-gallery__arrow_left",
            onAction: _ctx.prev
          }, null, 8, ["onAction"]))
        : _createCommentVNode("", true),
      (_ctx.hasMiddleArrows)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 1,
            icon: "scroll-right",
            type: "link",
            class: "n-image-gallery__arrow n-image-gallery__arrow_right",
            onAction: _ctx.next
          }, null, 8, ["onAction"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["src", "zoomable", "draggable", "bboxes", "distances", "line", "track", "handlers", "i18n", "bbox-selectable", "error-message", "actions", "image-count"]))
}