import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "n-form-item n-big-filter__switch-block" }
const _hoisted_2 = { class: "n-big-filter__label heading-m" }
const _hoisted_3 = { class: "n-big-filter__value n-form-w-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_WebhooksFiltersDivider = _resolveComponent("WebhooksFiltersDivider")!
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('common.type', 'f')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NButtonGroup, {
          items: _ctx.sectionItems,
          type: "separated",
          "button-type": "outline",
          multiple: "",
          multiline: "",
          "model-value": _ctx.activeSections,
          "onUpdate:modelValue": _ctx.toggleActiveSection
        }, null, 8, ["items", "model-value", "onUpdate:modelValue"])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSections, (activeSection) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: activeSection }, [
        _createVNode(_component_WebhooksFiltersDivider, {
          class: "webhooks-divider-big",
          onReset: ($event: any) => (_ctx.resetSection(activeSection)),
          label: _ctx.$t(`webhooks.section_${activeSection}`, 'f'),
          "has-reset": "",
          "is-big": ""
        }, null, 8, ["onReset", "label"]),
        _createVNode(_component_NForm, {
          class: "n-big-filter__form",
          layout: _ctx.formLayout[activeSection],
          model: _ctx.model[activeSection]
        }, null, 8, ["layout", "model"])
      ], 64))
    }), 128))
  ]))
}