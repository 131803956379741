import { reactive } from 'vue';

class DialogController {
  show = false;
  component: any = {};
  props: any = {};
  action: any = () => {
    console.log('dialog default action handler!');
  };
}

const dialogController = reactive(new DialogController());
export { dialogController };
