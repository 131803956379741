
import { cloneDeep, difference } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { NButton } from '@/uikit/index';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import { EditorSection, EditorSections } from '@/pages/webhooks/components/types';
import { isSectionAvailable, isSectionIncludedInArrayBuilder } from '@/pages/webhooks/components/utils';
import WebhooksFiltersDivider from '@/pages/webhooks/components/WebhooksFiltersDivider.vue';
import { webhooksFiltersEditorVisualBuilder } from '@/pages/webhooks/components/webhooksFiltersEditorVisualBuilder';
import { differenceOf } from '@/definitions/common/utils';
import { dialogModule } from '@/store/dialogs/dialogModule';

@Options({
  name: 'AlertRuleForm',
  components: { FiltersBig, FiltersSmall, NButton, NButtonGroup, NForm, NModalWindow, WebhooksFiltersDivider },
  emits: ['reset', 'close', 'update:modelValue']
})
export default class AlertRuleForm extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  readonly modelValue!: Record<string, any>;

  @Prop({ type: Array })
  readonly sections?: EditorSection[];

  @Prop({ type: Boolean, default: false })
  readonly autosync = false;

  @Prop({ type: Boolean, default: true })
  readonly multiple = true;

  private model: Record<string, any> = {};

  get activeSections() {
    return Object.keys(this.model).filter(isSectionIncludedInArrayBuilder(this.sections)).filter(isSectionAvailable);
  }

  get formLayout(): Record<string, (IFormLayout | undefined)[]> {
    return webhooksFiltersEditorVisualBuilder() as any;
  }

  get sectionItems() {
    return Object.values(EditorSections)
      .filter(isSectionIncludedInArrayBuilder(this.sections))
      .filter(isSectionAvailable)
      .map((v) => {
        return {
          value: v,
          label: this.$t(`webhooks.section_${v}`, 'f')
        };
      });
  }

  @Watch('modelValue', { deep: true, immediate: true })
  syncModelValue() {
    this.model = cloneDeep(this.modelValue);
  }

  @Watch('model', { deep: true, immediate: false })
  autoSyncModel() {
    if (this.autosync) {
      const differenceKeys = Object.keys(differenceOf(this.model, this.modelValue));
      const differenceKeysReverse = Object.keys(differenceOf(this.modelValue, this.model));
      if (differenceKeys.length || differenceKeysReverse.length) this.$nextTick(() => this.$emit('update:modelValue', this.model));
    }
  }

  async toggleActiveSection(sections: EditorSection[]) {
    const [sectionOff] = difference(this.activeSections, sections);
    const [sectionOn] = difference(sections, this.activeSections);
    const hasActiveSection = !!Object.keys(this.model).length;

    if (sectionOff) delete this.model[sectionOff];
    if (sectionOn) {
      if (!this.multiple && hasActiveSection) {
        const result = await dialogModule.createResetAlertRulesConfirm();
        if (!result) return;
        Object.keys(this.model).forEach((key) => delete this.model[key]);
      }
      this.model[sectionOn] = {};
    }
    this.$emit('update:modelValue', this.model);
  }

  submit() {
    this.$emit('update:modelValue', this.model);
    this.$emit('close');
  }

  resetSection(v: EditorSection) {
    this.model[v] = {};
  }
}
