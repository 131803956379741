import { getListViewModel, ListViewModel } from '@/definitions/view-models';
import { FaceCluster } from '@/api';
import { viewModelRepository } from '@/api/common';
import { ClustersFacesContactsFilter, EmptyClustersFacesContactsFilter } from '@/api/models/ClustersFacesContactsFilter';
import { EmptyFaceCluster } from '@/api/models/FaceCluster';

type ContactsModuleType = ListViewModel<FaceCluster, ClustersFacesContactsFilter>;

export class InteractionsModule {
  clustersModule = viewModelRepository.getClustersFacesListViewModel();
  clusterContactsModule1?: ContactsModuleType | null = null;
  clusterContactsModule2?: ContactsModuleType | null = null;
  _clusterContactsModule1?: ContactsModuleType | null = null;
  _clusterContactsModule2?: ContactsModuleType | null = null;

  constructor() {}

  getClusterContactsModule(id: string | number, previousModule?: ContactsModuleType | null): ListViewModel<FaceCluster, ClustersFacesContactsFilter> | undefined {
    const options = {
      url: `/clusters/faces/${id}/contacts/`,
      routeName: 'ClustersFacesContacts',
      emptyItem: EmptyFaceCluster,
      emptyFilter: EmptyClustersFacesContactsFilter
    };
    const result = id ? getListViewModel(options) : undefined;
    if (result && previousModule) result.filter.current = previousModule.filter.current;
    return result;
  }
}
