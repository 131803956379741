
import { clone, cloneDeep, merge } from 'lodash';
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { applicationModule } from '@/store/application';
import { EpisodesMultiToSingle, EpisodeTypesMap, ObjectsMultiToSingle, ObjectsType } from '@/store/application/data.assets';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { DisplayType, DisplayTypes, EventsPageState, PagePaths, PageState, PageTypes } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { PageViewModel } from '@/store/application/page.view.model';
import { workspaceModule } from '@/store/application/workspace';
import { actionHandler, convertToMsbItem } from '@/store/data/ActionHandler';
import { satisfyEpisodeFilter } from '@/store/events/satisfy.episode.filter';
import { satisfyEventFilter } from '@/store/events/satisfy.event.filter';
import { IntersectionResultItem } from '@/store/intersection/IntersectionModule';
import { multisidebarModule } from '@/store/multisidebar';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { websocketModule } from '@/store/ws/websocket.module';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { ImageViewerActions } from '@/uikit/image-viewer/types';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import { isDefined } from '@/common/utils';
import Confirm from '@/components/common/Confirm.vue';
import DisplayTypesButtonGroup from '@/components/common/DisplayTypesButtonGroup.vue';
import { FilterValue } from '@/components/common/filter/filter-manager';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import { eventEpisodeAdapter } from '@/components/events/adapter';
import EventItem from '@/components/events/EventItem.vue';
import { EventOpenedItems, EventOrEpisode } from '@/components/events/types';
import { getEventFiltersBuilder } from '@/pages/events/forms/EventFiltersBuilder';
import { getMockFilterByType } from '@/pages/events/forms/mockFilters';
import { AcknowledgeUpdatableProperties, EpisodeUpdatableProperties, fillExistsItem } from '@/pages/events/helpers';
import ListPage from '@/pages/ListPage.vue';
import CommonMap from '@/components/map/CommonMap.vue';
import { CommonMapConfig } from '@/components/map/CommonMapConfig';
import { configModule, DefaultCenterMapPoint } from '@/store/config';
import EventMarker from '@/components/events/map/EventMarker.vue';
import EventClusterMarker from '@/components/events/map/EventClusterMarker.vue';
import SmallEventItem from '@/components/events/map/SmallEventItem.vue';
import { colorsConfig } from '@/components/map/colors';
import { FaceEvent, HumanCard } from '@/api';
import EventItemShort from '@/components/events/EventItemShort.vue';
import { differenceOf } from '@/definitions/common/utils';
import { dataModule } from '@/store/data';

@Options({
  name: 'EventsPage',
  components: {
    CommonMap,
    Confirm,
    DisplayTypesButtonGroup,
    EventItem,
    FilterSection,
    ListPage,
    NButton,
    NButtonGroup,
    NIcon,
    SortDropdown,
    Statistics
  }
})
export default class EventsPage extends Vue {
  @Prop({ type: String, required: true })
  readonly tab!: string;

  @Prop({ type: Object, required: false })
  readonly externalFilters!: any;

  isAcknowledgingConfirmVisible = false;
  pageVM: PageViewModel<any, any> = new PageViewModel({ tab: 'before', path: PagePaths.Events });
  intersectionResultItem?: IntersectionResultItem | null = null;

  get mapConfig() {
    const result: CommonMapConfig = {
      sidebarTitle_i18n: this.state.pageType === PageTypes.Events ? 'common.events' : 'common.episodes',
      module: this.module,
      showTracks: this.matchedCard,
      msbType: this.pageSidebarType as any,
      centerPoint: configModule.config.map?.default_center || DefaultCenterMapPoint,
      colorsConfig: colorsConfig,
      ItemMarker: EventMarker,
      ClusterMarker: EventClusterMarker,
      PopupItem: SmallEventItem,
      ListItem: EventItemShort,
      itemProps: {
        objects: this.state.objectType,
        episodeType: this.state.episodeType,
        type: this.state.pageType,
        acknowledgeAllowed: this.acknowledgeAllowed,
        eventSelected: (item: any) => this.getIsEventOrEpisodeSelected(item),
        cardSelected: (item: any) => item.matched_card && this.getIsCardSelected(item),
        openedItem: (item: any) => this.getOpenedTypeByItem(item)
      },
      actionHandler: async (actionName: ItemsActionName, item: FaceEvent | HumanCard) => {
        await this.actionHandler(item.id, actionName, item);
      }
    };

    return result;
  }

  get acknowledgeAllAllowed() {
    if (this.isEvents) {
      const type = ObjectsMultiToSingle[this.state.objectType];
      return aclModule.getAccess(`ffsecurity.change_${type}event`);
    } else {
      return this.state.episodeType === EpisodeTypesMap.Cars
        ? aclModule.getAccess('ffsecurity.change_carevent')
        : ['ffsecurity.change_faceevent', 'ffsecurity.change_bodyevent'].some((v) => aclModule.getAccess(v));
    }
  }

  get acknowledgeAllowed() {
    const { objectType, episodeType } = this.state;
    const type = this.isEvents ? ObjectsMultiToSingle[objectType] : EpisodesMultiToSingle[episodeType];
    const page = this.isEvents ? 'event' : 'episode';
    const permisssion = `ffsecurity.change_${type}${page}`;
    return aclModule.getAccess(permisssion);
  }

  get selectedItems() {
    return this.sidebarModule.getItemsByType(this.pageSidebarType);
  }

  get selectedCardItems() {
    return this.sidebarModule.getItemsByType(this.cardsSidebarType);
  }

  get pageSidebarType() {
    const { pageType, objectType, episodeType } = this.state;
    if (pageType === PageTypes.Events) {
      return `${pageType}_${objectType}`;
    }
    return `${pageType}_${episodeType}`;
  }

  get cardsSidebarType() {
    return `${PageTypes.Cards}_${this.cardType}`;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get dataAssetsModule(): DataAssetsModule {
    return dataAssetsModule;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get state(): EventsPageState {
    return this.pageVM.state as any;
  }

  get module(): ListViewModel<any, any> {
    this.pageVM.module.filter.force = this.externalFilters;
    this.pageVM.module.filter.current.limit = this.limit;
    return this.pageVM.module as ListViewModel<any, any>;
  }

  get limit() {
    return this.state.displayType === DisplayTypes.Map ? configModule?.config?.map?.maximum_objects_on_map ?? 200 : 20;
  }

  get smallFilterLayout() {
    const { pageType, objectType, episodeType } = this.state;
    return getEventFiltersBuilder({ small: true, cardType: this.cardType }).getFilterByType(pageType, objectType, episodeType);
  }

  get getBigFilterLayout() {
    const { pageType, objectType, episodeType } = this.state;
    return getEventFiltersBuilder({ small: false, cardType: this.cardType }).getFilterByType(pageType, objectType, episodeType);
  }

  get displayTypes() {
    const result: DisplayType[] = [DisplayTypes.Short, DisplayTypes.Full];
    if (configModule.features.maps_enabled) result.push(DisplayTypes.Map);
    return result;
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get description() {
    return this.isEvents ? this.$t('events.all_events_acknowledged_confirm') : this.$t('events.all_episodes_acknowledged_confirm');
  }

  get pageClasses(): Record<string, boolean> {
    return {
      'events-page_map': this.state.displayType === 'map'
    };
  }

  get classes(): Record<string, boolean> {
    return {
      'n-page__tiles': this.isShort,
      'n-page__tiles-compensation-5': this.isShort,
      'n-page__rows': !this.isShort
    };
  }

  get cardType() {
    return this.isEvents ? dataAssetsModule.getCardTypeByObjectType(this.state.objectType) : dataAssetsModule.getCardTypeByEpisodeType(this.state.episodeType);
  }

  get isEvents(): boolean {
    return this.state.pageType === PageTypes.Events;
  }

  get viewerItems() {
    return this.module.items.map((v: any) => eventEpisodeAdapter(v, this.state.objectType));
  }

  get websocketModule() {
    return websocketModule;
  }

  get hasCustomOrdering() {
    const ordering = this.module.filter.current.ordering;
    return ordering && ordering !== '-id' && ordering !== '-created_date';
  }

  get isCurrentTab() {
    return (this.state.tab || '').indexOf(workspaceModule.current || '') > -1;
  }

  get isShort() {
    return this.state.displayType === 'short';
  }

  doesItemExist(id: number) {
    return this.module.items.find((item: EventOrEpisode) => item.id === id) ?? null;
  }

  togglePlaying() {
    this.module.filter.current.created_date_lte = this.pageVM.state.playing ? new Date().toISOString() : undefined;
    this.pageVM.togglePlaying();
  }

  getOpenedTypeByItem(item: EventOrEpisode) {
    if (!this.currentSidebarItemId) return '';
    if (this.currentSidebarItemId === generateMultisidebarId(this.pageSidebarType, item.id)) return EventOpenedItems.Item;
    if (item.matched_card && this.currentSidebarItemId === generateMultisidebarId(this.cardsSidebarType, item.matched_card)) return EventOpenedItems.Card;
  }

  getIsEventOrEpisodeSelected(item: EventOrEpisode) {
    const msbId = generateMultisidebarId(this.pageSidebarType, item.id);
    return !!this.selectedItems.find((v) => v.id === msbId);
  }

  getIsCardSelected(id: number) {
    const msbId = generateMultisidebarId(this.cardsSidebarType, id);
    return !!this.selectedCardItems.find((v) => v.id === msbId);
  }

  computeEventsState(objectType: string): PageState {
    const result = pageModule.getPageStateByTab(PagePaths.Events, 'events');
    result.pageType = PageTypes.Events;
    result.objectType = objectType;
    return result;
  }

  computeEventsModule(objectType: string): ListViewModel<any, any> {
    const state = this.computeEventsState(objectType);
    return pageModule.getPageModule(state) as unknown as ListViewModel<any, any>;
  }

  navigateToCard(id: string | number) {
    const sidebarType = `${PageTypes.Cards}_${this.cardType}`;
    actionHandler.run(ItemsActionNames.ShowItem, { type: sidebarType, rawItem: id });
  }

  copyCommonFilters(from: any, to: any) {
    const items = [
      'acknowledged',
      'bs_type',
      'camera_groups',
      'cameras',
      'case_in',
      'episode',
      'has_case',
      'matched_card',
      'matched_lists',
      'no_match',
      'video_archive'
    ];
    items.forEach((name) => {
      to[name] = from[name];
    });
  }

  @Watch('externalFilters', { deep: true })
  changeExternalFilters(v: any) {
    this.module.filter.current = Object.assign(this.module.filter.current, v);
  }

  @Watch('state.displayType', { deep: true })
  changeDisplayType(v: DisplayType) {
    if (this.state.displayType != DisplayTypes.Map) {
      this.module.filter.current.longitude_gte = undefined;
      this.module.filter.current.longitude_lte = undefined;
      this.module.filter.current.latitude_gte = undefined;
      this.module.filter.current.latitude_lte = undefined;
    }
    this.module.debouncedGet();
    this.state.filter = this.module.filter.currentClean;
  }

  @Watch('module', { deep: false })
  changeModuleHandler(v: any, p: any) {
    if (v) autoUpdateHelper.addListInstance(v);
    if (p && v) this.copyCommonFilters(p.filter.current, v.filter.current);
    this.intersectionResultItem?.reset();
  }

  get currentFilter() {
    return cloneDeep(this.module.filter.current);
  }

  @Watch('currentFilter', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (!this.isCurrentTab) return;
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.debouncedGet();
    this.state.filter = this.module.filter.currentClean;
    if (differenceOf(v, p)['cameras']) this.syncMapPositionByCameras(differenceOf(v, p)['cameras']);
    if (differenceOf(v, p)['camera_in']) this.syncMapPositionByCameras(differenceOf(v, p)['camera_in']);
  }

  @Watch('module.loading')
  loadingHandler(v: boolean) {
    if (!v) {
      this.intersectionResultItem?.reset();
      this.intersectionResultItem?.syncObservableTargetsNextTick();
    }
  }

  @Watch('module.appending')
  appendingHandler(v: boolean) {
    if (!v) this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  @Watch('websocketModule.event', { deep: true })
  websocketEventHandler(v: any) {
    const canAdd = this.state.playing && this.isEvents && !this.hasCustomOrdering;
    if (!canAdd) return;
    const type = ObjectsMultiToSingle[this.state.objectType];
    const result = v[type];
    if (!result) return;
    const satisfy = satisfyEventFilter(result, this.module.filter.current, this.state.objectType as ObjectsType);
    if (!satisfy) {
      console.warn('Event is not satisfied: ', result, this.module.filter.current);
      return;
    }

    const existing = this.doesItemExist(result.id);
    isDefined(existing) ? fillExistsItem(existing, result) : this.module.items.unshift(result);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  @Watch('websocketModule.eventUpdated', { deep: true })
  websocketEventUpdateHandler(v: any) {
    const type = ObjectsMultiToSingle[this.state.objectType];
    const result = v[type];
    if (!result) return;
    const existing = this.doesItemExist(result.id);
    isDefined(existing) && fillExistsItem(existing, result);
  }

  @Watch('websocketModule.episode', { deep: true })
  websocketEpisodeHandler(v: any) {
    const canAdd = this.state.playing && !this.isEvents && !this.hasCustomOrdering;
    if (!canAdd) return;
    const type = EpisodesMultiToSingle[this.state.episodeType];
    const result = v[type];
    if (!result) return;

    const satisfy = satisfyEpisodeFilter(result, this.module.filter.current, this.state.episodeType);
    if (!satisfy) {
      console.warn('Episode is not satisfied: ', result, this.module.filter.current);
      return;
    }

    const existing = this.doesItemExist(result.id);
    isDefined(existing) ? fillExistsItem(existing, result) : this.module.items.unshift(result);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  @Watch('websocketModule.episodeUpdated', { deep: true })
  websocketEpisodeUpdateHandler(v: any) {
    const canAdd = this.state.playing && !this.isEvents && !this.hasCustomOrdering;
    const type = EpisodesMultiToSingle[this.state.episodeType];
    const result = v[type];
    if (!result) return;
    const existing = this.doesItemExist(result.id);
    isDefined(existing) ? fillExistsItem(existing, result, EpisodeUpdatableProperties) : canAdd && this.module.items.unshift(result);
    this.intersectionResultItem?.syncObservableTargetsNextTick();
    this.limitItemsCount();
  }

  @Watch('websocketModule.acknowledgedAll')
  handleAcknowledgedAll(v: boolean) {
    v && this.module.get();
  }

  created() {
    const route = cloneDeep(this.$route);
    route.query.tab = this.tab;
    const page = pageModule.getPageViewModelDirect(PagePaths.Events, {}, this.tab);
    this.pageVM = reactive(page) as PageViewModel<any, any>;
    if (this.externalFilters) this.module.filter.current = Object.assign(this.module.filter.current, this.externalFilters);
  }

  mounted() {
    this.intersectionResultItem = new IntersectionResultItem({
      container: document.querySelector('.page-content') as HTMLElement,
      itemsQuerySelector: '.event-item-proxy',
      id: this.tab
    });
    this.intersectionResultItem?.reset();
    this.intersectionResultItem?.syncObservableTargetsNextTick();
  }

  beforeUnmount() {
    this.intersectionResultItem?.reset();
  }

  get defaultAction() {
    return EventDetails.ShowFullScreen;
  }

  get currentSidebarItemId() {
    return this.sidebarModule.currentItem?.id;
  }

  get displayItemsMap(): Record<string, boolean> | null {
    return this.intersectionResultItem?.displayItemsMap ?? null;
  }

  get matchedCard() {
    return this.currentFilter.matched_card || this.currentFilter.matched_card_in?.[0];
  }

  async actionHandler(id: string | number, action: string, payload?: any): Promise<void> {
    const event = payload?.best_face_event || payload?.best_body_event || payload?.best_car_event || payload;
    switch (action) {
      case ItemsActionNames.ShowInfo:
      case ItemsActionNames.ShowItem:
        await actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: id });
        break;
      case ItemsActionNames.ShowFullScreen:
        this.showItemFullscreen(payload);
        break;
      case ItemsActionNames.ShowPlayer:
        if (event) {
          const timeFrom = new Date(event.created_date).getTime() / 1000;
          this.$videoPlayer.playArchive(event.camera, timeFrom - 3);
        }
        break;
      case ItemsActionNames.Acknowledge:
        await this.acknowledgeHandler(id, payload);
        break;
      case ItemsActionNames.NavigateToCard:
        await this.navigateToCard(payload.matched_card);
        break;
      default:
        console.warn('Unsupported action ', id, action, payload);
        break;
    }
  }

  scrollBottomHandler(v: number | null) {
    if (!this.isCurrentTab) return;
    if (this.state.displayType === DisplayTypes.Map) return;
    if (typeof v === 'number' && v < 200) {
      this.module.append();
    }
  }

  async acknowledgeHandler(id: string | number, payload: any): Promise<void> {
    await this.module.update({ id: id, acknowledged: payload.value }, AcknowledgeUpdatableProperties);
  }

  async acknowledgeAllHandler(): Promise<void> {
    this.isAcknowledgingConfirmVisible = false;
    if (this.isEvents) {
      await this.module.dataService.createItemSomethingByAction('', 'acknowledge');
    } else {
      const { episodeType } = this.state;
      if (episodeType === 'cars') {
        const module = this.computeEventsModule('cars');
        dataAssetsModule.availableObjectsMap.car && (await module.dataService.createItemSomethingByAction('', 'acknowledge'));
      } else {
        const facesModule = this.computeEventsModule('faces');
        const bodiesModule = this.computeEventsModule('bodies');
        const hasFacePermission = aclModule.getAccess('ffsecurity.change_faceevent');
        const hasBodyPermission = aclModule.getAccess('ffsecurity.change_bodyevent');
        hasFacePermission && (await facesModule.dataService.createItemSomethingByAction('', 'acknowledge'));
        hasBodyPermission && dataAssetsModule.availableObjectsMap.body && (await bodiesModule.dataService.createItemSomethingByAction('', 'acknowledge'));
      }
    }
  }

  setTestData() {
    const { pageType, objectType, episodeType } = this.state;
    const testData = getMockFilterByType(pageType, objectType, episodeType);
    Object.assign(this.module.filter.current, testData);
  }

  selectFilter(v?: FilterValue) {
    this.module.filter.setCurrentByString(v?.value ?? '');
  }

  limitItemsCount() {
    const maxCount = this.state.displayType === 'map' ? 1000 : 200;
    const scrollTop = this.$refs.listPage?.getScrollTop();
    const allowedScrollValue = scrollTop >= 0 && scrollTop <= 200;
    if (allowedScrollValue && this.module.items.length > maxCount) {
      this.module.items.splice(maxCount, this.module.items.length - maxCount);
    }
  }

  showItemFullscreen(item: EventOrEpisode) {
    const activeItemIndex = this.viewerItems.findIndex((v) => v.id == item.id);
    if (activeItemIndex !== -1) {
      this.viewerItems[activeItemIndex]['actions'] = [ImageViewerActions.Detect];
      this.$photoViewer.show(this.viewerItems, { activeItemIndex });
    }
  }

  async syncMapPositionByCameras(cameraIds: number[]) {
    if (!this.$refs?.commonMap) return;
    const camera = await dataModule.getCameraById(cameraIds[cameraIds.length - 1]);
    if (camera?.latitude && camera?.longitude) this.$refs?.commonMap?.setCenter([camera.latitude, camera.longitude] as any);
  }

  setFilter(filter: any) {
    Object.assign(this.module.filter.current, filter);
  }
}
