import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { configModule } from '@/store/config';
import { convertToMsbItem } from '@/store/data/ActionHandler';
import { confirmDialogFactory, ConfirmOptions } from '@/store/dialogs/confirmDialogFactory';
import { languageModule } from '@/store/languages';
import { MultisidebarGroup, MultisidebarItem } from '@/store/multisidebar/types';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import {HumanCard} from "@/api";
import { customDialogFactory } from "@/store/dialogs/customDialogFactory";
import ExternalSearchRequestCreate from "@/pages/external-search/requests/ExternalSearchRequestCreate.vue";

const t = languageModule.getTranslatedToken;

export class DialogModule {
  async alert(i18n_message: string) {
    await confirmDialogFactory.create({
      title: t('common.warning', 'f'),
      text: t(i18n_message),
      cancelLabel: '',
      confirmLabel: t('common.ok')
    });
  }

  async createResetAlertRulesConfirm() {
    const result = confirmDialogFactory.create({
      ...this.getResetAlertRuleConfirmOptions()
    });
    return result;
  }

  async createBatchLoaderConfirm() {
    const result = confirmDialogFactory.create({
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.batch_uploader_confirm', 'f')
    });
    return result;
  }

  async getWarning(action: ItemsActionName, item: MultisidebarItem<any>): Promise<boolean> {
    let result = Promise.resolve(false);
    if ((action === ItemsActionNames.Delete || action === ItemsActionNames.DeleteAll) && item.type === MultisidebarItemTypes.WatchLists && item.model?.item?.id < 2) {
      await dialogModule.alert('common.standard_watch_list_was_not_deleted');
      result = Promise.resolve(true);
    }
    return result;
  }

  async createDialog(action: ItemsActionName, item: MultisidebarItem<any>): Promise<boolean> {
    return this.createConfirm(action, item);
  }

  async createConfirm(action: ItemsActionName, item: MultisidebarItem<any>): Promise<boolean> {
    let result = Promise.resolve(true);
    let msbItem: MultisidebarItem<any> | null = null;

    switch (action) {
      case ItemsActionNames.Process:
      case ItemsActionNames.ProcessAll:
        msbItem = await convertToMsbItem(item);
        if (msbItem.model?.item?.finished) result = confirmDialogFactory.create(this.getPocessingConfirmOptions());
        break;

      case ItemsActionNames.ResetParticipant:
        result = confirmDialogFactory.create(this.getResetParticipantConfirmOptions());
        break;

      case ItemsActionNames.DeleteAll:
      case ItemsActionNames.Delete:
        result = confirmDialogFactory.create(this.getDeleteConfirmOptions(item.type));
        break;

      case ItemsActionNames.DeleteVideoFile:
      case ItemsActionNames.DeleteVideoFileAll:
        result = confirmDialogFactory.create(this.getDeleteVideoFileConfirmOptions(item.type));
        break;

      case ItemsActionNames.DeleteCards:
      case ItemsActionNames.DeleteCardsInAll:
        result = confirmDialogFactory.create(this.getDeleteCardsConfirmOptions(item.type));
        break;

      case ItemsActionNames.RemoveFromMonitoring:
      case ItemsActionNames.RemoveAllFromMonitoring:
        result = confirmDialogFactory.create(this.getRemoveFromMonitoringConfirmOptions(item.type));
        break;
    }
    return result;
  }

  createGroupConfirm(action: ItemsActionName, group: MultisidebarGroup): Promise<boolean> {
    return this.createDialog(action, group.items[0].item);
  }

  private getDefaultOptions(): Partial<ConfirmOptions> {
    return {
      confirmLabel: t('common.yes', 'f'),
      cancelLabel: t('common.cancel', 'f')
    };
  }

  private getResetParticipantConfirmOptions(): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('cases.reset_participant_confirm', 'f')
    };
    return result;
  }

  private getPocessingConfirmOptions(): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.video_processing_confirm', 'f')
    };
    return result;
  }

  private getDeleteCardsConfirmOptions(type: string): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.delete_all_cards_confirm', 'f')
    };
    return result;
  }

  private getRemoveFromMonitoringConfirmOptions(type: string): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('remote_monitoring.alert_text', 'f')
    };
    return result;
  }

  private getResetAlertRuleConfirmOptions(): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('alerts.reset_alert_rule_confirmation', 'f')
    };
    return result;
  }

  private getDeleteConfirmOptions(type: string): ConfirmOptions {
    const hasPuppeteer = !!configModule.config.plugins?.puppeteer;
    let confirmToken = 'common.delete_confirm';
    switch (type) {
      case MultisidebarItemTypes.ExternalVms:
        confirmToken = 'common.delete_external_vms_confirm';
        break;
      case MultisidebarItemTypes.Webhooks:
        confirmToken = 'common.delete_webhooks_confirm';
        break;
      case MultisidebarItemTypes.ClustersFaces:
      case MultisidebarItemTypes.ClustersBodies:
      case MultisidebarItemTypes.ClustersCars:
        confirmToken = 'common.delete_clusters_confirm';
        break;
      case MultisidebarItemTypes.Groups:
        confirmToken = 'common.delete_groups_confirm';
        break;
      case MultisidebarItemTypes.Users:
        confirmToken = 'common.delete_users_confirm';
        break;
      case MultisidebarItemTypes.Cameras:
        confirmToken = 'common.delete_cameras_confirm';
        break;
      case MultisidebarItemTypes.CardsHumans:
      case MultisidebarItemTypes.CardsCars:
        confirmToken = hasPuppeteer ? 'common.delete_cards_monitoring_confirm' : 'common.delete_cards_confirm';
        break;
      case MultisidebarItemTypes.CameraGroups:
        confirmToken = 'common.delete_camera_groups_confirm';
        break;
      case MultisidebarItemTypes.WatchLists:
        confirmToken = 'common.delete_all_watch_lists_confirm';
        break;
      case MultisidebarItemTypes.Videos:
        confirmToken = 'common.delete_video_confirm';
        break;
      case MultisidebarItemTypes.FaceObjects:
      case MultisidebarItemTypes.BodyObjects:
      case MultisidebarItemTypes.CarObjects:
        confirmToken = 'common.delete_objects_confirm';
        break;
    }

    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t(confirmToken, 'f')
    };
    return result;
  }

  private getDeleteVideoFileConfirmOptions(type: string): ConfirmOptions {
    const result: ConfirmOptions = {
      ...this.getDefaultOptions(),
      title: t('common.warning', 'f'),
      text: t('common.delete_confirm', 'f')
    };
    return result;
  }

  async createExternalSearchDialog(item: HumanCard) {
    return customDialogFactory.create<void>(ExternalSearchRequestCreate, { item }, 'close');
  }
}

export const dialogModule = new DialogModule();
