import { PageNames, PagePaths } from '@/store/application/page.definitions';
import { configModule } from '@/store/config';
import { MenuItem } from '@/store/menu/menu';

export const settingsItems: MenuItem[] = [
  {
    meta: {},
    name: 'settings',
    i18n: 'common.general',
    path: PagePaths.Settings
  },
  {
    meta: {},
    name: 'interface',
    i18n: 'common.interface',
    path: PagePaths.Interface
  },
  {
    meta: {
      permissions: ['ffsecurity.view_user']
    },
    name: 'roles',
    i18n: 'common.roles',
    path: PagePaths.Roles
  },
  {
    meta: {
      permissions: ['ffsecurity.view_user']
    },
    name: 'users',
    i18n: 'common.users',
    path: PagePaths.Users
  },
  {
    meta: {},
    name: 'sessions',
    i18n: 'common.sessions',
    path: PagePaths.Sessions
  },
  {
    meta: {
      permissions: ['ffsecurity.view_deviceblacklistrecord']
    },
    name: 'blocklistRecords',
    i18n: 'common.blocklist_records',
    path: PagePaths.BlockList
  },
  {
    meta: {
      permissions: ['ffsecurity.view_cameragroup']
    },
    name: 'camera-groups',
    i18n: 'common.camera_groups',
    path: PagePaths.CameraGroups
  },
  {
    meta: {
      permissions: ['ffsecurity.view_watchlist']
    },
    name: 'watch-lists',
    i18n: 'common.watch_lists',
    path: PagePaths.WatchLists
  },

  {
    meta: {
      permissions: ['ffsecurity.configure_ntls']
    },
    name: 'license',
    i18n: 'common.license',
    path: PagePaths.License
  },
  {
    meta: {
      permissions: ['ffsecurity.view_externalvms']
    },
    name: 'external-vms',
    i18n: 'common.external_vms',
    path: PagePaths.ExternalVms,
    enabled: () => {
      return configModule.config.external_vms?.enabled;
    }
  },
  {
    meta: {
      configPath: 'custom_reports',
      admin: true
    },
    name: 'custom-reports',
    i18n: 'common.custom_reports',
    path: PagePaths.CustomReports
  },
  {
    name: 'developer',
    i18n: 'common.developer',
    meta: {
      permissions: ['ffsecurity.view_webhook', 'ffsecurity.view_user'],
      plugins: {
        partner: false
      }
    }
  },
  {
    meta: {
      licensePath: 'multi.features.alerts',
      permissions: ['ffsecurity.view_alertrule']
    },
    name: PageNames.Alerts,
    i18n: 'alerts.alerts',
    path: PagePaths.Alerts,
    enabled: () => {
      return !!configModule.config.annex?.enabled;
    }
  },
  {
    meta: {
      services: {
        webhooks: true
      },
      permissions: ['ffsecurity.view_webhook']
    },
    name: PageNames.Webhooks,
    i18n: 'webhooks.webhooks',
    path: PagePaths.Hooks
  },
  {
    meta: {},
    name: 'documentation',
    i18n: 'common.documentation',
    external: true,
    serverPath: 'doc/'
  },
  {
    meta: {},
    name: PageNames.ApiDocumentation,
    i18n: 'common.documentation_api',
    external: true,
    serverPath: 'api-docs/'
  }
];
