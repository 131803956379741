import { onBeforeUnmount, onMounted, Ref, unref } from 'vue';
import { asDefined } from '@/common/utils';

export type Nullable<T> = T | null;

export type PageContentLayoutInfiniteScrollHandler = () => void;

const HANDLER_EXECUTION_THRESHOLD_IN_PX = 200;

export function usePageContentLayoutInfiniteScroll(
  container: Ref<Nullable<HTMLElement>>,
  content: Ref<Nullable<HTMLElement>>,
  handler: PageContentLayoutInfiniteScrollHandler
) {
  const { round } = Math;
  const getContainer = () => asDefined(unref(container));
  const getContainerHeight = () => round(getContainer().getBoundingClientRect().height);
  const getContainerScrollTop = () => round(getContainer().scrollTop);
  const getContent = () => asDefined(unref(content));
  const getContentHeight = () => round(getContent().getBoundingClientRect().height);
  const observer = new MutationObserver(executeHandlerBySomeCondition);

  onBeforeUnmount(handleBeforeUnmountHook);
  onMounted(handleMountedHook);

  function handleBeforeUnmountHook(): void {
    getContainer().removeEventListener('scroll', executeHandlerBySomeCondition);
    observer.disconnect();
  }

  function handleMountedHook(): void {
    getContainer().addEventListener('scroll', executeHandlerBySomeCondition);
    observer.observe(getContent(), { attributes: false, childList: true, subtree: true });
  }

  function executeHandlerBySomeCondition(): void {
    shouldHandlerBeExecuted() && handler();
  }

  function shouldHandlerBeExecuted(): boolean {
    return getContentHeight() - getContainerHeight() - getContainerScrollTop() <= HANDLER_EXECUTION_THRESHOLD_IN_PX;
  }
}
