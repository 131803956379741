/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CasePhoto } from '../models/CasePhoto';
import type { CasePhotoRequest } from '../models/CasePhotoRequest';
import type { CasePhotoUpdate } from '../models/CasePhotoUpdate';
import type { CasePhotoUpdateRequest } from '../models/CasePhotoUpdateRequest';
import type { CountResponse } from '../models/CountResponse';
import type { PaginatedCasePhotoList } from '../models/PaginatedCasePhotoList';
import type { PatchedCasePhotoUpdateRequest } from '../models/PatchedCasePhotoUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
export class CasePhotosService {
    /**
     * List case photos
     * Use this method to list case photos.
     * @param caseIn Select case photos related to these cases
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn Select case photos with provided ids
     * @param limit Number of results to return per page.
     * @param nameContains Select case photos where the SQL condition `name ILIKE name_contains` is true
     * @param ordering Which field to use when ordering the results. Available fields: `id`, `created_date`, `name`
     * @param page Pagination cursor value.
     * @param status Select case photos with these statuses
     * @returns PaginatedCasePhotoList 
     * @throws ApiError
     */
    public static casePhotosList(
caseIn?: Array<number>,
createdDateGt?: string,
createdDateGte?: string,
createdDateLastNDays?: number,
createdDateLt?: string,
createdDateLte?: string,
createdDateNthFullWeek?: number,
createdDateNthWorkWeek?: number,
idIn?: Array<number>,
limit?: string,
nameContains?: string,
ordering?: string,
page?: string,
status?: Array<'completed' | 'downloaded' | 'failed'>,
): CancelablePromise<PaginatedCasePhotoList> {
        return __request({
            method: 'GET',
            path: `/case-photos/`,
            query: {
                'case_in': caseIn,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'limit': limit,
                'name_contains': nameContains,
                'ordering': ordering,
                'page': page,
                'status': status,
            },
        });
    }
    /**
     * Add a new case photo
     * Use this method to add a new case photo.
     * @param formData 
     * @returns CasePhoto 
     * @throws ApiError
     */
    public static casePhotosCreate(
formData: CasePhotoRequest,
): CancelablePromise<CasePhoto> {
        return __request({
            method: 'POST',
            path: `/case-photos/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Retrieve case photo by ID
     * Use this method to retrieve a case photo by its ID.
     * @param id A unique integer value identifying this Case Photo.
     * @returns CasePhoto 
     * @throws ApiError
     */
    public static casePhotosRetrieve(
id: number,
): CancelablePromise<CasePhoto> {
        return __request({
            method: 'GET',
            path: `/case-photos/${id}/`,
        });
    }
    /**
     * Update case photo properties
     * Use this method to update a case photo properties.
     * @param id A unique integer value identifying this Case Photo.
     * @param formData 
     * @returns CasePhotoUpdate 
     * @throws ApiError
     */
    public static casePhotosUpdate(
id: number,
formData: CasePhotoUpdateRequest,
): CancelablePromise<CasePhotoUpdate> {
        return __request({
            method: 'PUT',
            path: `/case-photos/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Update case photo properties
     * Use this method to update a case photo properties.
     * @param id A unique integer value identifying this Case Photo.
     * @param formData 
     * @returns CasePhotoUpdate 
     * @throws ApiError
     */
    public static casePhotosPartialUpdate(
id: number,
formData?: PatchedCasePhotoUpdateRequest,
): CancelablePromise<CasePhotoUpdate> {
        return __request({
            method: 'PATCH',
            path: `/case-photos/${id}/`,
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Delete case photo
     * Use this method to delete a case photo.
     * @param id A unique integer value identifying this Case Photo.
     * @returns void 
     * @throws ApiError
     */
    public static casePhotosDestroy(
id: number,
): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/case-photos/${id}/`,
        });
    }
    /**
     * Objects count
     * This method retrieves count of objects.
     * @param caseIn Select case photos related to these cases
     * @param createdDateGt Select objects with `created_date` **greater** than this value
     * @param createdDateGte Select objects with `created_date` **greater than or equal** to this value
     * @param createdDateLastNDays Select objects with `created_date` in last N days
     * @param createdDateLt Select objects with `created_date` **less** than this value
     * @param createdDateLte Select objects with `created_date` **less than or equal** to this value
     * @param createdDateNthFullWeek Select objects with `created_date` in last Nth week (including Sunday and Saturday)
     * @param createdDateNthWorkWeek Select objects with `created_date` in last Nth week (only working days, i.e. excluding Sunday and Saturday)
     * @param idIn Select case photos with provided ids
     * @param nameContains Select case photos where the SQL condition `name ILIKE name_contains` is true
     * @param status Select case photos with these statuses
     * @returns CountResponse 
     * @throws ApiError
     */
    public static casePhotosCountRetrieve(
caseIn?: Array<number>,
createdDateGt?: string,
createdDateGte?: string,
createdDateLastNDays?: number,
createdDateLt?: string,
createdDateLte?: string,
createdDateNthFullWeek?: number,
createdDateNthWorkWeek?: number,
idIn?: Array<number>,
nameContains?: string,
status?: Array<'completed' | 'downloaded' | 'failed'>,
): CancelablePromise<CountResponse> {
        return __request({
            method: 'GET',
            path: `/case-photos/count/`,
            query: {
                'case_in': caseIn,
                'created_date_gt': createdDateGt,
                'created_date_gte': createdDateGte,
                'created_date_last_n_days': createdDateLastNDays,
                'created_date_lt': createdDateLt,
                'created_date_lte': createdDateLte,
                'created_date_nth_full_week': createdDateNthFullWeek,
                'created_date_nth_work_week': createdDateNthWorkWeek,
                'id_in': idIn,
                'name_contains': nameContains,
                'status': status,
            },
        });
    }
}