
import { defineComponent, ref } from 'vue';
import { request } from '@/api/core/request';
import { NButton } from '@/uikit';
import NHint from '@/uikit/hint/NHint.vue';
import { getDefaultAuthPayload } from './login-helpers';

const I18NDefaultError = 'common.login_ad_error';

export default defineComponent({
  name: 'LoginADForm',
  components: { NButton, NHint },
  emit: ['login'],
  setup(props, { emit }) {
    const error = ref('');

    async function login() {
      error.value = '';
      try {
        let result = await request({
          method: 'POST',
          path: '/users/me/ad',
          body: getDefaultAuthPayload(),
          mediaType: 'application/json'
        });
        emit('login', result);
      } catch (e) {
        if (e.isAxiosError || e.name === 'ApiError') {
          error.value = I18NDefaultError;
          console.warn('[Active Directory Auth Error]: ' + e);
        } else {
          throw e;
        }
      }
    }

    return {
      login,
      error
    };
  }
});
