/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum TypeEnum {
    FaceEvents = 'face-events',
    FaceEventsInOut = 'face-events-in-out',
    BodyEvents = 'body-events',
    CarEvents = 'car-events',
    HumanEpisodes = 'human-episodes',
    CarEpisodes = 'car-episodes',
    FaceClusters = 'face-clusters',
    BodyClusters = 'body-clusters',
    CarClusters = 'car-clusters',
    FaceClusterEvents = 'face-cluster-events',
    FaceClusterEventsInOut = 'face-cluster-events-in-out',
    BodyClusterEvents = 'body-cluster-events',
    CarClusterEvents = 'car-cluster-events',
    HumanCards = 'human-cards',
    CarCards = 'car-cards',
    KYC = 'kyc',
    Cameras = 'cameras',
    Counters = 'counters',
    CounterRecords = 'counter-records',
    Areas = 'areas',
    AreaActivations = 'area-activations',
    AreaActivationsInOut = 'area-activations-in-out',
    AreaRecords = 'area-records',
    AuditLogs = 'audit-logs',
    Lines = 'lines',
    Custom = 'custom',
    RemoteMonitoringEvents = 'remote-monitoring-events',
    None = 'none',
    Alerts = 'alerts'
}
