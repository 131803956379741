
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { AnnexAlarm, AnnexAlarmFilter } from '@/thirdpaties/annex/annex.types';
import { PageViewModel } from '@/store/application/page.view.model';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { annexWebsocketModule } from '@/store/ws/annex.websocket.module';
import AnnexAlarmItemTile from '@/pages/annex/alarms/AnnexAlarmItemTile.vue';
import AnnexAlarmItemRow from '@/pages/annex/alarms/AnnexAlarmItemRow.vue';
import CommonPage from '@/components/common/page/CommonPage.vue';
import { CommonPageState } from '@/components/common/page/CommonPageState';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { getAnnexAlarmFiltersBuilder } from '@/pages/annex/alarms/AnnexAlarmFilterBuilder';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { annexAlarmActionHandler } from '@/pages/annex/alarms/AnnexAlarmActionHandler';
import { watch } from 'vue';
import { WatchStopHandle } from '@vue/runtime-core';
import { timeOffsetCalculator } from '@/common/TimeOffsetCalculator';
import { dataModule } from '@/store/data';

@Options({
  components: { CommonPage }
})
export default class AnnexAlarmsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  commonPageState = new CommonPageState();
  disposeFilterWatcher: WatchStopHandle | null = null;

  get pageVM(): PageViewModel<AnnexAlarm, AnnexAlarmFilter> {
    return pageModule.getPageViewModel(this.$route);
  }

  get module(): ListViewModel<AnnexAlarm, AnnexAlarmFilter> {
    return this.pageVM.module;
  }

  get state(): PageState {
    return this.pageVM.state;
  }

  async mounted() {
    await dataModule.usersModule.firstLoadingPromise;
    autoUpdateHelper.addListInstance(this.module);

    this.commonPageState.module = this.module;
    this.commonPageState.pageSidebarType = MultisidebarItemTypes.AnnexAlarms;
    this.commonPageState.serializeToLocalStorageAsKey = 'annex_alarms_state';

    this.commonPageState.filter.show = true;
    this.commonPageState.filter.schemeSmall = getAnnexAlarmFiltersBuilder({ small: true });
    this.commonPageState.filter.schemeLarge = getAnnexAlarmFiltersBuilder({ small: false });
    this.commonPageState.filter.hasReport = true;

    this.commonPageState.showStatistics = true;
    this.commonPageState.sortTypes = dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
    this.commonPageState.displayTypeShow = true;

    this.commonPageState.playing.show = true;
    this.commonPageState.playing.module = this.module; // TODO: refactor this!

    annexWebsocketModule.onNewItemReceived = (item: AnnexAlarm) => {
      this.commonPageState.playing.addWebsocketItem(item);
    };

    this.commonPageState.componentForShort = Object.freeze(AnnexAlarmItemTile);
    this.commonPageState.componentForFull = Object.freeze(AnnexAlarmItemRow);
    this.commonPageState.actionHandler = annexAlarmActionHandler;

    this.disposeFilterWatcher = watch(
      this.module.filter,
      (v) => {
        this.state.filter = { ...v.current };
      },
      { deep: true }
    );

    timeOffsetCalculator.updateOnce();
  }

  beforeUnmount() {
    autoUpdateHelper.removeListInstance(this.module);
    if (this.disposeFilterWatcher) this.disposeFilterWatcher();
  }
}
