
import { defineComponent, inject } from 'vue';
import { bool, oneOfType, string } from 'vue-types';
import NIcon from '../icons/NIcon.vue';
import { NDropdownCommand, NDropdownCommandEventHandler } from './helpers/types';

export default defineComponent({
  name: 'NDropdownItem',
  props: {
    selected: bool().def(false),
    select: bool().def(false),
    mono: bool().def(false),
    hovered: bool().def(false),
    disabled: bool().def(false),
    expand: bool().def(false),
    command: oneOfType<NDropdownCommand>([String, Number, Object]),
    add: bool().def(false),
    color: string().def(''),
    icon: string().def('')
  },
  components: { NIcon },
  setup: () => ({
    dispatchCommandEvent: inject<NDropdownCommandEventHandler>('dispatchCommandEvent', () => {})
  }),
  methods: {
    handleClickEvent() {
      !this.disabled && this.dispatchCommandEvent(this.command);
    }
  },
  computed: {
    classes() {
      const root = 'n-dropdown-menu-item';
      return [
        root,
        {
          [`${root}_selected`]: this.selected,
          [`${root}_hovered`]: this.hovered,
          [`${root}_disabled`]: this.disabled,
          [`${root}_expand`]: this.expand,
          ['label-mono-m']: this.mono,
          ['control-m']: !this.mono
        }
      ];
    }
  }
});
