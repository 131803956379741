import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { configModule } from '@/store/config';

export function getCounterFilters(options: SimpleFilterOptions = {}) {
  const showProximity = configModule.getBoolean('counters.show_proximity', false);
  return [
    commonFilterSchemaModule.getNameContains(options),
    commonFilterSchemaModule.getId({
      ...options,
      i18n_label: 'counters.counter_id',
      i18n_placeholder: 'counters.enter_counter_id'
    }),
    commonFilterSchemaModule.getCameraGroups(options),
    commonFilterSchemaModule.getCamerasDependedOnCameraGroups(options),
    showProximity ? commonFilterSchemaModule.getDistanceType(options) : undefined,
    commonFilterSchemaModule.getCounterStatus(options)
  ].filter((v) => !!v);
}

export function getCounterRecordFilters(options: SimpleFilterOptions = {}, cameraItems: any = [], isWebhook = false) {
  const cameraOptions = cameraItems.length ? { ...options, items: cameraItems } : options;
  const bodiesEnabled = configModule.getBoolean('objects.bodies.enabled', false);
  const carsEnabled = configModule.getBoolean('objects.cars.enabled', false);
  const showProximity = configModule.getBoolean('counters.show_proximity', false);
  return [
    isWebhook ? commonFilterSchemaModule.getCameraGroups(options) : undefined,
    commonFilterSchemaModule.getCameras(cameraOptions),
    isWebhook ? undefined : commonFilterSchemaModule.getCreatedDateTimeRange(options),
    isWebhook ? undefined : commonFilterSchemaModule.getHasError(options),
    commonFilterSchemaModule.getCountFaces(options),
    bodiesEnabled ? commonFilterSchemaModule.getCountSilhouettes(options) : undefined,
    carsEnabled ? commonFilterSchemaModule.getCountCars(options) : undefined,
    isWebhook
      ? commonFilterSchemaModule.getId({ ...options, path: 'counter', i18n_label: 'counters.counter_id', i18n_placeholder: 'counters.enter_counter_id' })
      : commonFilterSchemaModule.getId({ ...options, i18n_label: 'counters.counter_record_id', i18n_placeholder: 'counters.enter_counter_record_id' }),
    bodiesEnabled && showProximity ? commonFilterSchemaModule.getMinimumDistance(options) : undefined,
    bodiesEnabled && showProximity ? commonFilterSchemaModule.getMaximumDistance(options) : undefined,
    bodiesEnabled && showProximity ? commonFilterSchemaModule.getAverageDistance(options) : undefined
  ].filter((v) => !!v);
}
