
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItem } from '@/store/multisidebar/types';
import NThemeImage from '@/uikit/image/NThemeImage.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';

@Options({
  name: 'MultisidebarContentNotFound',
  components: { MultisidebarHeader, NThemeImage }
})
export default class MultisidebarContentNotFound extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<any>>;

  actionHandler(action: ItemsActionName) {
    actionHandler.run(action, this.sidebarItem);
  }
}
