import { reactive } from 'vue';
import { ArrowTypes, ImageViewerActions } from '@/uikit/image-viewer/types';
import { PhotoViewerOptions, PhotoViewerSourceItem } from '@/components/photo-viewer/types';
import { convertSourcesToItems } from '@/components/photo-viewer/utils';

const DefaultActions = [ImageViewerActions.Zoom, ImageViewerActions.Copy, ImageViewerActions.Download, ImageViewerActions.Fullscreen, ImageViewerActions.Close] as const;

export class PhotoViewerController {
  options: PhotoViewerOptions = reactive({
    key: 1,
    activeItemIndex: 0,
    visible: false,
    itemsButton: true,
    arrowTypes: ArrowTypes.TopLeft,
    items: [],
    bboxes: [],
    actions: []
  });

  show(item: unknown[] | unknown, options: Partial<PhotoViewerOptions> = {}) {
    let sourceItems = (Array.isArray(item) ? item : [item]) as PhotoViewerSourceItem[];
    try {
      const items = convertSourcesToItems(sourceItems);
      this.options.actions = [...DefaultActions];
      Object.assign(this.options, { ...options, items, visible: true });
      items.forEach((i) => {
        if (i.actions !== undefined) this.options.actions = [...DefaultActions, ...i.actions];
      });
    } catch (e) {
      console.warn("[PhotoViewerController]: Can't generate $photoViewerController items. Reason: " + e.message);
    }
  }

  hide() {
    this.options.visible = false;
    this.options.activeItemIndex = 0;
  }
}

export const photoViewerController = new PhotoViewerController();
