import _ from 'lodash';
import { reactive } from 'vue';
import { UsersService } from '@/api';
import { FilterValue as _FilterValue } from '@/components/common/filter/manager/filter-manager/types';

export type FilterValue = _FilterValue;

export type Filter = {
  source: string;
  filters: FilterValue[];
};

export type UserSavedData = {
  key: string;
  value: string;
};

export class FilterManagerModule {
  static Name = 'FilterManagerModule';

  filters: Filter[] = [];
  loaded = false;

  getFiltersListBySource(source: string): Filter | undefined {
    return this.filters.find((item) => item.source === source);
  }

  async getUsersSavedData() {
    try {
      const userSavedData = await UsersService.usersMeDataList();
      if (userSavedData) {
        const filterItem = userSavedData.find((item) => item.key === 'filters');
        this.filters = JSON.parse(filterItem?.value ?? '');
      }
    } catch (e) {
      console.warn('[users.service]:getUsersSavedData error', e);
      throw new Error('[user saved data] load error');
    } finally {
      this.loaded = true;
    }
  }

  async getFilters() {
    try {
      const filtersItem = await UsersService.usersMeDataRetrieve('filters');
      this.filters = JSON.parse(filtersItem?.value) ?? '';
    } catch (e) {
      console.warn('[users.service]:getFilters error', e);
      throw new Error('[filters] load error');
    }
  }

  addFilter(filter: Filter) {
    const filtersListBySource = this.getFiltersListBySource(filter.source);
    !filtersListBySource ? this.filters.push(filter) : this.updateFilters(filter);
  }

  updateFilters(filter: Filter) {
    const filtersListBySource = this.getFiltersListBySource(filter.source);
    if (filtersListBySource) {
      filtersListBySource.filters = _.cloneDeep(filter.filters);
    }
  }

  async saveFilters() {
    try {
      await UsersService.usersMeDataUpdate('filters', { key: 'filters', value: JSON.stringify(this.filters) ?? '' });
    } catch (e) {
      console.warn('[users.service]:saveFilters error', e);
    }
  }

  async deleteSavedDataByKey(key: string) {
    try {
      await UsersService.usersMeDataDestroy(key);
    } catch (e) {
      console.warn('[users.service]:deleteFilters error', e);
    }
  }

  reset() {
    this.filters = [];
  }
}

export const filterManagerModule = reactive(new FilterManagerModule());
