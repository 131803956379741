export default {
  faces_age: 'age',
  faces_beard: 'beard',
  faces_beard__beard: 'beard',
  faces_beard__bristle: 'bristle',
  faces_beard__mustache: 'mustache',
  faces_beard__none: 'no beard',
  faces_emotions: 'emotions',
  faces_emotions__angry: 'anger',
  faces_emotions__disgust: 'disgust',
  faces_emotions__fear: 'fear',
  faces_emotions__happy: 'happiness',
  faces_emotions__neutral: 'neutral expression',
  faces_emotions__sad: 'sadness',
  faces_emotions__surprise: 'surprise',
  faces_gender: 'gender',
  faces_gender__female: 'woman',
  faces_gender__male: 'man',

  faces_eyes_attrs: 'Eyes',
  faces_eyes_attrs__opened: 'Eyes opened',
  faces_eyes_attrs__closed: 'Eyes closed',
  faces_eyes_attrs__occluded: 'Eyes occluded',

  faces_glasses: 'Glasses',
  faces_glasses__eye: 'Eyeglasses',
  faces_glasses__sun: 'Sunglasses',
  faces_glasses__other: 'Other glasses',
  faces_glasses__none: 'No glasses',

  faces_liveness: 'liveness',
  faces_liveness__fake: 'fake',
  faces_liveness__real: 'real',
  faces_look: 'look',
  faces_look__black: 'black',
  faces_look__white: 'white',
  faces_look__asian: 'asian',
  faces_look__indian: 'indian',
  faces_medmask: 'face mask',
  faces_medmask__correct: 'mask on',
  faces_medmask__incorrect: 'mask improperly worn',
  faces_medmask__none: 'no mask',
  faces_headpose_yaw: 'head turn',
  faces_headpose_pitch: 'head tilt',
  cars_body: 'vehicle body type',
  cars_body__cab: 'pickup',
  cars_body__convertible: 'convertible',
  cars_body__coupe: 'coupe',
  cars_body__crossover: 'crossover',
  cars_body__limousine: 'limousine',
  cars_body__minibus: 'van',
  cars_body__minivan: 'minivan',
  cars_body__sedan: 'sedan',
  cars_body__suv: 'suv',
  cars_body__wagon: 'shooting brake',
  cars_body__coming_soon: 'coming soon',
  cars_body__unknown: 'vehicle body type unknown',
  cars_color: 'vehicle body color',
  cars_color__unknown: 'vehicle body color unknown',
  cars_color__beige: 'beige',
  cars_color__black: 'black',
  cars_color__blue: 'blue',
  cars_color__brown: 'brown',
  cars_color__cyan: 'cyan',
  cars_color__gold: 'gold',
  cars_color__green: 'green',
  cars_color__grey: 'gray',
  cars_color__orange: 'orange',
  cars_color__pink: 'pink',
  cars_color__purple: 'purple',
  cars_color__red: 'red',
  cars_color__silver: 'silver',
  cars_color__violet: 'violet',
  cars_color__white: 'white',
  cars_color__yellow: 'yellow',
  cars_color__coming_soon: 'coming soon',
  cars_license_plate_country: 'country',
  cars_license_plate_number: 'license plate number',
  cars_license_plate_number__unknown: 'license plate number unknown',
  cars_license_plate_region: 'region',
  cars_license_plate_region__unknown: 'License plate region unknown',
  cars_license_plate_region__ADH: 'ADH (Abu Dhabi)',
  cars_license_plate_region__AJM: 'AJM (Ajman)',
  cars_license_plate_region__DXB: 'DXB (Dubai)',
  cars_license_plate_region__FUJ: 'FUJ (Fujairah)',
  cars_license_plate_region__RAK: 'RAK (Ras Al Khaimah)',
  cars_license_plate_region__SHJ: 'SHJ (Sharjah)',
  cars_license_plate_region__UAK: 'UAK (Umm Al Quwain)',
  'cars_license_plate_region__TH-10': 'TH-10 (Bangkok (officially – Krung Thep Maha Nakhon))',
  'cars_license_plate_region__TH-11': 'TH-11 (Samut Prakan)',
  'cars_license_plate_region__TH-12': 'TH-12 (Nonthaburi)',
  'cars_license_plate_region__TH-13': 'TH-13 (Pathum Thani)',
  'cars_license_plate_region__TH-14': 'TH-14 (Phra Nakhon Si Ayutthaya)',
  'cars_license_plate_region__TH-15': 'TH-15 (Ang Thong)',
  'cars_license_plate_region__TH-16': 'TH-16 (Lop Buri)',
  'cars_license_plate_region__TH-17': 'TH-17 (Sing Buri)',
  'cars_license_plate_region__TH-18': 'TH-18 (Chai Nat)',
  'cars_license_plate_region__TH-19': 'TH-19 (Saraburi)',
  'cars_license_plate_region__TH-20': 'TH-20 (Chon Buri)',
  'cars_license_plate_region__TH-21': 'TH-21 (Rayong)',
  'cars_license_plate_region__TH-22': 'TH-22 (Chanthaburi)',
  'cars_license_plate_region__TH-23': 'TH-23 (Trat)',
  'cars_license_plate_region__TH-24': 'TH-24 (Chachoengsao)',
  'cars_license_plate_region__TH-25': 'TH-25 (Prachin Buri)',
  'cars_license_plate_region__TH-26': 'TH-26 (Nakhon Nayok)',
  'cars_license_plate_region__TH-27': 'TH-27 (Sa Kaeo)',
  'cars_license_plate_region__TH-30': 'TH-30 (Nakhon Ratchasima)',
  'cars_license_plate_region__TH-31': 'TH-31 (Buri Ram)',
  'cars_license_plate_region__TH-32': 'TH-32 (Surin)',
  'cars_license_plate_region__TH-33': 'TH-33 (Si Sa Ket)',
  'cars_license_plate_region__TH-34': 'TH-34 (Ubon Ratchathani)',
  'cars_license_plate_region__TH-35': 'TH-35 (Yasothon)',
  'cars_license_plate_region__TH-36': 'TH-36 (Chaiyaphum)',
  'cars_license_plate_region__TH-37': 'TH-37 (Amnat Charoen)',
  'cars_license_plate_region__TH-38': 'TH-38 (Bueng Kan)',
  'cars_license_plate_region__TH-39': 'TH-39 (Nong Bua Lam Phu)',
  'cars_license_plate_region__TH-40': 'TH-40 (Khon Kaen)',
  'cars_license_plate_region__TH-41': 'TH-41 (Udon Thani)',
  'cars_license_plate_region__TH-42': 'TH-42 (Loei)',
  'cars_license_plate_region__TH-43': 'TH-43 (Nong Khai)',
  'cars_license_plate_region__TH-44': 'TH-44 (Maha Sarakham)',
  'cars_license_plate_region__TH-45': 'TH-45 (Roi Et)',
  'cars_license_plate_region__TH-46': 'TH-46 (Kalasin)',
  'cars_license_plate_region__TH-47': 'TH-47 (Sakon Nakhon)',
  'cars_license_plate_region__TH-48': 'TH-48 (Nakhon Phanom)',
  'cars_license_plate_region__TH-49': 'TH-49 (Mukdahan)',
  'cars_license_plate_region__TH-50': 'TH-50 (Chiang Mai)',
  'cars_license_plate_region__TH-51': 'TH-51 (Lamphun)',
  'cars_license_plate_region__TH-52': 'TH-52 (Lampang)',
  'cars_license_plate_region__TH-53': 'TH-53 (Uttaradit)',
  'cars_license_plate_region__TH-54': 'TH-54 (Phrae)',
  'cars_license_plate_region__TH-55': 'TH-55 (Nan)',
  'cars_license_plate_region__TH-56': 'TH-56 (Phayao)',
  'cars_license_plate_region__TH-57': 'TH-57 (Chiang Rai)',
  'cars_license_plate_region__TH-58': 'TH-58 (Mae Hong Son)',
  'cars_license_plate_region__TH-60': 'TH-60 (Nakhon Sawan)',
  'cars_license_plate_region__TH-61': 'TH-61 (Uthai Thani)',
  'cars_license_plate_region__TH-62': 'TH-62 (Kamphaeng Phet)',
  'cars_license_plate_region__TH-63': 'TH-63 (Tak)',
  'cars_license_plate_region__TH-64': 'TH-64 (Sukhothai)',
  'cars_license_plate_region__TH-65': 'TH-65 (Phitsanulok)',
  'cars_license_plate_region__TH-66': 'TH-66 (Phichit)',
  'cars_license_plate_region__TH-67': 'TH-67 (Phetchabun)',
  'cars_license_plate_region__TH-70': 'TH-70 (Ratchaburi)',
  'cars_license_plate_region__TH-71': 'TH-71 (Kanchanaburi)',
  'cars_license_plate_region__TH-72': 'TH-72 (Suphan Buri)',
  'cars_license_plate_region__TH-73': 'TH-73 (Nakhon Pathom)',
  'cars_license_plate_region__TH-74': 'TH-74 (Samut Sakhon)',
  'cars_license_plate_region__TH-75': 'TH-75 (Samut Songkhram)',
  'cars_license_plate_region__TH-76': 'TH-76 (Phetchaburi)',
  'cars_license_plate_region__TH-77': 'TH-77 (Prachuap Khiri Khan)',
  'cars_license_plate_region__TH-80': 'TH-80 (Nakhon Si Thammarat)',
  'cars_license_plate_region__TH-81': 'TH-81 (Krabi)',
  'cars_license_plate_region__TH-82': 'TH-82 (Phangnga)',
  'cars_license_plate_region__TH-83': 'TH-83 (Phuket)',
  'cars_license_plate_region__TH-84': 'TH-84 (Surat Thani)',
  'cars_license_plate_region__TH-85': 'TH-85 (Ranong)',
  'cars_license_plate_region__TH-86': 'TH-86 (Chumphon)',
  'cars_license_plate_region__TH-90': 'TH-90 (Songkhla)',
  'cars_license_plate_region__TH-91': 'TH-91 (Satun)',
  'cars_license_plate_region__TH-92': 'TH-92 (Trang)',
  'cars_license_plate_region__TH-93': 'TH-93 (Phatthalung)',
  'cars_license_plate_region__TH-94': 'TH-94 (Pattani)',
  'cars_license_plate_region__TH-95': 'TH-95 (Yala)',
  'cars_license_plate_region__TH-96': 'TH-96 (Narathiwat)',
  cars_make: 'make',
  cars_make__unknown: 'car make unknown',
  cars_model: 'model',
  cars_model__unknown: 'car model unknown',
  cars_special_vehicle_type: 'special vehicle',
  cars_special_vehicle_type__unknown: 'vehicle special use unknown',
  cars_special_vehicle_type__police: 'Police (incl. traffic police)',
  cars_special_vehicle_type__ambulance: 'Ambulance (incl. mobile ICU)',
  cars_special_vehicle_type__rescue_service: 'Fire service and EMERCOM vehicles',
  cars_special_vehicle_type__taxi: 'Taxi (incl. cargo taxi)',
  cars_special_vehicle_type__not_special: 'Not special',
  cars_special_vehicle_type__military: 'Military (incl. National Guard)',
  cars_special_vehicle_type__road_service: 'Municipal vehicles (incl. Center for the Organization of Road Traffic)',
  cars_special_vehicle_type__other_special: 'Other special',
  cars_special_vehicle_type__route_transport: 'Public road transport',
  cars_special_vehicle_type__car_sharing: 'Carsharing',
  cars_special_vehicle_type__gas_service: 'Gas rescue and emergency services',
  cars_special_vehicle_type__cash_in_transit: 'Cash-in-transit',
  cars_category: 'Vehicle category',
  cars_category__A: 'Motorcycle, scooter, ATV',
  cars_category__B: 'Car',
  cars_category__BE: 'Car with a trailer',
  cars_category__C: 'Truck',
  cars_category__CE: 'Truck with a trailer',
  cars_category__D: 'Bus',
  cars_category__DE: 'Articulated bus',
  cars_category__other: 'Other category vehicle',
  cars_category__unknown: 'Vehicle category unknown',
  cars_weight_type: 'Vehicle weight and body size',
  cars_weight_type__unknown: 'Vehicle weight and body size unknown',
  cars_weight_type__B_light: 'Cars under 3.5 tons',
  cars_weight_type__B_heavy: 'Trucks under 3.5 tons',
  cars_weight_type__C_light: 'Trucks under 3.5-12 tons',
  cars_weight_type__C_heavy: 'Trucks over 12 tons',
  cars_weight_type__D_light: 'Single-deck buses',
  cars_weight_type__D_long: 'Articulated buses',
  cars_weight_type__other: 'Other weight class vehicle',
  cars_orientation: 'Vehicle orientation',
  cars_orientation__unknown: 'Vehicle orientation unknown',
  cars_orientation__front: 'Front',
  cars_orientation__back: 'Rear',
  cars_orientation__side: 'Side',
  bodies_bottom_color: 'lower clothes color',
  bodies_bottom_color__beige: 'beige',
  bodies_bottom_color__black: 'black',
  bodies_bottom_color__blue: 'blue',
  bodies_bottom_color__brown: 'brown',
  bodies_bottom_color__green: 'green',
  bodies_bottom_color__grey: 'gray',
  bodies_bottom_color__lightblue: 'light blue',
  bodies_bottom_color__orange: 'orange',
  bodies_bottom_color__pink: 'pink',
  bodies_bottom_color__purple: 'purple',
  bodies_bottom_color__red: 'red',
  bodies_bottom_color__white: 'white',
  bodies_bottom_color__yellow: 'yellow',
  bodies_bottom_color__violet: 'violet',
  bodies_top_color: 'upper clothes color',
  bodies_top_color__beige: 'beige',
  bodies_top_color__black: 'black',
  bodies_top_color__blue: 'blue',
  bodies_top_color__brown: 'brown',
  bodies_top_color__green: 'green',
  bodies_top_color__grey: 'gray',
  bodies_top_color__lightblue: 'light blue',
  bodies_top_color__orange: 'orange',
  bodies_top_color__pink: 'pink',
  bodies_top_color__purple: 'purple',
  bodies_top_color__red: 'red',
  bodies_top_color__white: 'white',
  bodies_top_color__yellow: 'yellow',
  bodies_top_color__violet: 'violet',
  bodies_detailed_upper_clothes: 'upper clothes type',
  bodies_detailed_upper_clothes__jacket: 'jacket',
  bodies_detailed_upper_clothes__coat: 'coat',
  bodies_detailed_upper_clothes__sleeveless: 'sleeveless',
  bodies_detailed_upper_clothes__sweatshirt: 'sweatshirt',
  'bodies_detailed_upper_clothes__t-shirt': 't-shirt',
  bodies_detailed_upper_clothes__shirt: 'shirt',
  bodies_detailed_upper_clothes__dress: 'dress',
  bodies_headwear: 'headwear',
  bodies_headwear__hat: 'hat, cap',
  bodies_headwear__none: 'none',
  bodies_headwear__hood: 'hood, scarf',
  bodies_lower_clothes: 'lower body clothes',
  bodies_lower_clothes__pants: 'pants',
  bodies_lower_clothes__obscured: 'nondescript',
  bodies_lower_clothes__skirt: 'skirt',
  bodies_lower_clothes__shorts: 'shorts',
  bodies_upper_clothes: 'upper body clothes',
  bodies_upper_clothes__long_sleeves: 'long sleeves',
  bodies_upper_clothes__short_sleeves: 'short sleeves',
  bodies_upper_clothes__without_sleeves: 'no sleeves',
  bodies_vest_type: 'vest',
  'bodies_vest_type__green/yellow': 'green/yellow',
  bodies_vest_type__orange: 'orange',
  bodies_vest_type__none: 'none',
  bodies_vest_type__not_visible: 'not visible',
  bodies_vest_type_score: 'vest score',
  bodies_helmet_type: 'helmet',
  bodies_helmet_type__white: 'white',
  'bodies_helmet_type__red/orange': 'red/orange',
  bodies_helmet_type__other: 'other',
  bodies_helmet_type__none: 'none',
  bodies_helmet_type__not_visible: 'not visible',
  bodies_helmet_type_score: 'helmet score',
  bodies_bag_back: 'bag on the back',
  bodies_bag_back__back: 'Yes',
  bodies_bag_back__none: 'None',
  bodies_bag_hand: 'bag in hand',
  bodies_bag_hand__hand: 'Yes',
  bodies_bag_hand__none: 'None',
  bodies_bag_ground: 'bag on the floor',
  bodies_bag_ground__ground: 'Yes',
  bodies_bag_ground__none: 'None',
  bodies_gender: 'gender by body',
  bodies_gender__male: 'man',
  bodies_gender__female: 'woman',
  bodies_age_group: 'age by body',
  'bodies_age_group__0-16': '0-16 years',
  'bodies_age_group__17-35': '17-35 years',
  'bodies_age_group__36-50': '36-50 years',
  'bodies_age_group__50+': '50+ years',

  bodies_handface_smoking: 'smoking',
  bodies_handface_smoking__none: 'None',
  bodies_handface_smoking__smoking: 'Yes',

  bodies_handface_phone_call: 'phone talk',
  bodies_handface_phone_call__none: 'None',
  bodies_handface_phone_call__phone_call: 'Yes',

  bodies_handface_phone_use: 'phone usage',
  bodies_handface_phone_use__none: 'None',
  bodies_handface_phone_use__phone_use: 'Yes',

  bodies_fall: 'fall',
  bodies_fall__none: 'None',
  bodies_fall__fall: 'Yes',

  cars_license_plate_country__unknown: 'License plate country unknown',
  cars_license_plate_country__RU: 'RUS (Russia)',
  cars_license_plate_country__AE: 'UAE (UAE)',
  cars_license_plate_country__KZ: 'KZ (Kazakhstan)',
  cars_license_plate_country__GE: 'GE (Georgia)',
  cars_license_plate_country__SA: 'KSA (Saudi Arabia)',
  cars_license_plate_country__VN: 'VN (Vietnam)',
  cars_license_plate_country__BY: 'BY (Belarus)',
  cars_license_plate_country__UA: 'UA (Ukraine)',
  cars_license_plate_country__AM: 'AM (Armenia)',
  cars_license_plate_country__KG: 'KG (Kyrgyzstan)',
  cars_license_plate_country__UZ: 'UZ (Uzbekistan)',
  cars_license_plate_country__BR: 'BR (Brazil)',
  cars_license_plate_country__IN: 'IND (India)',
  cars_license_plate_country__AZ: 'AZ (Azerbaijan)',
  cars_license_plate_country__EST: 'EST (Estonia)',
  cars_license_plate_country__FIN: 'FIN (Finland)',
  cars_license_plate_country__LT: 'LT (Lithuania)',
  cars_license_plate_country__LV: 'LV (Latvia)',
  cars_license_plate_country__MD: 'MD (Moldova)',
  cars_license_plate_country__MX: 'MEX (Mexico)',
  cars_license_plate_country__AR: 'RA (Argentina)',
  cars_license_plate_country__SRB: 'SRB (Serbia)',
  cars_license_plate_country__TJ: 'TJ (Tajikistan)',
  cars_license_plate_country__TM: 'TM (Turkmenistan)',
  cars_license_plate_country__Country_1: 'unknown',
  cars_license_plate_country__Country_2: 'unknown',
  cars_license_plate_country__Country_3: 'unknown',
  cars_license_plate_country__Country_4: 'unknown',
  cars_license_plate_country__Country_5: 'unknown',
  cars_license_plate_country__TH: 'T (Thailand)',
  cars_license_plate_country__CZ: 'CZ (Czech Republic)',
  cars_license_plate_country__PK: 'PK (Pakistan)',
  cars_license_plate_country__CN: 'CN (China)',
  cars_license_plate_country__EG: 'ET (Egypt)',
  cars_license_plate_country__JP: 'J (Japan)',
  cars_license_plate_country__MN: 'MGL (Mongolia)',
  cars_license_plate_country__MA: 'MA (Morocco)',
  cars_license_plate_country__PS: 'PS (Palestine)',
  cars_license_plate_country__SG: 'SGP (Singapore)',
  cars_license_plate_country__KR: 'ROK (South Korea)',
  cars_license_plate_country__BH: 'BRN (Bahrain)',
  cars_license_plate_country__CO: 'CO (Colombia)',
  cars_license_plate_country__PE: 'PE (Peru)',
  cars_license_plate_country__QA: 'Q (Qatar)',
  cars_license_plate_country__TN: 'TN (Tunisia)',
  cars_license_plate_country__TR: 'TR (Türkiye)',
  cars_license_plate_number_color: 'license plate color',
  cars_license_plate_number_color__white: 'License plate white',
  cars_license_plate_number_color__yellow: 'License plate yellow',
  cars_license_plate_number_color__blue: 'License plate blue',
  cars_license_plate_number_color__green: 'License plate green',
  cars_license_plate_number_color__grey: 'License plate grey',
  cars_license_plate_number_color__black: 'License plate black',
  cars_license_plate_number_color__unknown: 'License plate color unknown'
};
