import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';

export default class ImageConverter {
  readonly convertableMimeTypes = ['', 'image/heic', 'image/heic-sequence', 'image/heif'];
  convertationInProgress = false;

  async tryConvert(image: Blob) {
    if (!this.convertableMimeTypes.includes(image.type)) return image;
    this.convertationInProgress = true;

    const imageKey = 'image';
    const formData = new FormData();
    formData.append(imageKey, image);
    formData.append('conversion_meta', JSON.stringify([{ key: imageKey, to: 'jpeg' }]));

    try {
      const url = (configModule.config.server?.url ?? '/') + 'convert/';
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: { Authorization: 'Token ' + encodeURIComponent(authModule.token!.toString()) }
      });

      const responseFormData = await response.formData();
      const convertedImage = responseFormData.get(imageKey);
      return (convertedImage as File) || image;
    } finally {
      this.convertationInProgress = false;
    }
  }
}
