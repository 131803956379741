
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import ScrollHorizontally from '@/uikit/directives/scroll-horizontally';
import NIcon from '@/uikit/icons/NIcon.vue';
import { WorkspaceTab } from './types';

@Options({
  name: 'WorkspaceTabs',
  components: { NButton, NIcon },
  directives: { ScrollHorizontally },
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
    autoHiddenCloseIconWidth: {
      type: Number,
      default: 120
    },
    insertToAfter: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    isMousedownActive: {
      type: Boolean,
      default: true
    },
    newLabel: {
      type: String,
      default: 'New Tool'
    }
  }
})
export default class WorkspaceTabs extends Vue {
  @Prop({ type: String, default: '' })
  readonly modelValue!: string;

  @Prop({ type: Array, default: () => [] })
  readonly items!: WorkspaceTab[];

  @Prop({ type: Number, default: 40 })
  readonly minWidth!: number;

  @Prop({ type: Number, default: 245 })
  readonly maxWidth!: number;

  @Prop({ type: Number, default: 7 })
  readonly gap!: number;

  @Prop({ type: Number, default: 21 })
  readonly tabCloseWidth!: number;

  @Watch('items')
  itemsChangeHandler(v: WorkspaceTab[], p: WorkspaceTab[] | undefined) {
    if (v?.length != p?.length) {
      this.$nextTick(() => {
        this.calcTabWidth();
      });
    }
  }

  @Watch('modelValue')
  modelValueWatcher() {
    this.$nextTick(() => {
      const isLastTab = this.computedTabList.length && this.computedTabList[this.computedTabList.length - 1].tab === this.modelValue;
      const selector = isLastTab ? '.tabs-after' : '.tabs-item.active';
      const tabItem = (this.$refs.content as HTMLElement).querySelector(selector);
      if (tabItem) {
        tabItem.scrollIntoView({ block: 'center' });
      }
    });
  }

  tabWidth = 0;
  timer = 0;
  workspaceButtonMarginLeft = 37;

  // dragging
  dragTab: WorkspaceTab | null = null;
  dragOldLeft = 0;
  dragNewLeft = 0;
  dragOldX = 0;
  dragNewX = 0;

  animateTabKey = '';
  animateTabKey2 = '';
  animateTabLeft = 0;

  removeTab(key: string) {
    const index = this.items.findIndex((tab) => tab.tab === key);
    this.handleDelete(index);
  }

  get computedTabList() {
    return this.tabWidth
      ? this.items.map((tab, i) => {
          const calcLeft = this.tabWidth * i;
          const left1 = tab.tab === this.dragTab?.tab ? this.dragNewLeft : calcLeft;
          const left2 = tab.tab == this.animateTabKey ? this.animateTabLeft : left1;
          return { ...tab, left: left2 };
        })
      : [];
  }

  calcTabWidth() {
    const { items, maxWidth, minWidth } = this;
    const contentEl = this.$refs.content as HTMLElement;
    const afterEl = this.$refs.after as HTMLElement;
    if (!contentEl) return Math.max(maxWidth, minWidth);
    const contentWidth = contentEl.clientWidth - this.workspaceButtonMarginLeft - afterEl.clientWidth;
    let width = contentWidth / items.length;
    if (width > maxWidth) width = Math.trunc(maxWidth);
    if (width < minWidth) width = Math.trunc(minWidth);

    this.tabWidth = Math.trunc(width);

    if (items.length * this.tabWidth > contentWidth) {
      contentEl.classList.add('tabs-content_has-scroll');
      afterEl.style.left = contentEl.clientWidth + 'px';
    } else {
      contentEl.classList.remove('tabs-content_has-scroll');
      afterEl.style.left = items.length * this.tabWidth + 'px';
    }
    this.$emit('afterLeft', parseInt(afterEl.style.left));
  }

  mounted() {
    this.calcTabWidth();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  handleResize(e: any) {
    this.timer && window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.calcTabWidth();
    }, 100);
  }

  handleDelete(i: number) {
    this.$emit('removeTab', i);
  }

  handleClick(e: any, tab: WorkspaceTab, i: number) {
    this.$emit('update:modelValue', tab.tab);
  }

  handleMenu(e: any, tab: WorkspaceTab, index: number) {
    this.$emit('contextmenu', e, tab, index);
  }

  handleMouseDown(e: any, tab: WorkspaceTab, i: number) {
    if (tab.dragable === false) {
      return;
    }

    this.dragTab = tab;
    this.dragOldLeft = tab.left!;
    this.dragNewLeft = tab.left!;
    this.dragOldX = e.clientX;
    this.dragNewX = e.clientX;

    this.animateTabKey2 = tab.tab;
  }

  handleMouseMove(e: any) {
    if (!this.dragTab) return;

    const tab = this.dragTab;

    this.dragNewX = e.clientX;
    let dx = this.dragNewX - this.dragOldX;
    if (Math.abs(dx) < 8) dx = 0;
    this.dragNewLeft = this.dragOldLeft + dx;

    const { instance, targetTab } = this.getInstanceAt(this.dragTab, this.dragNewLeft);
    if (instance) {
      this.swapTab(tab, targetTab);
    }
  }

  handleMouseUp() {
    this.dragTab = null;
    this.animateTabKey2 = '';
  }

  canShowTabClose(tab: WorkspaceTab) {
    return tab.closeable !== false;
  }

  getInstanceAt(tab: WorkspaceTab, x: number) {
    const halfWidth = (this.tabWidth - this.gap) / 2;
    const tabs = this.computedTabList;
    for (let i = 0; i < tabs.length; i++) {
      const targetX = tabs[i].left - 1;
      if (tab.tab === tabs[i].tab) continue;
      // in range
      if (targetX <= x && x < targetX + halfWidth / 2) {
        // before range
        return {
          direction: 'left',
          instance: true,
          targetTab: tabs[i]
        };
      } else if (targetX + halfWidth / 2 <= x && x < targetX + halfWidth) {
        // after range
        return {
          direction: 'right',
          instance: true,
          targetTab: tabs[i]
        };
      }
    }
    return {
      direction: null,
      instance: null,
      targetTab: tab
    };
  }

  /**
   * swap tab
   * @param tab, current tab
   * @param swapTab, target tab
   */
  swapTab(tab: WorkspaceTab, swapTab: WorkspaceTab) {
    const { items } = this;
    if (swapTab.swappable === false) {
      return;
    }
    let index = 0;
    let targetIndex = 0;
    for (let i = 0; i < items.length; i++) {
      if (tab.tab === items[i].tab) {
        index = i;
      }
      if (swapTab.tab === items[i].tab) {
        targetIndex = i;
      }
    }

    // swap items array index
    if (index !== targetIndex) {
      [items[index], items[targetIndex]] = [items[targetIndex], items[index]];
      this.$emit('swapTabs', [index, targetIndex]);
    }
  }
}
