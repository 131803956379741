import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "case-cluster-item-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NThumbnail, {
      size: "small",
      modelValue: _ctx.thumbnail,
      canClose: _ctx.canClose,
      corners: _ctx.isParticipant ? 'circle' : 'rounded',
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.$emit('action', v))
    }, null, 8, ["modelValue", "canClose", "corners"])
  ]))
}