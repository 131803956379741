/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { str_int_float_bool_object_array_null } from './str_int_float_bool_object_array_null';
export type CaseEvent = {
    readonly id: number;
    /**
     * Case event creation date
     */
    readonly created_date: string;
    /**
     * Case event modification date
     */
    readonly modified_date: string;
    /**
     * Case event's video archive
     */
    readonly video_archive: number;
    readonly features: Record<string, str_int_float_bool_object_array_null>;
    /**
     * Case event face bbox
     */
    readonly bbox: Array<number>;
    /**
     * Event score; Used during clusterization
     */
    readonly score: number;
    /**
     * Cluster event thumbnail
     */
    readonly thumbnail: string;
    /**
     * Cluster event full frame
     */
    readonly fullframe: string;
    /**
     * End date of the related episode
     */
    readonly episode_last_event_date: string;
    /**
     * Bbox of the episode last event
     */
    readonly episode_last_event_bbox: Array<number>;
    /**
     * Thumbnail of the episode last event
     */
    readonly episode_last_event_thumbnail: string;
    /**
     * Full frame of the episode last event
     */
    readonly episode_last_event_fullframe: string;
    /**
     * Related episode
     */
    readonly episode: number;
    /**
     * Related case cluster
     */
    readonly case_cluster: number;
    /**
     * Related case
     */
    readonly case: number;
};
export const EmptyCaseEvent: CaseEvent = {
    id: 0,
        /**
        * Case event creation date
        */
    created_date: '',
        /**
        * Case event modification date
        */
    modified_date: '',
        /**
        * Case event's video archive
        */
    video_archive: 0,
    features: {},
        /**
        * Case event face bbox
        */
    bbox: [],
        /**
        * Event score; Used during clusterization
        */
    score: 0,
        /**
        * Cluster event thumbnail
        */
    thumbnail: '',
        /**
        * Cluster event full frame
        */
    fullframe: '',
        /**
        * End date of the related episode
        */
    episode_last_event_date: '',
        /**
        * Bbox of the episode last event
        */
    episode_last_event_bbox: [],
        /**
        * Thumbnail of the episode last event
        */
    episode_last_event_thumbnail: '',
        /**
        * Full frame of the episode last event
        */
    episode_last_event_fullframe: '',
        /**
        * Related episode
        */
    episode: 0,
        /**
        * Related case cluster
        */
    case_cluster: 0,
        /**
        * Related case
        */
    case: 0,
};