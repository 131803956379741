export const colorsConfig = {
  default: {
    new: '#FF0000',
    default: '#CC0000',
    old: '#660000'
  },
  colors: {
    blue: '#0000ff',
    yellow: '#ffff00',
    red: '#ff0000',
    coral: '#ff7f50',
    magenta: '#ff00ff',
    chartreuse: '#7fff00',
    aqua: '#00ffff',
    dodgerBlue: '#1e90ff'
  }
};
