
import { h } from '@vue/runtime-core';
import { Options, Vue } from 'vue-class-component';
import { TypeEnum } from '@/api';
import { dataServiceRepository } from '@/api/common';
import ReportNotification from './ReportNotification.vue';
import {
  camerasInSelectSchemaItem,
  camerasOutSelectSchemaItem,
  dataTypeSelectSchemaItem,
  nameInputSchemaItem,
  picturesSelectSchemaItem,
  reportFormatCheckboxGroupSchemaItem,
  reportTypeSelectSchemaItem
} from './reports-form-schema';
import NButton from '@/uikit/buttons/NButton.vue';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
import notify from '@/uikit/notification/helpers/notification';
import { ReportFormModelType } from './ReportsFormController';

@Options({
  name: 'ReportsForm',
  components: {
    NButton,
    NForm,
    NModalWindow,
    ReportNotification
  }
})
export default class ReportsForm extends Vue {
  private type: TypeEnum | undefined;
  private creating = false;

  created() {
    this.type = this.model.type;
  }

  get model(): ReportFormModelType {
    return this.$reportsForm.model;
  }

  get layout(): IFormLayout {
    if (this.type === TypeEnum.FaceEvents) {
      if (this.model.type === TypeEnum.FaceEventsInOut)
        return [
          nameInputSchemaItem(),
          reportFormatCheckboxGroupSchemaItem(),
          reportTypeSelectSchemaItem(TypeEnum.FaceEvents),
          picturesSelectSchemaItem(),
          camerasInSelectSchemaItem(this.model.meta?.cameras_out && Number(this.model.meta.cameras_out)),
          camerasOutSelectSchemaItem(this.model.meta?.cameras_in && Number(this.model.meta.cameras_in))
        ];
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), reportTypeSelectSchemaItem(TypeEnum.FaceEvents), picturesSelectSchemaItem()];
    }
    if (this.type === TypeEnum.AuditLogs || this.type === TypeEnum.Lines) {
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem()];
    }
    if (this.type === TypeEnum.KYC) {
      if (this.model.type === TypeEnum.FaceClusterEvents) {
        return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), picturesSelectSchemaItem(), dataTypeSelectSchemaItem()];
      }
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), dataTypeSelectSchemaItem()];
    }
    if (this.type === TypeEnum.RemoteMonitoringEvents) {
      return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem()];
    }

    return [nameInputSchemaItem(), reportFormatCheckboxGroupSchemaItem(), picturesSelectSchemaItem()];
  }

  get size() {
    return NModalWindowSize.Medium;
  }

  getIsFormValid(): boolean {
    const result = this.$refs.infoForm ? this.$refs.infoForm.validate() : true;
    if (!result) {
      this.$refs.infoForm.displayErrors();
    }
    return result;
  }

  async createHandler() {
    if (!this.getIsFormValid()) return;
    try {
      this.creating = true;
      await dataServiceRepository.ReportsService.create(this.model);
      await notify({ content: () => h(ReportNotification), showClose: true });
      await this.closeHandler();
    } catch (err) {
      await notify({ content: 'Error', showClose: true, type: 'error' });
    } finally {
      this.creating = false;
    }
  }

  closeHandler() {
    this.$reportsForm.hide();
  }
}
