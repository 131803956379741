
import { merge } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { Camera, CamerasService } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ModelAclResult } from '@/store/acl/types';
import { dataModule } from '@/store/data';
import { actionHandler, convertToMsbItem } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { websocketModule } from '@/store/ws/websocket.module';
import { Action } from '@/uikit';
import { NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import { createIdTableSelectionColumn } from '@/uikit/table-v2/utils';
import NText from '@/uikit/text/NText.vue';
import ActionsDropdown from '@/components/common/ActionsDropdown.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import { CameraStatus } from '@/pages/data-sources/forms/components';

@Options({
  name: 'DataSourcesCamerasTable',
  components: { NTable },
  emits: ['select']
})
export default class DataSourcesCamerasTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly items!: Camera[];

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: number[];

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  createIdTableSelectionColumn = createIdTableSelectionColumn;

  get isExternalDetector() {
    return this.items[0]?.external_detector;
  }

  get isPlayEnabled() {
    return !this.isExternalDetector;
  }

  get columns(): NTableColumn<Camera>[] {
    const baseColumns: NTableColumn<Camera>[] = [
      {
        width: 'max-content',
        head: this.$t('ds.id'),
        body: ({ model }) => model.id
      },
      {
        width: 120,
        head: this.$t('ds.image'),
        body: ({ model }) => {
          const props = { width: 100, displayWidth: '100px', modelValue: model.screenshot, hasPlay: this.isPlayEnabled };
          return <CameraScreenshot {...props} />;
        }
      },
      {
        width: 'minmax(240px, 1fr)',
        head: this.$t('ds.name'),
        body: ({ model }) => {
          const nameProps = {
            modelValue: model.name,
            class: 'datasource-camera-table__description-name',
            onClick: this.handleNameClick.bind(this, model)
          };
          const groupProps = { modelValue: dataModule.cameraGroupsModule.items.find((v) => v.id === model.group)?.name || '' };
          return (
            <div class="datasource-camera-table__description">
              <NText {...nameProps} />
              <NText {...groupProps} />
            </div>
          );
        }
      }
    ];

    const statusColumn: NTableColumn<Camera> = {
      width: 250,
      head: this.$t('ds.status'),
      body: ({ model }) => {
        const dropdownProps = {
          actions: this.getActions(model),
          placement: 'left-start',
          onAction: (action: ItemsActionName) => this.actionHandler(action, model)
        };
        return (
          <div class="datasource-camera-table__status-cell">
            <CameraStatus camera={model} />
            <ActionsDropdown class="datasource-camera-table__actions" {...dropdownProps} />
          </div>
        );
      }
    };

    const actionsOnlyColumn: NTableColumn<Camera> = {
      width: 250,
      head: '',
      body: ({ model }) => {
        const dropdownProps = {
          actions: this.getActions(model),
          placement: 'left-start',
          onAction: (action: ItemsActionName) => this.actionHandler(action, model)
        };
        return (
          <div class="datasource-camera-table__actions-cell">
            <ActionsDropdown class="datasource-camera-table__actions" {...dropdownProps} />
          </div>
        );
      }
    };

    return [...baseColumns, this.isExternalDetector ? actionsOnlyColumn : statusColumn];
  }

  get websocketModule() {
    return websocketModule;
  }

  @Watch('websocketModule.cameraUpdated')
  updateCamera(updatedItem: Camera) {
    const item = this.items.find((v) => v.id === updatedItem.id) as Camera;
    if (item) {
      merge(item, updatedItem);
      autoUpdateHelper.updateHandler('/cameras/', item);
    }
  }

  handleNameClick(model: Camera) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Cameras, rawItem: model });
  }

  dispatchSelect(selected: number[]) {
    this.$emit('select', selected);
  }

  async actionHandler(v: ItemsActionName, item: Camera) {
    const requiredSaveActionItems: ItemsActionName[] = [
      ItemsActionNames.CameraResetAll,
      ItemsActionNames.CameraResetFull,
      ItemsActionNames.CameraResetAdvanced,
      ItemsActionNames.CameraResetMap,
      ItemsActionNames.CameraResetDetectors,
      ItemsActionNames.CameraResetZone
    ];
    const executeSaveAction = requiredSaveActionItems.includes(v);

    if (v === ItemsActionNames.Restart) {
      item && CamerasService.camerasRestartCreate(item.id);
    } else {
      const payload = await convertToMsbItem({ type: MultisidebarItemTypes.Cameras, rawItem: item });
      await actionHandler.run(v, payload);
      if (executeSaveAction) await actionHandler.run(ItemsActionNames.Save, payload);
    }
  }

  getActions(item: Camera): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasActive: true,
        currentActive: item.active,
        hasRestart: !this.isExternalDetector,
        hasDelete: true,
        hasCameraReset: !this.isExternalDetector
      })
      .map(actionsModule.computeActionByName);
  }
}
