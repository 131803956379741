
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import { viewModelRepository } from '@/api/common';
import { HumanCard } from '@/api';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail';
import { configModule } from '@/store/config';
import { EventOpenedItems } from '@/components/events/types';
import { ObjectsTypesMap } from '@/store/application/data.assets';
import { actionHandler } from '@/store/data/ActionHandler';
import Acknowledge from '@/components/common/deprecated/Acknowledge.vue';
import Confidence from '@/components/common/Confidence.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NHint from '@/uikit/hint/NHint.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';

@Options({
  components: {
    Acknowledge,
    Confidence,
    NButton,
    NHint,
    NIcon,
    NThumbnail,
    WatchListsGroup
  }
})
export default class RemoteMonitoringEventTile extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringEvent;

  @Prop({ type: Boolean, default: false })
  itemSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: String, required: false })
  openedItem?: string;

  @Prop({ type: Boolean, default: true })
  acknowledgeAllowed!: boolean;

  private cardModule = viewModelRepository.getCardsHumansItemViewModel();
  private faceObjectsModule = viewModelRepository.getObjectsFacesItemViewModel();
  private reasons = '';

  created() {
    this.load();
  }

  get card(): HumanCard | undefined {
    return this.cardModule.item;
  }

  get thumbnail(): string | undefined {
    return this.item?.thumbnail;
  }

  get defaultAction(): string {
    return 'info';
  }

  get thumbnailActions() {
    const result = [
      { icon: 'check', name: EventTypes.Select, position: ThumbnailPositions.TopLeft, selected: this.itemSelected },
      { icon: 'info', name: EventDetails.ShowInfo, position: ThumbnailPositions.TopRight },
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];

    if (configModule.features.vms_enabled) {
      result.push({ icon: 'play', name: EventDetails.ShowPlayer, position: ThumbnailPositions.BottomLeft });
    }
    return result;
  }

  get formattedMatchedCard(): string {
    return this.card?.name || String(this.item.card);
  }

  get watchListsColor() {
    return this.cardSelected ? 'var(--color-on-color-100)' : '';
  }

  get objectsType() {
    return ObjectsTypesMap.Faces;
  }

  get itemContainerClasses() {
    return {
      'remote-monitoring-item-tile__container': true,
      'remote-monitoring-item-tile__in-sidebar': this.itemSelected,
      'remote-monitoring-item-tile__is-open': this.openedItem === EventOpenedItems.Item
    };
  }

  get cardContainerClasses() {
    return {
      'remote-monitoring-item-tile__container': true,
      'remote-monitoring-item-tile__card-in-sidebar': this.cardSelected,
      'remote-monitoring-item-tile__card-is-open': this.openedItem === EventOpenedItems.Card
    };
  }

  navigateToCard() {
    if (this.card) {
      this.$emit('action', ItemsActionNames.NavigateToCard, this.card);
    }
  }

  async actionHandler(v: ItemsActionName, payload?: any) {
    await actionHandler.run(v, payload);
  }

  @Watch('item.id')
  async load() {
    this.cardModule.clear();
    if (this.item.card) this.cardModule.get(this.item.card);
    this.faceObjectsModule.clear();
    if (this.item.face_object_id) this.faceObjectsModule.get(this.item.face_object_id);
    this.reasons = '';
    this.reasons = await monitoringModule.getReasonsByCardId(this.item.card);
  }

  async acknowledgeHandler(actionName: string) {
    if (actionName === 'acknowledge') {
      await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeCreate(this.item.id);
      Object.assign(this.item, { acknowledged: true });
      autoUpdateHelper.updateHandler('/puppeteer/remote-monitoring/events/', this.item);
    }
  }
}
