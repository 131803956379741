
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { NButtonTypes } from '@/uikit';
import { NButtonSize, NButtonSizes } from '@/uikit/buttons/types';
import { copyTextToClipboard } from '../helpers';
import NHint from '../hint/NHint.vue';
import NButtonModel from './NButtonModel.vue';

@Options({
  name: 'NButtonModelCopy',
  components: { NButtonModel, NHint }
})
export default class NButtonModelCopy extends Vue {
  @Prop({ type: [String, Number], default: '' })
  readonly modelValue!: string | number;

  @Prop({ type: String, default: '' })
  readonly prefix!: string;

  @Prop({ type: String, default: '' })
  readonly hint!: string;

  @Prop({ type: String, default: 'top-center' })
  readonly hintPlacement!: string;

  @Prop({ type: String, default: NButtonTypes.Text })
  readonly type!: string;

  @Prop({ type: String, default: NButtonSizes.Normal })
  readonly size!: NButtonSize;

  @Prop({ type: String, default: 'label-mono-m' })
  readonly contentClass!: NButtonSize;

  private copyComplete = false;

  get computedProps() {
    const { hint, hintPlacement, prefix, modelValue, ...props } = this.$props as any;
    return {
      modelValue: this.prefix ? `${this.prefix}${modelValue}` : modelValue,
      ...props
    };
  }

  get icon() {
    return this.copyComplete ? 'done-all' : 'empty';
  }

  async copyToClipboard() {
    this.copyComplete = await copyTextToClipboard(String(this.modelValue));
    setTimeout(() => (this.copyComplete = false), 2000);
  }
}
