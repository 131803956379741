import { ItemViewModel } from '@/definitions/view-models';
import { itemViewModelLoader } from '@/store/multisidebar/helpers';
import { PermissionsModelNamesMap, saveItemAndPermissions } from '@/components/permissions/permission-helpers';

export default async function (...args: any[]) {
  // @ts-ignore
  const defaultItem = await itemViewModelLoader(...args);
  wrapCaseSave(defaultItem.model);
  return defaultItem;
}

export function wrapCaseSave(model: ItemViewModel<any>) {
  const originSave = model.save;
  model.save = () => saveItemAndPermissions(model, PermissionsModelNamesMap.Cases, originSave.bind(model));
  return model;
}
