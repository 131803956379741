
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ListViewModel } from '@/definitions/view-models';
import { FaceCluster } from '@/api';
import { ClustersFacesContactsFilter } from '@/api/models/ClustersFacesContactsFilter';
import { configModule } from '@/store/config';
import { NButton } from '@/uikit';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import InteractionClusterItem from '@/pages/interactions/InteractionClusterItem.vue';
import InteractionItem from '@/pages/interactions/InteractionItem.vue';

@Options({
  name: 'ClusterItem',
  components: { InfiniteScroll, InteractionClusterItem, InteractionItem, NButton },
  emits: ['display-filters', 'select-current']
})
export default class InteractionSection extends Vue {
  @Prop({ type: Object, required: false })
  currentItem?: FaceCluster | null = null;

  @Prop({ type: Object, required: true })
  module!: ListViewModel<FaceCluster, ClustersFacesContactsFilter>;

  get filtersCount(): number | undefined {
    const filtersCount = this.module.filterChanges.length;
    return filtersCount > 0 ? filtersCount : undefined;
  }

  get videoEnabled() {
    return configModule.features.vms_enabled;
  }
}
