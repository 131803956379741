import { AbsoluteBox, AbsoluteFrameBox, AbsoluteNumericBox, AbsoluteNumericBox2, AbsoluteSourceBox, Box, SomeBox } from './types';

function isValidCoordinate(v: any) {
  return v !== undefined && v !== null && Number.isFinite(+v);
}

export function isBox(item: any): item is Box {
  return ['w', 'h', 'x', 'y'].every(isValidCoordinate);
}

export function isAbsoluteBox(item: any): item is AbsoluteBox {
  return ['top', 'left', 'right', 'bottom'].every((v) => isValidCoordinate(item[v]));
}

export function isAbsoluteNumericBox(item: any): item is AbsoluteNumericBox {
  return item.length === 4 && item.every((v: any) => isValidCoordinate(v));
}

export function isAbsoluteNumericBox2(item: any): item is AbsoluteNumericBox2 {
  return (
    item.length === 4 &&
    item.reduce((p: boolean, v: any) => {
      const [x, y] = v;
      return p && isValidCoordinate(x) && isValidCoordinate(y);
    }, true)
  );
}

export function isAbsoluteFrameBox(item: any): item is AbsoluteFrameBox {
  return ['frame_coords_right', 'frame_coords_top', 'frame_coords_left', 'frame_coords_bottom'].every((v) => isValidCoordinate(item[v]));
}

export function isAbsoluteSourceBox(item: any): item is AbsoluteFrameBox {
  return ['source_coords_right', 'source_coords_top', 'source_coords_left', 'source_coords_bottom'].every((v) => isValidCoordinate(item[v]));
}

export function convertAbsoluteBoxToBox(box: AbsoluteBox): Box {
  return {
    x: box.left,
    y: box.top,
    w: box.right - box.left,
    h: box.bottom - box.top
  };
}

export function convertAbsoluteFrameBoxToBox(box: AbsoluteFrameBox): Box {
  return {
    x: box.frame_coords_left,
    y: box.frame_coords_top,
    w: box.frame_coords_right - box.frame_coords_left,
    h: box.frame_coords_bottom - box.frame_coords_top
  };
}

export function convertAbsoluteSourceBoxToBox(box: AbsoluteSourceBox): Box {
  return {
    x: box.source_coords_left,
    y: box.source_coords_top,
    w: box.source_coords_right - box.source_coords_left,
    h: box.source_coords_bottom - box.source_coords_top
  };
}

export function convertAbsoluteNumericBoxToBox(box: AbsoluteNumericBox): Box {
  let [x2, y1, x1, y2] = box;

  // @todo: Remove after backend fix, swap for annex
  if (x1 > x2) {
    const tmp = x1;
    x1 = x2;
    x2 = tmp;
  }

  return {
    x: x1,
    y: y1,
    w: x2 - x1,
    h: y2 - y1
  };
}

export function convertAbsoluteNumericBox2ToBox(box: AbsoluteNumericBox2): Box {
  const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = box;
  return {
    x: x1,
    y: y1,
    w: x2 - x1,
    h: y3 - y1
  };
}

export function convertSomeBoxToBox(box: SomeBox) {
  if (isBox(box)) return box;
  if (isAbsoluteBox(box)) return convertAbsoluteBoxToBox(box);
  if (isAbsoluteNumericBox(box)) return convertAbsoluteNumericBoxToBox(box);
  if (isAbsoluteNumericBox2(box)) return convertAbsoluteNumericBox2ToBox(box);
  if (isAbsoluteFrameBox(box)) return convertAbsoluteFrameBoxToBox(box);
  if (isAbsoluteSourceBox(box)) return convertAbsoluteSourceBoxToBox(box);
  throw new Error("Can't convert item bbox to Box: " + JSON.stringify(box));
}

export function scaleBox(box: Box, scaleFactor: number): Box {
  const w = box.w * scaleFactor;
  const h = box.h * scaleFactor;

  const xOffset = (w - box.w) / 2;
  const yOffset = (h - box.h) / 2;

  return { w, h, x: box.x - xOffset, y: box.y - yOffset };
}
