
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemViewModel } from '@/definitions/view-models';
import { Camera, Counter, CounterRecord } from '@/api';
import { viewModelRepository } from '@/api/common';
import { dataModule } from '@/store/data';
import NTable from '@/uikit/table/NTable.vue';
import { mapObjectToSelectItem } from '@/common/utilsFilterSchema';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import { getCounterRecordFilters } from '@/pages/counters/counterFiltersBuilder';
import columns from './schemes/counter-records-columns';

function gatherRecordBboxes(record: CounterRecord) {
  return [...(record.faces_bbox ?? []), ...(record.cars_bbox ?? []), ...(record.silhouettes_bbox ?? [])];
}

@Options({
  name: 'CounterRecords',
  components: { FilterSection, InfiniteScroll, NTable }
})
export default class CounterRecords extends Vue {
  @Prop({ type: Number, required: true })
  readonly counterId!: number;

  readonly columns = columns;
  readonly module = viewModelRepository.getCounterRecordsListViewModel();

  cameraItems: any = [];

  async created() {
    this.cameraItems = mapObjectToSelectItem(await this.getAvailableCameras());
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    this.module.get();
  }

  @Watch('counterId')
  reload() {
    this.module.filter.force.counter = [this.counterId];
    this.module.get();
  }

  get smallFiltersLayout() {
    return getCounterRecordFilters({ small: true }, this.cameraItems);
  }

  get largeFiltersLayout() {
    return getCounterRecordFilters({}, this.cameraItems);
  }

  async getCounter(): Promise<ItemViewModel<Counter>> {
    const counter = viewModelRepository.getCountersItemViewModel();
    await counter.get(this.counterId);
    return counter;
  }

  async getAvailableCameras(): Promise<Camera[]> {
    let result: Camera[] = [];
    try {
      const counter = await this.getCounter();
      const availableItemsFilter = (v: Camera) => (counter.item!.cameras || []).includes(v.id);
      result = dataModule.camerasModule.items.filter(availableItemsFilter);
    } catch (e: any) {
      console.warn('[filter:getAvailableCameras] ', e);
    }
    return result;
  }

  mounted() {
    this.reload();
  }

  handleTableAction(record: CounterRecord) {
    const item = { fullframe: record.fullframe, bboxes: gatherRecordBboxes(record) };
    this.$photoViewer.show(item);
  }
}
