
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import { getAuditLogsFilters } from '@/pages/audit-logs/forms/AuditLogsFilterBuilder';
import ListPage from '@/pages/ListPage.vue';
import { auditLogsTableSchema } from './table/audit-logs-table-schema';

@Options({
  name: 'AuditLogsPage',
  components: {
    FilterSection,
    ListPage,
    NButton,
    NIcon,
    NTable
  }
})
export default class AuditLogsPage extends Vue {
  @Prop({ type: String, required: true })
  tab!: string;

  get pageSidebarType() {
    return MultisidebarItemTypes.AuditLogs;
  }

  get tableSchema() {
    return auditLogsTableSchema;
  }

  get smallFilterSchema() {
    return getAuditLogsFilters({ small: true });
  }

  get bigFilterSchema() {
    return getAuditLogsFilters({ small: false });
  }

  get state(): PageState {
    return pageModule.getPageStateByTab(PagePaths.AuditLogs, this.tab);
  }

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get items() {
    return this.module.items;
  }

  handleTableAction(row: any, cell: ITableCell, rowIndex: any, cellIndex: any) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: this.pageSidebarType, rawItem: row });
  }

  scrollBottomHandler(v: number | null) {
    if (typeof v === 'number' && v < 200) {
      if (!this.module.loading && !this.module.appending) this.module.append();
    }
  }

  @Watch('module.filter.current', {
    deep: true
  })
  filterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    if (v?.limit !== p.limit) return;
    this.module.debouncedGet();
  }
}
