import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-small-data-range" }
const _hoisted_2 = { class: "filter-small-data-range__label control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterDateRange = _resolveComponent("FilterDateRange")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode(_component_FilterDateRange, {
      size: "small",
      model: _ctx.model,
      mode: _ctx.mode,
      "gte-prop": _ctx.gteProp,
      "lte-prop": _ctx.lteProp,
      plain: true,
      "has-intervals": false,
      clearable: true,
      "clear-button": false,
      "data-qa": _ctx.dataQa
    }, null, 8, ["model", "mode", "gte-prop", "lte-prop", "data-qa"])
  ]))
}