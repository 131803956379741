/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportStatusEnum {
    SCHEDULED = 'scheduled',
    PROCESSING = 'processing',
    PREPARED = 'prepared',
    ERROR = 'error',
    COMPLETED = 'completed',
    UPLOADED = 'uploaded'
}
