export default {
  params: {
    track_overlap_threshold_desc:
      'Процент перекрытия границ между двумя последовательными кадрами, чтобы эти bbox\'ы считались одним треком.\n' +
      'Значение данного параметра стоит уменьшить при низкой частоте кадров, иначе треки будут слишком часто разрываться, что приведёт к большому количеству overall-кадров и большой нагрузке на findface-extraction-api.\n' +
      'Значение данного параметра стоит увеличить, если происходит ошибка, когда внутри одного трека появляются разные объекты (треки разных объектов переходят от одного к другому).\n' +
      'Диапазон значений: от 0 до 1',
    track_send_history_desc:
      'Отправлять массив координат границ объекта вместе с событием. Может быть применимо для внешних интеграций, чтобы отобразить путь объекта.\n' +
      'Работа при track_max_duration_frames = 0 невозможна - необходимо установить этот параметр в ненулевое значение, иначе будет получен статус misconfigured',
    filter_min_quality_faces_desc:
      'Минимальное качество изображения лица для детекции.\n' +
      'Допустимый диапазон от 0 до 1.\n' +
      'Базовое рекомендуемое значение 0.42 - соответствует удовлетворительному качеству изображения лица.\n' +
      'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям лиц, полностью находящихся в кадре.\n' +
      'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые лица или лица, повернутые под большими углами. При таких значениях распознавание может быть неэффективным',
    filter_min_quality_bodies_desc:
      'Минимальное качество изображения силуэта для детекции.\n' +
      'Допустимый диапазон от 0 до 1.\n' +
      'Базовое рекомендуемое значение 0.6 - соответствует удовлетворительному качеству изображения силуэта.\n' +
      'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям силуэтов, полностью находящихся в кадре.\n' +
      'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые силуэты или силуэты, повернутые под большими углами. При таких значениях распознавание может быть неэффективным.',
    filter_min_quality_cars_desc:
      'Минимальное качество изображения транспортного средства для детекции.\n' +
      'Допустимый диапазон от 0 до 1.\n' +
      'Базовое рекомендуемое значение 0.6 - соответствует удовлетворительному качеству изображения ТС.\n' +
      'Значения вблизи 1 соответствуют наиболее качественным выровненным изображениям ТС, полностью находящихся в кадре.\n' +
      'Значения вблизи 0 соответствуют замыленным изображениям, а также изображениям, содержащим перевернутые ТС или ТС, повернутые под большими углами. При таких значениях распознавание может быть неэффективным.',
    track_max_duration_frames_desc:
      'Ограничение максимального количества кадров в истории трека.\n' +
      'Если значение этого параметра больше нуля и количество кадров в треке превышает его, то трек будет принудительно разорван. Однако из-за особенностей videoworker\'а (интерполяция bbox\'ов) эта проверка является не строгой - к примеру, при track_max_duration_frames = 5 трек может длиться и 6, и 7 кадров.\n' +
      'Данная настройка нужна для разрыва "вечных" треков объектов - например, лицо на рекламном плакате, который попал в поле зрения камеры.\n' +
      'Для работы опции track_send_history необходимо установить параметр track_max_duration_frames в ненулевое значение',
    filter_min_size: 'Размер',
    filter_max_size: '-',
    filter_min_quality: 'Качество',
    jpeg_quality: 'Качество JPEG',
    fullframe_use_png: 'Полный кадр PNG',
    track_overlap_threshold: 'Коэффициент перекрытия границ распознанного объекта',
    track_max_duration_frames: 'Максимальная длительность трека',
    track_miss_interval: 'Интервал закрытия трека',
    track_interpolate_bboxes: 'Интерполировать границы распознанного объекта',
    track_send_history: 'Отправлять историю трека',
    fullframe_crop_rot: 'Обрезать полный кадр',
    overall_only: 'Буферный режим',
    realtime_post_interval: 'Интервал',
    realtime_post_every_interval: 'Отправлять в каждом интервале',
    realtime_post_first_immediately: 'Отправлять первый распознанный объект немедленно',
    post_first_track_frame: 'Отправлять первый кадр трека',
    post_last_track_frame: 'Отправлять последний кадр трека',
    post_best_track_normalize: 'Отправлять лучшее нормализованное изображение трека',
    post_best_track_frame: 'Отправлять лучший кадр трека',
    deduplicate_desc: 'Фиксировать только уникальные события среди камер группы, исключив дубликаты',
    deduplicate_delay_desc: 'Интервал в секундах, с которым события проверяются на уникальность',
    roi_desc: 'Отправлять только объекты, обнаруженные внутри интересующей области',
    rot_desc: 'Распознавать и отслеживать объекты только внутри заданной области. Используйте данную опцию, чтобы уменьшить нагрузку на сервер',
    min_score: 'Минимальное качество объекта (min_score)',
    min_score_desc:
      'Минимальное качество изображения объекта при выборе лучшего. Определяется эмпирически: отрицательные значения вблизи 0 = наиболее качественные прямые изображения лиц анфас, -1 = хорошее качество, -2 = удовлетворительное качество, отрицательные значения -5 и меньше = перевернутые объекты и объекты, повернутые под большими углами, распознавание может быть неэффективным',
    min_d_score: 'Максимальное отклонение объекта (min_d_score)',
    min_d_score_desc:
      'Максимальное отклонение объекта от положения анфас при выборе лучшего. Определяется эмпирически: -3.5 = слишком большие углы поворота, распознавание лиц может быть неэффективным,  -2.5 = удовлетворительное отклонение, -0.05 = близко к положению анфас, 0 = анфас',
    filter_min_size_desc: 'Минимальный размер объекта в пикселях для отправки на сервер',
    filter_max_size_desc: 'Максимальный размер объекта в пикселях для отправки на сервер',
    realtime: 'Режим реального времени (realtime)',
    realtime_desc:
      'Режим реального времени. Выбирать лучший кадр с объектом в каждом интервале времени realtime_dly. Если realtime_post_perm: true, отправка лучшего кадра происходит по завершению каждого интервала realtime_dly; если false, лучший кадр отправляется, только если его качество улучшилось по сравнению с предыдущим отправленным кадром',
    realtime_post_first_immediately_desc:
      'Если true, отправлять первый объект из трека сразу, как только фильтры по качеству/размеру/региону интереса пройдены, не дожидаясь окончания первого realtime_post_interval  в режиме реального времени. Если false, отправлять первый объект после окончания первого realtime_post_interval',
    realtime_post_perm: 'Отправлять лучший кадр (realtime_post_perm)',
    realtime_post_perm_desc:
      'Если true, отправлять лучший кадр в каждом интервале времени realtime_dly в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром',
    realtime_dly: 'Временной интервал (realtime_dly)',
    realtime_dly_desc: 'Временной интервал в миллисекундах, в течение которого в режиме реального времени выбирается лучший кадр с объектом',
    overall: 'Буферный режим (overall)',
    overall_desc: 'Буферный режим. Отправлять для объекта один кадр наилучшего качества',
    ffmpeg_params: 'Параметры FFmpeg (ffmpeg_params)',
    ffmpeg_params_desc: 'Параметры FFmpeg для видеопотока. Задаются массивом строк ключ=значение, например, "rtsp_transport=tcp allowed_media_types=video"',
    fd_frame_height_desc:
      'Размер кадра для детектора объектов в пикселях. Отрицательные значения соответствуют исходному размеру. Оптимальные значения для уменьшения нагрузки: 640-720',
    npersons: 'Максимальное количество объектов (npersons)',
    npersons_desc: 'Максимальное количество объектов, одновременно отслеживаемых детектором объектов. Влияет на производительность',
    tracker_threads: 'Количество потоков отслеживания (tracker_threads)',
    tracker_threads_desc:
      'Количество потоков отслеживания для детектора объектов. Должно быть меньше или равно значению параметра npersons. Оптимально, когда они равны. Если количество потоков отслеживания меньше, чем максимальное количество отслеживаемых объектов, потребление ресурсов уменьшается, однако также уменьшается и скорость отслеживания',
    jpeg_quality_desc: 'Качество сжатия полного кадра для отправки',
    draw_track: 'Отрисовать трек (draw_track)',
    draw_track_desc: 'Отрисовать трек в границах объекта',
    api_timeout: 'Время ожидания (api_timeout)',
    api_timeout_desc: 'Время ожидания в миллисекундах ответа на API-запрос',
    md_threshold: 'Минимальная интенсивность движения (md_threshold)',
    md_threshold_desc:
      'Минимальная интенсивность движения, которая будет регистрироваться детектором движения. Определяется эмпирически: 0 = детектор выключен, 0.002 = значение по умолчанию, 0.05 = минимальная интенсивность слишком высока, чтобы зарегистрировать движение',
    md_scale: 'Коэффициент масштабирования кадра (md_scale)',
    md_scale_desc:
      'Размер кадра для детектора движения относительно исходного размера от 0 до 1. Кадр должен быть уменьшен при больших разрешениях камеры, отображении объектов крупным планом, а также при чрезмерной загрузке процессора — для снижения потребления системных ресурсов',
    fullframe_crop_rot_desc:
      'Если true, то обрезать полный кадр по размеру ROT-области перед отправкой на распознавание. Размер полного кадра будет равен размеру ROT-области',
    track_miss_interval_desc: 'Завершить трек, если в нем не было распознано новых изображений объекта в течение заданного времени (в секундах)',
    post_first_track_frame_desc:
      'При завершении трека в дополнение к overall-кадру трека будет дополнительно отправлен первый кадр трека. Может быть применимо для внешних интеграций',
    post_last_track_frame_desc:
      'При завершении трека в дополнение к overall-кадру трека будет дополнительно отправлен последний кадр трека. Может быть применимо для внешних интеграций',
    router_timeout_ms: 'Время ожидания ответа на запрос (router_timeout_ms)',
    router_timeout_ms_desc: 'Время ожидания в миллисекундах ответа на отправленный запрос с объектом',
    start_stream_timestamp: 'Прибавлять к меткам времени (start_stream_timestamp)',
    start_stream_timestamp_desc: 'Прибавлять указанное количество секунд к меткам времени из потока',
    use_stream_timestamp: 'Получать метки времени из потока',
    use_stream_timestamp_desc: 'Отправлять на сервер метки времени, полученные из потока, вместо текущих значений дата и время',
    use_stream_timestamp__simple: 'Передавать на сервер метки времени из видеопотока (use_stream_timestamp)',
    use_stream_timestamp_desc__simple: 'Если не выбрано, то по умолчанию будут передаваться текущие дата и время',
    select_start_stream_timestamp: 'Указать метку времени для загружаемого видео',
    select_start_stream_timestamp_desc:
      'Чтобы самостоятельно указать метку времени для загружаемого видео, включите выше передачу на сервер меток времени из видеопотока',
    watch_list_camera_groups_desc: 'Если список групп камер пустой, то список наблюдения работает по всем группам камер',
    play_speed: 'Ограничение скорости проигрывания (play_speed)',
    play_speed_desc:
      'Если меньше нуля, то скорость не ограничивается, в остальных случаях поток читается со скоростью play_speed. Не применимо для потоков с камер видеонаблюдения',
    ffmpeg_format: 'Формат FFmpeg (ffmpeg_format)',
    ffmpeg_format_desc: 'Передать FFmpeg формат (mxg, flv и т. д.), если он не может быть автоматически определен',
    router_verify_ssl: 'Проверять SSL-сертификат (router_verify_ssl)',
    router_verify_ssl_desc:
      'Если true, проверять SSL-сертификат сервера при отправке на него объектов через https. Если false, может быть принят самоподписанный сертификат',
    imotion_threshold: 'Минимальная интенсивность движения (imotion_threshold)',
    imotion_threshold_desc: 'Минимальная интенсивность движения, которая будет регистрироваться детектором движения',
    realtime_post_interval_desc:
      'Временной интервал в секундах (целое или рациональное число), в течение которого в режиме реального времени выбирается лучший кадр с объектом',
    realtime_post_every_interval_desc:
      'Если true, отправлять лучший кадр в каждом временном интервале realtime_post_interval в режиме реального времени. Если false, отправлять лучший кадр, только если его качество улучшилось по сравнению с предыдущим отправленным кадром',
    detect_bodies_desc:
      'Для подсчета силуэтов должно быть включено их детектирование. Ознакомьтесь с документацией или обратитесь к администратору, чтобы включить детектирование',
    detect_cars_desc:
      'Для подсчета транспортных средств должно быть включено их детектирование. Ознакомьтесь с документацией или обратитесь к администратору, чтобы включить детектирование',
    time_should_be_in_utc: 'Необходимо указать время в формате UTC',
    collect_track_data_seconds_desc: 'Минимальное время - 15, максимальное - 300',
    detect_proximity_recommendations_desc: 'Рекомендации для успешной калибровки камер:',
    detect_proximity_recommendations_desc_1: 'Человек на камере должен быть виден в полный рост',
    detect_proximity_recommendations_desc_2: 'Человек должен пройти по зоне, фиксируемой камерой, по которой в дальнейшем будут рассчитываться расстояния',
    detect_proximity_recommendations_desc_3: 'Для достижения наилучшего качества человек должен ходить по ровной поверхности',
    distance_measurement_desc:
      'После включения функции определения дистанции сохраните изменения. Затем выполните настройку счетчика, введя время калибровки и инициировав калибровку.',
    disable_drops: 'Обработка всех подходящих кадров без пропусков (disable_drops)',
    disable_drops_desc:
      'Если findface-video-worker не обладает достаточными ресурсами для обработки всех кадров с объектами, он будет пропускать некоторые из них. Если данная опция активна, findface-video-worker помещает лишние кадры в очередь, чтобы обработать их впоследствии. При обработке файлов как камер необходимо указать значение true',
    single_pass: 'Обработать поток один раз до конца или до первой ошибки (single_pass)',
    single_pass_desc: 'Если true, не перезапускать обработку потока при обнаружении ошибки',
    confidence_face_desc:
      'Порог срабатывания применяется для сопоставления обнаруженного объекта (лица) с объектом (лицом) внутренней сущности. Рекомендуемые значения порога для объекта лицо: 0.625 - низкий, 0.702 - нормальный, 0.75 - высокий.\n' +
      'Оптимальным порогом считается нормальный.',
    confidence_body_desc:
      'Порог срабатывания применяется для сопоставления обнаруженного объекта (силуэта) с объектом (силуэтом) внутренней сущности. Рекомендуемые значения порога для объекта силуэт: 0.5 - низкий, 0.65 - нормальный, 0.85 - высокий.\n' +
      'Оптимальным порогом считается нормальный.',
    confidence_car_desc:
      'Порог срабатывания применяется для сопоставления обнаруженного объекта (TC) с объектом (TC) внутренней сущности. Рекомендуемые значения порога для объекта ТС: 0.3 - низкий, 0.65 - нормальный, 0.85 - высокий.\n' +
      'Оптимальным порогом считается нормальный.',
    router_url: 'URL роутера (router_url)',
    router_url_desc:
      'Важно: изменение этого параметра отключит сохранение всех событий в системе. Не меняйте его, если вы не уверены.\n' +
      '\n' +
      'Установленный по умолчанию URL начнет использоваться после того, как вы очистите поле URL роутера и сохраните изменения.'
  },
  devices_pf: '{ count, plural, one {# устройство} other {# устройств} few {# устройства}}',
  files_pf: '{ count, plural, one {# файл} other {# файлов} few {# файла}}',
  open_video_events: 'Открыть события видео',
  open_all: 'Открыть все',
  add_to_filter: 'Фильтровать',
  camera_optional: 'Камера (необязательно)',
  tabs: {
    external_detectors: 'Внешние детекторы',
    cameras: 'Устройства',
    onvif_discovery: 'Обнаружение ONVIF',
    videos: 'Загрузка файла'
  },
  transformation: 'Трансформация',
  mirror: 'Отразить по вертикали',
  flip: 'Отразить по горизонтали',
  rotate_clockwise: 'Повернуть по часовой стрелке',
  rotate_counterclockwise: 'Повернуть против часовой стрелки',
  posting_objects: 'Отправка объектов',
  timeout: 'Таймаут',
  timestamp: 'Временная метка',
  add_to_timestamps: 'Добавить к временным меткам',
  other: 'Другие',
  minimum_track_length: 'Минимальная длина трека',
  minimum_track_length_desc: 'Фильтр по минимальной длине трека, данная настройка влияет только на отправку',
  ffmpeg_parameters: 'Параметры FFmpeg',
  play_speed_limit: 'Ограничение скорости воспроизведения',
  stream_data_filter: 'Фильтр данных потока',
  retrieve_timestamps_from_stream: 'Получать метки времени из потока',
  verify_ssl_certificate: 'Проверить SSL-сертификат',
  force_input_format: 'Формат ввода',
  imotion_threshold: 'Минимальная интенсивность движения',
  read_frames_from_source_without_drops: 'Считывание кадров из источника без пропусков',
  one_detector_must_be_on: 'Должен быть включен хотя бы один детектор',
  start_time: 'Время начала',
  file_size: 'Размер файла',
  now: 'Сейчас',
  clear: 'Очистить',
  url: 'URL',
  reset_params: 'Сбросить параметры',
  deleting_video_confirm_desc:
    'Важно: при повторной обработке видеофайлов все связанные с ними данные, такие как распознанные объекты и записи участников происшествия, будут удалены. Вы уверены, что хотите продолжить?',
  yes_start_process: 'Да, начать обработку',
  cancel: 'Отмена',
  process: 'Обработать',
  again: 'повторно',
  sections: {
    filter: 'Фильтр',
    compression: 'Сжатие',
    rot: 'ROT',
    tracking: 'Трекинг',
    posting: 'Отправка на обработку'
  },
  deleting_videos_confirm_desc_1:
    'Вы хотите удалить видеофайл, сохранив связанные с ним данные (распознанные объекты, записи участников происшествия), или удалить файл со всеми связанными данными?',
  deleting_videos_confirm_desc_2:
    'Этот видеофайл был удалён. Хотите удалить все связанные с ним данные (распознанные объекты, записи участников происшествия)?',
  deleting_videos_confirm_desc_3: 'Хотите удалить видео и все связанные с ним данные (распознанные объекты, записи участников происшествия)?',
  delete_all: 'Удалить всё',
  delete_video_files_only: 'Удалить только видеофайлы',
  delete: 'Удалить',
  processed: 'обработано',
  not_processed: 'не обработано',
  brand: 'бренд',
  devices: 'устройств',
  discover_again: 'Найти устройства',
  discovering: 'Поиск устройств...',
  ip_address: 'IP-адрес',
  login_to_camera: 'Авторизоваться в камере',
  mac_address: 'MAC-адрес',
  model: 'модель',
  port: 'порт',
  active: 'активный',
  gray: 'серый',
  green: 'зеленый',
  only_active: 'только активные',
  only_not_active: 'только неактивные',
  red: 'красный',
  yellow: 'желтый',
  frames_dropped: 'Потеряно кадров',
  face_objects_not_posted: 'Не отправлено лиц',
  face_objects_posted: 'Отправлено лиц',
  body_objects_not_posted: 'Не отправлено силуэтов',
  body_objects_posted: 'Отправлено силуэтов',
  car_objects_not_posted: 'Не отправлено машин',
  car_objects_posted: 'Отправлено машин',
  process_duration: 'Длительность процесса',
  status: 'Статус',
  upload: 'Загрузить',
  uploads: 'Загрузки',
  cameras: 'Камеры',
  external_detectors: 'Внешние детекторы',
  create: 'Добавить',
  image: 'Изображение',
  name: 'Название',
  cameras_no_items: 'У вас нет ни одной камеры',
  videos_no_items: 'У вас нет ни одного загруженного файла',
  external_detectors_no_items: 'У вас нет ни одного внешнего детектора',
  external_vms_no_items: 'У вас нет ни одной камеры из внешних VMS',
  not_found_items: 'Извините, ничего не найдено',
  create_cameras: 'Добавить камеры',
  create_videos: 'Загрузить файлы',
  create_external_detectors: 'Добавить внешние детекторы',
  create_external_vms: 'Добавить камеру внешней VMS',
  zones: 'зоны',
  general: 'основные',
  advanced: 'дополнительные',
  info: 'сведения',
  faces: 'лица',
  face_clusters: 'кластеры лиц',
  bodies: 'силуэты',
  cars: 'ТС',
  detectors: 'Детекторы',
  reset_face_detector: 'Сбросить параметры лиц',
  reset_body_detector: 'Сбросить параметры силуэтов',
  reset_car_detector: 'Сбросить параметры ТС',
  enabled: 'Включен',
  map: 'Геопозиция',
  latitude: 'широта',
  longitude: 'долгота',
  azimuth: 'азимут',
  angle_of_view: 'угол обзора',
  pitch: 'угол наклона',
  camera_reset: 'Сбросить настройки',
  camera_reset_advanced: 'Сбросить дополнительные',
  camera_reset_all: 'Сбросить все',
  camera_reset_zone: 'Сбросить зоны',
  camera_reset_detectors: 'Сбросить распознавание',
  camera_reset_map: 'Сбросить геопозиции',
  inactivated: 'не активна',
  sec: 'сек',
  ms: 'мс',
  px: 'пикс',
  enable_liveness: 'включить liveness',
  enable_recorder: 'записывать видео',
  camera_id: 'ID камеры',
  camera_status: 'Статус камеры',
  disabled: 'ОТКЛЮЧЕНО',
  connection_type: 'Тип подключения',
  resolution: 'Разрешение',
  camera_name: 'Название камеры',
  ds_camera_group: 'Группа камер',
  description_optional: 'Описание',
  enter_description_optional: 'Добавьте описание',
  streaming_protocol_url: 'URL потока',
  connection_onvif: 'ONVIF',
  connection_stream: 'поток',
  connection_external: 'внешний детектор',
  connection_external_vms: 'внешние VMS',
  camera_group: 'группа камер',
  description: 'описание',
  external_detector_name: 'Название внешнего детектора',
  enter_external_detector_name: 'Введите название внешнего детектора',
  external_detector_token: 'Токен',
  external_detector_camera_group: 'Исходная группа камер',
  select_external_detector_camera_group: 'Выберите исходную группу камер',
  faces_posted: 'отправлено лиц',
  faces_failed: 'неудачных лиц',
  faces_not_posted: 'не отправлено лиц',
  bodies_posted: 'отправлено силуэтов',
  bodies_failed: 'неудачных силуэтов',
  bodies_not_posted: 'не отправлено силуэтов',
  cars_posted: 'отправлено ТС',
  cars_failed: 'неудачных ТС',
  cars_not_posted: 'не отправлено ТС',
  job_starts: 'Перезапусков',
  decoding_errors: 'Не отправлено объектов',
  objects_posted: 'Отправлено объектов',
  objects_failed: 'Неудачных объектов',
  objects_not_posted: 'Не отправлено объектов',
  objects_statistics: 'Статистика по объектам',
  back: 'назад',
  add_and_config: 'Добавить и настроить',
  add: 'Добавить',
  add_cameras_phrase_1: 'Используйте Enter для добавления нескольких URL или',
  add_cameras_phrase_2: '. Вы также можете написать в поле выше список в формате CSV.',
  add_cameras_phrase_3: 'Поддерживаемый формат: "Name;Camera group;Stream URL or device IP address;Longitude;Latitude;Azimuth;Angle of view;Address".',
  upload_csv: 'загрузите CSV-файл',
  download_sample_csv: 'Скачать пример CSV-файла',
  stream_url: 'URL потока',
  enter_stream_url: 'Введите URL потока',
  stream: 'Поток',
  login: 'Имя пользователя',
  login_enter: 'Введите имя польз.',
  password: 'Пароль',
  password_enter: 'Введите пароль',
  save_and_check: 'Сохранить и проверить',
  discard: 'Отказаться',
  ds_name: 'Имя',
  ds_info: 'Сведения',
  latest_device_events: 'Последние события устройства',
  you_are_adding: 'Вы добавляете',
  camera_ip_address: 'IP-адрес камеры',
  stream_profile: 'Профиль потока',
  id: 'ID',
  location: 'Местоположение',
  location_none: 'Не задано',
  location_area: 'Область на карте',
  filter_events: 'Отфильтровать события',
};
