
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { workspaceModule } from '@/store/application/workspace';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  components: { NButton }
})
export default class WorkspaceProxyView extends Vue {
  @Prop({ type: String, required: true })
  readonly tab!: string;

  get enabled() {
    return this.tab ? !!workspaceModule.items.find((v) => v.tab === this.tab) : true;
  }
}
