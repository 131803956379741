import { render } from 'vue';
import { appEnvironment } from '@/store/application/app.environment';

export type ConfirmOptions = {
  size?: string; /* NModalWindowSize */
  title?: string;
  text: string;
  confirmLabel?: string;
  cancelLabel?: string;
};

const DefaultOptions: Partial<ConfirmOptions> = {
  size: 'medium',
  confirmLabel: 'Yes',
  cancelLabel: 'Cancel'
};

type ResultFunction<T> = (value?: T) => void;

export class CustomDialogFactory {
  protected getContainer(): HTMLElement {
    return document.body.appendChild(document.createElement('div'));
  }

  protected getComponent<T>(Component: any, options: any, resultHandlerName: string, resultHandler: ResultFunction<T>, cancelHandlerName?: string): JSX.Element {
    const props = {
      ...Object.assign(DefaultOptions, options),
      [cancelHandlerName || 'onClose']: () => resultHandler(undefined),
      [resultHandlerName]: (v: T) => resultHandler(v)
    };
    const component = <Component {...props}></Component>;
    component.appContext = appEnvironment.context;
    return component;
  }

  create<T>(Component: any, options: any, resultHandlerName: string, cancelHandlerName?: string): Promise<T | undefined> {
    const result = new Promise<T | undefined>((resolve, reject) => {
      const container = this.getContainer();
      const component = this.getComponent<T>(Component, options, resultHandlerName, resultHandler, cancelHandlerName);
      render(component, container);

      function resultHandler(value?: T): void {
        resolve(value);
        render(null, document.body.removeChild(container));
      }
    });
    return result;
  }
}

export const customDialogFactory = new CustomDialogFactory();
