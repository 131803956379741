
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import { getConePath } from './helpers';
import { PointAsArray } from '@/uikit/draw/types';
import { CameraStatusColorCode } from '@/api/models/Status';
import { generateV4UUID } from '@/common/uuid';
import { Camera } from '@/api';
import CameraPopup from '@/components/map/CameraPopup.vue';
import { dialogController } from '@/common/DialogController';

const CameraMarkerRadius = 40;
const FullRadius = 110;
const SmallRadius = 15;
const SizeOffset = 6;

@Options({
  name: 'CameraMarker',
  components: {
    LMarker,
    LIcon
  },
  emits: ['select']
})
export default class CameraMarker extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Camera;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly opened!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly hasCone!: boolean;

  @Prop({ type: Boolean, default: true })
  readonly showStatus!: boolean;

  readonly clickTargetDataId = 'camera-marker-click-target';

  get latLng() {
    return { lat: this.item.latitude, lng: this.item.longitude };
  }

  get gradientId() {
    return generateV4UUID();
  }

  get gradientClipPathId() {
    return generateV4UUID();
  }

  get streamStatus() {
    return this.item.health_status?.code ?? 'gray';
  }

  get coneGradientColor() {
    if (this.opened) {
      return 'var(--color-accent)';
    }

    if (!this.showStatus) return 'var(--color-grey)';

    switch (this.streamStatus) {
      case 'green':
        return 'var(--color-light-green)';
      case 'red':
        return 'var(--color-red)';
      case 'yellow':
        return 'var(--color-yellow)';
      case 'gray':
      default:
        return 'var(--color-grey)';
    }
  }

  get openedCircleClass() {
    return {
      'camera-marker__opened-circle': true,
      'camera-marker__opened-circle_open': this.opened,
      'camera-marker__opened-circle_select': this.selected
    };
  }

  get iconClass() {
    return {
      'camera-marker__icon': true,
      'camera-marker__no-events': this.readonly,
      [`camera-marker__icon_${this.streamStatus}`]: true,
      ['camera-marker__icon_selected']: this.selected
    };
  }

  get fullRadius() {
    return this.hasCone ? FullRadius : SmallRadius;
  }

  get center(): PointAsArray {
    return [this.size / 2, this.size / 2];
  }

  get size() {
    return (this.fullRadius + SizeOffset) * 2;
  }
  get CMR() {
    return CameraMarkerRadius;
  }

  get statusPath() {
    return getConePath(this.item.angle_of_view || 0, 13, this.center);
  }

  get conePath() {
    return getConePath(this.item.angle_of_view || 0, this.fullRadius, this.center);
  }

  get iconSvgStyle() {
    let deltaSize = Math.round((CameraMarkerRadius - this.size) / 2);
    let transform = `translate(${deltaSize}px, ${deltaSize}px)`;
    if (this.item.azimuth) {
      transform += `rotate(${Math.round(this.item.azimuth)}deg)`;
    }
    return { transform };
  }

  showPopup(el: HTMLElement) {
    dialogController.component = CameraPopup;
    dialogController.props = {
      reference: el,
      item: this.item
    };
    dialogController.show = true;
  }

  hidePopup() {
    dialogController.show = false;
  }
}
