import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-item-row__thumbnail" }
const _hoisted_2 = { class: "card-item-row__content" }
const _hoisted_3 = { class: "card-item-row__name" }
const _hoisted_4 = { class: "card-item-row__name_SIDEEFFECT card-item-row__ellipsis heading-m" }
const _hoisted_5 = {
  key: 0,
  class: "card-item__monitoring label-s"
}
const _hoisted_6 = {
  key: 1,
  class: "card-item-row__ellipsis label-mono-m"
}
const _hoisted_7 = { class: "card-item-row__watch-lists" }
const _hoisted_8 = {
  key: 2,
  class: "card-item-row__comment card-item-row__ellipsis label-xs"
}
const _hoisted_9 = {
  key: 0,
  "data-name": "actions",
  class: "card-item-row__actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_CardDate = _resolveComponent("CardDate")!
  const _component_WatchListsGroup = _resolveComponent("WatchListsGroup")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_CardAttachments = _resolveComponent("CardAttachments")!
  const _component_CardMetafields = _resolveComponent("CardMetafields")!
  const _component_ActionsDropdown = _resolveComponent("ActionsDropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes),
    style: _normalizeStyle(_ctx.styles),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showThumbnail)
        ? (_openBlock(), _createBlock(_component_NThumbnail, {
            key: 0,
            modelValue: _ctx.thumbnail,
            size: _ctx.thumbnailSize,
            actions: _ctx.thumbnailActions,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
            onAction: _ctx.showFullscreen
          }, null, 8, ["modelValue", "size", "actions", "onAction"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showDate)
        ? (_openBlock(), _createBlock(_component_CardDate, {
            key: 0,
            modelValue: _ctx.item.created_date
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formattedName), 1),
        (_ctx.hasAnyMonitoringRecord)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('remote_monitoring.monitoring_letter')), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.item.license_plate_number)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.item.license_plate_number), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_WatchListsGroup, {
          items: _ctx.item.watch_lists
        }, null, 8, ["items"]),
        (_ctx.showMatchedConnections)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: _ctx.selected ? 'text' : 'link',
              icon: "connections",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('action', 'show-connections', _ctx.item)), ["stop"]))
            }, null, 8, ["type"]))
          : _createCommentVNode("", true),
        (_ctx.showInteractions)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              type: _ctx.selected ? 'text' : 'link',
              icon: "relations",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('action', 'show-interactions', _ctx.item)), ["stop"]))
            }, null, 8, ["type"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.comment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.formattedComment), 1))
        : _createCommentVNode("", true),
      (_ctx.showAttachments)
        ? (_openBlock(), _createBlock(_component_CardAttachments, {
            key: 3,
            item: _ctx.item,
            type: _ctx.type
          }, null, 8, ["item", "type"]))
        : _createCommentVNode("", true),
      (_ctx.showMetafields)
        ? (_openBlock(), _createBlock(_component_CardMetafields, {
            key: 4,
            item: _ctx.item,
            type: _ctx.type
          }, null, 8, ["item", "type"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_ActionsDropdown, {
            placement: "left-start",
            actions: _ctx.cardActions,
            onAction: _ctx.actionHandler
          }, null, 8, ["actions", "onAction"])
        ]))
      : _createCommentVNode("", true)
  ], 6))
}