/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { CaseStatusEnum } from './CaseStatusEnum';
export type Case = {
    readonly id: number;
    /**
     * Case creation date
     */
    readonly created_date: string | null;
    /**
     * Case modification date
     */
    readonly modified_date: string | null;
    /**
     * Case name, up to 256 characters
     */
    name: string;
    /**
     * Incident date
     */
    incident_date?: string | null;
    permissions?: Record<string, string>;
    readonly videos_count: number;
    readonly participants_count: number;
    readonly photos_count: number;
    readonly clusters_count: number;
    /**
     * Comment, up to 2048 characters
     */
    comment?: string;
    created_by?: number;
    status: CaseStatusEnum;
    error?: string | null;
    /**
     * Record id
     */
    record_id?: string | null;
    /**
     * Record created date
     */
    record_created_date?: string | null;
};
export const EmptyCase: Case = {
    id: 0,
    /**
     * Case creation date
     */
    created_date: null,
    /**
     * Case modification date
     */
    modified_date: null,
    /**
     * Case name, up to 256 characters
     */
    name: '',
    /**
     * Incident date
     */
    incident_date: '',
    permissions: {},
    videos_count: 0,
    participants_count: 0,
    photos_count: 0,
    clusters_count: 0,
    /**
     * Comment, up to 2048 characters
     */
    comment: '',
    created_by: 0,
    status: CaseStatusEnum.Open,
    error: null,
    /**
     * Record id
     */
    record_id: null,
    /**
     * Record created date
     */
    record_created_date: null,
};
