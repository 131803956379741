import { extractFileNameFromUrl } from '@/store/verify/utils';
import { asDefined, exception } from '@/common/utils';
import { ReportUrl } from '../../../types';
import { extractReportUrlByFormat, extractReportUrls } from '../../../utils';
import { ReportSource, ReportSourceFetchHandlers, ReportSourceFetchProgressInPercents, ReportSourceSequence } from './source';
import { Report, ReportFile, ReportFileName, ReportFileUrl, ReportFormat, ReportUrlWithReport } from './types';

type ReportFetchChuckReceivedHandler = (name: ReportFileName, length: ResponseBodyStreamChunkLength, size: number) => void;

type ResponseBodyStreamChunk = Uint8Array;

type ResponseBodyStreamChunkLength = Uint8Array['length'];

type ResponseBodyStreamChunkReceivedHandler = (length: ResponseBodyStreamChunkLength) => void;

type ResponseBodyStreamReader = ReadableStreamDefaultReader<ResponseBodyStreamChunk>;

export class ReportSourceImpl implements ReportSource {
  static create(): ReportSourceImpl {
    return new this();
  }

  private constructor() {}

  fetch(report: Report, format: ReportFormat, handlers?: ReportSourceFetchHandlers): Promise<ReportFile> {
    let received = 0;
    let url = extractReportUrlByFormat(report, format);
    return this.fetchByUrl({ url, report }, (name, length, size) => {
      received += length;
      const percentage = computeFetchProgressInPercents(received, size);
      handlers?.onProgress({ name, percentage, position: 1, summary: 1 });
    });
  }

  async *fetchAllSequentially(reports: Report[], handlers?: ReportSourceFetchHandlers): ReportSourceSequence {
    const urls = computeReportUrls(reports);

    for (let position = 0; position < urls.length; position += 1) {
      yield this.fetchByUrl(urls[position], (name) => {
        const percentage = computeFetchProgressInPercents(position + 1, urls.length + 1);
        handlers?.onProgress({ name, percentage, position: position + 1, summary: urls.length });
      });
    }
  }

  private fetchByUrl(url: ReportUrlWithReport, onChunkReceived: ReportFetchChuckReceivedHandler): Promise<ReportFile> {
    return fetch(url.url).then((response) => this.handleFetchResponse(url, response, onChunkReceived));
  }

  private async handleFetchResponse(url: ReportUrlWithReport, response: Response, onChunkReceived: ReportFetchChuckReceivedHandler): Promise<ReportFile> {
    responseIsOk(response);
    // const name = extractFileNameFromUrl(response.url);
    const extension = url.url.split('.').pop();
    const name = `${url.report.name}.${extension}`;
    const reader = getResponseBodyStreamReader(response);
    const contentLength = response.headers.get('content-length');
    const size = contentLength ? +contentLength : 1e6;
    return new File(await readResponseBodyStream(reader, (length) => onChunkReceived(name, length, size)), name);
  }
}

function computeReportUrls(reports: Report[]): ReportUrlWithReport[] {
  return reports.map(extractReportUrls).flat();
}

function responseIsOk(response: Response): void {
  !response.ok && exception(`Unable to download the report from ${response.url}.`);
}

function getResponseBodyStreamReader(response: Response): ResponseBodyStreamReader {
  return asDefined(response.body?.getReader(), 'Unable to read the report stream.');
}

async function readResponseBodyStream(reader: ResponseBodyStreamReader, onChunkReceived: ResponseBodyStreamChunkReceivedHandler) {
  const chunks: ResponseBodyStreamChunk[] = [];

  async function readChunk(): Promise<void> {
    const chunk = await reader.read();
    !chunk.done && (saveChunk(chunk.value), await readChunk());
  }

  function saveChunk(chunk: ResponseBodyStreamChunk): void {
    chunks.push(chunk);
    onChunkReceived(chunk.length);
  }

  return await readChunk(), chunks;
}

function computeFetchProgressInPercents(received: number, summary: number): ReportSourceFetchProgressInPercents {
  return Math.min(Math.round((received / summary) * 100), 100);
}
