export default {
  line: 'линия',
  information: 'сведения',
  create_lines: 'Создать линию',
  delete: 'Удалить',
  deactivate: 'Деактивировать',
  filter_events: 'Фильтровать события по линии',
  edit: 'Редактировать линию',
  lines_no_items: 'Создайте первую линию',
  id: 'ID',
  name: 'Название',
  name_placeholder: 'Введите название',
  no_camera: 'Камера не выбрана',
  description: 'Описание',
  data_source_group: 'Группа источника данных',
  data_source: 'Источник данных',
  camera: 'Камера',
  video_archive: 'Видеоархив',
  video: 'Видео',
  cameras: 'Камеры',
  videos: 'Видео',
  detectors: 'Детекторы',
  bbox_intersection_point: 'Задать точку пересечения линии bbox\'ом',
  not_found_items: 'Линии не найдены',
  has_backward_line_crossing: 'Есть обратное пересечение',
  with_backward_line_crossing: 'С обратным пересечением',
  without_backward_line_crossing: 'Без обратного пересечения',
  line_crossing_direction: 'Направление пересечения линии',
  line_crossing_direction_a_b: 'A→B',
  line_crossing_direction_b_a: 'B→A',
  line_crossing_direction_none: 'отсутствует',
  line_id: 'ID линии',
  enter_line_name: 'Введите название линии',
  data_source_settings_warning:
    'Пересечение линий не будет работать, потому что в настройках камеры отключена отправка истории трека. История треков должна быть включена для всех детекторов.',
  data_source_line_warning: 'Пересечение линий не будет работать пока вы не нарисуете линию во вкладке "Линия"',
  data_source_detectors_settings: 'Вы можете изменить это в настройках камеры',
  bi: 'BI Аналитика',
  line_attributes: 'Атрибуты пересечения линии',
  line_attributes_desc: 'Метка будет использована для фильтрации и группировки данных в BI системе. ',
  entrance: 'Вход',
  entrance_name_desc: 'Наименование будет отображаться на графиках в BI системе: “Название линии“, ”Название входа”',
  entrance_attributes: 'Атрибуты',
  entrance_attributes_desc: 'Метка будет использована для фильтрации и группировки данных в BI системе.',
  exit: 'Выход',
  exit_attributes: 'Атрибуты',
  exit_name_desc: 'Наименование будет отображаться на графиках в BI системе: “Название линии“, ”Название выхода”',
  exit_attributes_desc: 'Метка будет использована для фильтрации и группировки данных в BI системе.',
  attributes_placeholder: 'Выбрать атрибуты',
  roi_desc_1: 'Для изменения направления нажмите на Ⓐ или Ⓑ на картинке.\n' + '',
  roi_desc_2:
    'При необходимости подсчета людей, ТС внутри периметра и работе с данными в BI системе важно использовать направление A→B для Входа и направление B→A для Выхода.'
};
