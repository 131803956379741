import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "external-search-result-info" }
const _hoisted_2 = { class: "external-search-result-info__id" }
const _hoisted_3 = { class: "external-search-result-info__date" }
const _hoisted_4 = { class: "external-search-result-info__camera" }
const _hoisted_5 = {
  key: 0,
  class: "external-search-result-info__camera-name"
}
const _hoisted_6 = {
  key: 1,
  class: "external-search-result-info__camera-address"
}
const _hoisted_7 = {
  key: 2,
  class: "external-search-result-info__camera-location"
}
const _hoisted_8 = {
  key: 3,
  class: "external-search-result-info__camera-location"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDateTimeLabel = _resolveComponent("NDateTimeLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "#" + _toDisplayString(_ctx.item.id), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_NDateTimeLabel, {
        size: "m",
        "model-value": _ctx.item.external_timestamp
      }, null, 8, ["model-value"]),
      _createTextVNode(" #" + _toDisplayString(_ctx.item.external_face_id), 1)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.camera) + " #" + _toDisplayString(_ctx.item.puppet_id), 1),
    (_ctx.item.name)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.item.name), 1))
      : _createCommentVNode("", true),
    (_ctx.item.address)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.item.address), 1))
      : _createCommentVNode("", true),
    (_ctx.item.latitude && _ctx.item.longitude)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('common.location', 'f')) + ": " + _toDisplayString(_ctx.item.latitude) + ", " + _toDisplayString(_ctx.item.longitude), 1))
      : _createCommentVNode("", true),
    (_ctx.item.azimuth)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('common.azimuth', 'f')) + ": " + _toDisplayString(_ctx.item.azimuth) + "°", 1))
      : _createCommentVNode("", true)
  ]))
}