
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import NFilterManager from '@/components/common/filter/manager/filter-manager/NFilterManager.vue';
import { FilterValue } from '@/components/common/filter/filter-manager';
import { isEqual } from 'lodash';
import { UsersService } from '@/api';
import { LatLngType } from '@/components/map/types';

export type MapView = {
  center: LatLngType;
  zoom: number;
  bounds?: any;
  isInitDefault?: boolean;
};

const GlobalMapZoneList: { items: FilterValue[] } = reactive({ items: [] });
export const clearMapZoneList = () => {
  GlobalMapZoneList.items = [];
};

@Options({
  name: 'MapZones',
  components: { NFilterManager }
})
export default class MapZones extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: MapView;

  lastSelectedZone: any = null;

  get items() {
    return GlobalMapZoneList.items;
  }
  set items(items: FilterValue[]) {
    GlobalMapZoneList.items = items;
  }

  get labels() {
    return {
      label: this.$t('common.more_zones'),
      headerLabel: this.$t('common.apply_zone'),
      footerLabel: this.$t('common.save_selected_zone'),
      placeholder: this.$t('common.enter_zone_name')
    };
  }

  get modelValueJson() {
    const mv = this.modelValue;
    return JSON.stringify({
      center: {
        lat: Math.round(mv.center?.lat * 1e5) / 1e5,
        lng: Math.round(mv.center?.lng * 1e5) / 1e5
      },
      zoom: mv.zoom
    });
  }

  get selectedId(): undefined | string {
    const item = this.items.find((v) => v.value === this.modelValueJson);
    return item?.id;
  }

  @Watch('selectedId')
  updateLastSelectedZone() {
    const item = this.items.find((v) => v.value === this.modelValueJson);
    if (item) {
      this.lastSelectedZone = item;
    }
  }

  openLastZone() {
    this.selectHandler(this.lastSelectedZone);
  }

  setDefaultView() {
    const item = this.items.find((v) => v.selected === true);
    if (item) {
      this.selectHandler(item, true);
    }
  }

  mounted() {
    nextTick(async () => {
      await this.loadItems();
      this.setDefaultView();
    });
  }

  async loadItems() {
    if (this.items.length) {
      return;
    }
    try {
      const userSavedData = await UsersService.usersMeDataList();
      if (userSavedData) {
        const item = userSavedData.find((item) => item.key === 'zones');
        this.items = JSON.parse(item?.value ?? '[]');
      }
    } catch (e) {
      console.warn('[users.service]:getUsersSavedData error', e);
      throw new Error('[user saved data] load error');
    }
  }

  async saveItems() {
    try {
      await UsersService.usersMeDataUpdate('zones', { key: 'zones', value: JSON.stringify(this.items) ?? '[]' });
    } catch (e) {
      console.warn('MapZones:saveItems error', e);
    }
  }

  async addHandler(item: FilterValue) {
    const newItem = { ...item, value: this.modelValueJson };
    const previousItemIndex = this.items.findIndex((v) => v.value === this.modelValueJson);
    if (previousItemIndex > -1) {
      this.items.splice(previousItemIndex, 1);
    }
    this.items.push(newItem);
    await this.saveItems();
  }

  async selectHandler(item: FilterValue, isInitDefault = false) {
    this.items.map((v) => {
      return v.id === item.id ? (v.selected = true) : (v.selected = false);
    });
    this.$emit('update:modelValue', { ...JSON.parse(item.value), isInitDefault } as MapView);
    await this.saveItems();
  }

  async deleteHandler(id: string) {
    if (this.lastSelectedZone?.id === id) {
      this.lastSelectedZone = null;
    }
    this.items.map((item) => {
      return item.id === id ? (item.deleted = true) : item.deleted;
    });
    await this.saveItems();
  }

  async hideHandler() {
    const remainingItems = this.items.filter((filter) => !filter.deleted);
    if (!isEqual(this.items, remainingItems)) {
      this.items = remainingItems;
      await this.saveItems();
    }
  }

  async undoHandler(id: string) {
    this.items.map((item) => {
      return item.id === id ? (item.deleted = false) : item.deleted;
    });
    await this.saveItems();
  }
}
