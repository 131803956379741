import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sort-dropdown-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckboxIcon = _resolveComponent("NCheckboxIcon")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, null, {
    toggle: _withCtx(({ toggle }) => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.pin && _ctx.pinKey)
          ? (_openBlock(), _createBlock(_component_NCheckboxIcon, {
              key: 0,
              "model-value": _ctx.pinnedValue,
              name: "pin",
              size: 13,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
              "onUpdate:modelValue": _ctx.togglePin
            }, null, 8, ["model-value", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_NButton, {
          type: "link",
          onClick: toggle,
          icon: "arrows-up-down"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.button_i18n_label, 'f')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ])
    ]),
    body: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ i18n_label, value }) => {
        return (_openBlock(), _createBlock(_component_NDropdownItem, {
          selected: _ctx.modelValue === value,
          key: value,
          select: "",
          onClick: ($event: any) => (_ctx.selectHandler(value))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(i18n_label)), 1)
          ]),
          _: 2
        }, 1032, ["selected", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}