
import { Options, Vue } from 'vue-class-component';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import NInput from '@/uikit/input/NInput.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { request } from '@/api/core/request';
import { ISelectItem } from '@/uikit';

type SearchResultItem = {
  lat: string;
  lon: string;
  value: string;
};

const SearchDelayMs = 300;

@Options({
  name: 'MapSearch',
  components: {
    NSelect,
    NInput,
    LMarker,
    LIcon
  },
  emits: ['select']
})
export default class MapSearch extends Vue {
  selected: ISelectItem = { label: '', value: null };
  delayTimeout = 0;

  async handleSelectResult(item?: ISelectItem) {
    if (item) {
      this.$emit('select', item.value);
      this.selected = { label: item.label, value: item.value };
    }
  }

  delayedSearch(query: string) {
    let resolve = (v: unknown) => {};
    let reject = () => {};
    const promise = new Promise((r, j) => {
      resolve = r;
      reject = j;
    });
    clearTimeout(this.delayTimeout);
    this.delayTimeout = window.setTimeout(() => {
      this.search(query).then(resolve).catch(reject);
    }, SearchDelayMs);

    return promise;
  }

  async search(query: string) {
    if (query) {
      const results = await request<SearchResultItem[]>({
        method: 'GET',
        path: '/geocoding/search/',
        query: { query }
      });
      return results.map(({ value, lat, lon }) => ({ value: { lat: Number(lat), lng: Number(lon) }, label: value }));
    }

    return [];
  }

  clear() {
    this.selected = { value: null, label: '' };
  }
}
