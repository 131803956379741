
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ObjectsSingleToMulti } from '@/store/application/data.assets';
import { NAttachment, NAttachmentFileBlob, NAttachments, NAttachmentsI18n } from '@/uikit/attachments';
import { BboxConfig } from '@/uikit/bbox/types';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import Features from '@/components/common/Features.vue';
import DetectionImageViewer from '@/components/detection/DetectionImageViewer.vue';

@Options({
  components: {
    DetectionImageViewer,
    Features,
    NAttachments,
    NLoadingCircle
  }
})
export default class VerifyImages extends Vue {
  @Prop({ type: File })
  readonly sourceFile?: File;

  @Prop({ type: Boolean, default: false })
  readonly loading = false;

  private localFiles: File[] = [];
  private bboxItem?: BboxConfig = { box: { w: 0, h: 0, x: 0, y: 0 }, meta: {} };

  get file() {
    return this.sourceFile || this.localFiles[0];
  }

  get attachmentsI18n(): NAttachmentsI18n {
    return {
      droparea_label: this.$t('verify.dnd_placeholder', 'f'),
      droparea_link_label: this.$t('verify.select_files', 'f')
    };
  }

  get features() {
    return this.bboxItem?.meta?.features;
  }
  get objects() {
    return this.bboxItem?.meta.type && ObjectsSingleToMulti[this.bboxItem.meta.type];
  }
  get bboxInfo() {
    return {
      width: this.bboxItem?.box.w,
      height: this.bboxItem?.box.h,
      quality: this.bboxItem?.meta.detection_score
    };
  }

  get sizeParams() {
    return `${this.$t('verify.width')}/${this.$t('verify.height')}: ${this.bboxInfo.width}/${this.bboxInfo.height}`;
  }

  uploadHandler([attachment]: NAttachment[]) {
    this.localFiles.push(attachment.file as NAttachmentFileBlob);
  }

  selectBBox(value: BboxConfig[]) {
    this.bboxItem = value[0];
    const selectedBox = this.bboxItem
      ? {
          detectionId: this.bboxItem.meta.id,
          object: this.bboxItem.meta.type
        }
      : {};
    this.$emit('select-bbox', selectedBox);
  }

  @Watch('file')
  handlerFile() {
    this.bboxItem = undefined;
  }
}
