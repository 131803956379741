
import { debounce } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { ListViewModel } from '@/definitions/view-models';
import { CustomReportsService } from '@/api/services/CustomReportsService';
import { dataAssetsModule, DataAssetsModule } from '@/store/application/data.assets.module';
import { PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { configModule } from '@/store/config/index';
import { NButton } from '@/uikit';
import { exception } from '@/uikit/utils';
import SettingsBar from '@/components/common/SettingsBar.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import SettingsPageLayout from '@/pages/settings/SettingsPageLayout.vue';
import { CustomReportData, CustomReportFormData, CustomReportFormItem, CustomReportItem } from './types';
import { renderDialogComponent } from '@/common/render';
import CustomReportDialog from '@/pages/custom-reports/CustomReportDialog.vue';
import { CustomReportForm } from '@/pages/custom-reports/CustomReportForm';
import qs from 'qs';
import { dataServiceRepository } from '@/api/common';
import notify from '@/uikit/notification/helpers/notification';
import { h } from '@vue/runtime-core';
import ReportNotification from '@/components/reports-form/ReportNotification.vue';
import { getDelay } from '@/definitions/common/base';

@Options({
  components: {
    NButton,
    SettingsBar,
    SettingsPageLayout,
    SortDropdown
  }
})
export default class CustomReportsPage extends Vue {
  items: CustomReportItem[] | undefined = [];
  loading = false;
  searchQuery = '';

  get module(): ListViewModel<any, any> {
    return pageModule.getPageModule(this.state) as unknown as ListViewModel<any, any>;
  }

  get state(): PageState {
    const tab = 'custom-reports';
    return pageModule.getPageStateByTab(PagePaths.CustomReports, tab);
  }

  get filteredItems() {
    return this.items?.filter((item) => item.description.toLowerCase().includes(this.searchQuery.toLowerCase()));
  }

  async renderForm(form: CustomReportFormData) {
    let result;
    const { title, schema } = form;
    try {
      const defaults = schema.reduce<Record<string, any>>((m: any, v: CustomReportFormItem) => {
        if (v.default) m[v.name] = v.default;
        return m;
      }, {});
      result = await renderDialogComponent<any>(CustomReportDialog, {
        title: title,
        schema: CustomReportForm.computeSchema(schema),
        defaults
      });
    } catch (e) {
      console.warn('Form reject', e);
    }
    return result;
  }

  async handleAction(item: CustomReportItem) {
    const formResult = item.form ? await this.renderForm(item.form) : null;
    if (item.form && !formResult) return;
    this.loading = true;
    item.loading = true;
    try {
      const optionalQueryString = formResult ? '?' + qs.stringify(formResult) : '';
      // await getDelay(2000); // uncomment for demo
      await CustomReportsService.customReportsCreate(item.path + optionalQueryString);
      await notify({ content: () => h(ReportNotification), showClose: true });
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
      item.loading = false;
    }
  }

  load() {
    if (configModule.custom_reports !== undefined) {
      configModule.custom_reports.map((el: CustomReportData) => {
        this.items?.push({
          ...el,
          loading: false
        });
      });
    }
  }

  handleSearch(query: string) {
    if (this.searchQuery !== query) this.searchQuery = query;
  }

  mounted() {
    this.load();
  }
}
