import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-group-row" }
const _hoisted_2 = { class: "card-group-row__confidence-block" }
const _hoisted_3 = {
  key: 0,
  class: "card-group-row__confidence"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardConfidence = _resolveComponent("CardConfidence")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.confidence)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_CardConfidence, {
              modelValue: _ctx.confidence,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.confidence) = $event))
            }, null, 8, ["modelValue"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "card")
    ])
  ]))
}