import { ItemsActionName, ItemsActionNames } from "@/definitions/app/item.actions.name";
import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import AlertsCommentsDialog from '@/pages/alerts/AlertsCommentsDialog.vue';
import { dataModule } from '@/store/data';
import { AnnexAlarmModelName, annexModule } from '@/thirdpaties/annex/annex.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { adaptItem } from '@/pages/annex/alarms/AnnexAlarmAdapter';
import { photoViewerController } from '@/components/photo-viewer/PhotoViewerController';
import { dialogController } from '@/common/DialogController';
import { videoPlayerController } from '@/components/video-player/VideoPlayerController';
import { annexWebsocketModule } from '@/store/ws/annex.websocket.module';
import { getDelay } from '@/definitions/common/base';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { configModule } from '@/store/config';
import { languageModule, LanguageModule } from '@/store/languages';
import { viewModelRepository } from '@/api/common';
import { ImageViewerActions } from "@/uikit/image-viewer/types";

const PhotoViewerActions = [ImageViewerActions.Zoom, ImageViewerActions.Detect, ImageViewerActions.Copy, ImageViewerActions.Download, ImageViewerActions.Fullscreen, ImageViewerActions.Close];

async function updateItem(item: AnnexAlarm, payload: any) {
  const module = annexModule.getAnnexAlarmsItemViewModel();
  module.setItemsState(item);
  if (item.ack_value < 0) {
    annexWebsocketModule.sendMessage(annexWebsocketModule.getAckMessage({ ddp_id: item.ddp_id, ...payload }));
    item.ack_author = payload.ack_author;
    item.ack_value = payload.ack_value;
    if (item.id) {
      autoUpdateHelper.updateHandler(AnnexAlarmModelName, item);
    }
    await getDelay(200);
  } else {
    await module.update({ id: item.id, ...payload });
  }
}

async function getFastCommentsByAlertRule(source: AnnexAlarm): Promise<string[] | undefined> {
  const id = source.rule_id as number;
  if (!(id > 0)) return;
  try {
    let item = dataModule.alertsModule.items.find((v) => v.id === id);
    if (!item) {
      const itemVM = viewModelRepository.getAlertsItemViewModel();
      await itemVM.get(id);
      item = itemVM.item;
      dataModule.alertsModule.items.push(item!);
    }
    return item?.quick_replies;
  } catch (e) {
    console.warn('[getFastComments] Cant get fast comments for rule ', id);
    return;
  }
}

async function addCommentAndComplete(item: AnnexAlarm) {
  const alertRuleComments = await getFastCommentsByAlertRule(item) || [];
  const configComments = configModule.config.annex?.comments || [];
  const mergedComments = ([] as string[]).concat(configComments, alertRuleComments);

  dialogController.component = AlertsCommentsDialog;
  dialogController.props = {
    items: mergedComments
  };
  dialogController.action = async (payload: any) => {
    const author = dataModule.currentUserModule.item?.name;
    const acknowledgedBy = item.ack_author || languageModule.getTranslatedToken('annex_alarms.system', 'f');
    const inWorkMessage = languageModule.getTranslatedToken('annex_alarms.statuses.in_work', 'f');
    const comment = (item.comment ? `${item.comment}; ` : `${inWorkMessage} (${acknowledgedBy}); `) + `${payload} (${author})`;
    dialogController.show = false;
    await updateItem(item, { comment: comment, ack_author: author });
  };
  dialogController.show = true;
}

export async function annexAlarmActionHandler(action: string, item: AnnexAlarm) {
  const author = dataModule.currentUserModule.item?.name;

  if (action === 'addCommentAndComplete') {
    addCommentAndComplete(item);
    return;
  }

  if (action === 'ack_success') {
    await updateItem(item, { ack_value: 1, ack_author: author });
    return;
  }

  if (action === 'ack_failure') {
    await updateItem(item, { ack_value: 0, ack_author: author });
    return;
  }

  if (action === 'click' || action === ItemsActionNames.ShowInfo) {
    multisidebarModule.addItemAndOpen(MultisidebarItemTypes.AnnexAlarms, item);
    return;
  }

  if (action === 'select') {
    multisidebarModule.toggleItemSelect(MultisidebarItemTypes.AnnexAlarms, item);
    return;
  }

  if (action === ItemsActionNames.ShowFullScreen) {
    const adaptedItem = adaptItem(item);
    photoViewerController.show({
      fullframe: adaptedItem.fullframe,
      bboxes: adaptedItem.bboxes,
      linePoints: item?.extra?.line?.points,
      trackPoints: adaptedItem.track
    }, { actions: PhotoViewerActions });
    return;
  }

  if (action === ItemsActionNames.ShowPlayer) {
    const timeFrom = new Date(item.declared_at).getTime() / 1000;
    const adaptedItem = adaptItem(item);
    adaptedItem?.camera?.id && videoPlayerController.playArchive(adaptedItem?.camera?.id, timeFrom - 3);
    return;
  }

  actionHandler.run(action as ItemsActionName, item);
}
