/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportFormatEnum {
    XLS = 'xls',
    JSON = 'json',
    CSV = 'csv',
    PDF = 'pdf',
    DOCX = 'docx',
    CUSTOM = 'custom'
}