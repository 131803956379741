import { UsersService } from '@/api';
import { Debounce } from '@/common/debounce-decorator';
import { getLogger } from 'loglevel';

const logger = getLogger('[sort-module]');
logger.setLevel('info');

export class SortModule {
  static Name = 'SortModule';
  static StorageKey = 'sort';

  private items: Record<string, string> = {};
  private loaded = false;
  public readyPromise!: Promise<boolean>;
  private resolveHandler?: (v: boolean) => void;

  constructor() {
    this.reset();
  }

  reset() {
    this.items = {};
    this.loaded = false;
    this.readyPromise = new Promise((resolve, reject) => {
      this.resolveHandler = resolve;
    });
  }

  async save(name: string, value: string): Promise<boolean> {
    this.items[name] = value;
    await this.saveToUserStore();
    logger.info(` '${name}':${value} saved `);
    return true;
  }

  async delete(name: string) {
    delete this.items[name];
    logger.info(` '${name}' deleted `);
    await this.saveToUserStore();
  }

  get(name: string | undefined): string | undefined {
    if (!this.loaded) logger.warn(`[${SortModule.Name}] get '${name}' error: module is not loaded`);
    const r = name ? this.items[name] : undefined;
    logger.info(` '${name}' got with value ${r}`);
    return name ? this.items[name] : undefined;
  }

  async load() {
    try {
      const data = await UsersService.usersMeDataRetrieve(SortModule.StorageKey);
      const items = JSON.parse(data?.value);
      Object.assign(this.items, items);
    } catch (e) {
      logger.warn('load error, but it might be ok then it is empty', e);
    } finally {
      logger.info(` loaded, items: ${JSON.stringify(this.items)}`);
      this.loaded = true;
      if (this.resolveHandler) {
        this.resolveHandler(true);
        this.resolveHandler = undefined;
      }
    }
  }

  @Debounce(200)
  async saveToUserStore() {
    try {
      await UsersService.usersMeDataUpdate(SortModule.StorageKey, {
        key: SortModule.StorageKey,
        value: JSON.stringify(this.items) ?? ''
      });
    } catch (e) {
      console.warn('[users.service]:saveFilters error', e);
    }
  }
}

export const sortModule = new SortModule();
