import { getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';
import { ISelectItem, NCheckbox, NInput, NSelect } from '@/uikit';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { getDelay } from '@/definitions/common/base';
import { axiosInstanceConfig } from '@/definitions/services/axiosInstanceConfig';
import { dataServiceFactory } from '@/definitions/services/data.services';
import ItemCommonFields from "@/components/common/ItemCommonFields.vue";
import NTextareaAutosize from "@/uikit/textarea/NTextareaAutosize.vue";

const generalSchema: IFormLayout = [
  [
    {
      path: 'name',
      classes: 'control-m n-form-w-4 n-form-pad-10',
      i18n_label: 'common.name',
      component: NInput,
      validators: [getRequiredValidator(), getStringLengthValidator({ required: true, min: 3, max: 100 })]
    },
    {
      path: 'active',
      classes: 'control-m n-form-w-1 n-form-pad-10',
      i18n_label: 'common.active',
      component: NCheckbox
    }
  ],
  {
    path: 'description',
    classes: 'control-m n-form-w-6 n-form-pad-10',
    i18n_label: 'common.comment',
    component: NTextareaAutosize
  },
  {
    classes: 'control-m n-form-w-6 n-form-pad-10',
    i18n_label: 'common.tags',
    component: NSelect,
    props: {
      multiple: true,
      allowCreate: true,
      keyField: '',
      hideChevron: false,
      displayCreated: false,
      i18n_placeholder: 'common.tags',
      loadItems: async () => {
        const axios = dataServiceFactory.getAxiosInstance();
        const result = await axios.get('/v1/alert-rules/tags/');
        return (result.data?.tags || []).map((v: string) => ({ value: v, label: v }));
      }
    },
    encode: function encode(this: IFormContext, model: IFormModel, value: ISelectItem[]) {
      let result: Record<string, string> = {};
      value.forEach((item) => {
        result[item.label] = 'true';
      });
      this.model.tags = Object.keys(result).filter((v: string) => !!v);
    },
    decode: function decode(this: IFormContext) {
      let value: ISelectItem[] = [];
      (this.model?.tags || []).forEach((label: string) =>
        value.push({
          label: label,
          value: label
        })
      );
      return value;
    }
  },
  {
    classes: 'n-form-pad-10',
    component: ItemCommonFields,
    props: (item: any) => ({ item })
  }
];

export default generalSchema;
