import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "n-filter-manager n-dropdown__body" }
const _hoisted_2 = { class: "n-filter-manager__header heading-m" }
const _hoisted_3 = { class: "n-filter-manager__item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownItemDivider = _resolveComponent("NDropdownItemDivider")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NDoubleButton = _resolveComponent("NDoubleButton")!

  return (_openBlock(), _createBlock(_component_NDoubleButton, {
    type: "secondary",
    onToggle: _ctx.dropdownToggleHandler,
    disableButton: _ctx.disableButton
  }, {
    dropdown: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.currentItems.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerLabel), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentItems, (item) => {
                return (_openBlock(), _createBlock(_component_NDropdownItem, {
                  key: item.id,
                  class: _normalizeClass(["n-filter-manager__item", item.deleted ? 'n-filter-manager__item_deleted' : '']),
                  onClick: _withModifiers(($event: any) => (_ctx.selectHandler(item)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    (_ctx.selected === item.id)
                      ? (_openBlock(), _createBlock(_component_NIcon, {
                          key: 0,
                          name: "select",
                          class: "dropdown-icon"
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(item.name), 1),
                    (item.deleted)
                      ? (_openBlock(), _createBlock(_component_NButton, {
                          key: 1,
                          type: "link",
                          "suffix-icon": "revert",
                          onClick: _withModifiers(($event: any) => (_ctx.undoHandler(item.id)), ["stop"])
                        }, null, 8, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_NButton, {
                          key: 2,
                          type: "link",
                          "suffix-icon": "close",
                          onClick: _withModifiers(($event: any) => (_ctx.deleteHandler(item.id)), ["stop"])
                        }, null, 8, ["onClick"]))
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128)),
              _createVNode(_component_NDropdownItemDivider)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.displaySaveButton)
          ? (_openBlock(), _createBlock(_component_NDropdownItem, {
              key: 1,
              ref: "saveButton",
              onClick: _withModifiers(_ctx.addHandler, ["stop","prevent"]),
              onKeydown: [
                _withKeys(_ctx.addHandler, ["enter"]),
                _withKeys(_ctx.hideHandler, ["escape"])
              ],
              autofocus: "",
              tabindex: "0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.footerLabel), 1)
              ]),
              _: 1
            }, 8, ["onClick", "onKeydown"]))
          : (_openBlock(), _createBlock(_component_NDropdownItem, {
              key: 2,
              class: "new-filter"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: _ctx.placeholder,
                  modelValue: _ctx.newFilter,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newFilter) = $event)),
                  modelModifiers: { trim: true },
                  onKeydown: _withKeys(_ctx.inputHandler, ["enter"]),
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, null, 8, ["placeholder", "modelValue", "onKeydown"]),
                _createVNode(_component_NButton, {
                  type: "link",
                  "suffix-icon": "select",
                  onClick: _withModifiers(_ctx.addHandler, ["stop"]),
                  disabled: !_ctx.newFilter
                }, null, 8, ["onClick", "disabled"])
              ]),
              _: 1
            }))
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onToggle", "disableButton"]))
}