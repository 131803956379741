
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VideoArchive } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';

@Options({
  name: 'Confirm',
  components: { NButton, NModalWindow }
})
export default class Confirm extends Vue {
  @Prop({ type: String, required: true })
  description!: string;

  @Prop({ type: String, required: false })
  cancelLabel?: string;

  @Prop({ type: String, required: false })
  confirmLabel?: string;

  get computedCancelLabel() {
    return this.cancelLabel ?? this.$t('common.cancel', 'f');
  }

  get computedConfirmLabel() {
    return this.confirmLabel ?? 'Ok';
  }

  cancelHandler() {
    this.$emit('cancel');
  }

  confirmHandler() {
    this.$emit('confirm');
  }
}
