import { ITableColumn } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NText from '@/uikit/text/NText.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import AlertDelivery from '@/pages/alerts/AlertDelivery.vue';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import { Alert } from '@/api';
import TableItemCameras from '@/pages/alerts/TableItemCameras.vue';
import TableItemCameraGroups from '@/pages/alerts/TableItemCameraGroups.vue';
import TableItemTags from '@/pages/alerts/TableItemTags.vue';

function getFirstEventTypeFilters(item: any) {
  const keys = Object.keys(item.filters);
  if (keys.length) {
    return item.filters[keys[0]];
  }
  return {};
}

export const alertTableSchema: ITableColumn[] = [
  {
    width: 'max-content',
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.id',
        name: 'id',
        sortable: true
      }
    },
    content: {
      path: 'id',
      component: ButtonModelCopy
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.name',
        name: 'name',
        sortable: true
      }
    },
    content: {
      path: 'name',
      component: NButtonModel,
      props: { type: 'link', align: 'left' }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.camera'
      }
    },
    content: {
      component: TableItemCameras,
      props: (item: Alert) => {
        const filters = getFirstEventTypeFilters(item);
        return {
          class: 'label-xs',
          modelValue: filters.cameras
        };
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.camera_group'
      }
    },
    content: {
      component: TableItemCameraGroups,
      props: (item: Alert) => {
        const filters = getFirstEventTypeFilters(item);
        return {
          class: 'label-xs',
          modelValue: filters.camera_groups
        };
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.tags',
        name: 'tags'
      }
    },
    content: {
      path: 'tags',
      component: TableItemTags,
      props: (item: Alert) => {
        return {
          modelValue: item.tags
        };
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NTableColumnCell,
      props: {
        i18n_label: 'alerts.delivery',
        name: 'active'
      }
    },
    content: {
      path: 'delivery_channels',
      component: AlertDelivery
    }
  }
];
