import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "camera-popup__name heading-m" }
const _hoisted_2 = { class: "camera-popup__detectors" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NTooltip = _resolveComponent("NTooltip")!

  return (_openBlock(), _createBlock(_component_NTooltip, {
    className: "camera-popup",
    reference: _ctx.reference,
    placement: "top-left"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.item.name), 1),
      _createVNode(_component_CameraScreenshot, {
        modelValue: _ctx.item.screenshot,
        width: "180",
        delay: 0
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.detectors.face)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 0,
              name: "detect-face"
            }))
          : _createCommentVNode("", true),
        (_ctx.detectors.body)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 1,
              name: "detect-body"
            }))
          : _createCommentVNode("", true),
        (_ctx.detectors.car)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 2,
              name: "detect-auto"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["reference"]))
}