
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Counter } from '@/api';
import NFormBlocks from '@/uikit/forms/NFormBlocks.vue';
import formLayoutBlocks from './schemes/counter-form';

@Options({
  name: 'CounterForm',
  components: { NFormBlocks }
})
export default class CounterForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Counter;

  formLayoutBlocks = formLayoutBlocks;

  get formState() {
    return {
      created: this.model.id >= 0
    };
  }

  validate() {
    return this.$refs.form.validateAndDisplayErrors();
  }
}
