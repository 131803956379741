import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-from" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NHint = _resolveComponent("NHint")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NButtonGroup, {
      items: _ctx.availableSearchFromItems,
      modelValue: _ctx.state.searchFrom,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.searchFrom) = $event))
    }, null, 8, ["items", "modelValue"]),
    (_ctx.availableObjectsItems.length > 1)
      ? (_openBlock(), _createBlock(_component_NButtonGroup, {
          key: 0,
          items: _ctx.availableObjectsItems,
          modelValue: _ctx.objectType,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.objectType) = $event))
        }, null, 8, ["items", "modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NHint, {
      class: "search-from-url",
      content: _ctx.$t('search.input_error'),
      "visible-mode": "manual",
      isVisible: _ctx.hasError
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.state.id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.id) = $event)),
          placeholder: _ctx.$t('search.enter_search_objects_holder', 'f'),
          clearable: ""
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }, 8, ["content", "isVisible"]),
    (_ctx.getSourceFromMsbAvailable)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 1,
          type: "secondary",
          icon: "arrow-left",
          onClick: _ctx.getSourcesFromMsb
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}