/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PuppeteerDailyEventsFilter = {
  /**
   * Select only events with exact camera
   */
  camera?: string;
  /**
   * Select daily search events associated with the specified card
   */
  card?: number;
  /**
   * Select events with `confidence` **greater than or equal** to this value
   */
  confidence_gte?: number;
  /**
   * Select objects with `created_date` **greater** than this value
   */
  created_date_gt?: string;
  /**
   * Select objects with `created_date` **greater than or equal** to this value
   */
  created_date_gte?: string;
  /**
   * Select objects with created in last N days
   */
  created_date_last_n_days?: number;
  /**
   * Select objects with `created_date` **less** than this value
   */
  created_date_lt?: string;
  /**
   * Select objects with `created_date` **less than or equal** to this value
   */
  created_date_lte?: string;
  /**
   * Select objects with created in last Nth week (including Sunday and Saturday)
   */
  created_date_nth_full_week?: number;
  /**
   * Select objects with created in last Nth week (only working days, i.e. excluding Sunday and Saturday)
   */
  created_date_nth_work_week?: number;
  /**
   * Select events with `external_timestamp` **greater than or equal** to this value
   */
  event_timestamp_gte?: string;
  /**
   * Select events with `external_timestamp` **less than or equal** to this value
   */
  event_timestamp_lte?: string;
  /**
   * Select only events with exact face object id
   */
  face_object?: string;
  /**
   * Number of results to return per page.
   */
  limit?: string;
  /**
   * Which field to use when ordering the results. Available fields: `created_date`, `id`, `external_timestamp`
   */
  ordering?: string;
  /**
   * Pagination cursor value.
   */
  page?: string;
  /**
   * Select only events with exact puppet id
   */
  puppet_id?: string;
};
export const EmptyPuppeteerDailyEventsFilter: PuppeteerDailyEventsFilter = {
  camera: undefined,
  card: undefined,
  confidence_gte: undefined,
  created_date_gt: undefined,
  created_date_gte: undefined,
  created_date_last_n_days: undefined,
  created_date_lt: undefined,
  created_date_lte: undefined,
  created_date_nth_full_week: undefined,
  created_date_nth_work_week: undefined,
  event_timestamp_gte: undefined,
  event_timestamp_lte: undefined,
  face_object: undefined,
  limit: '20',
  ordering: '-external_timestamp',
  page: undefined,
  puppet_id: undefined
};
