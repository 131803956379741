import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "annex-alarm-item-tile" }
const _hoisted_2 = { class: "annex-alarm-item-tile__name heading-m" }
const _hoisted_3 = { class: "annex-alarm-item-tile__date" }
const _hoisted_4 = {
  key: 0,
  class: "annex-alarm-item-tile__card"
}
const _hoisted_5 = { class: "annex-alarm-item-tile__confidence" }
const _hoisted_6 = { class: "comment" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnnexAlarmAcknowledge = _resolveComponent("AnnexAlarmAcknowledge")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_AnnexAlarmAcknowledgeInfo = _resolveComponent("AnnexAlarmAcknowledgeInfo")!
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_CardItemTile = _resolveComponent("CardItemTile")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AnnexAlarmAcknowledge, {
      orientation: "horizontal",
      status: _ctx.adaptedItem.acknowledge,
      onAction: _ctx.actionHandler
    }, null, 8, ["status", "onAction"]),
    _createElementVNode("div", {
      class: _normalizeClass(["annex-alarm-item-tile__container", _ctx.classes])
    }, [
      _createVNode(_component_NThumbnail, {
        modelValue: _ctx.adaptedItem.thumbnail,
        corners: 'rounded',
        actions: _ctx.thumbnailActions,
        onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select'))),
        onAction: _cache[1] || (_cache[1] = (v) => _ctx.actionHandler(v)),
        "not-found-image-label": _ctx.$t('annex_alarms.not_found')
      }, null, 8, ["modelValue", "actions", "not-found-image-label"]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.title), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.formattedDate), 1),
      _createVNode(_component_AnnexAlarmAcknowledgeInfo, { item: _ctx.item }, null, 8, ["item"])
    ], 2),
    (_ctx.matchedCardHelper.card)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Confidence, {
              value: _ctx.confidence,
              direction: "horizontal"
            }, null, 8, ["value"])
          ]),
          _createVNode(_component_CardItemTile, {
            type: _ctx.matchedCardHelper.objectType === 'cars' ? 'cars' : 'humans',
            objectType: _ctx.matchedCardHelper.objectType,
            item: _ctx.matchedCardHelper.card,
            "model-acl": {},
            confidence: _ctx.confidence,
            selected: _ctx.matchedCardHelper.isSelected,
            opened: _ctx.matchedCardHelper.isOpened,
            "show-more": false,
            showThumbnail: false,
            showConnections: false,
            showInteractions: false,
            onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.matchedCardHelper.showCard()))
          }, null, 8, ["type", "objectType", "item", "confidence", "selected", "opened"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_NButton, {
        type: "secondary",
        widen: "",
        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
        onAction: _cache[4] || (_cache[4] = ($event: any) => (_ctx.actionHandler('addCommentAndComplete'))),
        counter: _ctx.item.comment ? 1 : undefined,
        disabled: _ctx.item.comment || _ctx.item.ack_value === -1
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('annex_alarms.comment')), 1)
        ]),
        _: 1
      }, 8, ["counter", "disabled"])
    ])
  ]))
}