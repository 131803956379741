import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "n-image-viewer__content-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "n-image-viewer__content",
  ref: "content"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "n-image-viewer__error"
}
const _hoisted_5 = { class: "n-image-viewer__error-text heading-xl" }
const _hoisted_6 = { class: "n-image-viewer__slot n-image-viewer__slot_top-right" }
const _hoisted_7 = { class: "n-image-viewer__slot n-image-viewer__slot_top-left" }
const _hoisted_8 = { class: "n-image-viewer__slot n-image-viewer__slot_bottom-right" }
const _hoisted_9 = { class: "n-image-viewer__slot n-image-viewer__slot_bottom-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_NImageViewerBboxDistancesLayer = _resolveComponent("NImageViewerBboxDistancesLayer")!
  const _component_NImageViewerBboxLayer = _resolveComponent("NImageViewerBboxLayer")!
  const _component_NImageViewerLineLayer = _resolveComponent("NImageViewerLineLayer")!
  const _component_NThemeImage = _resolveComponent("NThemeImage")!
  const _component_NImageViewerZoom = _resolveComponent("NImageViewerZoom")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "n-image-viewer",
    ref: "viewer",
    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.emit('close')), ["esc"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_NLoadingCircle, { key: 0 }))
        : (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "n-image-viewer__image",
                src: _ctx.src,
                alt: "",
                style: _normalizeStyle(_ctx.style),
                draggable: "false"
              }, null, 12, _hoisted_3),
              (_ctx.distances)
                ? (_openBlock(), _createBlock(_component_NImageViewerBboxDistancesLayer, {
                    key: 0,
                    distances: _ctx.distances,
                    scale: _ctx.scale,
                    size: _ctx.imageSize,
                    offset: _ctx.offset
                  }, null, 8, ["distances", "scale", "size", "offset"]))
                : _createCommentVNode("", true),
              (_ctx.bboxes)
                ? (_openBlock(), _createBlock(_component_NImageViewerBboxLayer, {
                    key: 1,
                    bboxes: _ctx.bboxes,
                    selectable: _ctx.bboxSelectable,
                    size: _ctx.imageSize,
                    offset: _ctx.offset,
                    scale: _ctx.scale,
                    onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('selectBbox', $event)))
                  }, null, 8, ["bboxes", "selectable", "size", "offset", "scale"]))
                : _createCommentVNode("", true),
              (_ctx.hasTrackOrLine)
                ? (_openBlock(), _createBlock(_component_NImageViewerLineLayer, {
                    key: 2,
                    track: _ctx.showTrack ? _ctx.track : [],
                    line: _ctx.line,
                    size: _ctx.imageSize,
                    offset: _ctx.offset,
                    scale: _ctx.scale
                  }, null, 8, ["track", "line", "size", "offset", "scale"]))
                : _createCommentVNode("", true)
            ], 512))
          : (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1),
                _createVNode(_component_NThemeImage, { "css-var": "--image-loading-error" })
              ]))
            : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "top-right"),
      _createElementVNode("div", {
        class: "n-image-viewer__actions",
        onDblclick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
      }, [
        (!_ctx.hasError && _ctx.zoomable && _ctx.actions.includes('zoom'))
          ? (_openBlock(), _createBlock(_component_NImageViewerZoom, {
              key: 0,
              modelValue: _ctx.scale,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.scale) = $event)),
              options: _ctx.scaleOptions
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "extra-actions"),
        (!_ctx.hasError && _ctx.actions.includes('detect'))
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              icon: "show-track",
              type: _ctx.showTrack ? 'primary' : 'secondary-alt',
              round: "",
              onAction: _ctx.toggleShowTrack
            }, null, 8, ["type", "onAction"]))
          : _createCommentVNode("", true),
        (!_ctx.hasError && _ctx.actions.includes('copy'))
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 2,
              icon: "copy",
              type: "secondary-alt",
              round: "",
              onAction: _ctx.copy
            }, null, 8, ["onAction"]))
          : _createCommentVNode("", true),
        (!_ctx.hasError && _ctx.actions.includes('download'))
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 3,
              icon: "download",
              type: "secondary-alt",
              round: "",
              onAction: _ctx.download
            }, null, 8, ["onAction"]))
          : _createCommentVNode("", true),
        (!_ctx.hasError && _ctx.actions.includes('fullscreen'))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              (_ctx.fullscreenEnabled)
                ? (_openBlock(), _createBlock(_component_NButton, {
                    key: 0,
                    icon: "fullscreen-exit",
                    type: "secondary-alt",
                    round: "",
                    onAction: _ctx.fullscreenToggle
                  }, null, 8, ["onAction"]))
                : (_openBlock(), _createBlock(_component_NButton, {
                    key: 1,
                    icon: "fullscreen",
                    type: "secondary-alt",
                    round: "",
                    onAction: _ctx.fullscreenToggle
                  }, null, 8, ["onAction"]))
            ], 64))
          : _createCommentVNode("", true),
        (!_ctx.hasError && _ctx.imageCount > 1 && _ctx.actions.includes('exclude'))
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 5,
              type: "secondary-alt",
              onAction: _ctx.exclude
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(this.i18n.exclude), 1)
              ]),
              _: 1
            }, 8, ["onAction"]))
          : _createCommentVNode("", true)
      ], 32),
      (_ctx.actions.includes('close'))
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "secondary-alt",
            icon: "close",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('close'))),
            round: ""
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "top-left")
    ]),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "bottom-right")
    ]),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "bottom-left")
    ])
  ], 544))
}