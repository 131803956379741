import router from '@/router';
import { merge, omit } from 'lodash';
import { getLogger } from 'loglevel';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { TabItemsMap } from '@/definitions/app/tab.items';
import { getDelay } from '@/definitions/common/base';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { Camera } from '@/api';
import { dataServiceRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ObjectsType, ObjectsTypesMap, SearchFromMap } from '@/store/application/data.assets';
import { PagePaths, PageTypes, SearchFrom, SearchPageState } from '@/store/application/page.definitions';
import { cameraResetModule, CameraResetType, CameraResetTypes } from '@/store/camera/camera.reset.module';
import { dialogModule } from '@/store/dialogs/dialogModule';
import { multisidebarModule } from '@/store/multisidebar';
import { getPageStateByMsbType, isMultisidebarItem, isMultisidebarRawItemPayload } from '@/store/multisidebar/helpers';
import { MultisidebarCommonItem, MultisidebarItemTypes, MultisidebarRawItemPayload } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import { photoViewerController } from '@/components/photo-viewer/PhotoViewerController';
import { dataModule } from '@/store/data/index';
import MultisidebarItem from "@/components/multisidebar/MultisidebarItem.vue";

const logger = getLogger('[action.handler]');
logger.setLevel('info');

export async function convertToMsbItem(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
  if (isMultisidebarItem(payload)) {
    return payload;
  }
  if (isMultisidebarRawItemPayload(payload)) {
    const msbItem =
      multisidebarModule.getItem(payload.type, payload.rawItem.id ?? payload.rawItem) ||
      (await multisidebarModule.convertRawItemToMsbByType(payload.type, payload.rawItem));

    if (typeof payload.rawItem === 'object') {
      const toMerge = omit(payload.rawItem, msbItem.model.changes);
      merge(msbItem.model.item, toMerge);
    }

    return msbItem;
  }
  throw new Error("Can't convert passed payload. Expected types: MultisidebarRawItemPayload | MultisidebarCommonItem");
}

class ActionHandler {
  public getIsItemFormValid?: () => Promise<boolean> | boolean;

  private async validate() {
    return this.getIsItemFormValid?.() || false;
  }

  async run(actionName: ItemsActionName, payload: unknown, showConfirmDialog = true) {
    logger.info('run: ', actionName, ', payload: ', payload, ', show confirm', showConfirmDialog);

    const hasWarning = await dialogModule.getWarning(actionName, payload as any);
    if (hasWarning) return;

    const optionalConfirmResult = showConfirmDialog ? await dialogModule.createDialog(actionName, payload as any) : true;
    if (!optionalConfirmResult) return;


    switch (actionName) {
      case ItemsActionNames.CameraResetAll:
        return (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.resetCameraFullAndSave(payload);
      case ItemsActionNames.CameraReset:
      case ItemsActionNames.CameraResetZone:
      case ItemsActionNames.CameraResetAdvanced:
      case ItemsActionNames.CameraResetDetectors:
      case ItemsActionNames.CameraResetMap:
      case ItemsActionNames.CameraResetFull:
        return (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.resetCamera(actionName, payload);
      case ItemsActionNames.AddItem:
        return isMultisidebarRawItemPayload(payload) && multisidebarModule.addItem(payload.type, payload.rawItem);
      case ItemsActionNames.CloseCurrentItem:
        multisidebarModule.closeCurrent();
        return true;
      case ItemsActionNames.CloseGroup:
        multisidebarModule.removeItemsByGroupName(payload as string);
        return true;
      case ItemsActionNames.Delete:
      case ItemsActionNames.DeleteAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.deleteMsbItemOrRaw(payload);
        return true;
      case ItemsActionNames.FilterCardEvent:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToCardEvents(payload);
        return true;
      case ItemsActionNames.FilterLineFaceEvents:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToLineEvents(payload, ObjectsTypesMap.Faces);
        return true;
      case ItemsActionNames.FilterLineBodyEvents:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToLineEvents(payload, ObjectsTypesMap.Bodies);
        return true;
      case ItemsActionNames.FilterLineCarEvents:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToLineEvents(payload, ObjectsTypesMap.Cars);
        return true;
      case ItemsActionNames.RemoveCurrentItem:
        multisidebarModule.currentItem && multisidebarModule.removeBarItem(multisidebarModule.currentItem);
        return true;
      case ItemsActionNames.Remove:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.removeBarItem(payload));
        return true;
      case ItemsActionNames.Reset:
        (payload as MultisidebarCommonItem).model.reset();
        return true;
      case ItemsActionNames.ToggleSelectItem:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.toggleItemSelect(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.ToggleCurrent:
        isMultisidebarItem(payload) && multisidebarModule.toggleCurrentBarItem(payload);
        return true;
      case ItemsActionNames.Save:
      case ItemsActionNames.SaveAll:
        if ((await this.validate()) && isMultisidebarItem(payload)) {
          const result = await multisidebarModule.save(payload);
          if (payload.type === MultisidebarItemTypes.WatchLists) {
            await getDelay(1000);
            await dataModule.loadCurrentUserModule();
          }
          return result;
        }
        return false;
      case ItemsActionNames.Duplicate:
        await this.duplicate(payload as any);
        break;
      case ItemsActionNames.SelectAll:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.addItems(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.Search:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && this.navigateToSearch(payload);
        return true;
      case ItemsActionNames.ShowFullScreen:
        photoViewerController.show(payload);
        return true;
      case ItemsActionNames.ShowItem:
        isMultisidebarRawItemPayload(payload) && multisidebarModule.addItemAndOpen(payload.type, payload.rawItem);
        return true;
      case ItemsActionNames.Activate:
      case ItemsActionNames.ActivateAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.setItemActivation(payload, true));
        return true;
      case ItemsActionNames.Deactivate:
      case ItemsActionNames.DeactivateAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.setItemActivation(payload, false));
        return true;
      case ItemsActionNames.UpdateActiveField:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.updateItemActivation(payload));
        return true;
      case ItemsActionNames.Process:
      case ItemsActionNames.ProcessAll:
        await this.process(payload);
        return true;
      case ItemsActionNames.StopProcess:
      case ItemsActionNames.StopProcessAll:
        await this.stopProcessVideoArchive(payload);
        await getDelay(2000); //@todor add delay cause it takes time to sync between VM/VW
        await this.syncVideoArchive(payload);
        return true;
      case ItemsActionNames.DeleteVideoFile:
      case ItemsActionNames.DeleteVideoFileAll:
        await this.deleteFileVideoArhive(payload);
        return true;
      case ItemsActionNames.DeleteCards:
      case ItemsActionNames.DeleteCardsInAll:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.purgeItem(payload));
        return true;
      case ItemsActionNames.AddToMonitoring:
      case ItemsActionNames.AddAllToMonitoring:
        await this.addItemToMonitoring(payload);
        return true;
      case ItemsActionNames.ExternalSearch:
        await this.openExternalSearchDialog(payload);
        return true;
      case ItemsActionNames.RemoveFromMonitoring:
      case ItemsActionNames.RemoveAllFromMonitoring:
        await this.removeItemFromMonitoring(payload);
        return true;
      case ItemsActionNames.MonitoringEvents:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.navigateToMonitoringEvents(payload));
        break;
      case ItemsActionNames.ShowInteractions:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.navigateToInteractions(payload));
        break;
      case ItemsActionNames.ResetParticipant:
        (isMultisidebarRawItemPayload(payload) || isMultisidebarItem(payload)) && (await this.resetParticipant(payload));
        break;
      case ItemsActionNames.ShowMatchedConnections:
        await multisidebarModule.addItemAndOpen(MultisidebarItemTypes.CardsHumans, payload);
        if (multisidebarModule.currentSharedState) {
          multisidebarModule.currentSharedState.activeTab = TabItemsMap.MatchedCaseClusters;
        }
        break;
      case ItemsActionNames.ShowRelatedConnections:
        await multisidebarModule.addItemAndOpen(MultisidebarItemTypes.CardsHumans, payload);
        if (multisidebarModule.currentSharedState) {
          multisidebarModule.currentSharedState.activeTab = TabItemsMap.RelatedCaseClusters;
        }
        break;
      default:
        console.warn('[actionHandler]: unknown action: ' + actionName);
        return false;
    }
  }

  private async duplicate(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    if (!isMultisidebarItem(payload)) return;
    const rawItem: Record<string, any> = { ...payload.model.item, id: -1001, name: payload.model.item.name + ' (copy)' };
    const newItem = { type: payload.type, rawItem };
    const newMsbItem = await convertToMsbItem(newItem);
    const result = await multisidebarModule.save(newMsbItem);
    await multisidebarModule.addItemAndOpen(newMsbItem.type, newMsbItem.model.item);
  }

  private async removeBarItem(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    return multisidebarModule.removeBarItem(payload);
  }

  private async deleteMsbItemOrRaw(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    await multisidebarModule.deleteItem(payload, true);
    await this.syncCase(payload);
  }

  private async syncCase(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const isCaseSources = payload.type === MultisidebarItemTypes.CasePhotos || payload.type === MultisidebarItemTypes.Videos;
    const caseId = payload.model.item?.case;
    if (isCaseSources && caseId) {
      autoUpdateHelper.updateHandler('/cases/', { id: caseId }, [], { remote: true });
    }
  }

  private async resetParticipant(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    await this.removeBarItem(payload);
    await dataServiceRepository.CaseClustersService.update(payload.model.item.id, { name: null, role: null });
    autoUpdateHelper.deleteHandler(payload.model.name, payload.model.item.id, [payload.model]);
    autoUpdateHelper.updateHandler('/cases/', { id: payload.model?.item?.case }, [], { remote: true });
  }

  private async updateItemActivation(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const model = payload.model;
    if (typeof model.item.active === 'undefined') return;
    return this.setItemActivation(payload, model.item.active);
  }

  private async setItemActivation(payload: MultisidebarRawItemPayload | MultisidebarCommonItem, active: boolean) {
    payload = await convertToMsbItem(payload);
    const model = payload.model;
    if (typeof model.item.active === 'undefined') return;
    const updatePayload = { id: model.item.id, active };
    const changes = model.changedData;
    delete changes.active;
    try {
      await model.update(updatePayload);
    } catch (e) {
      if (e.isAxiosError) {
        console.warn(`[actionHandler]: can't toggle active of item: ${payload.id}. Error: ` + e);
      } else {
        throw e;
      }
    }
    Object.assign(model.item, changes);
    autoUpdateHelper.updateHandler((model as any).name, model.item, [model]); // @todor
  }

  private async resetCameraFullAndSave(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    await cameraResetModule.reset(payload.model.item as Camera, CameraResetTypes.Full);
    await payload.model.save();
  }

  private async resetCamera(actionName: ItemsActionName, payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    const CameraResetActionPrefix = 'camera-reset-';
    payload = await convertToMsbItem(payload);
    const resetType = actionName.replace(CameraResetActionPrefix, '');
    return cameraResetModule.reset(payload.model.item as Camera, resetType as CameraResetType);
  }

  private async process(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    switch (msbItem.type) {
      case MultisidebarItemTypes.Videos:
        await dataServiceRepository.VideosService.createItemSomethingByAction(msbItem.model.item.id, 'process');
        await this.syncVideoArchive(msbItem);
        break;
      case MultisidebarItemTypes.CasePhotos:
        await dataServiceRepository.CasePhotosService.createItemSomethingByAction(msbItem.model.item.id, 'process');
        await this.syncCasePhoto(msbItem);
        break;
    }
  }

  private async stopProcessVideoArchive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    return dataServiceRepository.VideosService.createItemSomethingByAction(msbItem.model.item.id, 'stop');
  }

  private async syncVideoArchive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    await msbItem.model.get(msbItem.model.item.id);
    autoUpdateHelper.updateHandler(msbItem.model.name, msbItem.model.item, [msbItem.model]); // @todo check relevance
  }

  private async syncCasePhoto(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    await msbItem.model.get(msbItem.model.item.id);
    autoUpdateHelper.updateHandler(msbItem.model.name, msbItem.model.item, [msbItem.model]); // @todo check relevance
  }

  private async deleteFileVideoArhive(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    const axios = dataServiceFactory.getAxiosInstance();
    return axios.delete(`videos/${msbItem.model.item.id || ''}/file/`);
  }

  private async navigateToSearch(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const pageState: Partial<SearchPageState> = { ...getPageStateByMsbType(payload.type), id: payload.model.item.id };
    const searchFrom = pageState.pageType as SearchFrom;
    pageState.searchFrom = Object.values(SearchFromMap).includes(searchFrom) ? searchFrom : 'file';
    const to = RouterModule.getRouteLocation({ path: PagePaths.Search, pageState });
    await router.push(to);
    multisidebarModule.toggleCurrentBarItem(payload);
  }

  private async navigateToCardEvents(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    const pageState = { ...getPageStateByMsbType(payload.type), pageType: PageTypes.Events, filter: { matched_card: payload.model.item.id } };
    pageState.objectType = pageState.cardType === ObjectsTypesMap.Cars ? ObjectsTypesMap.Cars : ObjectsTypesMap.Faces;
    const to = RouterModule.getRouteLocation({ path: PagePaths.Events, pageState });
    await router.push(to);
  }

  private async navigateToLineEvents(payload: MultisidebarRawItemPayload | MultisidebarCommonItem, objectType: ObjectsType) {
    payload = await convertToMsbItem(payload);
    const pageState = { ...getPageStateByMsbType(payload.type), pageType: PageTypes.Events, filter: { line: payload.model.item.id } };
    pageState.objectType = objectType;
    const to = RouterModule.getRouteLocation({ path: PagePaths.Events, pageState });
    await router.push(to);
  }

  private async navigateToMonitoringEvents(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    let filter: Record<string, any> = {};
    if (payload.type === MultisidebarItemTypes.WatchLists) {
      filter['watch_lists'] = [payload.model.item.id];
    } else {
      filter['card'] = [payload.model.item.id];
    }

    const to = RouterModule.getRouteLocation({
      path: PagePaths.RemoteMonitoringEvents,
      pageState: { filter }
    });
    await router.push(to);
  }

  private async navigateToInteractions(payload: MultisidebarRawItemPayload | MultisidebarCommonItem) {
    payload = await convertToMsbItem(payload);
    RouterModule.navigateToInteractions({ card: payload.model.item.id });
  }

  private async purgeItem(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    const id = msbItem.model.item.id;
    return id && msbItem.model.dataService.createItemSomethingByAction(id, 'purge');
  }

  private async addItemToMonitoring(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    if (msbItem.type === MultisidebarItemTypes.WatchLists) {
      return monitoringModule.addWatchListToMonitoring(msbItem.model.item);
    } else {
      return monitoringModule.addCardToMonitoring(msbItem.model.item);
    }
  }

  private async removeItemFromMonitoring(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    const id = msbItem.model.item.id;
    if (msbItem.type === MultisidebarItemTypes.WatchLists) {
      return monitoringModule.deleteByWatchLists([id]);
    } else {
      return monitoringModule.deleteByCards([id]);
    }
  }

  private async openExternalSearchDialog(payload: unknown) {
    const msbItem = await convertToMsbItem(payload as any);
    await dialogModule.createExternalSearchDialog(msbItem.model.item);
  }
}

export const actionHandler = new ActionHandler();
