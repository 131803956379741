
import { kebabCase, snakeCase } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NThemeImage from '@/uikit/image/NThemeImage.vue';

@Options({
  name: 'EntityBlank',
  components: { NButton, NThemeImage },
  emits: ['create']
})
export default class EntityBlank extends Vue {
  @Prop({ type: String, required: true })
  readonly pagePrefix!: string;

  @Prop({ type: String, required: true })
  readonly pageType!: string;

  @Prop({ type: Boolean, default: true })
  readonly hasCreate!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isEmpty!: boolean;

  @Prop({ type: String, default: '' })
  readonly createIcon!: string;

  get imageToken() {
    return this.isEmpty ? `--image-${this.pagePrefix}-${kebabCase(this.pageType)}-no-items` : `--image-${this.pagePrefix}-not-found-items`;
  }

  get message() {
    const message = this.isEmpty ? `${this.pagePrefix}.${snakeCase(this.pageType)}_no_items` : `${this.pagePrefix}.not_found_items`;
    return this.$t(message, 'f');
  }

  get createLabel() {
    return this.$t(`${this.pagePrefix}.create_${snakeCase(this.pageType)}`);
  }
}
