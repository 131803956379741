import { KycChartType, KycFilter } from '@/api/models/KycFilter';
import { KycTimeToken } from '@/store/kyc/types';
import { languageModule } from '@/store/languages';

export const ReturnIntervalValues: KycTimeToken[] = ['30s', '1m', '30m', '1h', '3h', '6h', '12h', '1d', '7d', '1M', '3M', '1y'];
export const PrecisionValues: KycTimeToken[] = ['1m', '1h', '1d', '1M', '1y'];
export const ReturnNumberValues = [1, 2, 3, 4, 5];

export const TimeTokenToI18n: Record<KycTimeToken, [string, number]> = {
  '30s': ['date.second', 30],
  '1m': ['date.minute', 1],
  '30m': ['date.minute', 30],
  '1h': ['date.hour', 1],
  '3h': ['date.hour', 3],
  '6h': ['date.hour', 6],
  '12h': ['date.hour', 12],
  '1d': ['date.day', 1],
  '7d': ['date.day', 7],
  '1M': ['date.month', 1],
  '3M': ['date.month', 3],
  '1y': ['date.year', 1]
};

export const KycChartTypes: Record<Capitalize<KycChartType>, KycChartType> = {
  Visitors: 'visitors',
  Gender: 'gender',
  Age: 'age',
  Cameras: 'cameras',
  Visits: 'visits'
};

export function getDateTimeItems(items: KycTimeToken[]) {
  return items.map((v) => {
    if (TimeTokenToI18n[v]) {
      const [message, count] = TimeTokenToI18n[v];
      const label = languageModule.getTranslatedMessage(message, { count });
      return { value: v, label };
    }
    throw Error(`Unknown "${v}" token! Check DateTokenToI18n map.`);
  });
}

export function getTimezoneString() {
  const tzo = -new Date().getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function padImpl(num: number) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
}

const EndOfDayHours = 23;
const EndOfDayMinutes = 59;
const EndOfDaySeconds = 59;
const EndOfDayMilliseconds = 0;
const FullDayInMilliseconds = 86399e3;
const TwoWeeksInMilliseconds = 12096e5;

export function setFilterTodayAndTwoWeeksAgoDates(filter: KycFilter) {
  const today = setDateEndOfDayTime(new Date());
  filter.created_date_lte = today.toISOString();
  today.setTime(today.getTime() - TwoWeeksInMilliseconds - FullDayInMilliseconds);
  filter.created_date_gte = today.toISOString();
}

export function setDateEndOfDayTime(date: Date) {
  return date.setHours(EndOfDayHours, EndOfDayMinutes, EndOfDaySeconds, EndOfDayMilliseconds), date;
}
