export default {
  body: 'Силуэты',
  car: 'Транспортные средства',
  eventId: 'ID события',
  face: 'Лица',
  no_faces_on_photo: 'На фото не найдено лиц',
  or: 'или',
  result: 'Результат',
  select_file: 'Выберите файл',
  source: 'Источник {index}',
  submit: 'Сравнить',
  compare_error: 'Невозможно сравнивать объекты разных типов',
  matched_score: 'Вероятность совпадения',
  first_source: 'Первый источник',
  second_source: 'Второй источник',
  upload: 'Загрузить файл',
  load: 'Загрузить',
  width: 'Ширина',
  height: 'Высота',
  quality: 'Качество',
  first_source_placeholder: 'Введите URL события для первого источника',
  second_source_placeholder: 'Введите URL события для второго источника',
  dnd_placeholder: 'Перетащите один или два файла для сравнения',
  detect_config_error: 'Добавьте в конфигурацию интерфейса блок `detect_config`, чтобы распознавать атрибуты объектов',
  select_files: 'выберите файлы',
};
