
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import { request } from '@/api/core/request';
import { SearchRequest } from '@/api/models/SearchRequest';
import NButton from '@/uikit/buttons/NButton.vue';
import NInput from '@/uikit/input/NInput.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCell } from '@/uikit/table/NTableCell.vue';
import FilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import CardSelect from '@/pages/external-search/requests/CardSelect.vue';
import enrichSearchRequests, { EnrichedSearchRequest } from '@/pages/external-search/requests/enrich-search-requests';
import ExternalSearchRequestForm from '@/pages/external-search/requests/ExternalSearchRequestForm.vue';
import UserSelect from '@/pages/external-search/requests/UserSelect.vue';
import { externalSearchRequestsTableSchema } from './external-search-requests-table-schema';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import notify from '@/uikit/notification/helpers/notification';

@Options({
  name: 'ExternalSearchRequests',
  components: { CardSelect, ExternalSearchRequestForm, FilterDateRange, InfiniteScroll, NButton, NInput, NModalWindow, NTable, UserSelect }
})
export default class ExternalSearchRequests extends Vue {
  tableSchema = externalSearchRequestsTableSchema;
  enrichedItems: EnrichedSearchRequest[] = [];
  currentEnrichedItem: EnrichedSearchRequest | null = null;

  readonly cardsHumansListViewModel = viewModelRepository.getCardsHumansListViewModel();

  get module() {
    return viewModelRepository.getPuppeteerSearchListViewModel();
  }

  @Watch('module.items')
  async moduleItemsWatcher(items: SearchRequest[]) {
    this.enrichedItems = await enrichSearchRequests(items, this.enrichedItems);

    const ids = this.enrichedItems.map((item: EnrichedSearchRequest) => item.card);
    this.cardsHumansListViewModel.filter.current.id_in = ids as number[];
    this.cardsHumansListViewModel.get();
  }

  @Watch('module.filter.current', { deep: true })
  async reloadList() {
    await this.module.get();
  }

  async mounted() {
    await this.reloadList();
    autoUpdateHelper.addListInstance(this.cardsHumansListViewModel);
  }

  @Watch('cardsHumansListViewModel.items', { deep: true })
  async cardModuleForEnrichWatcher(newItems: any, oldItems: any) {
    // update only if changed card property
    if (newItems === oldItems) {
      this.enrichedItems = await enrichSearchRequests(this.enrichedItems, []);
    }
  }

  async refreshResults(id: number) {
    await request({
      method: 'POST',
      path: `/puppeteer/search/${id}/update_search/`
    });
  }

  closeRequestForm() {
    this.currentEnrichedItem = null;
  }

  async applyRequestForm() {
    if (this.currentEnrichedItem) {
      try {
        await this.refreshResults(this.currentEnrichedItem.id);
        await this.reloadList();
        this.$emit('showResultsById', this.currentEnrichedItem.id);
      } catch (e) {
        console.warn(e);
      } finally {
        this.currentEnrichedItem = null;
      }
    }
  }

  handleCellClick(item: EnrichedSearchRequest, cell: ITableCell) {
    if (cell.name === 'card') return;
    this.$emit('showResultsById', item.id);
  }

  async handleAction({ item, action, src }: { item: EnrichedSearchRequest; action: string; src?: string }) {
    switch (action) {
      case 'refresh': {
        await this.refreshResults(item.id);
        notify({ content: this.$t('external_search.search_results_updated'), showClose: true });
        await this.reloadList();
        break;
      }
      case 'edit': {
        this.currentEnrichedItem = item;
        break;
      }
      case 'delete': {
        await this.module.delete(item.id);
        await this.reloadList();
        break;
      }
      case 'show-results': {
        this.$emit('showResultsById', item.id);
        break;
      }
      case 'fullframe':
        this.$emit('display-card-image', src);
        break;
    }
  }
}
