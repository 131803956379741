
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { BodyObjectUpdate, CarCard, CardsService, CarObjectUpdate, Case, FaceObjectUpdate, HumanCard } from '@/api';
import { dataServiceRepository } from '@/api/common';
import { CaseCluster } from '@/api/models/CaseCluster';
import { aclModule } from '@/store/acl';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import { ThumbnailAction } from '@/uikit/thumbnail';
import { EventDetails } from '@/uikit/thumbnail/helpers/enums';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import { ClusterOpenedItem, ClusterOpenedItems, ListItem } from '@/components/clusters/types';
import Acknowledge from '@/components/common/Acknowledge.vue';
import Confidence from '@/components/common/Confidence.vue';
import WatchListLabelsShort from '@/components/common/WatchListsGroup.vue';
import ParticipantRole from '@/components/participants/sidebar/ParticipantRole.vue';
import { dataModule } from '@/store/data';

@Options({
  name: 'CaseClusterItem',
  components: { Acknowledge, Confidence, NButton, NIcon, NThumbnail, ParticipantRole, WatchListLabelsShort }
})
export default class CaseClusterItem extends Vue {
  @Prop({ type: Object, required: true })
  item!: CaseCluster;

  @Prop({ type: Number })
  index?: number;

  @Prop({ type: Boolean, default: false })
  disabled?: boolean;

  @Prop({ type: String, default: 'faces' })
  objects!: string;

  @Prop({ type: String, default: 'clusters' })
  type!: string;

  @Prop({ type: String, default: 'short' })
  displayType!: string;

  @Prop({ type: Boolean, required: false, default: false })
  inSidebar!: boolean;

  @Prop({ type: Boolean, default: false })
  useDefaultActions!: boolean;

  @Prop({ type: Array, default: () => [] })
  actions!: ThumbnailAction[];

  @Prop({ type: String })
  defaultAction?: ItemsActionName;

  @Prop({ type: Boolean, default: false })
  clusterSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  cardSelected!: boolean;

  @Prop({ type: Boolean, default: false })
  showCard!: boolean;

  @Prop({ type: Boolean, default: false })
  showAcknowledge!: boolean;

  @Prop({ type: Boolean, default: false })
  showName!: boolean;

  @Prop({ type: Boolean, default: false })
  showRole!: boolean;

  @Prop({ type: Boolean, default: false })
  showCase!: boolean;

  @Prop({ type: Boolean, default: false })
  hideCaseDate!: boolean;

  @Prop({ type: Boolean, default: false })
  showEventsCount!: boolean;

  @Prop({ type: Boolean, default: false })
  showConfidence!: boolean;

  @Prop({ type: String, default: '' })
  openedItem!: ClusterOpenedItem;

  mouseOnThumbnail = false;
  card: HumanCard | CarCard | null = null;
  matched_object: FaceObjectUpdate | BodyObjectUpdate | CarObjectUpdate | null = null;

  @Prop({ type: Boolean, default: false })
  clusterOnly!: boolean;

  parentCase?: Case | null = null;

  async mounted() {
    await this.loadParentCase(this.item.case);
    if (this.item.matched_card) {
      this.card = await this.loadCard(this.item.matched_card.card);
    }
  }

  get canChange() {
    return aclModule.getAccess('ffsecurity.change_case') && this.parentCase?.status !== 'archived';
  }

  get EventDetails() {
    return EventDetails;
  }

  get clusterClasses() {
    return {
      'cluster-item_sidebar': this.clusterSelected,
      'cluster-item_selected': this.openedItem === ClusterOpenedItems.Cluster
    };
  }

  get cardName() {
    return this.card?.name ?? '';
  }

  get isParticipant() {
    return !!this.item.role;
  }

  actionHandler(v: string) {
    this.$emit('action', v);
  }

  async loadCard(id: number): Promise<HumanCard | CarCard | null> {
    let result = null;
    if (id) {
      const servicePromise = this.objects === 'cars' ? CardsService.cardsCarsRetrieve(id) : CardsService.cardsHumansRetrieve(id);
      result = await (servicePromise as Promise<HumanCard | CarCard>);
    }
    return result;
  }

  async loadParentCase(id: number): Promise<void> {
    this.parentCase = await dataModule.getCaseById(id);
  }

  @Watch('item.card')
  async changeCardHandler(v: number | null) {
    if (!v) {
      this.card = null;
    } else {
      this.card = await this.loadCard(v);
    }
  }
}
