export const TabItemsMap = {
  Info: 'info',
  DepricatedGeneral: 'general',

  /* cards, participants */
  FaceObjects: 'face_objects',
  FaceClusterEvents: 'face_cluster_events',
  BodyObjects: 'body_objects',
  BodyClusterEvents: 'body_cluster_events',
  CarObjects: 'car_objects',
  CarClusterEvents: 'car_cluster_events',

  MatchedCaseClusters: 'matched_case_clusters',
  RelatedCaseClusters: 'related_case_clusters',

  CaseHumanCards: 'case_human_cards',
  Connections: 'connections',

  /* cibr */
  ClusterEvents: 'cluster_events',

  /* vns */
  Locations: 'locations'
};
