import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "n-double-button__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "n-double-button__dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NButtonsDoubleGroup = _resolveComponent("NButtonsDoubleGroup")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_component_NButtonsDoubleGroup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_NButton, {
          type: _ctx.type,
          size: _ctx.size,
          class: "n-double-button",
          counter: _ctx.counter,
          disabled: _ctx.disableButton
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["type", "size", "counter", "disabled"]),
        _createVNode(_component_NButton, {
          type: _ctx.type,
          size: _ctx.size,
          class: "n-double-button n-double-button__select",
          icon: "chevron-down",
          onClick: _withModifiers(_ctx.triggerDropdown, ["stop"])
        }, null, 8, ["type", "size", "onClick"]),
        (_ctx.dropdownStatus)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "dropdown")
            ])), [
              [_directive_click_outside, _ctx.triggerDropdown]
            ])
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}