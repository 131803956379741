import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "camera-cluster-marker" }
const _hoisted_2 = {
  key: 0,
  class: "camera-cluster-marker__selected-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LIcon = _resolveComponent("LIcon")!
  const _component_LMarker = _resolveComponent("LMarker")!

  return (_openBlock(), _createBlock(_component_LMarker, {
    "lat-lng": _ctx.latLng,
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LIcon, {
        "icon-anchor": _ctx.center,
        "class-name": "camera-cluster-marker__wrapper"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.selected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(['camera-cluster-marker__label label-mono-s', { 'camera-cluster-marker__label_selected': _ctx.selected }])
            }, _toDisplayString(_ctx.label), 3)
          ])
        ]),
        _: 1
      }, 8, ["icon-anchor"])
    ]),
    _: 1
  }, 8, ["lat-lng", "onClick"]))
}