import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "filter-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterManager = _resolveComponent("FilterManager")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_FiltersSmall = _resolveComponent("FiltersSmall")!
  const _component_FiltersBig = _resolveComponent("FiltersBig")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FiltersSmall, {
      key: "smallFilters",
      modelValue: _ctx.filter.current,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.current) = $event)),
      "form-layout": _ctx.schemeSmall,
      "has-changes": _ctx.hasFilterChanges,
      "has-filter-manager": _ctx.hasFilterManager,
      "has-report": _ctx.hasReport,
      "supports-search": _ctx.supportSearch,
      enabledFields: _ctx.enabledFields,
      "data-qa": `${_ctx.filter.storageKey}.small-filters`,
      onReport: _ctx.showReportDialog,
      onOpenFilters: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFilterVisible = true)),
      onResetFilters: _ctx.resetFilters
    }, {
      pre: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content")
      ]),
      "name-contains": _withCtx(() => [
        _renderSlot(_ctx.$slots, "name-contains")
      ]),
      "filter-manager": _withCtx(() => [
        (_ctx.hasFilterManager)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.hasFilterManager)
                ? (_openBlock(), _createBlock(_component_FilterManager, {
                    key: 0,
                    source: _ctx.filter.storageKey,
                    filterString: _ctx.filter.currentCleanAsString,
                    onSelect: _ctx.selectFilter,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFilterVisible = true))
                  }, null, 8, ["source", "filterString", "onSelect"]))
                : _createCommentVNode("", true)
            ], 64))
          : (_ctx.schemeLarge)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 1,
                type: "secondary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFilterVisible = true)),
                "data-qa": `${_ctx.filter.storageKey}.small-filters.more-filters`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.more_filters', 'f')), 1)
                ]),
                _: 1
              }, 8, ["data-qa"]))
            : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "small")
      ]),
      _: 3
    }, 8, ["modelValue", "form-layout", "has-changes", "has-filter-manager", "has-report", "supports-search", "enabledFields", "data-qa", "onReport", "onResetFilters"]),
    (_ctx.isFilterVisible)
      ? (_openBlock(), _createBlock(_component_FiltersBig, {
          key: "bigFilters",
          modelValue: _ctx.filter.current,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.current) = $event)),
          "item-type": _ctx.filter.current.storageKey,
          "form-layout": _ctx.schemeLarge,
          "has-report": _ctx.hasReport,
          enabledFields: _ctx.enabledFields,
          "data-qa": `${_ctx.filter.storageKey}.big-filters`,
          onReport: _ctx.showReportDialog,
          "onUpdate:enabledFields": _cache[5] || (_cache[5] = (v) => _ctx.setEnabledFields(v)),
          onResetFilters: _ctx.resetFilters,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isFilterVisible = false))
        }, null, 8, ["modelValue", "item-type", "form-layout", "has-report", "enabledFields", "data-qa", "onReport", "onResetFilters"]))
      : _createCommentVNode("", true)
  ]))
}