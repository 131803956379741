
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButton from '../buttons/NButton.vue';
import NIcon from '../icons/NIcon.vue';
import NNotFoundImage from '../image/NNotFoundImage.vue';
import NLoadingDots from '../loading/NLoadingDots.vue';
import { EventTypes } from '../thumbnail/helpers/enums';
import { ThumbnailAction } from './types';

@Options({
  name: 'NThumbnail',
  components: { NButton, NIcon, NLoadingDots, NNotFoundImage }
})
export default class NThumbnail extends Vue {
  @Prop({ type: Boolean })
  readonly loading: boolean = false;

  @Prop({ type: String, required: false })
  readonly modelValue!: string | null;

  @Prop({ type: String, default: 'extra-large' })
  readonly size!: 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({ type: String, default: 'rounded' })
  readonly corners!: 'rounded' | 'circle';

  @Prop({ type: String, default: 'contain' })
  readonly fit!: 'cover' | 'contain';

  @Prop({ type: Array, default: [] })
  readonly actions!: ThumbnailAction[];

  @Prop({ type: String, default: 'click' })
  readonly defaultAction!: string;

  @Prop({ type: Boolean, default: false })
  readonly canClose!: boolean;

  @Prop({ type: String, required: false })
  readonly notFoundImageLabel?: string;

  loadingError = false;

  @Watch('modelValue')
  srcWatcher() {
    this.loadingError = false;
  }

  get computedNotFoundImageLabel() {
    return this.notFoundImageLabel ?? this.$t('errors.image.not_found');
  }

  get compDefaultAction(): string {
    return this.actions?.length === 1 ? this.actions[0].name : this.defaultAction;
  }

  get wrapClasses() {
    const result = [];
    if (this.size) result.push('n-thumbnail__' + this.size);
    if (this.corners) result.push('n-thumbnail__' + this.corners);
    if (this.actions.length === 1) result.push('n-thumbnail__only-one-action');
    return result;
  }

  get imageClasses() {
    const result = [];
    if (this.size) result.push('n-thumbnail__' + this.size);
    if (this.fit) result.push('n-thumbnail__image_' + this.fit);
    if (this.corners) result.push('n-thumbnail__' + this.corners);
    return result;
  }

  emitAction(actionName: string) {
    this.$emit('action', actionName);

    // TODO: это какая-то лишняя хрень
    if (actionName === EventTypes.Select) {
      this.$emit(EventTypes.Select);
    }
  }
}
