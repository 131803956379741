
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { dataModule } from '@/store/data';
import NText from '@/uikit/text/NText.vue';

@Options({
  name: 'TableItemCameras',
  components: { NText }
})
export default class TableItemCameras extends Vue {
  @Prop({ type: Array, required: true })
  readonly modelValue!: number[];

  get content() {
    if (Array.isArray(this.modelValue)) {
      return this.modelValue
        .map((id) => {
          const item = dataModule.camerasModule.items.find((v) => v.id === id);
          return item?.name || '';
        })
        .filter((v) => !!v)
        .join(', ');
    }
    return '-';
  }
}
