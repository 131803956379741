
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { NewItemIdStart } from '@/store/multisidebar';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import ButtonModelCopy from '@/components/common/ButtonModelCopy.vue';
import { RouterModule } from '@/store/router';
import { dataModule } from '@/store/data';

@Options({
  name: 'ItemCommonFields',
  components: { ButtonModelCopy, NDateTimeLabel }
})
export default class ItemCommonFields extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Record<any, any>;

  get isNew() {
    return this.item.id <= NewItemIdStart;
  }

  get dataModule() {
    return dataModule;
  }

  navigateToUser(id: number) {
    RouterModule.navigateToUser(id);
  }
}
