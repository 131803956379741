import { reactive, unref, watch } from 'vue';
import {
  MultisidebarCommonItem,
  MultisidebarGroup,
  MultisidebarItemConfig,
  MultisidebarItemType,
  MultisidebarItemTypes,
  MultisidebarSharedState
} from './types';
import router from '@/router';
import { getDelay } from '@/definitions/common/base';
import { differenceOf } from '@/definitions/common/utils';
import { BodyObjectRequest, CarObjectRequest, FaceObjectRequest, ObjectsService } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { getDetectionEventTypeByMsbType, getObjectTypeByMsbType } from '@/store/application/data.assets';
import { PageType } from '@/store/application/page.definitions';
import { ObjectType } from '@/components/detection/types';
import { generateMultisidebarId, getMsbStateFromUrl, parseMultisidebarId, parseMultisidebarType } from './helpers';
import { MultisidebarGroupConfigs } from './types';
import { dialogModule } from '@/store/dialogs/dialogModule';

function createGroupByName(name: string): MultisidebarGroup {
  const groupConfig = MultisidebarGroupConfigs.find((v) => v.name === name);
  if (groupConfig) return Object.assign({ items: [], hasCurrent: false }, groupConfig);
  throw new Error('Can\'t get group config by name: ' + name);
}

export const NewItemIdStart = -1000;

class MultisidebarModule {
  public active = true;
  public contentOverlap = false;
  public items: MultisidebarCommonItem[] = [];
  public itemConfigs: MultisidebarItemConfig<any>[] = [];
  public itemConfigsMap: Record<string, MultisidebarItemConfig<any>> | null = null;
  public disabledActions: boolean = false;
  protected newItemId = NewItemIdStart;
  protected history: string[] = [];
  protected currentItemHistoryIndex: number | null = 0;
  protected sharedStateStore: Partial<Record<PageType, MultisidebarSharedState>> = {};

  public static create() {
    const instance = reactive(new this());
    instance.init();
    return instance;
  }

  public async init() {
    await this.initItemConfigs();
    await this.restoreState();
    this.startStateWatching();
    // this.startGlobalEventWatching();
  }

  protected async initItemConfigs() {
    const { MultisidebarItemConfigs, MultisidebarItemConfigsMap } = await import('./config');
    this.itemConfigs = MultisidebarItemConfigs;
    this.itemConfigsMap = MultisidebarItemConfigsMap;
  }

  protected startGlobalEventWatching() {
    // watch(() => globalEventModule.current, this.handleEvent.bind(this));
  }

  protected startStateWatching() {
    watch(() => this.currentId || this.itemIds, this.saveState.bind(this));
  }

  /*
  public handleEvent(event: GlobalEvent | null) {
    if (event?.name === GlobalEventName.Update && event?.payload?.id) {
      let complexId = generateMultisidebarId(event.type, event.payload.id);
      let item = this.items.find(({ id }) => id === complexId);
      item && Object.assign(item.model.item, event.payload);
    }
  }
*/
  public get currentId() {
    return this.currentItem?.id ?? '';
  }

  public get itemIds() {
    return this.items
      .map((v) => v.id)
      .filter((id) => {
        const numberId = Number(parseMultisidebarId(id).rawItem);
        return Number.isNaN(numberId) || numberId > NewItemIdStart;
      });
  }

  public get currentItem(): MultisidebarCommonItem | null {
    if (this.currentItemHistoryIndex !== null) {
      const id = this.history[this.currentItemHistoryIndex];
      return this.items.find((v) => v.id === id) || null;
    }
    return null;
  }

  public get currentItemItem() {
    return this.currentItem?.model.item;
  }

  public get currentSharedState() {
    if (this.currentItem) {
      const [type] = parseMultisidebarType(this.currentItem.type);
      if (!(type in this.sharedStateStore)) {
        this.sharedStateStore[type] = {};
      }
      return this.sharedStateStore[type];
    }
    return null;
  }

  public get currentItemConfig() {
    return this.currentItem ? this.getConfigByType(this.currentItem.type) : null;
  }

  public get groups() {
    const groups: Record<string, MultisidebarGroup> = {};
    this.items.forEach((item) => {
      const config = this.getConfigByType(item.type);
      if (!groups[config.groupName]) groups[config.groupName] = createGroupByName(config.groupName);
      groups[config.groupName].items.push({ item, config });
      if (item.id === this.currentItem?.id) groups[config.groupName].hasCurrent = true;
    });

    return Object.values(groups);
  }

  public get hasNext() {
    return this.currentItemHistoryIndex !== null && this.history.length > this.currentItemHistoryIndex + 1;
  }

  public get hasPrev() {
    return this.history.length > 1 && (this.currentItemHistoryIndex === null || this.currentItemHistoryIndex > 0);
  }

  public async restoreState() {
    const msbState = getMsbStateFromUrl(window.location.href);
    if (msbState.ids) {
      for (let id of msbState.ids) {
        let multisidebarPayload = parseMultisidebarId(id);
        await this.addItem(multisidebarPayload.type, multisidebarPayload.rawItem);
      }
    }

    if (msbState.currentId) {
      const item = this.items.find((v) => v.id === msbState.currentId);
      item && this.openCurrent(item);
    }
  }

  public saveState() {
    const stateToApply = { ids: this.itemIds, currentId: this.currentId };
    const currentState = getMsbStateFromUrl(window.location.href);
    const hasDiff = Object.keys(differenceOf(stateToApply, currentState)).length;
    if (hasDiff) {
      const query: any = Object.assign({}, unref(router.currentRoute).query, {
        msbState: JSON.stringify(stateToApply)
      });
      router.replace({ path: unref(router.currentRoute).path, query, force: true });
    }
  }

  public getItemsByType(type: string) {
    return this.items.filter((v) => v.type === type);
  }

  public getItem(type: string, rawId: number | string) {
    const id = generateMultisidebarId(type, rawId);
    return this.items.find((v) => v.id === id);
  }

  public async toggleItemSelect(type: MultisidebarItemType, rawItem: any) {
    const { aclModelName, aclPrefix, itemLoader } = this.getConfigByType(type);
    const item = await itemLoader(type, rawItem, { aclModelName, aclPrefix });
    this.findItemIndex(item) > -1 ? this.removeBarItem(item) : this.items.push(item);
  }

  public toggleCurrentBarItem(item: MultisidebarCommonItem) {
    this.currentItem?.id === item.id ? this.closeCurrent() : this.openCurrent(item);
  }

  public openCurrent(item: MultisidebarCommonItem) {
    this.currentItemHistoryIndex = this.history.push(item.id) - 1;
  }

  public closeCurrent() {
    this.currentItemHistoryIndex = null;
  }

  public removeBarItem(item: MultisidebarCommonItem) {
    const itemIndex = this.findItemIndex(item);
    if (itemIndex > -1) {
      if (this.currentItem?.id === item.id) this.currentItemHistoryIndex = null;
      if (this.currentItemHistoryIndex !== null && this.currentItemHistoryIndex > itemIndex) this.currentItemHistoryIndex--;
      this.items.splice(itemIndex, 1);
      this.history = this.history.filter((id) => id !== item.id);
    }
  }

  public async fakeDeleteItem(item: MultisidebarCommonItem, delayEvent = false) {
    try {
      const config = this.getConfigByType(item.type);
      const dataItem = item.model.item;
      this.removeBarItem(item);
    } catch (e) {
      console.warn(`[multisidebar]: can't delete item: ${item.id}. Error: ` + e);
    }
  }

  public async deleteItem(item: MultisidebarCommonItem, delayEvent = false) {
    try {
      const dataItem = item.model.item;
      const isSuccess = await item.model.delete(dataItem.id);
      if (isSuccess) {
        this.removeBarItem(item);
      }
    } catch (e) {
      console.warn(`[multisidebar]: can't delete item: ${item.id}. Error: ` + e);
    }
  }

  public removeItemsByGroupName(groupName: string) {
    const itemTypesToRemoveMap = Object.fromEntries(this.itemConfigs.filter((v) => v.groupName === groupName).map((v) => [v.type, true]));
    const itemsToDeleteMap = Object.fromEntries(this.items.filter((item) => itemTypesToRemoveMap[item.type]).map((v) => [v.id, true]));
    if (this.currentItem && itemTypesToRemoveMap[this.currentItem?.type]) this.currentItemHistoryIndex = null;
    this.items = this.items.filter((item) => !itemTypesToRemoveMap[item.type]);
    this.history = this.history.filter((id) => !itemsToDeleteMap[id]);
  }

  public async convertRawItemToMsbByType(type: MultisidebarItemType, rawItem: any) {
    const { aclModelName, aclPrefix, itemLoader } = this.getConfigByType(type);
    return itemLoader(type, rawItem, { aclModelName, aclPrefix, immediate: true });
  }

  public async addNewItemByType(type: MultisidebarItemType, itemProps?: any, additionalData?: any) {
    const { aclModelName, aclPrefix, itemLoader } = this.getConfigByType(type);
    const item = await itemLoader(type, --this.newItemId, { aclModelName, aclPrefix });
    Object.assign(item.model.item, itemProps);
    item.model.additionalData = additionalData;
    this.items.push(item);
    this.currentItemHistoryIndex = this.history.push(item.id) - 1;
    return item;
  }

  public async addItemAndOpen(type: MultisidebarItemType, rawItem: any) {
    let item = await this.addItem(type, rawItem);
    this.openCurrent(item);
  }

  public async addItemsAndOpen(type: MultisidebarItemType, rawItems: any) {
    await this.addItems(type, rawItems);
    await this.addItemAndOpen(type, rawItems[0]);
  }

  public async addItems(type: MultisidebarItemType, rawItems: any) {
    for (let rawItem of rawItems) {
      await this.addItem(type, rawItem);
    }
  }

  public async addItem(type: MultisidebarItemType, rawItem: any) {
    const { aclModelName, aclPrefix, itemLoader } = this.getConfigByType(type);
    const item = await itemLoader(type, rawItem, { aclModelName, aclPrefix });
    this.addBarItem(item);
    autoUpdateHelper.addItemInstance(item.model);
    return item;
  }

  public addBarItem(item: MultisidebarCommonItem) {
    if (!this.items.find((v) => v.id == item.id)) {
      this.items.push(item);
    }
  }

  public async save(item: MultisidebarCommonItem) {
    try {
      const config = this.getConfigByType(item.type);
      await item.model.save();
      const dataItem = item.model.item;
      const newItemId = generateMultisidebarId(item.type, dataItem.id);
      const prevItemId = item.id;
      const itemWithPrevId = this.items.find((v) => v.id === prevItemId);
      if (item.id !== newItemId) {
        this.history = this.history.map((v) => (v === prevItemId ? newItemId : v));
        itemWithPrevId && (itemWithPrevId.id = newItemId);
        item.id = newItemId;
        await this.saveAdditionalData(item);
      }
      return item;
    } catch (e) {
      console.warn(`[multisidebar]: can't save item: ${item.id}. Error: ` + e);
    }
  }

  public async saveAdditionalData(item: MultisidebarCommonItem) {
    const from: MultisidebarCommonItem = item.model.additionalData?.from;
    const isCard = [MultisidebarItemTypes.CardsCars, MultisidebarItemTypes.CardsHumans].includes(item.type as any);
    const isFromEvent = true;
    const detectionType = getDetectionEventTypeByMsbType(from?.type);

    if (from && isCard && isFromEvent) {
      const objectType = getObjectTypeByMsbType(from?.type);
      const request = {
        create_from: `${detectionType}:${from.model.item.id}`,
        card: item.model.item.id as number
      };
      if (objectType) await this.addObjectToCard(request, objectType);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest, objectType: string) {
    switch (objectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  public next() {
    this.hasNext && this.currentItemHistoryIndex!++;
  }

  public prev() {
    this.hasPrev && this.currentItemHistoryIndex!--;
  }

  protected findItemIndex(item: MultisidebarCommonItem) {
    return this.items.findIndex((v) => v.id === item.id);
  }

  public getItemByMsbId(id: string): MultisidebarCommonItem | undefined {
    return this.items.find((v) => v.id === id);
  }

  protected getConfigByType(type: string) {
    if (!this.itemConfigsMap?.[type]) throw new Error('Cant get item config for type: ' + type);
    return this.itemConfigsMap?.[type];
  }

  resetAndSave() {
    this.items = [];
    this.history = [];
    this.saveState();
  }
}

export const multisidebarModule = MultisidebarModule.create();
