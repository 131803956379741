
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CaseStatusEnum } from '@/api';
import { viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { aclModule } from '@/store/acl';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { applicationModule } from '@/store/application';
import { EmptyCasePageState, PagePaths, PageState } from '@/store/application/page.definitions';
import { pageModule } from '@/store/application/page.module';
import { NButton } from '@/uikit';
import NIcon from '@/uikit/icons/NIcon.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { loadAndSyncPermissions, PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';
import { wrapCaseSave } from '@/pages/cases/case-item-loader';
import CasePhotos from '@/pages/cases/photos/CasePhotos.vue';
import CaseVideos from '@/pages/cases/videos/CaseVideos.vue';
import DataSourcesVideosTable from '@/pages/data-sources/DataSourceVideosTable.vue';
import CaseClusterPage from './case-clusters/CaseClusterPage.vue';
import CaseForm from './common/CaseForm.vue';

export const TabNames = {
  Info: 'information',
  Sources: 'videos',
  Photos: 'photos',
  Clusters: 'clusters',
  Participants: 'participants'
};

@Options({
  name: 'EditCasePage',
  components: {
    CaseClusterPage,
    CaseForm,
    CasePhotos,
    CaseVideos,
    DataSourcesVideosTable,
    NButton,
    NIcon,
    NTabs
  },
  emits: ['create', 'close']
})
export default class EditCasePage extends Vue {
  @Prop({ required: false, type: String })
  readonly tab?: string;

  @Prop({ required: false, type: String, default: 'cases.edit-page'})
  readonly dataQa?: string;

  get compId() {
    return this.state?.id || Number(this.$route.query?.id) || this.compItem?.id;
  }

  get compItem() {
    return this.module.item!;
  }

  get isArchived() {
    return this.compItem.status !== CaseStatusEnum.Open;
  }

  get isError() {
    return !!this.compItem.error;
  }

  get debug(): boolean {
    return applicationModule.settings.debug;
  }

  get isNew() {
    return this.compId < 1;
  }

  get TabNames() {
    return TabNames;
  }

  get videoAcl(): ModelAclResult {
    return aclModule.getModelAclByName('videoarchive');
  }

  get maxAcl(): ModelAclResult {
    return aclModule.getModelAclByName('case');
  }

  get currentItemAclEdit(): boolean {
    return this.isNew ? this.maxAcl.add : this.currentItemAcl.update;
  }

  get currentItemAcl(): ItemAclResult {
    const result = aclModule.getCaseItemAclByUserGroupMap((this.compItem.permissions as any) || {}, this.maxAcl);
    return result;
  }

  get relatedCaseVideosAcl(): ModelAclResult {
    return { ...this.currentItemAcl, add: this.currentItemAcl.update };
  }

  get relatedCaseParticipantsAcl(): ModelAclResult {
    return aclModule.mergeAcl(this.maxAcl, { ...this.currentItemAcl, add: this.currentItemAcl.update });
  }

  tabCounter(v: string) {
    switch (v) {
      case 'videos':
        return this.compItem['videos_count'].toString();
      case 'participants':
        return this.compItem['participants_count'].toString();
      case 'photos':
        return this.compItem['photos_count'].toString();
      case 'clusters':
        return this.compItem['clusters_count'].toString();
    }
  }

  get tabItems() {
    const filterTabByName = (v: string) => {
      if (v === TabNames.Sources) return this.videoAcl.view;
      return true;
    };

    let items = Object.values(TabNames)
      .filter(filterTabByName)
      .map((v) => {
        return {
          label: this.$t(`common.${v}`, 'f'),
          name: v,
          counter: this.tabCounter(v)
        };
      });

    if (this.isNew) items = [items[0]];
    return items;
  }

  get module() {
    const result = viewModelRepository.getCasesItemViewModel();
    wrapCaseSave(result);
    autoUpdateHelper.addItemInstance(result);
    return result;
  }

  get state(): PageState {
    return this.tab ? pageModule.getPageStateByTab(PagePaths.Case, this.tab) : { ...EmptyCasePageState };
  }

  async created() {
    if (this.compId) {
      await this.module.get(this.compId);
      const { pageType } = JSON.parse(this.$route.query?.pageState as string);
      if (pageType) {
        this.state.pageType = pageType;
      }
    }
  }

  @Watch('module.item.id')
  async loadPermissionsOnPermissionTab() {
    await loadAndSyncPermissions(this.module, PermissionsModelNamesMap.Cases);
  }

  async saveHandler() {
    // Reflect.deleteProperty(this.compItem, 'created_by');
    await this.module.save();
    this.$emit('save', this.module.item);
  }

  updateSubPage(subPage: any) {
    this.state.pageType = subPage.tabName;
    this.state.caseClusterFilters = subPage.caseClusterFilters;
  }
}
