import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "vp-wrapper" }
const _hoisted_2 = { class: "render-wrapper" }
const _hoisted_3 = {
  key: 2,
  class: "vp-fallback"
}
const _hoisted_4 = {
  key: 3,
  class: "vp-fallback"
}
const _hoisted_5 = {
  key: 4,
  class: "vp-fallback"
}
const _hoisted_6 = { class: "controls-wrapper" }
const _hoisted_7 = { class: "thumb-wrapper" }
const _hoisted_8 = {
  key: 3,
  class: "close-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayerRender = _resolveComponent("VideoPlayerRender")!
  const _component_VideoPlayerPtzPanel = _resolveComponent("VideoPlayerPtzPanel")!
  const _component_VideoPlayerInfoPanel = _resolveComponent("VideoPlayerInfoPanel")!
  const _component_VideoPlayerSettings = _resolveComponent("VideoPlayerSettings")!
  const _component_VideoPlayerOverlay = _resolveComponent("VideoPlayerOverlay")!
  const _component_VideoPlayerLineLayer = _resolveComponent("VideoPlayerLineLayer")!
  const _component_NLoadingCircle = _resolveComponent("NLoadingCircle")!
  const _component_VideoPlayerControls = _resolveComponent("VideoPlayerControls")!
  const _component_VideoPlayerTimeline = _resolveComponent("VideoPlayerTimeline")!
  const _component_VideoPlayerExport = _resolveComponent("VideoPlayerExport")!
  const _component_VideoPlayerThumbnail = _resolveComponent("VideoPlayerThumbnail")!
  const _component_VideoPlayerBottomBar = _resolveComponent("VideoPlayerBottomBar")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: _ctx.playerContentRef,
      class: _normalizeClass(["vp-content", { hoverFlag: _ctx.hoverFlag }]),
      onMousemove: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.mousemove && _ctx.mousemove(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.wsUrl)
          ? (_openBlock(), _createBlock(_component_VideoPlayerRender, {
              key: 0,
              positionStartTime: _ctx.positionStartTime,
              "reconnect-on-close": _ctx.reconnectOnClose,
              style: _normalizeStyle(_ctx.cssFilters),
              "ws-url": _ctx.wsUrl,
              "restart-interval-sec": _ctx.restartIntervalSec,
              "sync-live-interval-sec": _ctx.syncLiveIntervalSec,
              onFrameObjectsChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.frameObjects = $event)),
              onPositionChange: _ctx.videoPositionChange,
              onSeeked: _ctx.videoSeeked,
              onStateChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.playerState = $event)),
              onStreamPropsChange: _ctx.streamPropsChange,
              class: "vp-render",
              ref: _ctx.setPlayerRenderRef,
              state: _ctx.settings
            }, null, 8, ["positionStartTime", "reconnect-on-close", "style", "ws-url", "restart-interval-sec", "sync-live-interval-sec", "onPositionChange", "onSeeked", "onStreamPropsChange", "state"]))
          : _createCommentVNode("", true),
        (_ctx.hasPtzPanel)
          ? (_openBlock(), _createBlock(_component_VideoPlayerPtzPanel, {
              key: 1,
              cameraId: _ctx.cameraId,
              onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hasPtzPanel = false))
            }, null, 8, ["cameraId"]))
          : _createCommentVNode("", true),
        (_ctx.hasInfoPanel)
          ? (_openBlock(), _createBlock(_component_VideoPlayerInfoPanel, {
              key: 2,
              cameraId: _ctx.cameraId,
              onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hasInfoPanel = false))
            }, null, 8, ["cameraId"]))
          : _createCommentVNode("", true),
        (_ctx.hasSettingsPanel)
          ? (_openBlock(), _createBlock(_component_VideoPlayerSettings, {
              key: 3,
              settings: _ctx.settings,
              onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hasSettingsPanel = false))
            }, null, 8, ["settings"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showOverlay)
        ? (_openBlock(), _createBlock(_component_VideoPlayerOverlay, {
            key: 0,
            frameObjects: _ctx.frameObjectsEx,
            scaleFactor: _ctx.container.scale,
            settings: _ctx.settings,
            style: _normalizeStyle(_ctx.containerStyles)
          }, null, 8, ["frameObjects", "scaleFactor", "settings", "style"]))
        : _createCommentVNode("", true),
      (_ctx.settings.camera && !_ctx.settings.isCameraRemoved && !(_ctx.playerState === 'closed' || _ctx.playerState === 'error'))
        ? (_openBlock(), _createBlock(_component_VideoPlayerLineLayer, {
            key: 1,
            containerWidth: _ctx.container.width,
            style: _normalizeStyle(_ctx.containerStyles),
            state: _ctx.settings
          }, null, 8, ["containerWidth", "style", "state"]))
        : _createCommentVNode("", true),
      (_ctx.playerState === 'seeking')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NLoadingCircle)
          ]))
        : (_ctx.settings.isCameraRemoved)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('video_player.no_video_camera_removed')), 1))
          : (_ctx.playerState === 'closed' || _ctx.playerState === 'error')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('video_player.video_not_available')), 1))
            : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.showControls)
          ? (_openBlock(), _createBlock(_component_VideoPlayerControls, {
              key: 0,
              class: "vp-controls",
              isLive: _ctx.isLive,
              isPause: _ctx.isPause,
              ptzButton: _ctx.settings.isOnvifCamera,
              showRewinds: _ctx.showRewinds,
              currentTime: _ctx.currentTime,
              fullscreenElement: _ctx.playerContent,
              onPlay: _ctx.play,
              onPause: _ctx.pause,
              onTimeChange: _ctx.timelinePositionChangeForce,
              onToggleExportMode: _ctx.toggleExportMode,
              onTogglePtzPanel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hasPtzPanel = !_ctx.hasPtzPanel)),
              onToggleInfoPanel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hasInfoPanel = !_ctx.hasInfoPanel)),
              onToggleSettingsPanel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hasSettingsPanel = !_ctx.hasSettingsPanel))
            }, null, 8, ["isLive", "isPause", "ptzButton", "showRewinds", "currentTime", "fullscreenElement", "onPlay", "onPause", "onTimeChange", "onToggleExportMode"]))
          : _createCommentVNode("", true)
      ])
    ], 34),
    (_ctx.showTimeline)
      ? (_openBlock(), _createBlock(_component_VideoPlayerTimeline, {
          key: 0,
          ref: "playerTimeline",
          cameraId: _ctx.cameraId,
          eventType: _ctx.eventType,
          exportMode: _ctx.exportMode,
          state: _ctx.settings,
          onPositionChange: _ctx.timelinePositionChange,
          onThumbChanged: _ctx.thumbChanged,
          onExportTimeChange: _ctx.exportTimeChange
        }, null, 8, ["cameraId", "eventType", "exportMode", "state", "onPositionChange", "onThumbChanged", "onExportTimeChange"]))
      : _createCommentVNode("", true),
    (_ctx.exportMode)
      ? (_openBlock(), _createBlock(_component_VideoPlayerExport, {
          key: 1,
          cameraId: _ctx.cameraId,
          timeFrom: _ctx.exportTimeFrom,
          timeTo: _ctx.exportTimeTo,
          onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.exportMode = false))
        }, null, 8, ["cameraId", "timeFrom", "timeTo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.thumbSrc)
        ? (_openBlock(), _createBlock(_component_VideoPlayerThumbnail, {
            key: _ctx.thumbSrc,
            src: _ctx.thumbSrc,
            style: _normalizeStyle(_ctx.thumbCss)
          }, null, 8, ["src", "style"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showBottomBar)
      ? (_openBlock(), _createBlock(_component_VideoPlayerBottomBar, {
          key: 2,
          eventType: _ctx.eventType,
          state: _ctx.settings,
          currentTime: _ctx.currentTime,
          onEventTypeChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.eventType = $event)),
          onPositionChange: _ctx.timelinePositionChangeForce,
          showZoomButtons: _ctx.showTimeline,
          onZoom: _ctx.zoomHandler
        }, null, 8, ["eventType", "state", "currentTime", "onPositionChange", "showZoomButtons", "onZoom"]))
      : _createCommentVNode("", true),
    (_ctx.closeable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_NIcon, {
            onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('close'))),
            name: "vp-dialog-close",
            width: "30",
            height: "30"
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}