import { dataAssetsModule } from '@/store/application/data.assets.module';
import { cardAssetsModule } from '@/store/cards/CardAssetsModule';
import { IFormLayoutItem } from '@/uikit/forms/NForm.vue';
import { commonFilterSchemaModule } from '@/components/common/filter/filters/CommonFilterSchemaModule';
import { SimpleFilterOptions } from '@/components/common/filter/filters/types';
import { configModule } from '@/store/config';

export function getCardFiltersBuilder(options: SimpleFilterOptions) {
  const availableObjectsMap = dataAssetsModule.availableObjectsMap;

  const commonHeadFilters = [commonFilterSchemaModule.getWatchLists(options)];

  const humanCardFilters = [
    availableObjectsMap.face ? commonFilterSchemaModule.getHasFaceObjects({ ...options }) : undefined,
    availableObjectsMap.body ? commonFilterSchemaModule.getHasBodyObjects({ ...options }) : undefined
  ];
  const carCardFilters = [
    availableObjectsMap.car ? commonFilterSchemaModule.getHasCarObjects({ ...options }) : undefined,
    availableObjectsMap.car ? commonFilterSchemaModule.getCarLicensePlateNumber({ ...options }) : undefined
  ];

  const commonBottomFilters = [
    commonFilterSchemaModule.getIsFilled(options),
    commonFilterSchemaModule.getNameContains({ ...options }),
    commonFilterSchemaModule.getId(options),
    commonFilterSchemaModule.getActiveStatus(options)
  ];

  if (configModule.features.cases_enabled) {
    commonBottomFilters.push(commonFilterSchemaModule.getCaseMatchType(options));
  }

  function getFilterByType(cardType: string): IFormLayoutItem[] {
    function updateFilterClass(item: IFormLayoutItem): IFormLayoutItem {
      item.classes = 'heading-m n-form-item_fill n-form-label-horizontal-200 n-form-pad-10 n-form-label-text-align-right';
      return item;
    }

    const metaFormItems = cardAssetsModule.getMetaFilterItems(cardType, options.small).map(updateFilterClass);
    let filterSchema = [];
    if (cardType === 'humans') {
      filterSchema = [...commonHeadFilters, ...humanCardFilters, ...commonBottomFilters, ...metaFormItems];
    } else {
      filterSchema = [...commonHeadFilters, ...carCardFilters, ...commonBottomFilters, ...metaFormItems];
    }
    return filterSchema.filter((v) => !!v) as any;
  }

  return { getFilterByType };
}
