export default {
  fallback_title: 'Retrieve the case from the archive',
  fallback_description: 'If you want to work and view the clusters, please retrieve the case from the archive and come here again',
  clusters_in_current_case: 'Clusters in Current Case',
  clusters_in_other_cases: 'Clusters in Other Cases',
  participant_info: 'Participant Info',
  participant: 'Participant',
  enter_comment: 'Enter Comment',
  case_info: 'Case Info',
  case_name: 'Case Name',
  date_incident: 'Incident Date',
  case_date: 'Case Date',
  acknowledgment: 'Acknowledgment',
  create_first_case: 'New case file',
  create_first_video: 'Add video',
  create_first_photo: 'Add photo',
  case: 'case file',
  new_case: 'new case file',
  new_case_description: 'You are opening a new case file. Be sure to specify its name and number (ID), and the incident date',
  new_video: 'New video',
  new_video_description: 'Please upload your first video',
  new_photo: 'New photo',
  new_photo_description: 'Please upload your first photo. Supported formats are JPG, PNG, WEBP, HEIC',
  incident_date: 'incident date',
  record_id: 'Case ID in registry',
  record_id_short: 'Registry',
  record_date: 'Case date',
  set_role_permissions: 'Set access permissions',
  filter_all: 'all',
  filter_open: 'open',
  filter_archived: 'archived',
  status_open: 'open',
  status_archived: 'archived',
  status_archiving: 'archiving',
  status_dearchiving: 'dearchiving',
  status_deleting: 'deleting',
  status_error: 'error',
  name: 'name',
  type: 'type',
  connections: 'connections',
  information: 'information',
  cluster: 'cluster',
  witness: 'Witness',
  victim: 'Victim',
  suspect: 'Suspect',
  irrelevant: 'Not relevant',
  comment: 'comment',
  related_cards: 'Related individuals records',
  related_participants: 'Related participants',
  clusters: 'Clusters',
  participants: 'Participants',
  participants_and_clusters: 'Participants and clusters',
  participants_in_current_case: 'Participants of current case',
  participants_in_other_cases: 'Participants of other cases',
  card_index: 'Record index',
  wizard_help_text:
    'This is the individual who was automatically recognized while processing case file footage. If they are relevant to the case, mark them as a participant. If you are sure that there is a match with the other recognized individuals, or with the index records, or with the participants of other cases, establish a link by clicking the Add button',
  create_new_participant: 'Mark as participant',
  add: 'Add',
  save_and_done: 'Save and close',
  close: 'Close',
  save_participant: 'Save',
  save_as_participant: 'Save participant',
  case_id: 'Case ID',
  enter_case_id: 'Enter case ID',
  participant_name: 'Participant name',
  enter_participant_name: 'Enter participant name',
  role: 'Role',
  date_time_participant: 'Date and time of participant creation',
  upload: 'Upload',
  add_and_process: 'Add and process',
  photo_status__downloaded: 'Ready',
  photo_status__completed: 'Completed',
  photo_status__failed: 'Failed',
  select_photos: 'Select photos',
  reset_participant_confirm: 'Are you sure you want to reset the participant?'
};
