import { PageNames, SearchFrom } from '@/store/application/page.definitions';
import { MultisidebarItemType, MultisidebarItemTypes } from '@/store/multisidebar/types';

export type SortOption = boolean | { desc?: boolean; asc?: boolean };
export type SortOptions = {
  created_date?: SortOption;
  modified_date?: SortOption;
  name?: SortOption;
  id?: SortOption;
  idAsCreatedDate?: SortOption;
  confidence?: SortOption;
  external_timestamp?: SortOption;
  looks_like_confidence?: SortOption;
};
export type ObjectType = 'face' | 'body' | 'car';
export type ObjectsType = typeof ObjectsTypesMap[keyof typeof ObjectsTypesMap];

export type EventType = typeof EventTypesMap[keyof typeof EventTypesMap];
export const EventTypesMap = {
  Episodes: 'episodes',
  Events: 'events'
} as const;

export const EventTypes = Object.values(EventTypesMap);
export const ObjectTypes = ['faces', 'bodies', 'cars'];
export const EpisodeTypes = ['humans', 'cars'];
export const CardTypes = ['humans', 'cars'];
export const DisplayTypeItems = [
  { value: 'short', icon: 'card-view' },
  { value: 'full', icon: 'row-view' },
  { value: 'map', icon: 'map-view' }
];
export const SearchPageTypes = ['cards', 'events', 'clusters', 'case_clusters'];

export const SearchFromMap: Record<string, SearchFrom> = {
  Events: PageNames.Events,
  Cards: PageNames.Cards,
  Clusters: PageNames.Clusters,
  CaseClusters: PageNames.CaseClusters,
  File: 'file'
};

export const ObjectsTypesMap = {
  Faces: 'faces',
  Bodies: 'bodies',
  Cars: 'cars'
} as const;

export type EpisodeType = typeof EpisodeTypesMap[keyof typeof EpisodeTypesMap];
export const EpisodeTypesMap = {
  Humans: 'humans',
  Cars: 'cars'
} as const;

export type CardType = typeof CardTypesMap[keyof typeof CardTypesMap];
export const CardTypesMap = {
  Humans: 'humans',
  Cars: 'cars'
} as const;


export type CardSingleType = typeof CardSingleTypeMap[keyof typeof CardSingleTypeMap];
export const CardSingleTypeMap = {
  Human: 'human',
  Car: 'car'
} as const;

export const ObjectsSingleToMulti: Record<string, string> = {
  face: 'faces',
  body: 'bodies',
  car: 'cars'
} as const;

export const ObjectsMultiToSingle: Record<string, string> = {
  faces: 'face',
  bodies: 'body',
  cars: 'car'
} as const;

export const DetectionEventTypes: Record<string, string> = {
  Face: 'faceevent',
  Body: 'bodyevent',
  Car: 'carevent'
} as const;

export type DetectionEventType = typeof DetectionEventTypes[keyof typeof DetectionEventTypes];

export const ObjectsSingleToEventType: Record<string, string> = {
  face: 'events_faces',
  body: 'events_bodies',
  car: 'events_cars'
} as const;

export const EpisodesMultiToSingle: Record<string, string> = {
  humans: 'human',
  cars: 'car'
} as const;

export const EpisodesSingleToMulti: Record<string, string> = {
  human: 'humans',
  car: 'cars'
} as const;

export const CardsMultiToSingle: Record<CardType, CardSingleType> = {
  humans: 'human',
  cars: 'car'
} as const;

export const CardsSingleToMulti: Record<CardSingleType, CardType> = {
  human: 'humans',
  car: 'cars'
} as const;

export function getDetectionEventTypeByMsbType(msbType: MultisidebarItemType): DetectionEventType | undefined {
  switch (msbType) {
    case MultisidebarItemTypes.EventsFaces:
      return DetectionEventTypes.Face;
    case MultisidebarItemTypes.EventsBodies:
      return DetectionEventTypes.Body;
    case MultisidebarItemTypes.EventsCars:
      return DetectionEventTypes.Car;
  }
}

export function getObjectTypeByMsbType(msbType: MultisidebarItemType): string | undefined {
  switch (msbType) {
    case MultisidebarItemTypes.EventsFaces:
      return 'face';
    case MultisidebarItemTypes.EventsBodies:
      return 'body';
    case MultisidebarItemTypes.EventsCars:
      return 'car';
  }
}
