
import { cloneDeep } from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { ItemAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { ImageViewerActions } from '@/uikit/image-viewer/types';
import { ThumbnailPositions } from '@/uikit/thumbnail';
import ClusterItem from '@/components/clusters/ClusterItem.vue';
import FilterSection from '@/components/common/filter/FilterSection.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import Statistics from '@/components/common/Statistics.vue';
import ClusterWizard from '@/pages/cases/case-cluster-wizard/ClusterWizard.vue';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import { getCaseClusterFiltersBuilder, getCaseParticipantsFilters, getClusterFiltersBuilder } from '@/pages/clusters/forms/ClusterFiltersBuilder';
import PageContentLayout from '@/pages/PageContentLayout.vue';
import { TabNames } from '@/pages/cases/EditCasePage.vue';

@Options({
  name: 'CaseClusterPage',
  components: {
    CaseClusterItem,
    ClusterItem,
    ClusterWizard,
    FilterSection,
    InfiniteScroll,
    NButtonGroup,
    PageContentLayout,
    Statistics
  }
})
export default class CaseClusterPage extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: any;

  @Prop({ type: String, required: true })
  readonly type!: string;

  @Prop({ type: Boolean, default: false })
  readonly isCaseArchived!: boolean;

  @Prop({ type: Object, default: false })
  readonly presetFilters!: any;

  readonly module = viewModelRepository.getCaseClustersListViewModel();

  wizardClusterEnabled = false;
  currentCaseCluster = {};

  get isParticipantsPage() {
    return this.type === 'participants';
  }

  get clusters() {
    return this.module.items;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get currentSidebarItemId() {
    return Number(this.sidebarModule.currentId.split(':')[1]);
  }

  async showCaseClusterEvents(id: any) {
    const caseEventsModule = viewModelRepository.getCaseEventsListViewModel();
    caseEventsModule.filter.current.case_cluster_in = [id];
    await caseEventsModule.get();
    const viewerItems = caseEventsModule.items.map((v) => ({
      ...v,
      actions: [ImageViewerActions.Exclude],
      handlers: { exclude: () => this.excludeClusterEvent(v.id) },
      i18n: { exclude: this.$t('clusters.exclude_from_cluster') }
    }));
    this.$photoViewer.show(viewerItems);
  }

  async excludeClusterEvent(id: number) {
    const result = await dataServiceRepository.CaseEventsService.createItemSomethingByAction(id, 'separate');
    await autoUpdateHelper.createHandler('/case-clusters/');
    return !!result;
  }

  async acknowledgeHandler(id: number): Promise<void> {
    await this.module.dataService.createItemSomethingByAction(id, 'acknowledge');
    this.module.items.map((v) => {
      if (v.id === id && v.matched_card?.acknowledged !== undefined) {
        v.matched_card.acknowledged = true;
      }
    });
  }

  async actionHandler(item: any, type: any) {
    if (type === 'click' && this.type === 'clusters') {
      this.currentCaseCluster = cloneDeep(item);
      this.wizardClusterEnabled = true;
    }

    if (type === 'click' && this.type === 'participants') {
      actionHandler.run(ItemsActionNames.ShowItem, {
        type: MultisidebarItemTypes.CaseClusters,
        rawItem: item
      });
    }

    if (type === 'acknowledge') {
      await this.acknowledgeHandler(item.id);
    }

    if (type === 'ShowCard') {
      actionHandler.run(ItemsActionNames.ShowItem, {
        type: MultisidebarItemTypes.CardsHumans,
        rawItem: item
      });
    }
    if (type === 'ShowEvents') {
      this.showCaseClusterEvents(item.id);
    }
  }

  get largeFilterLayout() {
    const options = { small: false };
    return this.isParticipantsPage ? getCaseParticipantsFilters(options) : getCaseClusterFiltersBuilder(options).getFilterByType('faces');
  }

  get smallFilterLayout() {
    const options = { small: true };
    return this.isParticipantsPage ? getCaseParticipantsFilters(options) : getCaseClusterFiltersBuilder(options).getFilterByType('faces');
  }

  async mounted() {
    if (this.presetFilters && Object.keys(this.presetFilters).length) {
      this.module.filter.current = { ...this.module.filter.current, ...this.presetFilters };
      this.$emit('updateSubPage', { tabName: TabNames.Clusters, caseClusterFilters: false });
    }

    autoUpdateHelper.addListInstance(this.module);
    await this.load();
  }

  beforeUnmount(): void {
    autoUpdateHelper.removeListInstance(this.module);
  }


  async load() {
    if (this.type === 'participants') {
      this.module.filter.force.is_case_participant = true;
      this.module.filter.storageKey = 'case-participants';
    }
    this.module.filter.force.case_in = [this.item.id];
    this.module.filter.force.ordering = '-is_case_participant,-id';
    this.module.filter.current.limit = '30';
    await this.module.get();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler() {
    this.module.debouncedGet();
  }
}
