import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "watch-list-table-item" }
const _hoisted_2 = {
  key: 0,
  class: "watch-list-table-item__monitoring label-s"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WatchListItem = _resolveComponent("WatchListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WatchListItem, {
      color: '#' + _ctx.item.color,
      name: _ctx.item.name,
      "text-type": "link",
      onClick: _ctx.handleClick
    }, null, 8, ["color", "name", "onClick"]),
    (_ctx.currentMonitoringRecord)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('remote_monitoring.monitoring_letter')), 1))
      : _createCommentVNode("", true)
  ]))
}