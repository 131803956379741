
import { defineComponent, PropType } from 'vue';
import { Report } from '@/api';
import { ReportsPageTableColumnLabel } from './common';

export default defineComponent({
  name: 'ReportsPageTableColumnId',
  components: { ReportsPageTableColumnLabel },
  props: {
    id: {
      required: true,
      type: Number as PropType<Report['id']>
    }
  }
});
