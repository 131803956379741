
import { computed, defineComponent, inject, nextTick, onBeforeUnmount, onMounted, PropType, reactive, toRef, toRefs, watch } from 'vue';
import NImageViewerLineLayer from '@/uikit/image-viewer/NImageViewerLineLayer.vue';
import { BboxConfig, BboxDistance, Point, Size } from '../bbox/types';
import NButton from '../buttons/NButton.vue';
import { copyTextToClipboard, fileDownload, getFileNameFromFileUrl } from '../helpers';
import NImageViewerBboxDistancesLayer from '../image-viewer/NImageViewerBboxDistancesLayer.vue';
import NThemeImage from '../image/NThemeImage.vue';
import NLoadingCircle from '../loading/NLoadingCircle.vue';
import { openFullscreen, closeFullscreen } from '../utils';
import { getScaleOptions, imageLoad } from './image-helpers';
import { Mover } from './Mover';
import NImageViewerBboxLayer from './NImageViewerBboxLayer.vue';
import NImageViewerZoom from './NImageViewerZoom.vue';
import { ImageViewerAction, ImageViewerActions, ImageViewerSrc, LineTrackProp, ScaleOptions } from './types';
import { Zoomer } from './Zoomer';

export function getDefaultActions(): ImageViewerAction[] {
  return [ImageViewerActions.Zoom, ImageViewerActions.Copy, ImageViewerActions.Download, ImageViewerActions.Fullscreen, ImageViewerActions.Close];
}

export default defineComponent({
  name: 'NImageViewer',
  components: { NButton, NImageViewerBboxDistancesLayer, NImageViewerBboxLayer, NImageViewerLineLayer, NImageViewerZoom, NLoadingCircle, NThemeImage },
  props: {
    src: { type: [String, Object] as PropType<ImageViewerSrc>, required: false },
    zoomable: { type: Boolean, default: true },
    draggable: { type: Boolean, default: true },
    bboxSelectable: { type: Boolean, default: false },
    bboxes: { type: Array as PropType<BboxConfig[]> },
    distances: { type: Array as PropType<BboxDistance[]> },
    actions: { type: Array as PropType<string[]>, default: getDefaultActions() },
    errorMessage: { type: String, default: 'The image may have been deleted' },
    track: { type: Array as PropType<LineTrackProp>, default: () => [] },
    line: { type: Array as PropType<LineTrackProp>, default: () => [] },
    toggleFullscreen: { type: Function },
    handlers: { type: Object },
    i18n: { type: Object, default: () => {} },
    imageCount: { type: Number },
    alwaysShowTrack: { type: Boolean }
  },
  emits: ['error', 'close', 'selectBbox', 'exclude'],
  setup(props, { emit }) {
    let showTrack = !!props.line;
    const showTrackString = window.sessionStorage.getItem('NImageViewer_showTrack');
    if (showTrackString !== null) {
      showTrack = props.alwaysShowTrack || showTrackString === '1';
    }
    const state = reactive({
      src: '',
      loading: true,
      loaded: false,
      viewer: null as Element | null,
      content: null as Element | null,
      imageSize: { width: 0, height: 0 } as Size,
      offset: { x: 0, y: 0 } as Point,
      scale: 1,
      fullscreenEnabled: false,
      scaleOptions: {} as ScaleOptions,
      hasError: false,
      showTrack
    });

    function toggleShowTrack() {
      state.showTrack = !state.showTrack;
      window.sessionStorage.setItem('NImageViewer_showTrack', state.showTrack ? '1' : '0');
    }

    const plugins = {
      zoomer: null as Zoomer | null,
      mover: null as Mover | null
    };

    let objectUrl: string;

    const injectedFullscreenComponent = inject('fullscreenComponent') as any;

    const style = computed(() => {
      return {
        transform: `translate3d(${state.offset.x}px, ${state.offset.y}px, 0) scale3d(${state.scale}, ${state.scale}, 1)`
      };
    });

    const hasArrayOrFunction = (v: Array<number> | any) => (v && Array.isArray(v) ? !!v.length : !!v);
    const hasTrackOrLine = computed(() => hasArrayOrFunction(props.line) || hasArrayOrFunction(props.track));

    watch(() => props.src, handleSourceChange, { immediate: true });
    async function handleSourceChange(src?: string | Blob) {
      state.hasError = false;
      state.loading = true;
      state.loaded = false;
      destructPlugins();

      if (typeof src === 'object' && src instanceof Blob) {
        objectUrl && URL.revokeObjectURL(objectUrl);
        src = URL.createObjectURL(src);
        objectUrl = src;
      }

      try {
        if (!src) {
          throw new Error('[ImageViewer] src is empty');
        }
        const image = await imageLoad(src);
        state.imageSize = { width: image.width, height: image.height };
        state.src = src;
        state.loaded = true;
      } catch (e) {
        emit('error');
        state.hasError = true;
      } finally {
        state.loading = false;
      }
    }

    watch(() => state.loaded, reInitOnLoad, { immediate: true });
    function reInitOnLoad(v: boolean) {
      v && nextTick(init);
    }

    onMounted(init);

    function init() {
      generateScaleOptions();
      initMover();
      initZoomer();
    }

    function generateScaleOptions() {
      if (state.viewer) {
        const viewerRect = state.viewer.getBoundingClientRect();
        const viewerSize = { width: viewerRect.width, height: viewerRect.height };
        Object.assign(state.scaleOptions, getScaleOptions(state.imageSize, viewerSize));
        state.scale = state.scaleOptions.fit;
      }
    }

    function initMover() {
      if (props.draggable && state.content && state.viewer) {
        plugins.mover = new Mover(state.content, state.viewer, state.offset);
      }
    }

    function initZoomer() {
      if (props.zoomable && state.viewer) {
        plugins.zoomer = new Zoomer(state.viewer, toRef(state, 'scale'), state.scaleOptions);
      }
    }

    async function exclude() {
      try {
        const result = await props.handlers?.exclude();
        if (result) emit('exclude');
      } catch (e) {
        console.warn('[exclude] error ', e);
      }
    }

    onBeforeUnmount(destructPlugins);
    function destructPlugins() {
      plugins.mover?.destruct();
      plugins.zoomer?.destruct();
      objectUrl && URL.revokeObjectURL(objectUrl);
    }

    function copy() {
      copyTextToClipboard(state.src);
    }

    function download() {
      fileDownload(state.src, getFileNameFromFileUrl(state.src));
    }

    onMounted(bindResizeHandler);
    function bindResizeHandler() {
      window.addEventListener('resize', generateScaleOptions);
    }

    onBeforeUnmount(unbindResizeHandler);
    function unbindResizeHandler() {
      window.removeEventListener('resize', generateScaleOptions);
    }

    onMounted(bindFullscreenHandler);
    function bindFullscreenHandler() {
      document.addEventListener('fullscreenchange', fullscreenChangeHandler);
    }

    onBeforeUnmount(unbindFullscreenHandler);
    function unbindFullscreenHandler() {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
    }

    function fullscreenChangeHandler() {
      state.fullscreenEnabled = Boolean(document.fullscreenEnabled && state.viewer === document.fullscreenElement);
      nextTick(generateScaleOptions);
    }

    function fullscreenToggle() {
      if (state.fullscreenEnabled) {
        closeFullscreen();
      } else {
        const el = injectedFullscreenComponent?.fullscreenTarget || state.viewer;
        openFullscreen(el);
      }
      state.fullscreenEnabled = !state.fullscreenEnabled;
      nextTick(generateScaleOptions);
    }

    return {
      ...toRefs(state),
      style,
      hasTrackOrLine,
      emit,
      download,
      copy,
      fullscreenToggle,
      toggleShowTrack,
      exclude
    };
  }
});
