
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { LIcon, LMarker } from '@vue-leaflet/vue-leaflet';
import { LatLngType } from './types';
import { PointAsArray } from '@/uikit/draw/types';
import { LeafletMouseEvent } from 'leaflet';

const Radius = 20;
const SizeOffset = 6;

@Options({
  name: 'CameraClusterMarker',
  components: {
    LMarker,
    LIcon
  },
  emits: ['select']
})
export default class CameraClusterMarker extends Vue {
  @Prop({ type: Object, required: true })
  readonly latLng!: LatLngType;

  @Prop({ type: [String, Number], default: '' })
  readonly label!: string | number;

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean;

  get fullRadius() {
    return Radius;
  }

  get center(): PointAsArray {
    return [this.size / 2, this.size / 2];
  }

  get size() {
    return (this.fullRadius + SizeOffset) * 2;
  }

  handleClick(event: LeafletMouseEvent) {
    event.originalEvent.stopPropagation();
    this.$emit('select', event);
  }
}
