import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "empty-page__content" }
const _hoisted_2 = { class: "empty-page__title heading-4xl" }
const _hoisted_3 = { class: "empty-page__description label-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["empty-page", [_ctx.imageType]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.i18n_title, 'f')), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.i18n_description)), 1),
      _createVNode(_component_NButton, {
        size: "big",
        disabled: _ctx.disabledAddButton,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create'))),
        icon: "add"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.i18n_buttonText, 'f')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ], 2))
}