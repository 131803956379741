
import { Remarkable } from 'remarkable';
import { Options, Vue } from 'vue-class-component';
import NButton from '@/uikit/buttons/NButton.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NModalWindow, { NModalWindowSize } from '@/uikit/modal-window/NModalWindow.vue';
const remarkable = new Remarkable();

@Options({
  name: 'LicenseAgreement',
  components: {
    NButton,
    NCheckbox,
    NModalWindow
  }
})
export default class LicenseAgreement extends Vue {
  private licenseAccept = false;

  get size() {
    return NModalWindowSize.Large;
  }

  toHtml(v: any) {
    return remarkable.render(v);
  }

  proceedHandler() {
    this.licenseAccept && this.$emit('proceed');
  }
}
