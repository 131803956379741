
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { CaseCluster } from '@/api';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';

@Options({
  name: 'CaseClusterItemRow',
  components: { NThumbnail }
})
export default class CaseClusterItemRow extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CaseCluster;

  @Prop({ type: Boolean, default: false })
  readonly canClose!: boolean;

  get isParticipant() {
    return !!this.item.role;
  }

  get thumbnail() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.item.best_event.thumbnail;
  }
}
