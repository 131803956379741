import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table-item-tags" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_NTag, {
        key: item,
        label: item
      }, null, 8, ["label"]))
    }), 128)),
    (!_ctx.items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "-"))
      : _createCommentVNode("", true)
  ]))
}