import { extractReportUrlByFormat } from '@/pages/reports/utils';
import { ReportArchiveFileName, ReportArchiver } from './archiver';
import { ReportDownloader, ReportDownloaderHandlers } from './downloader';
import { ReportSaver } from './saver';
import { ReportSource } from './source';
import { Report, ReportFormat } from './types';

export class ReportDownloaderImpl implements ReportDownloader {
  static create(archiver: ReportArchiver, saver: ReportSaver, source: ReportSource): ReportDownloaderImpl {
    return new this(archiver, saver, source);
  }

  private constructor(private readonly archiver: ReportArchiver, private readonly saver: ReportSaver, private readonly source: ReportSource) {}

  replaceFileName(file: File, name: string) {
    const blob = file.slice(0, file.size, 'image/png');
    return new File([blob], name, { type: file.type });
  }

  async download(report: Report, format: ReportFormat, handlers?: ReportDownloaderHandlers): Promise<void> {
    const extension = extractReportUrlByFormat(report, format).split('.').pop();
    this.saver.saveAs(this.replaceFileName(await this.source.fetch(report, format, handlers), `${report.name}.${extension}`));
  }

  async downloadAllInArchive(reports: Report[], name: ReportArchiveFileName, handlers?: ReportDownloaderHandlers): Promise<void> {
    const archive = this.archiver.createArchive(name);

    for await (const file of this.source.fetchAllSequentially(reports, handlers)) {
      archive.append(file);
    }

    this.saver.saveAs(await archive.compress());
  }
}
