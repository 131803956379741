import { reactive } from 'vue';
import { viewModelRepository } from '@/api/common';
import { PagePath, PagePaths, PageState } from '@/store/application/page.definitions';

class TabLabelModule {
  private store: Record<string, string> = {};

  private getStoreId(state: PageState) {
    const { pagePath, id } = state;
    return `${pagePath}_${id}`;
  }

  private getModule(path: PagePath) {
    switch (path) {
      case PagePaths.Case:
        return viewModelRepository.getCasesItemViewModel();
      default:
        return false;
    }
  }

  private async loadItemLabel(state: PageState) {
    const module = this.getModule(state.pagePath);
    if (module && state.id) {
      await module.get(state.id);
      return module.item?.name || '';
    }
    return '';
  }

  get(pageState: PageState): string {
    const storeId = this.getStoreId(pageState);
    if (typeof this.store[storeId] !== 'undefined') return this.store[storeId];
    this.store[storeId] = '';
    this.loadItemLabel(pageState).then((label) => {
      this.store[storeId] = label;
    });
    return String(pageState.id || '');
  }
}

export const tabLabelModule = reactive(new TabLabelModule());
