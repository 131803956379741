import { cloneDeep } from 'lodash';
import { DefaultAclPrefix, getDelay, getFilterString, PartialWithAnyId, PartialWithId, BaseItemState } from '@/definitions/common/base';
import { differenceOf } from '@/definitions/common/utils';
import { DataService } from '@/definitions/services/data.services';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { AclModuleAbstract, ModelAclResult } from '@/store/acl/types';

export class ItemViewModel<T> implements BaseItemState<T> {
  name = 'base';
  apiName = 'api_name';
  aclPrefix = DefaultAclPrefix;
  aclModelName: string | undefined;
  version = 2;

  loading = false;
  loaded = false;
  saving = false;
  loadError: any | null = null;

  relations: any = {};

  additionalData: any = {};

  emptyItem?: T;
  originalItem?: T;
  item?: T;

  excludedChangeKeys: string[] = [];
  autoUpdateWithHelper = true;

  mockItemOnLoad?: (item: any) => any;

  private _dataService: DataService<T, any> | undefined;
  protected _aclModel?: AclModuleAbstract;

  constructor() {}

  init(emptyItem: T) {
    this.emptyItem = cloneDeep(emptyItem);
    this.setEmptyItemsState();
  }

  set dataService(v: DataService<T, any>) {
    this._dataService = v;
  }

  get dataService(): DataService<T, any> {
    if (!this._dataService) {
      throw new Error('Data Service should be initialized');
    } else {
      return this._dataService;
    }
  }

  set aclModule(v: AclModuleAbstract) {
    this._aclModel = v;
  }

  get acl(): ModelAclResult {
    if (!this._aclModel) {
      throw new Error(`ViewModel: ${this.name} has no acl model to compute acl`);
    }
    return this._aclModel.getModelAcl(this);
  }

  get aclViewPermissionName(): string {
    return `${this.aclPrefix}.view_${this.aclModelName}`;
  }

  get aclAddPermissionName(): string {
    return `${this.aclPrefix}.add_${this.aclModelName}`;
  }

  get aclUpdatePermissionName(): string {
    return `${this.aclPrefix}.change_${this.aclModelName}`;
  }

  get aclDeletePermissionName(): string {
    return `${this.aclPrefix}.delete_${this.aclModelName}`;
  }

  get changedData(): Record<string, any> {
    // Fixing bug FFSEC-5927 and similar
    const removeUndefined = function (obj: any) {
      if (obj && typeof obj === 'object') {
        for (const key in obj) {
          if (obj[key] === undefined) {
            delete obj[key];
          } else {
            removeUndefined(obj[key]);
          }
        }
      }
      return obj;
    };
    const diffObject = differenceOf(removeUndefined(cloneDeep(this.item)), this.originalItem);
    const hasExcludedKeys = this.excludedChangeKeys?.length;
    hasExcludedKeys && this.excludedChangeKeys.forEach((v: string) => delete diffObject[v]);
    return diffObject;
  }

  get changes(): string[] {
    return Object.keys(this.changedData);
  }

  get hasChanges(): boolean {
    return Object.keys(this.changes).length > 0;
  }

  get hasAcl(): boolean {
    return !!this.aclModelName;
  }

  get isNew(): boolean {
    const id = (this.item as any)?.id;
    return !id || Number(id) <= -1000;
  }

  setEmptyItemsState(): void {
    this.item = cloneDeep(this.emptyItem);
    this.originalItem = cloneDeep(this.emptyItem);
  }

  async get(id: string | number, merge: boolean = false): Promise<boolean> {
    this.loading = true;
    this.loadError = null;

    try {
      const item = await this.dataService.get(id);
      this.setItemsState(this.mockItemOnLoad ? this.mockItemOnLoad(item) : item, undefined, merge);
    } catch (e) {
      this.setItemsState(null, e);
    }

    return this.loadError ? Promise.reject(this.loadError) : true;
  }

  setItemsState(item: T | null, error?: any, merge: boolean = false) {
    if (item) {
      if (this.item && merge) {
        if (this.hasChanges) {
          console.log('Stop change item with changes!');
          return;
        }
        const newItem = Object.assign({}, this.item, item);
        this.item = cloneDeep(newItem);
        this.originalItem = cloneDeep(newItem);
      } else {
        this.originalItem = item;
        this.item = cloneDeep(item);
      }
      this.loaded = true;
    } else {
      this.loaded = false;
    }
    this.loadError = error;
    this.loading = false;
  }

  async create(): Promise<T | null> {
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      const createdItem: PartialWithAnyId<T> = cloneDeep(this.item) as PartialWithAnyId<T>;
      delete createdItem.id;
      await getDelay(200);
      result = await this.dataService.create(createdItem);
      this.setItemsState(result);
      autoUpdateHelper.createHandler(this.name, result, [this]);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async update(data: Partial<T>): Promise<T | null> {
    const id = (this.item as any)?.id;
    let result: T | null = null;
    this.loading = true;
    this.loadError = null;
    try {
      if (!id) throw new Error('Can not update, because no item ID is defined');
      await getDelay(200);
      result = await this.dataService.update(id, data);
      this.setItemsState(result);
      autoUpdateHelper.updateHandler(this.name, result, [this]);
    } catch (e) {
      this.setItemsState(null, e);
    }
    this.loading = false;
    return this.loadError ? Promise.reject(this.loadError) : result;
  }

  async save(): Promise<T | null> {
    let result: T | null | Promise<never>;
    try {
      this.saving = true;
      await getDelay(100);
      const isNew = this.isNew;
      result = isNew ? await this.create() : await this.update(this.item!);
      if (isNew) {
        autoUpdateHelper.createHandler(this.name, result, [this]);
      } else {
        autoUpdateHelper.updateHandler(this.name, this.item, [this]);
      }
    } catch (e: unknown) {
      result = Promise.reject(e as any);
    } finally {
      this.saving = false;
    }
    return result;
  }

  async reset() {
    this.item = cloneDeep(this.originalItem);
  }

  async clear() {
    this.setItemsState(null);
  }

  async delete(id: string | number): Promise<boolean> {
    return this.dataService
      .delete(id)
      .then(() => {
        autoUpdateHelper.deleteHandler(this.name, id, [this]);
        return true;
      })
      .catch((e) => {
        this.setItemsState(null, e);
        return false;
      });
  }

  getItemRoute(): any {
    const id = (this.item as any)?.id;

    return {
      name: `${this.apiName}Edit`,
      params: {
        id: id,
        item: this.item
      }
    };
  }

  dispose() {
    this._dataService = undefined;
    this.relations = null;
  }
}
