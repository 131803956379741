import { RouteRecordRaw } from 'vue-router';
import { PagePaths, PageNames } from '@/store/application/page.definitions';
import NLauncherPage from '@/pages/launcher/LauncherPage.vue';
import LoginPage from '@/pages/login/LoginPage.vue';
import SettingsPage from '@/pages/settings/SettingsPage.vue';

const Main: RouteRecordRaw = {
  name: PageNames.Launcher,
  path: PagePaths.Launcher,
  component: NLauncherPage
};

const Login: RouteRecordRaw = {
  name: PageNames.Login,
  path: PagePaths.Login,
  component: LoginPage
};

const Settings: RouteRecordRaw = {
  name: PageNames.Settings,
  path: PagePaths.Settings,
  component: SettingsPage
};

export const Routes = {
  Main,
  Login,
  Settings
};
