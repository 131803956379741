
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NDropdown from '@/uikit/dropdown/NDropdown.vue';
import NDropdownItem from '@/uikit/dropdown/NDropdownItem.vue';

@Options({
  name: 'ActionsDropdown',
  components: {
    NButton,
    NDropdown,
    NDropdownItem
  },
  emits: ['action']
})
export default class ActionsDropdown extends Vue {
  @Prop({ type: Array, required: true })
  readonly actions!: Action[];

  @Prop({ type: Boolean, default: false, required: false })
  readonly disableActions!: boolean;

  @Prop({ type: String, required: false })
  readonly dataQa!: string;


  actionsCount = 0;

  private emitAction(action: string) {
    this.$emit('action', action);
    this.actionsCount++;
  }

  private getActionName(action: any) {
    return (action.i18n_label && this.$t(action.i18n_label, 'f')) || (this.$t(action.label, 'f') && action.label);
  }
}
