/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AlertsFilters } from "./AlertsFilters";
import AlertNotification from '@/components/notification-header/AlertNotification.vue';
/**
 * `Alert` is an HTTP URL that is used to notify external services on newly created events
 */
export type AlertNotificationChannels = {
    websocket: boolean;
    telegram: boolean;
}

export type Alert = {
    readonly id: number;
    /**
     * Human-readable name for this Alert
     */
    name: string;
    /**
     * Inactive Alerts will not send any requests
     */
    active: boolean;
    /**
     * Receiving URL
     */
    description: string;
    /**
     * Alert messages batch size
     */
    filters: AlertsFilters;
    delivery_channels: AlertNotificationChannels;
    quick_replies: string[];
    tags: string[];

    ack_interval: number;
    ack_enabled: boolean;

    dedup_interval: number;
    dedup_enabled: boolean;

    /**
     * Creator ID
     */
    readonly created_by: number;
    /**
     * Alert creation date
     */
    readonly created_date: string;
    /**
     * Alert modification date
     */
    readonly modified_date: string;
};

export const EmptyAlert: Alert = {
    id: 0,
        /**
        * Human-readable name for this Alert
        */
    name: '',
        /**
        * Inactive Alerts will not send any requests
        */
    active: true,
        /**
        * Receiving URL
        */
    description: '',
        /**
        * Alert messages batch size
        */
    filters: {},
    tags: [],
    delivery_channels: {
        websocket: true,
        telegram: false
    },

    ack_interval: 60,

    ack_enabled: false,

    dedup_interval: 60,

    dedup_enabled: false,

    quick_replies: [],
        /**
        * Maximum number of attempts to send a Alert
        */
        /**
        * Creator ID
        */
    created_by: 0,
        /**
        * Alert creation date
        */
    created_date: '',
        /**
        * Alert modification date
        */
    modified_date: '',
};
