import { annexWebsocketModule, AnnexWebsocketModule } from '@/store/ws/annex.websocket.module';
import { ItemViewModel, ListViewModel, urlToSnakeCase } from '@/definitions/view-models';
import { reactive } from 'vue';
import { AnnexAlarm, AnnexAlarmFilter, EmptyAnnexAlarm, EmptyAnnexAlarmFilter } from '@/thirdpaties/annex/annex.types';
import { annexApi } from '@/thirdpaties/annex/annex.api';
import { configModule } from '@/store/config';
import { licenseModule } from '@/store/config/LicenseModule';

export const AnnexAlarmModelName = '/alarms';

export class AnnexModule {
  ws?: AnnexWebsocketModule;
  constructor() {}

  init() {
    this.ws = annexWebsocketModule;
    annexWebsocketModule.connect();
  }

  get commentTemplates(): string[] | undefined {
    return configModule.config.annex?.comments;
  }

  getAnnexAlarmsListViewModel() {
    type ModelClass = AnnexAlarm;
    type ModelFilters = AnnexAlarmFilter;
    const url = AnnexAlarmModelName;

    const result: ListViewModel<ModelClass, ModelFilters> = new ListViewModel();
    result.name = url;
    result.apiName = urlToSnakeCase(url);
    result.dataService = annexApi.alarmsDataService;
    result.init({ id: 0, title: '' } as any, EmptyAnnexAlarmFilter);
    result.filter.storageKey = 'alerts';
    const observableResult = reactive(result) as ListViewModel<ModelClass, ModelFilters>;
    return observableResult;
  }

  getAnnexAlarmsItemViewModel() {
    type ModelClass = AnnexAlarm;
    const url = AnnexAlarmModelName;

    const result: ItemViewModel<ModelClass> = new ItemViewModel();
    result.name = url;
    result.apiName = urlToSnakeCase(url);
    result.dataService = annexApi.alarmsDataService;
    result.init(EmptyAnnexAlarm);
    const observableResult = reactive(result) as ItemViewModel<ModelClass>;
    return observableResult;
  }
}

export const annexModule = new AnnexModule();

export function initAnnex() {
  if (configModule.features.alerts_enabled) {
    annexModule.init();
  }
}
