import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "search-sidebar-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "search-sidebar-card__form" }
const _hoisted_4 = { class: "search-sidebar-card__form-top" }
const _hoisted_5 = { class: "search-sidebar-card__form-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardItemTile = _resolveComponent("CardItemTile")!
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.debugMode)
      ? (_openBlock(), _createElementBlock("pre", _hoisted_2, "      currentSearchMode: " + _toDisplayString(_ctx.currentSearchMode) + "\n      pageState.objectType: " + _toDisplayString(_ctx.state.objectType) + "\n      currently applied state: " + _toDisplayString(_ctx.state.cardLooksLike) + "\n      sidebar state: " + _toDisplayString(_ctx.cardModule.state) + "\n    ", 1))
      : _createCommentVNode("", true),
    _createVNode(_component_CardItemTile, {
      class: "search-sidebar-card__card",
      item: _ctx.card,
      type: _ctx.cardType,
      selectable: false,
      hoverable: false,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCard())),
      "show-short-comment": ""
    }, null, 8, ["item", "type"]),
    (_ctx.searchModeItems.length > 1)
      ? (_openBlock(), _createBlock(_component_NButtonGroup, {
          key: 1,
          class: "search-sidebar-card__modes",
          items: _ctx.searchModeItems,
          modelValue: _ctx.currentSearchMode,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentSearchMode) = $event)),
            _ctx.changeObjectTypeOnModeSelect
          ]
        }, null, 8, ["items", "modelValue", "onUpdate:modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_ctx.availableCluster)
          ? (_openBlock(), _createBlock(_component_NSwitch, {
              key: 0,
              modelValue: _ctx.selectedCluster,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedCluster) = $event)),
              label: _ctx.$t('search.include_cluster')
            }, null, 8, ["modelValue", "label"]))
          : _createCommentVNode("", true),
        (_ctx.objects.length > 1)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              type: "link",
              icon: "done-all",
              onClick: _ctx.allCurrentTypeToggle
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.isAllObjectsSelected ? 'search.deselect_all' : 'search.select_all')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objects, (object) => {
          return (_openBlock(), _createBlock(_component_NThumbnail, {
            class: _normalizeClass(['search-sidebar-card__form-item', { 'search-sidebar-card__form-item_selected': _ctx.getIsObjectIdSelected(object.id) }]),
            key: object.id,
            "model-value": object.thumbnail,
            size: "semi-small",
            actions: _ctx.itemActions,
            "default-action": _ctx.getIsObjectIdSelected(object.id) ? 'select' : '',
            onSelect: ($event: any) => (_ctx.toggleObject(object.id))
          }, null, 8, ["class", "model-value", "actions", "default-action", "onSelect"]))
        }), 128))
      ])
    ]),
    (_ctx.hasChanges)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 2,
          class: "search-sidebar-card__apply",
          onClick: _ctx.apply
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('search.apply')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}