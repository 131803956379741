
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { Camera, FaceEvent, HumanEpisode } from '@/api';
import Map from '@/components/map/Map.vue';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { multisidebarModule } from '@/store/multisidebar';
import { actionHandler } from '@/store/data/ActionHandler';
import { isDefined } from '@/uikit/utils';
import NButton from '@/uikit/buttons/NButton.vue';
import MapProviderSelect from '@/components/map/MapProviderSelect.vue';
import { NTableBodyCell, NTableColumn } from '@/uikit/table-v2';
import NTable from '@/uikit/table-v2/NTable.vue';
import { createTableSectionSchemaDecorator } from '@/uikit/table-v2/utils';
import Supercluster, { ClusterProperties } from 'supercluster';
import { LatLng, LatLngBounds, LeafletMouseEvent, Map as TMap } from 'leaflet';
import * as GeoJSON from 'geojson';
import { AreaSelectActionPayload, LatLngType } from '@/components/map/types';
import NTooltip from '@/uikit/hint/NTooltip.vue';
import ClickOutside from '@/uikit/directives/click-outside';
import MapAreaSelect from '@/components/map/MapAreaSelect.vue';
import { computeColors, computeColorsByPosition, isPointInPolygon } from '@/components/map/helpers';
import MapSearch from '@/components/map/MapSearch.vue';
import { CommonMapConfig, CommonMapType, CommonMapTypes } from '@/components/map/CommonMapConfig';
import { LPolyline } from '@vue-leaflet/vue-leaflet';
import { Debounce } from '@/common/debounce-decorator';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { NHint } from '@/uikit';
import Color from 'color';
import MapZones, { MapView } from '@/components/map/MapZones.vue';
import { hashCodeNew } from '@/uikit/helpers';
import { configModule } from '@/store/config';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';

type MapPropId = { item_id: number };
type TModel = HumanEpisode | FaceEvent;

interface ClusterItem extends ClusterProperties {
  latLng: LatLngType;
  cluster_id: any;
}

type ClusterRecord = { id: string; is_cluster: boolean; payload: TModel | ClusterItem };
type PopupState = {
  container: null | HTMLElement;
  visible: boolean;
  reference: null | HTMLDivElement;
  items: TModel[];
};

const ItemMarkerRadius = 15;

function getDefaultPopupState(): PopupState {
  return {
    container: null,
    visible: false,
    reference: null,
    items: []
  };
}

@Options({
  name: 'CommonMap',
  components: {
    NLoadingCircle,
    MapZones,
    NHint,
    LPolyline,
    MapSearch,
    MapAreaSelect,
    NTooltip,
    MapProviderSelect,
    NButton,
    Map,
    NTable
  },
  emits: ['select', 'setFilter', 'setMapView'],
  directives: { ClickOutside }
})
export default class CommonMap extends Vue {
  @Prop({ type: Object })
  readonly config!: CommonMapConfig;

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems!: number[];

  @Prop({ type: Object })
  readonly mapView?: { center: LatLngType; zoom: number };

  @Prop({ type: Object })
  readonly type: CommonMapType = CommonMapTypes.Default;

  @Prop({ type: Number })
  readonly trackId?: number | null;

  map: TMap | null = null;
  clusters: Array<Supercluster.PointFeature<MapPropId> | Supercluster.ClusterFeature<MapPropId>> = [];
  displayTracks = false;
  enableClusters = true;
  hasCones = true;
  leftBarOn = false;
  providerId = 'default';
  areaDrawOn = false;

  popupState: PopupState = getDefaultPopupState();
  currentMapView: any = {};

  get overloadThreshold() {
    return (this.config.module as any)?.filter?.current?.limit ?? 200;
  }

  get isOverloaded() {
    return (this.config.module as any).items?.length >= this.overloadThreshold;
  }

  get mapEnabled() {
    return configModule.features.maps_enabled;
  }

  get bodyDecorators() {
    const getCustomClasses = ({ model }: NTableBodyCell<Camera>) => {
      return { 'common-map__table-cell_selected': this.selectedItems.includes(model.id) };
    };
    return [createTableSectionSchemaDecorator({ class: getCustomClasses })];
  }

  mounted() {
    if (this.isEasyModes) this.fitToMap();
  }

  @Watch('availableItems')
  loadedHandler(v: TModel[]) {
    if (this.isEasyModes) this.fitToMap();
  }

  fitToMap() {
    if (this.availableItems.length) {
      this.$nextTick(() => this.map?.fitBounds(this.getItemBounds(), { padding: [50, 50] }));
    }
  }

  getItemBounds(): LatLngBounds {
    let minLatitude = Math.min(...this.availableItems.map((v) => v.latitude));
    let maxLatitude = Math.max(...this.availableItems.map((v) => v.latitude));
    let minLongitude = Math.min(...this.availableItems.map((v) => v.longitude));
    let maxLongitude = Math.max(...this.availableItems.map((v) => v.longitude));
    return new LatLngBounds(new LatLng(minLatitude, minLongitude, 0), new LatLng(maxLatitude, maxLongitude, 0));
  }

  beforeUnmount() {
    this.map?.off('moveend');
    this.map?.off('movestart');
    this.map?.off('dragend');
  }

  @Watch('availableItems')
  @Watch('enableClusters')
  updateClustersOnItemsChange() {
    this.updateClusters();
  }

  get areaActions() {
    return [
      { name: 'open', i18n_label: 'ds.open_all', icon: 'link-circle' },
      { name: 'filter', i18n_label: 'ds.add_to_filter', icon: 'filter' }
    ];
  }

  get clusterIndex() {
    const clusterOptions = { radius: 60, maxZoom: 18 };
    const index = new Supercluster<MapPropId, MapPropId>(clusterOptions);
    const clusterPoints = this.availableItems.map((v) => ({
      type: 'Feature' as const,
      geometry: {
        type: 'Point' as const,
        coordinates: [v.longitude, v.latitude]
      } as GeoJSON.Point,
      properties: { item_id: v.id }
    }));
    index.load(clusterPoints);
    return index;
  }

  get itemsMap() {
    return Object.fromEntries(this.availableItems.map((v) => [v.id, v]));
  }

  get listItems() {
    return this.availableItems.slice(0, 100);
  }

  get trackLines(): any {
    type MapType = TModel;
    const items: MapType[] = this.computedItems
      .filter((v) => !v.is_cluster)
      // .filter((v) => (this.isEasyModes ? true : (v.payload as FaceEvent).matched_card === this.trackId))
      .map((v) => v.payload) as any;

    const itemGroups = items.reduce<Record<string, MapType[]>>((m, v) => {
      const matchedCard = this.isEasyModes ? 1 : v.matched_card;
      if (matchedCard) {
        m[matchedCard] = m[matchedCard] || [];
        m[matchedCard].push(v);
      }
      return m;
    }, {});

    let result = [];
    for (let matchedCard in itemGroups) {
      let group = itemGroups[matchedCard];
      if (group.length < 2) continue;
      let sortedGroup = group.sort((a, b) => (a.created_date > b.created_date ? 1 : -1));
      let line = sortedGroup.map((v) => [v.latitude, v.longitude]);
      result.push({
        id: matchedCard,
        line: line,
        color: this.isEasyModes ? this.config.colorsConfig.default.default : computeColors(matchedCard, this.config.colorsConfig).default
      });
    }
    return result;
  }

  get computedItems(): ClusterRecord[] {
    return this.availableItems.map((v: HumanEpisode | FaceEvent) => {
      const id = `item-${v.id}`;
      return { id, is_cluster: false, payload: v };
    });
  }

  get clusterItems(): ClusterRecord[] {
    return this.clusters.map((v) => {
      if (v.properties.item_id) {
        const id = `item-${v.properties.item_id}`;
        return { id, is_cluster: false, payload: this.itemsMap[v.properties.item_id] };
      }

      return {
        id: `cluster-${(v.properties as ClusterProperties).cluster_id}`,
        is_cluster: true,
        payload: {
          ...(v.properties as ClusterProperties),
          latLng: {
            lng: v.geometry.coordinates[0],
            lat: v.geometry.coordinates[1]
          }
        }
      };
    });
  }

  get availableItems() {
    const items = (this.config.module as ItemViewModel<any>).item
      ? [(this.config.module as ItemViewModel<any>).item]
      : (this.config.module as ListViewModel<any, any>).items;

    return items.filter((v) => isDefined(v.latitude) && isDefined(v.longitude));
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  get columns(): NTableColumn<TModel>[] {
    return [
      {
        width: 'auto',
        body: ({ model }) => {
          const props = {
            ...this.config.itemProps,
            item: model,
            onAction: (action: string) => {
              this.actionHandler(action as any, model);
              this.config.actionHandler(action, model);
            }
          };
          for (let propName of Object.keys(props)) {
            if (typeof props[propName] === 'function' && propName.indexOf('onAction') < 0) {
              props[propName] = props[propName](model);
            }
          }
          const ListItemComponent = this.config.ListItem;
          return (
            <div class="common-map__description">
              <ListItemComponent {...props} />
            </div>
          );
        }
      }
    ];
  }

  actionHandler(action: ItemsActionName, item: TModel) {
    if (action === ItemsActionNames.ShowInfo || action === ItemsActionNames.AddItem) {
      this.map?.setView(new LatLng(item.latitude!, item.longitude!, 0));
    }
  }

  handleAreaAction({ name, coordinates }: AreaSelectActionPayload) {
    const items = this.availableItems.filter((v) => isPointInPolygon([v.latitude as number, v.longitude as number], coordinates));
    switch (name) {
      case 'open':
        items.map((v) => actionHandler.run(ItemsActionNames.ShowItem, { type: this.config.msbType, rawItem: v }));
        break;
      case 'filter':
        this.$emit('set-filter', { id_in: items.map((v) => v.id) });
        break;
    }
  }

  updateClusters() {
    if (!this.map) return;
    const bounds = this.map.getBounds();
    const bbox: GeoJSON.BBox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
    const zoom = this.map.getZoom();
    this.clusters = this.clusterIndex.getClusters(bbox, zoom);
  }

  resetPopup() {
    this.popupState = getDefaultPopupState();
  }

  updateView() {
    if (this.map) {
      this.currentMapView = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom(),
        bounds: this.map.getBounds()
      };
      if (!this.isEasyModes) {
        this.setFilterCoordinates(this.currentMapView.bounds);
      }
      this.$emit('setMapView', this.currentMapView);
    }
  }

  get isFitToMap() {
    return this.isEasyModes && this.availableItems.length;
  }

  handleMapReady(map: TMap) {
    map.on('moveend', this.updateClusters.bind(this));
    map.on('moveend', () => this.updateView());
    map.on('dragend', () => this.updateView());
    map.on('movestart', this.resetPopup.bind(this));
    this.map = map;

    if (this.config.minZoom) {
      this.map.setMinZoom(this.config.minZoom);
    }

    if (this.isFitToMap) {
      this.fitToMap();
    } else if (this.mapView?.center) {
      this.map.setView(this.mapView.center, this.mapView.zoom);
    }

    this.updateView();
  }

  setCenter(point: LatLng) {
    this.map?.setView(point);
  }

  /* move up, out from component */
  setFilterCoordinates(bounds: LatLngBounds) {
    const topLeft = bounds.getNorthWest();
    const bottomRight = bounds.getSouthEast();
    const filter = (this.config.module as ListViewModel<any, any>).filter.current;
    if (!filter) return;
    filter.longitude_gte = Math.min(topLeft.lng, bottomRight.lng);
    filter.longitude_lte = Math.max(topLeft.lng, bottomRight.lng);
    filter.latitude_gte = Math.min(topLeft.lat, bottomRight.lat);
    filter.latitude_lte = Math.max(topLeft.lat, bottomRight.lat);
  }

  handleMapClick(e: any) {
    if (this.areaDrawOn) return;
    const eventPoint = this.map?.mouseEventToContainerPoint(e);
    if (eventPoint) {
      const clickedItemMarker = this.clusterItems.find((v) => {
        if (v.is_cluster) return false;
        const point = this.map?.latLngToContainerPoint({ lat: (v.payload as TModel).latitude!, lng: (v.payload as TModel).longitude! });
        return point && Math.abs(eventPoint.x - point.x) <= ItemMarkerRadius && Math.abs(eventPoint.y - point.y) <= ItemMarkerRadius;
      });
      if (clickedItemMarker) {
        this.handleClick(clickedItemMarker.payload as any);
      }
    }
  }

  handleSearchSelect(result?: { lat: number; lng: number }) {
    if (result) {
      this.map?.setView(result);
      this.updateView();
    }
  }

  handleViewChanged(data: MapView) {
    if (data.isInitDefault && this.isFitToMap) {
      return;
    }
    if (this.map && data) {
      this.map.setView(data.center, data.zoom);
    }
  }

  handleClick(item: any) {
    if (this.areaDrawOn) return;
    actionHandler.run(ItemsActionNames.ShowItem, { type: this.config.msbType, rawItem: item });
  }

  handleClusterClick(event: LeafletMouseEvent, item: ClusterRecord) {
    if (this.areaDrawOn) return;
    const { cluster_id, point_count } = item.payload as ClusterProperties;
    const clusterPoints = this.clusterIndex.getLeaves(cluster_id, point_count);

    this.popupState.container = this.map?.getContainer() ?? null;
    this.popupState.items = clusterPoints.map((v) => this.itemsMap[v.properties.item_id]);
    this.popupState.reference = event.originalEvent.target as HTMLDivElement;
    this.popupState.visible = true;
  }

  getItemProps(item: TModel) {
    const props = {
      ...this.config.itemProps,
      item,
      onAction: (action: string) => this.config.actionHandler(action, item)
    };

    for (let propName of Object.keys(props)) {
      if (typeof props[propName] === 'function' && propName.indexOf('onAction') < 0) {
        props[propName] = props[propName](item);
      }
    }
    return props;
  }

  getIsItemOpened(item: any) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.config.msbType, item.id);
  }

  getClusterColor(value: ClusterItem) {
    return this.config.colorsConfig.default.default;
  }

  getClusterRadius(value: ClusterItem) {
    const minSize = 30;
    const maxCountToRadiusMap = [
      { count: 10, value: 30 },
      { count: 50, value: 40 },
      { count: 1e12, value: 50 }
    ];
    return maxCountToRadiusMap.find((v) => value.point_count < v.count)?.value || minSize;
  }

  openAll(items: any[]) {
    items.forEach(this.handleClick);
  }

  get isEasyModes() {
    return this.isSearchMode || this.isSimpleMode;
  }

  get isSearchMode() {
    return this.config.mode === 'search';
  }

  get isSimpleMode() {
    return this.config.mode === 'simple';
  }

  getTrackSegmentColor(color: string, index: number, length: number) {
    const alpha = index / (length - 1);
    return Color(color + 'FF')
      .mix(Color(color + 'FF').lighten(0.8), alpha)
      .mix(Color(color + 'FF').darken(0.8), 1 - alpha)
      .hexa();
  }
}
