
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { launchMenuItem } from '@/store/menu';
import { MenuItem } from '@/store/menu/menu';
import NIcon from '@/uikit/icons/NIcon.vue';
import { LaunchpadItem } from './types';

@Options({
  name: 'Launchpad',
  components: { NIcon }
})
export default class Launchpad extends Vue {
  @Prop({ type: Array, required: true })
  items!: LaunchpadItem[];

  @Prop({ type: Number, default: 228 })
  arrowLeft!: number;

  click(item: MenuItem) {
    launchMenuItem(item);
  }
}
