import { getViewModelByName, viewModelRepository } from '@/api/common';
import { aclModule } from '@/store/acl';
import { PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';
import { CasePermissions } from '@/api/models/CasePermissions';
import { ItemViewModel } from '@/definitions/view-models';
import { Case, CaseStatusEnum, VideoArchive } from '@/api';
import { ItemAclResult } from '@/store/acl/types';
import { HealthStatus } from '@/definitions/app/health-status';
import { ActionsModuleOptions } from '@/store/data/ActionsModule';
import { configAclModule } from '@/store/config/acl';

export class CaseAclHelper {
  casePermissions!: CasePermissions;
  case!: Case;
  caseAcl: ItemAclResult = { view: false, update: false, delete: false };

  get isCaseArchived() {
    return this.case?.status !== CaseStatusEnum.Open;
  }

  get isCaseReadonly() {
    return this.caseAcl && !this.caseAcl.update;
  }

  async calcCaseAcl() {
    const maxAcl = aclModule.getModelAclByName('case');
    const permissionVM = getViewModelByName(PermissionsModelNamesMap.Cases) as ItemViewModel<CasePermissions>;
    await permissionVM.get(this.case.id);

    if (!permissionVM.item) {
      console.warn('Not found permissions for case id', this.case.id);
      return;
    }

    const permissionsMap = permissionVM.item.permissions;

    const currentAcl = aclModule.getCaseItemAclByUserGroupMap((permissionsMap as any) || {}, maxAcl);

    const caseModule = viewModelRepository.getCasesItemViewModel();
    await caseModule.get(this.case.id);

    if (this.isCaseArchived) {
      currentAcl.update = false;
      currentAcl.delete = false;
    }

    this.casePermissions = permissionVM.item;
    this.caseAcl = currentAcl;

    return currentAcl;
  }

  async getCaseAclByCase(newCase: Case) {
    this.case = newCase;
    await this.calcCaseAcl();
    return this.caseAcl;
  }

  async getCaseAclByCaseId(caseId: number) {
    const caseModule = viewModelRepository.getCasesItemViewModel();
    await caseModule.get(caseId);

    if (caseModule.item) {
      this.case = caseModule.item;
      await this.calcCaseAcl();
    }

    return this.caseAcl;
  }

  updateActionsOptions(options: ActionsModuleOptions, videoArchive?: VideoArchive) {
    const checkFileSize = videoArchive?.file_size !== 0 || !!videoArchive?.url;
    options.hasProcess = videoArchive?.health_status?.status !== HealthStatus.InProgress && checkFileSize;
    options.hasStopProcess = videoArchive?.health_status?.status !== HealthStatus.Disabled && checkFileSize;
    if (configAclModule.isCIBR() && this.caseAcl && !this.caseAcl.update) {
      options.hasProcess = false;
      options.hasStopProcess = false;
      options.hasDelete = false;
    }
  }
}

export default CaseAclHelper;
