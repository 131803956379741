
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import NRadio from '@/uikit/radio/NRadio.vue';
import NTable, { ITableColumn, ITableModel, ITableRow } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import HeaderBar from '@/components/common/HeaderBar.vue';

type Permission = Record<number, string | null>;

@Options({
  name: 'Permissions',
  components: { HeaderBar, NRadio, NTable }
})
export default class Permissions extends Vue {
  @Prop({ type: Object, required: true })
  readonly modelValue!: Permission[];

  @Prop({ type: Array, default: () => [] })
  readonly disabledIds!: number[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  header: ITableRow = { checked_all: false };
  content: ITableModel = [];
  searchText = '';
  namePrefix = 'group';

  readonly module = viewModelRepository.getGroupsListViewModel();

  get columns(): ITableColumn[] {
    return [
      {
        header: {
          classes: ['bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_name' }
        },
        content: {
          path: 'name',
          component: NButtonModel,
          props: { type: 'link' }
        }
      },
      {
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_off' }
        },
        content: {
          path: 'permission',
          classes: ['align-center'],
          component: NRadio,
          props: (item: any) => ({
            name: `${this.namePrefix}_${item.id}`,
            selectValue: 'off',
            disabled: this.disabledIds.includes(item.id)
          })
        }
      },
      {
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_view' }
        },
        content: {
          path: 'permission',
          classes: ['align-center'],
          component: NRadio,
          props: (item: any) => ({
            name: `${this.namePrefix}_${item.id}`,
            selectValue: 'view',
            disabled: this.disabledIds.includes(item.id)
          })
        }
      },
      {
        width: '100px',
        header: {
          classes: ['align-center', 'bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.column_change' }
        },
        content: {
          path: 'permission',
          classes: ['align-center'],
          component: NRadio,
          props: (item: any) => ({
            name: `${this.namePrefix}_${item.id}`,
            selectValue: 'edit',
            disabled: this.disabledIds.includes(item.id)
          })
        }
      }
    ];
  }

  get filteredContent() {
    return this.content.filter((item: any) => item.name.toLowerCase().includes(this.searchText));
  }

  @Watch('modelValue', { deep: true })
  updateTable() {
    this.content = this.module.items.map((item) => ({
      ...item,
      permission: (this.modelValue && this.modelValue[item.id]) || 'off'
    }));
  }

  async mounted() {
    this.module.filter.current.limit = 1000;
    await this.module.get();
    this.updateTable();
  }

  actionHandler({ type, row, path, value }: { type: string; row: any; path: string; value: any }) {
    if (type === 'change') {
      if (path === 'name') {
        this.openGroup(row.id);
      }
      if (path === 'permission') {
        this.modelValue[row.id] = value === 'off' ? null : value;
      }
      this.$emit('update:modelValue', this.modelValue);
    }
  }

  openGroup(groupId: number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Groups, rawItem: groupId });
  }
}
