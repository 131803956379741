
import { Options, Vue } from 'vue-class-component';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { dialogModule } from '@/store/dialogs/dialogModule';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarGroup as Group } from '@/store/multisidebar/types';
import { Action } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import MultisidebarGroup from './MultisidebarGroup.vue';
import MultisidebarItem from './MultisidebarItem.vue';

@Options({
  name: 'MultisidebarMenu',
  components: { MultisidebarGroup, MultisidebarItem, NButton }
})
export default class MultisidebarMenu extends Vue {
  get module() {
    return multisidebarModule;
  }

  get currentItem() {
    return this.module.currentItem;
  }

  get groups() {
    return this.module.groups;
  }

  get groupActionsMap() {
    const groupActionsMap: Record<string, ItemsActionName[]> = {};
    this.module.itemConfigs.forEach((config) => {
      if (!groupActionsMap[config.groupName]) groupActionsMap[config.groupName] = [];
      groupActionsMap[config.groupName].push(...this.itemsActionsMap[config.type]);
    });

    const groupObjectActionsMap: Record<string, Action[]> = {};
    for (let groupName in groupActionsMap) {
      groupObjectActionsMap[groupName] = [...new Set(groupActionsMap[groupName])].map(actionsModule.computeActionByName).map(actionsModule.addIconToAction);
    }

    return groupObjectActionsMap;
  }

  get itemsActionsMap() {
    const itemActionsMap: Record<string, ItemsActionName[]> = {};
    this.module.itemConfigs.forEach((config) => {
      if (!itemActionsMap[config.type]) itemActionsMap[config.type] = [ItemsActionNames.CloseGroup];
      if (config.groupActionsOptions?.length) {
        const firstItem = this.module.items.find((v) => v.type === config.type);
        if (firstItem) {
          const modelAcl = config.getModelAcl(firstItem);
          const optionsMap = Object.fromEntries(config.groupActionsOptions.map((v: any) => [v, true]));
          itemActionsMap[config.type].push(...actionsModule.getMultipleItemsActions(modelAcl, optionsMap));
        }
      }
    });

    return itemActionsMap;
  }

  get hasNext() {
    return this.module.hasNext;
  }

  get hasPrev() {
    return this.module.hasPrev;
  }

  next() {
    this.module.next();
  }

  prev() {
    this.module.prev();
  }

  async handleGroupAction(group: Group, actionName: ItemsActionName) {
    if (actionName === ItemsActionNames.CloseGroup) {
      await actionHandler.run(actionName, group.name);
    } else {
      const confirmResult = await dialogModule.createGroupConfirm(actionName, group);
      if (!confirmResult) return;

      for (let { item, config } of group.items) {
        if (this.itemsActionsMap[config.type].includes(actionName)) {
          try {
            await actionHandler.run(actionName, item, false);
          } catch (e) {
            console.warn(`[group-action] error for item ${item.type}:${item.id}, message: ${e}`);
          }
        }
      }
    }
  }
}
