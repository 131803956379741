import uniqueId from 'lodash/uniqueId';
import { nextTick } from 'vue';
import type { NAttachment, NAttachmentFileBlob } from './types';

export function fromBlob(blob: NAttachmentFileBlob): NAttachment {
  return { file: blob, id: uniqueId('attachment_'), name: blob.name, progress: 0, size: blob.size, status: 'done' };
}

export function fromDrop(event: DragEvent): NAttachmentFileBlob[] {
  return [...(event.dataTransfer?.files ?? [])];
}

export function fromFs(accept: string, multiple: boolean): Promise<NAttachmentFileBlob[]> {
  const input = createHtmlInputElement(accept, multiple);
  input.classList.add('hide-element');
  document.body.appendChild(input);
  return new Promise((resolve) => {
    input.addEventListener('change', () => { resolve([...(input.files ?? [])]); }, { once: true });
    input.click();
    document.body.removeChild(input);
  });
}

function createHtmlInputElement(accept: string, multiple: boolean): HTMLInputElement {
  const input = document.createElement('input');
  return ([input.accept, input.multiple, input.type] = [accept, multiple, 'file']), input;
}
