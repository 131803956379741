import { applyFilterForItem } from '@/components/common/page/ApplyFilter';
import { ListViewModel } from '@/definitions/view-models';

export class CommonPagePlaying {
  show = false;
  active = true;
  items: any[] = [];
  module!: ListViewModel<any, any>;

  get newItemsCount() {
    const n = this.items.length;
    if (n === 0) return undefined;
    return n;
  }

  toggle() {
    this.active = !this.active;
    if (this.active) {
      this.showNewItems();
    }
  }

  addWebsocketItem(item: any) {
    if (applyFilterForItem(item, this.module.filter.current)) {
      if (this.active) {
        this.module.items.unshift(item);
      } else {
        this.items.push(item);
      }
      this.module.count++;
    }
  }

  showNewItems() {
    this.module.items.unshift(...this.items);
    this.items.length = 0;
  }
}
